import React from 'react'

const Spinner = ({children, mask, hideIcon, inRow}) => (
  <div className={`spinner ${mask ? 'mask' : ''} ${inRow ? 'in-row' : ''}`} >
    <div className="spinner-content">
      { hideIcon ? null : <span className="fa fa-spinner fa-pulse" /> }
      &nbsp;
      <span className="spinner-text">{children}</span>
    </div>
  </div>
)

export default Spinner
