import React from 'react'
import MyLabel from './MyLabel'
import { HiChevronUpDown } from 'react-icons/hi2'

export default function MySelect({
  register,
  name,
  errors,
  rules = {},
  options = [],
  label,
  labelSuffix,
  prefix,
  theme = 'light',
  onChange,
  defaultValue = {},
}) {
  const themeClassMap = {
    white: 'tw-bg-white tw-border-gray-300',
    light: 'tw-bg-gray-100 tw-border-transparent',
    dark: 'tw-bg-gray-800 tw-border-transparent',
  }
  const themeClass = themeClassMap[theme] || 'tw-bg-gray-100 tw-border-transparent'

  return (
    <div>
      <MyLabel text={label} textSuffix={labelSuffix}>
        <div>
          <div className="tw-flex tw-items-center">
            {prefix && <div>{prefix}</div>}
            <div className="tw-w-full tw-relative">
              {onChange ?
                <select onChange={onChange} className={`myselect ${themeClass} tw-cursor-pointer`}>
                  {options.map(({ label: _label, value: _value }) => (
                    <option key={_label} value={_value}>
                      {_label}
                    </option>
                  ))}
                </select>
              :
                <select {...register(name, rules)} className={`myselect ${themeClass} tw-cursor-pointer tw-truncate`} defaultValue={defaultValue?.value}>
                  {Object.values(defaultValue).length > 0 ? <option value={defaultValue.value}>{defaultValue?.name}</option> : null}
                  {options.map(({ label: _label, value: _value }) => (
                    <option key={_label} value={_value}>
                      {_label}
                    </option>
                  ))}
                </select>
              }
              <div className="tw-absolute tw-top-2 tw-right-1.5 tw-text-slate-400 tw-text-xl tw-pointer-events-none">
                <HiChevronUpDown />
              </div>
            </div>
          </div>
          {errors?.[name] && (
            <div className="tw-text-xs tw-text-red-600 tw-font-medium tw-mt-1">{errors[name]?.message}</div>
          )}
        </div>
      </MyLabel>
    </div>
  )
}
