import React from 'react'

export default function ConnectionProgress({
  isLoading = false,
  label,
  current,
  total,
  percentage = 0,
  timeRemaining,
}) {
  return (
    <div className="tw-text-slate-500 tw-py-3 md:tw-flex md:tw-space-x-3">
      <div className="md:tw-shrink-0 md:tw-w-20" />
      <div className="tw-w-full">
        <div className="tw-text-xs tw-mb-1 md:tw-flex md:tw-justify-between">
          {label && (
            <div className="tw-pr-8">
              {isLoading ? (
                <>
                  Loading data for <span className="tw-font-bold tw-mr-1">{label}</span>
                  <span>
                    ({current}/{total} days)
                  </span>
                </>
              ) : (
                <>
                  <span className="tw-font-bold tw-mr-1">{label}</span>
                  <span>successfuly connected</span>
                </>
              )}
            </div>
          )}
          {timeRemaining && (
            <>
              <div className="tw-block md:tw-hidden tw-mt-0.5">({timeRemaining})</div>
              <div className="tw-hidden md:tw-block">{timeRemaining}</div>
            </>
          )}
        </div>
        <div className="tw-relative tw-w-full tw-h-1 tw-rounded-full tw-bg-blue-600/20 tw-overflow-hidden">
          <div
            className={`tw-absolute tw-top-0 tw-left-0 tw-h-1 ${
              isLoading ? 'tw-bg-blue-600' : 'tw-bg-green-500'
            } tw-transition-all`}
            style={{ width: `${percentage}%` }}
          />
        </div>
      </div>
    </div>
  )
}
