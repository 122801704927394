import React, { Component } from 'react';
import useDrivePicker from 'react-google-drive-picker';

const withDrivePicker = (WrappedComponent) => {
  return (props) => {
    const [openPicker, authResponse] = useDrivePicker();

    return (
      <WrappedComponent
        {...props}
        openPicker={openPicker}
        authResponse={authResponse}
      />
    );
  };
};

export default withDrivePicker;