import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import _ from 'lodash'
import { awsSecretsManagerUrl } from 'urls'
import { getAxiosDefaultConfig } from '../../modules/utils'

export const initialState = {
  secrets: {},
}

// Will match any secrets whose name starts with the payload `ownerId`
export const fetchAllSecrets = createAsyncThunk('awsSecretsManager/fetchAllSecrets', async (payload) => {
  const { ownerId } = payload
  let res = await axios.get(`${awsSecretsManagerUrl}?secretId=${ownerId}`, getAxiosDefaultConfig())
  return res?.data?.data
})

export const createSecret = createAsyncThunk('awsSecretsManager/createSecret', async (payload, { rejectWithValue }) => {
  const { secretId, secretValue } = payload
  try {
    let res = await axios.post(awsSecretsManagerUrl, payload, getAxiosDefaultConfig())
    return { secretId, secretValue }
  } catch (err) {
    const errData = err?.response?.data
    const errMessage = errData?.error?.message
    console.error('Error while creating secret', errMessage)
    return rejectWithValue('Error while adding private key')
  }
})

export const updateSecret = createAsyncThunk('awsSecretsManager/updateSecret', async (payload) => {
  const { secretId, secretValue } = payload
  let res = await axios.put(`${awsSecretsManagerUrl}/${secretId}`, payload, getAxiosDefaultConfig())
  return { secretId, secretValue }
})

export const deleteSecret = createAsyncThunk('awsSecretsManager/deleteSecret', async (payload) => {
  const { secretId } = payload
  let res = await axios.delete(`${awsSecretsManagerUrl}/${secretId}`, getAxiosDefaultConfig())
  return { secretId }
})

export const awsSecretsManagerSlice = createSlice({
  name: 'awsSecretsManager',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      /**
       *
       * aws secrets api
       *
       */
      .addCase(fetchAllSecrets.pending, (state, action) => {
        state.secrets.status = 'loading'
      })
      .addCase(fetchAllSecrets.fulfilled, (state, action) => {
        state.secrets.status = 'succeeded'
        state.secrets.data = action.payload
      })
      .addCase(fetchAllSecrets.rejected, (state, action) => {
        state.secrets.status = 'failed'
        state.secrets.error = action.error.message
      })
      .addCase(createSecret.fulfilled, (state, action) => {
        state.secrets.data = [...state.secrets.data, action.payload]
      })
      .addCase(updateSecret.fulfilled, (state, action) => {
        const { secretId } = action.meta.arg
        const index = _.findIndex(state.secrets.data, (secret) => secret.secretId === secretId)
        _.set(state.secrets.data, [index, 'secretValue'], action.payload.secretValue)
      })
      .addCase(deleteSecret.fulfilled, (state, action) => {
        const { secretId } = action.meta.arg
        state.secrets.data = _.filter(state.secrets.data, (secret) => secret.secretId !== secretId)
      })
  },
})

export const {} = awsSecretsManagerSlice.actions

/**
 *
 * Selectors
 *
 */

// Dedupe secrets by secretValue - pick the most recently created secret (this handles the old key format as well)
export const dedupeSecrets = (secrets) =>
  _.chain(secrets)
    .groupBy((secret) => secret.secretValue)
    .values()
    .map((group) => _.maxBy(group, (secret) => new Date(secret.createdDate)))
    .flatten()
    .value()

export const selectAllSecretsStatus = (state) => state.awsSecretsManager.secrets.status || 'idle'
export const selectAllSecretsError = (state) => state.awsSecretsManager.secrets.error
export const selectAllSecrets = (state) => state.awsSecretsManager.secrets.data

export const selectChannelSecrets = createSelector(
  [(state) => state.awsSecretsManager.secrets, (state, channelId) => channelId],
  (secrets, channelId) => _.filter(secrets.data, (secret) => secret.secretId.includes(channelId))
)

export const selectSecret = createSelector(
  [(state) => state.awsSecretsManager.secrets, (state, secretId) => secretId],
  (secrets, secretId) => secrets.data.find((secret) => secret.secretId === secretId) || null
)

export default awsSecretsManagerSlice.reducer
