export const sampleCsvData = [
  {
    order_number: 1234,
    channel: 'shopify *REQUIRED*',
    product_cost: 19.99,
    gateway_fee: 2.99,
    shipping_cost: 5.27,
    shipping_label_cost: 2.99,
    shipping_provider: 'usps',
    tracking_number: '1Z999AA19123456789',
    meta_data: "{ sampleMetaInfo1: 'leave columns blank if unused' }"
  },
]

export const sampleCsvHeaders = [
  { label: 'order_number', key: 'order_number' },
  { label: 'channel', key: 'channel' },
  { label: 'product_cost', key: 'product_cost' },
  { label: 'gateway_fee', key: 'gateway_fee' },
  { label: 'shipping_label_cost', key: 'shipping_label_cost' },
  { label: 'shipping_cost', key: 'shipping_cost' },
  { label: 'shipping_provider', key: 'shipping_provider' },
  { label: 'tracking_number', key: 'tracking_number' },
  { label: 'meta_data', key: 'meta_data' },
]

export const ACCEPTED_HEADERS = sampleCsvHeaders.map(header => header.label)

const ACCEPTED_SHEETS_HEADERS = ['order_number', 'channel']


export const sanitizeData = (data) => {
  return data.map((row) => {
    return Object.keys(row).reduce((obj, key) => {
      obj[key] = typeof row[key] === 'string' ? row[key].replace(/[^A-Za-z0-9.@_-{}:,"]/g, '') : row[key]
      return obj
    }, {})
  })
}

export const checkFileSize = (data) => {
  const sizeInBytes = new TextEncoder().encode(JSON.stringify(data)).length
  return sizeInBytes
}

export const checkInvalidProperties = (data) => {
  const dataProperties = Object.keys(data[0]);
  const missingProperties = ACCEPTED_HEADERS.filter(prop => !dataProperties.includes(prop));
  return missingProperties
}

export const checkInvalidSheetsProperties = (data) => {
  const dataProperties = Object.keys(data[0]);
  const missingProperties = ACCEPTED_SHEETS_HEADERS.filter(prop => !dataProperties.includes(prop));
  return missingProperties
}
