import React from 'react'
import ReactTooltip from 'react-tooltip'
import MyCheckbox from '../../features/forms/MyCheckbox'
import MyInput from '../../features/forms/MyInput'
import Accordian, { AccordianItem } from '../../sharedComponents/Accordian'
import { FiAlertCircle } from 'react-icons/fi'

export default function InsurancePreferences({ preferences, formObj }) {
  const { register, errors, autoInsuranceRules } = formObj

  return (
    <div className="tw-pt-10 tw-pb-36 tw-border-t tw-border-slate-200">
      <div className="tw-px-4 md:tw-px-8">
        <div className="tw-text-xl tw-font-bold tw-mb-4">Auto-insurance setup</div>
        <div className="tw-text-slate-500 tw-mb-4">
          Enable auto-insurance and make sure your orders are automatically insured.
        </div>

        <div className="tw-p-4 tw-rounded-lg tw-bg-slate-50 tw-border tw-border-slate-200">
          <div>
            Please read the following{' '}
            <a href="https://www.payhelm.com/autoinsure/" target="_blank" rel="noreferrer noopener">
              disclaimer
            </a>{' '}
            before enabling Auto-Insurance Rules
            <span>
              <span
                className="fa fa-info-circle cursor-pointer ml1"
                data-tip
                data-for={`tooltip-primary-insurance-settings`}
                style={{ color: '#0070ba' }}
              />
              <ReactTooltip id={`tooltip-primary-insurance-settings`}>
                <div style={{ maxWidth: 270 }}>
                  <div>
                    UPS and FedEx include $100 of insurance with all their shipments and USPS offers insurance with some
                    of their services. You can insure everything over these amounts with PayHelm.
                  </div>
                </div>
              </ReactTooltip>
            </span>
          </div>
        </div>

        <div>
          <div className="tw-text-base tw-py-6 tw-mb-6 tw-border-b tw-border-slate-200">
            <MyCheckbox register={register} name="autoInsure" label="Auto-Insure all orders" />
          </div>
          <Accordian>
            <AccordianItem header={<div>Additional Insurance Rules</div>}>
              <div>
                <div className="tw-flex tw-mb-3" style={{ color: '#808080' }}>
                  <div className="tw-pr-1 tw-shrink-0 tw-mt-1">
                    <FiAlertCircle />
                  </div>
                  <div>
                    Use the settings below to automatically purchase insurance for shipments based on Domestic vs
                    International addresses, carrier and package value. Auto Insurance only supports USD transactions.
                    Please read the following{' '}
                    <a href="https://www.payhelm.com/autoinsure/" target="_blank" rel="noreferrer noopener">
                      disclaimer
                    </a>{' '}
                    before enabling Auto-Insurance Rules.
                  </div>
                </div>

                <table className="auto-insurance-rules-table tw-min-w-[480px]">
                  <thead>
                    <tr>
                      <th>CARRIER</th>
                      <th>DOMESTIC</th>
                      <th>INTERNATIONAL</th>
                    </tr>
                  </thead>
                  <tbody>
                    {autoInsuranceRules.map((_autoInsuranceRule, i) => {
                      const { id, carrier } = _autoInsuranceRule
                      return (
                        <tr key={id}>
                          <td>{carrier}</td>
                          <td>
                            <div className="tw-flex tw-items-center">
                              <div className="tw-w-6 tw-mt-0.5 tw-shrink-0">
                                <MyCheckbox
                                  register={register}
                                  name={`autoInsuranceRules.${i}.domestic.enabled`}
                                  label=""
                                />
                              </div>
                              <div className="tw-w-full">
                                <MyInput
                                  register={register}
                                  name={`autoInsuranceRules.${i}.domestic.amount`}
                                  errors={errors}
                                  placeholder=""
                                  prefix={<div className="tw-pr-1">$</div>}
                                />
                              </div>
                            </div>
                          </td>
                          <td>
                            <div className="tw-flex tw-items-center">
                              <div className="tw-w-6 tw-mt-0.5 tw-shrink-0">
                                <MyCheckbox
                                  register={register}
                                  name={`autoInsuranceRules.${i}.international.enabled`}
                                  label=""
                                />
                              </div>
                              <div className="tw-w-full">
                                <MyInput
                                  register={register}
                                  name={`autoInsuranceRules.${i}.international.amount`}
                                  errors={errors}
                                  placeholder=""
                                  prefix={<div className="tw-pr-1">$</div>}
                                />
                              </div>
                            </div>
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              </div>
            </AccordianItem>
          </Accordian>
        </div>
      </div>
    </div>
  )
}
