import moment from 'moment'
import numeral from 'numeral'

export const defaultPlan = {
  code: 'free',
  product: 'Free',
  interval: 'month',
  nickname: ''
}

export const defaultTrialEnd = () => {
  const trialEnd = moment().utc()
  trialEnd.add(30, 'days') // eslint-disable-line no-magic-numbers
  return trialEnd.toISOString()
}

export const planOptions = (loginFromChannel) => {
  const sortedChannels = [
    ...(loginFromChannel ? [loginFromChannel] : []),
    ...['Bigcommerce', 'Shopify', 'Woocommerce', 'Amazon'].filter(c => c !== loginFromChannel),
  ].map(c => `b_${c}`)
  const channelStringArray = sortedChannels.reduce((acc, cur, index) => [
    ...acc,
    ...(index ? (
      (index === sortedChannels.length - 1) ? [' & ', cur] : [', ', cur]
    ) : [cur])
  ], [])
  return [
    {
      code: 'free',
      product: 'Free',
      priceMonth: 0,
      priceYear: 0,
      priceTwoYear: 0,
      priceThreeYear: 0,
      description: [
        ['Analyze ', 'b_$10,000/m ', 'in sales'],
        channelStringArray,
        // ['b_Bigcommerce', ', ', 'b_Shopify', ' & ', 'b_WooCommerce'],
        ['Import All Sales History'],
        ['Stores, Orders, and Users'],
        ['b_300+ ', 'Metrics to Segment'],
        ['Shipping & Insurance'],
      ],
      limit: '1 connected user',
      limitOnModal: '$10,000/m & 1 connected user',
    },
    {
      code: 'startup',
      product: 'Startup',
      priceMonth: 10,
      priceYear: 96,
      priceTwoYear: 180,
      priceThreeYear: 252,
      description: [
        ['Analyze ', 'b_$10,000/m ', 'in sales'],
        ['Everything in the free plan plus:'],
        ['Shared Reports'],
        ['Export Reports'],
        ['Create Custom Reports'],
      ],
      limit: 'Unlimited connected users',
      limitOnModal: '$10,000/m',
    },
    {
      code: 'professional',
      product: 'Professional',
      priceMonth: 50,
      priceYear: 480,
      priceTwoYear: 900,
      priceThreeYear: 1260,
      description: [
        ['Analyze ', 'b_$50,000/m ', 'in sales'],
        ['Everything in the free plan plus:'],
        ['Shared Reports'],
        ['Export Reports'],
        ['Create Custom Reports'],
      ],
      limit: 'Unlimited connected users',
      limitOnModal: '$50,000/m',
    },
    {
      code: 'business',
      product: 'Business',
      priceMonth: 100,
      priceYear: 960,
      priceTwoYear: 1800,
      priceThreeYear: 2520,
      description: [
        ['Analyze ', 'b_$200,000/m ', 'in sales'],
        ['Everything in the free plan plus:'],
        ['Save Custom Reports'],
        ['Advanced Filters & Segments'],
        ['Schedule & Email Reports'],
      ],
      limit: 'Unlimited connected users',
      limitOnModal: '$200,000/m',
    },
    {
      code: 'enterprise',
      product: 'Enterprise',
      description: [
        ['Analyze ', 'b_Unlimited ', 'volumes of sales'],
        ['Everything in the free plan plus:'],
        ['ShipStation integration'],
        ['Google Analytics Integration'],
        ['Web Traffic Reporting'],
      ],
    },
  ]
}

export const planOptionNames = planOptions().map(o => o.product)

export const addOnOptions = ({ Traffic: trafficPrice } = {}) => {
  return [
    {
      code: 'inventory',
      product: 'Inventory',
      priceMonth: 100,
      priceYear: 960,
      priceTwoYear: 1800,
      priceThreeYear: 2520,
      description: [
        ['Track your Inventory levels, sell through-rate, and turn over.'],
        ['See your top performers and bottom performers.'],
        ['Know when to restock and track seasonal changes.'],
      ],
    },
    {
      code: 'traffic',
      product: 'Traffic',
      productTitle: 'Traffic & Advertising',
      requiredPlanLevel: 'Professional',
      priceMonth: trafficPrice,
      priceYear: Math.round(trafficPrice * 9.6),
      priceTwoYear: Math.round(trafficPrice * 18),
      priceThreeYear: Math.round(trafficPrice * 25.2),
      description: [
        ['Know what campaigns have great ROAS and improve your funnel performance.'],
        ['Compare traffic from all your marketing efforts and know what landing pages are performing best.'],
        ['Get the detail your team needs.'],
      ],
    },
  ]
}

export const addOnOptionNames = planOptions().map(o => o.product)

export const getMonthlyPriceFromStripePlan = (plan) => {
  let ret
  const intervalCount = Number(_.get(plan, ['metadata', 'intervalCount'], plan.interval_count))
  if (plan.interval === 'month') {
    ret = plan.amount
  }
  else if (intervalCount === 1) {
    ret = plan.amount / 9.6
  }
  else if (intervalCount === 2) {
    // ret = plan.amount / 18 // for 2-year
    ret = plan.amount / 9 // for 2 x yearly
  }
  else {
    // ret = plan.amount / 25.2 // for 3-year
    ret = plan.amount / 8.4 // for 3 x yearly
  }
  return Math.round(ret / 100)
}

export const getAffectedAddOns = (selectedPlanOption, stripeSubscriptionAddOns) => {
  const selectedPlanPrice = selectedPlanOption.interval === 'month' ? selectedPlanOption.priceMonth : (
    selectedPlanOption.intervalCount === 1 ? selectedPlanOption.priceYear : (
      selectedPlanOption.intervalCount ===  2 ? selectedPlanOption.priceTwoYear : selectedPlanOption.priceThreeYear
    )
  )
  const intervalText = selectedPlanOption.interval === 'month' ? 'm' : `${selectedPlanOption.intervalCount}y`
  const durationText = selectedPlanOption.interval === 'month' ? '1 Month' : `${selectedPlanOption.intervalCount} Year${selectedPlanOption.intervalCount > 1 ? 's' : ''}`
  const savingText = selectedPlanOption.interval === 'month' ? '' : (
    selectedPlanOption.intervalCount === 1 ? '(20% Savings)' : (
      selectedPlanOption.intervalCount ===  2 ? '(25% Savings)' : '(30% Savings)'
    )
  )
  const savingTextV2 = selectedPlanOption.interval === 'month' ? 'No Savings' : (
    selectedPlanOption.intervalCount === 1 ? '20% Savings' : (
      selectedPlanOption.intervalCount ===  2 ? '25% Savings' : '30% Savings'
    )
  )
  const billingTypeText = selectedPlanOption.interval === 'month' ? 'Monthly Billable' : 'Yearly Billable'
  const affectedAddOns = stripeSubscriptionAddOns.filter(addOn => 
    addOn.interval !== selectedPlanOption.interval ||
    Number(_.get(addOn, ['metadata', 'intervalCount'], addOn.interval_count)) !== selectedPlanOption.intervalCount
  ).map(affectedAddOn => {
    let affectedAddOnPrice
    const monthlyAddOnPrice = getMonthlyPriceFromStripePlan(affectedAddOn)
    affectedAddOnPrice = monthlyAddOnPrice * (
      selectedPlanOption.interval === 'month' ? 1 : (
        selectedPlanOption.intervalCount === 1 ? 9.6 : (
          selectedPlanOption.intervalCount === 2 ? 18 : 25.2
        )
      )
    )
    const monthlyPaidPrice = _.round(affectedAddOnPrice / (
      selectedPlanOption.interval === 'month' ? 1 : (selectedPlanOption.intervalCount * 12)
    ), 2)
    const summaryText = selectedPlanOption.interval === 'month' ? 
      `Monthly $${numeral(monthlyPaidPrice).format('0,0.0').replace(/\.0$/, '').replace(/\.00$/, '')}/m` : 
      `Yearly $${numeral(monthlyPaidPrice).format('0,0.0').replace(/\.0$/, '').replace(/\.00$/, '')}/m billed, $${numeral(affectedAddOnPrice).format('0,0')}/${intervalText} ${savingText}`
    const confirmationText = selectedPlanOption.interval === 'month' ? 
    `Monthly $${numeral(monthlyPaidPrice).format('0,0.00').replace(/\.0$/, '').replace(/\.00$/, '')}/m` : 
    `Yearly $${numeral(affectedAddOnPrice).format('0,0.00').replace(/\.0$/, '').replace(/\.00$/, '')}/${intervalText}`
    
    return {
      ...affectedAddOn,
      monthlyPaidPrice,
      calculatedPrice: affectedAddOnPrice,
      summaryText,
      confirmationText,
      durationText,
      savingTextV2,
      billingTypeText,
    }
  })
  return affectedAddOns
}