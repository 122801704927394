import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import _ from 'lodash'
/**
 *
 * !!! NOTE !!! - this is not currently being used (except for selectors)!
 *
 *    - Had to stop since it'd involve refactoring `BackillBanner` and possibly other backfill related pages/components
 *    - For future refactoring:
 *      - Currently, backfill status is being polled inside actions - a better way to do it would be to create
 *        a custom hook (e.g. `useBackfillStatusPoll`) that uses something like `useInterval` to poll the status
 *        and use this hook inside components that require status polling - i.e. `BackfillBanner`
 *
 */

export const initialState = {}

export const backfillSlice = createSlice({
  name: 'backfillNew',
  initialState,
  reducers: {},
})

export const {} = backfillSlice.actions

/**
 *
 * Selectors
 *
 */
export const selectBackfills = (state) => state.backfill.backfill

export default backfillSlice.reducer
