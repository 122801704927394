import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import momenttz from 'moment-timezone'
import _ from 'lodash'
import { getAxiosDefaultConfig, queryObjToStr } from '../../modules/utils'
import { searchServer } from '../Dashboard/requests/search'
import { trafficCartsReportUrl } from '../../modules/urls'

export const initialState = {
  currencyRates: {},
  todayOrders: {},
  orders: {},
  todayTrends: {},
  trends: {},
  traffic: {},
}

export const fetchCurrencyRates = createAsyncThunk('home/currencyRates', async (payload) => {
  const { start_date, end_date, currency, timezone } = payload
  const queryObj = {
    start_date,
    end_date,
    currency,
    ...(timezone ? { timezone } : {}),
  }
  const queryStr = queryObjToStr(queryObj)
  const res = await axios.get(`/currency-rates?${queryStr}`, getAxiosDefaultConfig())
  return res?.data
})

export const fetchTodayOrders = createAsyncThunk('home/todayOrders', async (payload) => {
  const { params, config } = payload
  const res = await searchServer().post('/orders', { params, config })
  return res?.data
})

export const fetchOrders = createAsyncThunk('home/orders', async (payload) => {
  const { params, config } = payload
  const res = await searchServer().post('/orders', { params, config })
  return res?.data
})

export const fetchTodayTrends = createAsyncThunk('home/todayTrends', async (payload) => {
  const { params, config } = payload
  const res = await searchServer().post('/trends', { params, config })
  return res?.data
})

export const fetchTrends = createAsyncThunk('home/trends', async (payload) => {
  const { params, config } = payload
  const res = await searchServer().post('/trends', { params, config })
  return res?.data
})

export const fetchTraffic = createAsyncThunk('home/traffic', async (payload, { getState }) => {
  const { timeQuery = 5, page = 1, limit = 20, timestamp = new Date().getTime() } = payload

  const {
    user: { default_timezone: timezone },
  } = getState().root
  const today_timestamp = momenttz.tz(timezone).hour(0).minute(0).second(0).millisecond(0).toDate().getTime()
  const min_ago = timeQuery !== 'today' && timeQuery
  const start_timestamp = timeQuery === 'today' ? today_timestamp : undefined
  const by_day = timeQuery === 'today' ? 'true' : undefined

  const queryObj = {
    timestamp,
    page,
    limit,
    ...(min_ago ? { min_ago } : {}),
    ...(start_timestamp ? { start_timestamp } : {}),
    ...(today_timestamp ? { today_timestamp } : {}),
    ...(by_day ? { by_day } : {}),
    ...(timezone ? { timezone } : {}),
  }
  const queryStr = queryObjToStr(queryObj)

  const requestUrl = `${trafficCartsReportUrl}?${queryStr}`
  const res = await axios.post(requestUrl, {}, getAxiosDefaultConfig())
  return res?.data
})

export const homeSlice = createSlice({
  name: 'home',
  initialState,
  reducers: {
    setCurrencyRatesStatus: (state, action) => {
      state.currencyRates.status = action.payload
    },
    setOrdersStatus: (state, action) => {
      state.orders.status = action.payload
    },
    setTrendsStatus: (state, action) => {
      state.trends.status = action.payload
    },
    setTrafficStatus: (state, action) => {
      state.traffic.status = action.payload
    },
  },
  extraReducers(builder) {
    builder
      /**
       *
       * currencyRates api
       *
       */
      .addCase(fetchCurrencyRates.pending, (state, action) => {
        state.currencyRates.status = 'loading'
      })
      .addCase(fetchCurrencyRates.fulfilled, (state, action) => {
        state.currencyRates.data = action.payload
        state.currencyRates.status = 'succeeded'
      })
      .addCase(fetchCurrencyRates.rejected, (state, action) => {
        state.currencyRates.status = 'failed'
        state.currencyRates.error = action.error.message
      })

      /**
       *
       * todayOrders api
       *
       */
      .addCase(fetchTodayOrders.pending, (state, action) => {
        state.todayOrders.status = 'loading'
      })
      .addCase(fetchTodayOrders.fulfilled, (state, action) => {
        state.todayOrders.data = action.payload
        state.todayOrders.status = 'succeeded'
      })
      .addCase(fetchTodayOrders.rejected, (state, action) => {
        state.todayOrders.status = 'failed'
        state.todayOrders.error = action.error.message
      })

      /**
       *
       * orders api
       *
       */
      .addCase(fetchOrders.pending, (state, action) => {
        state.orders.status = 'loading'
      })
      .addCase(fetchOrders.fulfilled, (state, action) => {
        state.orders.data = action.payload
        state.orders.status = 'succeeded'
      })
      .addCase(fetchOrders.rejected, (state, action) => {
        state.orders.status = 'failed'
        state.orders.error = action.error.message
      })

      /**
       *
       * todayTrends api
       *
       */
      .addCase(fetchTodayTrends.pending, (state, action) => {
        state.todayTrends.status = 'loading'
      })
      .addCase(fetchTodayTrends.fulfilled, (state, action) => {
        state.todayTrends.data = action.payload
        state.todayTrends.status = 'succeeded'
      })
      .addCase(fetchTodayTrends.rejected, (state, action) => {
        state.todayTrends.status = 'failed'
        state.todayTrends.error = action.error.message
      })

      /**
       *
       * trends api
       *
       */
      .addCase(fetchTrends.pending, (state, action) => {
        state.trends.status = 'loading'
      })
      .addCase(fetchTrends.fulfilled, (state, action) => {
        state.trends.data = action.payload
        state.trends.status = 'succeeded'
      })
      .addCase(fetchTrends.rejected, (state, action) => {
        state.trends.status = 'failed'
        state.trends.error = action.error.message
      })

      /**
       *
       * traffics api
       *
       */
      .addCase(fetchTraffic.pending, (state, action) => {
        state.traffic.status = 'loading'
      })
      .addCase(fetchTraffic.fulfilled, (state, action) => {
        state.traffic.data = action.payload
        state.traffic.status = 'succeeded'
      })
      .addCase(fetchTraffic.rejected, (state, action) => {
        state.traffic.status = 'failed'
        state.traffic.error = action.error.message
      })
  },
})

export const { setOrdersStatus, setTrendsStatus, setTrafficStatus } = homeSlice.actions

/**
 *
 * Selectors
 *
 */
export const selectCurrencyRatesStatus = (state) => state.home.currencyRates.status || 'idle'
export const selectCurrencyRatesError = (state) => state.home.currencyRates.error
export const selectCurrencyRates = (state) => state.home.currencyRates

export const selectTodayOrdersStatus = (state) => state.home.todayOrders.status || 'idle'
export const selectTodayOrdersError = (state) => state.home.todayOrders.error
export const selectTodayOrders = (state) => state.home.todayOrders

export const selectOrdersStatus = (state) => state.home.orders.status || 'idle'
export const selectOrdersError = (state) => state.home.orders.error
export const selectOrders = (state) => state.home.orders

export const selectTodayTrendsStatus = (state) => state.home.todayTrends.status || 'idle'
export const selectTodayTrendsError = (state) => state.home.todayTrends.error
export const selectTodayTrends = (state) => state.home.todayTrends

export const selectTrendsStatus = (state) => state.home.trends.status || 'idle'
export const selectTrendsError = (state) => state.home.trends.error
export const selectTrends = (state) => state.home.trends

export const selectTrafficStatus = (state) => state.home.traffic.status || 'idle'
export const selectTrafficError = (state) => state.home.traffic.error
export const selectTraffic = (state) => state.home.traffic

export default homeSlice.reducer
