import React from 'react'
import _ from 'lodash'
import Spinner from 'sharedComponents/Spinner'
import InkfrogRouter from './sharedComponents/Router.inkfrog'
import AppRouter from './sharedComponents/Router.app'
import { connect } from 'react-redux'
import { getCookie, setCookie } from 'cookie'
import { sendGtmPixel } from 'tagManager'
import qs from 'query-string'
import {
  appLogin,
  inkfrogLogin,
  changeApplication,
  setTopUrl,
  updateQueryJson,
  setSessionSignature,
  setOnboardSkipped,
} from 'actions/rootActions'
import queryString from 'query-string'
import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom'

@connect(
  store => ({
    ...store.root,
    mimicStatus: store.mimic.mimic.status,
    adminFetching: store.userAdmin.fetching,
    accountDetails: store.userLinkedAccounts.userLinkedAccounts,
    subscriptionStatus: store.plan.subscription ? store.plan.subscription.status : null,
    subscription: store.plan.subscription,
    subscriptionActive: store.plan.subscriptionActive,
    upgradeDismissed: store.plan.upgradeDismissed,
    loginFromChannel: store.root.loginFromChannel,
    onboardSkipped: store.root.onboardSkipped,
  }),
  {
    appLogin,
    inkfrogLogin,
    changeApplication,
    setTopUrl,
    updateQueryJson,
    setSessionSignature,
    setOnboardSkipped,
  }
)
class Root extends React.Component {

  checkSpecialStatus = () => {
    const { loginStatus, loginStatusMsg } = this.props
    // Check login status
    if (!loginStatus) return <Spinner />
    else if (loginStatus === 'failure') {
      return (
        <div className="plain-message pt3">
          <title>{'Payhelm Analytics & Intelligence'}</title>
          <h4>{loginStatusMsg}</h4>
        </div>
      )
    }
    else if (getCookie('waiting_for_close')) {
      if (window !== window.top) {
        // original access from iframe
        setTimeout(() => {
          setCookie('waiting_for_close', '')
          setCookie('pending_success_message', '')
        }, 3000)
        return null
      }
      // pop-up window from iframe to link an account, need to close it to avoid non-iframe access
      const successMessage = getCookie('pending_success_message')
      window.onbeforeunload = () => {
        setCookie('waiting_for_close', '')
        setCookie('pending_success_message', '')
      }
      return (
        <div className="plain-message pt3">
          <title>{'Payhelm Analytics & Intelligence'}</title>
          <h4>
            <span className='fa fa-check-circle' style={{ color: '#04a778' }}/>&nbsp;
            {
              successMessage || `Your account is linked successfully. Please close the tab.`
            }
          </h4>
        </div>
      )
    }

    return null
  }

  componentDidUpdate(prevProps) {
    if (prevProps.applicationId !== this.props.applicationId || (!prevProps.user.email && this.props.user.email) ) {
      sendGtmPixel({ page: this.props.applicationId, email: this.props.user.email }, 'pageview')
    }
  }

  componentDidMount() {
    const { appLogin, inkfrogLogin, updateQueryJson, changeApplication, setSessionSignature } = this.props
    const { signature } = qs.parse(location.search)
    if (signature) {
      setSessionSignature(signature)
    }
    // Set top url
    const auth_referer = getCookie('auth_referer')
    let topUrl =
      window.self === window.top
        ? `${window.location.protocol}//${window.location.host}`
        : auth_referer || document.referrer
    this.props.setTopUrl(topUrl)
    // Login
    const query = queryString.parse(location.search)
    const { thirdparty } = query
    if (thirdparty === 'inkfrog') {
      inkfrogLogin()
    } else {
      appLogin()
    }
    // Popstate callback
    window.addEventListener('popstate', () => {
      const nextApplicationId = location.pathname.split('/').filter(t => t && (t !== '/'))[0]
      if (nextApplicationId !== this.props.applicationId) {
        changeApplication(this.props.applicationId, nextApplicationId)
      }
      updateQueryJson()
    })
  }

  render() {
    const {
      thirdParty,
      admin,
      accounts,
      accountDetails,
      subscriptionActive,
      upgradeDismissed,
      applicationId,
      mimicStatus,
      sessionExpired,
      reportTimeCost,
      adminFetching,
      user,
      loginFromChannel,
      onboardSkipped,
      setOnboardSkipped,
      subscription,
    } = this.props

    let hasBigcommerce = accounts.find(account => (account.channel === "Bigcommerce"))
    const hasAccounts = accountDetails.data.details.is_inkfrog
      ? !!accountDetails.data.linked_accounts.length
      : !!accounts.filter(account => account.channel && account.account_name).length
    const specialStatus = this.checkSpecialStatus()
    const accessPermissions = _.chain(user?.access_permission).keyBy('id').mapValues('checked').value();
    const isLite = (subscription?.plans || [])[0]?.lite

    if (sessionExpired) {
      return (
        <Router>
          <div className="p4 center">
            <h4>Your PayHelm session has expired. Please re-login or refresh the page.</h4>
            <Switch><Route path="/error" component={null} /><Redirect to="/error" /></Switch>
          </div>
        </Router>
      )
    }
    if (specialStatus) return specialStatus
    if (thirdParty) return <InkfrogRouter admin={admin} />
    return (
      <AppRouter
        applicationId={applicationId}
        hasAccounts={hasAccounts}
        hasBigcommerce={hasBigcommerce}
        subscriptionActive={subscriptionActive}
        upgradeDismissed={upgradeDismissed}
        mimicStatus={mimicStatus}
        reportTimeCost={reportTimeCost}
        adminFetching={adminFetching}
        accessPermissions={accessPermissions}
        loginFromChannel={loginFromChannel}
        setOnboardSkipped={setOnboardSkipped}
        onboardSkipped={onboardSkipped}
        isLite={isLite}
      />
    )
  }
}

export default Root
