import * as React from 'react'
import moment from 'moment'
import _ from 'lodash'
import { Checkbox } from '@/features/shadcn/ui/checkbox'
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/features/shadcn/ui/tooltip'
import { Button } from '@/features/shadcn/ui/button'
import { ArrowUpDown, MoreHorizontal } from 'lucide-react'
import { metricToTotalKey } from './components/OverviewTab'
import GoogleAdsLogo from '../../assets/images/logos/google_ads.svg'
import FacebookAdsLogo from '../../assets/images/logos/facebook_ads.svg'
import KlaviyoLogo from '../../assets/images/logos/klaviyo.svg'

export const buildGaqlQuery = ({ metrics, timePeriod, groupBy, limit }) => {
  const selectClause = metrics.join(',')
  const dateRangeClause = timePeriodToGaqlDateRangeClause(timePeriod)
  const whereClause = groupBy === 'campaign' ? `campaign.status != 'REMOVED' AND ` : ''
  return `SELECT ${selectClause} FROM ${groupBy} WHERE ${whereClause}${dateRangeClause}${
    limit ? ` LIMIT ${limit}` : ''
  }`
}

export const msToTime = (ms, timeUnit = 'seconds') => {
  if (timeUnit === 'seconds') return ms / 1000
  if (timeUnit === 'minutes') return ms / (1000 * 60)
  if (timeUnit === 'hours') return ms / (1000 * 60 * 60)
  if (timeUnit === 'days') return ms / (1000 * 60 * 60 * 24)
}

export const getMetricTableColumns = ({ metricData, timePeriod, timezone, groupBy }) => {
  // Columns are time units - e.g. may-1, may-2, etc. for the timePeriod
  let timeUnit = 'days' // 'days' | 'hours' | 'minutes'

  if (_.isEmpty(metricData)) return []

  // Group by groupBy
  const groupedMetricData = _.groupBy(metricData, groupBy)
  // console.log('groupedMetricData', groupedMetricData)

  // Within each group, merge same time periods (this could happen because we query multiple domains for example)
  const groupByKeys = _.keys(groupedMetricData)
  const mergedGroupedMetricData = groupByKeys.map((key) => {
    const group = groupedMetricData[key]
    const mergedGroup = _.chain(group)
      .groupBy('date_trunc')
      .mapValues((v) => _.merge({}, ...v))
      .values()
      .value()
    return mergedGroup
  })
  // console.log('mergedGroupedMetricData', mergedGroupedMetricData)

  if (timePeriod === 'today') {
    timeUnit = 'hours'
  } else {
    const timePeriodPair = timePeriod.split('-')
    timeUnit = timePeriodPair[1]
  }

  const uniqueTimeIntervals = _.uniqBy(metricData, 'date_trunc')
  const sortedTimeIntervalRange = _.chain(uniqueTimeIntervals)
    .sortBy(['date_trunc'])
    .map(({ date_trunc }) => {
      return moment(date_trunc).tz(timezone)
    })
    .value()
  // console.log('sortedTimeIntervalRange', sortedTimeIntervalRange)

  const columns = sortedTimeIntervalRange.map((timeInterval, i) => {
    const accessorKeyMoment = timeInterval
    const accessorKeyTimeFormats = {
      days: 'MMM-D',
      hours: 'MMM-D-HH',
      minutes: 'MMM-D-HH:mm',
    }
    const accessorKey = accessorKeyMoment.tz(timezone).format(accessorKeyTimeFormats[timeUnit]).toLowerCase()

    const timePeriodAgo = moment(_.last(sortedTimeIntervalRange)).diff(timeInterval)
    // console.log('timePeriodAgo', timePeriodAgo)

    const headerTimeFormats = {
      days: 'MMM D',
      hours: `${msToTime(timePeriodAgo, 'hours')} hrs ago`,
      minutes: `${msToTime(timePeriodAgo, 'minutes')} mins ago`,
    }

    let header
    if (timePeriod === 'today') {
      header = accessorKeyMoment.tz(timezone).format('h a')
    } else if (timeUnit === 'days') {
      header = accessorKeyMoment.tz(timezone).format(headerTimeFormats[timeUnit])
    } else {
      header = headerTimeFormats[timeUnit]
    }

    return {
      header,
      accessorKey,
      cell: ({ row }) => {
        const value = row.getValue(accessorKey)
        return <div className="tw-max-w-48 tw-text-right">{value?.toLocaleString()}</div>
      },
      meta: { date: accessorKeyMoment.tz(timezone).format('YYYY-MM-DD HH:mm') },
    }
  })

  // Prepend groupBy column (and total column)
  return groupBy
    ? [
        {
          header: ({ table }) => {
            return (
              <div className="tw-max-w-48 tw-text-right tw-flex tw-items-center tw-space-x-2">
                <Checkbox
                  checked={table.getIsAllPageRowsSelected() || (table.getIsSomePageRowsSelected() && 'indeterminate')}
                  onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
                  aria-label="Select all"
                />
                <div>Segment</div>
              </div>
            )
          },
          accessorKey: 'groupBy',
          cell: ({ row, column }) => {
            const segmentValue = row.getValue('groupBy')
            const color = stringToColor(segmentValue)
            return (
              <div className="tw-max-w-48 tw-flex tw-items-center tw-space-x-2">
                <Checkbox
                  className="tw-rounded-md !tw-border-none"
                  style={{ backgroundColor: color }}
                  checked={row.getIsSelected()}
                  onCheckedChange={(value) => row.toggleSelected(!!value)}
                  aria-label="Select row"
                />
                <TooltipProvider>
                  <Tooltip>
                    <TooltipTrigger asChild>
                      <div className="tw-truncate">{segmentValue}</div>
                    </TooltipTrigger>
                    <TooltipContent>
                      <div>{segmentValue}</div>
                    </TooltipContent>
                  </Tooltip>
                </TooltipProvider>
              </div>
            )
          },
        },
        {
          header: ({ column }) => (
            <div
              className="tw-flex tw-items-center tw-justify-end"
              onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
            >
              Total
              <div className="tw-ml-2 tw-h-5 tw-w-5 tw-flex tw-items-center tw-justify-center tw-cursor-pointer tw-rounded-md hover:tw-bg-slate-200">
                <ArrowUpDown size={14} />
              </div>
            </div>
          ),
          accessorKey: 'groupTotal',
          cell: ({ row, column }) => {
            const segmentValue = row.getValue('groupTotal')
            return <div className="tw-max-w-48 tw-text-right">{segmentValue?.toLocaleString()}</div>
          },
        },
        ...columns,
      ]
    : columns
}

// Table data with formatted values
export const getMetricTableRows = (tableRawData) => {
  return tableRawData.map((row) => {
    return _.chain(row).toPairs().fromPairs().value()
  })
}

export const getMetricTableRawData = ({ metricData, metric, timePeriod, timezone, tableColumns, groupBy }) => {
  // First group by group-by
  const rows = groupBy ? _.chain(metricData).groupBy(groupBy).values().value() : [metricData]

  // Must group again since multiple domains will create duplicate time periods within each row
  const mergedRows = rows.map((group) => {
    return _.chain(group)
      .groupBy('date_trunc')
      .map((_groupedRows) => {
        return _groupedRows.reduce((acc, row) => {
          const totalKey = metricToTotalKey[metric]
          return {
            ...acc,
            ...row,
            [totalKey]: parseInt(acc[totalKey] || 0) + parseInt(row[totalKey] || 0),
          }
        }, {})
      })
      .flatten()
      .value()
  })

  // Then group by time
  const timeGroupedRows = timeGroupRows({ rows: mergedRows, metric, timePeriod, timezone, groupBy, tableColumns })

  return timeGroupedRows
}

export const timeGroupRows = ({ rows, metric, timePeriod, timezone, groupBy, tableColumns }) => {
  let timeUnit = 'days' // 'days' | 'hours' | 'minutes'

  if (timePeriod === 'today') {
    timeUnit = 'hours'
  } else {
    const timePeriodPair = timePeriod.split('-')
    timeUnit = timePeriodPair[1]
  }

  const timeGroupFormats = {
    days: 'MMM-D',
    hours: 'MMM-D-HH',
    minutes: 'MMM-D-HH:mm',
  }

  const timeGroupedRows = rows
    .map((cols) => {
      // Filter out time periods that are not in the columns (if we don't, `groupTotal` can be wrong because rows include columns that are not in the table columns)
      const filteredCols = cols.filter((col) => {
        const lastDate = moment(col.date_trunc)
        const timeGroup = lastDate.tz(timezone).format(timeGroupFormats[timeUnit]).toLowerCase()
        const tableColumnAccessorKeys = tableColumns.map((tableCol) => tableCol?.accessorKey)
        return tableColumnAccessorKeys.includes(timeGroup)
      })
      // console.log('filteredCols', filteredCols)

      const groupTotalValue = filteredCols.reduce((acc, col) => acc + parseInt(col[metricToTotalKey[metric]]), 0)
      // console.log('groupTotalValue', groupTotalValue)

      return filteredCols.reduce((acc, col) => {
        const { date_trunc } = col

        // MUTATION!!!
        // `groupByValue` is `undefined` if no groupBy selected; it's `null` if the groupBy is selected, but the value is `null`
        let groupByValue
        if (groupBy === undefined) {
          groupByValue = undefined
        } else {
          groupByValue = col[groupBy]
          if (groupByValue === null) {
            groupByValue = 'Unknown'
          }
        }

        const metricTotal = col[metricToTotalKey[metric]]

        const lastDate = moment(date_trunc)
        const timeGroup = lastDate.tz(timezone).format(timeGroupFormats[timeUnit]).toLowerCase()

        return {
          ...acc,
          color: stringToColor(groupByValue),
          ...(groupByValue ? { groupBy: groupByValue } : {}),
          groupTotal: groupTotalValue,
          [timeGroup]: parseInt(metricTotal),
        }
      }, {})
    })
    .filter((row) => {
      // Filter out rows that have no data
      return !_.isEmpty(row)
    })

  // console.log('timeGroupedRows', timeGroupedRows)

  return timeGroupedRows
}

function hashCode(str) {
  let hash = 0
  for (var i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash)
  }
  return hash
}

const stringToColor = (str) => {
  if (!str) return '#2563eb'
  return `hsl(${hashCode(str) % 360}, 100%, 50%)`
}

export const getFiltersFromQs = (qs) => {
  const urlFilters = qs.filters ? JSON.parse(qs.filters) : [{ field: '', op: 'eq', value: '' }]
  return urlFilters.map((f) => _.omit(f, ['op']))
}

export const getTrafficReportTableColumns = (columns) => {
  return columns.map(({ name, title }) => {
    return {
      header: () => (
        <div className="!tw-max-w-[240px] tw-whitespace-pre tw-text-left">
          <div>{title}</div>
        </div>
      ),
      accessorKey: name,
      cell: ({ row }) => {
        const value = row.getValue(name)
        return (
          <div className="!tw-max-w-[240px]">
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger asChild>
                  <div className="tw-truncate tw-whitespace-pre">{value}</div>
                </TooltipTrigger>
                <TooltipContent>
                  <div className="tw-whitespace-pre">{value}</div>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          </div>
        )
      },
    }
  })
}

export const logoDict = {
  'google_ads': {
    path: GoogleAdsLogo,
    size: 'tw-w-6 tw-h-6 tw-mr-2',
    title: true
  },
  'facebook_ads': {
    path: FacebookAdsLogo,
    size: 'tw-w-32 tw-h-9 tw-mr-2',
    title: false
  },
  'klaviyo': {
    path: KlaviyoLogo,
    size: 'tw-w-32 tw-h-8 tw-mr-2',
    title: false
  }
}

export const getOwnerId = (user, teamAccounts) => {
  if (!user || !teamAccounts) return null
  if (teamAccounts.length === 0) return user.id

  // i.e. First person ever invited must be invited by the owner user
  const sortedTeamAccounts = _.sortBy(teamAccounts, ['created_time'])
  return sortedTeamAccounts[0].owner_id
}