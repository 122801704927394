import React from 'react'
import Intercom from 'react-intercom'
import queryString from 'query-string'
import { connect } from "react-redux"
import { getCookie } from 'cookie'
import { setDoneAction } from 'actions/intercomActions'

@connect((store) => {
	return {
    ...store.intercom,
    accounts: store.root.accounts,
    user: store.root.user,
    applicationId: store.root.applicationId,
    view: queryString.parse(location.search).view,
    ...(store.plan.subscription ? {
      plan: (store.plan.subscription.plans || [])[0] ,
      subscription_status: store.plan.subscription.status,
      trial_end: store.plan.subscription.trial_end,
    } : {}),
    monthlySales: store.plan.monthlySales,
	}
}, { setDoneAction })

export default class IntercomCurl extends React.Component {
  state = {
    mimic: true,
    pendingUpdate: false
  }

  /**
   * Get intercom settings from props
   * @param {Object} nextProps nextProps while updating
   */
  propsToSettings = (nextProps) => {
    const { 
      accounts = [], user, applicationId, view, lastLoginDate, 
      plan, subscription_status, trial_end, monthlySales, doneAction, 
      upgradeModalOpen, upgradeDismissed,
    } = (nextProps || this.props)
    const accountWithName = accounts.filter(account => account.given_name && account.family_name)[0]
    const name = accountWithName ? `${accountWithName.given_name} ${accountWithName.family_name}` : ''
    const currentPlan = plan ? `${plan.product} ${plan.nickname}` : null
    const trialEnd = trial_end ? (new Date(trial_end)).toString() : null
    const settings = {
      name,
      email: user.owner || user.email || (accounts[0] || {}).email,
      user_id: user.id,
      merchant_id: accounts.map(account => account.merchant_id).join('|'),
      last_visited_app: applicationId,
      last_visited_view: view || 'default',
      last_login_date: lastLoginDate,
      [`${applicationId}_visited`]: true,
      current_plan: currentPlan,
      subscription_status: subscription_status || null,
      trial_end: trialEnd,
      monthly_sales: monthlySales,
      upgrade_modal_open: upgradeModalOpen,
      upgrade_dismissed: upgradeDismissed,
      ...this.actionProps(doneAction),
      ...this.companyProps(accounts, currentPlan, trialEnd, monthlySales, user),
    }
    return settings
  }

  /**
   * Convert linked accounts to company props in intercom settings
   * @param {Array} accounts linked accounts from API
   */
  companyProps = (accounts, currentPlan, trialEnd, monthlySales, user) => {
    if (!accounts.length) {
      return null
    }
    const companyId = accounts[0].owner_id
    const companyName = accounts.map(a => a.merchant_id).join('|')
    const companyWebsites = accounts.map(a => a.website).filter(v => v).join('|')
    const company = {
      company: {
        id: companyId,
        name: companyName,
        plan: currentPlan,
        trial_end: trialEnd,
        monthly_sales: monthlySales,
        website: companyWebsites,
      }
    }
    if (user.billing && user.billing.id) {
      company.company.stripe_id = user.billing.id
    }
    return company
  }

  /**
   * Convert done action object to action props in intercom settings
   * @param {Object} doneAction done action dispatched by different apps
   */
  actionProps = (doneAction) => {
    if (!window.intercomSettings || !doneAction) return {}
    console.log('doneAction', doneAction)
    if (doneAction) {
      switch(doneAction.type) {
        case 'new_plan':
          return {
            last_plan_action: `${doneAction.oldPlan?.product || 'None'} ${doneAction.oldPlan?.interval || 'None'}|${doneAction.product} ${doneAction.interval}|${doneAction.date}`
          }
        case 'new_account':
          return {
            last_account_action: `new account|${doneAction.merchant_id}|${doneAction.platform}|${doneAction.date}`
          }
        case 'required_action':
          return {
            last_required_action: doneAction.details
          }
        case 'upgrade_dimissed':
          return {
            upgrade_dismissed: (new Date()).toISOString()
          }
        case 'add_on_upgrade_modal_seen':
          return {
            add_on_upgrade_modal_seen: doneAction.addOn
          }
        default:
          return {}
      }
    }
  }

  UNSAFE_componentWillUpdate(nextProps, nextState) {
    if (!this.state.mimic || (this.state.mimic && !nextState.mimic)) {
      if (!window.Intercom && !this.state.pendingUpdate) {
        this.setState({
          pendingUpdate: true
        })
      }
      else {
        if (this.state.pendingUpdate && window.Intercom) {
          if (!nextState.mimic) {
            window.Intercom('update', this.propsToSettings(nextProps))
          }
          this.setState({ pendingUpdate: null })
        }
        if (nextProps.doneAction) {
          this.props.setDoneAction(null)
        }
      }
    }
  }

  componentDidMount() {
    const admin_token = getCookie('admin_token')
    const app_token = getCookie('app_token')
    if (!admin_token || (admin_token === app_token)) {
      this.setState({ mimic: false })
    }
  }

  render() {
    if ((!this.props.accounts || !this.props.accounts.length) && !this.props.user) return null
    if (this.state.mimic) return null

    return (
      <Intercom
        appID="c8oesru0"
        {...this.propsToSettings()}
      />
    )
  }

}
