import React from 'react'
import { Modal } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { selectCurrentChannel } from '../integrationsSlice'
import { useConnectionModalContent } from './hooks'

export default function ConnectionModal({ ownerId, visible, closeModal, retrieveAccounts, accounts }) {
  /**
   *
   * Handle accounts
   *
   */
  const currentChannel = useSelector(selectCurrentChannel)
  const { content } = useConnectionModalContent({ ownerId, channel: currentChannel, retrieveAccounts, accounts })

  return (
    <Modal show={visible} onHide={closeModal} size="lg">
      <Modal.Body>{content}</Modal.Body>
    </Modal>
  )
}
