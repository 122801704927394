import React from 'react'

export default function FeedConfigTable({ integration }) {
  const feedConfig = (integration?.accounts || [])[0]
  return (
    <div className="tw-rounded-lg tw-border tw-border-slate-200 tw-w-full tw-text-sm tw-shadow-sm tw-overflow-x-auto">
      <table className="tw-table-auto tw-border-collapse tw-w-full">
        <thead className="tw-border-b tw-font-medium tw-text-slate-500 tw-bg-slate-50 tw-text-left">
          <tr>
            <th className='tw-px-2 tw-py-1'>Enabled</th>
            {feedConfig.feed_url && <th className='tw-px-2 tw-py-1'>Feed URL</th>}
          </tr>
        </thead>

        <tbody>
          <tr>
            <td className='tw-px-2 tw-py-1'>Yes</td>
            {
              feedConfig.feed_url && (
                <td className='tw-px-2 tw-py-1'>{ feedConfig.feed_url}</td>
              )
            }
          </tr>
        </tbody>
      </table>
    </div>
  )
}
