import React from 'react'
import { Row, Col, Dropdown, DropdownButton } from 'react-bootstrap'
import ReactHighcharts from 'react-highcharts'
import ExportButton from 'sharedComponents/ExportButton'
import { hcFontFamily, hcDefaultColors } from 'modules/chart'
import numeral from 'numeral'
import { getGroupedDateKeys, getGroupedSeries, singleTimelineDataCsvFormatter, convertChartBoxSeriesDataForExport } from 'data'
import { axisDateFormatterGroupBy, dateFormatterGroupBy } from 'formatters'
import { getMetricDefault } from './util'
import { localStoragePayhelm } from 'modules/utils'
import moment from 'moment'
import _ from 'lodash'

const EmptyChart = ({series}) => (
  <div className="empty-chart-message">
    <div className="title">{series.title}</div>
    <div className="message">No data to display</div>
  </div>
)

export default class ChartBoxes extends React.Component {
  constructor(props) {
    super(props)
    const metricTitles = props.metricNames.map(val => val.title).concat('None')
    
    const localGroupBy = localStoragePayhelm[`${this.props.source}_group_by`]
    const groupBy = localGroupBy || props.defaultGroupBy || 'Day'
    const seriesArrayKey = getGroupedDateKeys(props.dataKey.array, groupBy, props.additionalGroupedMetrics)
    const metricSeries = getGroupedSeries(props.metrics, groupBy, seriesArrayKey, props.additionalGroupedMetrics)
    const dataForExport = convertChartBoxSeriesDataForExport({metricSeries, seriesArrayKey, groupBy, dateFormatter: dateFormatterGroupBy(groupBy)})
    const prevSeriesArrayKey = props.prevDataKey ? getGroupedDateKeys(props.prevDataKey.array, groupBy, props.additionalGroupedPrevMetrics) : null
    const prevMetricSeries = props.prevMetrics ? getGroupedSeries(props.prevMetrics, groupBy, seriesArrayKey, props.additionalGroupedPrevMetrics) : null
    let primaryMetricIndex, secondaryMetricIndex, trendKeys
    if (props.trendKeys) {
      const metricCsvKeys = props.metricNames.map(mn => mn.csvKey)
      primaryMetricIndex = metricCsvKeys.indexOf(props.trendKeys[0])
      secondaryMetricIndex = metricCsvKeys.indexOf(props.trendKeys[1])
      trendKeys = props.trendKeys
    }
    else {
      primaryMetricIndex = getMetricDefault(metricTitles, this.props.defaultPrimary, 0, `${this.props.source}_primary_metric`)
      secondaryMetricIndex = getMetricDefault(metricTitles, this.props.defaultSecondary, 1, `${this.props.source}_secondary_metric`)
    }
    const trendIndices = [ primaryMetricIndex, secondaryMetricIndex ]
    trendKeys = trendKeys || trendIndices.map(i => this.props.metricNames[i] ? this.props.metricNames[i].csvKey : null)

    this.state = {
      primaryMetricIndex,
      secondaryMetricIndex,
      metricTitles,
      groupBy,
      trendIndices,
      trendKeys,
      metricSeries,
      seriesArrayKey,
      prevMetricSeries,
      prevSeriesArrayKey,
      focusedChartIndex: 0,
      dataForExport,
    }
  }

  changePrimaryMetric = index => {
    if (index !== this.state.primaryMetricIndex) {
      const { metricTitles } = this.state
      localStoragePayhelm[`${this.props.source}_primary_metric`] = metricTitles[index]
      this.setState({
        primaryMetricIndex: index
      })
    }
  }

  changeSecondaryMetric = index => {
    if (index !== this.state.secondaryMetricIndex) {
      const { metricTitles } = this.state
      localStoragePayhelm[`${this.props.source}_secondary_metric`] = metricTitles[index]
      this.setState({
        secondaryMetricIndex: index
      })
    }
  }

  changeGroupBy = groupBy => {
    if (groupBy === this.state.groupBy) return
    this.setState({ groupBy })
    const dataFetchNeeded = !!(this.props.setGroupByCallback && this.props.setGroupByCallback(groupBy))
    const seriesArrayKey = getGroupedDateKeys(this.props.dataKey.array, groupBy, this.props.additionalGroupedMetrics)
    const metricSeries = getGroupedSeries(this.props.metrics, groupBy, seriesArrayKey, this.props.additionalGroupedMetrics)
    const dataForExport = dataFetchNeeded ? this.state.dataForExport :
      convertChartBoxSeriesDataForExport({metricSeries, seriesArrayKey, groupBy, dateFormatter: dateFormatterGroupBy(groupBy)})
    const prevSeriesArrayKey = this.props.prevDataKey ? getGroupedDateKeys(this.props.prevDataKey.array, groupBy, this.props.additionalGroupedPrevMetrics) : null
    const prevMetricSeries = this.props.prevMetrics ? getGroupedSeries(this.props.prevMetrics, groupBy, prevSeriesArrayKey, this.props.additionalGroupedPrevMetrics) : null
    localStoragePayhelm[`${this.props.source}_group_by`] = groupBy
    this.setState({
      metricSeries,
      seriesArrayKey,
      prevMetricSeries,
      prevSeriesArrayKey,
      dataForExport,
    })
    if (this.props.updateDataForExport && this.state.dataForExport) {
      this.props.updateDataForExport(dataForExport)
    }
  }

  changeTrendKey = (primary, secondary) => {
    const trendIndices = [primary, secondary]
    const groupBy = this.state.groupBy
    const trendKeys = trendIndices.map(i => this.props.metricNames[i] ? this.props.metricNames[i].csvKey : null)
    const newTrendKeys = [trendKeys[0], trendKeys[1]]
    this.setState({ newTrendKeys, trendIndices })
    localStoragePayhelm[`${this.props.source}_primary_metric_key`] = trendKeys[0]
    localStoragePayhelm[`${this.props.source}_secondary_metric_key`] = trendKeys[1]
    const dataFetchNeeded = this.props.setTrendKeyCallback && this.props.setTrendKeyCallback(trendKeys)
    const seriesArrayKey = getGroupedDateKeys(this.props.dataKey.array, groupBy, this.props.additionalGroupedMetrics)
    const metricSeries = getGroupedSeries(this.props.metrics, groupBy, seriesArrayKey, this.props.additionalGroupedMetrics)
    const dataForExport = dataFetchNeeded ? this.state.dataForExport :
      convertChartBoxSeriesDataForExport({metricSeries, seriesArrayKey, groupBy, dateFormatter: dateFormatterGroupBy(groupBy)})
    const prevSeriesArrayKey = this.props.prevDataKey ? getGroupedDateKeys(this.props.prevDataKey.array, groupBy, this.props.additionalGroupedPrevMetrics) : null
    const prevMetricSeries = this.props.prevMetrics ? getGroupedSeries(this.props.prevMetrics, groupBy, prevSeriesArrayKey, this.props.additionalGroupedPrevMetrics) : null
    this.setState({
      metricSeries,
      seriesArrayKey,
      prevMetricSeries,
      prevSeriesArrayKey,
      dataForExport,
    })
    if (this.props.updateDataForExport && this.state.dataForExport) {
      this.props.updateDataForExport(dataForExport)
    }
  }

  getChartConfig = (type, dataKey, statusIndex, periodCompare) => {
    switch(type) {
      case 'timeline_series':
        return this.getTimeSeriesConfig(dataKey, statusIndex, periodCompare)
      default:
       return null
    }
  }

  getTimeSeriesConfig = (dataKey, statusIndex, periodCompare) => {
    const { linkedMetrics, currency, isCurrency } = this.props
    const { primaryMetricIndex, secondaryMetricIndex, metricTitles, metricSeries, seriesArrayKey, groupBy, prevSeriesArrayKey, prevMetricSeries } = this.state
    const series = metricSeries[statusIndex], prevSeries = (prevMetricSeries || [])[statusIndex] || { array: [] }
    const primaryMetricTitle = metricTitles[primaryMetricIndex]
    const secondaryMetricTitle = metricTitles[secondaryMetricIndex]
    const titleText = series.title || (
      (primaryMetricTitle !== 'None' && secondaryMetricTitle !== 'None') ? `${primaryMetricTitle} vs ${secondaryMetricTitle}` :
      (secondaryMetricTitle === 'None' ? primaryMetricTitle : secondaryMetricTitle)
    )
    let yAxis = [], displaySeries = [], tooltipCheckpoint = 1, primaryMetricSeries, secondaryMetricSeries
    let displayPrevSeries = [], primaryPrevMetricSeries, secondaryPrevMetricSeries
    if (primaryMetricTitle !== 'None') {
      primaryMetricSeries = series.array[0].data ? series.array[0] : { data: [] }
      primaryPrevMetricSeries = prevSeries.array[0] && prevSeries.array[0].data ? prevSeries.array[0] : { data: [] }
      const primaryMetricMin = Math.min.apply(null, primaryMetricSeries.data.concat(primaryPrevMetricSeries.data))
      const primaryMetricMax = Math.max.apply(null, primaryMetricSeries.data.concat(primaryPrevMetricSeries.data))
      const primaryMetricDiff = primaryMetricMax - primaryMetricMin
      const primaryZeroAsMin = primaryMetricMin >= 0
      const primaryZeroAsMax = primaryMetricMax <= 0
      yAxis.push({
        title: { text: primaryMetricTitle },
        labels: {
          formatter: function() {
            // Formatter has higher priority
            if (primaryMetricSeries.axisFormatter) {
              return (Math.abs(this.value) >= 10000 ? primaryMetricSeries.axisFormatterBig : primaryMetricSeries.axisFormatter)(this.value)
            }
            else {
              return `${(isCurrency && currency) ? (currency + ' ') : ''}${numeral(this.value).format(Math.abs(this.value) >= 10000 ? primaryMetricSeries.axis_format_big : primaryMetricSeries.axis_format)}`
            }
          }
        },
        min: Math.max((primaryMetricMin - primaryMetricDiff * 0.1), primaryZeroAsMin ? 0 : -Infinity),
        max: Math.min((primaryMetricMax + primaryMetricDiff * 0.1), primaryZeroAsMax ? 0 : Infinity),
      })
      displaySeries.push(Object.assign({}, primaryMetricSeries, { yAxis: 0, xAxis: 0 }))
      if (prevMetricSeries) {
        displayPrevSeries.push(Object.assign({}, primaryPrevMetricSeries, { yAxis: 0, xAxis: 0 }))
      }
    }
    else {
      tooltipCheckpoint = 0
    }

    if (secondaryMetricTitle !== 'None' && series.array[1]) {
      secondaryMetricSeries = series.array[1].data ? series.array[1] : { data: [] }
      secondaryPrevMetricSeries = prevSeries.array[1] && prevSeries.array[1].data ? prevSeries.array[1] : { data: [] }
      const secondaryMetricMin = Math.min.apply(null, secondaryMetricSeries.data.concat(secondaryPrevMetricSeries.data))
      const secondaryMetricMax = Math.max.apply(null, secondaryMetricSeries.data.concat(secondaryPrevMetricSeries.data))
      const secondaryMetricDiff = secondaryMetricMax - secondaryMetricMin
      const secondaryZeroAsMin = secondaryMetricMin >= 0
      const secondaryZeroAsMax = secondaryMetricMax <= 0
      yAxis.push({
        title: { text: secondaryMetricTitle },
        labels: {
          formatter: function() {
            // Formatter has higher priority
            if (secondaryMetricSeries.axisFormatter) {
              return (Math.abs(this.value) >= 10000 ? secondaryMetricSeries.axisFormatterBig : secondaryMetricSeries.axisFormatter)(this.value)
            }
            else {
              return `${numeral(this.value).format(Math.abs(this.value) >= 10000 ? secondaryMetricSeries.axis_format_big : secondaryMetricSeries.axis_format)}`
            }
          }
        },
        min: Math.max((secondaryMetricMin - secondaryMetricDiff * 0.1), secondaryZeroAsMin ? 0 : -Infinity),
        max: Math.min((secondaryMetricMax + secondaryMetricDiff * 0.1), secondaryZeroAsMax ? 0 : Infinity),
        opposite: true,
      })
      displaySeries.push(Object.assign({}, secondaryMetricSeries, { yAxis: primaryMetricTitle === 'None' ? 0 : 1, xAxis: 0 }))
      if (prevMetricSeries) {
        displayPrevSeries.push(Object.assign({}, secondaryPrevMetricSeries, { yAxis: primaryMetricTitle === 'None' ? 0 : 1, xAxis: 1 }))
      }
    }

    if (primaryMetricTitle !== 'None' && secondaryMetricTitle !== 'None' && linkedMetrics) {
      linkedMetrics.forEach(metrics => {
        if (metrics.indexOf(primaryMetricTitle) >= 0 && metrics.indexOf(secondaryMetricTitle) >= 0) {
          let mergedSeries = primaryMetricSeries.data.concat(secondaryMetricSeries.data)
          let mergedPrevSeries = prevMetricSeries ? primaryPrevMetricSeries.data.concat(secondaryPrevMetricSeries.data) : []
          yAxis = yAxis.map(axis => {
            return Object.assign({}, axis, {
              min: Math.min.apply(null, [...mergedSeries, ...mergedPrevSeries]),
              max: Math.max.apply(null, [...mergedSeries, ...mergedPrevSeries]),
            })
          })
        }
      })
    }

    yAxis = yAxis.map(axis => ({
      ...axis,
      min: Math.max(axis.min, 0),
      max: Math.max(axis.max, 0),
    }))

    const axisDateFormatter = axisDateFormatterGroupBy(groupBy)
    const dateFormatter = dateFormatterGroupBy(groupBy)

    let xAxisLabels = {
      formatter: function() {
        return axisDateFormatter(this.value)
      }
    }
    const xAxis = [{
      categories: seriesArrayKey,
      labels: xAxisLabels,
    }].concat(prevSeriesArrayKey ? [{
      categories: prevSeriesArrayKey,
      labels: xAxisLabels,
      opposite: true,
    }] : [])
    const tooltipInsertDateIndexes = [0, displaySeries.length]

    return {
      neverReflow: true,
      chart: {
        type: this.props.chartType || 'line',
        height: this.props.height || 200,
        style: {
          fontFamily: hcFontFamily,
        },
      },
      colors: hcDefaultColors,
      legend: {
        enabled: this.props.legend,
        labelFormatter: function () {
          return `${this.name}${(prevMetricSeries && this.index >= displaySeries.length) ? ' (prev. period)' : ''}`;
        }
      },
      credits: {
        enabled: false,
      },
      title: {
        text: titleText,
        align: 'left',
      },
      xAxis,
      yAxis,
      plotOptions: {
        line: {
          marker: {
            enabled: dataKey.array.length === 1,
          }
        }
      },
      tooltip: {
        shared: true,
        formatter: function () {
          let tooltipMarkup = '', dateMarkup
          this.points.forEach((point, index) => {
            if (this.points.length) {
              let date
              if (index > 0) date = 'period compare'
              if (index > 0) {
                periodCompare === 'previous'
                  ? date = dateFormatter(moment(point.key).subtract(1, 'months'))
                  : date = dateFormatter(moment(point.key).subtract(1, 'years'))
              } else date = dateFormatter(point.key)
              dateMarkup = () => `<span style="font-size: 10px">${date}</span><br/>`
            }
            if (tooltipInsertDateIndexes.includes(index)) {
              tooltipMarkup += dateMarkup(point)
            }
            const currentMetricIndex = (index % displaySeries.length) === tooltipCheckpoint ? 1 : 0
            let valueString, value = this.points[index].y
            if (value < 0.0001) value = 0
            // Formatter has higher priority
            if (series.array[currentMetricIndex].formatter) {
              valueString = series.array[currentMetricIndex].formatter(value)
            }
            else {
              valueString = numeral(value).format(series.array[currentMetricIndex].format)
            }
            if (isCurrency && currency) {
              valueString = `${currency} ${valueString}`
            }
            tooltipMarkup +=
              `<span style="color:${this.points[index].series.color}">\u25CF</span>
              ${this.points[index].series.name}:
              <b>${valueString}</b><br/>`
          })

          return tooltipMarkup;
        }
      },
      series: [...displaySeries, ...displayPrevSeries],
    }
  }

  componentDidUpdate(prevProps) {
    const { groupBy } = this.state
    if (
      (prevProps.fetching && !this.props.fetching)
      || (prevProps.additionalGroupedMetrics && prevProps.additionalGroupedMetrics[groupBy]?.key?.array.length !== this.props.additionalGroupedMetrics[groupBy]?.key?.array.length)
    ) {
      const seriesArrayKey = getGroupedDateKeys(this.props.dataKey.array, groupBy, this.props.additionalGroupedMetrics)
      const metricSeries = getGroupedSeries(this.props.metrics, groupBy, seriesArrayKey, this.props.additionalGroupedMetrics)
      const dataForExport = convertChartBoxSeriesDataForExport({metricSeries, seriesArrayKey, groupBy, dateFormatter: dateFormatterGroupBy(groupBy)})
      const prevSeriesArrayKey = this.props.prevDataKey ? getGroupedDateKeys(this.props.prevDataKey.array, groupBy, this.props.additionalGroupedPrevMetrics) : null
      const prevMetricSeries = this.props.prevMetrics ? getGroupedSeries(this.props.prevMetrics, groupBy, prevSeriesArrayKey, this.props.additionalGroupedPrevMetrics) : null
      this.setState({ seriesArrayKey, metricSeries, prevSeriesArrayKey, prevMetricSeries, dataForExport })
      if (this.props.updateDataForExport && this.state.dataForExport) {
        this.props.updateDataForExport(dataForExport)
      }
    }
    const [prevPrimaryMetric, prevSecondaryMetric] = prevProps.trendKeys || []
    const [primaryMetric, secondaryMetric] = this.props.trendKeys || []
    if (prevPrimaryMetric !== primaryMetric || prevSecondaryMetric !== secondaryMetric) {
      const metricCsvKeys = this.props.metricNames.map(mn => mn.csvKey)
      const primaryMetricIndex = metricCsvKeys.indexOf(primaryMetric), secondaryMetricIndex = metricCsvKeys.indexOf(secondaryMetric)
      this.setState({
        primaryMetricIndex,
        secondaryMetricIndex,
      })
    }
  }

  componentDidMount() {
    if (this.props.updateDataForExport && this.state.dataForExport) {
      this.props.updateDataForExport(this.state.dataForExport)
    }
  }

  render() {
    const { cols, metricSelector, legend, groupByMenu, metrics, additionalGroupedMetrics = {}, dataKey, type, exportButtonProps, periodCompare, style = {}, disableTrendKeyMenus } = this.props
    const { metricTitles, primaryMetricIndex, secondaryMetricIndex, groupBy, seriesArrayKey, focusedChartIndex, metricSeries, dataForExport, trendIndices } = this.state
    const primaryMetricTitle = metricTitles[primaryMetricIndex]
    const secondaryMetricTitle = metricTitles[secondaryMetricIndex]

    return (
      <Row className="chart-grid" style={style}>
        {
          metricSelector ? (
            <Col className="dropdown-row col-xxs-12 metric-selector" xs={9} style={{ display: 'flex', alignItems: 'center', }}>
              <DropdownButton title={<b>{primaryMetricTitle}</b>} id="pri-metric-dropdown" disabled={disableTrendKeyMenus}>
              {
                metricTitles.map((title, index) => (
                  <Dropdown.Item
                    key={`pri-metric-title-${title}`}
                    eventKey={index} active={index === primaryMetricIndex}
                    disabled={index === secondaryMetricIndex && title !== 'None'}
                    onClick={() => {
                      this.changePrimaryMetric(index)
                      this.changeTrendKey(index, trendIndices[1])
                    }}
                  >
                    { title }
                  </Dropdown.Item>
                ))
              }
              </DropdownButton>
              <span className="metric-menu-tag">{' vs '}</span>
              <DropdownButton title={<b>{secondaryMetricTitle}</b>} id="sec-metric-dropdown" disabled={disableTrendKeyMenus}>
              {
                metricTitles.map((title, index) => (
                  <Dropdown.Item
                    key={`sec-metric-title-${title}`}
                    eventKey={index} active={index === secondaryMetricIndex}
                    disabled={index === primaryMetricIndex && title !== 'None'}
                    onClick={() => {
                      this.changeSecondaryMetric(index)
                      this.changeTrendKey(trendIndices[0], index)
                    }}
                  >
                    { title }
                  </Dropdown.Item>
                ))
              }
              </DropdownButton>
            </Col>
          ) : null
        }
        {
          groupByMenu ? (
            <Col className="dropdown-row col-xxs-12 group-by-menu dropdown-right" xs={3} style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', }}>
              <div className="dropdown btn-group hidden-lg hidden-md hidden-sm hidden-xs inline-xxs">{' '}</div>
              <span className="hidden-lg hidden-md hidden-sm hidden-xs inline-xxs metric-menu-tag">{ ' By '}</span>
              <DropdownButton title={<b>{groupBy}</b>} id="group-by-dropdown">
              {
                [...Object.keys(additionalGroupedMetrics), 'Day', 'Week', 'Month', 'Quarter'].map(val => (
                  <Dropdown.Item
                    key={`group-by-${val}`}
                    eventKey={val}
                    active={val === groupBy}
                    onClick={() => this.changeGroupBy(val)}
                  >
                    {val}
                  </Dropdown.Item>
                ))
              }
              </DropdownButton>
            </Col>
          ) : null
        }
        {
          metrics.map((series, seriesIndex, array) => {
            return (
              <Col xs={cols.xs} sm={cols.sm} md={cols.md} lg={cols.lg} key={`series-${seriesIndex}`}>
                <div
                  className={'chart-grid-mini-chart' + (legend ? ' with-legend' : '') + (array.length > 1 ? ' clickable' : '') + (focusedChartIndex === seriesIndex ? ' focused' : '')}
                  onClick={() => this.setState({ focusedChartIndex: seriesIndex })}
                >
                  {
                    (dataKey.array.length && !(primaryMetricTitle === 'None' && secondaryMetricTitle === 'None')) ?
                    <ReactHighcharts config={this.getChartConfig(type, dataKey, seriesIndex, periodCompare)} isPureConfig/> :
                    <EmptyChart series={series}/>
                  }
                </div>
              </Col>
            )
          })
        }
        {
          (this.props.canExport !== false && dataKey.array.length && !(primaryMetricTitle === 'None' && secondaryMetricTitle === 'None')) ?
          (
            <Col xs={12}>
              <ExportButton
                dataSets={dataForExport.map(i => i.data)}
                formatterSets={dataForExport.map(i => i.formatters)}
                exportKeySets={dataForExport.map(i => i.keys)}
                fileName={this.props.source + (metricSeries.length > 1 ? `_${metricSeries[focusedChartIndex].title.toLowerCase().replace(/ /g, '_')}` : '')}
                dataSetNames={dataForExport.map(i => `Report (${i.name})`)}
                {...exportButtonProps}
              />
            </Col>
          ): null
        }
      </Row>
    )
  }
}
