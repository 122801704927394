import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import { selectRootUser } from '../../../../rootSlice'
import { createUserAPIKey } from '../../settingsSlice'
import { Button } from 'react-bootstrap'
import ConfirmModal from './ConfirmModal'
import CopyToClipboardButton from '../../../../features/buttons/CopyToClipboardButton'

export default function APIKey() {
  const dispatch = useDispatch()

  /**
   *
   * Handle confirm modal
   *
   */
  const [confirmModalVisible, setConfirmModalVisible] = useState(false)
  const openConfirmModal = () => setConfirmModalVisible(true)
  const closeConfirmModal = () => setConfirmModalVisible(false)

  /**
   *
   * Handle generate API key
   *
   */
  const user = useSelector(selectRootUser)
  const [apiKey, setApiKey] = useState(null)
  const generateAPIKey = async () => {
    const { payload: api_key } = await dispatch(createUserAPIKey({ unique_user_id: user.id }))
    setApiKey(api_key)
  }

  const handleGenerateAPIKey = async () => {
    if (user?.api_key) {
      openConfirmModal()
      return
    }

    generateAPIKey()
  }

  return (
    <>
      <div className="md:tw-p-4">
        <div className="tw-mb-4">
          <h4>{user?.api_key ? 'Re-generate API Key' : 'Create API Key'}</h4>
          <div className="tw-text-slate-500">
            {user?.api_key ? 'Please note that this will overwrite the old API key and the old key will no longer be usable.' : 'Create an API key to gain access to our API.'}
          </div>
        </div>
        <div>
          {apiKey && (
            <div className="tw-mb-4">
              <div className="tw-text-red-500 tw-mb-3">
                Please store this key safely somewhere - you won't be able to see it again.
              </div>
              <div className="tw-p-4 tw-rounded-xl tw-bg-slate-100 tw-flex tw-items-center tw-space-x-2">
                <CopyToClipboardButton value={apiKey} />
                <div>{apiKey}</div>
              </div>
            </div>
          )}

          {user?.api_key_create_time && !apiKey && (
            <div className="tw-p-4 tw-rounded-xl tw-bg-slate-100 tw-mb-4 tw-text-slate-600">
              API key last generated on: {moment(user.api_key_create_time).format('MMM DD, YYYY (h:MM A)')}
            </div>
          )}

          {!apiKey && <div className="tw-mb-4">
            <Button onClick={handleGenerateAPIKey}>Generate API Key</Button>
          </div>}
        </div>
      </div>

      <ConfirmModal visible={confirmModalVisible} closeModal={closeConfirmModal} generateAPIKey={generateAPIKey} />
    </>
  )
}
