import React from 'react'
import { bool } from 'prop-types'
import { connect } from 'react-redux'

import ConditionalRoute from './Router.conditionalRoute'
import PlanModal from 'sharedComponents/PlanModal'


const PaidRoute = ({ isPaying, admin, ...props }) =>
  <ConditionalRoute
    condition={isPaying || admin}
    fallback={PlanModal}
    {...props}
  />

PaidRoute.propTypes = {
  isPaying: bool,
  admin: bool,
}

const mapStateToProps = store => ({
  isPaying: ['trialing', 'active'].includes(store.plan.subscription.status) || store.plan.billingChannel === 'thirdparty',
  admin: !!store.root.admin
})

export default connect(mapStateToProps)(PaidRoute)
