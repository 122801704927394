import React, { useState } from 'react'
import { BiPlug, BiChevronDown } from 'react-icons/bi'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlug, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import UninstallModal from './UninstallModal'

export default function ConnectButton({ onClick, channelId, uninstallEnabled, integration }) {
  const [isOpen, setIsOpen] = useState(false);
  let buttonName = !uninstallEnabled ? 'Connect' : 'Manage Connections'

  if (channelId === 'upload') buttonName = 'Upload'

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  /**
   *
   * Handle modal
   *
   */
  const [visible, setVisible] = useState(false)
  const closeModal = () => setVisible(false)
  const showModal = () => setVisible(true)

  return !uninstallEnabled ? (
    <div
      className="tw-cursor-pointer tw-rounded-md tw-border tw-border-slate-300 hover:tw-border-blue-600 hover:tw-bg-blue-600 hover:tw-text-white tw-transition-colors tw-duration-150 tw-shadow-sm tw-flex tw-items-center tw-space-x-2.5 tw-px-2.5 tw-py-1"
      onClick={onClick}
    >
      <BiPlug />
      <div>{buttonName}</div>
    </div>
  ) : (
    <div className="tw-relative tw-inline-block tw-text-left">
      <div
        className="tw-cursor-pointer tw-rounded-md tw-border tw-border-slate-300 hover:tw-border-blue-600 hover:tw-bg-blue-600 hover:tw-text-white tw-transition-colors tw-duration-150 tw-shadow-sm tw-flex tw-items-center tw-space-x-2.5 tw-px-2.5 tw-py-1"
        onClick={toggleDropdown}
      >
        <div>{buttonName}</div>
        <BiChevronDown />
      </div>
      {isOpen && (
        <div className="tw-absolute tw-right-0 tw-mt-2 tw-w-56 tw-rounded-md tw-shadow-lg tw-bg-white tw-ring-1 tw-ring-black tw-ring-opacity-5 focus:tw-outline-none">
          <div className="tw-py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
            <button
              onClick={() => {
                onClick();
                setIsOpen(false);
              }}
              className="tw-block tw-px-4 tw-py-2 tw-text-sm tw-text-gray-700 hover:tw-bg-gray-100 hover:tw-text-gray-900 tw-w-full tw-text-left"
              role="menuitem"
            >
              <FontAwesomeIcon icon={faPlug} className="tw-mr-2" />
              Connect
            </button>
            <button
              onClick={() => {
                showModal();
                setIsOpen(false);
              }}
              className="tw-block tw-px-4 tw-py-2 tw-text-sm tw-text-gray-700 hover:tw-bg-gray-100 hover:tw-text-gray-900 tw-w-full tw-text-left"
              role="menuitem"
            >
              <FontAwesomeIcon icon={faTimesCircle} className="tw-mr-2" />
              Disconnect
            </button>
          </div>
        </div>
      )}
      <UninstallModal visible={visible} closeModal={closeModal} integration={integration} />
    </div>
  )
}
