const initialState = {
  showShippingForm: false,
  shippingConfigModal: false,
  labelInformation: {},
  showToast: false,
  toastMessage: '',
  shippingFormLoading: false,
  labelButtonStatus: false,
  insuranceButtonStatus: false,
  sessionInsuredOrders: [],
  sessionInsuredOrdersInfo: {},
  errorAlert: {},
  invalidTransaction: false,
  mailingDates: [],
  mailingDate: null,
  defaultMailingDate: null,
  shippingMethod: '',
  shippingService: '',
  defaultShippingService: null,
  serviceDestination: 'DOM',
  carrierId: null,
  trackingNumber: null,
  insuranceRates: 0,
  postageCost: 0,
  insured: false,
  insuredInformation: {},
  labelRates: [],
  defaultTrackingNumber: null,
  shippingLabelInfo: {},
  sessionLabelOrders: [],
  sessionLabelOrdersInfo: {},
  fetchingLabelRates: false,
  voidModal: false,
  voidRecordedShipmentID: '',
}

export default function reducer(state = initialState, action) {
  switch(action.type) {
    case "SET_SHIPPING_TOAST_NOTIFICATION" : {
      return {
        ...state,
        showToast: action.payload,
      }
    }
    case "SET_SHIPPING_TOAST_MESSAGE" : {
      return {
        ...state,
        toastMessage: action.payload,
      }
    }
    case "UPDATE_SHIPPING_INSURED_ORDERS" : {
      return {
        ...state,
        sessionInsuredOrders: action.payload,
      }
    }
    case "UPDATE_SHIPPING_INSURED_ORDERS_INFO" : {
      return {
        ...state,
        sessionInsuredOrdersInfo: action.payload,
      }
    }
    case "UPDATE_SHIPPING_LABELED_ORDERS" : {
      return {
        ...state,
        sessionLabelOrders: action.payload,
      }
    }
    case "UPDATE_SHIPPING_LABELED_ORDERS_INFO" : {
      return {
        ...state,
        sessionLabelOrdersInfo: action.payload,
      }
    }
    case "SET_SHOW_SHIPPING_FORM" : {
      return {
        ...state,
        showShippingForm: action.payload
      }
    }
    case "SET_SHIPPING_LABEL_INFORMATION" : {
      return {
        ...state,
        labelInformation: action.payload
      }
    }
    case "SET_SHIPPING_FORM_LOADING" : {
      return {
        ...state,
        shippingFormLoading: action.payload
      }
    }
    case "SET_SHIPPING_ERROR_ALERT" : {
      return {
        ...state,
        errorAlert: action.payload
      }
    }
    case "SET_SHIPPING_INVALID_TRANSACTION" : {
      return {
        ...state,
        invalidTransaction: action.payload,
      }
    }
    case "SET_SHIPPING_LABEL_BUTTON_STATUS" : {
      return {
        ...state,
        labelButtonStatus: action.payload
      }
    }
    case "SET_SHIPPING_INSURANCE_BUTTON_STATUS" : {
      return {
        ...state,
        insuranceButtonStatus: action.payload
      }
    }
    case "SET_SHIPPING_MAILING_DATES" : {
      return {
        ...state,
        mailingDates: action.payload
      }
    }
    case "SET_SHIPPING_MAILING_DATE" : {
      return {
        ...state,
        mailingDate: action.payload
      }
    }
    case "SET_SHIPPING_DEFAULT_MAILING_DATE" : {
      return {
        ...state,
        defaultMailingDate: action.payload
      }
    }
    case "SET_SHIPPING_METHOD" : {
      return {
        ...state,
        shippingMethod: action.payload
      }
    }
    case "SET_SHIPPING_SERVICE" : {
      return {
        ...state,
        shippingService: action.payload
      }
    }
    case "SET_SHIPPING_DEFAULT_SERVICE" : {
      return {
        ...state,
        defaultShippingService: action.payload
      }
    }
    case "SET_SHIPPING_SERVICE_DESTINATION" : {
      return {
        ...state,
        serviceDestination: action.payload
      }
    }
    case "SET_SHIPPING_CARRIER_ID" : {
      return {
        ...state,
        carrierId: action.payload
      }
    }
    case "SET_SHIPPING_TRACKING_NUMBER" : {
      return {
        ...state,
        trackingNumber: action.payload
      }
    }
    case "SET_SHIPPING_INSURANCE_RATES" : {
      return {
        ...state,
        insuranceRates: action.payload
      }
    }
    case "SET_SHIPPING_POSTAGE_COST" : {
      return {
        ...state,
        postageCost: action.payload
      }
    }
    case "SET_SHIPPING_INSURED" : {
      return {
        ...state,
        insured: action.payload
      }
    }
    case "SET_SHIPPING_INSURED_INFORMATION" : {
      return {
        ...state,
        insuredInformation: action.payload
      }
    }
    case "SET_SHIPPING_LABEL_RATES" : {
      return {
        ...state,
        labelRates: action.payload
      }
    }
    case "SET_SHIPPING_DEFAULT_TRACKING_NUMBER" : {
      return {
        ...state,
        defaultTrackingNumber: action.payload
      }
    }
    case "SET_SHIPPING_LABEL_INFO" : {
      return {
        ...state,
        shippingLabelInfo: action.payload
      }
    }
    case 'SET_SHIPPING_CONFIG_MODAL': {
      return {
        ...state,
        shippingConfigModal: action.payload
      }
    }
    case 'SET_SHIPPING_FETCHING_LABEL_RATES': {
      return {
        ...state,
        fetchingLabelRates: action.payload
      }
    }
    case 'SET_VOID_MODAL': {
      return {
        ...state,
        voidModal: action.payload
      }
    }
    case 'SET_VOID_MODAL_RECORDED_SHIPMENT_ID': {
      return {
        ...state,
        voidRecordedShipmentID: action.payload
      }
    }
  }
  return state
}