
export default function reducer(state={
    admin: {
      admin: false
    },
    fetching: true,
    fetched: false,
    error: null,
  }, action) {
    switch (action.type) {
      case "FETCH_USER_ADMIN": {
        return {...state, fetching: true}
      }
      case "FETCH_USER_ADMIN_REJECTED": {
        return {...state, fetching: false, error: action.payload}
      }
      case "FETCH_USER_ADMIN_FULFILLED": {
        return {
          ...state,
          fetching: false,
          fetched: true,
          admin: action.payload,
        }
      }
    }

    return state
}