import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import MySelect from '../../../features/forms/MySelect'

export default function SideMenu({ categories, selectedCategory, setSelectedCategory }) {
  /**
   *
   * Handle mobile select box options
   *
   */
  const [categoryOptions, setCategoryOptions] = useState([])
  useEffect(() => {
    if (!categories?.ids || !categories?.entities) return

    const _categoryOptions = categories.ids.map((categoryId) => {
      const _category = categories.entities[categoryId]
      return { value: _category.id, label: _category.title }
    })
    setCategoryOptions(_categoryOptions)
  }, [categories])

  /**
   *
   * Handle form for mobile view
   *
   */
  const {
    register,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      mobileCategory: 'all',
    },
  })

  const mobileCategoryWatch = watch('mobileCategory')
  useEffect(() => {
    const _selectedCategory = categories?.entities?.[mobileCategoryWatch]
    setSelectedCategory(_selectedCategory)
  }, [mobileCategoryWatch])

  return (
    <div className="tw-p-8">
      {/* mobile view */}
      <div className="md:tw-hidden">
        <form>
          <MySelect register={register} name="mobileCategory" label="Select a category" options={categoryOptions} />
        </form>
      </div>
      {/* end: mobile view */}

      {/* desktop view */}
      <div className="tw-hidden md:tw-block">
        {categories.ids &&
          categories.ids.map((_id) => {
            const _category = categories.entities[_id]
            const isSelected = selectedCategory.id === _id
            return (
              <div
                key={_id}
                className={`tw-cursor-pointer tw-rounded-lg tw-px-3 tw-py-1.5 tw-my-2 tw-border-2 tw-border-transparent hover:tw-border-blue-600 ${
                  isSelected ? 'tw-bg-slate-100 tw-font-bold' : ''
                }`}
                onClick={() => setSelectedCategory(_category)}
              >
                {_category.title}
              </div>
            )
          })}
      </div>
      {/* end: desktop view */}
    </div>
  )
}
