export const validateDate = (value) => {
  var regEx = /^\d{4}-\d{2}-\d{2}$/
  if (value.match(regEx) === null) {
    return 'Invalid date format'
  }
}

export const checkIsValidSnakeCase = (value) => {
  if (!value) return false

  return /^[a-z_]$/.test(value[0]) && /^[a-z0-9_]+$/.test(value)
}

export const validateSnakeCase = (value) => {
  if (value && !checkIsValidSnakeCase(value)) {
    return 'Only lowercase alphaneumeric characters and underscore are allowed'
  }
}
