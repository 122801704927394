const initialState = {
  mimic: {
    token: null,
    status: false,
  },
  redirect: false,
  unmimic_redirect: false,
  fetching: false,
  fetched: false,
  unmimic_fetching: false,
  unmimic_fetched: false,
  mimic_status_fetching: false,
  mimic_status_fetched: false,
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case 'FETCH_REDIRECT': {
      return { ...state, fetching: true }
    }
    case 'FETCH_REDIRECT_REJECTED': {
      return { ...state, fetching: false, redirect: false }
    }
    case 'FETCH_REDIRECT_FULFILLED': {
      return {
        ...state,
        fetching: false,
        fetched: true,
        redirect: true,
        unmimic_fetched: false,
        unmimic_redirect: false,
        mimic: action.payload,
      }
    }
    case 'FETCH_UNMIMIC': {
      return { ...state, unmimic_fetching: true }
    }
    case 'FETCH_UNMIMIC_REJECTED': {
      return { ...state, unmimic_fetching: false, redirect: false }
    }
    case 'FETCH_UNMIMIC_FULFILLED': {
      return {
        ...state,
        fetched: false,
        unmimic_fetching: false,
        unmimic_fetched: true,
        unmimic_redirect: true,
        redirect: false,
        mimic: action.payload,
      }
    }
    case 'FETCH_MIMIC_STATUS': {
      return { ...state, mimic_status_fetching: true }
    }
    case 'FETCH_MIMIC_STATUS_REJECTED': {
      return { ...state, mimic_status_fetching: false }
    }
    case 'FETCH_MIMIC_STATUS_FULFILLED': {
      return {
        ...state,
        mimic_status_fetched: true,
        mimic: action.payload,
      }
    }
  }

  return state
}
