import React from 'react'
import { Alert, Button } from 'react-bootstrap'
import { planOptions } from 'applications/Plan/planConfig'
import NavLinkContainer from '../NavLinkContainer'

const getPlanAction = (planStatus, currentPlan, nextPlan, subscriptionId, planExpired, currentApp) => {
  if (planExpired && this.props.currentApp === 'plan') {
    return {
      targetApp: 'plan',
      targetViewIndex: 0,
      planOption: nextPlan,
      message: `You can continue to use your current plan. If you'd like to add any new features then please choose one of our new plans.`,
      ctaText: 'Make it now',
    }
  }
  return null
}

const redirectToAction = (action, setRedirectedAction, currentApp, changeApplication) => {
  changeApplication(currentApp, action.targetApp)
  setRedirectedAction(action)
}

const renderPlanAction = (
  currentPlans,
  planStatus,
  planExpired,
  subscriptionId,
  setRedirectedAction,
  currentApp,
  changeApplication,
  loginFromChannel,
) => {
  const planOptionConfigs = planOptions(loginFromChannel)
  const currentPlan = planOptionConfigs.filter(option => option.product === currentPlans[0].product)[0]
  const nextPlan = planOptionConfigs.filter(option => option.product === planStatus.nextPlan)[0]
  const planAction = getPlanAction(planStatus, currentPlan, nextPlan, subscriptionId, planExpired, currentApp)
  if (planAction) {
    return (
      <Alert>
        {planAction.message}
        &nbsp;&nbsp;
        <NavLinkContainer to="/plan" onClick={() => redirectToAction(planAction, setRedirectedAction, currentApp, changeApplication)} type="inline-block">
          <Button variant="primary" style={{marginTop: 5, marginBottom: 5}}>{planAction.ctaText}</Button>
        </NavLinkContainer>
      </Alert>
    )
  }
  return null
}

export default renderPlanAction