import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import { Button } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import Alert from 'react-bootstrap/Alert'
import { useDispatch, useSelector } from 'react-redux'
import {
  fetchShipsuranceConfigs,
  selectShipsuranceConfig,
  selectShipsuranceConfigStatus,
  updateShipsuranceConfigs
} from '../../../Settings/settingsSlice'
import MyInput from '../../../../features/forms/MyInput'
import { closeConnectionModal } from '../../integrationsSlice'

export default function ShipsuranceConnectionModalContent({ retrieveAccounts }) {
  const dispatch = useDispatch()
  const [updated, setUpdated] = useState(false)
  const [error, setError] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const closeModal = () => {
    setUpdated(false)
    return dispatch(closeConnectionModal())
  }

  /**
   *
   * Handle ShipStation config
   *
   */
  const shipsuranceConfigStatus = useSelector(selectShipsuranceConfigStatus)
  const shipsuranceConfig = useSelector(selectShipsuranceConfig)
  useEffect(() => {
    if (shipsuranceConfigStatus === 'idle') {
      dispatch(fetchShipsuranceConfigs())
    }
  }, [shipsuranceConfigStatus])

  /**
   *
   * Handle form
   *
   */
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm()

  // Set default form values
  useEffect(() => {

    if (_.isEmpty(shipsuranceConfig)) return

    setValue('partner_policy_id', shipsuranceConfig[0]['partner_policy_id'])
    setValue('shipsurance_email', shipsuranceConfig[0]['email'])
  }, [shipsuranceConfig])

  const onSubmit = async (values) => {
    // const payload = _.values(values)
    const result = await dispatch(updateShipsuranceConfigs(values))
    if (result.meta.requestStatus === 'fulfilled') {
      await retrieveAccounts()
      setUpdated(true)
      setTimeout(() => dispatch(closeConnectionModal()), '1000')
    } else if (result.meta.requestStatus === 'rejected') {
      setError(true)
      const errorMessage = result.payload?.message || 'An error occurred';
      setErrorMessage(errorMessage)
    }
    dispatch(fetchShipsuranceConfigs())
  }

  return (
    <div className="tw-p-6">
      <h2 className="tw-text-xl tw-mb-6">Connect Your Shipsurance Account</h2>
      {error && <Alert variant={'danger'}>{errorMessage}</Alert>}
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="tw-grid tw-grid-cols-1 sm:tw-grid-cols-2 lg:tw-grid-cols-4 tw-gap-4 tw-mb-6">
          <div className="tw-col-span-1">
            <MyInput
              register={register}
              name={`shipsurance_email`}
              label="Shipsurance Email"
              errors={errors}
              disabled={false}
            />
          </div>
          <div className="tw-col-span-3">
            <MyInput
              register={register}
              name={`partner_policy_id`}
              label="Partner Policy ID"
              errors={errors}
              disabled={false}
              type='password'
              icon={true}
            />
          </div>
        </div>
        <div className="tw-flex tw-items-center tw-justify-end tw-space-x-2">
          {updated && <span className="fa fa-check" style={{color: '#5cb85c'}}/>}
          <Button type="submit" disabled={isSubmitting}>
            Save Shipsurance Connection
          </Button>
          <Button variant="secondary" onClick={closeModal}>
            Cancel
          </Button>
        </div>
      </form>
    </div>
  )
}
