const initialState = {
  reportId: null,
  reports: null,
  reportStatus: null,
  sortOrder: 'update_time desc',
}

export default function reducer(state = initialState, action) {
  switch(action.type) {
    case 'SET_REPORT_ID_CUSTOM_REPORTS': {
      return {
        ...state,
        reportId: action.payload,
      }
    }
    case 'SET_REPORTS_CUSTOM_REPORTS': {
      return {
        ...state,
        reports: action.payload,
      }
    }
    case 'SET_REPORT_STATUS_CUSTOM_REPORTS': {
      return {
        ...state,
        reportStatus: action.payload,
      }
    }
    case 'SET_SORT_ORDER_CUSTOM_REPORTS': {
      return {
        ...state,
        sortOrder: action.payload,
      }
    }
  }
  return state
}
