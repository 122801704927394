import { configureStore, combineReducers } from '@reduxjs/toolkit'
import logger from 'redux-logger'
import { setupListeners } from '@reduxjs/toolkit/query'

import root from '../reducers/rootReducer'
import dashboard from '../reducers/dashboardReducer'
import subscribers from '../reducers/subscribersReducer'
import emailCampaigns from '../reducers/emailCampaignsReducer'
import datePicker from '../reducers/datePickerReducer'
import search from '../reducers/searchReducer'
import plan from '../reducers/planReducer'
import user from '../reducers/userReducer'
import userList from '../reducers/userListReducer'
import userAdmin from '../reducers/userAdminReducer'
import mimic from '../reducers/mimicReducer'
import intercom from '../reducers/intercomReducer'
import backfill from '../reducers/backFillReducer'
import userLinkedAccounts from '../reducers/userLinkedAccountsReducer'
import internalStatistics from '../reducers/internalStatisticsReducer'
import categories from '../reducers/categoriesReducer'
import customReports from '../reducers/customReportsReducer'
import csv from '../reducers/csvReducer'
import shipping from '../reducers/shippingReducer'
import sharedAccounts from '../reducers/sharedAccountsReducer'
import traffic from '../reducers/trafficReducer'
import dateFilters from '../reducers/dateFiltersReducer'
import settings from '../reducers/settingsReducer'
import reports from '../reducers/reportsReducer'
import rangeFilters from '../reducers/rangeFiltersReducer'
import keywordFilters from '../reducers/keywordFiltersReducer'
import inventory from '../reducers/inventoryReducer'
import timePicker from '../reducers/timePickerReducer'

// Slice reducers
import settingsReducer from '../applications/Settings/settingsSlice'
import integrationsReducer from '../applications/Integrations/integrationsSlice'
import trafficReportReducer from '../applications/Traffic/trafficReportSlice'
import backfillReducer from '../features/backfill/backfillSlice'
import awsSecretsManagerReducer from '../features/aws/awsSecretsManagerSlice'
import homeReducer from '../applications/Home/homeSlice'
import signupMultistepReducer from '../applications/SignupMultistep/signupMultistepSlice'
import planReducer from '../applications/Plan/planSlice'
import openaiReducer from '../features/openai/openaiSlice'
import apistoreReducer from '../features/apistore/apistoreslice'
import payhelmPixelReducer from '../features/payhelmPixel/payhelmPixelSlice'
import feedsReducer from '../features/feeds/feedsSlices'

// api services

import { googleAdsApi } from './services/googleAdsApi'

const reducers = combineReducers({
  root,
  dashboard,
  subscribers,
  emailCampaigns,
  datePicker,
  search,
  plan,
  user,
  userList,
  userAdmin,
  mimic,
  intercom,
  backfill,
  userLinkedAccounts,
  internalStatistics,
  categories,
  csv,
  customReports,
  shipping,
  sharedAccounts,
  traffic,
  dateFilters,
  settings,
  reports,
  inventory,
  timePicker,

  rangeFilters,
  keywordFilters,
  // Slice reducers
  appSettings: settingsReducer,
  planSettings: planReducer,
  // RTK reducers
  [googleAdsApi.reducerPath]: googleAdsApi.reducer,
  integrations: integrationsReducer,
  trafficReport: trafficReportReducer,
  backfillNew: backfillReducer,
  awsSecretsManager: awsSecretsManagerReducer,
  home: homeReducer,
  signupMultistep: signupMultistepReducer,
  openai: openaiReducer,
  apistore: apistoreReducer,
  payhelmPixel: payhelmPixelReducer,
  feeds: feedsReducer,
})

const middleware =
  process.env.NODE_ENV === 'development'
    ? (getDefaultMiddleware) =>
        getDefaultMiddleware({
          serializableCheck: false,
          immutableCheck: false,
        })
          // .concat(logger)
          .concat(googleAdsApi.middleware)
    : (getDefaultMiddleware) =>
        getDefaultMiddleware({
          serializableCheck: false,
          immutableCheck: false,
        })
const store = configureStore({
  reducer: reducers,
  middleware,
})

setupListeners(store.dispatch)

export default store
