const initialState = {
  url: {}
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
  case 'UPDATE_CSV_URL_OBJECT': {
    return {...state, url: action.payload}
  }
  default: {
    return state
  }
  }
}