import React, { useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import queryString from 'query-string'
import { useDispatch, useSelector } from 'react-redux'
import { useForm } from 'react-hook-form'
import { z } from 'zod'
import _ from 'lodash'
import { Form } from '@/features/shadcn/ui/form'
import { zodResolver } from '@hookform/resolvers/zod'
import Combobox from '@/features/forms/Combobox'
import { Button } from '@/features/shadcn/ui/button'
import { EmptyState, LoadingState } from '../Traffic'
import {
  fetchGoogleAdsMetrics,
  fetchGoogleAdsMetricsSummary,
  fetchGoogleAdsReporting,
  resetFetchGoogleAdsMetricsSummaryStatus,
  selectGoogleAdsAuditData,
  selectGoogleAdsMetrics,
  selectGoogleAdsMetricsStatus,
  selectGoogleAdsMetricsSummary,
  selectGoogleAdsMetricsSummaryStatus,
  selectGoogleAdsReporting,
  selectGoogleAdsReportingStatus,
  fetchFbAdsMetricsSummary,
  selectFbAdsAuditData,
  selectGbAdsMetricsSummary,
  selectGbAdsMetricsSummaryStatus,
  setCurrentTab,
  selectFbAdsMetricsSummary,
  selectFbAdsMetricsSummaryStatus,
  selectKlaviyoAdsMetricsSummary,
  selectKlaviyoAdsMetricsSummaryStatus,
  selectKlaviyoAdsAuditData,
  fetchKlaviyoAdsMetricsSummary,
} from '../trafficReportSlice'
import { AiOutlineLoading3Quarters } from 'react-icons/ai'
import AuditModal from './AuditModal'
import MetricBox from './MetricBox'
import { selectRootAccounts, selectRootUser } from '../../../rootSlice'
import { useIntegrations, useCategories } from '../../Integrations/hooks'
import { getOwnerId } from '../utils'

function AdsTab({ prevTimePeriod, timePeriodWatch, filtersWatch, location, history }) {
  const dispatch = useDispatch()

  const qs = queryString.parse(location.search)

  const FormSchema = z.object({
    adPlatform: z.string({
      required_error: 'Please select an ad platform.',
    }),
  })

  const form = useForm({
    resolver: zodResolver(FormSchema),
  })

  const adPlatformWatch = form.watch('adPlatform')

  /**
   * Get Ad Accounts and format ad platform options
   * @type {Array<{label: string, value: string}>}
   */
  const accounts = useSelector(selectRootAccounts)
  const ownerId = useSelector(selectRootUser)?.id
  const { data: integrations } = useIntegrations({ accounts, ownerId })
  const { data: categories } = useCategories({ integrationIds: integrations.ids })
  const selectedCategory = categories.entities['all']

  const selectedCategoryIntegrations =
  selectedCategory.integrationIds && selectedCategory.integrationIds.map((_id) => integrations.entities[_id]).filter(v => v)
  const connectedIntegrations =
  selectedCategoryIntegrations &&
  selectedCategoryIntegrations.filter(({ connected }) => connected)

  const acceptedAdPlatforms = ['Google', 'Facebook Ads', 'Google Ads', 'Klaviyo']

  const AdAccounts = connectedIntegrations.filter((account) => acceptedAdPlatforms.includes(account.channel))

  const adPlatformOptions = [..._.uniqBy(AdAccounts.map((account) => ({
    label: account.channel === 'Google' ? 'Google Ads' : account.channel,
    value: account.channel === 'Google' ? 'google_ads' : account.id,
  })), 'value'), {
    label: 'Summary',
    value: 'summary',
  }]

  /**
   *
   * Ads metrics data
   *
   */
  const googleAdsMetricsSummaryData = useSelector(selectGoogleAdsMetricsSummary)
  const googleAdsMetricsSummaryStatus = useSelector(selectGoogleAdsMetricsSummaryStatus)
  const googleAdsAuditData = useSelector(selectGoogleAdsAuditData)
  const fbAdsMetricsSummaryData = useSelector(selectFbAdsMetricsSummary)
  const fbAdsMetricsSummaryStatus = useSelector(selectFbAdsMetricsSummaryStatus)
  const fbAdsAuditData = useSelector(selectFbAdsAuditData)
  const klaviyoAdsMetricsSummaryData = useSelector(selectKlaviyoAdsMetricsSummary)
  const klaviyoAdsMetricsSummaryStatus = useSelector(selectKlaviyoAdsMetricsSummaryStatus)
  const klaviyoAdsAuditData = useSelector(selectKlaviyoAdsAuditData)

  const [summaryMetricsStatus, setSummaryMetricsStatus] = React.useState('idle')

  useEffect(() => {
    const fn = async () => {

      if (!adPlatformWatch) return

      const timePeriodChanged = !_.isEqual(timePeriodWatch, prevTimePeriod)
      if (!timePeriodWatch || _.isEmpty(timePeriodWatch) || !timePeriodChanged) return

      const _filters = qs.filters ? JSON.parse(qs.filters) : '' // [{ field: 'cartStatus', op: 'eq', value: 'abandoned' }]

      if (adPlatformWatch === 'google_ads') {
        if (googleAdsMetricsSummaryStatus === 'idle') {
          const _googleAdsMetricsSummaryData = await dispatch(
            fetchGoogleAdsMetricsSummary({
              timePeriod: timePeriodWatch,
              filters: _filters
            })
          ).unwrap()
        }
      }

      if (adPlatformWatch === 'facebook_ads') {
        if (fbAdsMetricsSummaryStatus === 'idle') {
          const _fbAdsMetricsSummaryData = await dispatch(
            fetchFbAdsMetricsSummary({
              timePeriod: timePeriodWatch,
              filters: _filters
            })
          ).unwrap()
        }
      }

      if (adPlatformWatch === 'klaviyo') {
        if (klaviyoAdsMetricsSummaryStatus === 'idle') {
          const _klaviyoAdsMetricsSummaryData = await dispatch(
            fetchKlaviyoAdsMetricsSummary({
              timePeriod: timePeriodWatch,
              filters: _filters
            })
          ).unwrap()
        }
      }

      if (adPlatformWatch === 'summary') {

        setSummaryMetricsStatus('loading')

        if (googleAdsMetricsSummaryStatus === 'idle' && adPlatformOptions.find(({ value }) => value === 'google_ads')) {
          try {
            const googleAdsPromise = await dispatch(
              fetchGoogleAdsMetricsSummary({
                timePeriod: timePeriodWatch,
                filters: _filters
              })
            ).unwrap();
          } catch (error) {
            console.error('Error fetching Google Ads metrics summary')
          }
        }

        if (fbAdsMetricsSummaryStatus === 'idle' && adPlatformOptions.find(({ value }) => value === 'facebook_ads')) {
          try {
            const fbAdsPromise = await dispatch(
              fetchFbAdsMetricsSummary({
                timePeriod: timePeriodWatch,
                filters: _filters
              })
            ).unwrap();
          } catch (error) {
            console.error('Error fetching Facebook Ads metrics summary')
          }
        }

        if (klaviyoAdsMetricsSummaryStatus === 'idle' && adPlatformOptions.find(({ value }) => value === 'klaviyo')) {
          try {
            const klaviyoAdsPromise = await dispatch(
              fetchKlaviyoAdsMetricsSummary({
                timePeriod: timePeriodWatch,
                filters: _filters
              }),
            ).unwrap();
          } catch (error) {
            console.error('Error fetching Klaviyo Ads metrics summary')
          }
        }

        setSummaryMetricsStatus('idle')
      }
    }
    fn()
  }, [adPlatformWatch, prevTimePeriod, timePeriodWatch])

  /**
   *
   * Set default values
   *
   */
  useEffect(() => {
    form.reset({
      // adPlatform: adPlatformOptions[0].value,
      adPlatform: 'summary',
    })
  }, [])

  const onSubmit = async (values) => {
    const _filters = filtersWatch.map((f) => ({ ...f, op: 'eq' }))
    if (values.adPlatform === 'google_ads') {
      const _googleAdsMetricsSummaryData = await dispatch(
        fetchGoogleAdsMetricsSummary({
          timePeriod: timePeriodWatch,
          filters: _filters
        })
      ).unwrap()
    }

    if (values.adPlatform === 'facebook_ads') {
      const _fbAdsMetricsSummaryData = await dispatch(
        fetchFbAdsMetricsSummary({
          timePeriod: timePeriodWatch,
          filters: _filters
        })
      ).unwrap()
    }

    if (values.adPlatform === 'klaviyo') {
      const _klaviyoAdsMetricsSummaryData = await dispatch(
        fetchKlaviyoAdsMetricsSummary({
          timePeriod: timePeriodWatch,
          filters: _filters
        }),
      ).unwrap()
    }

    if (values.adPlatform === 'summary') {

      setSummaryMetricsStatus('loading')

      if (adPlatformOptions.find(({ value }) => value === 'google_ads')) {
        const googleAdsPromise = await dispatch(
          fetchGoogleAdsMetricsSummary({
            timePeriod: timePeriodWatch,
            filters: _filters
          })
        ).unwrap();
      }

      if (adPlatformOptions.find(({ value }) => value === 'facebook_ads')) {
        const fbAdsPromise = await dispatch(
          fetchFbAdsMetricsSummary({
            timePeriod: timePeriodWatch,
            filters: _filters
          })
        ).unwrap();
      }

      if (adPlatformOptions.find(({ value }) => value === 'klaviyo')) {
        const klaviyoAdsPromise = await dispatch(
          fetchKlaviyoAdsMetricsSummary({
            timePeriod: timePeriodWatch,
            filters: _filters
          }),
        ).unwrap();
      }
      setSummaryMetricsStatus('idle');
    }
  }

  /**
   *
   * Handle audit
   *
   */
  const [auditOpen, setAuditOpen] = React.useState(false)
  const [fbAuditOpen, setfbAuditOpen] = React.useState(false)
  const [klaviyoAuditOpen, setKlaviyoAuditOpen] = React.useState(false)
  const [summaryAuditOpen, setSummaryAuditOpen] = React.useState(false)
  const handleAuditOpen = async () => {
    if (adPlatformWatch === 'google_ads') {
      setAuditOpen(true)
    }

    if (adPlatformWatch == 'facebook_ads') {
      setfbAuditOpen(true)
    }

    if (adPlatformWatch == 'klaviyo') {
      setKlaviyoAuditOpen(true)
    }

    if (adPlatformWatch == 'summary') {
      setSummaryAuditOpen(true)
    }
  }

  const tableColumns = [
    {
      accessorKey: 'campaign_payhelm',
      header: 'Campaign Data in Payhelm',
      cell: ({ row }) => {
        const value = row.getValue('campaign_payhelm')
        if (value === 'Missing') return <div className="tw-max-w-48 tw-text-left tw-text-red-500">{value}</div>
        return <div className="tw-max-w-48 tw-text-left">{value}</div>
      },
    },
    {
      accessorKey: 'campaign_google_ads',
      header: 'Campaign Data in Google Ads',
      cell: ({ row }) => {
        const value = row.getValue('campaign_google_ads')
        if (value === 'Missing') return <div className="tw-max-w-48 tw-text-left tw-text-red-500">{value}</div>
        const campaignId = value?.split('(id: ')[1].replace(')', '')
        return (
          <div className="tw-max-w-48 tw-text-left">
            <a href={`https://ads.google.com/aw/campaigns?ocid=${campaignId}`} target="_blank">
              {value}
            </a>
          </div>
        )
      },
    },
  ]
  const [tableRows, setTableRows] = React.useState([])
  const [fbtableRows, setfbTableRows] = React.useState([])
  const [klaviyoTableRows, setKlaviyoTableRows] = React.useState([])
  useEffect(() => {
    const mismatchingCampaignsInTrafficDBData =
      googleAdsAuditData?.missingCampaigns?.mismatchingCampaignsInTrafficDBData?.length > 0
        ? [
            ...googleAdsAuditData.missingCampaigns.mismatchingCampaignsInTrafficDBData.map((campaign) => {
              return {
                campaign_payhelm: `${campaign.utm_campaign} (id: ${campaign.utm_id})`,
                campaign_google_ads: 'Missing',
                // campaign_google_ads: 'Missing in connected Google Ads accounts',
              }
            }),
          ]
        : []

    const mismatchingCampaignsInGoogleAdsAPI =
      googleAdsAuditData?.missingCampaigns?.mismatchingCampaignsInGoogleAdsAPI?.length > 0
        ? [
            ...googleAdsAuditData.missingCampaigns.mismatchingCampaignsInGoogleAdsAPI.map((campaign) => {
              return {
                campaign_payhelm: 'Missing',
                // campaign_payhelm: 'Missing in Payhelm',
                campaign_google_ads: `${campaign.campaign.name} (id: ${campaign.campaign.id})`,
              }
            }),
          ]
        : []

    setTableRows([...mismatchingCampaignsInTrafficDBData, ...mismatchingCampaignsInGoogleAdsAPI])
  }, [googleAdsAuditData])

  useEffect(() => {
    const mismatchingCampaignsInTrafficDBData =
      fbAdsAuditData?.missingCampaigns?.mismatchingCampaignsInTrafficDBData?.length > 0
        ? [
            ...fbAdsAuditData.missingCampaigns.mismatchingCampaignsInTrafficDBData.map((campaign) => {
              return {
                campaign_payhelm: `${campaign.utm_campaign} (id: ${campaign.utm_id})`,
                campaign_facebook_ads: 'Missing',
                // campaign_google_ads: 'Missing in connected Google Ads accounts',
              }
            }),
          ]
        : []

    const mismatchingCampaignsInFbAdsAPI =
      fbAdsAuditData?.missingCampaigns?.mismatchingCampaignsInFbAdsAPI?.length > 0
        ? [
            ...fbAdsAuditData.missingCampaigns.mismatchingCampaignsInFbAdsAPI.map((campaign) => {
              return {
                campaign_payhelm: 'Missing',
                // campaign_payhelm: 'Missing in Payhelm',
                campaign_facebook_ads: `${campaign.name} (id: ${campaign.id}) (act_id: ${campaign.account_id})`,
              }
            }),
          ]
        : []

      setfbTableRows([...mismatchingCampaignsInTrafficDBData, ...mismatchingCampaignsInFbAdsAPI])
  }, [fbAdsAuditData])

  const fbtableColumns = [
    {
      accessorKey: 'campaign_payhelm',
      header: 'Campaign Data in Payhelm',
      cell: ({ row }) => {
        const value = row.getValue('campaign_payhelm')
        if (value === 'Missing') return <div className="tw-max-w-48 tw-text-left tw-text-red-500">{value}</div>
        return <div className="tw-max-w-48 tw-text-left">{value}</div>
      },
    },
    {
      accessorKey: 'campaign_facebook_ads',
      header: 'Campaign Data in Facebook Ads',
      cell: ({ row }) => {
        const value = row.getValue('campaign_facebook_ads')
        if (value === 'Missing') return <div className="tw-max-w-48 tw-text-left tw-text-red-500">{value}</div>
        const campaignId = value?.split('(id: ')[1].replace(')', '')
        const acctId = value?.split('(act_id: ')[1].replace(')', '')
        return (
          <div className="tw-max-w-48 tw-text-left">
            <a href={`https://www.facebook.com/adsmanager/manage/campaigns?act=${acctId}&campaign_id=${campaignId}`} target="_blank">
              {value}
            </a>
          </div>
        )
      },
    },
  ]

  useEffect(() => {
    const mismatchingCampaignsInTrafficDBData =
      klaviyoAdsAuditData?.missingCampaigns?.mismatchingCampaignsInTrafficDBData?.length > 0
        ? [
            ...klaviyoAdsAuditData.missingCampaigns.mismatchingCampaignsInTrafficDBData.map((campaign) => {
              return {
                campaign_payhelm: `${campaign.utm_campaign} (id: ${campaign.utm_id ? campaign.utm_id : 'Not found'})`,
                campaign_klaviyo: 'Missing',
                // campaign_google_ads: 'Missing in connected Google Ads accounts',
              }
            }),
          ]
        : []

    const mismatchingCampaignsInKlaviyoAdsAPI =
      klaviyoAdsAuditData?.missingCampaigns?.mismatchingCampaignsInKlaviyoAPI?.length > 0
        ? [
            ...klaviyoAdsAuditData.missingCampaigns.mismatchingCampaignsInKlaviyoAPI.map((campaign) => {
              return {
                campaign_payhelm: 'Missing',
                // campaign_payhelm: 'Missing in Payhelm',
                campaign_klaviyo: `${campaign.by === '$flow' ? '(Flow ID)' : ''} ${campaign.byId}`,
              }
            }),
          ]
        : []

      setKlaviyoTableRows([...mismatchingCampaignsInTrafficDBData, ...mismatchingCampaignsInKlaviyoAdsAPI])
  }, [klaviyoAdsAuditData])

  const klaviyoTableColumns = [
    {
      accessorKey: 'campaign_payhelm',
      header: 'Campaign Data in Payhelm',
      cell: ({ row }) => {
        const value = row.getValue('campaign_payhelm')
        if (value === 'Missing') return <div className="tw-max-w-48 tw-text-left tw-text-red-500">{value}</div>
        return <div className="tw-max-w-48 tw-text-left">{value}</div>
      },
    },
    {
      accessorKey: 'campaign_klaviyo',
      header: 'Campaign Data in Klaviyo',
      cell: ({ row }) => {
        const value = row.getValue('campaign_klaviyo')
        if (value === 'Missing') return <div className="tw-max-w-48 tw-text-left tw-text-red-500">{value}</div>
        const campaignId = value.replace('(Flow ID)', '').trim()
        return (
          <div className="tw-max-w-48 tw-text-left">
            <a href={`https://www.klaviyo.com/campaigns/${encodeURIComponent(campaignId)}`} target="_blank">
              {value}
            </a>
          </div>
        )
      },
    },
  ]

  const [auditTipOpen, setAuditTipOpen] = React.useState(false)
  const toggleAuditTip = () => setAuditTipOpen(!auditTipOpen)

  const summaryMetricsData = (googleAdsMetricsSummaryData || fbAdsMetricsSummaryData || klaviyoAdsMetricsSummaryData) ? _.groupBy([...(googleAdsMetricsSummaryData || []), ...(fbAdsMetricsSummaryData || []), ...(klaviyoAdsMetricsSummaryData || [])], 'channel') : []

  const adPlatformDataMap = {
    google_ads: {
      metricsSummaryData: googleAdsMetricsSummaryData,
      metricsSummaryStatus: googleAdsMetricsSummaryStatus,
      auditData: googleAdsAuditData,
      auditProps: {
        toggleAuditTip,
        auditTipOpen,
        auditOpen,
        setAuditOpen,
        adPlatformOptions,
        tableColumns,
        tableRows,
        adPlatformWatch,
      },
    },
    facebook_ads: {
      metricsSummaryData: fbAdsMetricsSummaryData,
      metricsSummaryStatus: fbAdsMetricsSummaryStatus,
      auditData: fbAdsAuditData,
      auditProps: {
        toggleAuditTip,
        auditTipOpen,
        auditOpen: fbAuditOpen,
        setAuditOpen: setfbAuditOpen,
        adPlatformOptions,
        tableColumns: fbtableColumns,
        tableRows: fbtableRows,
        adPlatformWatch,
      }
    },
    klaviyo: {
      metricsSummaryData: klaviyoAdsMetricsSummaryData,
      metricsSummaryStatus: klaviyoAdsMetricsSummaryStatus,
      auditData: klaviyoAdsAuditData,
      auditProps: {
        toggleAuditTip,
        auditTipOpen,
        auditOpen: klaviyoAuditOpen,
        setAuditOpen: setKlaviyoAuditOpen,
        adPlatformOptions,
        tableColumns: klaviyoTableColumns,
        tableRows: klaviyoTableRows,
        adPlatformWatch,
      },
    },
    summary: {
      metricsSummaryData: summaryMetricsData,
      metricsSummaryStatus: _.some(
        [summaryMetricsStatus, klaviyoAdsMetricsSummaryStatus, fbAdsMetricsSummaryStatus],
        status => status === 'loading'
      ) ? 'loading' : 'idle',
      auditData: {
        ...fbAdsAuditData,
        ...googleAdsAuditData,
        ...klaviyoAdsAuditData
      },
      auditProps: {
        toggleAuditTip,
        auditTipOpen,
        auditOpen: summaryAuditOpen,
        setAuditOpen: setSummaryAuditOpen,
        adPlatformOptions,
        tableColumns: {
          facebook_ads: fbtableColumns,
          google_ads: tableColumns,
          klaviyo: klaviyoTableColumns,
        },
        tableRows: {
          facebook_ads: fbtableRows,
          google_ads: tableRows,
          klaviyo: klaviyoTableRows,
        },
        adPlatformWatch,
      },
    }
  }

  const selectedAdPlatformData = adPlatformDataMap[adPlatformWatch] || {}

  const {
    metricsSummaryData,
    metricsSummaryStatus,
    auditData,
    auditProps,
  } = selectedAdPlatformData

  const showAuditMessage = ((auditData?.missingCampaigns?.mismatchingCampaignsInTrafficDBData?.length > 0 ||
    auditData?.missingCampaigns?.mismatchingCampaignsInGoogleAdsAPI?.length > 0 ||
    auditData?.missingCampaigns?.mismatchingCampaignsInFbAdsAPI?.length > 0) || auditData?.missingCampaigns?.mismatchingCampaignsInKlaviyoAPI && metricsSummaryStatus !== 'loading')

  const showIntegrationMessage = adPlatformOptions.length < 1
  const currentTab = adPlatformOptions.find(({ value }) => value === adPlatformWatch)?.label

  return (
    <>
      <div className="tw-mt-4">
        <Form {...form}>
          <form
            onSubmit={form.handleSubmit(onSubmit)}
            className="tw-w-full md:tw-w-[540px] tw-flex tw-items-center tw-space-x-4 tw-mb-6"
          >
            <div className="tw-grow tw-grid tw-gap-4 tw-grid-cols-1 md:tw-grid-cols-12">
              <div className="tw-col-span-1 md:tw-col-span-5">
                <div className="tw-w-full">
                  <Combobox
                    form={form}
                    name="adPlatform"
                    label="Ad Platform"
                    labelClassName="!tw-font-bold"
                    placeholder="Select ad platform"
                    options={adPlatformOptions}
                  />
                </div>
              </div>
              <div className="tw-col-span-1 md:tw-col-span-2">
                <Button type="submit" className="md:tw-mt-[20px] !tw-h-9">
                  Retrieve Data
                </Button>
              </div>
            </div>
          </form>
        </Form>

        {showIntegrationMessage && (
          <div className="tw-rounded-lg tw-border tw-border-red-400 tw-bg-red-50 tw-px-4 tw-py-3 tw-mb-6">
            <div className="tw-text-center tw-mt-4">
              <p className="tw-text-lg tw-font-semibold">No Ad Accounts Connected</p>
              <p className="tw-text-sm tw-text-gray-600">
                To use this tab, please go to {' '}
                <a href="/integration" className="tw-text-blue-500 tw-underline">
                  Integrations
                </a>{' '}
                and connect your ad accounts.
              </p>
            </div>
          </div>
        )}

        {showAuditMessage && (
          <div className="tw-rounded-lg tw-border tw-border-red-400 tw-bg-red-50 tw-px-4 tw-py-3 tw-mb-6">
            There's mismatching data. Please see the{' '}
            <a className="tw-cursor-pointer tw-blue-500 tw-underline" onClick={handleAuditOpen}>
              {currentTab || 'Ads'} Audit
            </a>{' '}
            for more details.
          </div>
        )}

        {/* {metricsSummaryStatus === 'loading'  (
          <div className="tw-col-span-1 md:tw-col-span-9 2xl:tw-col-span-10 tw-mb-4">
            <div className="tw-flex tw-items-center tw-space-x-1.5">
              <AiOutlineLoading3Quarters className="tw-animate-spin" />
              <div>Loading...</div>
            </div>
          </div>
        )}

        {(metricsSummaryStatus !== 'loading' && (!metricsSummaryData || _.isEmpty(metricsSummaryData))) ? (
          <div className="tw-col-span-1 md:tw-col-span-9 2xl:tw-col-span-10">
            <EmptyState />
          </div>
        ) : null}

        <MetricBox
          metricsSummaryData={metricsSummaryData}
          adPlatformOptions={adPlatformOptions}
          adPlatformWatch={adPlatformWatch}
        /> */}

          {metricsSummaryStatus === 'loading' ? (
            <div className="tw-col-span-1 md:tw-col-span-9 2xl:tw-col-span-10">
              <div className="tw-mb-4">
                <div className="tw-flex tw-items-center tw-space-x-1.5">
                  <AiOutlineLoading3Quarters className="tw-animate-spin" />
                  <div>Loading...</div>
                </div>
              </div>
            </div>
          ) : !metricsSummaryData || _.isEmpty(metricsSummaryData) ? (
            <div className="tw-col-span-1 md:tw-col-span-9 2xl:tw-col-span-10">
              <EmptyState />
            </div>
          ) : (
            <MetricBox
              metricsSummaryData={metricsSummaryData}
              adPlatformOptions={adPlatformOptions}
              adPlatformWatch={adPlatformWatch}
            />
          )}

      </div>

      {!adPlatformWatch ? null : <AuditModal {...auditProps} />}
    </>
  )
}

export default withRouter(AdsTab)
