import React from 'react'
import { Button } from '@/features/shadcn/ui/button'
import { FiChevronLeft, FiChevronRight, FiChevronsLeft, FiChevronsRight } from 'react-icons/fi'

export default function DataTablePagination({ table }) {
  const pagination = table.getState().pagination
  const totalRows = table.getRowCount()
  const from = pagination.pageIndex * pagination.pageSize + 1
  const to = (pagination.pageIndex + 1) * pagination.pageSize
  const finalTo = Math.min(to, totalRows)

  return (
    <div className="tw-flex tw-justify-center">
      <div className="tw-flex tw-items-center tw-space-x-1">
        <Button
          variant="ghost"
          className="tw-flex tw-items-center tw-justify-center tw-space-x-1 !tw-pl-3 !tw-pr-2 tw-h-9"
          onClick={() => table.firstPage()}
          disabled={!table.getCanPreviousPage()}
        >
          <FiChevronsLeft className="tw-h-4 tw-w-4" />
          <span>First</span>
        </Button>
        <Button
          variant="ghost"
          className="tw-flex tw-items-center tw-justify-center tw-space-x-1 !tw-pl-3 !tw-pr-2 tw-h-9"
          onClick={() => table.previousPage()}
          disabled={!table.getCanPreviousPage()}
        >
          <FiChevronLeft className="tw-h-4 tw-w-4" />
          <span>Prev</span>
        </Button>
        <div className="tw-shrink-0 tw-flex tw-items-center tw-justify-center tw-text-sm tw-font-medium tw-space-x-2">
          {from}-{finalTo} of {totalRows.toLocaleString()}
        </div>
        <Button
          variant="ghost"
          className="tw-flex tw-items-center tw-justify-center tw-space-x-1 !tw-pl-3 !tw-pr-2 tw-h-9"
          onClick={() => table.nextPage()}
          disabled={!table.getCanNextPage()}
        >
          <span>Next</span>
          <FiChevronRight className="tw-h-4 tw-w-4" />
        </Button>
        <Button
          variant="ghost"
          className="tw-flex tw-items-center tw-justify-center tw-space-x-1 !tw-pl-3 !tw-pr-2 tw-h-9"
          onClick={() => table.lastPage()}
          disabled={!table.getCanNextPage()}
        >
          <span>Last</span>
          <FiChevronsRight className="tw-h-4 tw-w-4" />
        </Button>
      </div>
    </div>
  )
}
