import React from 'react'

export default function BottomMenuButton({
  type = 'button',
  prefixIcon,
  suffixIcon,
  kind = 'default',
  onClick = () => {},
  children,
}) {
  const kindToClass = {
    default: 'tw-border tw-border-blue-600 tw-bg-blue-600 hover:tw-bg-blue-800 hover:tw-border-blue-800 tw-text-white',
    outline:
      'tw-border tw-border-blue-600 hover:tw-bg-blue-800 hover:tw-border-blue-800 tw-text-blue-600 hover:tw-text-white',
  }
  const kindClassName = kindToClass[kind]

  return (
    <button
      type={type}
      className={`tw-cursor-pointer tw-rounded-md ${kindClassName} tw-transition-colors tw-duration-150 tw-shadow-sm tw-flex tw-items-center tw-space-x-2.5 tw-px-3 tw-py-2`}
      onClick={onClick}
    >
      <div className="tw-flex tw-items-center tw-space-x-2">
        {prefixIcon && <div>{prefixIcon}</div>}
        <div>{children}</div>
        {suffixIcon && <div>{suffixIcon}</div>}
      </div>
    </button>
  )
}
