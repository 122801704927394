import React from 'react'
import { Modal, Button, FormControl, InputGroup } from 'react-bootstrap'
import { linkAccount } from 'account'
import { connect } from 'react-redux'
import { setLinkModalOpenFor } from 'actions/rootActions'

const merchantIdMatch = {
  Shopify: merchantId => merchantId.match(/([a-zA-Z0-9\-]+)/),
  WooCommerce: merchantId => merchantId.match(/([a-zA-Z0-9\-]+)/)
}

const merchantIdPrefix = {
  Shopify: 'http://',
  WooCommerce: 'https://'
}

const merchantIdSuffix = {
  Shopify: '.myshopify.com'
}

const merchantIdConstructor = {
  Shopify: (merchantId, prefix, suffix) => `${merchantId}${suffix}`,
  WooCommerce: (merchantId, prefix, suffix) => `${merchantId}`
}

@connect((store) => ({
  topUrl: store.root.topUrl,
  channel: store.root.linkModalOpenFor,
  channelModal: store.root.channelModal,
  user: store.root.user,
}), { setLinkModalOpenFor })

export default class LinkAccountModal extends React.Component {

  state = {
    merchantId: '',
    merchantIdError: false,
    queryChannel: null,
  }

  link = (channel, merchantId) => {
    const prefix = merchantIdPrefix[channel], suffix = merchantIdSuffix[channel]
    if (merchantIdMatch[channel](merchantId)) {
      const { user } = this.props
      this.setState({
        merchantIdError: false,
      })
      linkAccount({
        channel,
        merchant_id: merchantIdConstructor[channel](merchantId, prefix, suffix),
        user_id: user.id,
      }, false)
    }
    else {
      this.setState({
        merchantIdError: true,
      })
    }
  }

  UNSAFE_componentWillMount() {
    try {
      const searchParams = new URLSearchParams(window.location.search)
      const channel = searchParams.get?.('channel')
      const encodedPayload = searchParams.get?.('payload')
      if (channel === 'woocommerce' && encodedPayload) {
        const decodedPayload = atob(encodedPayload)
        const payload = JSON.parse(decodedPayload)
        const merchantId = payload.store_url?.replace(/^http:\/\//, '')?.replace(/^https:\/\//, '')
        const page = payload.page
        if (merchantId && page === 'onboard') this.link('WooCommerce', merchantId)
      }
    } catch (e) {
      console.error('Error retrieving url search params:', e)
    }
  }

  UNSAFE_componentWillUpdate(nextProps, nextState) {
    if (this.props.channel !== nextProps.channel) {
      this.setState({ merchantId: '' })
    }
    if (this.state.merchantIdError && this.state.merchantId !== nextState.merchantId) {
      this.setState({ merchantIdError: false })
    }
  }

  render() {
    const { channel, setLinkModalOpenFor } = this.props
    const { merchantId, merchantIdError, queryChannel, } = this.state

    return (
      <Modal show={!!channel} onHide={() => setLinkModalOpenFor('')}>
        <Modal.Header closeButton>
          <Modal.Title>
            {`Enter your ${channel} account name here`}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <InputGroup className={merchantIdError ? 'has-error' : ''}>
            {
              merchantIdPrefix[channel] ? (
                <InputGroup.Text>{merchantIdPrefix[channel]}</InputGroup.Text>
              ) : null
            }
            <FormControl
              value={merchantId}
              onChange={e => this.setState({ merchantId: e.target.value })}
              placeholder="Your account to link"
            />
            {
              merchantIdSuffix[channel] ? (
                <InputGroup.Text>{merchantIdSuffix[channel]}</InputGroup.Text>
              ) : null
            }
          </InputGroup>

          <div className={`mt1 ${merchantIdError ? 'error-color' : 'hidden'}`}>{'Please enter a valid account name'}</div>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="primary" onClick={() => this.link(channel, merchantId)}>
            Link
          </Button>
        </Modal.Footer>
      </Modal>
    )
  }
}
