import { addDays } from 'date-fns';

export default function reducer(state={
    selection: {
      startDate: addDays(new Date(), -7),
      endDate: new Date(),
      key: 'selection',
    },
    statistics: {
      accounts: [],
      dates: [],
      stats: [],
      trends: [],
    },
    type: 'column',
    data_type: 'Overview',
    categories: [],
    fetching: true,
    fetched: false
  }, action) {
    switch (action.type) {
      case "CHANGE_STATISTICS_DATE_PICKER": {
        return {
          ...state,
          selection: action.payload.selection
        }
      }
      case "FETCH_STATISTICS": {
        return {...state, fetching: true}
      }
      case "FETCH_STATISTICS_REJECTED": {
        return {...state, fetching: false}
      }
      case "FETCH_STATISTICS_FULFILLED": {
        return {
          ...state,
          fetching: false,
          fetched: true,
          statistics: action.payload.data,
          data_type: action.payload.type
        }
      }
      case "CHANGE_CHART_TYPE": {
        return {
          ...state,
          type: action.payload
        }
      }
      case "CHANGE_DATA_TYPE": {
        return {
          ...state,
          data_type: action.payload
        }
      }
      case "CHANGE_CHART_CATEGORIES": {
        return {
          ...state,
          categories: action.payload
        }
      }
      case "CHANGE_USERS_SERIES": {
        return {
          ...state,
          users_series: action.payload
        }
      }
      case "CHANGE_EBAY_SERIES": {
        return {
          ...state,
          ebay_series: action.payload
        }
      }
      case "CHANGE_GOOGLE_SERIES": {
        return {
          ...state,
          google_series: action.payload
        }
      }
      case "CHANGE_SHOPIFY_SERIES": {
        return {
          ...state,
          shopify_series: action.payload
        }
      }
      case "CHANGE_PAYPAL_SERIES": {
        return {
          ...state,
          paypal_series: action.payload
        }
      }
      case "CHANGE_PAYPAL_MERCHANT_SERIES": {
        return {
          ...state,
          paypal_merchant_series: action.payload
        }
      }
      case "CHANGE_BIGCOMMERCE_SERIES": {
        return {
          ...state,
          bigcommerce_series: action.payload
        }
      }
      case "CHANGE_WOOCOMMERCE_SERIES": {
        return {
          ...state,
          woocommerce_series: action.payload
        }
      }
    }
    return state
}