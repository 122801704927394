import names from 'names.json'
import moment from 'momentExt'
import numeral from 'numeral'
import _ from 'lodash'

const calculateTransactionSummary = (data) => {
  // May need to modify
  const keyStat = data.overall.filter(val => {
    // For paypal
    if (val.transaction_status === 'Completed' && val.type === 'Recurring Payment') {
      return true
    }
    // For ebay
    else if (val.transaction_status === 'Complete') {
      return true
    }
    // For Shopify
    else if (val.transaction_status === 'Success') {
      return true
    }
    else {
      return false
    }
  })
  const ebayStats = data.overall.filter(val => (val.transaction_status === 'Complete' && val.type === 'ItemMarkedShipped'))[0]
  let summary = {}
  if (keyStat.length) {
    let calculatedKeyStat = mergeKeyStat(keyStat, names.dashboard.metrics)
    for (var metric in names.dashboard.metrics) {
      summary[metric] = {
        title: names.dashboard.metrics[metric].title,
        format: names.dashboard.metrics[metric].format,
        value: Number(calculatedKeyStat[metric])
      }
    }
    if (ebayStats) {
      const { ebayMetrics } = names.dashboard
      for (var metric in ebayMetrics) {
        summary[ebayMetrics[metric].mappedKey] = {
          title: ebayMetrics[metric].title,
          format: ebayMetrics[metric].format,
          value: Number(ebayStats[metric])
        }
      }
    }
    return summary
  }
  else return null
}

const mergeKeyStat = (keyStat, metrics) => {
  if (keyStat.length > 1) {
    let merged = {}
    for (var accMetric in metrics) {
      if (metrics[accMetric].acc) {
        keyStat.forEach(stat => {
          merged[accMetric] = (merged[accMetric] || 0) + (stat[accMetric] || 0)
        })
      }
    }
    for (var avgMetric in metrics) {
      if (metrics[avgMetric].avg) {
        merged[avgMetric] = merged[metrics[avgMetric].divide] / (merged[metrics[avgMetric].by] || 1)
      }
    }
    return merged
  }
  else {
    return keyStat[0]
  }
}

const calculateSubscribersSummary = (data) => {
  let summary = {}
  Object.keys(data.overall).forEach(metric => {
    summary[metric] = {
      title: names.subscribers.metrics[metric].title,
      format: names.subscribers.metrics[metric].format,
      value: Number(data.overall[metric])
    }
  })
  return summary
}

const convertRawToSeriesObj = (data, transactionCategory, displayMetrics, field) => {
  const raw = transactionCategory ? data.filter(val => val[field] === transactionCategory) : data
  let seriesObj = {}
  raw.forEach(val => {
    if (seriesObj[val.event_date]) {
      displayMetrics.forEach(metric => {
        seriesObj[val.event_date][metric] += Number(val[metric])
      })
    }
    else {
      seriesObj[val.event_date] = {}
      displayMetrics.forEach(metric => {
        seriesObj[val.event_date][metric] = Number(val[metric])
      })
    }
  })
  return seriesObj
}

export const calculatePriceAnalysisOverall = (data) => {
  delete data.current_active_subscribers // Not in metric box
  const sub_metrics = Object.keys(data).map(metric => Object.keys(data[metric]).filter(val => val !== 'price'))
  let summary = {}
  Object.keys(data).forEach((key, index) => {
    summary[key] = {
      title: names.subscribers.metrics[key].title,
      format: names.subscribers.metrics[key].format,
      value: Number(data[key].price)
    }
    if (sub_metrics[index].length) {
      summary[key].sub_metrics = {}
      sub_metrics[index].forEach((sub_metric) => {
        summary[key].sub_metrics[sub_metric] = names.subscribers.metrics[key].sub_metrics[sub_metric]
        summary[key].sub_metrics[sub_metric].value = data[key][sub_metric]
      })
    }
  })

  return summary
}

export const convertTransactionStats = (data, displayMetrics, transactionCategories) => {
  const dates = [... new Set(data.data.map(val => val.event_date))].sort((a, b) => new Date(a) - new Date(b))
  const metricNames = displayMetrics.map(metric => names.dashboard.metrics[metric])

  return Object.assign({},
    {
      type: 'timeline_series',
      key: {
        title: 'Date',
        array: dates.map(val => moment(new Date(val)).utcOffset(0)),
      },
      metricNames,
      summary: calculateTransactionSummary(data),
    }, ...['transaction_status', 'type'].map(field => {
      return {
        [field === 'type' ? 'metricsByType' : 'metricsByStatus']: transactionCategories[field].map(transactionCategory => {
          let seriesObj = convertRawToSeriesObj(data.data, transactionCategory, displayMetrics, field)
          return {
            title: transactionCategory,
            array: displayMetrics.map((metric, metricIndex) => {
              return {
                name: metricNames[metricIndex].title,
                csvKey: metric,
                format: names.dashboard.metrics[metric].format,
                axis_format: names.dashboard.metrics[metric].axis_format,
                axis_format_big: names.dashboard.metrics[metric].axis_format_big,
                data: dates.map(val => {
                  return seriesObj[val] ? (Number(seriesObj[val][metric]) || 0) : 0
                }),
              }
            })
          }
        })
      }
    })
  )
}

export const convertSubscirbersOverview = (data, displayMetrics) => {
  const dates = [... new Set(data.data.map(val => val.event_date))].sort((a, b) => new Date(a) - new Date(b))
  const metricNames = displayMetrics.map(metric => names.subscribers.metrics[metric])
  const seriesObj = convertRawToSeriesObj(data.data, null, displayMetrics)

  return {
    type: 'timeline_series',
    key: {
      title: 'Date',
      array: dates.map(val => moment(new Date(val)).utcOffset(0)),
    },
    metrics: [{
      array: displayMetrics.map((metric, metricIndex) => {
        return {
          name: metricNames[metricIndex].title,
          csvKey: metric,
          format: names.subscribers.metrics[metric].format,
          axis_format: names.subscribers.metrics[metric].axis_format,
          axis_format_big: names.subscribers.metrics[metric].axis_format_big,
          data: dates.map(val => {
            return seriesObj[val] ? (Number(seriesObj[val][metric]) || 0) : 0
          }),
        }
      }),
    }],
    metricNames,
    summary: calculateSubscribersSummary(data)
  }
}

export const getGroupedDateKeys = (array, groupBy, additionalGroupedMetrics = {}) => {
  if (additionalGroupedMetrics[groupBy]) {
    return additionalGroupedMetrics[groupBy].key.array
  }
  if (groupBy === 'Day') {
    return array
  }
  else if (groupBy === 'Week') {
    let groupedDateKeys = []
    array.forEach((val, index) => {
      if (val.day() === 6 || index === 0) {
        groupedDateKeys.push({ start: val, startIndex: index, end: val, endIndex: index })
      }
      else {
        groupedDateKeys[groupedDateKeys.length - 1].end = val
        groupedDateKeys[groupedDateKeys.length - 1].endIndex = index
      }
    })
    return groupedDateKeys
  }
  else if (groupBy === 'Month') {
    let groupedDateKeys = []
    let currentMonth = array[0]?.get('month')
    array.forEach((val, index) => {
      if (val.get('month') !== currentMonth || index === 0) {
        groupedDateKeys.push({ start: val, startIndex: index, end: val, endIndex: index })
        currentMonth = val.get('month')
      }
      else {
        groupedDateKeys[groupedDateKeys.length - 1].end = val
        groupedDateKeys[groupedDateKeys.length - 1].endIndex = index
      }
    })
    return groupedDateKeys
  }
  else if (groupBy === 'Quarter') {
    let groupedDateKeys = []
    let currentQuarter = Math.floor(array[0]?.get('month') / 3)
    array.forEach((val, index) => {
      let nextQuarter = Math.floor(val.get('month') / 3)
      if (nextQuarter !== currentQuarter || index === 0) {
        groupedDateKeys.push({ start: val, startIndex: index, end: val, endIndex: index })
        currentQuarter = nextQuarter
      }
      else {
        groupedDateKeys[groupedDateKeys.length - 1].end = val
        groupedDateKeys[groupedDateKeys.length - 1].endIndex = index
      }
    })
    return groupedDateKeys
  }
}

const reduceBySum = arr => arr.reduce((acc, cur) => (Number(acc) || 0) + Number(cur), 0)
export const getGroupedSeries = (metrics, groupBy, groupedDateKeys, additionalGroupedMetrics = {}) => {
  if (additionalGroupedMetrics[groupBy]) {
    return additionalGroupedMetrics[groupBy].metrics
  }
  if (groupBy === 'Day') {
    return metrics
  }
  else {
    let groupedMetrics = []
    metrics.forEach((metric, metricIndex) => {
      groupedMetrics[metricIndex] = Object.assign({}, metric, { array : metric.array?.map(arr => {
        return Object.assign({}, arr, { data: groupedDateKeys?.map(dateRange => {
          const filteredWithDateRange = arr.data
            ? arr.data.filter((val, index) => (index >= dateRange.startIndex && index <= dateRange.endIndex))
            : []
          const aggregationFunction = arr.aggregateBy ? arr.aggregateBy(filteredWithDateRange, dateRange) : reduceBySum
          return aggregationFunction(filteredWithDateRange)
        })})
      })})
    })
    return groupedMetrics
  }
}

export const singleTimelineDataCsvFormatter = (series, dateKeys, groupBy, seriesIndex) => {
  let formattedDateKeys, groupByTitle
  switch (groupBy) {
    case 'Day':
      formattedDateKeys = dateKeys.map(val => val.format('MMM D YYYY'))
      groupByTitle = 'Date'
      break
    case 'Week':
      formattedDateKeys = dateKeys.map(val => `${val.start.format('MMM D YYYY')} - ${val.end.format('MMM D YYYY')}`)
      groupByTitle = 'Week'
      break
    case 'Month':
      formattedDateKeys = dateKeys.map(val => `${val.start.format('MMM YYYY')}`)
      groupByTitle = 'Month'
      break
    default:
      formattedDateKeys = dateKeys.map(val => val.format('MMM D YYYY'))
      groupByTitle = 'Date'
  }
  let CsvData = [], CsvFormatters = {}
  formattedDateKeys.forEach((val, index) => {
    CsvData[index] = { [groupByTitle]: val }
    series[seriesIndex || 0].array.map(metric => {
      CsvData[index][metric.csvKey] = metric.data[index]
    })
  })
  series[seriesIndex || 0].array.forEach(metric => {
    CsvFormatters[metric.csvKey] = val => numeral(val).format(metric.format)
  })
  return { formattedData: CsvData, formatters: CsvFormatters }
}

export const convertMetricBoxDataForExport = (stats, metricConfigs) => {
  const statKeys = Object.keys(stats).filter(k => _.get(metricConfigs, [k, 'visible'])).sort(
    (a, b) => _.get(metricConfigs, [a, 'order'], 0) - _.get(metricConfigs, [b, 'order'], 0)
  )
  const statsTitles = Object.assign({}, ...statKeys.map(key => ({
    [key]: stats[key].title
  })))
  const data = [Object.assign({}, ...statKeys.map(key => ({
    [key]: stats[key].value
  })))]
  const formatters = Object.assign({}, ...statKeys.map(key => ({
    [key]: stats[key].formatter || (val => numeral(val).format(stats[key].format))
  })))
  return { keys: statKeys, titles: statsTitles, data, formatters }
}

export const convertChartBoxSeriesDataForExport = ({metricSeries, seriesArrayKey, groupBy, dateFormatter}) => {
  if (!seriesArrayKey.length) return []
  const results = [], groupByKey = groupBy.toLowerCase()
  for (var metricSeriesEntry of metricSeries) {
    const keys = [groupByKey, ...metricSeriesEntry.array.map(i => i.csvKey)]
    const titles = {
      [groupByKey]: groupBy,
      ...metricSeriesEntry.array.reduce((acc, i) => ({
        ...acc,
        [i.csvKey]: i.title,
      }), {})
    }
    const data = seriesArrayKey.map((k, kIndex) => ({
      [groupByKey]: dateFormatter(k),
      ...Object.assign({}, ...metricSeriesEntry.array.map(i => ( i.data ? { [i.csvKey]: i.data[kIndex] } : {})))
    }))
    const formatters = Object.assign({}, ...metricSeriesEntry.array.map(i => ({
      [i.csvKey]: i.formatter || (val => numeral(val).format(i.format))
    })))
    const name = dateFormatter(seriesArrayKey[0]) + (seriesArrayKey.length > 1 ? ` - ${dateFormatter(seriesArrayKey.slice(-1)[0])}` : '')
    results.push({ keys, titles, data, formatters, name })
  }
  return results
}

export const convertFacetChartDataForExport = (facets, facetFormatters, chartConfigs) => {
  return Object.keys(facets).sort((a, b) => _.get(chartConfigs, [a, 'order'], 0) - _.get(chartConfigs, [b, 'order'], 0)).map(k => {
    const metrics = _.get(chartConfigs, [k, 'metric'], 'total_orders').split(',')
    return {
      keys: ['name', ...metrics],
      titles: {
        name: facets[k].title,
        ...(metrics.reduce((acc, metric) => ({
          ...acc,
          [metric]: facets[k].yAxisTitle,
        }))),
      },
      data: facets[k].categories.map((c, cIndex) => ({
        name: c,
        ...Object.assign({}, ...facets[k].metrics.map(metric => facets[k].series[metric] ? ({
          [metric]: facets[k].series[metric][cIndex]
        }) : null ))
      })),
      formatters: Object.assign({}, ...facets[k].metrics.map(metric => ({
        [metric]: facets[k].formatters[metric].formatter || (val => numeral(val).format(facets[k].formatters[metric].format))
      }))),
      // formatters: { orders: number_formatter },
      name: facetFormatters[k] ? facetFormatters[k].title : k
    }
  })
}

const defaultFacetBucketLimit = {
  merchant_id: 20,
  billing_county_name: 254, // TBD: check if billing county displays correctly in charts
  default: 100,
}

const alignBucketByVal = (prevPeriodFacets, currentPeriodFacets) => {
  let result = {}
  for (var facetKey in currentPeriodFacets) {
    const valIndexDictOfPrev = (prevPeriodFacets[facetKey] || []).reduce((acc, prevBucket, index) => ({
      ...acc,
      [prevBucket.val]: index.toString()
    }), {})
    const defaultValueForMissingBucket = currentPeriodFacets[facetKey].length >= (defaultFacetBucketLimit[facetKey] || defaultFacetBucketLimit.default) ? null : 0
    result[facetKey] = currentPeriodFacets[facetKey].map(bucket => valIndexDictOfPrev[bucket.val] ? (
      prevPeriodFacets[facetKey][valIndexDictOfPrev[bucket.val]]
    ) : (
      {
        ...(Object.keys(bucket).reduce((acc, cur) =>({
          ...acc,
          [cur]: (!bucket[cur] || Array.isArray(bucket[cur])) ? bucket[cur] : defaultValueForMissingBucket,
          val: bucket.val,
        }), {}))
      }
    ))
  }
  return result
}

const stubOutPrevFacets = (facets) => {
  let stubbed = {}
  for (let facet in facets) {
    if (_.isArray(facets[facet])) {
      stubbed[facet] = facets[facet].map(entry => {
        let ret = {}
        for (let key in entry) {
          if (_.isNumber(entry[key])) {
            ret[key] = 0
          } else {
            ret[key] = entry[key]
          }
        }
        return ret
      })
    } else if (_.isObject(facets[facet])) {
      stubbed[facet] = stubOutPrevFacets(facets[facet])
    }
  }
  return stubbed
}

export const matchPeriodComparedFacetBucketsWithCurrent = (prevFacets, facets) => {
  if (_.isEmpty(prevFacets)) {
    prevFacets = stubOutPrevFacets(facets)
  }
  const {
    options: facetsOptions,
    custom_fields: facetsCustomFields,
    customer_form_fields: facetsCustomerFormFields,
    shipping_form_fields: facetsShippingFormFields,
    customer_custom_attributes: facetsCustomerCustomAttributes,
    ...currentPeriodFacetsRest
  } = facets
  const {
    options: prevFacetsOptions,
    custom_fields: prevFacetsCustomFields,
    customer_form_fields: prevFacetsCustomerFormFields,
    shipping_form_fields: prevFacetsShippingFormFields,
    customer_custom_attributes: prevFacetsCustomerCustomAttributes,
    ...prevPeriodFacetsRest
  } = prevFacets
  const output = alignBucketByVal(prevPeriodFacetsRest, currentPeriodFacetsRest)
  if (facets.options) output.options = alignBucketByVal(prevFacetsOptions, facetsOptions)
  if (facets.custom_fields) output.custom_fields = alignBucketByVal(prevFacetsCustomFields, facetsCustomFields)
  if (facets.customer_form_fields) output.customer_form_fields = alignBucketByVal(prevFacetsCustomerFormFields, facetsCustomerFormFields)
  if (facets.shipping_form_fields) output.shipping_form_fields = alignBucketByVal(prevFacetsShippingFormFields, facetsShippingFormFields)
  if (facets.customer_custom_attributes) {
    output.customer_custom_attributes = alignBucketByVal(prevFacetsCustomerCustomAttributes, facetsCustomerCustomAttributes)
  }

  return output
}