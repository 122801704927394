import { utmDataTypes } from 'modules/constants'
import _ from 'lodash'
import { localStoragePayhelm } from 'modules/utils'

const extractSortOrder = sort => {
  const [ sortBy, sortOrder ] = sort.split(' ')
  return (sortBy && sortOrder) ? { sortBy, sortAsc: sortOrder === 'asc' } : null
}
const {
  order_sort = '', lineItem_sort = '', product_sort = '', variant_sort = '', brand_sort = '', sku_sort = '', vendor_sort = '', product_trend_sort = '',
  customer_sort = 'total_orders desc', map_sort = '', utmSource_sort = '', utmMedium_sort = '', utmCampaign_sort = '',
  utmContent_sort = '', utmTerm_sort = '', order_trend_sort = '', utmId_sort = ''
} = localStoragePayhelm
const [
  orderSortOrder, lineItemSortOrder, productSortOrder, variantSortOrder, brandSortOrder, skuSortOrder, vendorSortOrder, productTrendSortOrder,
  customerSortOrder, mapSortOrder, utmSourceSortOrder, utmMediumSortOrder, utmCampaignSortOrder,
  utmContentSortOrder, utmTermSortOrder, customOrderBaseSortOrder, utmIdSortOrder
] = [
  order_sort || 'processed_at desc', lineItem_sort || 'processed_at desc', product_sort, variant_sort, brand_sort, sku_sort, vendor_sort, product_trend_sort, customer_sort,
  map_sort, utmSource_sort, utmMedium_sort, utmCampaign_sort, utmContent_sort, utmTerm_sort, order_trend_sort, utmId_sort
].map(sort => extractSortOrder(sort || 'total_sales desc'))
export const initialSortOrders = {
  orderSortOrder, lineItemSortOrder, mapSortOrder,
  productSortOrder, variantSortOrder, brandSortOrder, skuSortOrder, vendorSortOrder, productTrendSortOrder, customerSortOrder,
  utmSourceSortOrder, utmMediumSortOrder, utmCampaignSortOrder, utmContentSortOrder, utmTermSortOrder, customOrderBaseSortOrder, utmIdSortOrder
}
export const proCustReportKeys = ['product', 'variant', 'brand', 'sku', 'productTrend', 'customer', 'customOrderBase']
export const reportKeys = ['order', 'lineItem', ...proCustReportKeys, ...utmDataTypes, 'map']
const reportDefaultInvisibleColumns = {
  order: 'net_sales,customer_id,customer_name,customer_email,customer_phone,customer_company,customer_group_name,' +
    'total_cost_of_goods_sold,total_refunds,total_refunded_items,tax_exempt_category,total_tax,' +
    'total_discounts,total_sale_discount,total_shipping_presentment_amount,total_store_fees,total_costs,grand_total,' +
    'line_items,skus,order_tags,billing_full_address,shipping_full_address,shipping_city,shipping_province' +
    'shipping_zip,shipping_county_name,shipping_city,billing_county_name,billing_city,coupon_codes,gateway,processing_method,payment_method,' +
    'shipment_cost,shipment_profit,shipment_insurance_cost,shipment_insurance_value,shipments,last_refund_at,tax_rate,gross_tax_rate,' +
    'fulfilled_at,fulfillment_time_in_seconds,shipping_name,shipping_first_name,shipping_last_name,' +
    'brand_names,category_titles,catalog_titles_v1,product_ids,shipping_providers,shipping_methods,billing_street_address,' +
    'shipping_street_address,sales_channel_name,sales_channel_type,sales_channel_platform,billing_country,' +
    'shipping_country,currency,net_sales,custom_fields,marketplace_product_ids,product_skus,bin_picking_numbers,note,customer_message,' +
    'product_metafields,order_metafields,fulfillment_city,fulfillment_name,fulfillment_province_code,' +
    'fulfillment_zip,fulfillment_county_name,fulfillment_full_address,payout_transaction_ids,payout_dates,credit_card_type,refund_tax,total_product_refund,tracking_number,points_status' +
    'utm_campaign,utm_content,utm_medium,utm_source,utm_term,tracking_number,total_shipping_cost,' +
    'payout_fees,payout_ids,gateway_fee,points_claimed,points_refunded,ip_address,external_order_id,customer_created_at',
  lineItem: 'customer_id,customer_email,customer_phone,customer_company,customer_group_name,' +
  'sales_channel_name,sales_channel_type,sales_channel_platform,' +
  'billing_full_address,shipping_name,shipping_first_name,shipping_last_name,' +
  'shipping_full_address,shipping_city,shipping_province,shipping_zip,shipping_county_name,shipping_city,' +
  'tax_exempt_category,total_tax,tax_rate,total_discounts,' +
  'total_refunds,total_items_refunded,cost_of_refunds,' +
  'marketplace_product_id,sku,product_sku,bin_picking_number,order_tags,financial_status,fulfillment_status,fulfilled_at,' +
  'source_name,processing_method,gateway,payment_method,' +
  'fulfillment_time_in_seconds,updated_at,merchant_id,category_titles,catalog_titles_v1,product_id,' +
  'billing_street_address,shipping_street_address,shipping_country,billing_country,billing_city,' +
  'custom_fields,note,customer_message,product_metafields,order_metafields,fulfillment_city,fulfillment_name,' +
  'fulfillment_province_code,fulfillment_zip,fulfillment_county_name,fulfillment_full_address,' +
  'credit_card_type,utm_campaign,utm_content,utm_medium,utm_source,utm_term,utm_id,tracking_number,' +
  'options,currency_rate,shipping_form_fields,customer_form_fields,custom_fields,customer_custom_attributes,customer_created_at',
  product: 'sku,total_discounts,total_sale_discount,total_after_discount,total_costs,total_after_costs,items_sold_percent,total_customers,' +
    'avg_shipping_cost,total_shipping_cost,avg_fulfillment_time,min_fulfillment_time,max_fulfillment_time,options,variant_title,' +
    'product_type,total_cost_of_goods_sold,total_refunds,total_cost_of_refunds,total_items_refunded,marketplace_product_id,product_sku,bin_picking_number',
  variant: 'total_discounts,total_sale_discount,total_after_discount,total_costs,total_after_costs,items_sold_percent,total_customers,' +
    'avg_shipping_cost,total_shipping_cost,avg_fulfillment_time,min_fulfillment_time,max_fulfillment_time,sku,options,variant_title,' +
    'total_cost_of_goods_sold,total_refunds,total_cost_of_refunds,total_items_refunded,bin_picking_number',
  brand: 'items_sold_percent,total_customers,avg_fulfillment_time,min_fulfillment_time,max_fulfillment_time,total_cost_of_goods_sold,' +
    'total_refunds,total_cost_of_refunds,total_items_refunded',
  sku: 'items_sold_percent,total_customers,name,avg_fulfillment_time,min_fulfillment_time,max_fulfillment_time,options,variant_title,' +
    'total_cost_of_goods_sold,total_refunds,total_cost_of_refunds,total_items_refunded,total_sale_discount',
  productTrend: 'total_discounts,total_sale_discount,total_after_discount,total_costs,total_after_costs,items_sold_percent,total_customers,' +
  'avg_shipping_cost,total_shipping_cost,avg_fulfillment_time,min_fulfillment_time,max_fulfillment_time,' +
  'total_cost_of_goods_sold,total_refunds,total_cost_of_refunds,total_items_refunded',
  customer: 'customer_email,customer_phone,customer_company,customer_group_name,tax_exempt_category,total_tax,total_shippings,total_refunds,grand_total,' +
  'billing_full_address,shipping_full_address,billing_street_address,shipping_street_address,shipping_city,shipping_province,shipping_zip,' +
  'last_order_date,avg_fulfillment_time,min_fulfillment_time,max_fulfillment_time,total_cost_of_goods_sold,total_cost_of_refunds,' +
  'total_items_refunded,total_profit,points_claimed,points_refunded,first_order_date,customer_created_at,store_credit_amount,avg_tax,avg_order,min_sales,' +
  'max_sales,max_tax,min_tax,max_shipping,min_shipping,max_discount,min_discount,merchant_id,total_store_fees,total_store_credit_refund,total_refund_tax,net_sales'
  ,
  ...Object.assign({}, ...utmDataTypes.map(t => ({
    [t]: 'avg_shipping,avg_tax,max_discount,min_discount,max_sales,min_sales,max_shipping,min_shipping,max_tax,min_tax,total_costs,' +
      'total_discounts,total_profit,total_shippings,total_tax,total_store_fees,total_refunds,total_customers,avg_fulfillment_time,' +
      'min_fulfillment_time,max_fulfillment_time,tax_rate,gross_tax_rate,gross_margin,total_taxed_orders,total_discounted_orders,' +
      'total_sales_v1,amount_micros,cost_micros,clicks,average_cpc,conversions,roas,emails_received,emails_opened,email_clicked,total_shipping_tax,gateway_fee,' +
      'total_cost_of_goods_sold,total_revenue,net_sales,total_cost_of_refunds,total_store_credit_refund,total_store_credit_amount,total_refund_tax,total_shipping_refund,emails_clicked,impressions'
  }))),
  map: 'avg_shipping,avg_tax,max_discount,min_discount,max_sales,min_sales,max_shipping,min_shipping,max_tax,min_tax,total_costs,total_profit,' +
    'total_shippings,total_store_fees,total_tax,total_discounts,total_refunds,total_customers,avg_fulfillment_time,min_fulfillment_time,' +
    'max_fulfillment_time,total_cost_of_goods_sold,total_cost_of_refunds,total_items_refunded',
  customOrderBase: 'net_sales,customer_id,customer_name,customer_email,customer_phone,customer_company,customer_group_name,' +
  'total_cost_of_goods_sold,total_refunds,total_refunded_items,tax_exempt_category,total_tax,' +
  'total_discounts,total_sale_discount,total_shipping_presentment_amount,total_store_fees,total_costs,grand_total,' +
  'line_items,skus,order_tags,billing_full_address,shipping_full_address,shipping_city,shipping_province,' +
  'shipping_zip,shipping_county_name,shipping_city,billing_county_name,billing_city,coupon_codes,gateway,processing_method,payment_method,' +
  'shipment_cost,shipment_profit,shipment_insurance_cost,shipments,last_refund_at,tax_rate,gross_tax_rate,' +
  'fulfilled_at,fulfillment_time_in_seconds,shipping_name,shipping_first_name,shipping_last_name,' +
  'brand_names,category_titles,catalog_titles_v1,product_ids,shipping_providers,shipping_methods,billing_street_address,' +
  'shipping_street_address,sales_channel_name,sales_channel_type,sales_channel_platform,billing_country,' +
  'shipping_country,currency,net_sales,custom_fields,marketplace_product_ids,product_skus,bin_picking_numbers,note,customer_message,' +
  'product_metafields,order_metafields,fulfillment_city,fulfillment_name,fulfillment_province_code,' +
  'fulfillment_zip,fulfillment_county_name,fulfillment_full_address,payout_transaction_ids,payout_dates,credit_card_type,refund_tax,total_product_refund,tracking_number,' +
  'utm_campaign,utm_content,utm_medium,utm_source,utm_term,tracking_number,total_shipping_cost,' +
  'payout_fees,payout_ids,gateway_fee,points_claimed,points_refunded,shipping_form_fields,customer_form_fields,channel,shipping_province,last_order_date,first_order_date,' +
  'avg_items_per_order,avg_price,total_items_sold,total_cost_of_refunds,total_items_refunded,avg_fulfillment_time,min_fulfillment_time,max_fulfillment_time,' +
  'total_profit,avg_tax,avg_order,max_sales,min_sales,max_tax,min_tax,max_shipping,min_shipping,max_discount,min_discount,' +
  'gross_margin,merchant_id,total_shippings,avg_shipping,total_discounted_orders,total_store_credit_refund,total_shipping_refund,total_refund_tax,ip_address,external_order_id,customer_custom_attributes,customer_created_at,source_name',
}
export const initialColumnConfigs = Object.assign({}, ...reportKeys.map(reportKey => ({
  [reportKey]: [
    localStoragePayhelm[`invisible_report_cols_${reportKey}`], reportDefaultInvisibleColumns[reportKey], ''
  ].find(val => val !== undefined).split(',').filter(v => v).reduce((acc, cur, index) => ({
    ...acc,
    [cur]: { invisible: true, sort: (acc[cur] || {}).sort || null, order: index }
  }), {
    [initialSortOrders[`${reportKey}SortOrder`].sortBy]: {
      sort: initialSortOrders[`${reportKey}SortOrder`].sortAsc ? 'asc' : 'desc'
    }
  })
})))

export const updateConfigSortOrder = (config, newSortOrder) => {
  let nextConfig = config || { columns: initialColumnConfigs }
  for (var reportKey in newSortOrder) {
    nextConfig.columns[reportKey] = Object.keys(nextConfig.columns[reportKey]).reduce((acc, cur) => ({
      ...acc,
      [cur]: { ...nextConfig.columns[reportKey][cur], sort: (acc[cur] || {}).sort || null }
    }), {
      [newSortOrder[reportKey].sortBy]: { sort: newSortOrder[reportKey].sortAsc ? 'asc' : 'desc' }
    })
  }
  return nextConfig
}
