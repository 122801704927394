import React from 'react'
import { Alert, Button, ButtonToolbar } from 'react-bootstrap'
import LinkAccountModal from 'sharedComponents/LinkAccountModal'
import { linkFromChannel } from 'account'
import { connect } from "react-redux"
import { changeApplication, setAccounts } from 'actions/rootActions'
import { setRedirectedAction, setBillingMessage } from 'actions/planActions'
import { simpleDispatchProps } from 'actions/actionUtils'
import { getCookie, clearTempCookies } from 'cookie'
import renderAccountAction from './accountAction'
import renderPlanAction from './planAction'
import { sessionStoragePayhelm } from 'modules/utils'
import NavLinkContainer from '../NavLinkContainer'

@connect((store) => ({
  currentApp: store.root.applicationId,
  accounts: store.root.accounts,
  topUrl: store.root.topUrl,
  subscriptionId: store.plan.subscription ? store.plan.subscription.id : null,
  currentPlans: store.plan.subscription ? store.plan.subscription.plans : null,
  planStatus: store.plan.subscription ? store.plan.subscription.plan_status : null,
  planExpired: store.plan.subscription ? store.plan.subscription.plan_expired : false,
  billingMessage: store.plan.billingMessage,
  admin: store.root.admin,
  loginFromChannel: store.root.loginFromChannel,
}), (dispatch) => simpleDispatchProps(dispatch, { changeApplication, setRedirectedAction, setBillingMessage, setAccounts }))

export default class MarketingBanner extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      ...this.getInitialCallbackMsg(),
      linkModalOpenFor: '',
    }
  }

  getInitialCallbackMsg = () => {
    let callbackMsg = '', callbackMsgType = 'success', callbackMsgStay = false, visible = true, linking_types = ['Paypal Merchant', 'Paypal', 'Shopify']

    const [
      confirming_charge, charge_fulfilled,
      linking_account, linking_fulfilled, refreshing_token_for, new_linked_account,
      slack_fulfilled,
    ] = getCookie([
      'confirming_charge', 'charge_fulfilled',
      'linking_account', 'linking_fulfilled', 'refreshing_token_for', 'new_linked_account',
      'slack_fulfilled',
    ])

    if (confirming_charge) {
      if (charge_fulfilled) {
        if (charge_fulfilled === 'true') {
          callbackMsg = 'The billing charge has been confirmed.'
          callbackMsgType = 'success'
        }
        else {
          callbackMsg = 'The billing charge has been declined.'
          callbackMsgType = 'danger'
          callbackMsgStay = true
        }
      }
    }
    else if (linking_account) {
      if (linking_fulfilled) {
        if (linking_fulfilled === 'true') {
          if (refreshing_token_for) {
            if (new_linked_account) {
              const [merchant_id, remote_account_username] = decodeURIComponent(refreshing_token_for).split('|')
              const [new_account, channel] = decodeURIComponent(new_linked_account).split('|')
              callbackMsg = (
                <span>
                  {`You successfully linked account `}
                  <b>{new_account}</b>
                  {`. Please relink your ${channel} account `}
                  <b>{remote_account_username || merchant_id}</b>
                  {'.'}
                </span>
              )
            }
            else {
              callbackMsg = 'The account\'s token has been updated.'
            }
          }
          else {
            callbackMsg = 'The account linking has been done.'
          }
          callbackMsgType = 'success'
        }
        else {
          callbackMsg = refreshing_token_for ? 'Failed to update the account\'s token. Please try again.' : 'The account linking was not successful. Please try again.'
          callbackMsgType = 'danger'
          callbackMsgStay = true
        }
      }
    }
    else if (slack_fulfilled) {
      if (slack_fulfilled === 'true') {
        callbackMsg = 'Slack linking has been done.'
        callbackMsgType = 'success'
      }
      else {
        callbackMsg = 'Slack linking was not successful. Please try again.'
        callbackMsgType = 'danger'
        callbackMsgStay = true
      }
    }
    clearTempCookies()

    return { callbackMsg, callbackMsgType, callbackMsgStay, visible, linking_types }
  }

  UNSAFE_componentWillUpdate(nextProps, nextState) {
    if (nextState.callbackMsg && !nextState.callbackMsgStay) {
      setTimeout(() => {
        this.setState({ callbackMsg: '' })
      }, 3000)
    }
    if (nextProps.billingMessage.text && !this.props.billingMessage.text) {
      this.setState({
        callbackMsg: nextProps.billingMessage.text,
        callbackMsgType: nextProps.billingMessage.type,
      })
      this.props.setBillingMessage({ text: '', type: ''})
    }
  }

  componentDidMount() {
    if (this.state.callbackMsg && !this.state.callbackMsgStay) {
      setTimeout(() => {
        this.setState({ callbackMsg: '' })
      }, 3000)
    }
  }

  onClose = () => {
    sessionStoragePayhelm.ph_linked = 'true';
    this.setState({ visible: false });
  }

  render() {
    const { planStatus, planExpired, currentPlans, subscriptionId, accounts, thirdParty, topUrl, currentApp, admin, loginFromChannel, history } = this.props
    const { callbackMsg, callbackMsgType, callbackMsgStay, visible, linking_types } = this.state
    let body = null, ph_linked = sessionStoragePayhelm.ph_linked
    if (callbackMsg) {
      body = (
        <Alert variant={callbackMsgType} onClose={callbackMsgStay ? () => this.setState({callbackMsg: '', callbackMsgStay: false}) : null}>
          {callbackMsg}
        </Alert>
      )
    }
    else if (accounts.length === 0 && visible && !ph_linked && currentApp !== 'channels' ) {
      body = (
        <Alert variant={callbackMsgType} onClose={this.onClose}>
          Link your account <ButtonToolbar style={{float: 'right', marginTop: '-6px'}}>
          <NavLinkContainer to="/integrations" onClick={() => changeApplication(currentApp, 'integrations')} type="inline-block">
            <Button size="sm" variant="primary" style={{marginTop: 5, marginBottom: 5}}>Check Options</Button>
          </NavLinkContainer>
          </ButtonToolbar>
        </Alert>
      )
    }
    else if (planStatus && !thirdParty) {
      body = renderPlanAction(currentPlans, planStatus, planExpired, subscriptionId, setRedirectedAction, currentApp, changeApplication, loginFromChannel)
    }
    else if (accounts.length && !body) {
      body = renderAccountAction(accounts, topUrl, admin, this.setState)
    }

    return (
      <div>
        { body }
        <LinkAccountModal />
      </div>
    )
  }
}