import { getQueryJson, localStoragePayhelm } from 'utils'
import { extractAllSellerAccounts, getAccountEmailDict } from 'account'

const queryJson = getQueryJson()
const merchant_id = queryJson.merchant_id || localStoragePayhelm.account_filter_merchant_id || null
const initialState = {
  previousApplicationId: '',
  applicationId: location.pathname.split('/').filter((t) => t && t !== '/')[0] || 'dashboard',
  loginStatus: null,
  loginStatusMsg: '',
  loginFromChannel: null,
  linkingStatus: '',
  chargeStatus: '',
  baremetrics_token: '',
  intercom_token: '',
  slack_config: {
    subscriptions: {
      cancellations: { title: 'Cancellations', value: true },
      recurring_charges: { title: 'Charges', value: true },
      payment_charges: { title: 'Payment', value: true },
      failed_charges: { title: 'Failed Charges', value: true },
      new_subscriptions: { title: 'New Subscriptions', value: true },
    },
    paypal: {
      Purchases: { title: 'Purchases', value: true },
    },
    shopify: {
      ItemsDelivered: { title: 'Items Delivered', value: true },
      ItemsShipped: { title: 'Items Shipped', value: true },
      OrderConfirmed: { title: 'Order Confirmed', value: true },
    },
    bigcommerce: {
      Completed: { title: 'Completed', value: true },
      Shipped: { title: 'Items Shipped', value: true },
      'Awaiting Fulfillment': { title: 'Awaiting Fulfillment', value: true },
      'Awaiting Payment': { title: 'Awaiting Payment', value: true },
      'Partially Shipped': { title: 'Partially Shipped', value: true },
    },
  },
  accounts: [],
  user: {},
  isOwner: false,
  accountFilter: { key: 'merchant_id', value: merchant_id },
  thirdParty: null,
  inkfrogUser: null,
  inkfrogNeedsUpgrade: false,
  showAgreement: false,
  topUrl: '',
  queryJson: queryJson,
  admin: false,
  linkModalOpenFor: '',
  accountEmailDict: {},
  sessionSignature: '',
  sessionExpired: false,
  reportTimeCost: null,
  onboardSkipped: false,
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case 'CHANGE_APPLICATION_ROOT': {
      return {
        ...state,
        previousApplicationId: state.applicationId,
        applicationId: action.payload.replace('/', ''),
      }
    }
    case 'SET_LINKING_STATUS_ROOT': {
      return {
        ...state,
        linkingStatus: action.payload,
      }
    }
    case 'SET_CHARGE_STATUS_ROOT': {
      return {
        ...state,
        chargeStatus: action.payload,
      }
    }
    case 'SET_ACCOUNT_EMAIL_DICT_ROOT': {
      return {
        ...state,
        accountEmailDict: {
          ...state.accountEmailDict,
          ...(Array.isArray(action.payload) ? getAccountEmailDict(action.payload) : action.payload),
        },
      }
    }
    case 'SET_ACCOUNTS_ROOT': {
      const accounts = extractAllSellerAccounts(action.payload)
      return {
        ...state,
        accounts,
        accountEmailDict: {
          ...state.accountEmailDict,
          ...getAccountEmailDict(accounts),
        },
      }
    }
    case 'SET_DOMAINS_ROOT': {
      const domains = action.payload
      return {
        ...state,
        domains,
      }
    }
    case 'LOGIN_SUCCESS_ROOT': {
      const accounts = extractAllSellerAccounts(action.payload.accounts || [])
      return {
        ...state,
        loginStatus: 'success',
        accounts,
        accountEmailDict: {
          ...state.accountEmailDict,
          ...getAccountEmailDict(accounts),
        },
        user: action.payload.user || {},
        loginFromChannel: action.payload.loginFromChannel || null,
      }
    }
    case 'LOGIN_FAILURE_ROOT': {
      return {
        ...state,
        loginStatus: 'failure',
        loginStatusMsg: action.payload,
      }
    }
    case 'UPDATE_USER_PREFERENCES_ROOT': {
      return {
        ...state,
        user: {
          ...state.user,
          ...(action.payload || {}),
        },
      }
    }
    case 'SET_ACCOUNT_FILTER_ROOT': {
      return {
        ...state,
        accountFilter: action.payload,
      }
    }
    case 'SET_THIRD_PARTY_ROOT': {
      return {
        ...state,
        thirdParty: action.payload,
      }
    }
    case 'UPDATE_USER_ROOT': {
      return {
        ...state,
        user: {
          ...state.user,
          ...action.payload,
        },
      }
    }
    case 'SET_IS_OWNER_ROOT': {
      return {
        ...state,
        isOwner: action.payload,
      }
    }
    case 'SET_INKFROG_USER_ROOT': {
      return {
        ...state,
        inkfrogUser: action.payload,
      }
    }
    case 'SET_INKFROG_NEEDS_UPGRADE_ROOT': {
      return {
        ...state,
        inkfrogNeedsUpgrade: action.payload,
      }
    }
    case 'TOGGLE_AGREEMENT_ROOT': {
      return {
        ...state,
        showAgreement: action.payload,
      }
    }
    case 'SET_LINK_MODAL_OPEN_FOR_ROOT': {
      return {
        ...state,
        linkModalOpenFor: action.payload,
      }
    }
    case 'SET_TOP_URL_ROOT': {
      return {
        ...state,
        topUrl: action.payload,
      }
    }
    case 'UPDATE_QUERY_JSON': {
      return {
        ...state,
        queryJson: getQueryJson(),
      }
    }
    case 'SET_ADMIN': {
      return {
        ...state,
        admin: action.payload,
      }
    }
    case 'UPDATE_BAREMETRICS_TOKEN_ROOT': {
      return {
        ...state,
        baremetrics_token: action.payload,
      }
    }
    case 'UPDATE_INTERCOM_TOKEN_ROOT': {
      return {
        ...state,
        intercom_token: action.payload,
      }
    }
    case 'UPDATE_SLACK_NOTIFICATIONS_ROOT': {
      return {
        ...state,
        slack_config: action.payload,
      }
    }
    case 'SET_SESSION_SIGNATURE': {
      return {
        ...state,
        sessionSignature: action.payload,
      }
    }
    case 'SET_SESSION_EXPIRED': {
      return {
        ...state,
        sessionExpired: action.payload,
      }
    }
    case 'SET_REPORT_TIME_COST_ROOT': {
      return {
        ...state,
        reportTimeCost: action.payload,
      }
    }
    case 'SET_ONBOARD_SKIPPED_ROOT': {
      return {
        ...state,
        onboardSkipped: action.payload,
      }
    }
  }
  return state
}
