import React, { useEffect, useState } from 'react'
import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from '@/features/shadcn/ui/sheet'
import { AiFillCaretRight } from 'react-icons/ai'
import DataTable from './DataTable'
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/features/shadcn/ui/tabs'

const adPlatformDescriptionDict = {
  google_ads: ({ toggleAuditTip, auditTipOpen }) => {
    return (
      <div className="tw-rounded-lg tw-border tw-border-blue-400 tw-bg-blue-50 tw-px-4 tw-py-3 tw-mb-4">
        <div className="tw-flex tw-items-center tw-space-x-1.5 tw-cursor-pointer" onClick={() => toggleAuditTip()}>
          <div className={`tw-shrink-0 tw-w-4 tw-h-4 tw-transition-all ${auditTipOpen ? 'tw-rotate-90' : ''}`}>
            <AiFillCaretRight />
          </div>
          <div className="tw-font-bold">How to fix the missing campaigns in Google Ads</div>
        </div>
        <div className={`tw-mt-4 ${auditTipOpen ? 'tw-visible' : 'tw-hidden'}`}>
          <ol className="tw-list-decimal">
            <li>Click on the campaign below to go to the Campaign page in your Google Ads account</li>
            <li>Log in to your Google Ads account</li>
            {/* <li>
              Click "Campaigns" in the left side menu and then select the campaign you want to add utm tags for{' '}
            </li> */}
            <li>Click "Settings" in the left side menu and then expand the "Additional Settings" link at the bottom</li>
            <li>Click "Campaign URL Options" and set the "Final URL Suffix" value to the following:</li>
            <ul className="tw-list-disc">
              <li>For utm_source, we recommend setting this to "google".</li>
              <li>
                For utm_medium, enter the type of campaign here. For example, "cpc" (cost per click), "email", or
                "display"
              </li>
              <li>For utm_id, use {`{campaignid}`}. Google will automatically insert your existing campaign ID.</li>
              <li>For utm_campaign, enter the name of the campaign.</li>
              <li>
                For utm_term, leave it as {`{keyword}`}. Google will automatically insert the keyword used in the Google
                search.
              </li>
            </ul>
            <li>Click Save</li>
          </ol>
        </div>
      </div>
    )
  },
  facebook_ads: ({ toggleAuditTip, auditTipOpen }) => {
    return (
      <div className="tw-rounded-lg tw-border tw-border-blue-400 tw-bg-blue-50 tw-px-4 tw-py-3 tw-mb-4">
        <div className="tw-flex tw-items-center tw-space-x-1.5 tw-cursor-pointer" onClick={() => toggleAuditTip()}>
          <div className={`tw-shrink-0 tw-w-4 tw-h-4 tw-transition-all ${auditTipOpen ? 'tw-rotate-90' : ''}`}>
            <AiFillCaretRight />
          </div>
          <div className="tw-font-bold">How to fix the missing campaigns in Facebook Ads</div>
        </div>
        <div className={`tw-mt-4 ${auditTipOpen ? 'tw-visible' : 'tw-hidden'}`}>
          <ol className="tw-list-decimal">
            <li>
              <b>Open Facebook Ads Manager</b>: Go to{' '}
              <a href="https://facebook.com/adsmanager" target="_blank">
                Facebook Ads Manager
              </a>{' '}
              and log in to your account
            </li>
            <li>
              <b>Select Your Campaign</b>: Click on the campaign you want to edit. This will open up the details of the
              campaign.
            </li>
            <li>
              <b>Go to Campaign Settings</b>:
            </li>
            <ul className="tw-list-disc">
              <li>In the campaign view, click on the "Edit" button to enter the campaign settings.</li>
              <li>Scroll down to find the "Tracking" section.</li>
            </ul>
            <li>
              <b>Edit URL Parameters</b>:
            </li>
            <ul className="tw-list-disc">
              <li>
                Under "Tracking," locate the URL Parameters field. Here, you can add or edit parameters that will be
                appended to your ad’s destination URL.
              </li>
              <li>Enter any UTM parameters or other tracking parameters you need for your campaign.</li>
              <li>For utm_source, we recommend setting this to "facebook" or "fb".</li>
              <li>
                For utm_medium, enter the type of campaign here. For example, "cpc" (cost per click), "email", or
                "display"
              </li>
              <li>
                For utm_id, use {`{{campaign.id}}`}. Facebook will automatically insert your existing campaign ID.
              </li>
              <li>For utm_campaign, enter the name of the campaign.</li>
              <li>
                For utm_term, Facebook does not automatically insert search keywords into utm_term, as it’s not a
                search-based platform. If you want specific terms here, add them manually or leave the field blank
              </li>
            </ul>
            <li>Click Save</li>
          </ol>
        </div>
      </div>
    )
  },
  klaviyo: ({ toggleAuditTip, auditTipOpen }) => {
    return (
      <div className="tw-rounded-lg tw-border tw-border-blue-400 tw-bg-blue-50 tw-px-4 tw-py-3 tw-mb-4">
        <div className="tw-flex tw-items-center tw-space-x-1.5 tw-cursor-pointer" onClick={() => toggleAuditTip()}>
          <div className={`tw-shrink-0 tw-w-4 tw-h-4 tw-transition-all ${auditTipOpen ? 'tw-rotate-90' : ''}`}>
            <AiFillCaretRight />
          </div>
          <div className="tw-font-bold">How to fix tracking or missing campaigns in Klaviyo</div>
        </div>
        <div className={`tw-mt-4 ${auditTipOpen ? 'tw-visible' : 'tw-hidden'}`}>
          <ol className="tw-list-decimal">
            <li>
              <b>Open Klaviyo Dashboard</b>: Go to{' '}
              <a href="https://www.klaviyo.com/login" target="_blank">
                Klaviyo
              </a>{' '}
              and log in to your account.
            </li>
            <li>
              <b>Access Campaigns</b>: In the left menu, go to <b>Campaigns</b> and select the campaign you need to edit.
            </li>
            <li>
              <b>Edit Campaign Settings</b>:
            </li>
            <ul className="tw-list-disc">
              <li>Click on the campaign name to open its settings.</li>
              <li>Navigate to the <b>Content</b> tab to edit the campaign content and tracking.</li>
            </ul>
            <li>
              <b>Check Tracking and URL Parameters</b>:
            </li>
            <ul className="tw-list-disc">
              <li>Ensure that tracking parameters like UTM tags are set up correctly in your links.</li>
              <li>
                In the <b>Link Tracking</b> section, enable UTM tracking if needed. Typical settings:
                <ul className="tw-list-disc tw-pl-6">
                  <li><b>utm_source</b>: Use "klaviyo" or your preferred source identifier.</li>
                  <li><b>utm_medium</b>: Set this to "email".</li>
                  <li><b>utm_campaign</b>: Enter the campaign name for easy tracking in analytics.</li>
                  <li><b>utm_id</b>: Use `{`{{campaign.id}}`}` to dynamically insert the campaign ID.</li>
                  <li><b>utm_content</b>: Use a unique identifier if you want to differentiate content versions.</li>
                </ul>
              </li>
            </ul>
            <li>
              <b>Save Changes</b>: Once you've reviewed the settings, save your changes.
            </li>
            <li>
              <b>Test Your Campaign</b>: Use the <b>Preview</b> or <b>Send Test Email</b> feature to confirm that your links are tracking correctly.
            </li>
          </ol>
        </div>
      </div>
    )
  },
}

function AuditModal({
  auditOpen,
  setAuditOpen,
  adPlatformOptions,
  toggleAuditTip,
  auditTipOpen,
  tableColumns,
  tableRows,
  adPlatformWatch,
}) {
  const [currentTab, setCurrentTab] = useState('google_ads')

  const tabs = adPlatformOptions.filter((platform) => platform.value !== 'summary').map((option) => ({
    name: option.value,
    title: option.label,
    content: adPlatformDescriptionDict[option.value]({ toggleAuditTip, auditTipOpen }),
  }))

  return adPlatformWatch !== 'summary' ? (
    <Sheet open={auditOpen} onOpenChange={() => setAuditOpen(!auditOpen)}>
      <SheetContent className="tw-w-[400px] sm:tw-w-4/12 sm:tw-min-w-[540px]">
        <SheetHeader>
          <SheetTitle className="tw-text-lg tw-font-bold">
            {adPlatformOptions?.find(({ value }) => value === adPlatformWatch)?.label} Audit
          </SheetTitle>
          <SheetDescription>
            <div className="tw-space-y-6">
              <div>
                <h3>Campaign Data Mismatch</h3>
                <div className="tw-mb-2">
                  We've noticed that some of your campaign data we received does not match the data in your{' '}
                  {adPlatformOptions?.find(({ value }) => value === adPlatformWatch)?.label} Ads account!
                </div>
                <div className="tw-mb-2">This is most likely due to incorrectly set utm tags.</div>
              </div>

              <div>
                <div className="tw-text-lg tw-font-bold tw-mb-4">Mismatching campaigns</div>

                {adPlatformDescriptionDict[adPlatformWatch] &&
                  adPlatformDescriptionDict[adPlatformWatch]({ toggleAuditTip, auditTipOpen })}

                <DataTable columns={tableColumns} data={tableRows} showPagination={true} thClassName="!tw-text-left" />
              </div>
            </div>
          </SheetDescription>
        </SheetHeader>
      </SheetContent>
    </Sheet>
  ) : (
    <Sheet open={auditOpen} onOpenChange={() => setAuditOpen(!auditOpen)}>
      <SheetContent className="tw-w-[400px] sm:tw-w-4/12 sm:tw-min-w-[540px]">
        <SheetHeader>
          <SheetTitle className="tw-text-lg tw-font-bold">Summary Data Audit</SheetTitle>
          <SheetDescription>
            <div className="tw-space-y-6">
              <div>
                <div className="tw-mb-2">
                  We've noticed that some of your campaign data we received does not match the data in your Ads account!
                </div>
                <div className="tw-mb-2">This is most likely due to incorrectly set utm tags.</div>
              </div>

              <div>
                <div className="tw-col-span-1 md:tw-col-span-9 2xl:tw-col-span-10">
                  <div className="tw-hidden md:tw-block">
                    <Tabs
                      defaultValue="google_ads"
                      className="tw-w-full"
                      value={currentTab}
                      onValueChange={(value) => setCurrentTab(value)}
                    >
                      <TabsList className="tw-w-full" variant="folder">
                        {tabs.map((tab, index) => (
                          <TabsTrigger key={index} value={tab.name}
                            className={`tw-px-4 tw-py-2 tw-font-medium tw-text-sm tw-rounded-t-md ${
                              currentTab === tab.name
                                ? 'tw-bg-gray-700 tw-text-white'
                                : 'tw-bg-gray-200 tw-text-gray-700'
                            }`}
                          >
                            {tab.title}
                          </TabsTrigger>
                        ))}
                      </TabsList>

                      {tabs.map((tab, index) => (
                        <TabsContent
                          key={index}
                          value={tab.name}
                          className={`-tw-mt-[1px] tw-border-l tw-border-r tw-border-b tw-border-t tw-rounded-bl-md tw-rounded-br-md tw-rounded-tr-md ${
                            index !== 0 ? 'tw-rounded-tl-md' : ''
                          } tw-border-slate-200 tw-bg-white`}
                        >
                          <div className="tw-p-4">
                            <div className="tw-text-lg tw-font-bold tw-mb-4">{tab.title} Mismatching campaigns</div>
                            <div key={index}>{tab.content}</div>
                            <DataTable
                              columns={tableColumns[tab.name]}
                              data={tableRows[tab.name]}
                              showPagination={true}
                              thClassName="!tw-text-left"
                            />
                          </div>
                        </TabsContent>
                      ))}
                    </Tabs>
                  </div>
                </div>
              </div>
            </div>
          </SheetDescription>
        </SheetHeader>
      </SheetContent>
    </Sheet>
  )
}

export default AuditModal
