import { facetNames, formatters } from 'modules/formatting'
import _ from 'lodash'

const defaultVisibleCharts = ['financial_status', 'processing_method', 'source_name', 'fulfillment_status', 'billing_country_code', 'billing_province', 'shipping_country_code', 'sold_hour']
export const initialChartConfigs = Object.assign({}, facetNames.reduce((acc, cur, index) => ({
  ...acc,
  [cur]: { visible: defaultVisibleCharts.includes(cur), chart_type: _.get(formatters.facets, [cur, 'default_chart'], 'column'), metric: 'total_orders', order: index }
}), {}))
export const updateConfigChartType = (config, newChartType) => {
  let nextConfig = config || { charts: initialChartConfigs }
  for (var facetName in newChartType) {
    nextConfig.charts[facetName].chart_type = newChartType[facetName]
  }
  return nextConfig
}