import React, { useState } from 'react'
import { Badge, Button } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import { removeTeamAccount } from '../../../settingsSlice'
import AccessPermissionChangeModal from './AccessPermissionChangeModal'

const StatusBadge = ({ color, label }) => {
  return <Badge pill={true} bg={color}>{label}</Badge>
}

const AccessPermission = ({ email, accessPermission, label }) => {
  const [modalVisible, setModalVisible] = useState(false)
  const openModal = () => setModalVisible(true)
  const closeModal = () => setModalVisible(false)

  if (!accessPermission || accessPermission?.length < 1) return null

  return (
    <>
      <div className="tw-flex tw-items-center tw-space-x-2">
        <div>{label}</div>
        <Button className="btn-sm" onClick={openModal}>Change</Button>
      </div>
      <AccessPermissionChangeModal
        email={email}
        accessPermission={accessPermission}
        visible={modalVisible}
        closeModal={closeModal}
      />
    </>
  )
}

const RemoveButton = ({ email, label }) => {
  const dispatch = useDispatch()

  const handleClick = async () => {
    try {
      await dispatch(removeTeamAccount(email))
      toast.success(`Account (${email}) successfully removed`)
    } catch (error) {
      console.log('Error in TeamAccountsTable RemoveButton', error)
      toast.error(`Something went wrong removing the account: ${email}`)
    }
  }

  return (
    <Button className="btn-sm" variant="danger" onClick={handleClick}>
      {label}
    </Button>
  )
}

export default function TeamAccountsTable({ accounts }) {
  const headings = ['Email', 'Status', 'Access Permission', 'Remove']

  if (!accounts || accounts.length < 1) return null

  return (
    <div className="tw-rounded-lg tw-border tw-border-slate-200 tw-w-full tw-text-sm tw-shadow-sm tw-overflow-auto">
      <table className="tw-table-auto tw-border-collapse tw-w-full">
        <thead className="tw-border-b tw-font-medium tw-text-slate-800 tw-bg-slate-50 tw-text-left">
          <tr>
            {headings.map((heading) => (
              <th key={heading} className="tw-px-4 tw-py-3">
                {heading}
              </th>
            ))}
          </tr>
        </thead>

        {accounts.map((account, i) => {
          if (!account) return null

          /**
           *
           * Handle status
           *
           */
          const statusObjMap = {
            invited: { color: 'secondary', statusLabel: 'Invited', cancelLabel: 'Cancel' },
            accepted: { color: 'success', statusLabel: 'Accepted', cancelLabel: 'Revoke' },
          }
          const statusObj = statusObjMap[account.status]

          /**
           *
           * Handle access permissions
           *
           */
          const accessPermissionLabel = _.every(account.access_permission, ['checked', true])
            ? 'Full Access Permission'
            : account.access_permission
                .filter((item) => item.checked)
                .map((item) => item.label)
                .join(', ')

          /**
           *
           * Records (table rows)
           *
           */
          const record = [
            account.shared_with,
            <StatusBadge color={statusObj.color} label={statusObj.statusLabel} />,
            <AccessPermission
              email={account.shared_with}
              accessPermission={account.access_permission}
              label={accessPermissionLabel}
            />,
            <RemoveButton email={account.shared_with} label={statusObj.cancelLabel} />,
          ]

          return (
            <tbody key={i}>
              <tr className={i !== 0 ? `tw-border-t tw-border-slate-100` : ''}>
                {record.map((value, j) => (
                  <td key={j} className="tw-px-4 tw-py-2">
                    {value}
                  </td>
                ))}
              </tr>
            </tbody>
          )
        })}
      </table>
    </div>
  )
}
