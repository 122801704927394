import { createSelector, createSlice } from '@reduxjs/toolkit'

/**
 *
 * Selectors
 *
 */
export const selectRootAccounts = (state) => state.root.accounts
export const selectRootUser = (state) => state.root.user
export const selectRootDomains = (state) => state.root.domains
export const selectRootAdmin = (state) => state.root.admin
export const selectRootUserDefaultTimezone = (state) => state.root.user.default_timezone
export const selectRootUserDefaultCurrency = (state) => state.root.user.default_currency
export const selectRootUserDefaultCurrencyShouldAutoFilter = (state) =>
  state.root.user.currencysettings.auto_filter_default_currency
