import React from 'react'

function SuccessMessage({ successful, successMessage }) {
  return (
    <div>
      {successful ? <div className="tw-text-green-800 tw-mb-2">{successMessage || 'Orders uploaded successfully!'}</div> : null}
    </div>
  )
}

export default SuccessMessage
