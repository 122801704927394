import hosts from 'hosts.json'
import querystring from 'querystring'
import EtsyLogo from '../assets/images/logos/etsy.svg'

export const baseUrl = hosts.apiHost[location.hostname]

const baseNotificationUrl = hosts.apiNotificationHost[location.hostname]

export const appUrl = hosts.appHost.default

export const getUserLinkedAccounts = `${baseUrl}/accounts`

export const getBackfill = `${baseNotificationUrl}/backfill/user`

export const getShopifyBackfill = `${baseNotificationUrl}/backfill/shopify`

export const getPaypalBackfill = `${baseNotificationUrl}/backfill/paypal`

export const getMerchantPaypalBackfill = `${baseNotificationUrl}/backfill/paypal_merchant`

export const getBigcommerceBackfill = `${baseNotificationUrl}/backfill/bigcommerce`

export const getWoocommerceBackfill = `${baseNotificationUrl}/backfill/woocommerce`

export const getEtsyBackfill = `${baseNotificationUrl}/backfill/etsy`

export const getEbayBackfill = `${baseNotificationUrl}/backfill/ebay`

export const getWalmartBackfill = `${baseNotificationUrl}/backfill/walmart`

export const getAmazonBackfill = `${baseNotificationUrl}/backfill/amazon`

export const getWixBackfill = `${baseNotificationUrl}/backfill/wix`

export const getBackfillStatus = `${baseNotificationUrl}/backfill/status`

export const getBackfillRunningStatus = `${baseNotificationUrl}/backfill/running_status`

export const checkSlackConnectionUrl = `${baseUrl}/slackstatus/status`

export const getEmailPreview = (channel, merchantId) => `${baseNotificationUrl}/notification/${channel.toLowerCase()}/email_preview?merchant_id=${merchantId}`

export const putCredentials = `${baseUrl}/intercom/credentials`

export const dataConnectorsConfigUrl = `${baseUrl}/dataconnectors/config`

export const shipstationConfigUrl = `${baseUrl}/dataconnectors/shipstation_config`

export const shippingeasyConfigUrl = `${baseUrl}/dataconnectors/shippingeasy_config`

export const shipsuranceConfigUrl = `${baseUrl}/dataconnectors/shipsurance_config`

export const shipstationStoresUrl = `${baseUrl}/dataconnectors/shipstation_stores`

export const getEmailCampaignConfig = `${baseUrl}/emails/get_config`

export const saveEmailCampaignConfig = `${baseUrl}/emails/save_config`

export const sendEmailReportUrl = `${baseUrl}/emails/email_reports_v1`

export const awsSecretsManagerUrl =`${baseUrl}/aws/secrets-manager`
export const integrationsKeysUrl =`${baseUrl}/integrations-keys`
export const slackApiMessagesUrl =`${baseUrl}/slack-api/messages`
export const backfillUrl =`${baseUrl}/backfill`
export const openAIUrl =`${baseUrl}/openai`

export const loginsVerify = `${baseUrl}/logins/verify`
export const signOutUrl = `${baseUrl}/signout`

export const accountLinking = (channel, merchantId, userId = null) => {
  let url, referer, newWindow
  if (channel === 'shopify') {
    if (merchantId) url = `${baseUrl}/auth/shopify?shop=${encodeURIComponent(merchantId)}`
    url = `${baseUrl}/auth/shopify`
  } else if (channel==="google") {
    url = `${baseUrl}/auth/google`
  } else if (channel === 'paypal') {
    url = `${baseUrl}/auth/paypal`
  } else if (channel==='woocommerce') {
    const endpoint = '/wc-auth/v1/authorize/'
    const params = {
      app_name: 'Payhelm',
      scope: 'read_write',
      user_id: merchantId,
      return_url: `https://${hosts.appHost.default}/settings`,
      callback_url: `${baseUrl}/auth/woocommerce?userid=${userId}`
    }
    const query_string = querystring.stringify(params).replace(/%20/g, '+')
    const domain = merchantId.replace(/https?:\/\//, '')
    url = `https://${domain}${endpoint}?${query_string}`
    if (window !== window.top) referer = `https://${domain}/wp-admin/admin.php?page=dashboard`
  } else if (channel === 'amazon') {
    url = `${baseUrl}/auth/amazon/link`
  } else if (channel === 'google ads'){
    url = `${baseUrl}/auth/google/adwords`
    newWindow = window !== window.top
  } else if (channel === 'google_drive') {
    url = `${baseUrl}/auth/google-drive`
    newWindow = window !== window.top
  } else if (channel === 'facebook_ads' || channel === 'facebook ads') {
    url = `${baseUrl}/auth/facebook`
    newWindow = window !== window.top
  } else if (channel === 'google_eco') {
    url = `${baseUrl}/auth/google/eco`
    newWindow = window !== window.top
  } else if (channel === 'walmart') {
    url = `${baseUrl}/auth/walmart`
    newWindow = window !== window.top
  } else if (channel === 'ebay') {
    newWindow = window !== window.top
    url = `${baseUrl}/auth/ebay/link?from_page=link`
  } else {
    url = `${baseUrl}/permissions`
  }
  return { url, referer, newWindow }
}

export const accountLinkingUrls = {
  paypal: `${baseUrl}/auth/paypal`,
  shopify: `${baseUrl}/auth/shopify`,
  bigcommerce: `${baseUrl}/auth/bigcommerce`,
  bigcommerceAppStore: 'https://login.bigcommerce.com/deep-links/marketplace/apps/19100',
  shopifyAppStore: `${baseUrl}/auth/shopify/app_store`,
  etsy: `${baseUrl}/auth/etsy`,
  hubspot: `${baseUrl}/auth/hubspot`,
  ups: `${baseUrl}/auth/ups`,
  ebay: `${baseUrl}/auth/ebay/link?from_page=link`,
  wixAppStore: 'https://wix.to/idqBqyR',
  walmart: `${baseUrl}/auth/walmart`,
}

// Subscriber URLs
export const getTypeSet = (filterQuery) => `${baseUrl}/subscribers/typeset${filterQuery}`
export const getTransactionStats = `${baseUrl}/subscribers/typeset`
export const getSubscriberCustomers = (range, type) => `${baseUrl}/subscribers/potentialchurn?range=${range}&type=${type}`
export const getSubscriberOverview = (start, end) => `${baseUrl}/subscribers/churnoverview?start_date=${start}&end_date=${end}`
export const getSubscriberAnalysis = (start, end) => `${baseUrl}/subscribers/churn?start_date=${start}&end_date=${end}`
export const getSubscriberMRRUrl = (start, end) => `${baseUrl}/subscribers/mrr?start=${start}&end=${end}`
export const getRetention = (start, end, groupBy) => `${baseUrl}/subscribers/retention?start=${start}&end=${end}&groupBy=${groupBy}`
export const transactionsMap = (start, end, accountFilter) => `${baseUrl}/subscribers/countries?start=${start}&end=${end}${accountFilter ? '&filter=' + accountFilter : ''}`
// Subscriber URLs but not used
// export const transactionsTrends = (start, end, accountFilter) => `${baseUrl}/subscribers/trends?start=${start}&end=${end}${accountFilter ? '&filter=' + accountFilter : ''}`
// export const transactionsDetails = (start, end, perPage, page, filterQuery) => `${baseUrl}/subscribers/rows?start=${start}&end=${end}&limit=${perPage}&page=${page - 1}${filterQuery}`

export const getInternalStatistics = `${baseUrl}/internal/statistics`

export const getUser = `${baseUrl}/user/search/`

export const getUserList = `${baseUrl}/user/search`

export const getAdminUser = `${baseUrl}/user/level`

export const mimicUserUrl = `${baseUrl}/user/mimic/`

export const updateUserUrl = `${baseUrl}/user/insurance-tier`

export const unMimicUserUrl = `${baseUrl}/mimic/unmimic/`

export const mimicStatusUrl = `${baseUrl}/mimic/status/`

export const verifyLogin = `${baseUrl}/logins/verify`

export const verifyBigcommerceLogin = `${baseUrl}/auth/bigcommerce/verify`

export const verifyWoocommerceLogin = `${baseUrl}/auth/woocommerce/verify`

export const verifyShopifyLogin = `${baseUrl}/auth/shopify/verify`
export const checkShopifyExistence = `${baseUrl}/auth/shopify/check_existence`

export const authFail = `${baseUrl}/auth/fail`

export const inkfrogAuth = `${baseUrl}/inkfrog/inkfrog_auth`

export const inkfrogMarketing = `${baseUrl}/inkfrog/inkfrog_marketing`

export const createInkfrogAccount = `${baseUrl}/inkfrog/inkfrog_create_account`

export const updateInkfrogAgreeTo = `${baseUrl}/inkfrog/inkfrog_update_agree_to`

export const getCustomerInfoUrl = `${baseUrl}/plan/customer_info`

export const createCreditCardUrl = `${baseUrl}/plan/create_card`

export const deleteCreditCardUrl = `${baseUrl}/plan/delete_card`

export const changePlanUrl = `${baseUrl}/plan/change_plan`

export const updateAddOnUrl = `${baseUrl}/plan/update_add_on`

export const getStripeInvoicesUrl = `${baseUrl}/plan/stripe_invoices`

export const createCustomerChargeUrl = `${baseUrl}/plan/create_charge`

export const updateAutoDepositSettingUrl = `${baseUrl}/plan/update_auto_deposit_setting`

export const getCustomerTransactionBalanceUrl = `${baseUrl}/plan/stripe_customer_balance_transactions`

export const autocompleteUrl = (keyword) => `${baseUrl}/complete?q=${encodeURIComponent(keyword)}`

export const sharedAccountInvitationUrl = `${baseUrl}/invite`

export const updateInvitePermissionUrl = `${baseUrl}/invite/update`

export const getInviteSharedAccounts = `${baseUrl}/invite/shared_accounts`

export const getUserSharedAccountUrl = `${baseUrl}/invite/user`

export const preferencesUrl = `${baseUrl}/preferences`
export const userAPIKeyUrl = `${baseUrl}/api-key`
export const searchUrl = `${baseUrl}/search`
export const reportsUrl = `${baseUrl}/reports`
export const customReportUrl = `${baseUrl}/custom_reports`
export const emailReportUrl = `${baseUrl}/emails/email_reports`
export const googleDriveReportUrl = `${baseUrl}/google/generate_report`
export const trafficCartsReportUrl = `${baseUrl}/traffic/carts`
export const trafficMetricsUrl = `${baseUrl}/traffic/carts/metrics`
// export const trafficCountryCodesUrl = `${baseUrl}/traffic/carts/metrics/country-codes`
export const trafficUniqueFieldValuesUrl = `${baseUrl}/traffic/carts/metrics/unique-values`

export const filtersUrl = `${baseUrl}/filters`

export const shippingUrl = `${baseUrl}/shipping`

export const inventoryUrl = `${baseUrl}/inventory`

export const googleAdsUrl = `${baseUrl}/google/ads`
export const googleAdsReportingUrl = `${baseUrl}/google/ads-reporting`
export const googleAdsMetricsSummaryUrl = `${baseUrl}/traffic/carts/google-ads/metrics/summary`
export const googleAdsMetricsUrl = `${baseUrl}/traffic/carts/google-ads/metrics`

export const fbAdsMetricsSummaryUrl = `${baseUrl}/traffic/carts/facebook-ads/metrics/summary`

export const klaviyoAdsMetricsSummaryUrl = `${baseUrl}/traffic/carts/klaviyo-ads/metrics/summary`

export const getGoogleSheetsUrl = `${baseUrl}/google/sheets`

export const uploadOrdersUrl = `${baseUrl}/upload-orders`

export const getGoogleTokenUrl = `${baseUrl}/auth/google/access_token`

export const getGoogleDriveTokenUrl = `${baseUrl}/auth/google-drive/access_token`

export const apiStoreUrl = `${baseUrl}/api_store`

export const feedsUrl = `${baseUrl}/feeds`

export const facebookUrl = `${baseUrl}/facebook`

export const payhelmPixelAccounteUrl = `${baseUrl}/payhelm_pixel`

// Logo urls
export const logoUrls = {
  'paypal': `//${hosts.appHost.default}/assets/images/paypal-icon.png`,
  'paypal merchant': `//${hosts.appHost.default}/assets/images/paypal-icon.png`,
  shopify: `//${hosts.appHost.default}/assets/images/shopify-icon.png`,
  bigcommerce: `//${hosts.appHost.default}/assets/images/bigcommerce-icon.png`,
  'google': `//${hosts.appHost.default}/assets/images/google-icon.png`,
  'google ads': `//cdn.icon-icons.com/icons2/2699/PNG/512/google_ads_logo_icon_171064.png`,
  shipstation: `//${hosts.appHost.default}/assets/images/shipstation-icon.png`,
  shippingeasy: `//${hosts.appHost.default}/assets/images/shippingeasy-icon.png`,
  woocommerce: `//${hosts.appHost.default}/assets/images/woocommerce-icon.png`,
  wix: `//${hosts.appHost.default}/assets/images/wix-logo.png`,
  etsy: 'https://www.etsy.com/images/favicon-32x32.png',
  amazon: `//${hosts.appHost.default}/assets/images/amazon-icon.svg`,
  klaviyo: `//${hosts.appHost.default}/assets/images/klaviyo-icon.svg`,
  openai: `//${hosts.appHost.default}/assets/images/openai-icon.svg`,
  microsoft: `//${hosts.appHost.default}/assets/images/microsoft-icon.svg`,
  'apistore': `//${hosts.appHost.default}/assets/images/api_store.svg`,
  eBay:`//${hosts.appHost.default}/assets/images/ebay.svg`,
  walmart: `//${hosts.appHost.default}/assets/images/walmart-icon.png`,
}

// unlink urls
export const unlinkUrls = {
  walmart: `${baseUrl}/unlink/walmart`,
}

// Deprecated urls
export const competitorsUrl = `${baseUrl}/research/competitors`
export const getHotProductsUrl = `${baseUrl}/research/hot-products`
export const trackUrl = `${baseUrl}/research/track`
export const searchHistoryUrl = `${trackUrl}/searches/history`
export const ebaySellTokenUrl = `${baseUrl}/research/tokens/ebay-sell`
export const getEbaySellTokenUrl = (applicationId) => `${baseUrl}/auth/ebay/sell?from_page=${applicationId}`
export const categoriesUrl = `${baseUrl}/research/categories`
export const trafficReportUrl = `${baseUrl}/research/traffic/report`