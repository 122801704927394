import moment from 'momentExt'
import queryString from 'query-string'
import { localStoragePayhelm, getDefaultFilterType, } from 'modules/utils'

const queryJson = queryString.parse(location.search)
let startDate, endDate, formatStr = 'YYYY-MM-DD'
try {
  if (queryJson.startDate && queryJson.endDate) {
    startDate = moment(queryJson.startDate)
    endDate = moment(queryJson.endDate)
  }
  else if (localStoragePayhelm.startDate && localStoragePayhelm.endDate) {
    startDate = moment(localStoragePayhelm.startDate)
    endDate = moment(localStoragePayhelm.endDate)
  }
  else {
    startDate = moment().subtract(1, 'month').add(1, 'day')
    endDate = moment()
  }
  if (!startDate.isValid() || !endDate.isValid()) {
    throw('Date invalid')
  }
}
catch (err) {
  console.error(err)
  startDate = moment().subtract(1, 'month').add(1, 'day')
  endDate = moment()
}

let savedDateType
if (localStoragePayhelm.dateFilters) {
  const dateFilters = JSON.parse(localStoragePayhelm.dateFilters)
  savedDateType = dateFilters.dateTypes ? getDefaultFilterType(dateFilters.dateTypes).defaultFilterType : undefined
}

const initialState = {
  startDate: startDate.format(formatStr),
  endDate: endDate.format(formatStr),
  periodCompare: queryJson.periodCompare || localStoragePayhelm.periodCompare || 'none',
  dateType: queryJson.dateType || savedDateType || 'processed_at',
}

export default function reducer(state = initialState, action) {
  switch(action.type) {
    case 'CHANGE_DATE_RANGE_DATE_PICKER': {
      return {
        ...state,
        startDate: action.payload.startDate,
        endDate: action.payload.endDate,
      }
    }
    case 'CHANGE_PERIOD_CONFIG_DATE_PICKER': {
      const { periodCompare } = action.payload
      return {
        ...state,
        periodCompare: periodCompare || 'none',
      }
    }
    case 'CHANGE_DATE_TYPE_DATE_PICKER': {
      return {
        ...state,
        dateType: action.payload,
      }
    }
  }
  return state
}