import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import _, { create } from 'lodash'
import {
  dataConnectorsConfigUrl,
  shipstationConfigUrl,
  shipsuranceConfigUrl,
  sharedAccountInvitationUrl,
  updateInvitePermissionUrl,
  preferencesUrl,
  userAPIKeyUrl,
} from 'urls'
import { getAxiosDefaultConfig } from '../../modules/utils'

export const initialState = {
  sellApiTokenStatus: {},
  sellApiTokenFetched: false,
  dataConnectors: {
    slackConfigs: {
      status: 'idle',
      error: null,
      data: {
        subscriptions: {
          cancellations: { title: 'Cancellations', value: true },
          recurring_charges: { title: 'Charges', value: true },
          payment_charges: { title: 'Payment', value: true },
          failed_charges: { title: 'Failed Charges', value: true },
          new_subscriptions: { title: 'New Subscriptions', value: true },
        },
        paypal: {
          Purchases: { title: 'Purchases', value: true },
        },
        shopify: {
          ItemsDelivered: { title: 'Items Delivered', value: true },
          ItemsShipped: { title: 'Items Shipped', value: true },
          OrderConfirmed: { title: 'Order Confirmed', value: true },
        },
        bigcommerce: {
          Completed: { title: 'Completed', value: true },
          Shipped: { title: 'Items Shipped', value: true },
          'Awaiting Fulfillment': { title: 'Awaiting Fulfillment', value: true },
          'Awaiting Payment': { title: 'Awaiting Payment', value: true },
          'Partially Shipped': { title: 'Partially Shipped', value: true },
        },
      },
    },
    shipStationConfigs: {
      status: 'idle',
      error: null,
      data: [],
    },
    shipsuranceConfigs: {
      status: 'idle',
      error: null,
      data: [],
    }
  },
  teamAccounts: {
    status: 'idle',
    error: null,
    data: [],
  },
  preferences: {
    status: 'idle',
    error: null,
    data: {
      defaultTimezone: null,
      defaultCurrency: 'USD',
      currencySettings: {},
      shopifySettings: {},
      bigcommerceSettings: {},
      amazonSettings: {},
      walmartSettings: {},
      contacts: {},
      socialAccounts: {},
      shippingSettings: {},
    },
  },

}

export const fetchSlackConfigs = createAsyncThunk('appSettings/fetchSlackConfigs', async () => {
  let res = await axios.get(dataConnectorsConfigUrl, getAxiosDefaultConfig())
  return _.isEmpty(res?.data?.slackconfig) ? initialState.dataConnectors.slackConfigs.data : res?.data?.slackconfig
})

export const updateSlackConfigs = createAsyncThunk('appSettings/updateSlackConfigs', async (payload) => {
  let res = await axios.put(dataConnectorsConfigUrl, { slackConfig: payload }, getAxiosDefaultConfig())
  return res?.data?.data
})

export const fetchShipStationConfigs = createAsyncThunk('appSettings/fetchShipStationConfigs', async () => {
  let res = await axios.get(shipstationConfigUrl, getAxiosDefaultConfig())
  return res?.data
})

export const updateShipStationConfigs = createAsyncThunk('appSettings/updateShipStationConfigs', async (payload) => {
  let res = await axios.put(shipstationConfigUrl, payload, getAxiosDefaultConfig())
  return res?.data?.data
})

export const fetchShipsuranceConfigs = createAsyncThunk('appSettings/fetchShipsuranceConfigs', async () => {
  let res = await axios.get(shipsuranceConfigUrl, getAxiosDefaultConfig())
  return res?.data
})

export const updateShipsuranceConfigs = createAsyncThunk('appSettings/updateShipsuranceConfigs', async (payload) => {
  let res = await axios.put(shipsuranceConfigUrl, payload, getAxiosDefaultConfig())
  return res?.data?.data
})

export const fetchTeamAccounts = createAsyncThunk('appSettings/fetchTeamAccounts', async () => {
  let res = await axios.get(sharedAccountInvitationUrl, getAxiosDefaultConfig())
  return res?.data
})

export const addTeamAccount = createAsyncThunk('appSettings/addTeamAccount', async (invited) => {
  let res = await axios.post(sharedAccountInvitationUrl, { invited }, getAxiosDefaultConfig())
  return res?.data?.[0]
})

export const updateTeamAccount = createAsyncThunk(
  'appSettings/updateTeamAccount',
  async ({ invited, accessPermission }) => {
    let res = await axios.post(updateInvitePermissionUrl, { invited, accessPermission }, getAxiosDefaultConfig())
    return res?.data?.[0]
  }
)

export const removeTeamAccount = createAsyncThunk('appSettings/removeTeamAccount', async (invited) => {
  let res = await axios.delete(sharedAccountInvitationUrl, {
    ...getAxiosDefaultConfig(),
    data: { invited },
  })
  return invited
})

export const fetchPreferences = createAsyncThunk('appSettings/fetchPreferences', async () => {
  let res = await axios.get(preferencesUrl, getAxiosDefaultConfig())
  return res?.data
})

export const updatePreferences = createAsyncThunk('appSettings/updatePreferences', async (payload) => {
  let res = await axios.post(preferencesUrl, payload, getAxiosDefaultConfig())

  return res?.data
})

export const createUserAPIKey = createAsyncThunk('appSettings/createUserAPIKey', async (payload) => {
  let res = await axios.post(userAPIKeyUrl, payload, getAxiosDefaultConfig())
  return res?.data?.data
})

export const settingsSlice = createSlice({
  name: 'appSettings', // temporary name until we can remove the old settings state altogether
  initialState,
  reducers: {
  },
  extraReducers(builder) {
    builder
      /**
       *
       * slackConfigs api
       *
       */
      .addCase(fetchSlackConfigs.pending, (state, action) => {
        state.dataConnectors.slackConfigs.status = 'loading'
      })
      .addCase(fetchSlackConfigs.fulfilled, (state, action) => {
        state.dataConnectors.slackConfigs.status = 'succeeded'
        state.dataConnectors.slackConfigs.data = action.payload
      })
      .addCase(fetchSlackConfigs.rejected, (state, action) => {
        state.dataConnectors.slackConfigs.status = 'failed'
        state.dataConnectors.slackConfigs.error = action.error.message
      })
      .addCase(updateSlackConfigs.fulfilled, (state, action) => {
        state.dataConnectors.slackConfigs.data = action.payload
      })

      /**
       *
       * shipStationConfigs api
       *
       */
      .addCase(fetchShipStationConfigs.pending, (state, action) => {
        state.dataConnectors.shipStationConfigs.status = 'loading'
      })
      .addCase(fetchShipStationConfigs.fulfilled, (state, action) => {
        state.dataConnectors.shipStationConfigs.status = 'succeeded'
        state.dataConnectors.shipStationConfigs.data = action.payload
      })
      .addCase(fetchShipStationConfigs.rejected, (state, action) => {
        state.dataConnectors.shipStationConfigs.status = 'failed'
        state.dataConnectors.shipStationConfigs.error = action.error.message
      })
      .addCase(updateShipStationConfigs.fulfilled, (state, action) => {
        state.dataConnectors.shipStationConfigs.data = action.payload
      })

      /**
       *
       * shipsuranceConfigs api
       *
       */
      .addCase(fetchShipsuranceConfigs.pending, (state, action) => {
        state.dataConnectors.shipsuranceConfigs.status = 'loading'
      })
      .addCase(fetchShipsuranceConfigs.fulfilled, (state, action) => {
        state.dataConnectors.shipsuranceConfigs.status = 'succeeded'
        state.dataConnectors.shipsuranceConfigs.data = action.payload
      })
      .addCase(fetchShipsuranceConfigs.rejected, (state, action) => {
        state.dataConnectors.shipsuranceConfigs.status = 'failed'
        state.dataConnectors.shipsuranceConfigs.error = action.error.message
      })
      .addCase(updateShipsuranceConfigs.fulfilled, (state, action) => {
        state.dataConnectors.shipsuranceConfigs.data = action.payload
      })

      /**
       *
       * teamAccounts api
       *
       */
      .addCase(fetchTeamAccounts.pending, (state, action) => {
        state.teamAccounts.status = 'loading'
      })
      .addCase(fetchTeamAccounts.fulfilled, (state, action) => {
        state.teamAccounts.status = 'succeeded'
        state.teamAccounts.data = action.payload
      })
      .addCase(fetchTeamAccounts.rejected, (state, action) => {
        state.teamAccounts.status = 'failed'
        state.teamAccounts.error = action.error.message
      })
      .addCase(addTeamAccount.fulfilled, (state, action) => {
        state.teamAccounts.data = [...state.teamAccounts.data, action.payload]
      })
      .addCase(updateTeamAccount.fulfilled, (state, action) => {
        const index = _.findIndex(state.teamAccounts.data, { shared_with: action.payload.shared_with })
        if (index !== -1) {
          state.teamAccounts.data[index] = action.payload
        }
      })
      .addCase(removeTeamAccount.fulfilled, (state, action) => {
        state.teamAccounts.data = _.reject(
          state.teamAccounts.data,
          (teamAccount) => teamAccount.shared_with === action.meta.arg
        )
      })

      /**
       *
       * preferences api
       *
       */
      .addCase(fetchPreferences.pending, (state, action) => {
        state.preferences.status = 'loading'
      })
      .addCase(fetchPreferences.fulfilled, (state, action) => {
        state.preferences.status = 'succeeded'
        state.preferences.data = action.payload
      })
      .addCase(fetchPreferences.rejected, (state, action) => {
        state.preferences.status = 'failed'
        state.preferences.error = action.error.message
      })
      .addCase(updatePreferences.fulfilled, (state, action) => {
        state.preferences.data = action.payload
      })
  },
})

export const {} = settingsSlice.actions

/**
 *
 * Selectors
 *
 */
export const selectSlackConfigStatus = (state) => state.appSettings.dataConnectors.slackConfigs.status
export const selectSlackConfig = (state) => state.appSettings.dataConnectors.slackConfigs.data
export const selectShipStationConfigStatus = (state) => state.appSettings.dataConnectors.shipStationConfigs.status
export const selectShipStationConfig = (state) => state.appSettings.dataConnectors.shipStationConfigs.data
export const selectShipsuranceConfigStatus = (state) => state.appSettings.dataConnectors.shipsuranceConfigs.status
export const selectShipsuranceConfig = (state) => state.appSettings.dataConnectors.shipsuranceConfigs.data
export const selectTeamAccountsStatus = (state) => state.appSettings.teamAccounts.status
export const selectTeamAccounts = (state) => state.appSettings.teamAccounts.data
export const selectPreferencesStatus = (state) => state.appSettings.preferences.status
export const selectPreferences = (state) => state.appSettings.preferences.data


export default settingsSlice.reducer
