import React, { useEffect, useRef, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { useForm } from 'react-hook-form'
import ReactMarkdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'
import remarkMath from 'remark-math';
import rehypeKatex from 'rehype-katex';
import 'katex/dist/katex.min.css';
import Author from './components/Author'
import Chart from './components/Chart'
import Trends from './components/Trends'
import { useDispatch, useSelector } from 'react-redux'
import { selectRootUser } from '../../rootSlice'
import {
  callOpenAIChat,
  rateOpenAIChat,
  fetchOpenAIAccount,
  fetchOpenAIChatHistory,
  selectOpenAIAccount,
  selectDashboardConfig,
  selectDashboardFilters,
  selectKeywordFilters,
  selectOpenAIChatHistory,
  selectOpenAIChatHistoryStatus,
  selectRangeFilters,
  selectOpenAIAccountStatus,
  createOpenAIAccount,
  updateOpenAIAccount,
} from '../../features/openai/openaiSlice'
import { getSecretId } from '../Integrations/channels/generic/helpers'
import { getOwnerId } from '../../modules/utils'
import { fetchTeamAccounts, selectTeamAccounts, selectTeamAccountsStatus } from '../Settings/settingsSlice'
import ChatGPTLogo from '../../assets/images/logos/chatgpt.svg'
import { BiLoaderAlt } from 'react-icons/bi'
import MySelect from '../../features/forms/MySelect'
import { useWindowSize } from 'react-use'
import ReactTooltip from 'react-tooltip'
import { replaceLinks } from './helpers'
import { Button, Modal, } from 'react-bootstrap'
import MyCheckbox from '../../features/forms/MyCheckbox'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { changeApplication } from 'actions/rootActions'

const markdownComponents = {
  p: ({node, ...props}) => (
    <div {...props} />
  ),
  ul: ({node, ...props}) => (
    <ul className='my1' {...props} />
  )
};

function AI({ accounts, balance, checkAccess, isOwner }) {
  const dispatch = useDispatch()

  const { width: windowWidth, height: windowHeight } = useWindowSize()
  const HEADER_HEIGHT = 52
  const FOOTER_HEIGHT = 76
  const CHAT_TOP_MENU_HEIGHT = 84
  const CHAT_INPUT_SECTION_HEIGHT = 64
  const CHAT_INPUT_HEIGHT = 44
  const MESSAGES_HEIGHT =
    windowHeight - CHAT_TOP_MENU_HEIGHT - CHAT_INPUT_SECTION_HEIGHT - HEADER_HEIGHT - FOOTER_HEIGHT

  /**
   *
   * Fetch user and team accounts so we can get the ownerId
   *
   */
  const user = useSelector(selectRootUser)
  const teamAccounts = useSelector(selectTeamAccounts)
  const teamAccountsStatus = useSelector(selectTeamAccountsStatus)
  useEffect(() => {
    checkAccess()
  }, [])
  useEffect(() => {
    if (teamAccountsStatus === 'idle') {
      dispatch(fetchTeamAccounts())
    }
  }, [teamAccountsStatus])
  const ownerId = getOwnerId(user, teamAccounts)
  const openAIKeyId = getSecretId(ownerId, 'openai')

  /**
   *
   * Fetch dashboard configs and filters
   *
   */
  const dashboardConfig = useSelector(selectDashboardConfig)
  const rangeFilters = useSelector(selectRangeFilters)
  const keywordFilters = useSelector(selectKeywordFilters)
  const dashboardFilters = useSelector(selectDashboardFilters)

  /**
   *
   * Form setup
   *
   */
  const formRef = useRef(null)
  const gptFieldName = `gpt`

  const {
    register,
    handleSubmit,
    watch,
    reset: resetForm,
    resetField,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      model: 'gpt-3.5-turbo-1106',
      [gptFieldName]: '',
      overview: false,
      products: false,
      marketing: false,
      geographics: false,
      customers: false,
    },
  })

  const gptWatch = watch(gptFieldName)

  const [loadingMessage, setLoadingMessage] = useState(null)

  const onSubmit = async (values) => {
    setLoadingMessage('Analyzing...')
    const timeouts = []
    timeouts.push(setTimeout(() => setLoadingMessage('Fetching your data...'), 4000))
    timeouts.push(setTimeout(() => setLoadingMessage('Thinking...'), 9000))
    timeouts.push(setTimeout(() => setLoadingMessage('Processing a lot of data...'), 15000))
    timeouts.push(setTimeout(() => setLoadingMessage('Generating response...'), 25000))
    timeouts.push(setTimeout(() => setLoadingMessage('Almost finished...'), 40000))
    timeouts.push(setTimeout(() => setLoadingMessage('This is taking a little longer than usual...'), 55000))
    resetField(gptFieldName)

    const messageContent = values[gptFieldName]
    const model = values.model

    const userMessageId = uuidv4()
    const chatGPTId = uuidv4()
    const newMessages = [
      ...messages,
      { id: userMessageId, author: { email: user.email }, content: messageContent },
      {
        id: chatGPTId,
        author: { picture: ChatGPTLogo, email: null },
        content: null,
      },
    ]
    setMessages(newMessages)

    // Call OpenAI Chat API
    const isMimic = user?.is_mimic
    const ADMIN_OPENAI_KEY_ID = "7563ffce0b7a7c7d5d137cbb5dd37e819fde8665918eab3ef7e30380affb58a6--openai"

    const openAIChatPayload = {
      isMimic,
      key_id: openAIKeyId,
      admin_key_id: ADMIN_OPENAI_KEY_ID,
      userMessage: messageContent,
      model,
      customReportConfig: {
        startDate: { timeframe: 'months', decrement: 1 },
        interval: 'MONTHLY',
        customReport: {
          app_email: user.email,
          name: user.email,
          dashboard_config: dashboardConfig,
          filters: dashboardFilters,
          filter_configs: { rangeFilters, keywordFilters },
        },
      },
    }

    const filteredMessages = newMessages.filter((message) => message.id !== chatGPTId)
    try {
      const { payload: openAIChatRes } = await dispatch(callOpenAIChat(openAIChatPayload))
  
      if (openAIChatRes.error) {
        setMessages([
          ...filteredMessages,
          { author: { picture: ChatGPTLogo, email: null }, content: openAIChatRes.error?.message },
        ])
      } else {
        // Replace links in the message with anchor tags
        const chatResMessageWithLinks = replaceLinks(openAIChatRes?.choices?.[0]?.message?.content)
  
        setMessages([
          ...filteredMessages,
          {
            author: { picture: ChatGPTLogo, email: null, },
            content: chatResMessageWithLinks,
            chart: openAIChatRes?.chart,
            facet: openAIChatRes?.facet,
            overviewData: openAIChatRes?.overviewData,
            messageId: openAIChatRes?.messageId,
            rating: openAIChatRes?.messageId ? null : undefined,
            startDate: openAIChatRes?.startDate,
            endDate: openAIChatRes?.endDate,
          },
        ])
      }
    } catch(e) {
      setMessages([
        ...filteredMessages,
        {
          author: { picture: ChatGPTLogo, email: null, },
          content: 'Something went wrong. Please try again.',
          rating: undefined,
        },
      ])
      timeouts.forEach(clearTimeout)
      setLoadingMessage('')
    }

    // Update the chat history view
    dispatch(fetchOpenAIChatHistory({ app_email: user.email }))
  }

  const setRating = async (messageId, rating) => {
    setMessages(messages.map((message) => ({ ...message, rating: message.messageId === messageId ? rating : message.rating, })))
    const { payload: openAIChatRes } = await dispatch(rateOpenAIChat({ message_id: messageId, rating: rating, }))
  }

  const [settings, setSettings] = useState(false)
  const [mounted, setMounted] = useState(false)

  /**
   *
   * Handle messages (including scrolling to bottom)
   *
   */
  const [messages, setMessages] = useState([
    {
      author: { picture: ChatGPTLogo, email: 'ChatGPT', },
      // content: balance !== 0
      //   ? 'Hey! I\'m in beta, so I\'m still learning. Please ask me a question.'
      //   : 'Welcome to Ask AI! To get started asking me questions, <a href="/plan?view=billing">click here</a> to reload your balance.',
      content: 'Hey! I\'m in beta, so I\'m still learning and I\'m free to use right now. Please ask me a question.',
    },
  ])
  const messagesEndRef = useRef(null)

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' })
  }

  const [prevMessagesLength, setPrevMessagesLength] = useState(0)

  useEffect(() => {
    if (mounted) {
      if (messages.length > prevMessagesLength) scrollToBottom()
      setPrevMessagesLength(messages.length)
    }
    if (!mounted) setMounted(true)
  }, [messages, mounted, prevMessagesLength])

  /**
   *
   * Handle OpenAI Chat History
   *
   */
  const openAIChatHistoryStatus = useSelector(selectOpenAIChatHistoryStatus)
  const openAIChatHistory = useSelector(selectOpenAIChatHistory)
  const filteredChatHistory = _.chain(openAIChatHistory)
    .sortBy(({ create_time }) => create_time)
    .reverse()
    .take(windowWidth < 768 ? 5 : 20)
    .value()

  useEffect(() => {
    if (openAIChatHistoryStatus === 'idle' && user?.email) {
      dispatch(fetchOpenAIChatHistory({ app_email: user.email }))
    }
  }, [user, openAIChatHistoryStatus])

  const handleChatHistoryClick = (e, chatMessage) => {
    e.preventDefault()

    // Set the value of the chat input
    setValue(gptFieldName, chatMessage)

    // Submit the form
    formRef?.current.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }))
  }

  /**
   *
   * Handle select fields
   *
   */
  const modelOptions = [
    { value: 'gpt-3.5-turbo-1106', label: 'gpt-3.5-turbo-1106' },
    { value: 'gpt-3.5-turbo-16k', label: 'gpt-3.5-turbo-16k' },
    { value: 'gpt-4', label: 'gpt-4' },
    { value: 'gpt-4-32k', label: 'gpt-4-32k' },
  ]

  const currentOpenAIAccount = useSelector(selectOpenAIAccount)
  const currentOpenAIAccountStatus = useSelector(selectOpenAIAccountStatus)
  useEffect(() => {
    if (currentOpenAIAccountStatus === 'idle') {
      dispatch(fetchOpenAIAccount({ key_id: openAIKeyId }))
    }
  }, [currentOpenAIAccountStatus])

  useEffect(() => {
    if (currentOpenAIAccountStatus === 'fulfilled' && currentOpenAIAccount === null) {
      setSettings(true)
    }
    if (currentOpenAIAccountStatus === 'fulfilled' && currentOpenAIAccount) {
      Object.keys(currentOpenAIAccount.permissions).map(permission => {
        setValue(permission, true)
      })
    }
  }, [currentOpenAIAccount])

  const onSave = async (permissions) => {
    // Handle permissions
    try {
      if (currentOpenAIAccount?.id) {
        // If openai account already exists, update
        const updatePayload = {
          key_id: openAIKeyId,
          permissions,
        }

        await dispatch(updateOpenAIAccount(updatePayload))
      } else {
        // Otherwise, create
        const createPayload = {
          app_email: user.email,
          key_id: openAIKeyId,
          permissions,
        }

        await dispatch(createOpenAIAccount(createPayload))
      }
    } catch (err) {
      console.log(err)
      toast.error('Failed to save settings.')
    }
  }

  const permissions = _.chain({
    overview: watch('overview'),
    products: watch('products'),
    marketing: watch('marketing'),
    geographics: watch('geographics'),
    customers: watch('customers'),
  }).pick(['overview', 'products', 'marketing', 'geographics', 'customers'])
    .toPairs()
    .filter(([k, v]) => v === true)
    .fromPairs()
    .value()

  return (
    <div className="tw-grid tw-grid-cols-12 -tw-m-[20px]">
      <Modal show={settings} onHide={() => null} size="lg">
        <Modal.Body style={{ padding: "16" }}>
          <div style={{marginTop: '10px'}}>
            <h2 className="tw-text-xl tw-mb-2 center">Ask AI Settings</h2>

            <h2 className="tw-text-xl tw-mb-1">Data access permissions</h2>
            <div className="tw-block tw-mb-2 tw-text-sm tw-font-medium tw-text-gray-500">Ask AI will only have access to data you grant permission for.</div>
            <div className="tw-w-full tw-flex tw-flex-wrap tw-mb-6">
              <div className="tw-mr-2 tw-mb-2">
                <MyCheckbox register={register} name="overview" label="Overview" />
              </div>
              <div className="tw-mr-2 tw-mb-2">
                <MyCheckbox register={register} name="products" label="Products" />
              </div>
              <div className="tw-mr-2 tw-mb-2">
                <MyCheckbox register={register} name="marketing" label="Marketing" />
              </div>
              <div className="tw-mr-2 tw-mb-2">
                <MyCheckbox register={register} name="geographics" label="Geographics" />
              </div>
              <div className="tw-mr-2 tw-mb-2">
                <MyCheckbox register={register} name="customers" label="Customers" />
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={() => setSettings(false)}>Cancel</Button>
          <Button
            variant='primary'
            disabled={!Object.keys(permissions)?.length}
            onClick={() => {
              setSettings(false)
              onSave(permissions)
            }}
          >Save</Button>
        </Modal.Footer>
      </Modal>

      <div className="tw-col-span-12 md:tw-col-span-4 lg:tw-col-span-3 xl:tw-col-span-2 tw-py-3">
        <div className="tw-px-6 tw-py-2 tw-text-xs tw-text-slate-500 tw-font-bold">Chat history</div>
        {filteredChatHistory?.map((chat, i) => {
          const chatMessage = chat?.message.split('|||||')?.[0]
          return (
            <div key={i}>
              <div
                className="tw-px-6 tw-py-1 tw-cursor-pointer hover:tw-bg-white/50 tw-truncate"
                onClick={(e) => handleChatHistoryClick(e, chatMessage)}
              >
                {chatMessage}
              </div>
            </div>
          )
        })}
      </div>

      <div
        className="tw-col-span-12 md:tw-col-span-8 lg:tw-col-span-9 xl:tw-col-span-10"
        style={{ height: `calc(100vh - ${HEADER_HEIGHT + FOOTER_HEIGHT}px)` }}
      >
        <form ref={formRef} onSubmit={handleSubmit(onSubmit)}>
          <div className="tw-flex tw-justify-center tw-bg-white">
            <div className="tw-max-w-full tw-w-[48rem]">
              <div className="tw-p-6" style={{ height: `${CHAT_TOP_MENU_HEIGHT}px`, display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', }}>
                <div className="tw-max-w-full tw-w-[20rem]" style={{ display: 'flex', flexDirection: 'row', }}>
                  {/* <MySelect
                    theme="white"
                    register={register}
                    name="model"
                    errors={errors}
                    rules={{ required: true }}
                    options={modelOptions}
                  /> */}

                  {/* {!openAIAccount ? <div>My balance: <a href={'/plan?view=billing'} style={{color: 'blue',}}>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', }).format(balance)}</a></div> : null} */}
                </div>

                {
                  isOwner && (
                    <div>
                      <Button
                        id="config-menu-btn"
                        data-tip
                        data-for="config-menu-btn-tooltip"
                        onClick={() => {
                          const fieldsToReset = ['overview', 'products', 'marketing', 'geographics', 'customers']
                          fieldsToReset.forEach(field => resetField(field, { defaultValue: false, }))
                          if (currentOpenAIAccount) {
                            Object.keys(currentOpenAIAccount.permissions).map(permission => {
                              setValue(permission, true)
                            })
                          }
                          setSettings(true)
                        }}
                      >
                        <span className="fa fa-cog cursor-pointer flex-box v-center" />
                      </Button>
                    </div>
                  )
                }
              </div>
              <div
                className="tw-flex tw-flex-col tw-px-6 tw-pb-6 tw-overflow-y-auto tw-h-full"
                style={{ height: `${MESSAGES_HEIGHT}px` }}
              >
                {messages.map((message, i) => {
                  const { author, content, rating, messageId, chart, facet, overviewData, startDate, endDate, } = message
                  return (
                    <>
                      <div key={i} className="tw-flex tw-space-x-2 tw-mb-6">
                        <Author author={author} />
                        <div style={{flex: 1,}}>
                          <div className="tw-text-slate-900 tw-font-semibold tw-mt-0.5 tw-mb-1">
                            {author.email === user.email ? 'Me' : 'ChatGPT'}
                          </div>
                          {content
                            ? <ReactMarkdown components={markdownComponents} rehypePlugins={[rehypeRaw, rehypeKatex]} remarkPlugins={[remarkMath]}>{content}</ReactMarkdown>
                            : <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center',}}>
                              <BiLoaderAlt className="tw-animate-spin" />
                              <div style={{marginLeft: 8,}} className="tw-text-slate-900">{loadingMessage}</div>
                            </div>
                          }
                        </div>
                        {rating !== undefined &&
                          <div style={{flex: 0, display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', width: '100%',}}>
                            <div
                              style={{marginRight: 16, cursor: 'pointer',}}
                              onClick={() => setRating(messageId, rating == true ? null : true)}
                            >
                              <span className="fa fa-thumbs-up" style={{color: rating === true ? 'blue' : 'grey',}} />
                            </div>
                            <div
                              style={{cursor: 'pointer',}}
                              onClick={() => setRating(messageId, rating === false ? null : false)}
                            >
                              <span className="fa fa-thumbs-down" style={{color: rating === false ? 'red' : 'grey',}} />
                            </div>
                          </div>
                        }
                      </div>

                      {chart && facet && overviewData?.facets &&
                        <Chart
                          chartType={chart}
                          facetType={facet}
                          facets={overviewData.facets}
                          startDate={startDate}
                          endDate={endDate}
                        />
                      }
                      {
                        overviewData?.trends && 
                        <Trends trends={overviewData.trends} currency={overviewData.currency}/>
                      }
                    </>
                  )
                })}
                <div ref={messagesEndRef} />
              </div>

              <div className="tw-flex tw-items-center tw-px-6" style={{ height: `${CHAT_INPUT_SECTION_HEIGHT}px` }}>
                <div className="tw-flex tw-items-center tw-w-full tw-space-x-2 tw-mb-6">
                  <input
                    {...register(gptFieldName, {})}
                    name={gptFieldName}
                    className="tw-grow tw-rounded-lg tw-border tw-border-slate-300 tw-shadow tw-px-3"
                    style={{ height: `${CHAT_INPUT_HEIGHT}px` }}
                    placeholder="Ask me anything"
                  />
                  <button
                    className="tw-cursor-pointer tw-rounded-lg tw-bg-blue-600 hover:tw-bg-blue-800 disabled:tw-bg-slate-300 disabled:tw-cursor-default tw-text-white tw-px-4"
                    type="submit"
                    disabled={!!!gptWatch}
                    style={{ height: `${CHAT_INPUT_HEIGHT}px` }}
                  >
                    Ask
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}

const mapStateToProps = (store) => ({
  accounts: store.root.accounts,
  balance: store.plan.customerBalance,
  isOwner: store.root.isOwner,
})

export default connect(mapStateToProps)(withRouter(AI))
