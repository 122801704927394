import _ from 'lodash'

export const zipPublicAndPrivateKeys = (publicKeys, privateKeys) => {
  return privateKeys.map((privateKey) => {
    const { secretId, secretValue: privateKeyValue } = privateKey
    const accountIdExists = secretId.split('--').length >= 3
    const accountId = accountIdExists ? _.chain(secretId).split('--').last().value() : ''
    const publicKey = publicKeys.find(({ keyId }) => {
      const keyIdIndex = keyId.indexOf('--')
      const secretIdIndex = secretId.indexOf('--')
      if (keyIdIndex === -1 || secretIdIndex === -1) return false
      const keyIdSubstr = keyId.substring(keyIdIndex + 2)
      const secretIdSubstr = secretId.substring(secretIdIndex + 2)
      return keyIdSubstr === secretIdSubstr
    })
    const publicKeyValue = publicKey?.keyValue || ''
    return { keyId: secretId, accountId, publicKey: publicKeyValue, privateKey: privateKeyValue }
  })
}
