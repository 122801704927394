import React, { useEffect, useState } from 'react'
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom'
import { bool } from 'prop-types'

import { getCookie, setCookie } from 'cookie'
import PaidRoute from './Router.paidRoute'
import AdminRoute from './Router.adminRoute'
import AdminMimicRoute from './Router.adminMimicRoute'

import Subscribers from 'applications/Subscribers'
import EmailCampaigns from 'applications/EmailCampaigns'
import Home from 'applications/Home'
import SignupMultistep from 'applications/SignupMultistep'
import Dashboard from 'applications/Dashboard'
import Traffic from 'applications/Traffic'
import Integrations from 'applications/Integrations'
import AI from 'applications/AI'
import Settings from 'applications/Settings'
import Plan from 'applications/Plan'
import Staff from 'applications/Staff'
import Statistics from 'applications/Statistics'
import Backfills from 'applications/Backfills'
import Channels from 'applications/Channels'
import Action from 'applications/Action'
import FAQ from 'applications/FAQ'
import FAQBigcommerce from 'applications/FAQBigcommerce'
import Reports from 'applications/Reports'
import CustomReports from 'applications/CustomReports'
import Shipping from 'applications/Shipping'
import Claims from 'applications/Claims'
import Inventory from 'applications/Inventory'
import Unauthorized from 'applications/Unauthorized'

import IntercomCurl from 'sharedComponents/IntercomCurl'
import NavbarHeader from 'sharedComponents/NavbarHeader'
import MarketingBanner from 'sharedComponents/MarketingBanner'
import ErrorBoundary from 'sharedComponents/ErrorBoundary'
import Footer from 'sharedComponents/Footer'
import ActionModal from './ActionModal'

import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

const defaultRedirect = ({ route, hasAccounts, onboardSkipped }) => {
  const is_sign_up = getCookie('is_sign_up')
  const linking_account = getCookie('linking_account'),
    linking_fulfilled = getCookie('linking_fulfilled')
  const confirming_charge = getCookie('confirming_charge'),
    charge_fulfilled = getCookie('charge_fulfilled')
  const slack_fulfilled = getCookie('slack_fulfilled')

  if ((!hasAccounts || is_sign_up) && !onboardSkipped) {
    // setCookie('is_sign_up', '')
    return '/onboard'
  } else if (linking_account || linking_fulfilled || slack_fulfilled) {
    return '/integrations'
  } else if (confirming_charge || charge_fulfilled) {
    return '/plan'
  } else if (route) {
    return route
  } else {
    return '/dashboard'
  }
}

const AppRouter = ({
  hasAccounts,
  hasBigcommerce,
  subscription,
  subscriptionActive,
  upgradeDismissed,
  applicationId,
  mimicStatus,
  reportTimeCost,
  adminFetching,
  accessPermissions,
  loginFromChannel,
  onboardSkipped,
  setOnboardSkipped,
  isLite,
}) => {
  const [upgradeModalOpen, setUpgradeModalOpen] = useState(false)
  const allowContinue = ['dashboard', 'shipping', 'settings'].includes(applicationId)
  const adminUpgradeDismissed = !!mimicStatus && upgradeDismissed
  const limitedAccess = (isLite || !subscriptionActive || subscription?.plan_status?.upgradeRequired) && !adminUpgradeDismissed
  const checkAccess = () => {
    if (adminUpgradeDismissed) {
      console.log('adminUpgradeDismissed')
      return true
    }
    if (limitedAccess) {
      setUpgradeModalOpen(true)
      console.log('limited access')
      return false
    }
    console.log('full access')
    return true
  }
  const accessParams = { limitedAccess, checkAccess, upgradeDismissed }

  return (
    <Router>
      <div style={{ minHeight: `calc(100vh - 52px)`}}>
        {
          limitedAccess && (applicationId !== 'action') && (
            <ActionModal
              show={limitedAccess && upgradeModalOpen}
              allowContinue={allowContinue}
              continueTo={allowContinue ? "#" : ''}
              onClose={() => setUpgradeModalOpen(false)}
              isLite={isLite}
              subscriptionActive={subscriptionActive}
            />
          )
        }
        <header id="sub-analytics-header">
          <NavbarHeader hasAccounts={hasAccounts} isLite={isLite} accessPermissions={accessPermissions} />
        </header>
        <div id={`sub-analytics-body${applicationId === 'channels' ? '-channels' : ''}`}>
          <MarketingBanner />
          <ErrorBoundary suppress>{adminFetching ? null : <IntercomCurl upgradeModalOpen={upgradeModalOpen} upgradeDismissed={upgradeDismissed}/>}</ErrorBoundary>
          {
            (onboardSkipped || hasAccounts) ? (
              <Switch>
                <Route path="/channels" component={Channels} />
                <Route path="/onboard" render={() => <SignupMultistep setOnboardSkipped={setOnboardSkipped} loginFromChannel={loginFromChannel}/>} />
                {/* <Route
                  path="/home"
                  render={() =>
                    accessPermissions['home'] ||
                    accessPermissions['home'] === undefined ||
                    !accessPermissions['home'] ? (
                      <Home />
                    ) : (
                      <Unauthorized title={'Home'} />
                    )
                  }
                /> */}
                <Route
                  path="/dashboard"
                  render={() =>
                    accessPermissions['dashboard'] ||
                    accessPermissions['dashboard'] === undefined ||
                    !accessPermissions['dashboard'] ? (
                      <Dashboard {...accessParams}/>
                    ) : (
                      <Unauthorized title={'Dashboard'} />
                    )
                  }
                />
                <Route
                  path="/traffic"
                  render={() =>
                    accessPermissions['traffic'] ||
                    accessPermissions['traffic'] === undefined ||
                    !accessPermissions['traffic'] ? (
                      <Traffic />
                    ) : (
                      <Unauthorized title={'Traffic'} />
                    )
                  }
                />
                <Route
                  path="/reports"
                  exact
                  render={() =>
                    accessPermissions['reports'] || accessPermissions['reports'] === undefined ? (
                      <Reports {...accessParams}/>
                    ) : (
                      <Unauthorized title={'Reports'} />
                    )
                  }
                />
                <Route
                  path="/reports/:id"
                  exact
                  render={({ match }) =>
                    accessPermissions['reports'] || accessPermissions['reports'] === undefined ? (
                      <Reports applyReport={true} reportId={match.params.id} {...accessParams}/>
                    ) : (
                      <Unauthorized title={'Reports'} />
                    )
                  }
                />
                <Route
                  path="/reports/:id/:reportName"
                  exact
                  render={({ match }) =>
                    accessPermissions['reports'] || accessPermissions['reports'] === undefined ? (
                      <Reports applyReport={true} reportId={match.params.id} reportName={match.params.reportName} {...accessParams}/>
                    ) : (
                      <Unauthorized title={'Reports'} />
                    )
                  }
                />
                <Route path="/integrations" render={() => <Integrations />} />
                <Route path="/subscribers" component={Subscribers} {...accessParams}/>
                <Route
                  path="/emailcampaigns"
                  render={() =>
                    accessPermissions['email-campaigns'] ||
                    accessPermissions['email-campaigns'] === undefined ||
                    !accessPermissions['email-campaigns'] ? (
                      <EmailCampaigns {...accessParams}/>
                    ) : (
                      <Unauthorized title={'Email Campaigns'} />
                    )
                  }
                />
                <Route path="/ai" render={() => <AI {...accessParams}/>} />
                <Route
                  path="/custom"
                  exact
                  render={() =>
                    accessPermissions['custom-reports'] ||
                    accessPermissions['custom-reports'] === undefined ||
                    !accessPermissions['custom-reports'] ? (
                      <CustomReports {...accessParams} />
                    ) : (
                      <Unauthorized title={'Custom Reports'} />
                    )
                  }
                />
                <Route
                  path="/custom/:id"
                  render={({ match }) =>
                    accessPermissions['custom-reports'] ||
                    accessPermissions['custom-reports'] === undefined ||
                    !accessPermissions['custom-reports'] ? (
                      <CustomReports applyCustomReport={true} customReportId={match.params.id} {...accessParams} />
                    ) : (
                      <Unauthorized title={'Custom Reports'} />
                    )
                  }
                />
                <Route path="/plan" component={Plan} />
                <Route path="/settings" render={() => <Settings {...accessParams}/>} />
                <Route
                  path="/faq"
                  // component={hasBigcommerce ? FAQBigcommerce : FAQ}
                  render={() =>
                    hasBigcommerce ? (
                      <FAQBigcommerce />
                    ) : (
                      <FAQ loginFromChannel={loginFromChannel} />
                    )
                  }
                />
                <Route
                  path="/shipping"
                  render={() =>
                    accessPermissions['shipping'] ||
                    accessPermissions['shipping'] === undefined ||
                    !accessPermissions['shipping'] ? (
                      <Shipping {...accessParams}/>
                    ) : (
                      <Unauthorized title={'Shipping'} />
                    )
                  }
                />
                <Route
                  path="/claims"
                  component={
                    accessPermissions['shipping'] ||
                    accessPermissions['shipping'] === undefined ||
                    !accessPermissions['shipping']
                      ? Claims
                      : Unauthorized
                  }
                />
                <Route path="/action" component={Action} />
                <AdminRoute path="/staff" component={Staff} />
                <AdminRoute path="/statistics" component={Statistics} />
                <AdminRoute path="/backfills" component={Backfills} />
                <Route path="/inventory" component ={Inventory} />
                <Redirect to={defaultRedirect({ route: '/dashboard', hasAccounts, onboardSkipped })} />
              </Switch>
            ) : (
              <Switch>
                <Route path="/channels" component={Channels} />
                <Route path="/onboard" render={() => <SignupMultistep loginFromChannel={loginFromChannel} setOnboardSkipped={setOnboardSkipped}/>} />
                <Route path="/plan" component={Plan} />
                <Route path="/settings" render={() => <Settings {...accessParams} />}/>
                <Route path="/faq" component={hasBigcommerce ? FAQBigcommerce : FAQ} />
                {accessPermissions['custom-report'] ||
                  accessPermissions['custom-report'] === undefined ||
                  (!accessPermissions['custom-report'] && <Route path="/shipping" component={Shipping} />)}
                <AdminRoute path="/staff" component={Staff} />
                <AdminRoute path="/statistics" component={Statistics} />
                <AdminRoute path="/backfills" component={Backfills} />
                <Redirect to={defaultRedirect({ route: '/settings', hasAccounts, onboardSkipped })} />
              </Switch>
            )
          }
        </div>
        <footer id="sub-analytics-footer">
          <Footer reportTimeCost={reportTimeCost} />
        </footer>

        <ToastContainer
          className="tw-text-sm"
          bodyClassName="tw-text-slate-800"
          autoClose={5000}
          hideProgressBar={true}
        />
      </div>
    </Router>
  )
}

AppRouter.propTypes = {
  hasAccounts: bool,
}

export default AppRouter
