import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import _ from 'lodash'
import { integrationsKeysUrl, slackApiMessagesUrl } from 'urls'
import { getAxiosDefaultConfig } from '../../modules/utils'

export const initialState = {
  currentChannel: null,
  currentChannelAccount: null,
  connectionModal: {
    visible: false,
  },
  backfillModal: {
    visible: false,
  },
  settingsModal: {
    visible: false,
  },
  csvModal: {
    visible: false,
  },
  allPublicKeys: {},
  publicKeys: {},
}

// Will match any secrets whose name includes the payload `ownerId`
export const fetchAllPublicKeys = createAsyncThunk('integrations/fetchAllPublicKeys', async (payload) => {
  const { ownerId } = payload
  let res = await axios.get(`${integrationsKeysUrl}?keyId=${ownerId}`, getAxiosDefaultConfig())
  const keys = Object.values(res?.data)
  return keys.map((_key) => ({ keyId: _key.key_id, keyValue: _key.key_value, tags: _key.tags }))
})

export const createPublicKey = createAsyncThunk('integrations/createPublicKey', async (payload) => {
  const { keyId, keyValue } = payload
  let res = await axios.post(integrationsKeysUrl, payload, getAxiosDefaultConfig())
  return { keyId, keyValue }
})

export const updatePublicKey = createAsyncThunk('integrations/updatePublicKey', async (payload) => {
  const { keyId, keyValue } = payload
  let res = await axios.put(`${integrationsKeysUrl}/${keyId}`, payload, getAxiosDefaultConfig())
  return { keyId, keyValue }
})

export const deletePublicKey = createAsyncThunk('integrations/deletePublicKey', async (payload) => {
  const { keyId } = payload
  let res = await axios.delete(`${integrationsKeysUrl}/${keyId}`, getAxiosDefaultConfig())
  return { keyId }
})

export const postSlackMessage = createAsyncThunk('integrations/postSlackMessage', async (payload) => {
  const { channel, text } = payload
  let res = await axios.post(slackApiMessagesUrl, payload, getAxiosDefaultConfig())
  return { channel, text }
})

export const integrationsSlice = createSlice({
  name: 'integrations',
  initialState,
  reducers: {
    setCurrentChannel: (state, action) => {
      state.currentChannel = action.payload
    },
    setCurrentChannelAccount: (state, action) => {
      state.currentChannelAccount = action.payload
    },
    openConnectionModal: (state, action) => {
      state.connectionModal.visible = true
    },
    closeConnectionModal: (state, action) => {
      state.connectionModal.visible = false
    },
    openBackfillModal: (state, action) => {
      state.backfillModal.visible = true
    },
    closeBackfillModal: (state, action) => {
      state.backfillModal.visible = false
    },
    openSettingsModal: (state, action) => {
      state.settingsModal.visible = true
    },
    closeSettingsModal: (state, action) => {
      state.settingsModal.visible = false
    },
    openCsvModal: (state, action) => {
      state.csvModal.visible = true
    },
    closeCsvModal: (state, action) => {
      state.csvModal.visible = false
    },
  },
  extraReducers(builder) {
    builder
      /**
       *
       * integrations keys api
       *
       */
      .addCase(fetchAllPublicKeys.pending, (state, action) => {
        state.publicKeys.status = 'loading'
      })
      .addCase(fetchAllPublicKeys.fulfilled, (state, action) => {
        state.publicKeys.data = action.payload
        state.publicKeys.status = 'succeeded'
      })
      .addCase(fetchAllPublicKeys.rejected, (state, action) => {
        state.publicKeys.status = 'failed'
        state.publicKeys.error = action.error.message
      })
      .addCase(createPublicKey.fulfilled, (state, action) => {
        state.publicKeys.data = [...state.publicKeys.data, action.payload]
      })
      .addCase(updatePublicKey.fulfilled, (state, action) => {
        const { keyId } = action.meta.arg
        const index = _.findIndex(state.publicKeys.data, (key) => key.keyId === keyId)
        _.set(state.publicKeys.data, [index, 'keyValue'], action.payload.keyValue)
      })
      .addCase(deletePublicKey.fulfilled, (state, action) => {
        const { keyId } = action.meta.arg
        state.publicKeys.data = _.filter(state.publicKeys.data, (key) => key.keyId !== keyId)
      })

      /**
       *
       * post slack message
       *
       */
      .addCase(postSlackMessage.fulfilled, (state, action) => {
        const { channel, text } = action.meta.arg
      })
  },
})

export const {
  setCurrentChannel,
  setCurrentChannelAccount,
  openConnectionModal,
  closeConnectionModal,
  openBackfillModal,
  closeBackfillModal,
  openSettingsModal,
  closeSettingsModal,
  openCsvModal,
  closeCsvModal,
} = integrationsSlice.actions

/**
 *
 * Selectors
 *
 */
export const selectCurrentChannel = (state) => state.integrations.currentChannel
export const selectCurrentChannelAccount = (state) => state.integrations.currentChannelAccount
export const selectConnectionModalVisible = (state) => state.integrations.connectionModal.visible
export const selectBackillModalVisible = (state) => state.integrations.backfillModal.visible
export const selectSettingsModalVisible = (state) => state.integrations.settingsModal.visible
export const selectCsvModalVisible = (state) => state.integrations.csvModal.visible

export const selectAllPublicKeysStatus = (state) => state.integrations.publicKeys.status || 'idle'
export const selectAllPublicKeysError = (state) => state.integrations.publicKeys.error
export const selectAllPublicKeys = (state) => state.integrations.publicKeys.data

export const selectChannelPublicKeys = createSelector(
  [(state) => state.integrations.publicKeys, (state, channelId) => channelId],
  (publicKeys, channelId) => {
    return _.chain(publicKeys.data)
      .filter((key) => key.keyId.includes(channelId))
      .uniqWith((k1, k2) => k1.keyValue === k2.keyValue)
      .value()
  }
)

export const selectPublicKey = createSelector(
  [(state) => state.integrations.publicKeys, (state, keyId) => keyId],
  (publicKeys, keyId) => publicKeys.data.find((key) => key.keyId === keyId) || null
)
// export const selectChannelPublicKeysStatus = createSelector(
//   [(state) => state.integrations.publicKeys, (state, channelId) => channelId],
//   (publicKeys, channelId) => (publicKeys[channelId]?.status ? publicKeys[channelId].status : 'idle')
// )
// export const selectChannelPublicKeysError = createSelector(
//   [(state) => state.integrations.publicKeys, (state, channelId) => channelId],
//   (publicKeys, channelId) => (publicKeys[channelId] ? publicKeys[channelId].error : null)
// )
// export const selectChannelPublicKeys = createSelector(
//   [(state) => state.integrations.publicKeys, (state, channelId) => channelId],
//   (publicKeys, channelId) => (publicKeys[channelId] ? publicKeys[channelId].data : null)
// )
// export const selectPublicKey = createSelector(
//   [(state) => state.integrations.publicKeys, (state, keyId) => keyId],
//   (publicKeys, keyId) => {
//     const channelId = keyId.split('--')[1]
//     if (publicKeys[channelId]) {
//       return publicKeys[channelId].data.find((key) => key.keyId === keyId) || null
//     } else {
//       return null
//     }
//   }
// )

export default integrationsSlice.reducer
