import React, { useEffect, useState } from 'react'
import { localStoragePayhelm } from 'modules/utils'
import Logo from '../../assets/images/payhelm-logo-blue.png'
import Channels from './Channels'
import Preferences from './Preferences'
import Payment from './Payment'
import BottomMenu from './BottomMenu'
import Invites from './Invites'
import { useSelector } from 'react-redux'
import { withRouter } from 'react-router-dom'
import {
  selectIsStripeBilling
} from '../Plan/planSlice'
import {
  selectRootUser,
  selectRootAccounts,
} from '../../rootSlice'
import { sendGtmPixel } from 'tagManager'

function SignupMultistep({ history, loginFromChannel, setOnboardSkipped }) {
  const isStripeBilling = useSelector(selectIsStripeBilling)
  const user = useSelector(selectRootUser)
  const accounts = useSelector(selectRootAccounts)
  const stepToCopy = [
    {
      title: 'Connect channels',
      text: 'Connecting more channels will give you better data and analytics on your business. You can always connect more channels later.',
    },
    {
      title: 'Invite team members',
      text: 'Invite team members to PayHelm. You can invite more team members later.',
    },
    // {
    //   title: 'Set preferences',
    //   text: 'Set your preferences. You can change or set more preferences later.',
    // },
    {
      title: 'How our trial works',
      text: <span>Try all our features free for 14 days. After 14 days select the plan that matches your revenue level for the year. <a href="https://www.payhelm.com/pricing/" target='_blank'>Review our plans now.</a></span>
    }
  ]
  const [step, setStep] = useState(0)
  const [signupPixelSent, setSignupPixelSent] = useState(false)

  useEffect(() => {
    if (localStoragePayhelm.signup_step) {
      // Go to dashboard if signup is already over
      if (localStoragePayhelm.signup_step >= stepToCopy.length) {
        history.push('/dashboard')
      }
      if (localStoragePayhelm.signup_step > stepToCopy.length) {
        setStep(parseInt(stepToCopy.length))
      }
      else {
        setStep(parseInt(localStoragePayhelm.signup_step))
      }
    }
  }, [])

  useEffect(() => {
    // Save to localStorage on step change
    localStoragePayhelm.signup_step = step
    if (step === 0 && !signupPixelSent) {
      sendGtmPixel(accounts, 'onboard_success')
      setSignupPixelSent(true)
    }
  }, [step])
  
  const copy = stepToCopy[step]

  const setStepWithEvent = nextStepIndex => {
    setStep(nextStepIndex)
    if (nextStepIndex > step) {
      sendGtmPixel({ email: user.email, step: stepToCopy[nextStepIndex - 1].title}, 'onboard_step_finished')
    }
  }

  const stepToComponent = [
    <Channels step={step} setStep={setStepWithEvent} loginFromChannel={loginFromChannel}/>,
    <Invites step={step} setStep={setStepWithEvent} history={history} setOnboardSkipped={setOnboardSkipped}/>,
    // <Preferences step={step} setStep={setStep} />,
    <Payment step={step} setStep={setStepWithEvent} />,
  ]
  const component = stepToComponent[step]

  if (step > stepToCopy.length) {
    // Onboard flow finished
    return null
  }

  return (
    <>
      <div className="tw-fixed tw-inset-0 tw-max-h-screen tw-bg-slate-100 tw-z-[10000] tw-flex tw-flex-col">
        <div className="tw-container tw-mx-auto tw-h-full">
          <div className="tw-grid tw-grid-cols-12 tw-h-full">
            <div className="tw-col-span-12 md:tw-col-span-4 lg:tw-col-span-5 tw-h-fit">
              <div className="tw-px-4 md:tw-px-0 tw-pb-8 md:tw-pb-0">
                <div className="md:tw-pr-12">
                  <div className="tw-my-8 md:tw-my-20">
                    <img src={Logo} className="tw-h-[64px]" />
                  </div>
                  <div className="tw-space-y-3">
                    <div className="tw-text-cyan-500 tw-text-sm tw-font-bold">
                      Step {step + 1} of {stepToCopy.length}
                    </div>
                    <div className="tw-text-xl tw-font-bold">{copy.title}</div>
                    <div className="tw-text-slate-500">{copy.text}</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="tw-col-span-12 md:tw-col-span-8 lg:tw-col-span-7 tw-bg-white tw-overflow-y-auto">
              <div>{component}</div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default withRouter(SignupMultistep)
