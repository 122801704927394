import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import {
  addCreditCard,
  selectNewCreditCard,
  selectNewCreditCardStatus,
  selectNewCreditCardError,
  selectCurrentCreditCardsSubscription,
  selectIsStripeBilling,
} from '../Plan/planSlice'
import { applyNewCreditCard } from '../../actions/planActions'
import { FormGroup, FormLabel } from 'react-bootstrap'
import BottomMenu from './BottomMenu'
import { injectStripe, StripeProvider, Elements, CardNumberElement, CardExpiryElement, CardCVCElement, PostalCodeElement } from 'react-stripe-elements'
import Spinner from 'sharedComponents/Spinner'
import { stripeToken } from 'modules/token'
import { toast } from 'react-toastify'
import { withRouter } from 'react-router-dom'
import ShopifyLogo from '../../assets/images/logos/shopify.svg'

const ContentStyle = {
  base: {
    color: '#32325d',
    lineHeight: '18px',
    fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
    fontSmoothing: 'antialiased',
    fontSize: '16px',
    '::placeholder': {
      color: '#aab7c4'
    }
  },
  invalid: {
    color: '#a94442',
    iconColor: '#a94442'
  }
}

function Payment({ history, setStep, stripe }) {
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()
  const newCreditCard = useSelector(selectNewCreditCard)
  const newCreditCardStatus = useSelector(selectNewCreditCardStatus)
  const newCreditCardError = useSelector(selectNewCreditCardError)
  const { creditCards, subscription } = useSelector(selectCurrentCreditCardsSubscription)
  /**
   *
   * Handle form
   *
   */
  const {
    handleSubmit,
  } = useForm()

  useEffect(() => {
    if (!newCreditCard) return
    dispatch(applyNewCreditCard(creditCards, newCreditCard, subscription))
    toast.success(`Your payment method for trial is updated (${newCreditCard.brand} **** ${newCreditCard.last4})`)
    history.push('/dashboard')
  }, [newCreditCard])

  useEffect(() => {
    if (newCreditCardError) return
    toast.error(newCreditCardError)
  }, [newCreditCardError])

  /**
   * Submit input CC# to stripe and update redux store
   */
  const onSubmit = () => {
    stripe.createToken().then(async (data) => {
      if (data.error) {
        setLoading(false)
        toast.error(data.error.message)
      }
      else {
        await dispatch(addCreditCard(data.token.id))
      }
    })
  }

  const onSkip = () => {
    setStep(3)

    history.push('/dashboard')
  }

  return (
    <>
      <div className="tw-min-h-screen tw-pt-10 tw-pb-10 tw-border-t tw-border-slate-200">
        <div className="tw-px-4 md:tw-px-8">
          <div className="tw-hidden md:tw-block">
            <div className="tw-text-xl tw-font-bold tw-mb-4">Setup your Trial</div>
            <div className="tw-text-slate-500 tw-mb-4">
              Try PayHelm for free for 14 days. Cancel any time.
            </div>
          </div>

          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="credit-card-form" style={{position: 'relative'}}>
              { (loading || (newCreditCardStatus === 'loading')) ? <Spinner mask/> : null}
              <FormGroup>
                <FormLabel>Card Number</FormLabel>
                <CardNumberElement placeholder="Your Credit Card Number" style={ContentStyle} />
              </FormGroup>
              <br />
              <FormGroup>
                <FormLabel>Date of Expiry</FormLabel>
                <CardExpiryElement style={ContentStyle} />
              </FormGroup>
              <br />
              <FormGroup>
                <FormLabel>Card CVC/CVV</FormLabel>
                <CardCVCElement placeholder="Your Card's CVC/CVV" style={ContentStyle} />
              </FormGroup>
              <br />
              <FormGroup>
                <FormLabel>Postal Code</FormLabel>
                <PostalCodeElement placeholder="Your Postal or ZIP Code" style={ContentStyle} />
              </FormGroup>
              <br />
            </div>
            <BottomMenu onPrev={() => setStep(1)} onSkip={onSkip} submitOnNext={true} />
          </form>
        </div>
      </div>
    </>
  )
}

const StripeInjectedBody = injectStripe(Payment)

function WrappedBody(props) {
  const isStripeBilling = useSelector(selectIsStripeBilling)
  const { setStep, history } = props
  if (!isStripeBilling) {
    return (
      <>
        <div className="tw-min-h-screen tw-pt-10 tw-pb-10 tw-border-t tw-border-slate-200">
          <div className="tw-px-4 md:tw-px-8">
            <div className="md:tw-block">
              <div className="tw-text-xl tw-font-bold tw-mb-4">Setup your Trial</div>
              <div>
                <img src={ShopifyLogo}  width={200}/>
                <br />
                <h4>Subscribe with Shopify</h4>
                <div>Billing will be managed by your Shopify account</div>
              </div>
            </div>
  
            <BottomMenu onPrev={() => setStep(1)} submitOnNext={true} onNext={() => {
              setStep(3)
              history.push('/dashboard')
            }} />
          </div>
        </div>
      </>
    )
  }
  const [stripeLoaded, setStripeLoaded] = useState(!!window.Stripe)
  useEffect(() => {
    if (!stripeLoaded) {
      const script = document.createElement('script')
      document.head.appendChild(script)
      script.onload = () => setStripeLoaded(true)
      script.src = 'https://js.stripe.com/v3/'
    }
  }, [])

  return stripeLoaded ? (
    <StripeProvider apiKey={stripeToken}>
      <Elements>
        <StripeInjectedBody {...props}/>
      </Elements>
    </StripeProvider>
  ) : <Spinner />
}

export default withRouter(WrappedBody)