import { localStoragePayhelm, getQueryJson, validateJSONString, } from 'modules/utils'
import { rangeTypes, } from '../modules/constants'

let rangeFilters = {}
const savedState = localStoragePayhelm.rangeFilters && validateJSONString(localStoragePayhelm.rangeFilters)
  ? JSON.parse(localStoragePayhelm.rangeFilters) : undefined
let checksum = true
if (savedState) {
  for (const rangeType of Object.keys(savedState)) {
    if (isNaN(savedState[rangeType]?.min) || isNaN(savedState[rangeType]?.max)) checksum = false
    if (!rangeTypes.includes(rangeType)) checksum = false
  }
} else checksum = false
if (checksum) rangeFilters = savedState
else rangeTypes.map(rangeType => rangeFilters[rangeType] = { active: false, min: 0, max: 0, })

const queryJson = getQueryJson()
try {
  for (const type of rangeTypes) {
    if (queryJson[type] !== undefined) {
      const [min, max] = queryJson[type].split('to')
      const parsedMin = parseFloat(min)
      const parsedMax = parseFloat(max)
      if (!isNaN(parsedMin) && !isNaN(parsedMax)) {
        if (parsedMin <= parsedMax) rangeFilters[type] = { active: true, min: parsedMin, max: parsedMax, }
        else rangeFilters[type] = { active: true, min: parsedMin, max: parsedMin, }
      }
    }
  }
} catch (error) {
  console.log(error)
}

const initialState = {
  rangeTypes: rangeFilters,
  updated: false,
}

export default function reducer(state = initialState, action) {
  let nextState = state
  switch(action.type) {
    case 'ACTIVATE_RANGE_TYPE':
      nextState = {
        ...state,
        rangeTypes: {
          ...state.rangeTypes,
          [action.payload]: {
            ...state.rangeTypes[action.payload],
            active: true,
          }
        },
        updated: true,
      }
      break
    case 'DEACTIVATE_RANGE_TYPE':
      nextState = {
        ...state,
        rangeTypes: {
          ...state.rangeTypes,
          [action.payload]: {
            ...state.rangeTypes[action.payload],
            active: false,
          }
        },
        updated: true,
      }
      break
    case 'DEACTIVATE_ALL_RANGE_TYPES': {
      let nextRangeTypes = {}
      for (const rangeType of Object.keys(state.rangeTypes)) {
        nextRangeTypes[rangeType] = {
          ...state.rangeTypes[rangeType],
          active: false,
        }
      }
      nextState = {
        ...state,
        rangeTypes: nextRangeTypes,
        updated: true,
      }
      break
    }
    case 'CHANGE_RANGE_TYPE':
      nextState = {
        ...state,
        rangeTypes: {
          ...state.rangeTypes,
          [action.payload.prevRangeType]: {
            ...state.rangeTypes[action.payload.nextRangeType],
          },
          [action.payload.nextRangeType]: {
            ...state.rangeTypes[action.payload.prevRangeType],
          },
        },
        updated: true,
      }
      break
    case 'SET_RANGE':
      nextState = {
        ...state,
        rangeTypes: {
          ...state.rangeTypes,
          [action.payload.rangeType]: {
            ...state.rangeTypes[action.payload.rangeType],
            min: action.payload.min !== undefined ? action.payload.min : state.rangeTypes[action.payload.rangeType].min,
            max: action.payload.max !== undefined ? action.payload.max : state.rangeTypes[action.payload.rangeType].max,
          },
        },
        updated: true,
      }
      break
    case 'SET_UPDATED_RANGE_FILTERS':
      nextState = {
        ...state,
        updated: action.payload,
      }
      break
    case 'SET_RANGE_FILTERS_CONFIG':
      return {
        ...state,
        ...action.payload,
      }
  }
  localStoragePayhelm.rangeFilters = JSON.stringify(nextState.rangeTypes || '')
  return nextState
}
