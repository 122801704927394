import React from 'react'
import { Check, ChevronsUpDown } from 'lucide-react'
import { cn } from '@/features/utils'
import { Button } from '@/features/shadcn/ui/button'
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandList,
  CommandInput,
  CommandItem,
} from '@/features/shadcn/ui/command'
import { FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/features/shadcn/ui/form'
import { Popover, PopoverContent, PopoverTrigger } from '@/features/shadcn/ui/popover'

// IMPORTANT: Must be used with <Form> element from shadcn
export default function Combobox({
  form,
  name,
  label,
  labelClassName = '',
  buttonClassName = '',
  options,
  placeholder = 'Select an option...',
  searchPlaceholder = 'Search...',
  onSelectOverride,
}) {
  const [open, setOpen] = React.useState(false)

  return (
    <FormField
      control={form.control}
      name={name}
      render={({ field }) => (
        <FormItem className="tw-w-full tw-flex tw-flex-col">
          <FormLabel className={labelClassName}>{label}</FormLabel>
          <Popover open={open} onOpenChange={setOpen}>
            <PopoverTrigger asChild>
              <FormControl>
                <Button
                  variant="outline"
                  role="combobox"
                  className={cn(
                    'tw-w-full tw-justify-between tw-border-slate-200 tw-shadow-sm !tw-px-3 tw-h-9',
                    !field.value && 'tw-text-muted-foreground',
                    buttonClassName
                  )}
                >
                  {field.value ? options.find((option) => option.value === field.value)?.label : placeholder}
                  <ChevronsUpDown className="tw-ml-2 tw-h-4 tw-w-4 tw-shrink-0 tw-opacity-50" />
                </Button>
              </FormControl>
            </PopoverTrigger>
            <PopoverContent className="!tw-p-0 tw-w-[--radix-popover-trigger-width] tw-max-h-[--radix-popover-content-available-height]">
              <Command>
                <CommandInput placeholder={searchPlaceholder} />
                <CommandEmpty>No results found.</CommandEmpty>
                <CommandGroup>
                  <CommandList>
                    {options.map((option) => (
                      <CommandItem
                        value={option.label}
                        key={option.value}
                        onSelect={() => {
                          if (onSelectOverride) {
                            onSelectOverride(option.value)
                            setOpen(false)
                            return
                          }
                          form.setValue(name, option.value)
                          setOpen(false)
                        }}
                      >
                        <Check
                          className={cn(
                            'tw-mr-2 tw-h-4 tw-w-4',
                            option.value === field.value ? 'tw-opacity-100' : 'tw-opacity-0'
                          )}
                        />
                        {option.label}
                      </CommandItem>
                    ))}
                  </CommandList>
                </CommandGroup>
              </Command>
            </PopoverContent>
          </Popover>
          <FormMessage />
        </FormItem>
      )}
    />
  )
}
