import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { useDispatch, useSelector } from 'react-redux'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import { useForm } from 'react-hook-form'
import MyInput from '../../../features/forms/MyInput'
import MySelect from '../../../features/forms/MySelect'
import { selectCurrentChannel, selectCurrentChannelAccount } from '../integrationsSlice'
import { selectRootAccounts, selectRootUser } from '../../../rootSlice'
import { getAxiosDefaultConfig } from 'utils'
import { unlinkUrls } from 'urls'

export default function UninstallModal({ visible, closeModal, integration }) {
  const dispatch = useDispatch()

  const { channel, unlinkRequirements, unlinkExample, id: channelId, unlinkIdCol } = integration

  const [showConfirmModal, setShowConfirmModal] = useState(false)
  const [isProcessing, setIsProcessing] = useState(false)
  const [completed, setCompleted] = useState(false)
  const [showError, setShowError] = useState(false)

  /**
   *
   * Handle form
   *
   */
  const {
    register,
    handleSubmit,
    watch,
    reset: resetForm,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      identifier: '',
    },
  })

  const watchIdentifier = watch('identifier')

  const handleConfirm = async () => {
    try {
      setIsProcessing(true)
      await axios.delete(`${unlinkUrls[channelId]}?${unlinkIdCol}=${watchIdentifier}`, getAxiosDefaultConfig())
      setIsProcessing(false)
      setCompleted(true)
    } catch (error) {
      setShowError(true)
      setIsProcessing(false)
    }
  }

  /**
   *
   * Handle form submit
   *
   */
  const onSubmit = (values) => {
    setShowConfirmModal(true)
  }

  return (
    <>
      <Modal show={visible} onHide={closeModal}>
        <Modal.Body>
          <div className="tw-p-6">
            <h2 className="tw-text-xl tw-mb-6">Unlink {channel}</h2>

            <form onSubmit={handleSubmit(onSubmit)} className="tw-w-full">
              <MyInput
                register={register}
                name={'identifier'}
                label={`Enter ${unlinkRequirements}`}
                errors={errors}
                rules={{ required: true }}
                placeholder={`e.g. ${unlinkExample}`}
              />
              <p className="tw-text-red-600 tw-mb-4 tw-text-xs">
                Warning: Unlinking this integration will result in the loss of all data related to this integration.
              </p>
              <div className="tw-flex tw-justify-end tw-space-x-2">
                <Button variant="secondary" size="sm" className="tw-mt-4" onClick={closeModal}>
                  Cancel
                </Button>
                <Button variant="primary" size="sm" className="tw-mt-4" type="submit">
                  Unlink
                </Button>
              </div>
            </form>
          </div>
        </Modal.Body>
      </Modal>

      <Modal show={showConfirmModal} onHide={() => setShowConfirmModal(false)}>
        <Modal.Body>
          <div className="tw-p-6">
            <h2 className="tw-text-xl tw-mb-6">Confirm Unlink</h2>
            {showError && <p className="tw-text-red-600 tw-mb-4">Error unlinking integration. Please try again.</p>}
            <p className="tw-mb-4">{completed ? `${channel} Account has been unlinked.`: 'Are you sure you want to unlink this integration? This action cannot be undone.'}</p>
            <div className="tw-flex tw-justify-end tw-space-x-2">
              <Button variant="secondary" size="sm" className="tw-mt-4" onClick={() => {setShowConfirmModal(false); setError(false)}}>
                Cancel
              </Button>
              {completed ?
               <Button variant="primary" size="sm" className="tw-mt-4" onClick={() => {
                closeModal();
                setShowConfirmModal(false);
                setCompleted(false);
                setError(false);
                resetForm();
               }}>
                Done
               </Button>
              : <Button variant="primary" size="sm" className="tw-mt-4" onClick={handleConfirm} disabled={isProcessing}>
                {isProcessing ? 'Processing...' : 'OK'}
              </Button>}
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}
