import React from 'react'
import { checkShouldRemoveOldKey } from '../channels/generic/helpers'

export default function ChannelKeysTable({ ppKeys }) {
  const headings = ['Account ID', 'Public Key', 'Private Key']
  const headingCellPaddingClassName = 'tw-px-2 tw-py-1'
  const cellPaddingClassName = 'tw-px-2 tw-py-1'

  const { shouldRemoveOldKey, oldKey, newKeys } = checkShouldRemoveOldKey(ppKeys)
  const filteredPpKeys = shouldRemoveOldKey ? newKeys : ppKeys

  return (
    <div className="tw-rounded-lg tw-border tw-border-slate-200 tw-w-full tw-text-sm tw-shadow-sm tw-overflow-x-auto">
      <table className="tw-table-auto tw-border-collapse tw-w-full">
        <thead className="tw-border-b tw-font-medium tw-text-slate-500 tw-bg-slate-50 tw-text-left">
          <tr>
            {headings.map((heading) => (
              <th key={heading} className={headingCellPaddingClassName}>
                {heading}
              </th>
            ))}
          </tr>
        </thead>

        {filteredPpKeys.map((ppKey, i) => {
          const record = [ppKey.accountId, ppKey.publicKey, ppKey.privateKey]

          return (
            <tbody key={ppKey.accountId}>
              <tr className={i !== 0 ? `tw-border-t tw-border-slate-200` : ''}>
                {record.map((value, j) => {
                  const isPrivateKey = j === 2
                  const content = isPrivateKey ? '••••••••••' : value
                  return (
                    <td key={j} className={cellPaddingClassName}>
                      <span className="tw-whitespace-nowrap">{content}</span>
                    </td>
                  )
                })}
              </tr>
            </tbody>
          )
        })}
      </table>
    </div>
  )
}
