const initialState = {
  searchTerms: '',
  typing: false,
}

export default function reducer(state = initialState, action) {
  switch(action.type) {
    case 'UPDATE_SEARCH_TERMS_SEARCH': {
      return {
        ...state,
        searchTerms: action.payload
      }
    }
    case 'SET_TYPING_STATUS_SEARCH': {
      return {
        ...state,
        typing: action.payload
      }
    }
  }
  return state
}