module.exports = {
	"initialConfig": {
		"designs": {
			"themeKey": "airmail",
			"themeTitle": "Airmail"
		},
		"rules": {
			"order": {
				"account": "shopify",
				"sendEmailTime": "Immediately",
				"sendItemNumber": 4,
				"sendItemType": "Best Matched",
				"defaultContact": "email",
				"defaultSender": "Shop's email",
				"enabled": false
			},
			"tracking": {
				"account": "shopify",
				"sendEmailTime": "Immediately",
				"sendItemNumber": 4,
				"sendItemType": "Best Matched",
				"defaultContact": "email",
				"defaultSender": "Shop's email",
				"enabled": false
			},
			"thankYou": {
				"account": "shopify",
				"sendEmailTime": "Immediately",
				"sendItemNumber": 4,
				"sendItemType": "Best Matched",
				"defaultContact": "email",
				"defaultSender": "Shop's email",
				"enabled": false
			}
		},
		"settings": {
			"accounts": {
				"shopify": {
					"shopName": "",
					"shopLogo": "",
					"shopLink": ""
				},
				"bigcommerce": {
					"shopName": "",
					"shopLogo": "",
					"shopLink": ""
				},
				"amazon": {
					"shopName": "",
					"shopLogo": "",
					"shopLink": ""
				},
				"walmart": {
					"shopName": "",
					"shopLogo": "",
					"shopLink": ""
				}
			},
			"contacts": {
				"email": "",
				"phone": "",
				"skype": ""
			},
			"socialAccounts": {
				"facebook": "",
				"twitter": "",
				"google": "",
				"pinterest": ""
			}
		},
		"utm": {
			"utm_source": "PayHelm",
			"utm_medium": "email"
		},
		"dataStatus": "loading"
	},
	"contacts": [
		{
			"key": "email",
			"title": "Email"
		},
		{
			"key": "phone",
			"title": "Phone"
		},
		{
			"key": "skype",
			"title": "Skype"
		}
	],
	"socialAccounts": [
		{
			"key": "facebook",
			"title": "Facebook"
		},
		{
			"key": "twitter",
			"title": "Twitter"
		},
		{
			"key": "google",
			"title": "Google+"
		},
		{
			"key": "pinterest",
			"title": "Pinterest"
		}
	],
	"templates": {
		"themes": [
			{
				"title": "Airmail",
				"key": "airmail"
			},
			{
				"title": "Cleave",
				"key": "cleave"
			},
			{
				"title": "Go",
				"key": "go"
			},
			{
				"title": "Goldstar",
				"key": "goldstar"
			},
			{
				"title": "Mantra",
				"key": "mantra"
			},
			{
				"title": "Meow",
				"key": "meow"
			},
			{
				"title": "Narrative",
				"key": "narrative"
			},
			{
				"title": "Neopolitan",
				"key": "neopolitan"
			},
			{
				"title": "Oxygen",
				"key": "oxygen"
			},
			{
				"title": "Skyline",
				"key": "skyline"
			},
			{
				"title": "Sunday",
				"key": "sunday"
			},
			{
				"title": "Zen Flat",
				"key": "zen-flat"
			}
		],
		"types": [
			{
				"title": "Order",
				"key": "order"
			},
			{
				"title": "Tracking",
				"key": "tracking"
			},
			{
				"title": "Thank You",
				"key": "thankYou"
			}
		]
	},
	"platforms": [
		{
			"key": "shopify",
			"title": "Shopify",
			"namePlaceHolder": "Shopify Name",
			"logoPlaceHolder": "Shopify Logo URL",
			"linkPlaceHolder": "Shopify URL"
		},
		{
			"key": "bigcommerce",
			"title": "Bigcommerce",
			"namePlaceHolder": "Bigcommerce Store Name",
			"logoPlaceHolder": "Bigcommerce Logo URL",
			"linkPlaceHolder": "Bigcommerce URL"
		},
		{
			"key": "amazon",
			"title": "Amazon",
			"namePlaceHolder": "Amazon Store Name",
			"logoPlaceHolder": "Amazon Logo URL",
			"linkPlaceHolder": "Amazon URL"
		},
		{
			"key": "walmart",
			"title": "Walmart",
			"namePlaceHolder": "Walmart Store Name",
			"logoPlaceHolder": "Walmart Logo URL",
			"linkPlaceHolder": "Walmart URL"
		}
	],
	"rules": {
		"sendEmailTime": [
			"Immediately",
			"Next Day"
		],
		"sendItemTypes": [
			"Best Matched",
			"Most Recent"
		]
	}
};