import React from 'react'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import store from './app/store'
import { validateDomain } from 'domainControl'
import Root from './Root'
import './assets/styles/main.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import './features/forms/forms.css'
import './features/highcharts/highcharts.css'
import { DEFAULT_APP_HOSTNAME, DEFAULT_APP_PROTOCOL } from 'modules/constants'

if (location.hostname === DEFAULT_APP_HOSTNAME && location.protocol !== DEFAULT_APP_PROTOCOL) {
  location.href = location.href.replace(location.protocol, DEFAULT_APP_PROTOCOL)
} else if (validateDomain()) {
  class Index extends React.Component {
    constructor(props) {
      super(props)
    }

    render() {
      return (
        <Provider store={store}>
          <Root />
        </Provider>
      )
    }
  }

  const container = document.getElementById('sub-analytics-root')
  const root = createRoot(container)
  root.render(React.createElement(Index))
  // render(React.createElement(Index), document.getElementById(''))
}