import React from 'react'
import moment from 'moment'
import { useConnectionClick } from '../Integrations/components/hooks'
import { useDispatch, useSelector } from 'react-redux'
import { selectRootAccounts, selectRootAdmin, selectRootUser } from '../../rootSlice'
import ConnectButton from '../Integrations/components/ConnectButton'
import ConnectionProgress from './ConnectionProgress'

export default function ChannelItem({ accounts, integration, backfills }) {
  const user = useSelector(selectRootUser)
  const {
    id: channelId,
    channel,
    title,
    description,
    logo,
    enabled: channelEnabled,
    backfillEnabled,
    settingsEnabled,
    publicKey,
    connected,
  } = integration

  const filteredAccounts = accounts.filter((_account) => {
    if (_account.channel !== channel) return false

    // Filter out accounts that already have running backfills
    const runningBackillExists = !!backfills.find((_backfill) => _account.merchant_id === _backfill.merchant_id)
    return !runningBackillExists
  })

  /**
   *
   * Handle connect click
   *
   */
  const onConnectClick = useConnectionClick({ user, integration })

  return (
    <div className="tw-px-4 tw-py-4 md:tw-py-3 tw-border-b tw-border-slate-200">
      {/* MOBILE VIEW */}
      <div className="tw-block md:tw-hidden">
        <div className="tw-flex tw-items-center tw-justify-between tw-mb-4">
          <div className="tw-w-24 tw-h-12 md:tw-w-20 md:tw-h-auto tw-flex tw-items-center tw-justify-start">{logo}</div>
          <ConnectButton onClick={onConnectClick} channelId={channelId} />
        </div>
        <div className="tw-text-sm tw-text-slate-500">{description}</div>
      </div>
      {/* END: MOBILE VIEW */}

      {/* DESKTOP VIEW */}
      <div className="tw-hidden md:tw-block">
        <div className="tw-flex tw-items-center tw-justify-between">
          <div className="tw-flex tw-items-center tw-space-x-3">
            <div className="tw-shrink-0 tw-w-20 tw-h-8 tw-flex tw-items-center tw-justify-center">{logo}</div>
            <div className="tw-text-sm tw-text-slate-500 tw-pr-6">{description}</div>
          </div>
          <ConnectButton onClick={onConnectClick} channelId={channelId} />
        </div>
      </div>
      {/* END: DESKTOP VIEW */}

      {filteredAccounts.map((_account, i) => {
        return (
          <div key={i} className="tw-mt-2 -tw-mb-2">
            <ConnectionProgress
              isLoading={false}
              label={_account.merchant_id}
              current={null}
              total={null}
              percentage={100}
              timeRemaining={null}
            />
          </div>
        )
      })}

      {backfills.map((backfill, i) => {
        const {
          status = 'running',
          current,
          total = 1,
          merchant_id: merchantId,
          channel: backfillChannel,
          email,
          created_at = new Date(),
          updated_at,
          start_date,
          end_date,
          time_remaining: timeRemaining,
        } = backfill

        if (backfillChannel !== channel) return null

        const percentage = parseInt((current / total) * 100)
        const _timeRemaining =
          timeRemaining === -1 || timeRemaining === 0 || _.isNil(timeRemaining)
            ? null
            : timeRemaining < 60
            ? `Done in ${timeRemaining}s`
            : `Done ${moment().add(timeRemaining, 'seconds').fromNow()}`

        return (
          <div key={i} className="tw-mt-2 -tw-mb-2">
            <ConnectionProgress
              isLoading={true}
              label={merchantId}
              current={current}
              total={total}
              percentage={percentage}
              timeRemaining={_timeRemaining}
            />
          </div>
        )
      })}
    </div>
  )
}
