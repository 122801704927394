import { statsNames } from 'modules/formatting'
import _ from 'lodash'

const defaultVisibleMetrics = {
  overview: ['total_sales', 'total_orders', 'total_items_sold', 'total_tax', 'total_store_fees', 'total_shippings'],
  product: ['total_sales', 'default_sales', 'total_orders', 'total_items_sold', 'total_products', 'total_product_taxes', 'total_product_discounts'],
  marketing: ['utm_orders', 'utm_sales', 'utm_items_sold', 'utm_avg_price', 'utm_avg_order', 'utm_avg_items_per_order'],
}
export const initialMetricConfigs = Object.keys(statsNames).reduce((acc, cur) => ({
  ...acc,
  [cur]: Object.assign({}, ...statsNames[cur].map((name, index) => ({
    [name]: { visible: defaultVisibleMetrics[cur].includes(name), order: index }
  })))
}), {})
export const metricConfigNames = { overview: 'Overview', product: 'Products', marketing: 'Marketing' }