import React from 'react'
import ReactHighcharts from 'react-highcharts'
import { Col, Table } from 'react-bootstrap'
import numeral from 'numeral'
import moment from 'momentExt'
import { hcFontFamily, hcDefaultColors } from 'modules/chart'
import { localStoragePayhelm } from 'modules/utils'
import ReactTooltip from 'react-tooltip'

const EmptyChart = ({title}) => (
  <div className="empty-chart-message">
    <div className="title">{title}</div>
    <div className="message">No data to display</div>
  </div>
)

export default class CustomHighcharts extends React.Component {

  /**
   * Set chart type of highcharts
   * @param {String} chartType 'table', 'column' or 'pie'
   */
  setChartType = (chartType) => {
    const { facetName, setChartTypeCallback } = this.props
    if (facetName && (chartType !== this.props.chartType)) {
      localStoragePayhelm[`${facetName}_chart`] = chartType
      setChartTypeCallback(facetName, chartType)
    }
  }

  convertToSecondaryConfigs = () => {
    const {
      facet, prevFacet, prevRange, currentRange
    } = this.props
    const facetSeries = facet.series[facet.metric]
    const facetFormatters = facet.formatters[facet.metric]
    const visibleCategories = facet.categories.map((bucket, index) => bucket !== 'N/A' || facetSeries[index])
    const visibleFilter = (bucket, index) => visibleCategories[index]
    const categories = facet.categories.filter(visibleFilter)
    const values = facet.values.filter(visibleFilter)
    const series = facetSeries.filter(visibleFilter)
    const prevSeries = prevFacet ? prevFacet.series[prevFacet.metric].filter(visibleFilter) : null
    let currentRangeLabel = currentRange ? `${moment(currentRange.startDate).format('MMM DD')} - ${moment(currentRange.endDate).format('MMM DD')}` : ''
    let prevRangeLabel = prevFacet ? `${moment(prevRange.startDate).format('MMM DD')} - ${moment(prevRange.endDate).format('MMM DD')}` : ''
    if (currentRangeLabel === prevRangeLabel) {
      currentRangeLabel = currentRange ? `${moment(currentRange.startDate).format('MMM DD YYYY')} - ${moment(currentRange.endDate).format('MMM DD YYYY')}` : ''
      prevRangeLabel = prevRange ? `${moment(prevRange.startDate).format('MMM DD YYYY')} - ${moment(prevRange.endDate).format('MMM DD YYYY')}` : ''
    }
    return {
      facetFormatters,
      categories,
      values,
      series,
      prevSeries,
      currentRangeLabel,
      prevRangeLabel,
    }
  }

  handleFilterClick = (filterKey, nextFilter) => {
    const { filterable, updateFilter, toggleFilter, currentFilter, filterVisible, setForceSearch, setFilterUpdated } = this.props
    if (!filterable) {
      return
    }
    if (updateFilter && currentFilter) {
      updateFilter({ [filterKey]: nextFilter })
      if (toggleFilter && filterVisible !== true) {
        toggleFilter()
      }
    }
    if (setForceSearch) {
      setForceSearch(true, true)
    }
    if (setFilterUpdated) {
      setFilterUpdated(true)
    }
  }

  getTable = () => {
    const { facet, height, currentFilter, prevFacet, filterable, limitedAccess, checkAccess } = this.props
    const { xFormatter, yAxisTitle, yPrefix } = facet
    const { facetFormatters, categories, series, prevSeries, currentRangeLabel, prevRangeLabel } = this.convertToSecondaryConfigs()
    return (
      <div style={{ padding: 10, height }}>
        <div style={{ fontFamily: 'Nunito', fontSize: 18, paddingBottom: 5 }}>{facet.title}</div>
        <div style={{ overflow: 'auto', height: height - 50 }}>
          <Table striped bordered hover responsive>
            <thead>
              <tr>
                <th>&nbsp;</th>
                <th>{yAxisTitle}{prevFacet ? <span><br/>{` (${currentRangeLabel})`}</span> : null}</th>
                {
                  prevFacet ? (
                    <th>{yAxisTitle}{prevFacet ? <span><br/>{` (${prevRangeLabel})`}</span> : null}</th>
                  ) : null
                }
              </tr>
            </thead>
            <tbody>
              {
                categories.map((bucket, index) => {
                  const filterInEffect = currentFilter.includes(bucket)
                  return (
                    <tr key={index}>
                      <td>
                        {
                          filterable ? (
                            <a
                              className="cursor-pointer"
                              title={filterInEffect ? 'Remove filter' : 'Add filter'}
                              onClick={limitedAccess ? checkAccess : () => {
                                const nextEntryKey = facet.reversedDict ? facet.reversedDict[bucket] : bucket
                                const nextFilter = filterInEffect ? (
                                  (currentFilter.length == 1) ? [null] : currentFilter.filter(v => v !== nextEntryKey)
                                ) : (
                                  [...currentFilter.filter(v => v !== null), nextEntryKey]
                                )
                                this.handleFilterClick(facet.key, nextFilter)
                              }}
                            >
                              {xFormatter ? xFormatter(bucket) : bucket}&nbsp;
                              <span
                                className="fa fa-filter"
                                style={filterInEffect ? { textDecoration: 'line-through'} : {}}
                              />
                            </a>
                          ) : (xFormatter ? xFormatter(bucket) : bucket)
                        }
                      </td>
                      <td>{`${yPrefix || ''} ${facetFormatters.formatter ? facetFormatters.formatter(series[index]) : numeral(series[index]).format(facetFormatters.format)}`}</td>
                      {
                        prevFacet ? (
                          <td>
                            {
                              prevSeries[index] === null ?
                              <span className="link-style" data-tip data-for={`chart-na-tooltip-${facet.key}-${index}`}>N/A</span> :
                              `${yPrefix || ''} ${facetFormatters.formatter ? facetFormatters.formatter(prevSeries[index]) : numeral(prevSeries[index]).format(facetFormatters.format)}`
                            }
                            <ReactTooltip id={`chart-na-tooltip-${facet.key}-${index}`}>
                              <div style={{ maxWidth: 200 }}>
                                This item has no sales or is not in the top search results during the compared period.
                              </div>
                            </ReactTooltip>
                          </td>
                        ) : null
                      }
                    </tr>
                  )
                })
              }
            </tbody>
          </Table>
        </div>
      </div>
    )
  }

  /**
   * Convert facet data and other props into Highcharts' config
   */
  getChartConfig = chartType => {
    const { handleFilterClick } = this
    const { facet, height, legend, currentFilter, prevFacet, filterable, limitedAccess, checkAccess } = this.props
    const { xFormatter, yAxisTitle, yPrefix, ySuffix } = facet
    const { facetFormatters, categories, values, series, prevSeries, currentRangeLabel, prevRangeLabel } = this.convertToSecondaryConfigs()
    return {
      neverReflow: true,
      chart: {
        type: chartType,
        height: height || (prevFacet ? 250 : 200),
        style: {
          fontFamily: hcFontFamily,
        }
      },
      colors: hcDefaultColors,
      legend: {
        enabled: !!prevFacet || legend,
        labelFormatter: function () {
          return this.index ? prevRangeLabel : currentRangeLabel
        }
      },
      credits: {
        enabled: false,
      },
      title: {
        text: facet.title,
        align: 'left',
      },
      plotOptions: {
        pie: {
          size: 160,
          dataLabels: {
            style: { textTransform: 'capitalize' },
          }
        },
        series: (facet.key === 'hour_sold' || !facet.key) ? {} : {
          cursor: filterable ? 'pointer' : 'default',
          point: {
            events: {
              click: limitedAccess ? checkAccess : function() {
                let nextEntryKey = this.val || (chartType === 'column' ? this.category : this.name)
                if (facet.reversedDict) {
                  nextEntryKey = facet.reversedDict[nextEntryKey]
                }
                const nextFilter = currentFilter.includes(nextEntryKey) ? (
                  (currentFilter.length == 1) ? [null] : currentFilter.filter(v => v !== nextEntryKey)
                ) : (
                  [...currentFilter.filter(v => v !== null), nextEntryKey]
                )
                handleFilterClick(facet.key, nextFilter)
              }
            }
          }
        }
      },
      xAxis: {
        labels: {
          style: { textTransform: 'capitalize' },
          ...(xFormatter ? {
            formatter: function() {
              return xFormatter(this.value)
            }
          } : {})
        },
        ...(chartType === 'column' ? { categories: categories } : {}),
      },
      yAxis: chartType === 'column' ? {
        title: { text: yAxisTitle },
        labels: {
          formatter: function() {
            return `${numeral(this.value).format(Math.abs(this.value) >= 10000 ? facetFormatters.axis_format_big : facetFormatters.axis_format)}`
          }
        },
        minRange: 1,
        minTickInterval: 1,
      } : {},
      tooltip: {
        formatter: function() {
          let dateLabel = prevFacet ? `${this.series.index ? prevRangeLabel : currentRangeLabel}<br/>` : ''
          if (this.y === null) {
            return dateLabel + `N/A (This item has no sales or is not in the top search results during the compared period.)`
          }
          return dateLabel + `${yPrefix || ''} ${(
            chartType === 'column' && xFormatter
          ) ? xFormatter(this.key) : this.key}: ${
            facetFormatters.formatter ? facetFormatters.formatter(this.y) : numeral(this.y).format(facetFormatters.format)} ${ySuffix || ''
          }`
        },
        style: { textTransform: 'capitalize' },
      },
      series: chartType === 'column' ? [series, prevSeries].filter(s => s).map(s => ({
        name: yAxisTitle, data: s,
      })) : [{
        name: yAxisTitle,
        data: categories.map((bucket, index) => {
          return { val: values[index], name: xFormatter ? xFormatter(bucket) : bucket, y: series[index] }
        })
      }]
    }
  }

  shouldComponentUpdate(nextProps) {
    let filterUpdated = false
    if (!nextProps.fetching && !this.props.fetching) {
      filterUpdated = (
        this.props.currentFilter.length !== nextProps.currentFilter.length
      ) || (
        this.props.currentFilter[0] !== nextProps.currentFilter[0]
      ) || (
        this.props.facet.chart_type !== nextProps.facet.chart_type
      )
    }
    return filterUpdated || (
      this.props.fetching && !nextProps.fetching
    ) || (
      this.props.facet !== nextProps.facet
    )
  }

  render() {
    const { cols, facet, facetName, empty, chartTypes = ['column', 'pie', 'table'], style, className = '' } = this.props
    const facetSeries = facet.series[facet.metric]
    const chartType = this.props.chartType || (facetName ? (facet.chart_type || localStoragePayhelm[`${facetName}_chart`] || facet.default_chart || 'column') : 'column')

    let body
    if (!facetSeries.length || empty) {
      body = <EmptyChart />
    }
    else if (chartType === 'table') {
      body = this.getTable()
    }
    else {
      body = <ReactHighcharts config={this.getChartConfig(chartType)} />
    }

    return (
      <Col xs={cols.xs} sm={cols.sm} md={cols.md} lg={cols.lg}>
        <div style={{position: "relative", ...style}} className={`custom-chart-box ${className}`}>
          {
            chartTypes.length > 1 ? (
              <div className="custom-chart-type-menu">
                {
                  chartTypes.includes('column') ? (
                    <span
                      className={`fa fa-bar-chart ${chartType == 'column' ? 'active' : ''}`}
                      onClick={() => chartType === 'column' ? null : this.setChartType('column')}
                    />
                  ) : null
                }
                {
                  chartTypes.includes('pie') ? (
                    <span
                      className={`fa fa-pie-chart ${chartType == 'pie' ? 'active' : ''}`}
                      onClick={() => chartType === 'pie' ? null : this.setChartType('pie')}
                    />
                  ) : null
                }
                {
                  chartTypes.includes('table') ? (
                    <span
                      className={`fa fa-table ${chartType == 'table' ? 'active' : ''}`}
                      onClick={() => chartType === 'table' ? null : this.setChartType('table')}
                    />
                  ) : null
                }
              </div>
            ) : null
          }
          { body }
        </div>
      </Col>
    )
  }
}
