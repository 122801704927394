import { countryCodeDict } from 'countries'
import { logoUrls } from 'urls'
import _ from 'lodash'

export const filterConfigs = {
  merchant_id: {
    title: 'Account',
    decodeFunc: (bucket) => {
      return {
        ...bucket,
        title: bucket.email,
        logo: logoUrls[bucket.channel],
      }
    },
    notEmpty: true,
  },
  channel: {
    title: 'Channel',
    decodeFunc: (bucket) => {
      return {
        ...bucket,
        logo: logoUrls[bucket.val],
      }
    },
  },
  financial_status: {
    title: 'Payment Status',
  },
  currency: {
    title: 'Currency',
  },
  billing_country_code: {
    title: 'Country/Region Bought In',
    decodeFunc: (bucket) => {
      return { ...bucket, title: bucket.title || countryCodeDict[bucket.val] || bucket.val }
    }
  },
  billing_province: {
    title: 'State/Province Bought In',
    decodeFunc: (bucket) => {
      return { ...bucket, title: bucket.val }
    }
  },
  billing_county_name: {
    title: 'County Bought In'
  },
  billing_city: {
    title: 'City Bought In'
  },
  shipping_country_code: {
    title: 'Country/Region Shipped To',
    decodeFunc: (bucket) => {
      return { ...bucket, title: bucket.title || countryCodeDict[bucket.val] || bucket.val }
    }
  },
  shipping_province: {
    title: 'State/Province Shipped To'
  },
  shipping_county_name: {
    title: 'County Shipped To'
  },
  shipping_city: {
    title: 'City Shipped To'
  },
  customer_id: {
    title: 'Customer',
  },
  customer_group_name: {
    title: 'Customer Group',
    defaultHidden: true,
  },
  processing_method: {
    title: 'Processing Method',
  },
  gateway: {
    title: 'Payment Gateway',
  },
  payment_method: {
    title: 'Payment Method',
  },
  credit_card_type: {
    title: 'Credit Card Type',
  },
  source_name: {
    title: 'Source',
  },
  app_id: {
    title: 'Sales Channel ID',
  },
  fulfillment_status: {
    title: 'Fulfillment Status',
  },
  fulfillment_full_address: {
    title: 'Fulfillment Address',
  },
  shipping_providers: {
    title: 'Shipping Provider',
  },
  shipping_methods: {
    title: 'Shipping Method',
  },
  coupon_codes: {
    title: 'Coupon Code',
  },
  product_ids: {
    title: 'Product',
    defaultHidden: true,
  },
  variant_ids: {
    title: 'Variant',
    defaultHidden: true,
  },
  brand_ids: {
    title: 'Brand',
  },
  marketplace_product_ids: {
    title: 'Marketplace Product ID',
  },
  skus: {
    title: 'SKU',
  },
  product_skus: {
    title: 'Product SKU'
  },
  bin_picking_numbers: {
    title: 'Bin Picking Number'
  },
  vendors: {
    title: 'Vendor',
  },
  product_types: {
    title: 'Product Tags',
  },
  category_titles: {
    title: 'Category',
  },
  catalog_titles_v1: {
    title: 'Catalog',
  },
  order_tags: {
    title: 'Tags',
  },
  tax_exempt_category: {
    title: 'Tax Exempt Category',
    defaultHidden: true,
  },
  utm_source: {
    title: 'UTM Source',
  },
  utm_medium: {
    title: 'UTM Medium',
  },
  utm_campaign: {
    title: 'UTM Campaign',
  },
  utm_content: {
    title: 'UTM Content',
  },
  utm_term: {
    title: 'UTM Term',
  },
  utm_id: {
    title: 'UTM ID',
  },
  sales_channel_name: {
    title: 'Sales Channel Name',
  },
  sales_channel_type: {
    title: 'Sales Channel Type',
  },
  sales_channel_platform: {
    title: 'Sales Channel Platform',
  },
  product_metafields: {
    title: 'Product Metafields',
    defaultHidden: true,
  },
  order_metafields: {
    title: 'Order Metafields',
    defaultHidden: true,
  },
  taxed: {
    title: 'Taxed',
    defaultHidden: true,
  },
  discounted: {
    title: 'Discounted',
    defaultHidden: true,
  },
  noted: {
    title: 'Staff Note',
    defaultHidden: true,
  },
  guest: {
    title: 'Guest',
    defaultHidden: true,
  },
  gateway_fee: {
    title: 'Gateway Fee',
    defaultHidden: true,
  },
  points_status: {
    title: 'Loyalty Points',
  },
  gift_cards: {
    title: 'Gift Card',
  }
}

const filterTypeTitles = {
  option: { title: 'Product Options' },
  custom_field: { title: 'Custom Product Fields' },
  customer_form_field: { title: 'Customer Form Fields' },
  shipping_form_field: { title: 'Shipping Form Fields' },
  customer_custom_attribute: { title: 'Customer Attributes' },
}

export const allFilterFormatters = {
  keywords: { title: 'Keyword(s)' },
  ...filterConfigs,
  ...filterTypeTitles,
}

export const filterArrangement = (facets, filterType = '', userConfigs = {}, forFilterEditor = false) => {
  if (filterType && !forFilterEditor && !_.get(userConfigs, [filterType, 'visible'])) {
    return []
  }
  const results = [], iterable = filterType ? facets : filterConfigs
  let index = 0
  for (var key in iterable) {
    if (facets && (!facets[key] || !facets[key].length)) {
      continue
    }
    if (!filterType && !facets && filterConfigs[key].defaultHidden) {
      continue
    }
    if (!filterType && !_.get(userConfigs, [key, 'visible'])) {
      continue
    }
    if (index && index % 3 == 2) {
      results.push({ clearFix: true })
    }
    results.push({
      key,
      title: (filterConfigs[key] || {}).title || key,
      decodeFunc: (filterConfigs[key] || {}).decodeFunc || null,
      filterType,
    })
    index ++
  }
  if (results.length && filterType) {
    results.unshift({ header: true, title: filterTypeTitles[filterType].title })
  }
  return results
}

export const initialFilterConfigs = Object.assign({}, Object.keys(filterConfigs).filter(
  k => !['product_ids', 'variant_ids'].includes(k)
).reduce((acc, cur, index) => ({
  ...acc,
  [cur]: { visible: true, order: index }
}), {}), {
  option: { visible: true },
  custom_field: { visible: true },
  customer_form_field: { visible: true },
  shipping_form_field: { visible: true },
  customer_custom_attribute: { visible: true },
})