import React, { useEffect, useState } from 'react'
import queryString from 'query-string'
import { FiChevronLeft, FiChevronRight, FiChevronsLeft, FiChevronsRight } from 'react-icons/fi'
import { VscEllipsis } from 'react-icons/vsc'
import { Button } from '@/features/shadcn/ui/button'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/features/shadcn/ui/select'
import { useDispatch, useSelector } from 'react-redux'
import {
  selectTabPagination,
  selectCurrentTab,
  selectTrafficReport,
  fetchTrafficReport,
  selectTabTotalPages,
} from '@/applications/Traffic/trafficReportSlice'
import { DEFAULT_TIME_PERIOD } from '../constants'
import { getFiltersFromQs } from '../utils'

export default function DataTablePagination({ table, showSelectedRowsLabel }) {
  const dispatch = useDispatch()
  const qs = queryString.parse(location.search)
  const timePeriod = qs.timePeriod || DEFAULT_TIME_PERIOD
  const filters = getFiltersFromQs(qs)
  const currentTab = useSelector(selectCurrentTab)
  const trafficReport = useSelector(selectTrafficReport)
  const tabPagination = useSelector((state) => selectTabPagination(state, currentTab))
  const pageIndex = tabPagination?.pageIndex || 1
  const pageSize = tabPagination?.pageSize || 20
  const tabTotalPages = useSelector(selectTabTotalPages)
  const totalPages = (currentTab && tabTotalPages[currentTab]) || 0

  // const [pageIndexOptions, setPageIndexOptions] = useState([])
  useEffect(() => {
    if (!trafficReport) return
    // const _pageIndexOptions = _.range(_totalPages).map((page) => ({
    //   label: `${page * pageSize + 1}-${page * pageSize + pageSize}`,
    //   value: page,
    // }))
    // setPageIndexOptions(_pageIndexOptions)
  }, [trafficReport])

  const getNextPage = async (_pageIndex) => {
    dispatch(
      fetchTrafficReport({
        timePeriod,
        pagination: { pageIndex: _pageIndex, pageSize },
        tabName: currentTab,
        filters: filters.map((f) => ({ ...f, op: 'eq' })),
      })
    )
  }

  return (
    <div className="tw-flex tw-items-center tw-justify-between tw-px-2">
      {showSelectedRowsLabel ? (
        <div className="tw-flex-1 tw-text-sm">
          {table.getFilteredSelectedRowModel().rows.length} of {table.getFilteredRowModel().rows.length} row(s)
          selected.
        </div>
      ) : (
        <div />
      )}

      <div className="tw-w-full tw-flex tw-flex-col tw-items-center md:tw-flex md:tw-items-center md:tw-space-x-6 tw-lg:space-x-8">
        <div className="tw-flex tw-items-center tw-space-x-2">
          <div className="tw-shrink-0 tw-text-sm tw-font-medium">Records per page</div>
          <Select
            value={`${pageSize}`}
            onValueChange={(value) => {
              dispatch(
                fetchTrafficReport({
                  timePeriod,
                  pagination: { pageIndex: 1, pageSize: Number(value) },
                  tabName: currentTab,
                  filters: filters.map((f) => ({ ...f, op: 'eq' })),
                })
              )
            }}
          >
            <SelectTrigger className="tw-h-8 tw-w-[70px]">
              <SelectValue placeholder={pageSize} />
            </SelectTrigger>
            <SelectContent side="top">
              {[10, 20, 30, 40, 50].map((pageSize) => (
                <SelectItem key={pageSize} value={`${pageSize}`}>
                  {pageSize}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>
        <div className="tw-flex tw-items-center">
          <div className="tw-flex tw-items-center tw-space-x-1">
            <Button
              variant="ghost"
              className="tw-flex tw-items-center tw-justify-center tw-space-x-1 !tw-pl-3 !tw-pr-2 tw-h-9"
              onClick={() => getNextPage(pageIndex - 1)}
              disabled={pageIndex === 1}
            >
              <FiChevronLeft className="tw-h-4 tw-w-4" />
              <span>Prev</span>
            </Button>
            <div className="tw-shrink-0 tw-flex tw-items-center tw-justify-center tw-text-sm tw-font-medium tw-space-x-2">
              {((pageIndex - 1) * pageSize + 1).toLocaleString()}-{(pageIndex * pageSize).toLocaleString()} of{' '}
              {(totalPages * pageSize).toLocaleString()}
              {/* <Select
                value={pageIndex}
                onValueChange={(value) => {
                  table.setPageSize(Number(value))
                }}
              >
                <SelectTrigger className="tw-h-8 tw-w-[70px]">
                  <SelectValue placeholder={pageIndex} />
                </SelectTrigger>
                <SelectContent side="top">
                  {pageIndexOptions.map(({ label, value }) => (
                    <SelectItem key={label} value={value}>
                      {label}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
              <div>of</div>
              <div>{(totalPages * pageSize).toLocaleString()}</div> */}
            </div>
            <Button
              variant="ghost"
              className="tw-flex tw-items-center tw-justify-center tw-space-x-1 !tw-pl-3 !tw-pr-2 tw-h-9"
              onClick={() => getNextPage(pageIndex + 1)}
              disabled={pageIndex >= totalPages}
            >
              <span>Next</span>
              <FiChevronRight className="tw-h-4 tw-w-4" />
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}
