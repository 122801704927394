import React from 'react'
import { bool } from 'prop-types'
import { connect } from 'react-redux'

import ConditionalRoute from './Router.conditionalRoute'

const AdminRoute = ({ admin, ...props }) =>
  <ConditionalRoute
    condition={admin}
    {...props}
  />

AdminRoute.propTypes = {
  admin: bool,
}

const mapStateToProps = store => ({
  admin: !!store.root.admin
})

export default connect(mapStateToProps)(AdminRoute)
