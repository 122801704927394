import { initialColumnConfigs } from './reportColumns'
import { initialMetricConfigs } from './metrics'
import { initialFilterConfigs } from './filters'
import { initialChartConfigs } from './charts'

export const defaultDashboardConfig = {
  columns: initialColumnConfigs,
  metrics: initialMetricConfigs,
  filters: initialFilterConfigs,
  charts: initialChartConfigs,
}

export { initialChartConfigs, updateConfigChartType } from './charts'
export { filterConfigs, allFilterFormatters, filterArrangement, initialFilterConfigs } from './filters'
export { initialMetricConfigs, metricConfigNames } from './metrics'
export { initialSortOrders, proCustReportKeys, reportKeys, initialColumnConfigs, updateConfigSortOrder } from './reportColumns'
