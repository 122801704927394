import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import { useForm } from 'react-hook-form'
import MyCheckbox from '../../../../../features/forms/MyCheckbox'
import { toast } from 'react-toastify'
import { updateTeamAccount } from '../../../settingsSlice'

export default function AccessPermissionChangeModal({ email, accessPermission, visible, closeModal }) {
  const dispatch = useDispatch()

  /**
   *
   * Handle form setup
   *
   */
  const { register, handleSubmit, setValue } = useForm()

  /**
   *
   * Set form default values
   *
   */
  useEffect(() => {
    if (!accessPermission) return

    accessPermission.forEach(({ id, checked }) => {
      setValue(id, checked)
    })
  }, [setValue, accessPermission])

  /**
   *
   * Handle form submit
   *
   */
  const onSubmit = async (values) => {
    const updatePayload = accessPermission.map((item) => ({ ...item, checked: values[item.id] }))

    try {
      await dispatch(updateTeamAccount({ invited: email, accessPermission: updatePayload }))
      toast.success(`Access permission successfully updated for this account: ${email}`)
    } catch (error) {
      console.log('Error in AccessPermissionChangeModal onSubmit', error)
      toast.error(`Something went wrong updating the access permission for this account: ${email}`)
    }

    closeModal()
  }

  if (!accessPermission) return null

  return (
    <Modal show={visible} onHide={closeModal}>
      <Modal.Body>
        <div className="tw-p-6">
          <h2 className="tw-text-xl tw-mb-6">Change Access Permissions</h2>

          <form onSubmit={handleSubmit(onSubmit)} className="tw-w-full">
            <div className="tw-grid tw-grid-cols-2 tw-gap-4">
              {accessPermission.map(({ id, label }) => {
                return (
                  <div key={id} className="tw-col-span-1">
                    <MyCheckbox register={register} name={id} label={label} />
                  </div>
                )
              })}
            </div>

            <div className="tw-flex tw-items-center tw-justify-end tw-space-x-2 tw-mt-4">
              <Button variant="secondary" size="sm" className="tw-mt-4" onClick={closeModal}>
                Cancel
              </Button>
              <Button variant="primary" size="sm" className="tw-mt-4" type="submit">
                Update access permissions
              </Button>
            </div>
          </form>
        </div>
      </Modal.Body>
    </Modal>
  )
}
