import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import { validateDate } from '../../../features/forms/validators'
import { useForm } from 'react-hook-form'
import MyInput from '../../../features/forms/MyInput'
import MySelect from '../../../features/forms/MySelect'
import { selectCurrentChannel, selectCurrentChannelAccount } from '../integrationsSlice'
import { selectRootAccounts, selectRootUser } from '../../../rootSlice'
import { fetchBackfill } from '../../../actions/backFillActions'

/**
 *
 * Select field options
 *
 */
const backfillTypes = [
  {
    type: 'last',
    title: 'Last',
    formProps: {
      name: 'lastDays',
      options: [
        { label: 'All History', value: 'all' },
        { label: '1 day', value: 1 },
        { label: '7 days', value: 7 },
        { label: '14 days', value: 14 },
        { label: '30 days', value: 30 },
        { label: '90 days', value: 90 },
        { label: '1 year', value: 365 },
        { label: '2 years', value: 730 },
      ],
      rules: {},
    },
  },
  {
    type: 'back_to',
    title: 'Back to',
    formProps: {
      name: 'backToDate',
      placeholder: 'YYYY-MM-DD',
      rules: { required: 'Required', validate: validateDate },
    },
  },
  {
    type: 'until',
    title: 'Until',
    formProps: {
      name: 'untilDate',
      placeholder: 'YYYY-MM-DD',
      rules: { required: 'Required', validate: validateDate },
    },
  },
  {
    type: 'on',
    title: 'On',
    formProps: { name: 'onDate', placeholder: 'YYYY-MM-DD', rules: { required: 'Required', validate: validateDate } },
  },
  {
    type: 'orderId',
    title: 'Order ID',
    formProps: { name: 'orderId', placeholder: '10012345', rules: { required: 'Required' } },
  },
  {
    type: 'product',
    title: 'Product',
    formProps: { name: 'product', disabled: true, values: 'product' },
  }
]

export default function BackfillModal({ visible, closeModal }) {
  const dispatch = useDispatch()

  /**
   *
   * Handle accounts
   *
   */
  const currentChannelAccount = useSelector(selectCurrentChannelAccount)

  /**
   *
   * Handle backfill options
   *
   */
  let backfillTypeOptions = backfillTypes.map(({ type, title }) => ({ value: type, label: title }))

  if (currentChannelAccount?.channel === 'Etsy') {
    backfillTypeOptions = backfillTypes.map(({ type, title }) => ({ value: type, label: title })).filter(({ value }) => value !== 'orderId')
  }
  else if (currentChannelAccount?.channel === 'Amazon') {
    backfillTypeOptions = backfillTypes.map(({ type, title }) => ({ value: type, label: title })).filter(({ value }) => !['orderId', 'product'].includes(value))
  }
  else if (currentChannelAccount?.channel === 'Wix') {
    backfillTypeOptions = backfillTypes.map(({ type, title }) => ({ value: type, label: title })).filter(({ value }) => value !== 'product')
  }

  /**
   *
   * Handle form
   *
   */
  const {
    register,
    handleSubmit,
    watch,
    reset: resetForm,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      backfillType: backfillTypeOptions[0].value,
    },
  })

  /**
   *
   * Watch the "backfill type" field (first field) and change the "backfill options" field (second field)
   *
   */
  const watchBackfillType = watch('backfillType')
  const [backfillOptionsFormProps, setBackfillOptionsFormProps] = useState(null)
  useEffect(() => {
    const backfillType = backfillTypes.find(({ type: backfillType }) => backfillType === watchBackfillType)
    if (backfillType) {
      // Set the second field formProps
      setBackfillOptionsFormProps(backfillType.formProps)

      // Also remove form values from previous selections
      resetForm()
      setValue('backfillType', watchBackfillType)
    }
  }, [watchBackfillType])

  /**
   *
   * Handle form submit
   *
   */
  const onSubmit = (values) => {

    let currentAccountChannelAccountId = currentChannelAccount.merchant_id

    if (currentChannelAccount.channel === 'Etsy') {
      currentAccountChannelAccountId = currentChannelAccount.shop_id
    }

    if (values.lastDays) {
      if (values.lastDays === 'all') {
        // Special case for all history
        dispatch(fetchBackfill(currentAccountChannelAccountId, currentChannelAccount.channel))
      } else {
        dispatch(
          fetchBackfill(currentAccountChannelAccountId, currentChannelAccount.channel, {
            total: parseInt(values.lastDays),
          })
        )
      }
    }

    if (values.backToDate || values.untilDate || values.onDate) {
      dispatch(
        fetchBackfill(currentAccountChannelAccountId, currentChannelAccount.channel, {
          date: values.backToDate || values.untilDate || values.onDate,
          type: values.backfillType,
        })
      )
    }

    if (values.orderId) {
      dispatch(
        fetchBackfill(currentAccountChannelAccountId, currentChannelAccount.channel, { order: values.orderId })
      )
    }

    if (values.backfillType === 'product') {
      dispatch(
        fetchBackfill(currentAccountChannelAccountId, currentChannelAccount.channel, { product: true })
      )
    }

    resetForm()
    closeModal()
  }

  return (
    <Modal show={visible} onHide={closeModal}>
      <Modal.Body>
        <div className="tw-p-6">
          <h2 className="tw-text-xl tw-mb-6">Backfill options</h2>

          <form onSubmit={handleSubmit(onSubmit)} className="tw-w-full">
            <div className="tw-grid tw-grid-cols-2 tw-gap-4">
              <div className="tw-col-span-1">
                <MySelect
                  register={register}
                  name="backfillType"
                  errors={errors}
                  rules={{ required: true }}
                  options={backfillTypeOptions}
                />
              </div>

              <div className="tw-col-span-1">
                {backfillOptionsFormProps?.options && (
                  <MySelect
                    register={register}
                    name={backfillOptionsFormProps?.name}
                    errors={errors}
                    rules={backfillOptionsFormProps?.rules}
                    options={backfillOptionsFormProps?.options}
                  />
                )}

                {backfillOptionsFormProps?.placeholder && (
                  <MyInput
                    register={register}
                    name={backfillOptionsFormProps?.name}
                    errors={errors}
                    rules={backfillOptionsFormProps?.rules}
                    placeholder={backfillOptionsFormProps?.placeholder}
                  />
                )}
              </div>
            </div>

            <div className="tw-flex tw-justify-end tw-space-x-2">
              <Button variant="secondary" size="sm" className="tw-mt-4" onClick={closeModal}>
                Cancel
              </Button>
              <Button variant="primary" size="sm" className="tw-mt-4" type="submit">
                Run Backfill
              </Button>
            </div>
          </form>
        </div>
      </Modal.Body>
    </Modal>
  )
}
