import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import {
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  useReactTable,
  getSortedRowModel,
} from '@tanstack/react-table'
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/features/shadcn/ui/table'
import { useDispatch, useSelector } from 'react-redux'
import { selectSelectedRows, setSelectedRows } from '@/applications/Traffic/trafficReportSlice'
import DataTablePagination from './DataTablePagination'
import DataTableDynamicPagination from './DataTableDynamicPagination'

export default function DataTable({
  columns,
  data,
  defaultSorting = [],
  showSelectedRowsLabel = true,
  showPagination = false,
  showDynamicPagination = false,
  thClassName = '',
}) {
  const dispatch = useDispatch()

  // Handle row selection
  const rowSelection = useSelector(selectSelectedRows)
  useEffect(() => {
    if (!data || _.isEmpty(data)) return
    // Select all rows by default
    const _selectedRows = data.map((row, i) => i).reduce((acc, i) => ({ ...acc, [i]: true }), {})
    dispatch(setSelectedRows(_selectedRows))
  }, [data])

  // Handle sorting
  const [sorting, setSorting] = useState(defaultSorting)

  // Handle pagination
  const [pagination, setPagination] = useState({
    pageIndex: 0, //initial page index
    pageSize: 50, //default page size
  })

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onPaginationChange: setPagination,
    onRowSelectionChange: (selectedRows) => {
      dispatch(setSelectedRows(selectedRows(rowSelection)))
    },
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    state: {
      pagination,
      rowSelection,
      sorting,
    },
  })

  return (
    <div className="tw-border-b">
      <Table>
        <TableHeader>
          {table.getHeaderGroups().map((headerGroup) => (
            <TableRow key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                return (
                  <TableHead key={header.id} className={`tw-min-w-24 tw-truncate tw-text-right ${thClassName}`}>
                    {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                  </TableHead>
                )
              })}
            </TableRow>
          ))}
        </TableHeader>
        <TableBody>
          {table.getRowModel().rows?.length ? (
            table.getRowModel().rows.map((row) => (
              <TableRow key={row.id} data-state={row.getIsSelected() && 'selected'}>
                {row.getVisibleCells().map((cell) => (
                  <TableCell key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</TableCell>
                ))}
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={columns.length} className="tw-h-24 tw-text-center">
                No results.
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>

      {showPagination && data && !_.isEmpty(data) && (
        <div className="tw-my-4">
          <DataTablePagination table={table} />
        </div>
      )}

      {showDynamicPagination && data && !_.isEmpty(data) && (
        <div className="tw-my-4">
          <DataTableDynamicPagination table={table} showSelectedRowsLabel={showSelectedRowsLabel} />
        </div>
      )}
    </div>
  )
}
