import React from 'react'
import { getAccountStatus, linkAccount } from 'account'
import { Alert, Button } from 'react-bootstrap'

const getAccountAction = (accounts, topUrl, admin, setParentState) => {
  let accountStatus = null, keyAccount
  for (var accountIndex in accounts) {
    accountStatus = getAccountStatus(accounts[accountIndex])
    if (accountStatus.tokenExpired) {
      keyAccount = accounts[accountIndex]
      if (admin || keyAccount.channel !== 'eBay') {
        return {
          message: (
            <span>
              {`Please link your ${keyAccount.channel} account `}
              <b>{keyAccount.account_name}</b>
              {` to enable data history & ongoing data accuracy for your account.`}
            </span>
          ),
          ctaText: 'Link Seller Account',
          callback: () => {
            if (keyAccount.channel === 'Shopify') {
              setParentState({ linkModalOpenFor: 'Shopify' })
            }
            else {
              linkAccount(keyAccount, true, topUrl)
            }
          },
        }
      }
    }
  }
  return null
}

const renderAccountAction = (accounts, topUrl, admin) => {
  const action = getAccountAction(accounts, topUrl, admin)
  if (action) {
    return (
      <Alert variant="warning">
        {action.message}
        &nbsp;&nbsp;
        <Button
          variant="warning"
          style={{marginTop: 5, marginBottom: 5}}
          onClick={() => action.callback()}
        >
          {action.ctaText}
        </Button>
      </Alert>
    )
  }
  return null
}

export default renderAccountAction
