import React from 'react'
import { Button, Modal } from 'react-bootstrap'
import { FiAlertTriangle } from 'react-icons/fi'

export default function ConfirmModal({ visible, closeModal, generateAPIKey }) {
  const handleGenerateAPIKey = async () => {
    generateAPIKey()
    closeModal()
  }

  return (
    <Modal show={visible} onHide={closeModal} size="sm">
      <Modal.Body>
        <div className="tw-mb-6 tw-text-center">
          <div className="tw-flex tw-justify-center tw-mb-6">
            <FiAlertTriangle className="tw-text-5xl tw-text-red-600" />
          </div>
          <div className="tw-mb-2">You already have an API key.</div>
          <div>Are you sure you want to re-generate the API key? The previous key will no longer be usable.</div>
        </div>

        <div className="tw-flex tw-items-center tw-justify-end tw-space-x-2">
          <Button variant="secondary" onClick={closeModal}>
            Cancel
          </Button>
          <Button onClick={handleGenerateAPIKey}>Re-generate API Key</Button>
        </div>
      </Modal.Body>
    </Modal>
  )
}
