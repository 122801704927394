import React, { useEffect } from 'react'
import { IntercomAPI } from 'react-intercom'
import GenericConnectionModalContent from '../channels/generic/GenericConnectionModalContent'
import ShipstationConnectionModalContent from '../channels/shipstation/ShipstationConnectionModalContent'
import SlackSettingsModalContent from '../channels/slack/SlackSettingsModalContent'
import APIStoreConnectionModalContent from '../channels/apistore/ApiStoreConnectionModal'
import {
  openConnectionModal,
  openCsvModal,
  openSettingsModal,
  postSlackMessage,
  setCurrentChannel,
  setCurrentChannelAccount,
} from '../integrationsSlice'
import { linkFromChannel } from '../../../modules/account'
import {
  SLACK_CLIENT_ID,
  SLACK_DATA_STATUS_CHANNEL_ID,
  SLACK_REDIRECT_URI,
  SLACK_SCOPES,
} from '../../../modules/constants'
import { getCookie } from '../../../modules/cookie'
import { useDispatch } from 'react-redux'
import ShipsuranceConnectionModalContent from '../channels/shipsurance/ShipsuranceConnectionModalContent'
import OpenAIConnectionModalContent from '../channels/openai/OpenAIConnectionModalContent'
import GoogleConnectionModal from '../channels/google/GoogleConnectionModal'
import PayHelmPixelModalContent from '../channels/payhelmPixel/PayHelmPixelModalContent'
import FeedModalContent from '../channels/feed/FeedModalContent'
import { topRedirect } from 'utils'

export function useConnectionModalContent({ ownerId, channel, retrieveAccounts, accounts }) {
  const { channel: channelName } = channel || {}
  const channelToModalContentMap = {
    OpenAI: <OpenAIConnectionModalContent ownerId={ownerId} channel={channel} retrieveAccounts={retrieveAccounts} />,
    Shipstation: <ShipstationConnectionModalContent />,
    Shipsurance: <ShipsuranceConnectionModalContent retrieveAccounts={retrieveAccounts} />,
    'API Store': <APIStoreConnectionModalContent retrieveAccounts={retrieveAccounts} />,
    'Google Eco': <GoogleConnectionModal />,
    'PayHelm Pixel': <PayHelmPixelModalContent newConnection={true} accounts={accounts} retrieveAccounts={retrieveAccounts}/>,
    'Authorize.Net': <GenericConnectionModalContent ownerId={ownerId} channel={channel} retrieveAccounts={retrieveAccounts} accountLabel={'Account Name'} publicKeyLabel={'API Login ID'} privateKeyLabel={'Transaction Key'} />,
    'Feedonomics': <FeedModalContent retrieveAccounts={retrieveAccounts} channel={channel} />,
    'DataFeedWatch': <FeedModalContent retrieveAccounts={retrieveAccounts} channel={channel} />,
    'Google Merchant Center': <FeedModalContent retrieveAccounts={retrieveAccounts} channel={channel} />,
  }

  // Fall back to generic modal content if not specified
  const modalContent = channelToModalContentMap[channelName] || (
    <GenericConnectionModalContent ownerId={ownerId} channel={channel} retrieveAccounts={retrieveAccounts} />
  )

  if (!channel) return { content: null }

  return { content: modalContent }
}

export function useSettingsModalContent({ account, accounts, retrieveAccounts, channel }) {
  const { channel: channelName } = account || {}
  const channelToModalContentMap = {
    Slack: <SlackSettingsModalContent />,
    'PayHelm Pixel': <PayHelmPixelModalContent accounts={accounts} retrieveAccounts={retrieveAccounts}/>,
    'Feedonomics': <FeedModalContent retrieveAccounts={retrieveAccounts} channel={channel} mode="settings"/>,
    'DataFeedWatch': <FeedModalContent retrieveAccounts={retrieveAccounts} channel={channel} mode="settings"/>,
    'Google Merchant Center': <FeedModalContent retrieveAccounts={retrieveAccounts} channel={channel} mode="settings"/>,
  }

  const modalContent = channelToModalContentMap[channelName] || null

  if (!account || !channelName) return { content: null }

  return { content: modalContent }
}

export function useConnectionClick({ user, integration }) {
  const dispatch = useDispatch()

  /**
   *
   * Handle special cases
   *
   */
  const onConnectClick = () => {
    const { id: channelId, enabled: channelEnabled } = integration

    dispatch(setCurrentChannel(integration))

    if (!channelEnabled) {
      // For integrations that are not yet implemented, we show it a generic "secret key" input to start a conversation
      dispatch(openConnectionModal())

      // Send message to a slack channel for notification
      dispatch(
        postSlackMessage({
          channel: SLACK_DATA_STATUS_CHANNEL_ID,
          text: `${user.email} clicked connect button for ${integration.title} integration`,
        })
      )

      // Track it via intercom
      IntercomAPI('trackEvent', 'clicked-integration-connect-button', {
        email: user.email,
        channel: integration.channel,
      })

      return
    }

    if ([
      'shipstation', 'openai', 'shipsurance',
      'api_store', 'google', 'payhelm_pixel',
      'feedonomics', 'datafeedwatch', 'google_merchant_center'
    ].includes(channelId)) {
      dispatch(openConnectionModal())
      return
    }

    if (channelId === 'google_analytics') {
      linkFromChannel('google')
      return
    }

    if (channelId === 'google_ads') {
      linkFromChannel('google ads')
      return
    }

    if (channelId === 'google_drive') {
      linkFromChannel('google_drive')
      return
    }

    if (channelId === 'slack') {
      const unique_user_id = getCookie('unique_user_id') // grab app_token as the user identifier
      topRedirect({
        url: `https://slack.com/oauth/v2/authorize?redirect_uri=${SLACK_REDIRECT_URI}&client_id=${SLACK_CLIENT_ID}&state=${unique_user_id}&scope=${SLACK_SCOPES}`
      })
      return
    }

    if (channelId === 'upload') {
      dispatch(openCsvModal())
      return
    }

    linkFromChannel(channelId)
  }

  return onConnectClick
}
