import { localStoragePayhelm } from 'modules/utils'

const viewList = ['overview', 'customers', 'price_analysis', 'retention', 'countries', 'mrr']
const viewIndex = location.pathname === '/subscribers' ? viewList.indexOf((location.search.match(/[?&]view=(\w+)(&|$)/) || [null, 'overview'])[1]) : 0
const viewsWithDatePicker = [0, 2, 3, 4]
const customersStatuses = ['All Customers', 'Cancelled', 'Active']
const localCustomersStatusIndex = customersStatuses.indexOf(localStoragePayhelm.customerDisplayStatus)
const localCustomersRange = Number(localStoragePayhelm.customerSearchRange)
const localCustomersType = localStoragePayhelm.customerSearchType
const localRetentionGrouping = localStoragePayhelm.retentionGrouping
const defaultRetentionGroupOption = 'Month'

const initialState = {
  viewList: viewList,
  viewIndex: viewIndex < 0 ? 0 : viewIndex,

  overviewStats: null,
  overviewLoadingData: true,
  overviewDateRangeChanged: false,
  overviewDisplayMetrics: ['acquisitions', 'cancels', 'total_sales', 'payments', 'avg_payment', 'net'],
  overviewDataError: false,

  customersStatuses: customersStatuses,
  customersRange: localCustomersRange || 30,
  customersType: localCustomersType || 'max',
  customersStatusIndex: localCustomersStatusIndex < 0 ? 0 : localCustomersStatusIndex,
  customersStatusFilter: 'All Customers',
  customers: null,
  customersLoadingData: true,
  customersDateRangeChanged: false,
  customersDataError: false,
  customersSortOrder: { sortBy: 'profile_start_month', sortAsc: false },

  priceAnalysis: null,
  priceSummary: null,
  priceLoadingData: true,
  priceDateRangeChanged: false,
  priceDataError: false,
  priceSortOrder: { sortBy: 'subscribers_end', sortAsc: false },

  retentionGrouping: localRetentionGrouping || defaultRetentionGroupOption,
  retentionLoading: true,
  retentionGraphData: null,
  retentionHeatmapData: null,
  retentionStats: {},
  retensionExportData: null,
  retentionDateRangeChanged: false,
  retentionDataError: false,

  worldMapLoading: true,
  worldMapDataError: false,
  worldMapSelectedMetricIndex: 0,
  worldMapData: [],
  worldMapFetchedStart: null,
  worldMapFetchedEnd: null,
  worldMapSortOrder: { sortBy: 'country', sortAsc: true },

  mrrStats: null,
  mrrLoadingData: true,
  mrrDateRangeChanged: false,
  mrrDataError: false,
}

initialState.datePickerVisible = viewsWithDatePicker.includes(initialState.viewIndex)

export default function reducer(state = initialState, action) {
  switch(action.type) {
    case "SET_VIEW_SUBSCRIBERS": {
      return {
        ...state,
        viewIndex: action.payload,
        datePickerVisible: viewsWithDatePicker.includes(action.payload),
      }
    }
    // Overview
    case 'UPDATE_OVERVIEW_STATS_SUBSCRIBERS': {
      return {
        ...state,
        overviewStats: action.payload,
      }
    }
    case 'UPDATE_OVERVIEW_LOADING_DATA_SUBSCRIBERS': {
      return {
        ...state,
        overviewLoadingData: action.payload,
      }
    }
    case 'UPDATE_OVERVIEW_DATE_RANGE_CHANGED_SUBSCRIBERS': {
      return {
        ...state,
        overviewDateRangeChanged: action.payload,
      }
    }
    case 'UPDATE_OVERVIEW_DATA_ERROR_SUBSCRIBERS': {
      return {
        ...state,
        overviewDataError: action.payload,
      }
    }
    // Customers
    case 'UPDATE_CUSTOMERS_RANGE_SUBSCRIBERS': {
      return {
        ...state,
        customersRange: action.payload,
      }
    }
    case 'UPDATE_CUSTOMERS_TYPE_SUBSCRIBERS': {
      return {
        ...state,
        customersType: action.payload,
      }
    }
    case 'UPDATE_CUSTOMERS_STATUS_INDEX_SUBSCRIBERS': {
      return {
        ...state,
        customersStatusIndex: action.payload,
      }
    }
    case 'UPDATE_CUSTOMERS_STATUS_FILTER_SUBSCRIBERS': {
      return {
        ...state,
        customersStatusFilter: action.payload,
      }
    }
    case 'UPDATE_CUSTOMERS_SUBSCRIBERS': {
      return {
        ...state,
        customers: action.payload,
      }
    }
    case 'UPDATE_CUSTOMERS_LOADING_DATA_SUBSCRIBERS': {
      return {
        ...state,
        customersLoadingData: action.payload,
      }
    }
    case 'UPDATE_CUSTOMERS_DATE_RANGE_CHANGED_SUBSCRIBERS': {
      return {
        ...state,
        customersDateRangeChanged: action.payload,
      }
    }
    case 'UPDATE_CUSTOMERS_DATA_ERROR_SUBSCRIBERS': {
      return {
        ...state,
        customersDataError: action.payload,
      }
    }
    case 'UPDATE_CUSTOMERS_SORT_ORDER_SUBSCRIBERS': {
      return {
        ...state,
        customersSortOrder: action.payload,
      }
    }
    // Price analysis
    case 'UPDATE_PRICE_ANALYSIS_SUBSCRIBERS': {
      return {
        ...state,
        priceAnalysis: action.payload,
      }
    }
    case 'UPDATE_PRICE_SUMMARY_SUBSCRIBERS': {
      return {
        ...state,
        priceSummary: action.payload,
      }
    }
    case 'UPDATE_PRICE_LOADING_DATA_SUBSCRIBERS': {
      return {
        ...state,
        priceLoadingData: action.payload,
      }
    }
    case 'UPDATE_PRICE_DATE_RANGE_CHANGED_SUBSCRIBERS': {
      return {
        ...state,
        priceDateRangeChanged: action.payload,
      }
    }
    case 'UPDATE_PRICE_DATA_ERROR_SUBSCRIBERS': {
      return {
        ...state,
        priceDataError: action.payload,
      }
    }
    case 'UPDATE_PRICE_SORT_ORDER_SUBSCRIBERS': {
      return {
        ...state,
        priceSortOrder: action.payload,
      }
    }
    // Retention view
    case 'UPDATE_RETENTION_GROUPING_SUBSCRIBERS': {
      return {
        ...state,
        retentionGrouping: action.payload,
      }
    }
    case 'UPDATE_RETENTION_GRAPH_DATA_SUBSCRIBERS': {
      return {
        ...state,
        retentionGraphData: action.payload,
      }
    }
    case 'UPDATE_RETENTION_HEATMAP_DATA_SUBSCRIBERS': {
      return {
        ...state,
        retentionHeatmapData: action.payload,
      }
    }
    case 'UPDATE_RETENTION_EXPORT_DATA_SUBSCRIBERS': {
      return {
        ...state,
        retensionExportData: action.payload,
      }
    }
    case 'UPDATE_RETENTION_STATS_SUBSCRIBERS': {
      return {
        ...state,
        retentionStats: action.payload,
      }
    }
    case 'UPDATE_RETENTION_LOADING_SUBSCRIBERS': {
      return {
        ...state,
        retentionLoading: action.payload,
      }
    }
    case 'UPDATE_RETENTION_DATE_RANGE_CHANGED_SUBSCRIBERS': {
      return {
        ...state,
        retentionDateRangeChanged: action.payload,
      }
    }
    case 'UPDATE_RENTENTION_DATA_ERROR_SUBSCRIBERS': {
      return {
        ...state,
        retentionDataError: action.payload,
      }
    }
    // World map
    case 'UPDATE_WORLD_MAP_DATA_SUBSCRIBERS': {
      return {
        ...state,
        worldMapData: action.payload,
      }
    }
    case 'UPDATE_WORLD_MAP_SELECTED_METRIC_INDEX_SUBSCRIBERS': {
      return {
        ...state,
        worldMapSelectedMetricIndex: action.payload,
      }
    }
    case 'UPDATE_WORLD_MAP_FETCH_START_SUBSCRIBERS': {
      return {
        ...state,
        worldMapFetchedStart: action.payload,
      }
    }
    case 'UPDATE_WORLD_MAP_FETCH_END_SUBSCRIBERS': {
      return {
        ...state,
        worldMapFetchedEnd: action.payload,
      }
    }
    case 'UPDATE_WORLDMAP_LOADING_SUBSCRIBERS': {
      return {
        ...state,
        worldMapLoading: action.payload,
      }
    }
    case 'UPDATE_WORLD_MAP_DATA_ERROR_SUBSCRIBERS': {
      return {
        ...state,
        worldMapDataError: action.payload,
      }
    }
    case 'UPDATE_WORLD_MAP_SORT_ORDER_SUBSCRIBERS': {
      return {
        ...state,
        worldMapSortOrder: action.payload,
      }
    }
    // MRR
    case 'UPDATE_MRR_STATS_SUBSCRIBERS': {
      return {
        ...state,
        mrrStats: action.payload,
      }
    }
    case 'UPDATE_MRR_LOADING_DATA_SUBSCRIBERS': {
      return {
        ...state,
        mrrLoadingData: action.payload,
      }
    }
    case 'UPDATE_MRR_DATE_RANGE_CHANGED_SUBSCRIBERS': {
      return {
        ...state,
        mrrDateRangeChanged: action.payload,
      }
    }
    case 'UPDATE_MRR_DATA_ERROR_SUBSCRIBERS': {
      return {
        ...state,
        mrrDataError: action.payload,
      }
    }
  }
  return state
}