import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import { Button } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import Alert from 'react-bootstrap/Alert'
import { useDispatch, useSelector } from 'react-redux'
import {
  fetchFeedsAccount,
  createFeedsAccount,
} from '../../../../features/feeds/feedsSlices.js'
import MyInput from '../../../../features/forms/MyInput'
import { closeConnectionModal, closeSettingsModal } from '../../integrationsSlice'

export default function FeedModalContent({ channel, retrieveAccounts, mode }) {
  const dispatch = useDispatch()
  const [updated, setUpdated] = useState(false)
  const [error, setError] = useState(false)
  const closeModal = () => {
    setUpdated(false)
    return dispatch(mode === 'settings' ? closeSettingsModal() : closeConnectionModal())
  }

  const { id: channelId, channel: channelName } = channel || {}
  const feedsStatus = useSelector((state) => {
    return state.feeds[channelId].account?.status || 'idle'
  })
  const feedsConfig = useSelector((state) => {
    return state.feeds[channelId].account?.data || null
  })
  const requireUrl = channelId !== 'google_merchant_center'
  useEffect(() => {
    if (feedsStatus === 'idle') {
      dispatch(fetchFeedsAccount(channelId))
    }
  }, [feedsStatus])

  /**
   *
   * Handle form
   *
   */
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors, isSubmitting },
  } = useForm()

  // Set default form values
  useEffect(() => {
    if (requireUrl) {
      setValue('feed_url', feedsConfig?.data?.feed_url || '')
    }
    setValue('feed_enabled', feedsConfig?.data?.feed_enabled !== false)
  }, [feedsConfig])

  const onSubmit = async (values) => {
    const result = await dispatch(createFeedsAccount({ channel: channelId, data: values }))
    if (result.meta.requestStatus === 'fulfilled') {
      await retrieveAccounts()
      setUpdated(true)
      setTimeout(() => dispatch(closeConnectionModal()), '1000')
    } else if (result.meta.requestStatus === 'rejected') {
      setError(true)
    }
    dispatch(fetchFeedsAccount(channelId))
  }

  const urlRegex = /^(http|https):\/\/[^ "]+$/
  const inSettingsMode = mode === 'settings'

  return (
    <div className={inSettingsMode ? "" : "tw-p-6"}>
      {
        !inSettingsMode && (
          <h2 className="tw-text-xl tw-mb-6">
            {`Configure Your ${channelName} Feed`}
          </h2>
        )
      }
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="tw-grid tw-grid-cols-1 sm:tw-grid-cols-2 lg:tw-grid-cols-2 tw-gap-4 tw-mb-6">
          {
            requireUrl && (
              <div className="tw-col-span-2">
                <MyInput
                  register={register}
                  rules={{ required: 'Feed URL is required', pattern: { value: urlRegex, message: 'Please enter a valid URL' }}}
                  name={`feed_url`}
                  label="Feed URL"
                  placeholder={`Your feed URL`}
                  errors={errors}
                  disabled={false}
                />
              </div>
            )
          }
          <div className="tw-col-span-2">
            <MyInput
              register={register}
              name={`feed_enabled`}
              label="Feed Enabled"
              errors={errors}
              disabled={false}
              type="checkbox"
            />
          </div>
        </div>
        <div className="tw-flex tw-items-center tw-justify-end tw-space-x-2">
          {updated && <span className="fa fa-check" style={{color: '#5cb85c'}}/>}
          <Button type="submit" disabled={isSubmitting}>
            {`Save ${channelName} Connection`}
          </Button>
          <Button variant="secondary" onClick={closeModal}>
            Cancel
          </Button>
        </div>
      </form>
    </div>
  )
}
