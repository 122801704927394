import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import { IntercomAPI } from 'react-intercom'
import { Button, Spinner } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import MyInput from '../../../../features/forms/MyInput'
import { createSecret, updateSecret } from '../../../../features/aws/awsSecretsManagerSlice'
import { FiEye, FiEyeOff } from 'react-icons/fi'
import { closeConnectionModal, postSlackMessage } from '../../integrationsSlice'
import { toast } from 'react-toastify'
import { selectRootUser } from '../../../../rootSlice'
import { getSecretId } from '../generic/helpers'
import MyCheckbox from '../../../../features/forms/MyCheckbox'
import MyLabel from '../../../../features/forms/MyLabel'
import {
  createOpenAIAccount,
  fetchOpenAIAccount,
  selectOpenAIAccount,
  selectOpenAIAccountStatus,
  updateOpenAIAccount,
} from '../../../../features/openai/openaiSlice'
import { SLACK_DATA_STATUS_CHANNEL_ID } from '../../../../modules/constants'

export default function OpenAIConnectionModalContent({ ownerId, channel, retrieveAccounts }) {
  const dispatch = useDispatch()
  const closeModal = () => dispatch(closeConnectionModal())
  const user = useSelector(selectRootUser)
  const { id: channelId, channel: channelName, ppKeys } = channel || {}
  const secretId = getSecretId(ownerId, channelId)

  /**
   *
   * Fetch openai account
   *
   */
  const currentOpenAIAccount = useSelector(selectOpenAIAccount)
  const currentOpenAIAccountStatus = useSelector(selectOpenAIAccountStatus)
  useEffect(() => {
    if (currentOpenAIAccountStatus === 'idle') {
      dispatch(fetchOpenAIAccount({ key_id: secretId }))
    }
  }, [currentOpenAIAccountStatus])

  /**
   *
   * Toggle form type
   *
   */
  const [formType, setFormType] = useState('password')

  /**
   *
   * Handle form
   *
   */
  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {
      privateKey: '',
      overview: true,
      products: true,
      marketing: true,
      geographics: true,
      customers: true,
    },
  })

  /**
   *
   * Set default form values
   *
   */

  const [privateKeyAvail, setPrivateKeyAvail] = useState(false)

  useEffect(() => {
    if (!ppKeys || _.isEmpty(ppKeys)) return

    const keyObj = ppKeys.find(({ keyId }) => keyId === secretId)
    if (keyObj) {
      setValue('privateKey', keyObj.privateKey)
      setPrivateKeyAvail(true)
    }
  }, [ppKeys])

  /**
   *
   * Handle form submit
   *
   */
  const onSubmit = async (values) => {
    const { privateKey } = values
    const permissions = _.chain(values)
      .pick(['overview', 'products', 'marketing', 'geographics', 'customers'])
      .toPairs()
      .filter(([k, v]) => v === true)
      .fromPairs()
      .value()

    // Handle permissions
    try {
      if (currentOpenAIAccount?.id) {
        // If openai account already exists, update
        const updatePayload = {
          key_id: secretId,
          permissions,
        }

        await dispatch(updateOpenAIAccount(updatePayload))
      } else {
        // Otherwise, create
        const createPayload = {
          app_email: user.email,
          key_id: secretId,
          permissions,
        }

        await dispatch(createOpenAIAccount(createPayload))
      }
    } catch (err) {
      const errMessage = privateKey
        ? `Error while updating integration keys`
        : `Error while creating integration keys`
      console.error(errMessage, err)
      toast.error(errMessage)
    }

    // Handle key
    try {
      if (currentOpenAIAccount?.id) {
        // If secret already exists, update
        const updatePayload = {
          secretId,
          secretValue: privateKey,
        }

        await dispatch(updateSecret(updatePayload))
      } else {
        // Otherwise, create
        const createPayload = {
          secretId,
          secretValue: privateKey,
          description: `Private key for ${channelId}`,
          tags: [
            { Key: 'ownerId', Value: ownerId },
            { Key: 'channelId', Value: channelId },
          ],
        }

        await dispatch(createSecret(createPayload))
      }
      await retrieveAccounts()
    } catch (err) {
      const errMessage = privateKey
        ? `Error while updating integration keys`
        : `Error while creating integration keys`
      console.error(errMessage, err)
      toast.error(errMessage)
    }

    // Send message to a slack channel for notification
    dispatch(
      postSlackMessage({
        channel: SLACK_DATA_STATUS_CHANNEL_ID,
        text: `${user.email} saved api keys for ${channelName} integration`,
      })
    )

    // Track it via intercom
    IntercomAPI('trackEvent', 'clicked-integration-connect-button', {
      email: user.email,
      channel: channelName,
    })

    const successMessage = privateKey
      ? `Successfully updated ${channelName} keys`
      : `Successfully created ${channelName} keys`
    toast.success(successMessage)
    closeModal()
  }

  return (
    <div className="tw-p-6">
      <h2 className="tw-text-xl tw-mb-2">Connect Your {channelName} Account</h2>
      <div className="tw-text-slate-500 tw-mb-4">
        Please enter your {channelName} API key below to connect your account.
      </div>

      {!ppKeys ? (
        <Spinner animation="border" variant="primary" />
      ) : (
        <form className="tw-w-full" onSubmit={handleSubmit(onSubmit)}>
          <div className="tw-w-full tw-flex tw-items-center tw-space-x-2 tw-mb-3">
            <MyInput
              register={register}
              name="privateKey"
              label="Private key"
              type={formType}
              rules={{ required: 'Private key is required' }}
              errors={errors}
            />

            {!privateKeyAvail ? formType === 'password' ? (
              <div
                className="tw-shrink-0 tw-mt-6 tw-cursor-pointer tw-text-slate-600 hover:tw-text-slate-900 tw-text-lg"
                onClick={() => setFormType('text')}
              >
                <FiEye />
              </div>
            ) : (
              <div
                className="tw-shrink-0 tw-mt-6 tw-cursor-pointer tw-text-slate-600 hover:tw-text-slate-900 tw-text-lg"
                onClick={() => setFormType('password')}
              >
                <FiEyeOff />
              </div>
            ) : null}
          </div>

          <div className="tw-block tw-mb-1 tw-text-sm tw-font-medium tw-text-gray-500">Data access permissions</div>
          <div className="tw-w-full tw-flex tw-flex-wrap tw-mb-6">
            <div className="tw-mr-2 tw-mb-2">
              <MyCheckbox register={register} name="overview" label="Overview" disabled={true} />
            </div>
            <div className="tw-mr-2 tw-mb-2">
              <MyCheckbox register={register} name="products" label="Products" />
            </div>
            <div className="tw-mr-2 tw-mb-2">
              <MyCheckbox register={register} name="marketing" label="Marketing" />
            </div>
            <div className="tw-mr-2 tw-mb-2">
              <MyCheckbox register={register} name="geographics" label="Geographics" />
            </div>
            <div className="tw-mr-2 tw-mb-2">
              <MyCheckbox register={register} name="customers" label="Customers" />
            </div>
          </div>

          <div className="tw-flex tw-items-center tw-justify-end tw-space-x-2">
            <Button variant="secondary" onClick={closeModal}>
              Cancel
            </Button>
            <Button type="submit" disabled={isSubmitting}>
              Save {channelName} Connection
            </Button>
          </div>
        </form>
      )}
    </div>
  )
}
