import React, { useEffect, useState } from 'react'
import { withRouter, useHistory } from 'react-router-dom'
import moment from 'moment'
import { useFieldArray, useForm, useWatch } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { z } from 'zod'
import { Filter, LineChart, LoaderCircle } from 'lucide-react'
import queryString from 'query-string'
import store from '../../app/store'
import { Form } from '@/features/shadcn/ui/form'
import { zodResolver } from '@hookform/resolvers/zod'
import { Button } from '@/features/shadcn/ui/button'
import Combobox from '@/features/forms/Combobox'
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/features/shadcn/ui/tabs'
import {
  fetchTrafficReport,
  fetchUniqueFieldValues,
  resetFetchGoogleAdsMetricsSummaryStatus,
  resetFetchFbAdsMetricsSummaryStatus,
  resetFetchKlaviyoAdsMetricsSummaryStatus,
  resetFetchTrafficMetricsStatus,
  resetFetchTrafficReportStatus,
  selectCurrentTab,
  selectMetricTableColumns,
  selectMetricTableRows,
  selectTabPagination,
  selectTrafficReport,
  selectTrafficReportStatus,
  setCurrentTab,
  setTabTotalPages,
} from './trafficReportSlice'
import _ from 'lodash'
import { DEFAULT_TIME_PERIOD } from './constants'
import OverviewTab from './components/OverviewTab'
import MetricsTab from './components/MetricsTab'
import DataTableTab from './components/DataTableTab'
import { getFiltersFromQs } from './utils'
import { FiPlus, FiX } from 'react-icons/fi'
import Multiselect from '@/features/forms/Multiselect'
import { selectRootDomains, selectRootUser, selectRootUserDefaultCurrency } from '@/rootSlice'
import { IoIosSave } from 'react-icons/io'
import jsonToCsvExport from 'json-to-csv-export'
import { DEFAULT_APP_NAME } from 'modules/constants'
import { changeApplication } from 'actions/rootActions'
import { setRedirectedAction, setAddOnUpgradeDismissed } from 'actions/planActions'
import { addOnOptions } from '../Plan/planConfig'
import { Button as ButtonLegacy, Modal } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'
import AdsTab from './components/AdsTab'

/**
 *
 * Global vars
 *
 */
export const timePeriodOptions = [
  { label: 'Last 5 minutes', value: '5-minutes' },
  { label: 'Last 10 minutes', value: '10-minutes' },
  { label: 'Last 30 minutes', value: '30-minutes' },
  { label: 'Last 60 minutes', value: '60-minutes' },
  { label: 'Last 24 hours', value: '24-hours' },
  { label: 'Yesterday', value: 'yesterday' },
  { label: 'Today', value: 'today' },
  { label: 'Last 7 days', value: '7-days' },
  { label: 'Last month', value: 'last_month' },
  { label: 'Last 30 days', value: '30-days' },
  { label: 'Last 90 days', value: '90-days' },
]

// IMPORTANT: `value` should be the name of the traffic db field!
export const propertyOptions = [
  { label: 'None', value: '' },
  { label: 'Cart Status', value: 'checkout_stage' },
  { label: 'Country', value: 'country_code' },
  { label: 'Domain', value: 'domain' },
  { label: 'UTM Campaign', value: 'utm_campaign' },
  { label: 'UTM Medium', value: 'utm_medium' },
  { label: 'UTM Source', value: 'utm_source' },
  { label: 'UTM Term', value: 'utm_term' },
  // { label: 'UTM Content', value: 'utm_content' },
  { label: 'Traffic Type', value: 'traffic_type' },
  { label: 'Landing Page', value: 'entrypoint' },
  // { label: 'Product', value: 'product' },
]

const cartStatusOptions = [
  { label: 'On Site', value: 'on_site' },
  { label: 'Cart Added', value: 'cart_added' },
  { label: 'Checking Out', value: 'checking_out' },
  { label: 'Order Complete', value: 'order_complete' },
]

const trafficTypeOptions = [
  { label: 'UTM', value: 'utm' },
  { label: 'Organic', value: 'organic' },
  { label: 'Referral', value: 'referral' },
  { label: 'Typein', value: 'typein' },
]

function Traffic({ location, history }) {
  const dispatch = useDispatch()
  const timezone = useSelector(selectRootUser)?.default_timezone
  const currency = useSelector(selectRootUserDefaultCurrency)
  const domains = useSelector(selectRootDomains)
  const addOnEnabled = useSelector(state => (state.plan?.addOns || []).find(a => a.plans[0].product === 'Traffic'))
  const addOnUpgradeDismissed = useSelector(state => !!state.plan.addOnUpgradeDismissed.Traffic)
  const isAdmin = useSelector(state => state.root.admin)
  const qs = queryString.parse(location.search)

  /**
   *
   * Time period form
   *
   */
  const FormSchema = z.object({
    timePeriod: z.string({
      required_error: 'Please select a time period.',
    }),
    filters: z.array(z.any()).optional(),
  })

  const form = useForm({
    resolver: zodResolver(FormSchema),
  })

  const [prevTimePeriod, setPrevTimePeriod] = useState('')
  const timePeriodWatch = form.watch('timePeriod')
  const timePeriodWatchMatchesQs = _.isEqual(timePeriodWatch, qs.timePeriod)
  // GOTCHA: Regular watch doesn't trigger useEffect on change for field arrays!
  const filtersFromQs = getFiltersFromQs(qs)
  const filtersWatch = useWatch({ control: form.control, name: 'filters' })
  const filtersWatchMatchesQs = _.isEqual(filtersWatch, filtersFromQs)

  const { fields, append, prepend, remove, swap, move, insert, update } = useFieldArray({
    control: form.control,
    name: 'filters',
  })

  /**
   * Set Tab View from qs
   */
  useEffect(() => {
    const view = qs.view
    if (view) {
      dispatch(setCurrentTab(view))
    }
  }, [])

  /**
   *
   * Set default values on load based on the url state
   *
   */
  useEffect(() => {
    form.reset({
      timePeriod: qs.timePeriod || DEFAULT_TIME_PERIOD,
      filters: filtersFromQs,
    })
  }, [])

  /**
   *
   * Fetch unique field values and turn them into select field options
   *
   */
  const getUniqueFieldValueOptions = async (fieldName) => {
    const timePeriod = timePeriodWatch

    // Handle exception fields
    if (fieldName === 'checkout_stage') {
      return cartStatusOptions
    }

    if (fieldName === 'domain') {
      return domains.map((domain) => ({
        label: domain,
        value: domain,
      }))
    }

    // If parameters didn't change (i.e. timePeriod and fieldName), return the cached value from state
    const stateKey = `${fieldName}--${timePeriod}`
    const uniqueFieldValuesStatus = store.getState().trafficReport.uniqueFieldValues[stateKey]?.status
    if (uniqueFieldValuesStatus === 'succeeded') {
      const uniqueFieldValuesData = store.getState().trafficReport.uniqueFieldValues[stateKey]?.data
      return uniqueFieldValuesData
        .filter((dbResItem) => !_.isNil(dbResItem?.[fieldName]))
        .map((dbResItem) => ({
          label: dbResItem?.[fieldName],
          value: dbResItem?.[fieldName],
        }))
    }

    // For the rest of the fields, grab it from the db
    const uniqueFieldValuesRes = await dispatch(fetchUniqueFieldValues({ fieldName, timePeriod, timezone })).unwrap()
    const _uniqueFieldValuesOptions = uniqueFieldValuesRes
      .filter((dbResItem) => !_.isNil(dbResItem?.[fieldName]))
      .map((dbResItem) => ({
        label: dbResItem?.[fieldName],
        value: dbResItem?.[fieldName],
      }))
    return _uniqueFieldValuesOptions
  }

  /**
   *
   * Set the filter value options and filter value based on the filter selected
   *
   */
  const [prevFilterFields, setPrevFilterFields] = useState([])
  const [filterValueOptionsLoading, setFilterValueOptionsLoading] = useState({})
  const [filterValueOptions, setFilterValueOptions] = useState([])
  useEffect(() => {
    // NOTE: filter values options are dependent on the filter fields and timePeriod
    if (!filtersWatch || _.isEmpty(filtersWatch) || !timePeriodWatch || _.isEmpty(timePeriodWatch)) return

    // Also return if filter fields and timePeriod didn't change
    const filterFields = filtersWatch.map((f) => f.field)
    const filterFieldsChanged = !_.isEqual(filterFields, prevFilterFields)
    const timePeriodChanged = !_.isEqual(timePeriodWatch, prevTimePeriod)
    if (!filterFieldsChanged && !timePeriodChanged) return

    const newFilterField = _.difference(filterFields, prevFilterFields)?.[0]
    const newFilterFieldIndex = filterFields.indexOf(newFilterField)

    const fn = async () => {
      setFilterValueOptionsLoading({ ...filterValueOptionsLoading, [newFilterFieldIndex]: true })
      const newFilterValueOptions = await Promise.all(
        filtersWatch.map((f, i) => {
          const filterField = f?.field
          if (!filterField || _.isEmpty(filterField)) return null
          return getUniqueFieldValueOptions(filterField) || []
        })
      )

      if (!_.isEmpty(newFilterValueOptions)) {
        setFilterValueOptions(newFilterValueOptions.filter((f) => f !== null))
      }
      setPrevFilterFields(filtersWatch.map((f) => f.field))
      setFilterValueOptionsLoading({ ...filterValueOptionsLoading, [newFilterFieldIndex]: false })
    }
    fn()
  }, [filtersWatch, timePeriodWatch])

  /**
   *
   * Handle submit
   *
   */
  const onSubmit = (values) => {
    const _timePeriod = values.timePeriod
    const _filters = values.filters.map((f) => ({ ...f, op: 'eq' }))

    // Set the qs
    const qsStr = queryString.stringify({
      ...qs,
      timePeriod: _timePeriod,
      ...(_filters ? { filters: JSON.stringify(_filters) } : {}),
    })
    history.push({ pathname: history.pathname, search: `?${qsStr}` })

    const currentView = qs.view

    // Reset the fetch status for the traffic report and metrics
    dispatch(resetFetchTrafficReportStatus())
    dispatch(resetFetchTrafficMetricsStatus())

    // Reset the ads tab data
    dispatch(resetFetchGoogleAdsMetricsSummaryStatus())
    dispatch(resetFetchFbAdsMetricsSummaryStatus())
    dispatch(resetFetchKlaviyoAdsMetricsSummaryStatus())
  }

  /**
   *
   * Fetch the traffic cart reports ON LOAD
   *
   */
  const trafficReport = useSelector(selectTrafficReport)
  const trafficReportStatus = useSelector(selectTrafficReportStatus)
  useEffect(() => {
    const fn = async () => {
      const timePeriodChanged = !_.isEqual(timePeriodWatch, prevTimePeriod)
      if (!timePeriodChanged) return

      if (trafficReportStatus === 'idle') {
        const _timePeriod = qs.timePeriod || DEFAULT_TIME_PERIOD
        const _trafficReport = await dispatch(
          fetchTrafficReport({
            timePeriod: _timePeriod,
            filters: filtersFromQs.map((f) => ({ ...f, op: 'eq' })),
          })
        )

        // NOTE: Separately save total pages data on load since it's only called once when
        // page === 1 (to prevent re-running the query)
        const _pages = _trafficReport?.payload?.pages || {}
        dispatch(setTabTotalPages(_pages))
      }
    }
    fn()
  }, [trafficReportStatus])
  // console.log('trafficReport', trafficReport)

  /**
   *
   * Handle tabs
   *
   */
  const [exportData, setExportData] = useState([])
  const dataTableTimeFormatter = (date) => {
    return date ? moment(date).format('YYYY-MM-DD (h:mma)') : ''
  }
  const productsFormatter = (products) => {
    return products?.map((product) => `●  ${product.name}`).join('\n')
  }

  const tabs = [
    {
      name: 'overview',
      title: 'Overview',
      content: (
        <OverviewTab
          location={location}
          history={history}
          prevTimePeriod={prevTimePeriod}
          timePeriodWatch={timePeriodWatch}
          filtersWatch={filtersWatch}
        />
      ),
    },
    {
      name: 'ads',
      title: 'Ads',
      content: <AdsTab prevTimePeriod={prevTimePeriod} timePeriodWatch={timePeriodWatch} filtersWatch={filtersWatch} />,
    },
    {
      name: 'metrics',
      title: 'Metrics',
      content: (
        <MetricsTab
          // location={location}
          // history={history}
          // prevTimePeriod={prevTimePeriod}
          // timePeriodWatch={timePeriodWatch}
          setExportData={setExportData}
          trafficReport={trafficReport}
        />
      ),
    },
    {
      name: 'orders',
      title: 'Orders',
      content: (
        <DataTableTab
          tabName="orders"
          columns={[
            { name: 'order_id', title: 'Order ID' },
            { name: 'identifier', title: 'Cart ID' },
            {
              name: 'last_date',
              title: 'Last Landed On',
              formatter: dataTableTimeFormatter,
            },
            {
              name: 'lineItems',
              title: 'Products',
              formatter: productsFormatter,
            },
            { name: 'pageviews', title: 'Pageviews' },
            { name: 'type', title: 'Type' },
            { name: 'utm_campaign', title: 'UTM Campaign' },
            { name: 'utm_source', title: 'UTM Source' },
            { name: 'utm_medium', title: 'UTM Medium' },
            { name: 'country_code', title: 'Country Code' },
          ]}
          trafficReport={trafficReport}
          setExportData={setExportData}
        />
      ),
    },
    {
      name: 'checkouts',
      title: 'Checkouts',
      content: (
        <DataTableTab
          tabName="checkouts"
          columns={[
            { name: 'identifier', title: 'Cart ID' },
            {
              name: 'last_date',
              title: 'Last Landed On',
              formatter: dataTableTimeFormatter,
            },
            {
              name: 'lineItems',
              title: 'Products',
              formatter: productsFormatter,
            },
            { name: 'pageviews', title: 'Pageviews' },
            { name: 'type', title: 'Type' },
            { name: 'utm_campaign', title: 'UTM Campaign' },
            { name: 'utm_source', title: 'UTM Source' },
            { name: 'utm_medium', title: 'UTM Medium' },
            { name: 'country_code', title: 'Country Code' },
          ]}
          trafficReport={trafficReport}
          setExportData={setExportData}
        />
      ),
    },
    {
      name: 'carts',
      title: 'Carts',
      content: (
        <DataTableTab
          tabName="carts"
          columns={[
            { name: 'identifier', title: 'Cart ID' },
            {
              name: 'last_date',
              title: 'Last Landed On',
              formatter: dataTableTimeFormatter,
            },
            {
              name: 'lineItems',
              title: 'Products',
              formatter: productsFormatter,
            },
            { name: 'pageviews', title: 'Pageviews' },
            { name: 'type', title: 'Type' },
            { name: 'utm_campaign', title: 'UTM Campaign' },
            { name: 'utm_source', title: 'UTM Source' },
            { name: 'utm_medium', title: 'UTM Medium' },
            { name: 'country_code', title: 'Country Code' },
          ]}
          trafficReport={trafficReport}
          setExportData={setExportData}
        />
      ),
    },
    {
      name: 'visitors',
      title: 'Visitors',
      content: (
        <DataTableTab
          tabName="visitors"
          columns={[
            { name: 'order_id', title: 'Order ID' },
            { name: 'identifier', title: 'Cart ID' },
            {
              name: 'last_date',
              title: 'Last Landed On',
              formatter: dataTableTimeFormatter,
            },
            { name: 'pageviews', title: 'Pageviews' },
            { name: 'type', title: 'Type' },
            { name: 'utm_campaign', title: 'UTM Campaign' },
            { name: 'utm_source', title: 'UTM Source' },
            { name: 'utm_medium', title: 'UTM Medium' },
            { name: 'country_code', title: 'Country Code' },
          ]}
          trafficReport={trafficReport}
          setExportData={setExportData}
        />
      ),
    },
    {
      name: 'sessions',
      title: 'Sessions',
      content: (
        <DataTableTab
          tabName="sessions"
          columns={[
            { name: 'order_id', title: 'Order ID' },
            { name: 'identifier', title: 'Cart ID' },
            {
              name: 'last_date',
              title: 'Last Landed On',
              formatter: dataTableTimeFormatter,
            },
            { name: 'pageviews', title: 'Pageviews' },
            { name: 'type', title: 'Type' },
            { name: 'utm_campaign', title: 'UTM Campaign' },
            { name: 'utm_source', title: 'UTM Source' },
            { name: 'utm_medium', title: 'UTM Medium' },
            { name: 'country_code', title: 'Country Code' },
          ]}
          trafficReport={trafficReport}
          setExportData={setExportData}
        />
      ),
    },
  ]

  const currentTab = useSelector(selectCurrentTab)
  const tabPagination = useSelector((state) => selectTabPagination(state, currentTab))
  // console.log('currentTab', currentTab)
  // console.log('tabPagination', tabPagination)

  /**
   *
   * Handle form for mobile view
   *
   */
  // const mobileTabForm = useForm({
  //   defaultValues: {
  //     mobileTab: 'overview',
  //   },
  // })
  const MobileTabFormSchema = z.object({
    mobileTab: z.string({
      required_error: 'Please select a tab.',
    }),
  })

  const mobileTabForm = useForm({
    resolver: zodResolver(MobileTabFormSchema),
  })
  const mobileTabWatch = mobileTabForm?.watch('mobileTab')

  // Set default for mobile tab form
  useEffect(() => {
    const view = qs.view
    if (view) {
      mobileTabForm.reset({
        mobileTab: view,
      })
    } else {
      mobileTabForm.reset({
        mobileTab: 'overview',
      })
    }
  }, [])

  /**
   *
   * Handle export
   *
   */
  const tableColumns = useSelector(selectMetricTableColumns)
  const tableRows = useSelector(selectMetricTableRows)

  const handleExport = () => {
    const today = moment().format('YYYY_MM_DD')

    if (currentTab === 'overview') {
      jsonToCsvExport({
        filename: `traffic_${currentTab}_${today}`,
        data: tableRows.map((row) => _.omit(row, ['color'])),
        headers: tableColumns.map((col) => col.accessorKey),
      })
    } else if (currentTab === 'metrics') {
      exportData.forEach((section) => {
        jsonToCsvExport({
          filename: `traffic_${currentTab}_${section.sectionName}_${today}`,
          data: section.data,
          headers: section.header,
        })
      })
    } else if (currentTab === 'orders') {
      jsonToCsvExport({
        filename: `traffic_${currentTab}_${today}`,
        data: exportData[0].data,
        headers: exportData[0].headers,
      })
    } else if (currentTab === 'checkouts') {
      jsonToCsvExport({
        filename: `traffic_${currentTab}_${today}`,
        data: exportData[0].data,
        headers: exportData[0].headers,
      })
    } else if (currentTab === 'carts') {
      jsonToCsvExport({
        filename: `traffic_${currentTab}_${today}`,
        data: exportData[0].data,
        headers: exportData[0].headers,
      })
    } else if (currentTab === 'visitors') {
      jsonToCsvExport({
        filename: `traffic_${currentTab}_${today}`,
        data: exportData[0].data,
        headers: exportData[0].headers,
      })
    } else if (currentTab === 'sessions') {
      jsonToCsvExport({
        filename: `traffic_${currentTab}_${today}`,
        data: exportData[0].data,
        headers: exportData[0].headers,
      })
    }
  }

  if (!(addOnEnabled || addOnUpgradeDismissed || isAdmin)) {
    return <UpgradeModal />
  }

  return (
    <>
      <div className="min-w-0">
        <div className="tw-flex tw-items-center tw-justify-between tw-mb-4">
          <h2>Traffic</h2>

          <div>
            <Button className="!tw-px-3 !tw-h-9 tw-text-[1rem]" onClick={handleExport}>
              <IoIosSave />
              <div className="tw-ml-1.5 tw-text-sm">Export</div>
            </Button>
          </div>
        </div>

        <div className="tw-grid tw-gap-4 tw-grid-cols-1 md:tw-grid-cols-12">
          <div className="tw-col-span-1 md:tw-col-span-3 2xl:tw-col-span-2">
            <div className="tw-bg-white tw-rounded-md tw-px-4 tw-py-4 tw-shadow">
              <Form {...form}>
                <form onSubmit={form.handleSubmit(onSubmit)} className="tw-space-y-6">
                  <Combobox
                    form={form}
                    name="timePeriod"
                    label="Time Period"
                    labelClassName="tw-text-sm !tw-font-bold"
                    placeholder="Select a time period"
                    options={timePeriodOptions}
                    onSelectOverride={(selected) => {
                      // Set the prev value for caching
                      setPrevTimePeriod(form.getValues('timePeriod'))

                      // Set the selected form value
                      // NOTE: this updates the timePeriodWatch, which triggers new data fetch for metrics (for the traffic report, manually re-fetch below)
                      form.setValue('timePeriod', selected)

                      // Refetch the traffic report
                      dispatch(
                        fetchTrafficReport({
                          timePeriod: selected,
                          pagination: tabPagination,
                          tabName: currentTab,
                          filters: filtersFromQs.map((f) => ({ ...f, op: 'eq' })),
                        })
                      )

                      // // Reset the filter fields & values
                      // form.setValue(`filters`, [{ field: '', value: '' }])
                    }}
                  />

                  <div>
                    <div className="tw-flex tw-justify-between mb-1">
                      <div className="tw-text-sm tw-font-bold">Filter</div>
                      <button
                        type="button"
                        onClick={() => {
                          append({ field: '', value: '' })
                        }}
                      >
                        <FiPlus />
                      </button>
                    </div>

                    {fields.map((field, i) => {
                      const filteredPropertyOptions =
                        filtersWatch && !_.isEmpty(filtersWatch)
                          ? propertyOptions.filter(({ label, value }) => {
                              const usedFilters = filtersWatch
                                .slice(0, i)
                                .map((f) => f.field)
                                .filter((f) => !_.isEmpty(f))
                              return !usedFilters.includes(value)
                            })
                          : propertyOptions

                      return (
                        <div key={field.id} className="tw-rounded-md tw-bg-slate-50 tw-p-2 tw-mb-2">
                          <div className="tw-flex tw-items-center tw-justify-between">
                            <Combobox
                              key={field.id}
                              form={form}
                              name={`filters.${i}.field`}
                              buttonClassName="!tw-mt-0"
                              placeholder="Select filter"
                              options={filteredPropertyOptions}
                              onSelectOverride={(selected) => {
                                // Set the value to empty when the field is changed
                                form.setValue(`filters.${i}.field`, selected)
                                form.setValue(`filters.${i}.value`, '')
                              }}
                            />

                            <div
                              className="tw-shrink-0 tw-pl-1.5 tw-cursor-pointer tw-text-slate-400 hover:tw-text-slate-700"
                              onClick={() => {
                                if (i === 0) {
                                  update(i, { field: '', value: '' })
                                } else {
                                  remove(i)
                                }
                              }}
                            >
                              <FiX />
                            </div>
                          </div>

                          <div>
                            {filterValueOptionsLoading[i] && (
                              <div className="tw-text-sm tw-text-slate-400 tw-pt-2 tw-flex tw-items-center tw-space-x-1">
                                <LoaderCircle size={14} className="tw-animate-spin" />
                                <div>Loading...</div>
                              </div>
                            )}
                            {filtersWatch?.[i]?.field !== '' && !filterValueOptionsLoading[i] && (
                              <div className="tw-flex tw-items-center tw-space-x-1.5">
                                <div className="tw-text-sm tw-mt-2">Is</div>

                                <Multiselect
                                  form={form}
                                  name={`filters.${i}.value`}
                                  labelClassName="!tw-font-bold"
                                  placeholder="Select value"
                                  options={filterValueOptions[i]}
                                />
                              </div>
                            )}
                          </div>
                        </div>
                      )
                    })}
                  </div>

                  <Button
                    type="submit"
                    disabled={timePeriodWatchMatchesQs && filtersWatchMatchesQs}
                    className="tw-mt-2 !tw-h-9"
                  >
                    Apply
                  </Button>
                </form>
              </Form>
            </div>
          </div>

          <div className="tw-col-span-1 md:tw-col-span-9 2xl:tw-col-span-10">
            <div className="tw-hidden md:tw-block">
              <Tabs
                defaultValue="overview"
                value={currentTab}
                onValueChange={(value) => {
                  const searchParams = new URLSearchParams(window.location.search)
                  searchParams.set('view', value)
                  history.push({ search: searchParams.toString() })
                  dispatch(setCurrentTab(value))
                }}
                className="tw-w-full"
              >
                <TabsList className="tw-w-full" variant="folder">
                  {tabs?.map(({ name, title }, i) => {
                    return (
                      <TabsTrigger key={i} value={name} variant="folder">
                        <div>{title}</div>
                      </TabsTrigger>
                    )
                  })}
                </TabsList>

                {tabs?.map(({ name, content }, i) => {
                  return (
                    <TabsContent
                      key={i}
                      value={name}
                      className={`-tw-mt-[1px] tw-border-l tw-border-r tw-border-b tw-border-t tw-rounded-bl-md tw-rounded-br-md tw-rounded-tr-md ${
                        i !== 0 ? 'tw-rounded-tl-md' : ''
                      } tw-border-slate-200 tw-bg-white`}
                    >
                      {trafficReportStatus === 'loading' && (name !== 'overview' || name !== 'ads') ? (
                        <LoadingState />
                      ) : (
                        <div className="tw-p-4">{content}</div>
                      )}
                    </TabsContent>
                  )
                })}
              </Tabs>
            </div>

            <div className="tw-block md:tw-hidden">
              <Form {...mobileTabForm}>
                <Combobox
                  form={mobileTabForm}
                  name="mobileTab"
                  label="Tab"
                  labelClassName="tw-text-sm !tw-font-bold"
                  placeholder="Select a tab"
                  options={tabs?.map(({ name, title, content }) => ({
                    label: title,
                    value: name,
                  }))}
                  onSelectOverride={(selected) => {
                    const searchParams = new URLSearchParams(window.location.search)
                    searchParams.set('view', selected)
                    history.push({ search: searchParams.toString() })
                    mobileTabForm.setValue('mobileTab', selected)
                    dispatch(setCurrentTab(selected))
                  }}
                />
              </Form>

              {mobileTabWatch && (
                <div className="tw-bg-white tw-rounded-md tw-px-4 tw-py-4 tw-shadow tw-mt-4">
                  {tabs?.find(({ name }) => name === mobileTabWatch)?.content}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default withRouter(Traffic)

export const EmptyState = () => {
  return (
    <div className="tw-bg-white tw-rounded-md tw-p-4 tw-shadow">
      <div className="tw-h-[368px] tw-bg-slate-100 tw-flex tw-items-center tw-justify-center tw-text-slate-500">
        No data to show
      </div>
    </div>
  )
}

export const LoadingState = () => {
  return (
    <div className="tw-bg-white tw-rounded-md tw-p-4 tw-shadow">
      <div className="tw-h-[368px] tw-bg-slate-100 tw-flex tw-items-center tw-justify-center tw-text-slate-500">
        <div className="tw-flex tw-items-center tw-justify-center tw-space-x-1 tw-min-h-80">
          <div className="tw-animate-spin">
            <LoaderCircle />
          </div>
          <div>Loading data...</div>
        </div>
      </div>
    </div>
  )
}

const UpgradeModal = () => {
  const dispatch = useDispatch()
  const addOnPrices = useSelector(state => state.plan.addOnPrices)
  const addOnOptionConfigs = addOnOptions(addOnPrices)
  const trafficAddOnOption = addOnOptionConfigs.find(a => a.product === 'Traffic')
  const mimicStatus = useSelector(state => state.mimic.mimic.status)
  return (
    <div>
      <title>{`Traffic - ${DEFAULT_APP_NAME} Analytics & Intelligence`}</title>
      <Modal show={true} backdropClassName={`backdrop-traffic plan-modal-backdrop`} className="plan-modal">
        <Modal.Header>
          <Modal.Title>Try Traffic & Advertising</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5>Start your 14 day free trial now.</h5>
          <ul style={{ listStyle: 'disc' }}>
            <li>Know what campaigns have great ROAS and improve your funnel performance.</li>
            <li> Compare traffic from all your marketing efforts and know what landing pages are performing best.</li>
            <li>Get the detail your team needs.</li>
          </ul>
        </Modal.Body>
        <Modal.Footer>
          <div className="left">
            {
              mimicStatus ? (
                <div className="inline-block mr1">
                  <ButtonLegacy
                    onClick={() => {
                      dispatch(setAddOnUpgradeDismissed({ Traffic: true }))
                  }}>
                    {'Continue'}
                  </ButtonLegacy>
                </div>
              ) : null
            }
            <div className="float-right">
              <LinkContainer to={{ pathname: '/plan', search: 'view=add-ons'  }}>
                <ButtonLegacy
                  variant="primary"
                  onClick={() => {
                    dispatch(changeApplication('traffic', 'plan'))
                    dispatch(setRedirectedAction({
                      targetApp: 'plan',
                      targetViewIndex: 1,
                      planOption: trafficAddOnOption,
                    }))
                }}>
                  Start Now
                </ButtonLegacy>
              </LinkContainer>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  )
}
