import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { FiChevronDown, FiChevronUp } from 'react-icons/fi'
import { useMeasure } from 'react-use'
import { openSettingsModal, setCurrentChannel } from '../integrationsSlice'
import ConnectButton from './ConnectButton'
import SettingsButton from './SettingsButton'
import { selectRootAccounts, selectRootAdmin, selectRootUser } from '../../../rootSlice'
import ChannelAccountTable from './ChannelAccountTable'
import { useConnectionClick } from './hooks'
import ChannelKeysTable from './ChannelKeysTable'
import FeedConfigTable from './FeedConfigTable'
import DropdownConnectButton from './DropdownConnectButton'

export default function IntegrationCard({ integration, ownerId, csvModalVisible }) {
  const dispatch = useDispatch()
  const [detailsRef, { height: detailsHeight }] = useMeasure()
  const user = useSelector(selectRootUser)
  const isAdmin = useSelector(selectRootAdmin)
  const rootAccounts = useSelector(selectRootAccounts)
  const accounts = rootAccounts.map((_account) => ({ ..._account, isOwner: user.email === _account.owner }))
  const {
    id: channelId,
    channel,
    title,
    description,
    logo,
    enabled: channelEnabled,
    backfillEnabled,
    settingsEnabled,
    connectDisabled,
    type,
    ppKeys,
    connected,
    customBanner,
    uninstallEnabled,
  } = integration

  const channelAccounts = accounts.filter((_account) => _account.channel === channel)

  /**
   *
   * Handle description height
   *
   */
  const MAX_DETAILS_HEIGHT = 80
  const [expandButtonVisible, setExpandButtonVisible] = useState(false)
  const [expanded, toggleExpand] = useState(false)
  const handleExpand = () => {
    toggleExpand(!expanded)
  }

  useEffect(() => {
    if (!detailsHeight) return

    if (detailsHeight > MAX_DETAILS_HEIGHT) {
      setExpandButtonVisible(true)
    }
  }, [detailsHeight])

  const cardHeightClassName = expanded ? 'tw-h-full' : 'tw-h-[240px]'
  const detailsHeightClassName = expanded ? 'tw-h-full' : `tw-h-[80px]` // make sure this height is the same as MAX_DETAILS_HEIGHT

  /**
   *
   * Handle connect click
   *
   */
  const onConnectClick = useConnectionClick({ user, integration })

  const onSettingsClick = () => {
    dispatch(setCurrentChannel(integration))
    dispatch(openSettingsModal())
  }

  return (
    <div className="tw-col-span-1">
      <div
        className={`${cardHeightClassName} tw-rounded-lg tw-shadow-md tw-p-4 tw-bg-white hover:tw-ring hover:tw-ring-blue-600`}
      >
        <div className="tw-flex tw-items-center tw-justify-between tw-mb-6">
          <div className="tw-w-24 tw-h-12 tw-flex tw-items-center tw-justify-start">{logo}</div>
          {connected ? (
            <div className="tw-flex tw-items-center tw-space-x-2">
              {!connectDisabled && <ConnectButton onClick={onConnectClick} uninstallEnabled={uninstallEnabled} integration={integration} />}
              {settingsEnabled && <SettingsButton onClick={onSettingsClick} />}
            </div>
          ) : (
            <ConnectButton onClick={onConnectClick} channelId={channelId} />
          )}
        </div>

        <div>
          <div className="tw-text-base tw-font-bold tw-mb-1">{connected ? `Your ${title} Accounts` : title}</div>
          <div className={`${detailsHeightClassName} tw-overflow-y-hidden tw-text-sm tw-text-slate-500`}>
            <div ref={detailsRef}>
              {connected ? (
                <div>
                  {channelEnabled ? (
                    <ChannelAccountTable
                      accounts={channelAccounts}
                      isAdmin={isAdmin}
                      backfillEnabled={backfillEnabled}
                    />
                  ) : (
                    type === 'feed' ? (
                      <FeedConfigTable integration={integration}/>
                    ) : (
                      <ChannelKeysTable ppKeys={ppKeys} />
                    )
                  )}
                </div>
              ) : (
                description
              )}
            </div>
          </div>
          {expandButtonVisible && (
            <div
              className="tw-mt-[8px] tw-h-[24px] tw-cursor-pointer tw-flex tw-justify-center tw-p-1 tw-rounded-lg tw-bg-slate-100 hover:tw-bg-slate-200 tw-transition-all"
              onClick={handleExpand}
            >
              {expanded ? (
                <div className="tw-flex tw-items-center tw-space-x-1 tw-text-slate-600">
                  <div className="tw-text-xs">Show less</div>
                  <FiChevronUp />
                </div>
              ) : (
                <div className="tw-flex tw-items-center tw-space-x-1 tw-text-slate-600">
                  <div className="tw-text-xs">Show more</div>
                  <FiChevronDown />
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
