import React from 'react'
import { connect } from 'react-redux'
import { ProgressBar, Button } from 'react-bootstrap'
import ReactTooltip from 'react-tooltip'
import { fetchBackfillStatus, switchBackfill } from 'actions/backFillActions'
import moment from 'moment-timezone'
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar'
import 'react-circular-progressbar/dist/styles.css'
import _ from 'lodash'
import { localStoragePayhelm } from 'modules/utils'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMinus, faExpand } from '@fortawesome/free-solid-svg-icons'

const BackfillNavBtn = ({ prev, index, length, switchBackfill }) => (
  <div
    className={`cursor-pointer fa fa-chevron-${prev ? 'left mr3' : 'right ml3'}`}
    onClick={() => {
      const nextIndex = (index + length + (prev ? -1 : 1)) % length
      switchBackfill(nextIndex)
    }}
  />
)

const BackfillRunning = ({ fadeIn, displayedName, currentAt, from, to, current_orders, total_orders, updated_at, timezone, percentage, percentage_orders, status_message, minimized }) => (
  <div className="flex-1">
  {
    !minimized ?
    (<>
      <div>
        <span>{`Importing data for ${displayedName} from ${from} to ${to}`}</span>
        {
          updated_at ? (
            <span className="float-right" style={{ color: 'grey' }}>{`Last Updated: ${moment.tz(updated_at, timezone).format('h:mmA, MMM D z')}`}</span>
          ) : null
        }
      </div>
      <div data-tip data-for="progress-bar-percentage">
        <ProgressBar now={percentage || 0.5} style={{ height: 10, marginTop: 5, marginBottom: 15 }}/>
        <ReactTooltip place="top" id="progress-bar-percentage">We are importing your past orders in the background. You can check back in 15 minutes to see progress.</ReactTooltip>
      </div>
    </>) : null}
    <div>
    {!minimized && (
      <>
        <span>{`Processed order(s): ${current_orders}/${total_orders} on `}</span>
        <span className={`fade-in-${fadeIn ? 'start' : 'end'}`}>{currentAt}</span>
        { status_message ? <div>{status_message}</div> : null }
      </>
    )}
    </div>
    {
      minimized ? (
      <div style={{ position: 'relative' }}>
        <ProgressBar now={percentage_orders || 0.5} style={{ height: 20 }}/>
        <span style={{ position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%, -50%)', color: 'white', textAlign: 'center', whiteSpace: 'nowrap', fontWeight: 'bold' }}>
          {percentage_orders > 0 ? `${percentage_orders}%` : 'Processing...'}
        </span>
      </div>
      ) : (<ProgressBar now={percentage_orders || 0.5} label={percentage_orders > 0 && `${percentage_orders}%`} style={{ marginTop: 0, marginBottom: 0}}/>)
    }
  </div>
)

const BackfillComplete = ({ displayedName, minimized }) => (
  <div className="flex-1">
    {minimized ? (
      <div style={{ position: 'relative' }}>
        <ProgressBar now={100} style={{ height: 20 }} />
        <span
          style={{
            position: 'absolute',
            left: '50%',
            top: '50%',
            transform: 'translate(-50%, -50%)',
            color: 'white',
            textAlign: 'center',
            whiteSpace: 'nowrap',
            fontWeight: 'bold',
          }}
        >
          Completed
        </span>
      </div>
    ) : (
      <>
        <div>{`Backfill completed on ${displayedName}`}</div>
        <ProgressBar now={100} style={{ height: 2, marginTop: 5, marginBottom: 15 }} />
      </>
    )}
  </div>
)

const BackfillError = ({ displayedName, status_message }) => (
  <div className="flex-1">
    {`An error occurred while backfilling ${displayedName}. Please try again later or contact support.`}
    { status_message ? <span>{status_message}</span> : null }
  </div>
)

@connect((store) => {
  const index = store.backfill.index
  const accounts = store.root.accounts
  const backfills = store.backfill.backfill.map(b => {
    const account = accounts.find(a => a.merchant_id === b.merchant_id) || {}
    return {
      ...b,
      email: account.email,
      channel: account.channel,
    }
  })
  const backfill = backfills[index] || backfills[0]
  const length = backfills.length
  const timezone = _.get(store, ['root', 'user', 'default_timezone'], 'utc')
  return { length, backfill, index, accounts, timezone }
}, { fetchBackfillStatus, switchBackfill })

export default class BackfillBanner extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      fadeIn: false,
      minimized: localStoragePayhelm.minimize_data_import_banner === 'true' ? true : false,
      isHovered: false
    }
  }

  handleMouseEnter = () => {
    this.setState({ isHovered: true });
  };

  handleMouseLeave = () => {
    this.setState({ isHovered: false });
  };


  composeDateRange = (isPayPal, created_at, current, total, timezone, start_date, end_date) => {
    const period = isPayPal ? 'months' : 'days'
    const format = isPayPal ? 'MMM YYYY' : 'MMM D, YYYY'
    if (start_date && end_date) {
      return {
        currentAt: moment.tz(end_date, timezone).subtract(current, period).format(format),
        from: moment.tz(start_date, timezone).format(format),
        to: moment.tz(end_date, timezone).format(format),
      }
    }
    else {
      return {
        currentAt: moment.tz(created_at, timezone).subtract(current, period).format(format),
        from: moment.tz(created_at, timezone).subtract(total, period).format(format),
        to: moment.tz(created_at, timezone).format(format),
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.index >= this.props.length) {
      this.props.switchBackfill(0)
    }
    if (
      (prevProps.backfill.merchant_id === this.props.backfill.merchant_id) &&
      (prevProps.backfill.current !== this.props.backfill.current)
    ) {
      this.setState({
        fadeIn: true
      }, () => setTimeout(() => this.setState({ fadeIn: false }), 100))
    }
  }

  componentDidMount() {
    const { fetchBackfillStatus, accounts } = this.props
    delete localStoragePayhelm.backfillToken
    fetchBackfillStatus('', accounts.map(a => a.merchant_id))

    if (this.props.backfill?.created_at && this.props.backfill?.updated_at) {
      const createdAt = moment(this.props.backfill?.created_at)
      const updatedAt = moment(this.props.backfill?.updated_at)

      if (updatedAt.diff(createdAt, 'minutes') > 60) {
        this.setState({ minimized: true })
        localStoragePayhelm.minimize_data_import_banner = true
      }
    }

  }

  render() {
    const { fadeIn, minimized, isHovered } = this.state
    const { length, index, backfill, switchBackfill, timezone, style } = this.props
    const {
      status = 'running',
      current,
      total = 1,
      current_period_orders_total: total_orders = 0,
      current_period_orders_processed: current_orders,
      merchant_id = '',
      channel = '',
      email = '',
      created_at = new Date(),
      updated_at,
      start_date,
      end_date,
      status_message,
    } = backfill
    const isPayPal = channel.toLowerCase().includes('paypal')
    const displayedName = isPayPal ? `${email} (PayPal)` : merchant_id
    const percentage = parseInt(current / total * 100), percentage_orders = total_orders ? parseInt(current_orders / total_orders * 100) : 0
    const hasMultiple = length > 1, hasError = status === 'error'
    const { currentAt, from, to } = this.composeDateRange(isPayPal, created_at, current, total, timezone, start_date, end_date)
    const contentProps = { currentAt, from, to, current_orders, total_orders, updated_at, timezone, percentage, percentage_orders, displayedName, fadeIn, status_message, minimized }
    const navProps = { index, length, switchBackfill }
    let content
    if (status === 'running') {
      content =  <BackfillRunning { ...contentProps } />
    } else if(status === 'done') {
      content = <BackfillComplete { ...contentProps }/>
    } else if(status === 'error') {
      content = <BackfillError {...contentProps } />
    } else {
      content = null
    }
    if (content) {
      return (
        <div className='tw-relative' style={style}>
        <div
          id={`backfill-banner`}
          className={`${minimized ? null : 'mb2'} transition-width font-size-smaller ${!minimized && 'p3'} default-border mx-auto ${hasError ? 'alert alert-danger' : 'background-white'} ${minimized ? 'tw-cursor-pointer' : ''}`}
          style={{position: 'relative', width: minimized ? '150px' : '100%',
            opacity: minimized ? isHovered ? 1 : 0.5 : 1, padding: minimized ? '9px' : null, zIndex: minimized ? 100 : null,
            borderRadius: minimized ? '0.375rem' : null,
            backgroundColor: minimized ? '#0d6efd' : null,
            float: minimized ? 'right' : null
          }}
          onMouseEnter={this.handleMouseEnter}
          onMouseLeave={this.handleMouseLeave}
          onClick={minimized ? () => { localStoragePayhelm.minimize_data_import_banner = false; this.setState({ minimized: false }); } : null}
        >
          {
            minimized ? (
              // <div className="flex-box" onClick={() => { localStoragePayhelm.minimize_data_import_banner = false; this.setState({ minimized: false }); }}>
              //   <div style={{ width: 40, height: 40, marginRight: 4, cursor: 'pointer' }} data-tip data-for="circular-progress-bar-percentage">
              //     <CircularProgressbar background value={percentage} text={`${percentage}%`} styles={buildStyles({ textSize: '32px', backgroundColor: '#eaeff2' })} />
              //     <ReactTooltip place="bottom" id="circular-progress-bar-percentage">Importing data for {displayedName || 'null'} from {from} to {to}</ReactTooltip>
              //   </div>
              //   <div style={{ width: 40, height: 40, cursor: 'pointer' }} data-tip data-for="circular-progress-bar-percentage-orders">
              //     <CircularProgressbar background value={percentage_orders} text={`${percentage_orders}%`} styles={buildStyles({ textSize: '32px', backgroundColor: '#eaeff2' })}/>
              //     <ReactTooltip place="bottom" id="circular-progress-bar-percentage-orders">{`Processed order(s): ${current_orders}/${total_orders} on `}</ReactTooltip>
              //   </div>
              // </div>
              <div className="flex-box v-center" data-tip data-for="minimized-data-import">
                { content }
                <ReactTooltip place="left" id="minimized-data-import">{status_message ? status_message : `Processed order(s): ${current_orders}/${total_orders} on ${currentAt}`}</ReactTooltip>
              </div>
            ) : (
              <div className="flex-box v-center">
                <Button
                  className="m2"
                  style={{ position: 'absolute', top: '-15px', right: '-10px', color: '#0d6efd', backgroundColor: 'transparent', border: 'none' }}
                  onClick={() => { localStoragePayhelm.minimize_data_import_banner = true; this.setState({ minimized: true }); }}
                  data-tip data-for="minimized-data-import"
                >
                  <FontAwesomeIcon icon={faMinus} color='black' size='xl' />
                  <ReactTooltip place="left" id="minimized-data-import">Minimize</ReactTooltip>
                </Button>
                { hasMultiple ? <BackfillNavBtn prev={true} {...navProps} /> : null }
                { content }
                { hasMultiple ? <BackfillNavBtn prev={false} {...navProps} /> : null }
              </div>
            )
          }
        </div>
        </div>
      )
    }
    else {
      return null
    }
  }
}