import React from 'react'
import './index.css'

const allChannels = ['BigCommerce', 'Shopify', 'PayPal', 'Woocommerce', 'Amazon']
const researchSupportedChannels = ['BigCommerce', 'Shopify', 'Woocommerce', 'Amazon']
const FAQ = ({loginFromChannel}) => {
  const sortedAllChannels = [
    ...(loginFromChannel ? allChannels.filter(c => c.toLowerCase() === loginFromChannel.toLowerCase()) : []),
    ...allChannels.filter(c => loginFromChannel ?
       c.toLowerCase() !== loginFromChannel.toLowerCase() : true
    ),
  ]
  const allChannelString = sortedAllChannels.reduce((acc, cur, index) => [
    ...acc,
    ...(index ? (
      index === sortedAllChannels.length - 1 ? [' and ', cur] : [', ', cur]
    ) : [cur])
  ]).join('')
  const sortedResearchSupportedChannels = [
    ...(loginFromChannel ? researchSupportedChannels.filter(c => c.toLowerCase() === loginFromChannel.toLowerCase()) : []),
    ...researchSupportedChannels.filter(c => loginFromChannel ?
       c.toLowerCase() !== loginFromChannel.toLowerCase() : true
    ),
  ]
  const researchSupportedChannelString = sortedResearchSupportedChannels.reduce((acc, cur, index) => [
    ...acc,
    ...(index ? (
      index === sortedResearchSupportedChannels.length - 1 ? [' and ', cur] : [', ', cur]
    ) : [cur])
  ]).join('')
  return (
    <main className="faq">
      <div className="content-wrapper">
        <div className="container">
            <div className="content">
              <h1 className="center"><p>Frequently Asked Questions & Support</p></h1>
              <h2 className="center">Frequently Asked Questions & Support<br/><br/></h2>
              <h4><strong>How many Shopify channels can I link to Payhelm?</strong></h4>
              <p>You can link unlimited Shopify channels into your Payhelm reporting & email campaigns. Your subscription plan is related to the total amount of sales you've made which is what costs more or less to serve your account.</p>
              <br/>
              <h4><strong>{`Can I connect my ${allChannelString} channels together?`}</strong></h4>
              <p>{`Yes you can connect ${allChannelString} all into the Payhelm analytics platform. ${researchSupportedChannelString} are available in the research tab so you can understand and analyze your business.  PayPal has its own separate reporting since that is transaction data and not sales data.  PayPal also supports subscription metrics such as life time value, churn %, ARPU, Net subscriptions, etc.`}</p>
              <br/>
              <h4><strong>How can I contact support?</strong></h4>
              <p>You can email our support team at <a href="mailto:success@payhelm.com">success@payhelm.com</a> and we'll respond within 24 to 48 business hours.</p>
              <br/>
              <h4><strong>I linked my Shopify store and I do not see any data, why?</strong></h4>
              <p>Shopify data will appear in the reports through the day when new orders appear.</p>
              <br/>
              <h4><strong>I linked my BigCommerce store and I do not see any data, why?</strong></h4>
              <p>BigCommerce data will appear in the reports through the day when new orders appear.</p>
              <br/>
              <h4><strong>How long does it take to load my Shopify history?</strong></h4>
              <p>When you signup we initiate a history backfill to load 30 days of your Shopify history right away.  The progress bar will indicate how much has completed and it can often take anywhere from a few minutes to an hour depending on the size of your sales volume.</p>
              <br/>
              <h4><strong>How long does it take to load my BigCommerce history?</strong></h4>
              <p>When you signup we initiate a history backfill to load the history of your BigCommerce account.  The progress bar will indicate how much has completed and it can often take anywhere from a few minutes to an hour depending on the size of your sales volume.</p>
              <br/>
              <h4><strong>How much history does Payhelm support for my data?</strong></h4>
              <p>We support as much history as you have in your account.  Every day more history accumulates and we store that history forever.  In addition we backfill as much history as each channel allows.  In the case of PayPal we can go back until the start of your account, for Shopify we can go back up to 60 days, and for BigCommerce will go back as far as the accounts creation.</p>
              <br/>
              <h4><strong>How do I filter my data to drill into other aspects of it?</strong></h4>
              <p>There is a filter icon next to the search box on the report tab.  You can click that filter to see other options available and to drill deeper into your data to uncover other insights.</p>
            </div>
        </div>
      </div>
    </main>
  )
}

export default FAQ