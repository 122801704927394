import DataTable from './DataTable'
import React, { useEffect } from 'react'
import { getTrafficReportTableColumns } from '../utils'

export default function DataTableTab({
  tabName,
  columns = [{ name: 'identifier', title: 'ID' }],
  trafficReport,
  setExportData,
}) {
  const [tableRows, setTableRows] = React.useState([])
  const [tableColumns, setTableColumns] = React.useState([])

  useEffect(() => {
    if (!trafficReport) return

    const data = trafficReport[tabName] || []
    const _tableColumns = getTrafficReportTableColumns(columns)
    const _tableRows = data.map((row) => {
      return _.chain(row)
        .pick(columns.map((col) => col.name))
        .toPairs()
        .map(([colName, colVal]) => {
          const colFormatter = columns.find((c) => c.name === colName)?.formatter
          return [colName, colFormatter ? colFormatter(colVal) : colVal]
        })
        .fromPairs()
        .value()
    })

    setTableColumns(_tableColumns)
    setTableRows(_tableRows)

    const _exportData = [
      {
        data: _tableRows
          .map((row) => {
            return _.chain(row)
              .toPairs()
              .map(([k, v]) => {
                const col = columns.find((c) => c.name === k)
                return [col?.title, v]
              })
              .fromPairs()
              .value()
          })
          .map((row) => {
            // Strip out added characters from the Products and replace `"` with `""` for CSV export
            const strippedProductsField = _.chain(row['Products'])
              .split('\n')
              .map((_product) => _product.replace('●  ', '').replace(/"/g, '""').trim())
              .join(' | ')
              .value()

            // Finally wrap the Products field in double quotes
            const productsField = `"${strippedProductsField}"`

            return {
              ...row,
              Products: productsField,
            }
          }),
        headers: _tableColumns.map((col) => {
          const column = columns.find((c) => c.name === col.accessorKey)
          return column.title
        }),
      },
    ]
    setExportData(_exportData)
  }, [trafficReport])

  return (
    <div>
      <DataTable columns={tableColumns} data={tableRows} showDynamicPagination={true} showSelectedRowsLabel={false} />
    </div>
  )
}
