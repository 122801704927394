import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import _ from 'lodash'
import { payhelmPixelAccounteUrl } from 'urls'
import { getAxiosDefaultConfig } from '../../modules/utils'

export const initialState = {
  error: null,
  warning: null,
}

export const fetchPayhelmPixelAccounts = createAsyncThunk('payhelmPixel/fetchPayhelmPixelAccounts', async () => {
  let res = await axios.get(payhelmPixelAccounteUrl, getAxiosDefaultConfig())
  return res?.data
})

export const createPayhelmPixelAccount = createAsyncThunk('payhelmPixel/createPayhelmPixelAccount', async (
  {payload, retrieveAccounts, callback},
) => {
  let res = await axios.put(payhelmPixelAccounteUrl, payload, getAxiosDefaultConfig())
  await retrieveAccounts()
  if (callback) {
    callback(res?.data?.warning || null)
  }
  return res?.data?.result
})

export const updatePayhelmPixelAccounts = createAsyncThunk('payhelmPixel/updatePayhelmPixelAccounts', async (
  {payload, retrieveAccounts, callback},
) => {
  try {
    let res = await axios.post(`${payhelmPixelAccounteUrl}/`, payload, getAxiosDefaultConfig())
    await retrieveAccounts()
    if (callback) {
      callback(res?.data?.warning || null)
    }
    return res?.data?.result
  }
  catch(error) {
    console.error(error)
    throw(error?.response?.data || 'Internal error')
  }
})

export const deletePayhelmPixelAccount = createAsyncThunk('payhelmPixel/deletePayhelmPixelAccount', async (
  {id, retrieveAccounts},
  { dispatch },
) => {
  let res = await axios.delete(`${payhelmPixelAccounteUrl}/${id}`, getAxiosDefaultConfig())
  await retrieveAccounts()
  dispatch(fetchPayhelmPixelAccounts())
  return res?.data?.result
})

export const payhelmPixelSlice = createSlice({
  name: 'payhelmPixel',
  initialState,
  reducers: {
    setEditedPayhelmPixelAccount: (state, action) => {
      state.editedAccount = action.payload
    },
    setPendingConfig: (state, action) => {
      state.pendingConfig = action.payload
    },
    setError: (state, action) => {
      state.error = action.payload
    },
    setWarning: (state, action) => {
      state.warning = action.payload
    },
    setResult: (state, action) => {
      state.result = action.payload
    }
  },
  extraReducers(builder) {
    builder
      /**
       *
       * Api Store account
       *
       */
      .addCase(fetchPayhelmPixelAccounts.pending, (state, action) => {
        state.dataStatus = 'loading'
        state.result = null
        state.warning = null
        state.error = null
      })
      .addCase(fetchPayhelmPixelAccounts.fulfilled, (state, action) => {
        state.dataStatus = 'fulfilled'
        state.accounts = action.payload
        if (action.payload.length) {
          state.editedAccount = action.payload[0]
          state.pendingConfig = action.payload
        }
      })
      .addCase(fetchPayhelmPixelAccounts.rejected, (state, action) => {
        state.dataStatus = 'failed'
        state.error = action.error
      })
      .addCase(createPayhelmPixelAccount.pending, (state, action) => {
        state.result = null
        state.warning = null
        state.error = null
        state.dataStatus = 'loading'
      })
      .addCase(createPayhelmPixelAccount.fulfilled, (state, action) => {
        state.dataStatus = 'fulfilled'
        state.error = null
        state.result = action.payload
      })
      .addCase(createPayhelmPixelAccount.rejected, (state, action) => {
        state.dataStatus = 'failed'
        state.error = action.error
      })
      .addCase(updatePayhelmPixelAccounts.pending, (state, action) => {
        state.result = null
        state.warning = null
        state.error = null
        state.dataStatus = 'loading'
      })
      .addCase(updatePayhelmPixelAccounts.fulfilled, (state, action) => {
        state.dataStatus = 'fulfilled'
        state.error = null
        state.result = action.payload
      })
      .addCase(updatePayhelmPixelAccounts.rejected, (state, action) => {
        state.dataStatus = 'failed'
        state.error = action.error
      })
      .addCase(deletePayhelmPixelAccount.pending, (state, action) => {
        state.result = null
        state.warning = null
        state.error = null
        state.dataStatus = 'loading'
      })
      .addCase(deletePayhelmPixelAccount.fulfilled, (state, action) => {
        state.dataStatus = 'fulfilled'
        state.error = null
        state.result = action.payload
      })
      .addCase(deletePayhelmPixelAccount.rejected, (state, action) => {
        state.dataStatus = 'failed'
        state.error = action.error
      })
  },
})

export const { 
  setEditedPayhelmPixelAccount,
  setPendingConfig,
  setError,
  setWarning,
  setResult,
} = payhelmPixelSlice.actions

/**
 *
 * Selectors
 *
 */
export const selectPayhelmPixelAccounts = (state) => state.payhelmPixel.accounts || null
export const selectEditedPayhelmPixelAccount = (state) => state.payhelmPixel.editedAccount || null
export const selectPayhelmPixelDataStatus = (state) => state.payhelmPixel.dataStatus || 'idle'
export const selectPayhelmPixelError = (state) => state.payhelmPixel.error?.message || null
export const selectPayhelmPixelWarning = (state) => state.payhelmPixel.warning?.message || null
export const selectPayhelmPixelResult = (state) => state.payhelmPixel.result?.message || null
export const selectPendingConfig = (state) => state.payhelmPixel.pendingConfig || null

export default payhelmPixelSlice.reducer