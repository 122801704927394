const initialState = {
  backfill: [{
    id: null,
    merchant_id: null,
    total: 0,
    current: 0,
    current_period_orders_total: 0,
    current_period_orders_processed: 0,
    status: false
  }],
  runningBackfill: [],
  finishedBackfill: [],
  failedBackfill: [],
  index: 0,
  fetching: false,
  fetched: false,
  fetchingRunning: true,
  fetchedRunning: false,
  adminPage: false,
  error: null,
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case 'RESET_BACKFILL': {
      const { backfill, index, fetching, fetched, error } = initialState
      return { ...state, backfill, index, fetching, fetched, error }
    }
    case 'FETCH_BACKFILL': {
      return { ...state, fetching: true }
    }
    case 'FETCH_REJECTED_BACKFILL': {
      return { ...state, fetching: false, error: action.payload }
    }
    case 'FETCH_FULFILLED_BACKFILL': {
      return {
        ...state,
        fetching: false,
        fetched: true,
        backfill: action.payload,
      }
    }
    case 'SWITCH_BACKFILL': {
      return {
        ...state,
        index: action.payload,
      }
    }
    case 'FETCH_RUNNING_BACKFILL': {
      return { ...state, fetchingRunning: true }
    }
    case 'FETCH_REJECTED_RUNNING_BACKFILL': {
      return { ...state, fetchingRunning: false, error: action.payload }
    }
    case 'FETCH_FULFILLED_RUNNING_BACKFILL': {
      return {
        ...state,
        fetchingRunning: false,
        fetchedRunning: true,
        runningBackfill: action.payload,
      }
    }
    case 'SET_FINISHED_BACKFILL': {
      return {
        ...state,
        finishedBackfill: action.payload,
      }
    }
    case 'SET_FAILED_BACKFILL': {
      return {
        ...state,
        failedBackfill: action.payload,
      }
    }
    case 'TOGGLE_ADMIN_PAGE_BACKFILL': {
      return {
        ...state,
        adminPage: action.payload,
      }
    }
    default: {
      return state
    }
  }
}
