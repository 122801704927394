import axios from 'axios'
import {
  verifyLogin,
  authFail,
  verifyBigcommerceLogin,
  verifyWoocommerceLogin,
  verifyShopifyLogin,
  checkShopifyExistence,
  inkfrogAuth,
  getCustomerInfoUrl,
  getAdminUser,
  signOutUrl,
  dataConnectorsConfigUrl,
  getUserSharedAccountUrl,
} from 'urls'
import { getLoginInfo, redirectLogin } from 'loginControl'
import hosts from 'hosts.json'
import queryString from 'query-string'
import { checkRequiredAction } from './actionUtils'
import { sendGtmPixel } from 'tagManager'
import { getAxiosDefaultConfig } from 'utils'
import _ from 'lodash'
import { getCookie } from 'cookie'
import { shopifyClientId } from 'token'

const verifyChannelLoginUrls = {
  Bigcommerce: verifyBigcommerceLogin,
  Woocommerce: verifyWoocommerceLogin,
  Shopify: verifyShopifyLogin,
}

export function changeApplication(currentId, nextId) {
  if (currentId != nextId) {
    return {
      type: 'CHANGE_APPLICATION_ROOT',
      payload: nextId.replace(/\//g, ''),
    }
  } else return {
    type: 'NULL'
  }
}

export function setLinkingStatus(status) {
  return {
    type: 'SET_LINKING_STATUS_ROOT',
    payload: status,
  }
}

export function setChargeStatus(status) {
  return {
    type: 'SET_CHARGE_STATUS_ROOT',
    payload: status,
  }
}

export function setAccounts(accounts) {
  return {
    type: 'SET_ACCOUNTS_ROOT',
    payload: accounts,
  }
}

export function setDomains(domains) {
  return {
    type: 'SET_DOMAINS_ROOT',
    payload: domains,
  }
}

export function loginSuccess(accounts, user, loginFromChannel) {
  return {
    type: 'LOGIN_SUCCESS_ROOT',
    payload: { accounts, user, loginFromChannel },
  }
}

export function loginFailure(message) {
  return {
    type: 'LOGIN_FAILURE_ROOT',
    payload: message,
  }
}

export function updateUserPreferencesRoot(userPreferences) {
  return {
    type: 'UPDATE_USER_PREFERENCES_ROOT',
    payload: userPreferences,
  }
}

export function setAccountFilter(filter) {
  return {
    type: 'SET_ACCOUNT_FILTER_ROOT',
    payload: filter,
  }
}

export function setThirdParty(thirdParty) {
  return {
    type: 'SET_THIRD_PARTY_ROOT',
    payload: thirdParty,
  }
}

export function setInkfrogUser(user) {
  return {
    type: 'SET_INKFROG_USER_ROOT',
    payload: user,
  }
}

export function setInkfrogNeedsUpgrade(flag) {
  return {
    type: 'SET_INKFROG_NEEDS_UPGRADE_ROOT',
    payload: flag,
  }
}

export function toggleAgreement(flag) {
  return {
    type: 'TOGGLE_AGREEMENT_ROOT',
    payload: flag,
  }
}

export function setLinkModalOpenFor(channel) {
  return {
    type: 'SET_LINK_MODAL_OPEN_FOR_ROOT',
    payload: channel,
  }
}

export function setTopUrl(url) {
  return {
    type: 'SET_TOP_URL_ROOT',
    payload: url,
  }
}

export function updateQueryJson() {
  return {
    type: 'UPDATE_QUERY_JSON',
  }
}

export function setAdmin(flag) {
  return {
    type: 'SET_ADMIN',
    payload: flag,
  }
}

export function setAccountEmailDict(accounts) {
  return {
    type: 'SET_ACCOUNT_EMAIL_DICT_ROOT',
    payload: accounts,
  }
}

export function updateBaremetricsToken(token) {
  return {
    type: 'UPDATE_BAREMETRICS_TOKEN_ROOT',
    payload: token
  }
}

export function updateIntercomToken(token) {
  return {
    type: 'UPDATE_INTERCOM_TOKEN_ROOT',
    payload: token
  }
}

export function updateSlackNotificationSettings(settings) {
  return {
    type: 'UPDATE_SLACK_NOTIFICATIONS_ROOT',
    payload: settings
  }
}

export function setSessionSignature(signature) {
  return {
    type: 'SET_SESSION_SIGNATURE',
    payload: signature
  }
}

export function setOnboardSkipped(flag) {
  return {
    type: 'SET_ONBOARD_SKIPPED_ROOT',
    payload: flag
  }
}

export function updateDataConnectorSettings({intercomToken, baremetricsToken, slackConfig}) {
  return (dispatch) => {
    intercomToken ? dispatch(updateIntercomToken(intercomToken)) : null
    baremetricsToken ? dispatch(updateBaremetricsToken(baremetricsToken)) : null
    slackConfig ? dispatch(updateSlackNotificationSettings(slackConfig)) : null
  }
}

export const signOut = () => (dispatch) => {
  return axios.get(signOutUrl, getAxiosDefaultConfig())
}

const checkAdmin = () => {
  return axios.get(getAdminUser, getAxiosDefaultConfig())
}

// For shopify login only
const initializeAppBridge = () => {
  const script = document.createElement('script')
  script.src = 'https://unpkg.com/@shopify/app-bridge@3'
  script.onload = () => {
    const AppBridge = window['app-bridge'];
    const appBridge = AppBridge.createApp({
        apiKey: shopifyClientId,
        host: new URLSearchParams(location.search).get("host"),
        forceRedirect: true
    })
    console.log('APP BRIDGE INITIALIZED', appBridge)
  }
  document.head.appendChild(script)
}

/**
 * General process of login
 * 1, Checked if there is signed_payload in query. If yes, go through the BigCommerce login process first
 * 2, If no signed_payload, check if app_token exists, if no then throw an error
 * 3, Get login account info and customer info (if BC login done then ignore the 1st one)
 * 4, Update the app with responses data
 * @param {String} applicationId the current application id in page
 */
export const appLogin = (applicationId) => {
  return async (dispatch, getState) => {
    let loginFromChannel, email
    try {
      const { app_token, admin_token } = getLoginInfo()
      const queryParams = queryString.parse(location.search)
      let channelLoginRes
      let hasChannelLoginParams = {
        Bigcommerce: !!queryParams.signed_payload,
        Woocommerce: queryParams.channel === 'woocommerce' && !!queryParams.payload,
        Shopify: !!queryParams.hmac && !!queryParams.shop && !!(queryParams.host || queryParams.url),
      }
      let referrerDomain = ''
      try {
        referrerDomain = document.referrer ? (new URL(document.referrer)).hostname : ''
      }
      catch(error) {
        console.error('Failed to get referrer site', document.referrer)
      }

      // Landing from channel iframe
      for (var channel of ['Bigcommerce', 'Woocommerce', 'Shopify']) {
        if (hasChannelLoginParams[channel]) {
          loginFromChannel = channel
          if (channel === 'Shopify') {
            const { data: { redirectUrl }} = await axios.get(`${checkShopifyExistence}?shop=${queryParams.shop}`)
            if (redirectUrl) {
              location.href = redirectUrl
              return
            }
          }
          try {
            channelLoginRes = await axios.get(`${verifyChannelLoginUrls[channel]}?${queryString.stringify(queryParams)}`, getAxiosDefaultConfig())
            if (channelLoginRes && channelLoginRes.data && channelLoginRes.data.signature) {
              dispatch(setSessionSignature(channelLoginRes.data.signature))
              if (channel === 'Shopify') {
                initializeAppBridge()
              }
              break
            }
          }
          catch(error) {
            console.log('Failed to auth through', channel, error)
          }
        }
      }
      if (!app_token && !getState().root.sessionSignature) {
        throw('No app token detected')
      }

      const isFromIframe = window.location != window.parent.location
      email = _.get(channelLoginRes, ['user', 'email'])
      if (isFromIframe) {
        console.log(`iframe login from ${referrerDomain}`)
        sendGtmPixel({
          email,
          message: `iframe login from ${referrerDomain}`
        }, 'login_iframe')
      }
      if (loginFromChannel === 'Woocommerce' && isFromIframe) {
        if (channelLoginRes) {
          const allowedDomain = channelLoginRes.data.domain
          if (allowedDomain !== referrerDomain) {
            sendGtmPixel({
              email,
              message: `Woocommerce login failed from ${referrerDomain}, requiring ${allowedDomain}`
            }, 'login_failure')
            // return dispatch(loginFailure('Sorry, this store is not authenticated with PayHelm'))
          }
        }
        else {
          // throw(`iframe login failed`)
          console.log('iframe login failed')
        }
      }

      const [loginRes, customerRes] =  await Promise.all(admin_token ? [
        channelLoginRes || axios.get(verifyLogin, getAxiosDefaultConfig())
      ] : [
        channelLoginRes || axios.get(verifyLogin, getAxiosDefaultConfig()),
        axios.get(getCustomerInfoUrl, getAxiosDefaultConfig())
      ])

      email = _.get(loginRes, ['user', 'email'])
      const { accounts, user, signature, isOwner, owner, domains } = loginRes.data
      const {
        subscription,
        addOns,
        addOnPrices,
        creditCards,
        billingChannel,
        monthlySales,
        customerBalance,
        autoDepositAmountSetting
      } = customerRes.data

      dispatch(setDomains(domains))

      let access_permission = []

      try {
        const sharedAccountRes = await axios.get(getUserSharedAccountUrl, {
          ...getAxiosDefaultConfig(),
          params: {
            email: user.email,
          },
        })
        access_permission = sharedAccountRes.data.access_permission
      } catch (error) {
        access_permission = []
      }

      if (signature) dispatch(setSessionSignature(signature))
      dispatch({ type: 'SET_CREDIT_CARDS_PLAN', payload: creditCards })
      dispatch({ type: 'SET_SUBSCRIPTION_PLAN', payload: subscription })
      if (addOns) {
        dispatch({ type: 'SET_ADD_ONS_PLAN', payload: addOns })
      }
      dispatch({ type: 'SET_ADD_ON_PRICES_PLAN', payload: addOnPrices })
      dispatch({ type: 'SET_BILLING_CHANNEL_PLAN', payload: billingChannel })
      dispatch({ type: 'SET_MONTHLY_SALES_PLAN', payload: monthlySales })
      dispatch({ type: 'SET_LAST_LOGIN_DATE_INTERCOM', payload: (new Date().toString()) })
      dispatch({ type: 'SET_CUSTOMER_BALANCE_PLAN', payload: parseFloat(customerBalance / 100) })
      dispatch({ type: 'SET_AUTO_DEPOSIT_AMOUNT_SETTING_PLAN', payload: autoDepositAmountSetting })
      dispatch({ type: 'SET_IS_OWNER_ROOT', payload: isOwner })

      if (!accounts.length) {
        dispatch({ type: 'CHANGE_APPLICATION_ROOT', payload: 'onboard' })
      }

      // Admin level check
      let admin = false
      try {
        await checkAdmin()
        admin = true
        dispatch(setAdmin(true))
        dispatch({ type: 'FETCH_USER_ADMIN_FULFILLED', payload: { admin: true } })
      }
      catch(error) {
        console.log('Not admin')
        dispatch({ type: 'FETCH_USER_ADMIN_REJECTED', payload: null })
      }

      dispatch(loginSuccess(accounts, {...user, access_permission: access_permission, owner }, loginFromChannel))

      const { subscriptionActive, requiredAction } = checkRequiredAction(customerRes.data, admin)
      const planExpired = subscription && subscription.plan_expired
      dispatch({ type: 'SET_SUBSCRIPTION_ACTIVE_PLAN', payload: subscriptionActive })
      dispatch({ type: 'SET_PLAN_EXPIRED_PLAN', payload: planExpired })
      dispatch({ type: 'SET_DONE_ACTION_INTERCOM', payload: requiredAction })

      const planActionRequired = !subscriptionActive || planExpired
      if (planActionRequired) {
        dispatch(changeApplication(applicationId, 'plan'))
      }

      sendGtmPixel(accounts, 'signup_success')
    }
    catch(error) {
      console.error(error)
      sendGtmPixel({
        email,
        message: _.get(error, ['data', 'error']) || _.get(error, ['data']) || error
      }, 'login_failure')
      if (!getCookie('app_token') && getState().root.sessionSignature) {
        return dispatch({ type: 'SET_SESSION_EXPIRED', payload: true })
      }
      else if (loginFromChannel) {
        dispatch(loginFailure('Authentication failed. Please try again or contact our support.'))
      }
      else if (error.data && error.data.error === 'data permission required') {
        redirectLogin({loginError: true})
      }
      else {
        console.error('App login with error:', error)
        redirectLogin({loginError: true})
      }
      axios.get(authFail, getAxiosDefaultConfig())
    }
  }
}

export const inkfrogLogin = () => {
  return (dispatch) => {
    dispatch(setThirdParty('inkfrog'))
    axios.get(hosts.inkfrogHost[location.hostname].user, {withCredentials: true})
    .then(res => {
      dispatch(setInkfrogUser(res.data))
      axios.post(inkfrogAuth, {user: res.data}, {withCredentials: true})
      .then(res => {
        checkAdmin()
        .then(() => {
          dispatch(setAdmin(true))
          dispatch(setInkfrogNeedsUpgrade(res.data.needs_upgrade))
          dispatch(loginSuccess(res.data.accounts))
        })
        .catch(() => {
          dispatch(setInkfrogNeedsUpgrade(res.data.needs_upgrade))
          dispatch(loginSuccess(res.data.accounts))
        })
      })
      .catch(err => {
        console.log(err)
      })
    })
    .catch(err => {
      console.log(err)
    })
  }
}

export const setSlackConnectorsConfig = (slack_config) => {
  return async (dispatch) => {
    try {
      let dataConnectorsConfigRes = await axios.get(dataConnectorsConfigUrl, getAxiosDefaultConfig())
      const { slackconfig, intercomtoken, baremetricstoken } = dataConnectorsConfigRes.data
      if (slackconfig === null) {
        dispatch(updateDataConnectorsConfig(slack_config, intercomtoken, baremetricstoken))
        dispatch(updateDataConnectorSettings({ baremetricsToken: baremetricstoken, intercomToken: intercomtoken, slackConfig: slack_config}))
      } else {
        dispatch(updateDataConnectorSettings({ baremetricsToken: baremetricstoken, intercomToken: intercomtoken, slackConfig: slackconfig}))
      }
    } catch(err) {
      console.log(err)
    }
  }
}

export const getDataConnectorsConfig = () => {
  return async (dispatch) => {
    try {
      let dataConnectorsConfigRes = await axios.get(dataConnectorsConfigUrl, getAxiosDefaultConfig())
      const { slackconfig, intercomtoken, baremetricstoken } = dataConnectorsConfigRes.data
      dispatch(updateDataConnectorSettings({ baremetricsToken: baremetricstoken, intercomToken: intercomtoken, slackConfig: slackconfig}))
    } catch(err) {
      console.log(err)
    }
  }
}

export const updateDataConnectorsConfig = (slackConfig, intercomToken, baremetricsToken, shipstationConfigs, shippingeasyConfigs) => {
  return async (dispatch) => {
    dispatch({ type: 'SET_DATA_CONNECTORS_STATUS_SETTINGS', payload: 'loading' })
    try {
      let dataConnectorsConfigRes = await axios.put(dataConnectorsConfigUrl, { intercomToken, slackConfig, baremetricsToken, shipstationConfigs, shippingeasyConfigs }, getAxiosDefaultConfig())
      const {slackconfig, intercomtoken, baremetricstoken} = dataConnectorsConfigRes.data
      dispatch({ type: 'SET_DATA_CONNECTORS_ERROR_SETTINGS', payload: null })
      dispatch(updateDataConnectorSettings({ baremetricsToken: baremetricstoken, intercomToken:intercomtoken, slackConfig: slackconfig}))
      dispatch({ type: 'SET_DATA_CONNECTORS_STATUS_SETTINGS', payload: 'load_success' })
    } catch(err) {
      const errMsg = _.get(err, ['response', 'data', 'errors'], 'Errors when saving configurations')
      dispatch({ type: 'SET_DATA_CONNECTORS_STATUS_SETTINGS', payload: 'load_error' })
      dispatch({ type: 'SET_DATA_CONNECTORS_ERROR_SETTINGS', payload: errMsg })
    }
  }
}

export function updateUser(user) {
  return {
    type: 'UPDATE_USER_ROOT',
    payload: user,
  }
}

export function setReportTimeCost(milliseconds) {
  return {
    type: 'SET_REPORT_TIME_COST_ROOT',
    payload: milliseconds,
  }
}
