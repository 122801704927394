import React from 'react'

export default function MyLabel({ wrapperStyle = null, text, textSuffix, children, className = '', ...rest }) {
  return (
    <label className="tw-block" style={wrapperStyle}>
      {text && (
        <span className={`tw-block tw-mb-1 tw-text-sm tw-font-medium tw-text-gray-500 ${className}`} {...rest}>
          {text}
          {textSuffix}
        </span>
      )}
      {children}
    </label>
  )
}
