import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import currencies from 'modules/fullCurrencies.json'
import timezones from 'compact-timezone-list'
import {
  fetchPreferences,
  selectPreferences,
  selectPreferencesStatus,
  updatePreferences,
} from '../Settings/settingsSlice'
import { updateUserPreferencesRoot } from '../../actions/rootActions'
import { toast } from 'react-toastify'
import MySelect from '../../features/forms/MySelect'
import MyCheckbox from '../../features/forms/MyCheckbox'
import { AiFillInfoCircle } from 'react-icons/ai'
import ReactTooltip from 'react-tooltip'
import { Button } from 'react-bootstrap'

const Section = ({ children }) => (
  <div className="tw-p-6 tw-rounded-xl tw-bg-slate-50 tw-border tw-border-slate-200">{children}</div>
)

export default function GeneralPreferences({ formObj }) {
  const { register, errors } = formObj

  /**
   *
   * Set options
   *
   */
  // timezone options
  const [timezoneOptions, setTimezoneOptions] = useState([])
  useEffect(() => {
    // Add default option too
    const _timezoneOptions = timezones.map(({ tzCode, label }) => ({ value: tzCode, label }))
    const _timezoneOptionsWithDefault = _.concat(
      [{ value: null, label: 'Select your default timezone' }],
      _timezoneOptions
    )
    setTimezoneOptions(_timezoneOptionsWithDefault)
  }, [])

  // currency options
  const [currencyOptions, setCurrencyOptions] = useState([])
  useEffect(() => {
    const _currencyOptions = _.values(currencies).map(({ code, name }) => ({ value: code, label: `${name} (${code})` }))
    setCurrencyOptions(_currencyOptions)
  }, [])

  return (
    <div className="tw-pt-10 tw-pb-10 tw-border-t tw-border-slate-200">
      <div className="tw-px-4 md:tw-px-8">
        <div className="tw-text-xl tw-font-bold tw-mb-4">Preferences</div>
        <div className="tw-text-slate-500 tw-mb-4">Set up basic preferences for your account.</div>

        <Section>
          <div className="tw-grid tw-grid-cols-1 tw-mb-4 tw-items-center">
            <div className="tw-col-span-1">
              <div className="tw-w-full md:tw-flex md:tw-items-center">
                <h6 className="tw-min-w-[9rem] md:tw-mb-0">Timezone</h6>
                <div className="tw-flex-grow">
                  <MySelect
                    register={register}
                    name="defaultTimezone"
                    errors={errors}
                    rules={{}}
                    options={timezoneOptions}
                    theme="white"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="tw-grid tw-grid-cols-1 tw-items-center">
            <div className="tw-col-span-1">
              <div className="tw-w-full md:tw-flex md:tw-items-center">
                <h6 className="tw-min-w-[9rem] tw-mt-2">Default currency</h6>
                <div className="tw-flex-grow">
                  <div>
                    <MySelect
                      register={register}
                      name="defaultCurrency"
                      errors={errors}
                      rules={{}}
                      options={currencyOptions}
                      theme="white"
                    />
                  </div>
                  <div className="tw-mt-2 tw-text-sm">
                    <MyCheckbox
                      register={register}
                      name="currencySettings.auto_filter_default_currency"
                      label={
                        <div className="tw-flex tw-items-center tw-space-x-1.5">
                          <div>Use as primary currency for searching</div>
                          <AiFillInfoCircle
                            className="tw-cursor-pointer tw-fill-blue-500 tw-text-lg"
                            data-tip
                            data-for={`tooltip-primary-currency`}
                          />
                          <ReactTooltip id={`tooltip-primary-currency`}>
                            <div style={{ maxWidth: 270 }}>
                              <div>
                                Make it default to only search for orders that are paid in the selected currency so that
                                currency conversions are disabled.
                              </div>
                              <div>
                                Unchecking this option to analyze among multi-currency orders, in which metrics are
                                converted from their USD amounts and become estimated.
                              </div>
                            </div>
                          </ReactTooltip>
                        </div>
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Section>
      </div>
    </div>
  )
}
