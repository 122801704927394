module.exports = {
	"dashboard": {
		"metrics": {
			"arpu": {
				"title": "ARPU",
				"format": "$0,0.00",
				"axis_format": "$0,0",
				"axis_format_big": "$0a",
				"avg": true,
				"divide": "netrevenue",
				"by": "customers"
			},
			"avgpayment": {
				"title": "Avg. Payment",
				"format": "$0,0.00",
				"axis_format": "$0,0",
				"axis_format_big": "$0a",
				"avg": true,
				"divide": "netrevenue",
				"by": "payments"
			},
			"customers": {
				"title": "Customers",
				"format": "0,0",
				"axis_format": "0,0",
				"axis_format_big": "0a",
				"acc": true
			},
			"netrevenue": {
				"title": "Net Revenue",
				"format": "$0,0.00",
				"axis_format": "$0,0",
				"axis_format_big": "$0a",
				"acc": true
			},
			"payments": {
				"title": "Payments",
				"format": "0,0",
				"axis_format": "0,0",
				"axis_format_big": "0a",
				"acc": true
			}
		},
		"transaction": {
			"transaction_id": "Transaction ID",
			"email": "Email",
			"event_at": "Event at",
			"type": "Type",
			"transaction_status": "Transaction Status",
			"amount": "Amount",
			"fee_amount": "Fee Amount",
			"net_amount": "Net Amount"
		},
		"ebayMetrics": {
			"netrevenue": {
				"mappedKey": "valueShipped",
				"title": "Value of Shipped Items",
				"format": "$0,0.00",
				"axis_format": "$0,0",
				"axis_format_big": "$0a",
				"acc": true
			},
			"payments": {
				"mappedKey": "countShipped",
				"title": "Count of Shipped Items",
				"format": "0,0",
				"axis_format": "0,0",
				"axis_format_big": "0a",
				"acc": true
			}
		}
	},
	"subscribers": {
		"metrics": {
			"churn": {
				"title": "Churn",
				"format": "0.00%"
			},
			"ltv": {
				"title": "LTV",
				"format": "$0,0.00",
				"axis_format": "$0,0",
				"axis_format_big": "$0a"
			},
			"subscribers": {
				"title": "Subscribers",
				"format": "0,0",
				"axis_format": "0,0",
				"axis_format_big": "0a"
			},
			"acquisitions": {
				"title": "Acquisitions",
				"format": "0,0",
				"axis_format": "0,0",
				"axis_format_big": "0a"
			},
			"cancels": {
				"title": "Cancels",
				"format": "0,0",
				"axis_format": "0,0",
				"axis_format_big": "0a"
			},
			"net": {
				"title": "Net",
				"format": "0,0",
				"axis_format": "0,0",
				"axis_format_big": "0a"
			},
			"total_sales": {
				"title": "Total Sales",
				"format": "$0,0.00",
				"axis_format": "$0,0",
				"axis_format_big": "$0a"
			},
			"payments": {
				"title": "Payments",
				"format": "0,0",
				"axis_format": "0,0",
				"axis_format_big": "0a"
			},
			"avg_payment": {
				"title": "Avg. Payment",
				"format": "$0,0.00",
				"axis_format": "$0,0",
				"axis_format_big": "$0a"
			},
			"most_popular": {
				"title": "Most Popular Price",
				"format": "$0,0.00",
				"axis_format": "$0,0",
				"axis_format_big": "$0a",
				"sub_metrics": {
					"subscribers": {
						"title": "subscribers",
						"format": "0,0",
						"axis_format": "$0,0",
						"axis_format_big": "$0a"
					}
				}
			},
			"most_profitable": {
				"title": "Most Profitable Price",
				"format": "$0,0.00",
				"axis_format": "$0,0",
				"axis_format_big": "$0a",
				"sub_metrics": {
					"revenue": {
						"title": "total sales",
						"format": "$0,0.00",
						"axis_format": "$0,0",
						"axis_format_big": "$0a"
					}
				}
			},
			"highest_churn": {
				"title": "Highest Churn Price",
				"format": "$0,0.00",
				"sub_metrics": {
					"churn": {
						"title": "churn",
						"format": "0.00%"
					}
				}
			}
		},
		"customers": {
			"email": "Email",
			"profile_id": "Profile ID",
			"status": "Status",
			"subscriber_name": "Name",
			"number_cycles_completed": "Cycles Completed",
			"aggregate_amount": "Aggregate Amount",
			"profile_start_month": "Start Month",
			"last_payment_month": "Last Payment Month",
			"probability_to_churn": "Prob. to Churn",
			"price": "Price",
			"churn": "Churn",
			"metrics": {
				"price": {
					"title": "Price",
					"format": "$0,0.00"
				},
				"churn": {
					"title": "Churn",
					"format": "0.00%"
				}
			}
		},
		"priceAnalysis": {
			"price": "Price",
			"revenue": "Total Sales",
			"ltv": "LTV",
			"subscribers_end": "Subscribers",
			"acquisitions": "Acquisitions",
			"cancelled": "Cancelled",
			"net_subscribers": "Net",
			"churn": "Churn",
			"subscriber_months": "Subscriber Months"
		}
	}
};