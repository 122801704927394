import React, { useState, useEffect } from 'react'
import { toast } from 'react-toastify'
import { FiCopy } from 'react-icons/fi'

export default function CopyToClipboardButton({ value, kind = 'icon', theme = 'light' }) {
  /**
   *
   * Handle output uri copy to clipboard
   *
   */
  const [copied, setCopied] = useState(false)
  useEffect(() => {
    if (copied) {
      toast.success('Copied to clipboard 👍')
      setCopied(false)
    }
  }, [copied])

  const handleCopyToClipboard = (e, _value) => {
    navigator.clipboard.writeText(_value)
    setCopied(true)
  }

  const themeClassNameMap = {
    icon: {
      light: 'tw-text-gray-400 hover:tw-text-gray-700',
      dark: 'tw-text-gray-400 hover:tw-text-gray-700',
    },
    button: {
      light: 'tw-bg-black/20 hover:tw-bg-black/30 tw-text-slate-800',
      dark: 'tw-bg-indigo-600 hover:tw-bg-indigo-500 tw-text-white/90',
    },
  }
  const themeClassName = themeClassNameMap[kind][theme]

  return (
    <>
      {kind === 'icon' && (
        <button
          type="button"
          aria-label="copy"
          className={themeClassName}
          onClick={(e) => handleCopyToClipboard(e, value)}
        >
          <FiCopy strokeWidth={1.5} />
        </button>
      )}

      {kind === 'button' && (
        <button
          type="button"
          aria-label="copy"
          className={`pointer-events-auto flex items-center space-x-1.5 rounded-full px-2.5 py-1.5 text-sm ${themeClassName}`}
          onClick={(e) => handleCopyToClipboard(e, value)}
        >
          <FiCopy strokeWidth={2} />
          <div>Copy</div>
        </button>
      )}
    </>
  )
}
