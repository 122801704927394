import React from 'react'
import { bool } from 'prop-types'
import { connect } from 'react-redux'

import ConditionalRoute from './Router.conditionalRoute'

const AdminMimicRoute = ({ mimic, ...props }) => {
  return (
    <ConditionalRoute
      condition={mimic}
      {...props}
    />
  )
}

AdminMimicRoute.propTypes = {
  mimic: bool,
}

const mapStateToProps = store => ({
  mimic: !!store.mimic.mimic.status
})

export default connect(mapStateToProps)(AdminMimicRoute)
