import React, { useEffect, useState } from 'react'
import _, { set } from 'lodash'
import ShopifyLogo from '../../assets/images/logos/shopify.svg'
import WoocommerceLogo from '../../assets/images/logos/woocommerce.svg'
import AmazonLogo from '../../assets/images/logos/amazon.svg'
import BigcommerceLogo from '../../assets/images/logos/bigcommerce.svg'
import GoogleAnalyticsLogo from '../../assets/images/logos/google_analytics.svg'
import MagentoLogo from '../../assets/images/logos/magento.svg'
import StripeLogo from '../../assets/images/logos/stripe.svg'
import MailchimpLogo from '../../assets/images/logos/mailchimp.svg'
import SalesforceLogo from '../../assets/images/logos/salesforce.svg'
import ShipsuranceLogo from '../../assets/images/logos/shipsurance.svg'
import FedExLogo from '../../assets/images/logos/fedEx.svg'
import UspsLogo from '../../assets/images/logos/usps.svg'
import UpsLogo from '../../assets/images/logos/ups.svg'
import FacebookAdsLogo from '../../assets/images/logos/facebook_ads.svg'
import BraintreeLogo from '../../assets/images/logos/braintree.svg'
import AuthorizeNetLogo from '../../assets/images/logos/authorize_net.svg'
import QuickbooksLogo from '../../assets/images/logos/quickbooks.svg'
import AvalaraLogo from '../../assets/images/logos/avalara.svg'
import SquareLogo from '../../assets/images/logos/square.svg'
import KlaviyoLogo from '../../assets/images/logos/klaviyo.svg'
import WalmartLogo from '../../assets/images/logos/walmart.svg'
import EbayLogo from '../../assets/images/logos/ebay.svg'
import EtsyLogo from '../../assets/images/logos/etsy.svg'
import ShippoLogo from '../../assets/images/logos/shippo.svg'
import ShipStationLogo from '../../assets/images/logos/shipstation.svg'
import BrightpearlLogo from '../../assets/images/logos/brightpearl.svg'
import ZapierLogo from '../../assets/images/logos/zapier.svg'
import OmnisendLogo from '../../assets/images/logos/omnisend.svg'
import DropboxLogo from '../../assets/images/logos/dropbox.svg'
import GoogleDriveLogo from '../../assets/images/logos/google_drive.svg'
import S3Logo from '../../assets/images/logos/s3.svg'
import FtpLogo from '../../assets/images/logos/ftp.svg'
import GoogleAdsLogo from '../../assets/images/logos/google_ads.svg'
import BingAdsLogo from '../../assets/images/logos/bing_ads.svg'
import AmazonAdsLogo from '../../assets/images/logos/amazon_ads.svg'
import AdrollLogo from '../../assets/images/logos/adroll.svg'
import SnapchatLogo from '../../assets/images/logos/snapchat.svg'
import PinterestLogo from '../../assets/images/logos/pinterest.svg'
import SendgridLogo from '../../assets/images/logos/sendgrid.svg'
import HubspotLogo from '../../assets/images/logos/hubspot.svg'
import EasypostLogo from '../../assets/images/logos/easypost.svg'
import RecurlyLogo from '../../assets/images/logos/recurly.svg'
import ZendeskLogo from '../../assets/images/logos/zendesk.svg'
import IntercomLogo from '../../assets/images/logos/intercom.svg'
import CrazyeggLogo from '../../assets/images/logos/crazyegg.svg'
import OptimizelyLogo from '../../assets/images/logos/optimizely.svg'
import SlackLogo from '../../assets/images/logos/slack.svg'
import PaypalLogo from '../../assets/images/logos/paypal.svg'
import WixLogo from '../../assets/images/logos/wix.svg'
import OpenAILogo from '../../assets/images/logos/openai.svg'
import ApiStoreLogo from '../../assets/images/logos/api_store.svg'
import GoogleLogo from '../../assets/images/logos/google.png'
import PayhelmLogo from '../../assets/images/payhelm-logo-blue.png'
import SmileIoLogo from '../../assets/images/logos/smile_io.svg'
import FeedonomicsLogo from '../../assets/images/logos/feedonomics.png'
import DataFeedWatchLogo from '../../assets/images/logos/datafeedwatch.png'
import GoogleMerchantCenterLogo from '../../assets/images/logos/google_merchant_center.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileCsv } from '@fortawesome/free-solid-svg-icons'
import { useDispatch, useSelector } from 'react-redux'
import { getSecretId } from './channels/generic/helpers'
import { fetchAllPublicKeys, selectAllPublicKeys, selectAllPublicKeysStatus } from './integrationsSlice'
import { fetchAllSecrets, selectAllSecrets, selectAllSecretsStatus } from '../../features/aws/awsSecretsManagerSlice'
import { zipPublicAndPrivateKeys } from './utils'

export function useIntegrations({ accounts, ownerId }) {
  const dispatch = useDispatch()
  const logoClassName = 'tw-max-w-full tw-max-h-full'

  const integrationsData = [
    {
      id: 'bigcommerce',
      channel: 'Bigcommerce',
      title: 'BigCommerce',
      description:
        'PayHelm for BigCommerce creates customer insights, sales analysis, & product performance data for informed decisions and improved ROI.',
      logo: <img src={BigcommerceLogo} className={logoClassName} alt="Bigcommerce logo" />,
      enabled: true,
      settingsEnabled: false,
      backfillEnabled: true,
      uninstallEnabled: false,
    },
    {
      id: 'shopify',
      channel: 'Shopify',
      title: 'Shopify',
      description:
        'Integrate Shopify with PayHelm to deliver real-time customer insights, sales analysis, and store performance data for better business decisions and ROI.',
      logo: <img src={ShopifyLogo} className={logoClassName} alt="Shopify logo" />,
      enabled: true,
      settingsEnabled: false,
      backfillEnabled: true,
      uninstallEnabled: false,
    },
    {
      id: 'woocommerce',
      channel: 'Woocommerce',
      title: 'Woocommerce',
      description:
        'Add WooCommerce to yield real-time customer insights, sales analysis, and SKU performance data for smarter business decisions and grow your business.',
      logo: <img src={WoocommerceLogo} className={logoClassName} alt="Woocommerce logo" />,
      enabled: true,
      settingsEnabled: false,
      backfillEnabled: true,
      uninstallEnabled: false,
    },
    {
      id: 'shipsurance',
      channel: 'Shipsurance',
      title: 'Shipsurance',
      description:
        "Purchase Shipping Insurance through ShipSurance. Connect your data with PayHelm's robust analytics platform. Unlock your data and grow your business.",
      logo: <img src={ShipsuranceLogo} className={logoClassName} alt="Shipsurance logo" />,
      enabled: true,
      settingsEnabled: false,
      backfillEnabled: false,
      uninstallEnabled: false,
    },
    {
      id: 'google_analytics',
      channel: 'Google',
      title: 'Google Analytics',
      description:
        'Analyze your Google Analytics traffic from inside of PayHelm.  Perform conversion audits and ensure your tracking your success.',
      logo: <img src={GoogleAnalyticsLogo} className={logoClassName} alt="Google Analytics logo" />,
      enabled: true,
      settingsEnabled: false,
      backfillEnabled: false,
      uninstallEnabled: false,
    },
    {
      id: 'google',
      channel: 'Google Eco',
      title: 'Google',
      description:
        'Track and analyze user traffic, behaviors, and conversions across the Google ecosystem like Google Drive, Sheets, Ads, and more. Monitor and optimize your online performance effortlessly, ensuring every aspect of your digital strategy is finely tuned for success',
      logo: <img src={GoogleLogo} className={logoClassName} alt="Google logo" />,
      enabled: true,
      settingsEnabled: false,
      backfillEnabled: false,
      uninstallEnabled: false,
    },
    {
      id: 'Paypal Merchant',
      channel: 'Paypal Merchant',
      title: 'Paypal',
      description:
        'Analyze your PayPal fees and PayPal sales for your store or any source of revenue.  Get flexible reporting and take your data with you.',
      logo: <img src={PaypalLogo} className={logoClassName} alt="Paypal logo" />,
      enabled: true,
      settingsEnabled: false,
      backfillEnabled: true,
      uninstallEnabled: false,
    },
    {
      id: 'shipstation',
      channel: 'Shipstation',
      title: 'ShipStation',
      description:
        'Analyze your shipping label cost and insurance fees.  Know your average shipping profit and ensure you are not losing money on shipping.',
      logo: <img src={ShipStationLogo} className={logoClassName} alt="ShipStation logo" />,
      enabled: true,
      settingsEnabled: false,
      backfillEnabled: true,
      uninstallEnabled: false,
    },
    {
      id: 'slack',
      channel: 'Slack',
      title: 'Slack',
      description:
        'Receive real-time notifications for orders in Slack.  Coordinate with your team and be on the same page in real-time.',
      logo: <img src={SlackLogo} className={logoClassName} alt="Slack logo" />,
      enabled: true,
      settingsEnabled: false,
      backfillEnabled: false,
      uninstallEnabled: false,
    },
    {
      id: 'amazon',
      channel: 'Amazon',
      title: 'Amazon',
      description:
        'Analyze your Amazon orders and compare your sales across channels.  Easily know your best products and grow your sales.',
      logo: <img src={AmazonLogo} className={logoClassName} alt="Amazon logo" />,
      enabled: true,
      settingsEnabled: false,
      backfillEnabled: true,
      uninstallEnabled: false,
    },
    {
      id: 'magento',
      channel: 'Magento',
      title: 'Magento',
      description:
        'Magento for PayHelm boosts decision-making with real-time insights on customer behavior, sales, site performance for improved ROI.',
      logo: <img src={MagentoLogo} className={logoClassName} alt="Magento logo" />,
      enabled: false,
      settingsEnabled: false,
      backfillEnabled: true,
      uninstallEnabled: false,
    },
    {
      id: 'stripe',
      channel: 'Stripe',
      title: 'Stripe',
      description:
        'Analyze your Stripe fees and keep your Stripe data aligned across all your sources of data.  Report on your monthly or quarterly sales.',
      logo: <img src={StripeLogo} className={logoClassName} alt="Stripe logo" />,
      enabled: false,
      settingsEnabled: false,
      backfillEnabled: true,
      uninstallEnabled: false,
    },
    {
      id: 'mailchimp',
      channel: 'Mailchimp',
      title: 'Mailchimp',
      description:
        'Import & sync your customers to Mailchimp.  Enrich your Mailchimp contact profiles using meta data from your sources of data.',
      logo: <img src={MailchimpLogo} className={logoClassName} alt="Mailchimp logo" />,
      enabled: false,
      settingsEnabled: false,
      backfillEnabled: true,
      uninstallEnabled: false,
    },
    {
      id: 'salesforce',
      channel: 'Salesforce',
      title: 'Salesforce',
      description:
        'Import & sync your customers with SFDC.  Select which meta data should be sent to SFDC and bring your CRM to your PayHelm reports.',
      logo: <img src={SalesforceLogo} className={logoClassName} alt="Salesforce logo" />,
      enabled: false,
      settingsEnabled: false,
      backfillEnabled: true,
      uninstallEnabled: false,
    },
    {
      id: 'fedEx',
      channel: 'FedEx',
      title: 'FedEx',
      description: 'Enrich your PayHelm reports using your FedEx data.  Ship using PayHelm with your FedEx profile.',
      logo: <img src={FedExLogo} className={logoClassName} alt="FedEx logo" />,
      enabled: false,
      settingsEnabled: false,
      backfillEnabled: true,
      uninstallEnabled: false,
    },
    {
      id: 'usps',
      channel: 'USPS',
      title: 'USPS',
      description: 'Enrich your PayHelm reports using your USPS data.  Ship using PayHelm with your USPS profile.',
      logo: <img src={UspsLogo} className={logoClassName} alt="USPS logo" />,
      enabled: false,
      settingsEnabled: false,
      backfillEnabled: true,
      uninstallEnabled: false,
    },
    {
      id: 'ups',
      channel: 'UPS',
      title: 'UPS',
      description: 'Enrich your PayHelm reports using your UPS data.  Ship using PayHelm with your UPS profile.',
      logo: <img src={UpsLogo} className={logoClassName} alt="UPS logo" />,
      enabled: true,
      settingsEnabled: false,
      backfillEnabled: false,
      uninstallEnabled: false,
    },
    {
      id: 'facebook_ads',
      channel: 'Facebook Ads',
      title: 'Facebook Ads',
      description: 'Analyze your social paid advertising performance.  Know the ROAS for your Facebook campaigns.',
      logo: <img src={FacebookAdsLogo} className={logoClassName} alt="Facebook Ads logo" />,
      enabled: true,
      settingsEnabled: false,
      backfillEnabled: false,
      uninstallEnabled: false,
    },
    {
      id: 'braintree',
      channel: 'Braintree',
      title: 'Braintree',
      description:
        'Analyze your Braintree fees and Braintree sales for your store or any source of revenue.  Enrich your transactions with Braintree data.',
      logo: <img src={BraintreeLogo} className={logoClassName} alt="Braintree logo" />,
      enabled: false,
      settingsEnabled: false,
      backfillEnabled: true,
      uninstallEnabled: false,
    },
    {
      id: 'authorize_net',
      channel: 'Authorize.Net',
      title: 'Authorize.Net',
      description:
        'Analyze your Authorize.net fees and Authorize.net sales for your store or any source of revenue.  Enrich your transactions with Authorize.net data.',
      logo: <img src={AuthorizeNetLogo} className={logoClassName} alt="Authorize.net logo" />,
      enabled: false,
      settingsEnabled: false,
      backfillEnabled: true,
      uninstallEnabled: false,
    },
    {
      id: 'quickbooks',
      channel: 'Quickbooks',
      title: 'Quickbooks',
      description:
        'Import your information into Quickbooks.  Analyze more details than ever inside Quickbooks or pull meta data into PayHelm.',
      logo: <img src={QuickbooksLogo} className={logoClassName} alt="Quickbooks logo" />,
      enabled: false,
      settingsEnabled: false,
      backfillEnabled: true,
      uninstallEnabled: false,
    },
    {
      id: 'avalara',
      channel: 'Avalara',
      title: 'Avalara',
      description:
        'Import your information into Avalara.  Analyze more details than ever inside Avalara or pull meta data into PayHelm.',
      logo: <img src={AvalaraLogo} className={logoClassName} alt="Mailchimp logo" />,
      enabled: false,
      settingsEnabled: false,
      backfillEnabled: true,
      uninstallEnabled: false,
    },
    {
      id: 'square',
      channel: 'Square',
      title: 'Square',
      description:
        'Analyze your Square fees and POS performance for your store or any source of revenue.  Enrich your transactions with Square data.',
      logo: <img src={SquareLogo} className={logoClassName} alt="Square logo" />,
      enabled: false,
      settingsEnabled: false,
      backfillEnabled: true,
      uninstallEnabled: false,
    },
    {
      id: 'klaviyo',
      channel: 'Klaviyo',
      title: 'Klaviyo',
      description:
        'Connect your store data with your Klaviyo email campaigns.  Know which customer segment is opening your emails & analyze meta data.',
      logo: <img src={KlaviyoLogo} className={logoClassName} alt="Klaviyo logo" />,
      enabled: false,
      settingsEnabled: false,
      backfillEnabled: true,
      uninstallEnabled: false,
    },
    {
      id: 'walmart',
      channel: 'Walmart',
      title: 'Walmart',
      description:
        'Analyze your Walmart performance across all your channels.  Analyze product & customer performance.  Enrich your PayHelm reports using Walmart meta data.',
      logo: <img src={WalmartLogo} className={logoClassName} alt="Walmart logo" />,
      enabled: true,
      settingsEnabled: false,
      backfillEnabled: true,
      uninstallEnabled: true,
      unlinkRequirements: 'ID',
      unlinkExample: 'walmart_123456789',
      unlinkIdCol: 'selling_partner_id',
    },
    {
      id: 'ebay',
      channel: 'eBay',
      title: 'eBay',
      description:
        'Analyze your eBay performance across all your channels.  Analyze product & customer performance.  Enrich your PayHelm reports using eBay meta data.',
      logo: <img src={EbayLogo} className={logoClassName} alt="Ebay logo" />,
      enabled: true,
      settingsEnabled: false,
      backfillEnabled: true,
      uninstallEnabled: false,
    },
    {
      id: 'etsy',
      channel: 'Etsy',
      title: 'Etsy',
      description:
        'Analyze your Etsy performance across all your channels.  Analyze product & customer performance.  Enrich your PayHelm reports using Etsy meta data.',
      logo: <img src={EtsyLogo} className={logoClassName} alt="Etsy logo" />,
      enabled: true,
      settingsEnabled: false,
      backfillEnabled: true,
      uninstallEnabled: false,
    },
    {
      id: 'shippo',
      channel: 'Shippo',
      title: 'Shippo',
      description:
        "Connect your shipping label & insurance cost from Shippo to PayHelm.  Know your shipping margins and if you're charging too little on shipping costs.",
      logo: <img src={ShippoLogo} className={logoClassName} alt="Shippo logo" />,
      enabled: false,
      settingsEnabled: false,
      backfillEnabled: true,
      uninstallEnabled: false,
    },
    {
      id: 'brightpearl',
      channel: 'Brightpearl',
      title: 'Brightpearl',
      description:
        'Bring your Brightpearl data into PayHelm.  Analyze your performance and enrich your PayHelm data with Brightpearl data points.',
      logo: <img src={BrightpearlLogo} className={logoClassName} alt="Brightpearl logo" />,
      enabled: false,
      settingsEnabled: false,
      backfillEnabled: true,
      uninstallEnabled: false,
    },
    {
      id: 'zapier',
      channel: 'Zapier',
      title: 'Zapier',
      description:
        'Connect into the strong data network of Zapier.  Bring your PayHelm information with you and connect to many other sources.',
      logo: <img src={ZapierLogo} className={logoClassName} alt="Zapier logo" />,
      enabled: false,
      settingsEnabled: false,
      backfillEnabled: true,
      uninstallEnabled: false,
    },
    {
      id: 'omnisend',
      channel: 'Omnisend',
      title: 'Omnisend',
      description:
        'Connect your store data with your Omnisend email campaigns.  Know which customer segment is opening your emails & analyze meta data.',
      logo: <img src={OmnisendLogo} className={logoClassName} alt="Omnisend logo" />,
      enabled: false,
      settingsEnabled: false,
      backfillEnabled: true,
      uninstallEnabled: false,
    },
    {
      id: 'dropbox',
      channel: 'Dropbox',
      title: 'Dropbox',
      description:
        'Export your email to DropBox.  Get daily, weekly, monthly or quarterly reports automatically sent to your Dropbox account.',
      logo: <img src={DropboxLogo} className={logoClassName} alt="Dropbox logo" />,
      enabled: false,
      settingsEnabled: false,
      backfillEnabled: true,
      uninstallEnabled: false,
    },
    {
      id: 'google_drive',
      channel: 'Google Drive',
      title: 'Google Drive',
      description:
        'Export your email to Google Drive.  Get daily, weekly, monthly or quarterly reports automatically sent to your Google Drive storage.',
      logo: <img src={GoogleDriveLogo} className={logoClassName} alt="Google Drive logo" />,
      enabled: true,
      settingsEnabled: false,
      backfillEnabled: true,
      uninstallEnabled: false,
    },
    {
      id: 's3',
      channel: 'S3',
      title: 'S3',
      description:
        'Export your email to S3.  Integrate your data from S3 to your AWS jobs or services right from S3.  Get daily, weekly, monthly, or quarterly exports.',
      logo: <img src={S3Logo} className={logoClassName} alt="AWS S3 logo" />,
      enabled: false,
      settingsEnabled: false,
      backfillEnabled: true,
      uninstallEnabled: false,
    },
    {
      id: 'ftp',
      channel: 'FTP',
      title: 'FTP',
      description:
        'Export your email to SFPT.  Get daily, weekly, monthly or quarterly reports automatically sent to your SFPT directory.',
      logo: <img src={FtpLogo} className={logoClassName} alt="FTP logo" />,
      enabled: false,
      settingsEnabled: false,
      backfillEnabled: true,
      uninstallEnabled: false,
    },
    {
      id: 'upload',
      channel: 'upload',
      title: 'Manual Order Upload',
      description:
        'Upload your product costs, gateway fee, shipping costs, shipping methods, tracking info, and meta data into your custom PayHelm reports.',
      logo: <FontAwesomeIcon icon={faFileCsv} color={'#1D6F42'} size="3x" className={logoClassName} />,
      enabled: true,
      settingsEnabled: false,
      backfillEnabled: true,
      uninstallEnabled: false,
    },
    {
      id: 'google_ads',
      channel: 'Google Ads',
      title: 'Google Ads',
      description: 'Analyze your search paid advertising performance.  Know the ROAS for your Google Ads campaigns.',
      logo: <img src={GoogleAdsLogo} className={logoClassName} alt="Google Ads logo" />,
      enabled: true,
      settingsEnabled: false,
      backfillEnabled: true,
      uninstallEnabled: false,
    },
    {
      id: 'bing_ads',
      channel: 'Bing Ads',
      title: 'Bing Ads',
      description: 'Analyze your search paid advertising performance.  Know the ROAS for your Bing Ads campaigns.',
      logo: <img src={BingAdsLogo} className={logoClassName} alt="Bing Ads logo" />,
      enabled: false,
      settingsEnabled: false,
      backfillEnabled: true,
      uninstallEnabled: false,
    },
    {
      id: 'amazon_ads',
      channel: 'Amazon Ads',
      title: 'Amazon Ads',
      description:
        'Analyze your search paid advertising performance on Amazon.  Know the ROAS for your Amazon Ads campaigns and which products peform.',
      logo: <img src={AmazonAdsLogo} className={logoClassName} alt="Amazon Ads logo" />,
      enabled: false,
      settingsEnabled: false,
      backfillEnabled: true,
      uninstallEnabled: false,
    },
    {
      id: 'adroll',
      channel: 'Adroll',
      title: 'Adroll',
      description:
        'Analyze your Adroll network paid advertising performance.  Know the ROAS for your paid ad campaigns.',
      logo: <img src={AdrollLogo} className={logoClassName} alt="Adroll logo" />,
      enabled: false,
      settingsEnabled: false,
      backfillEnabled: true,
      uninstallEnabled: false,
    },
    {
      id: 'snapchat',
      channel: 'Snapchat',
      title: 'Snapchat',
      description: 'Analyze your social paid advertising performance.  Know the ROAS for your Snapchat campaigns.',
      logo: <img src={SnapchatLogo} className={logoClassName} alt="Snapchat logo" />,
      enabled: false,
      settingsEnabled: false,
      backfillEnabled: true,
      uninstallEnabled: false,
    },
    {
      id: 'pinterest',
      channel: 'Pinterest',
      title: 'Pinterest',
      description:
        'Analyze your Pinterest performance across all your channels.  Analyze product & PIN performance.  Enrich your PayHelm reports using Pinterest meta data.',
      logo: <img src={PinterestLogo} className={logoClassName} alt="Pinterest logo" />,
      enabled: false,
      settingsEnabled: false,
      backfillEnabled: true,
      uninstallEnabled: false,
    },
    {
      id: 'sendgrid',
      channel: 'Sendgrid',
      title: 'Sendgrid',
      description:
        'Connect your store data with your Sendgrid email campaigns.  Import your customers to Sendgrid and keep track of their performance.',
      logo: <img src={SendgridLogo} className={logoClassName} alt="Sendgrid logo" />,
      enabled: false,
      settingsEnabled: false,
      backfillEnabled: true,
      uninstallEnabled: false,
    },
    {
      id: 'hubspot',
      channel: 'Hubspot',
      title: 'Hubspot',
      description:
        'Connect your CRM with your detailed PayHelm business data.  Ensure your customer information is kept up-to-date and accurate.',
      logo: <img src={HubspotLogo} className={logoClassName} alt="Hubspot logo" />,
      enabled: true,
      settingsEnabled: false,
      backfillEnabled: true,
      uninstallEnabled: false,
    },
    {
      id: 'easypost',
      channel: 'Easypost',
      title: 'Easypost',
      description:
        'Enrich your PayHelm reports using your Easypost data.  Ship using PayHelm with your Easypost profile.',
      logo: <img src={EasypostLogo} className={logoClassName} alt="Easypost logo" />,
      enabled: false,
      settingsEnabled: false,
      backfillEnabled: true,
      uninstallEnabled: false,
    },
    {
      id: 'recurly',
      channel: 'Recurly',
      title: 'Recurly',
      description:
        'Connect your Recurly transaction data with your store business data in PayHelm.  Get enriched reports using Recurly meta data in PayHelm.',
      logo: <img src={RecurlyLogo} className={logoClassName} alt="Recurly logo" />,
      enabled: false,
      settingsEnabled: false,
      backfillEnabled: true,
      uninstallEnabled: false,
    },
    {
      id: 'zendesk',
      channel: 'Zendesk',
      title: 'Zendesk',
      description:
        'Connect your Zendesk helpdesk with your store business data.  Keep track of which customers are engaged and how they interact with your business.',
      logo: <img src={ZendeskLogo} className={logoClassName} alt="Zendesk logo" />,
      enabled: false,
      settingsEnabled: false,
      backfillEnabled: true,
      uninstallEnabled: false,
    },
    {
      id: 'intercom',
      channel: 'Intercom',
      title: 'Intercom',
      description:
        'Connect your Intercom customer list with your store business information.  Add meta data to your customer profiles and ensure they get detailed messaging.',
      logo: <img src={IntercomLogo} className={logoClassName} alt="Intercom logo" />,
      enabled: false,
      settingsEnabled: false,
      backfillEnabled: true,
      uninstallEnabled: false,
    },
    {
      id: 'crazyegg',
      channel: 'Crazyegg',
      title: 'Crazyegg',
      description:
        'Analyze your Crazyegg performance across all your channels.  Analyze product & customer performance.  Enrich your PayHelm reports using Crazyegg meta data.',
      logo: <img src={CrazyeggLogo} className={logoClassName} alt="Crazyegg logo" />,
      enabled: false,
      settingsEnabled: false,
      backfillEnabled: true,
      uninstallEnabled: false,
    },
    {
      id: 'optimizely',
      channel: 'Optimizely',
      title: 'Optimizely',
      description:
        'Engage your customers with accurately store data with Optimizely & PayHelm.  Analyze meta data and make accurate decisions around performance.',
      logo: <img src={OptimizelyLogo} className={logoClassName} alt="Optimizely logo" />,
      enabled: false,
      settingsEnabled: false,
      backfillEnabled: true,
      uninstallEnabled: false,
    },
    {
      id: 'wix',
      channel: 'Wix',
      title: 'Wix',
      description:
        'Integrate Wix with PayHelm to deliver real-time customer insights, sales analysis, and store performance data for better business decisions and ROI.',
      logo: <img src={WixLogo} className={logoClassName} alt="Wix logo" />,
      enabled: true,
      settingsEnabled: false,
      backfillEnabled: true,
      connectDisabled: true,
      uninstallEnabled: false,
    },
    {
      id: 'openai',
      channel: 'OpenAI',
      title: 'OpenAI',
      description: 'Enable OpenAI assistant to ask questions and get answers from your data.',
      logo: <img src={OpenAILogo} className={logoClassName} alt="OpenAI logo" />,
      enabled: true,
      settingsEnabled: false,
      backfillEnabled: false,
      uninstallEnabled: false,
    },
    {
      id: 'api_store',
      channel: 'API Store',
      title: 'API Store',
      description:
        'Sync your ecommerce platform with our platform for customer insights, sales analysis, & product performance data for informed decisions and improved ROI.',
      logo: <img src={ApiStoreLogo} className={logoClassName} alt="ApiStore logo" />,
      enabled: true,
      settingsEnabled: false,
      backfillEnabled: false,
      uninstallEnabled: false,
    },
    {
      id: 'payhelm_pixel',
      channel: 'PayHelm Pixel',
      title: 'PayHelm Pixel',
      description:
        'Forward your pixels to our management system.',
      logo: <img src={PayhelmLogo} className={logoClassName} alt="PayHelm logo" />,
      enabled: true,
      settingsEnabled: true,
      backfillEnabled: false,
      uninstallEnabled: false,
    },
    {
      id: 'smile.io',
      channel: 'Smile.io',
      title: 'Smile.io',
      description: 'Analyze your Smile.io performance across all your channels. Analyze product & customer performance.',
      logo: <img src={SmileIoLogo} className={logoClassName} alt="Smile.io logo" />,
      enabled: false,
      settingsEnabled: false,
      backfillEnabled: false,
      uninstallEnabled: false,
    },
    {
      id: 'feedonomics',
      channel: 'Feedonomics',
      title: 'Feedonomics',
      description:
        'Enrich your Feedonomics feed using PayHelm\'s rich data & analytics.  Make custom rules to automatically filter your feed based on product, customer, and traffic performance.',
      logo: <img src={FeedonomicsLogo} className={logoClassName} alt="Feedonomics logo" />,
      enabled: false,
      settingsEnabled: true,
      backfillEnabled: false,
      connectDisabled: true, // Disable connection button when connected
      type: 'feed',
      uninstallEnabled: false,
    },
    {
      id: 'datafeedwatch',
      channel: 'DataFeedWatch',
      title: 'DataFeedWatch',
      description:
      'Enrich your DataFeedWatch feed using PayHelm\'s rich data & analytics.  Make custom rules to automatically filter your feed based on product, customer, and traffic performance.',
      logo: <img src={DataFeedWatchLogo} className={logoClassName} alt="DataFeedWatch logo" />,
      enabled: false,
      settingsEnabled: true,
      backfillEnabled: false,
      connectDisabled: true,
      type: 'feed',
      uninstallEnabled: false,
    },
    {
      id: 'google_merchant_center',
      channel: 'Google Merchant Center',
      title: 'Google Merchant Center',
      description:
      'Enrich your Google Merchant Center feed using PayHelm\'s rich data & analytics.  Make custom rules to automatically filter your feed based on product, customer, and traffic performance.',
      logo: <img src={GoogleMerchantCenterLogo} className={logoClassName} alt="Google Merchant Center logo" />,
      enabled: false,
      settingsEnabled: true,
      backfillEnabled: false,
      connectDisabled: true,
      type: 'feed',
      uninstallEnabled: false,
    },
  ]

  /**
   *
   * Add keys & connected status to integrations data
   *
   */
  const allPublicKeys = useSelector(selectAllPublicKeys)
  const allPublicKeysStatus = useSelector(selectAllPublicKeysStatus)
  const allPrivateKeys = useSelector(selectAllSecrets)
  const allPrivateKeysStatus = useSelector(selectAllSecretsStatus)

  useEffect(() => {
    if (allPublicKeysStatus === 'idle') {
      dispatch(fetchAllPublicKeys({ ownerId }))
    }
  }, [allPublicKeysStatus])

  useEffect(() => {
    if (allPrivateKeysStatus === 'idle') {
      dispatch(fetchAllSecrets({ ownerId }))
    }
  }, [allPrivateKeysStatus])

  const [allKeys, setAllKeys] = useState(null)
  useEffect(() => {
    if (!allPublicKeys || !allPrivateKeys) return

    const _allKeys = zipPublicAndPrivateKeys(allPublicKeys, allPrivateKeys)
    setAllKeys(_allKeys)
  }, [allPublicKeys, allPrivateKeys])

  const connectedChannelNames = [
    ...(accounts ? accounts.map((_account) => _account.channel) : []),
    ...(allKeys && !_.isEmpty(allKeys)
      ? _.chain(allKeys)
          .map(({ keyId }) => _.find(integrationsData, ({ id }) => id === keyId.split('--')[1])?.channel)
          .uniq()
          .value()
      : []),
  ]

  const integrationsDataWithConnectedStatus = integrationsData.map((integrationData) => ({
    ...integrationData,
    ppKeys: allKeys && allKeys.filter(({ keyId }) => keyId.includes(integrationData.id)),
    connected: connectedChannelNames.includes(integrationData.channel),
    accounts: accounts.filter((_account) => _account.channel === integrationData.channel),
  }))
  const integrationIds = integrationsDataWithConnectedStatus.map((_integration) => _integration.id)
  const integrationEntities = integrationsDataWithConnectedStatus.reduce(
    (prev, _integration) => ({ ...prev, [_integration.id]: _integration }),
    {}
  )

  const integrations = {
    ids: integrationIds,
    entities: integrationEntities,
  }

  return { data: integrations }
}

export function useCategories({ integrationIds }) {
  const categoriesData = [
    {
      id: 'all',
      title: 'All',
      integrationIds,
    },
    {
      id: 'most_popular',
      title: 'Most Popular',
      integrationIds: ['bigcommerce', 'shopify', 'woocommerce', 'google_analytics', 'paypal', 'shipstation', 'etsy'],
    },
    {
      id: 'accounting',
      title: 'Accounting',
      integrationIds: ['avalara', 'quickbooks'],
    },
    {
      id: 'advertising',
      title: 'Advertising',
      integrationIds: ['facebook_ads', 'google_ads', 'bing_ads', 'amazon_ads', 'adroll', 'snapchat', 'pinterest'],
    },
    {
      id: 'analytics',
      title: 'Analytics',
      integrationIds: ['google_analytics', 'facebook_ads', 'crazyegg', 'smile.io', 'optimizely'],
    },
    {
      id: 'crm',
      title: 'CRM',
      integrationIds: ['salesforce'],
    },
    {
      id: 'customer_support',
      title: 'Customer Support',
      integrationIds: ['zendesk', 'intercom'],
    },
    {
      id: 'ecommerce',
      title: 'E-Commerce',
      integrationIds: [
        'shopify',
        'woocommerce',
        'amazon',
        'bigcommerce',
        'magento',
        'facebook_ads',
        'walmart',
        'etsy',
        'ebay',
      ],
    },
    {
      id: 'fulfillment',
      title: 'Fulfillment',
      integrationIds: ['shipstation', 'shippo', 'easypost', 'shipsurance'],
    },
    {
      id: 'inventory',
      title: 'Inventory Management',
      integrationIds: ['brightpearl'],
    },
    {
      id: 'marketing',
      title: 'Marketing',
      integrationIds: ['magento', 'mailchimp', 'omnisend', 'klaviyo', 'sendgrid', 'hubspot', 'optimizely'],
    },
    {
      id: 'payments',
      title: 'Payments',
      integrationIds: ['stripe', 'paypal', 'amazon', 'authorize_net', 'square', 'braintree', 'recurly'],
    },
    {
      id: 'software',
      title: 'Software',
      integrationIds: ['zapier'],
    },
    {
      id: 'storage',
      title: 'Storage',
      integrationIds: ['dropbox', 's3', 'google_drive', 'ftp'],
    },
    {
      id: 'tax',
      title: 'Tax',
      integrationIds: ['avalara'],
    },
  ]

  const categoryIds = categoriesData.map((_category) => _category.id)
  const categoryEntities = categoriesData.reduce((prev, _category) => ({ ...prev, [_category.id]: _category }), {})

  const categories = {
    ids: categoryIds,
    entities: categoryEntities,
  }

  return { data: categories }
}
