import React from 'react'
import { logoDict } from '../utils'

function MetricBox({ metricsSummaryData, adPlatformOptions, adPlatformWatch }) {
  return (
    <>
      {adPlatformWatch !== 'summary' ? (
        <div>
          <div className="tw-grid tw-grid-cols-1 lg:tw-grid-cols-12 tw-gap-4">
            {metricsSummaryData?.map((metric, i) => {
              const { metricName, value } = metric
              return (
                <div key={i} className="tw-col-span-1 lg:tw-col-span-4">
                  <MetricBoxComponent metric={metric} adPlatformOptions={adPlatformOptions} />
                </div>
              )
            })}
          </div>
        </div>
      ) : (
        <div>
          {Object.keys(metricsSummaryData).map((channel, i) => (
            <>
              <div className="tw-flex tw-items-center tw-mb-4">
                <img src={logoDict[channel]?.path} alt="Logo" className={logoDict[channel]?.size} />
                {logoDict[channel].title ? <h6 className='tw-mb-0'><b>{adPlatformOptions?.find(({ value }) => value === channel)?.label}</b></h6> : null}
              </div>
              <div className="tw-grid tw-grid-cols-2 lg:tw-grid-cols-12 tw-gap-4">
                {metricsSummaryData[channel]?.map((metric, i) => {
                  const { metricName, value } = metric
                  return (
                    <div key={i} className="tw-col-span-1 lg:tw-col-span-3">
                      <MetricBoxComponent metric={metric} adPlatformOptions={adPlatformOptions} />
                    </div>
                  )
                })}
              </div>
              {i < Object.keys(metricsSummaryData).length - 1 && <hr />}
            </>
          ))}
        </div>
      )}
    </>
  )
}

export default MetricBox

const MetricBoxComponent = ({ metric, adPlatformOptions }) => {
  const { title, value, channel } = metric
  return (
    <div className="tw-rounded-md tw-border tw-border-slate-200 tw-px-3 tw-py-2">
      <div className="tw-mb-4">{title}</div>
      <div className="tw-text-2xl tw-text-blue-700">{value}</div>
    </div>
  )
}
