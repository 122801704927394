import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import { Button } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import {
  fetchShipStationConfigs,
  selectShipStationConfig,
  selectShipStationConfigStatus,
  updateShipStationConfigs,
} from '../../../Settings/settingsSlice'
import MyInput from '../../../../features/forms/MyInput'
import MyLabel from '../../../../features/forms/MyLabel'
import MySelect from '../../../../features/forms/MySelect'
import { closeConnectionModal } from '../../integrationsSlice'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { shipstationStoresUrl } from 'urls'
import axios from 'axios'
import { getAxiosDefaultConfig } from 'utils'

export default function ShipstationConnectionModalContent() {
  const dispatch = useDispatch()
  const closeModal = () => dispatch(closeConnectionModal())
  const [loadModal, setLoadModal] = useState(false)
  const [loadingShipstation, setLoadingShipstation] = useState(false)
  const [shipstationError, setShipStationError] = useState(false)
  const [noStoresError, setNoStoresError] = useState(false)
  const [stores, setStores] = useState([])
  const [disabledButtons, setDisabledButtons] = useState([])
  const [hideButton, setHideButton] = useState([])
  const [isFourCols, setIsFourCols] = useState(true)

  /**
   *
   * Handle ShipStation config
   *
   */
  const shipStationConfigStatus = useSelector(selectShipStationConfigStatus)
  const shipStationConfig = useSelector(selectShipStationConfig)
  useEffect(() => {
    if (shipStationConfigStatus === 'idle') {
      dispatch(fetchShipStationConfigs())
    }

    if (shipStationConfigStatus === 'loading') {
      setLoadModal(true)
    }

    if (shipStationConfigStatus === 'succeeded') {
      setLoadModal(false)
    }
  }, [shipStationConfigStatus])

  /**
   *
   * Handle form
   *
   */
  const {
    register,
    control,
    handleSubmit,
    setValue,
    formState: { errors, isSubmitting },
    getValues,
    watch,
  } = useForm()

  const watchedShipstationConfig = watch()
  /**
   * Disable button if client_key or client_secret is empty
   */
  useEffect(() => {
    const watchedConfigs = Object.values(watchedShipstationConfig)
    const newDisableButtons = watchedConfigs.map(
      (config) => !config.shipstation_client_key || !config.shipstation_client_secret
    )
    setDisabledButtons(newDisableButtons)

    // Hide Button if initial key and secret didn't change else show button when client_key and client_secret are changed
    const newHideButton = watchedConfigs.map((config, i) => {
      if (
        shipStationConfig[i]?.shipstation_client_key === config.shipstation_client_key &&
        shipStationConfig[i]?.shipstation_client_secret === config.shipstation_client_secret
      ) {
        return true
      } else {
        setIsFourCols(false)
        return false
      }
    })

    setHideButton(newHideButton)
  }, [watchedShipstationConfig])

  const fetchStoreIds = async (index) => {
    setLoadingShipstation(true)
    setShipStationError(false)
    setNoStoresError(false)
    try {
      const key = getValues(`${index}.shipstation_client_key`)
      const secret = getValues(`${index}.shipstation_client_secret`)
      const response = await axios.post(
        shipstationStoresUrl,
        { shipStationKey: key, shipStationSecret: secret },
        getAxiosDefaultConfig()
      )
      if (response.data) {
        if (response.data?.length > 0) {
          setLoadingShipstation(false)
          setStores((prev) => {
            const newStores = [...prev]
            newStores[index] = response.data.map((store) => ({
              label: `${store.storeId} - ${store.storeName}`,
              value: store.storeId,
            }))
            return newStores
          })
        } else setNoStoresError(true)
      } else setShipStationError(true)
    } catch (error) {
      setShipStationError(true)
    }
    setLoadingShipstation(false)
  }

  // Set default form values
  useEffect(() => {
    if (_.isEmpty(shipStationConfig) && shipStationConfigStatus === 'succeeded') {
      setNoStoresError(true)
      return
    } else setNoStoresError(false)

    if (shipStationConfig.length === 0) return

    const fieldsByStore = shipStationConfig.map(
      (
        {
          channel,
          merchant_id,
          shipstation_client_key,
          shipstation_client_secret,
          shipstation_store_id,
          shipstation_stores,
        },
        i
      ) => {
        return [
          { name: `${i}.channel`, value: channel },
          { name: `${i}.merchant_id`, value: merchant_id },
          { name: `${i}.shipstation_client_key`, value: shipstation_client_key },
          { name: `${i}.shipstation_client_secret`, value: shipstation_client_secret },
          { name: `${i}.shipstation_store_id_default_value`, value: shipstation_store_id },
        ]
      }
    )

    if (fieldsByStore.length) {
      fieldsByStore?.forEach((storeFields) =>
        storeFields.forEach(({ name, value }) => {
          setValue(name, value)
        })
      )
    }
  }, [shipStationConfig, shipStationConfigStatus])

  const onSubmit = (values) => {
    const payloadValues = Object.keys(values).map((value) => ({ ...values[value] }))
    const payload = _.values(payloadValues)
    dispatch(updateShipStationConfigs(payload))
    closeModal()
  }

  return (
    <div className="tw-p-6">
      <h2 className="tw-text-xl tw-mb-6">Connect Your ShipStation Account</h2>

      {loadModal ? (
        <FontAwesomeIcon icon={faSpinner} spin />
      ) : (
        <form onSubmit={handleSubmit(onSubmit)}>
          {shipStationConfig && shipStationConfig.length > 0 && (
            <div>
              {shipStationConfig.map((storeConfig, i) => {
                const { merchant_id, shipstation_store_id, shipstation_store_name } = storeConfig

                return (
                  <div
                    key={i}
                    className={`tw-grid tw-grid-cols-1 sm:tw-grid-cols-2 ${
                      isFourCols ? 'lg:tw-grid-cols-4' : 'lg:tw-grid-cols-5'
                    } tw-gap-4 tw-mb-6`}
                  >
                    <MyInput name={`${i}.channel`} register={register} type="hidden" />

                    <div className="tw-col-span-1">
                      <MyInput
                        register={register}
                        name={`${i}.merchant_id`}
                        label="Store"
                        errors={errors}
                        disabled={true}
                      />
                    </div>
                    <div className="tw-col-span-1">
                      <MyInput
                        register={register}
                        name={`${i}.shipstation_client_key`}
                        label="Client Key"
                        errors={errors}
                        disabled={!merchant_id}
                      />
                    </div>
                    <div className="tw-col-span-1">
                      <MyInput
                        register={register}
                        name={`${i}.shipstation_client_secret`}
                        label="Client Secret"
                        type="password"
                        errors={errors}
                        disabled={!merchant_id}
                      />
                    </div>
                    <div className="tw-col-span-1">
                      {storeConfig.shipstation_stores.length > 0 ? (
                        <MySelect
                          register={register}
                          name={`${i}.shipstation_store_id`}
                          label="Store ID"
                          errors={errors}
                          options={
                            stores[i] ||
                            storeConfig.shipstation_stores?.map((store) => ({
                              label: `${store.storeId} - ${store.storeName}`,
                              value: store.storeId,
                            }))
                          }
                          defaultValue={{
                            value: shipstation_store_id,
                            name: `${shipstation_store_id} - ${shipstation_store_name}`,
                          }}
                          theme="white"
                        />
                      ) : (
                        <MySelect
                          register={register}
                          name={`${i}.shipstation_store_id`}
                          label="Store ID"
                          errors={errors}
                          theme="white"
                        />
                      )}
                    </div>
                    {hideButton[i] ? null : (
                      <div
                        className="tw-col-span-1"
                        style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end' }}
                      >
                        <Button type="button" disabled={disabledButtons[i]} onClick={() => fetchStoreIds(i)}>
                          {loadingShipstation ? <FontAwesomeIcon icon={faSpinner} spin /> : 'Get Store IDs'}
                        </Button>
                      </div>
                    )}
                  </div>
                )
              })}
            </div>
          )}

          {shipstationError && (
            <div>
              <MyLabel text={'Incorrect credentials. Please try again.'} style={{ color: 'red' }} />
            </div>
          )}

          {noStoresError && (
            <div>
              <MyLabel text={'No stores found.'} style={{ color: 'red' }} />
            </div>
          )}

          <div className="tw-flex tw-items-center tw-justify-end tw-space-x-2">
            <Button variant="secondary" onClick={closeModal}>
              Cancel
            </Button>
            <Button type="submit" disabled={isSubmitting}>
              Save Shipstation Connection
            </Button>
          </div>
        </form>
      )}
    </div>
  )
}
