import * as React from 'react'
import { cn } from '@/features/utils'

const InputWithIcon = React.forwardRef(({ className, type, startIcon, endIcon, ...props }, ref) => {
  const StartIcon = startIcon
  const EndIcon = endIcon
  return (
    <div className="tw-w-full tw-relative">
      {StartIcon && (
        <div className="tw-absolute tw-left-1.5 tw-top-1/2 tw-transform -tw-translate-y-1/2">
          <StartIcon.type className={cn('tw-h-[16px] tw-w-[16px]')} {...startIcon.props} />
        </div>
      )}
      <input
        type={type}
        className={cn(
          'tw-flex tw-h-8 tw-w-full tw-rounded tw-bg-background tw-px-3 tw-py-2 tw-text-sm placeholder:tw-text-muted-foreground disabled:tw-cursor-not-allowed disabled:tw-opacity-50',
          startIcon ? 'tw-pl-8' : '',
          endIcon ? 'tw-pr-8' : '',
          className
        )}
        ref={ref}
        {...props}
      />
      {EndIcon && (
        <div className="tw-absolute tw-right-1.5 tw-top-1/2 tw-transform -tw-translate-y-1/2">
          <EndIcon.type className={cn('tw-h-[16px] tw-w-[16px]')} {...endIcon.props} />
        </div>
      )}
    </div>
  )
})
InputWithIcon.displayName = 'InputWithIcon'

export { InputWithIcon }
