export function setLastLoginDate(date) {
  return {
    type: 'SET_LAST_LOGIN_DATE_INTERCOM',
    payload: date,
  }
}

export function setDoneAction(action) {
  return {
    type: 'SET_DONE_ACTION_INTERCOM',
    payload: action,
  }
}