import React, { useEffect, useState } from 'react'
import { useCategories, useIntegrations } from '../Integrations/hooks'
import { useDispatch, useSelector } from 'react-redux'
import { selectRootAccounts, selectRootUser } from '../../rootSlice'
import { fetchTeamAccounts, selectTeamAccounts, selectTeamAccountsStatus } from '../Settings/settingsSlice'
import { getOwnerId } from '../../modules/utils'
import ConnectionModal from '../Integrations/components/ConnectionModal'
import BackfillModal from '../Integrations/components/BackfillModal'
import SettingsModal from '../Integrations/components/SettingsModal'
import {
  closeBackfillModal,
  closeConnectionModal,
  closeCsvModal,
  closeSettingsModal,
  selectBackillModalVisible,
  selectConnectionModalVisible,
  selectCsvModalVisible,
  selectSettingsModalVisible,
} from '../Integrations/integrationsSlice'
import {
  selectIsStripeBilling
} from '../Plan/planSlice'
import ChannelItem from './ChannelItem'
import ChannelCategories from './ChannelCategories'
import UploadModal from '../../sharedComponents/UploadCSVButton/UploadModal'
import BottomMenu from './BottomMenu'
import { toast } from 'react-toastify'
import { selectBackfills } from '../../features/backfill/backfillSlice'
import InvisibleBackfill from './InvisibleBackfill'
import { selectSkipToDashboard, setSkipToDashboard } from './signupMultistepSlice'
import { withRouter } from 'react-router-dom'

function Channels({ step, setStep, history, loginFromChannel }) {
  const dispatch = useDispatch()

  /**
   *
   * Handle accounts
   *
   */
  const user = useSelector(selectRootUser)
  const rootAccounts = useSelector(selectRootAccounts)
  const accounts = rootAccounts.map((_account) => ({ ..._account, isOwner: user.email === _account.owner }))

  /**
   *
   * Fetch user and team accounts so we can get the ownerId
   *
   */
  const teamAccounts = useSelector(selectTeamAccounts)
  const teamAccountsStatus = useSelector(selectTeamAccountsStatus)
  useEffect(() => {
    if (teamAccountsStatus === 'idle') {
      dispatch(fetchTeamAccounts())
    }
  }, [teamAccountsStatus])

  const ownerId = getOwnerId(user, teamAccounts)

  /**
   *
   * Handle integrations
   *
   */
  const { data: integrations } = useIntegrations({ accounts, ownerId })
  const { data: categories } = useCategories({ integrationIds: integrations.ids })
  const [selectedCategory, setSelectedCategory] = useState(categories.entities['all'])

  const selectedCategoryIntegrations =
    selectedCategory.integrationIds &&
    selectedCategory.integrationIds.map((_id) => integrations.entities[_id]).filter(({ enabled }) => enabled === true) // filter out unimplemented integrations

  const channelOrder = [
    'Bigcommerce',
    'Shopify',
    'Woocommerce',
    'Amazon',
    'Etsy',
    'Google Ads',
    'Stripe',
    'Braintree',
    'PayPal',
    'Hubspot',
    'UPS',
    'Shipstation',
  ]
  const integrationsWithout = selectedCategoryIntegrations.filter((_integration) => {
    return !channelOrder.includes(_integration?.channel)
  })
  const integrationsWith = channelOrder
    .map((_channel) => {
      return selectedCategoryIntegrations.find(({ channel }) => channel === _channel)
    })
    .filter((_integration) => !_.isEmpty(_integration))
  const orderedIntegrations = integrationsWith.concat(integrationsWithout).sort((a, b) => (loginFromChannel && (a.channel === loginFromChannel)) ? -1 : 0)
  const connectedIntegrations = orderedIntegrations && orderedIntegrations.filter(({ connected }) => connected)
  const unconnectedIntegrations = orderedIntegrations && orderedIntegrations.filter(({ connected }) => !connected)

  /**
   *
   * Handle modals
   *
   */
  const connectionModalVisible = useSelector(selectConnectionModalVisible)
  const backfillModalVisible = useSelector(selectBackillModalVisible)
  const settingsModalVisible = useSelector(selectSettingsModalVisible)
  const csvModalVisible = useSelector(selectCsvModalVisible)

  /**
   *
   * Handle backfills
   *
   */
  const skipToDashboard = useSelector(selectSkipToDashboard)
  const rawBackfills = useSelector(selectBackfills)
  const [backfills, setBackfills] = useState([])
  const [backfillNotificationShown, setBackfillNotificationShown] = useState(false)

  useEffect(() => {
    if (!rawBackfills || _.isEmpty(rawBackfills)) return

    // Set backfills
    const backfills = rawBackfills.map((_backfill) => {
      const account = accounts.find((_account) => _account.merchant_id === _backfill.merchant_id) || {}
      return { ..._backfill, email: account.email, channel: account.channel }
    })
    setBackfills(backfills)
  }, [rawBackfills])

  useEffect(() => {
    if (!backfills || _.isEmpty(backfills)) return

    // Give users notification that it's safe to continue while their data is still being loaded
    // Only show this notification once
    if (
      step === 0 &&
      backfillNotificationShown === false &&
      backfills.filter(({ status }) => status === 'running').length > 0
    ) {
      setBackfillNotificationShown(true)
    }

    // Once enough orders are loaded, set skipToDashboard to true
    const enoughOrdersLoaded =
      backfills.filter(({ current, current_period_orders_processed }) => {
        return (current == 0 && current_period_orders_processed > 20) || current > 0
      }).length > 0
    if (enoughOrdersLoaded && !skipToDashboard) {
      dispatch(setSkipToDashboard(true))
    }
  }, [backfills, backfillNotificationShown])

  const isStripeBilling = useSelector(selectIsStripeBilling)
  /**
   *
   * Handle skip
   *
   */
  const onSkip = () => {
    if (skipToDashboard) {
      setStep(isStripeBilling ? 4 : 3) // to mark onboarding as complete
      history.push('/dashboard')
    } else {
      setStep(1)
    }
  }

  return (
    <>
      <InvisibleBackfill />
      <div className="tw-pb-36">
        <div className="tw-pt-10 tw-px-8 tw-mb-8 tw-hidden md:tw-block">
          <div className="tw-text-xl tw-font-bold tw-mb-4">Connect channels</div>
          <div className="tw-text-slate-500 tw-mb-4">
            Connecting more channels will give you better data and analytics on your business. You can always connect
            more channels later.
          </div>
        </div>

        <div className="tw-px-0 md:tw-px-8 tw-border-t tw-border-slate-200">
          {connectedIntegrations && connectedIntegrations?.length > 0 && (
            <div>
              {connectedIntegrations?.map((_integration) => {
                return (
                  <ChannelItem
                    key={_integration.id}
                    accounts={accounts}
                    integration={_integration}
                    backfills={backfills}
                  />
                )
              })}
            </div>
          )}

          {unconnectedIntegrations && unconnectedIntegrations?.length > 0 && (
            <div>
              {unconnectedIntegrations?.map((_integration) => {
                if (!_integration?.id) return null
                return (
                  <ChannelItem
                    key={_integration.id}
                    accounts={accounts}
                    integration={_integration}
                    backfills={backfills}
                  />
                )
              })}
            </div>
          )}
        </div>
      </div>

      <BottomMenu
        onNext={() => setStep(1)}
        // onSkip={onSkip}
        // skipButtonLabel={skipToDashboard ? 'Skip to dashboard' : 'Skip step'}
      />

      <ConnectionModal
        ownerId={ownerId}
        visible={connectionModalVisible}
        closeModal={() => dispatch(closeConnectionModal())}
      />
      <BackfillModal visible={backfillModalVisible} closeModal={() => dispatch(closeBackfillModal())} />
      <SettingsModal visible={settingsModalVisible} closeModal={() => dispatch(closeSettingsModal())} />
      <UploadModal open={csvModalVisible} closeModal={() => dispatch(closeCsvModal())} />
    </>
  )
}

export default withRouter(Channels)
