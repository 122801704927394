import campaignContent from 'campaignContent.json'

const viewList = ['designs', 'rules']
const viewIndex = location.pathname === '/emailcampaigns' ? viewList.indexOf((location.search.match(/[?&]view=(\w+)(&|$)/) || [null, 'designs'])[1]) : 0
const initialState = {
  viewList: viewList,
  viewIndex: viewIndex < 0 ? 0 : viewIndex,
  saveHint: null,
  modified: false,
  ...campaignContent.initialConfig,
}

export default function reducer(state=initialState, action) {
  switch(action.type) {
    case "SET_VIEW_EMAIL_CAMPAIGNS": {
      return {
        ...state,
        viewIndex: action.payload,
      }
    }
    case "SET_MODIFIED_EMAIL_CAMPAIGNS": {
      return {
        ...state,
        modified: action.payload
      }
    }
    case "SET_SAVE_HINT_EMAIL_CAMPAIGNS": {
      return {
        ...state,
        saveHint: action.payload
      }
    }
    case 'SET_CONFIG_EMAIL_CAMPAIGNS': {
      return {
        ...state,
        ...action.payload,
        dataStatus: 'load_success',
      }
    }
    case 'SET_DATA_STATUS_EMAIL_CAMPAIGNS': {
      return {
        ...state,
        dataStatus: action.payload,
      }
    }
    case 'UPDATE_TEMPLATE_DESIGN_EMAIL_CAMPAIGNS':
      return {
        ...state,
        designs: {
          ...state.designs,
          ...action.payload,
        }
      }
    case 'UPDATE_RULES_EMAIL_CAMPAIGNS':
      return {
        ...state,
        rules: {
          ...state.rules,
          ...action.payload,
        }
      }
    case 'UPDATE_UTM_EMAIL_CAMPAIGNS':
      return {
        ...state,
        utm: {
          ...state.utm,
          ...action.payload,
        }
      }
    case 'UPDATE_ACCOUNTS_EMAIL_CAMPAIGNS':
      return {
        ...state,
        settings: {
          ...state.settings,
          accounts: {
            ...state.settings.accounts,
            [action.payload.account]: {
              ...state.settings.accounts[action.payload.account],
              ...action.payload.value,
            }
          }
        }
      }
    case 'UPDATE_CONTACT_EMAIL_CAMPAIGNS':
      return {
        ...state,
        settings: {
          ...state.settings,
          contacts: {
            ...state.settings.contacts,
            ...action.payload,
          }
        }
      }
    case 'UPDATE_SOCIAL_EMAIL_CAMPAIGNS':
      return {
        ...state,
        settings: {
          ...state.settings,
          socialAccounts: {
            ...state.settings.socialAccounts,
            ...action.payload,
          }
        }
      }
  }

  return state
}