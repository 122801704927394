import moment from 'moment'
import { localStoragePayhelm } from 'modules/utils'

const initialState = {
  dates: {
    start: localStoragePayhelm?.inventoryDates?.start ? JSON.parse(localStoragePayhelm.inventoryDates).start : moment(new Date()).format('YYYY-MM-DD'),
    end: localStoragePayhelm?.inventoryDates?.end ? JSON.parse(localStoragePayhelm.inventoryDates).end : moment(new Date()).format('YYYY-MM-DD'),
  },
  inventoryLoading: false,
  productDate: 'monthly',
  productYear: moment().year()
}

export default function reducer(state = initialState, action) {
  switch(action.type) {
    case 'SET_INVENTORY_DATE': {
      return {
        ...state,
        dates: action.payload
      }
    }
    case 'SET_INVENTORY_LOADING': {
      return {
        ...state,
        inventoryLoading: action.payload
      }
    }
    case 'SET_PRODUCT_DATE': {
      return {
        ...state,
        productDate: action.payload
      }
    }
    case 'SET_PRODUCT_YEAR': {
      return {
        ...state,
        productYear: action.payload
      }
    }
  }
  return state
}