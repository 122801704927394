// export const hcColors = [
//   '#2f7ed8', '#0d233a', '#8bbc21', '#910000', '#1aadce', '#492970', '#f28f43', '#77a1e5', '#c42525', '#a6c96a',
//   '#4572A7', '#AA4643', '#89A54E', '#80699B', '#3D96AE', '#DB843D', '#92A8CD', '#A47D7C', '#B5CA92',
//   "#7cb5ec", "#434348", "#90ed7d", "#f7a35c", "#8085e9", "#f15c80", "#e4d354", "#2b908f", "#f45b5b", "#91e8e1",
// ]

// Distinguished version 20241028
export const hcColors = [
  '#2f7ed8', '#0d233a', '#1b7a00', '#910000', '#1aadce', '#8a33ff', '#f28f43', '#77a1e5', '#ff33a3', '#a6c96a',
  '#C133FF', '#FF3333', '#89A54E', '#80699B', '#3D96AE', '#DB843D', '#92A8CD', '#A47D7C', '#B5CA92',
  "#7cb5ec", "#434348", "#90ed7d", "#f7a35c", "#8085e9", "#f15c80", "#e4d354", "#2b908f", "#f45b5b", "#91e8e1",
]

export const hcDefaultColors = [
  '#058DC7', '#ED561B', '#50B432', '#DDDF00', '#24CBE5', '#FF9655', '#50B432', '#FFF263', '#6AF9C4', // hc given example: https://jsfiddle.net/gh/get/library/pure/highcharts/highcharts/tree/master/samples/highcharts/chart/colors/
  '#4572A7', '#AA4643', '#89A54E', '#80699B', '#3D96AE', '#DB843D', '#92A8CD', '#A47D7C', '#B5CA92', // hc 2.x defaults
]

export const hcFontFamily = 'Nunito'