import React from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileCsv } from "@fortawesome/free-solid-svg-icons";
import ProgressBar from 'react-bootstrap/ProgressBar'

function CsvProgress({ fileName, fileSize, loading, progress }) {
  return (
    <div className='tw-h-24 tw-bg-slate-100 tw-mt-3 tw-rounded-md tw-p-2 tw-flex tw-justify-around tw-flex-col'>
      <div className='tw-flex tw-items-center tw-justify-between'>
        <div className='tw-flex tw-h-full'>
          <FontAwesomeIcon icon={faFileCsv} color={'#1D6F42'} size='3x' />
          <div className='tw-flex tw-flex-col tw-text-xs tw-text-gray-500 tw-h-full tw-justify-between tw-ml-3'>
            <div className='tw-font-bold'>{fileName}</div>
            <div>{fileSize}KB</div>
          </div>
        </div>
        <div className='tw-text-xs tw-text-gray-500 tw-self-end'>{progress || 100}%</div>
      </div>
      <ProgressBar now={progress} variant='success' style={{ height: '10px' }} />
    </div>
  )
}

export default CsvProgress