import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import {
  addTeamAccount,
  fetchTeamAccounts,
  selectTeamAccounts,
  selectTeamAccountsStatus,
} from '../Settings/settingsSlice'
import {
  selectIsStripeBilling
} from '../Plan/planSlice'
import MyInput from '../../features/forms/MyInput'
import { Button } from 'react-bootstrap'
import BottomMenu from './BottomMenu'
import TeamAccountsTable from '../Settings/views/SharedAccounts/components/TeamAccountsTable'

export default function Invites({ history, setStep, setOnboardSkipped }) {
  const dispatch = useDispatch()

  const isStripeBilling = useSelector(selectIsStripeBilling)
  /**
   *
   * Fetch team accounts
   *
   */
  const teamAccounts = useSelector(selectTeamAccounts)
  const teamAccountsStatus = useSelector(selectTeamAccountsStatus)
  useEffect(() => {
    dispatch(fetchTeamAccounts())
  }, [])

  /**
   *
   * Handle form
   *
   */
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm()

  const onSubmit = async (values) => {
    await dispatch(addTeamAccount(values.email))

    setStep(2)
  }

  const onSkip = () => {
    setStep(3)
    setOnboardSkipped(true)
    history.push('/dashboard')
  }

  return (
    <>
      <div className="tw-min-h-screen tw-pt-10 tw-pb-10 tw-border-t tw-border-slate-200">
        <div className="tw-px-4 md:tw-px-8">
          <div className="tw-hidden md:tw-block">
            <div className="tw-text-xl tw-font-bold tw-mb-4">Invite team members</div>
            <div className="tw-text-slate-500 tw-mb-4">
              Invite team members to PayHelm. Or skip this step and invite team members later.
            </div>
          </div>

          <form onSubmit={handleSubmit(onSubmit)}>
            <MyInput
              name="email"
              register={register}
              rules={{
                required: 'Email Address is required',
                pattern: {
                  value: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                  message: 'Invalid email',
                },
              }}
              placeholder="hello@work-email.com"
              label="Email to invite"
              errors={errors}
            />

            <Button type="submit" disabled={isSubmitting} className="tw-mt-4">
              Invite
            </Button>
          </form>

          {teamAccounts && teamAccounts.length > 0 && (
            <div className="tw-mt-8">
              <h5 className="tw-mb-4 tw-font-bold">Team Members</h5>
              <TeamAccountsTable accounts={teamAccounts} />
            </div>
          )}
        </div>
      </div>
      <BottomMenu onPrev={() => setStep(0)} onNext={() => setStep(2)} />  
    </>
  )
}
