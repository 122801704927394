import { createSlice } from '@reduxjs/toolkit'
import _ from 'lodash'

export const initialState = {
  skipToDashboard: false,
}

export const signupMultistepSlice = createSlice({
  name: 'signupMultistep',
  initialState,
  reducers: {
    setSkipToDashboard: (state, action) => {
      state.skipToDashboard = action.payload
    },
  },
})

export const { setSkipToDashboard } = signupMultistepSlice.actions

/**
 *
 * Selectors
 *
 */
export const selectSkipToDashboard = (state) => state.signupMultistep.skipToDashboard

export default signupMultistepSlice.reducer
