import React from 'react'
import Button from './BottomMenuButton'
import { FiArrowLeft, FiArrowRight, FiSkipForward } from 'react-icons/fi'

export default function BottomMenu({ onPrev, onNext, submitOnNext = false, onSkip, skipButtonLabel = 'Skip' }) {
  return (
    <div className="tw-absolute tw-bottom-0 tw-left-0 tw-right-0 md:tw-p-6">
      <div className="md:tw-rounded-xl md:tw-shadow-xl tw-border-t tw-border-slate-300 md:tw-border-none tw-p-4 md:tw-p-6 tw-backdrop-blur-md tw-bg-white/30">
        <div className="tw-flex tw-justify-between tw-items-center">
          <div>
            {onPrev && (
              <Button prefixIcon={<FiArrowLeft />} onClick={onPrev}>
                Previous
              </Button>
            )}
          </div>
          <div className="tw-flex tw-items-center tw-space-x-3">
            {onSkip && (
              <Button suffixIcon={<FiSkipForward />} kind="outline" onClick={onSkip}>
                {skipButtonLabel}
              </Button>
            )}
            {(onNext || submitOnNext === true) && (
              <Button type={submitOnNext ? 'submit' : 'button'} suffixIcon={<FiArrowRight />} onClick={onNext}>
                Next
              </Button>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
