import React from 'react'
import { Modal, Button } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'
import { connect } from "react-redux"
import { planOptions } from 'applications/Plan/planConfig'
import { changeApplication } from 'actions/rootActions'
import { setRedirectedAction, setUpgradeDismissed } from 'actions/planActions'
import { setDoneAction } from 'actions/intercomActions'
import { DEFAULT_APP_NAME } from 'modules/constants'
import { withRouter } from 'react-router-dom'

@connect((store) => ({
  currentApp: store.root.applicationId,
  previousApplicationId: store.root.previousApplicationId,
  subscription: store.plan.subscription,
  mimicStatus: store.mimic.mimic.status,
  loginFromChannel: store.root.loginFromChannel,
}), {
  changeApplication,
  setRedirectedAction,
  setUpgradeDismissed,
  setDoneAction,
})

class ActionModal extends React.Component {

  state = {
    action: null,
  }

  getPlanAction = (subscription, isLite, subscriptionActive) => {
    const { plan_status: planStatus = {}, plans: currentPlans, status } = subscription
    const planOptionConfigs = planOptions(this.props.loginFromChannel)
    const currentPlan = planOptionConfigs.find(option => option.product === currentPlans[0].product)
    const nextPlan = planOptionConfigs.find(option => option.product === planStatus.nextPlan)

    if (planStatus.upgradeRequired || isLite) {
      // Skip trialing users
      if (currentPlan.product === 'Free' && planStatus.trialDaysLeft > 0) {
        return null
      }
      const upgradeMessage = planStatus.upgradeRequired ? 
        <p>{`As of now, you've gone over your limit of ${currentPlan.limitOnModal}.`}</p> :
        (
          <>
            <p>Your data holds the key to smarter decisions and bigger growth—don’t let limits hold you back. Upgrade to Professional and gain the tools to maximize revenue, streamline reporting, and uncover new opportunities.</p>
            <p>Take full control of your data—upgrade today and turn insights into action!</p>
          </>
        )
      return {
        ...((nextPlan.product === 'Enterprise' && !planStatus.enterprisePrice) ? {
          targetUrl: 'https://www.payhelm.com/contact/',
          ctaText: 'Contact Us',
        } : {
          targetApp: 'plan',
          targetViewIndex: 0,
          planOption: { 
            priceMonth: (planStatus.enterprisePrice || 0),
            priceYear: Math.round((planStatus.enterprisePrice || 0) * 9.6),
            priceTwoYear: Math.round((planStatus.enterprisePrice || 0) * 18),
            priceThreeYear: Math.round((planStatus.enterprisePrice || 0) * 25.2),
            ...nextPlan, 
          },
          ctaText: 'Subscribe Now',
        }),
        message: (
          <div className={nextPlan.product}>
            {upgradeMessage}
            <p>{`Upgrade to `}<b className="pricing-text">{`${nextPlan.product}`}</b>{` in order to unlock our advanced features:`}</p>
            {
              nextPlan.description.map((line, lineIndex) => {
                return (
                  <div className="pricing-description" key={`line-${lineIndex}`}>
                    {
                      line.map((words, wordsIndex) => (
                        <span key={`line-${lineIndex}-${wordsIndex}`}>
                          { 
                            words.startsWith('b_') ? <b>{words.substr(2)}</b> : words
                          }
                        </span>
                      ))
                    }
                  </div>
                )
              })
            }
          </div>
        ),
        pageTitle: `Thank you for trying PayHelm > Upgrade to ${nextPlan.product}`,
        modalTitle: planStatus.upgradeRequired ? 'Thank you for trying PayHelm' : 'Unlock Powerful Insights & Scale Faster',
      }
    }
    if (!subscriptionActive) {
      const statusMessage = status === 'active' ? 
        `Please kindly notice that your trial has expired.` :
        `Please kindly notice that your subscription is suspended.`
      return {
        ...((nextPlan.product === 'Enterprise' && !planStatus.enterprisePrice) ? {
          targetUrl: 'https://www.payhelm.com/contact/',
          ctaText: 'Contact Us',
        } : {
          targetApp: 'plan',
          targetViewIndex: 0,
          planOption: nextPlan,
          ctaText: 'Subscribe Now',
        }),
        message: (
          <div>
            <p>{statusMessage}</p>
            <p>{`Set your plan/payment options to keep getting updated metrics/charts.`}</p>
          </div>
        ),
        pageTitle: 'Thank you for trying PayHelm > Set Plan/Payments',
        modalTitle: 'Thank you for trying PayHelm',
      }
    }
    if (planStatus.creditCardRequired) {
      return {
        targetApp: 'plan',
        targetViewIndex: 1,
        planOption: nextPlan,
        message: (
          <div>
            <p>{`Please kindly notice that your payment options are currently missing.`}</p>
            <p>{`Set them to keep getting updated metrics/charts.`}</p>
          </div>
        ),
        ctaText: 'Update Now',
        pageTitle: 'Thank you for using PayHelm > Set Payments',
        modalTitle: 'Thank you for using PayHelm',
      }
    }
    return null
  }

  componentDidUpdate(prevProps) {
    const { plan_status: prevPlanStatus = {} } = prevProps.subscription
    const { plan_status: planStatus = {} } = this.props.subscription
    if (
      prevPlanStatus.upgradeRequired !== planStatus.upgradeRequired ||
      prevPlanStatus.trialExpired !== planStatus.trialExpired ||
      prevPlanStatus.creditCardRequired !== planStatus.creditCardRequired
    ) {
      const action = this.getPlanAction(this.props.subscription, this.props.isLite, this.props.subscriptionActive)
      this.setState({ action })
    }
  }

  componentDidMount() {
    const { subscription, isLite } = this.props
    if (subscription) {
      const action = this.getPlanAction(subscription, isLite, this.props.subscriptionActive)
      this.setState({ action })
    }
  }

  render() {
    const { 
      currentApp, previousApplicationId, changeApplication,
      setRedirectedAction, setUpgradeDismissed, setDoneAction, 
      mimicStatus, show = false, backdropClassName = '', allowContinue = false, continueTo, onClose
    } = this.props
    const { action } = this.state
    if (!action || !show) return null
    return (
      <Modal 
        show={true} 
        backdropClassName={backdropClassName} 
        onHide={() => {
          setDoneAction({ type: 'upgrade_dimissed' })
          if (!allowContinue) {
            changeApplication(currentApp, previousApplicationId)
            const { history } = this.props
            history.push(`/${previousApplicationId || 'dashboard'}`)
          }
          if (onClose) {
            onClose()
          }
        }}
        className="plan-modal"
      >
        <title>{`${action.pageTitle} - ${DEFAULT_APP_NAME} Analytics & Intelligence`}</title>
        <Modal.Header closeButton>
          <Modal.Title>{action.modalTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {action.message}
        </Modal.Body>
        <Modal.Footer>
          <div className="left">
            {
              (mimicStatus || allowContinue) ? (
                <div className="inline-block mr1">
                  <Button
                    onClick={() => {
                      setUpgradeDismissed(true)
                      const { history, location } = this.props
                      if (!mimicStatus) {
                        setDoneAction({ type: 'upgrade_dimissed' })
                      }
                      else if (location.pathname === '/action') {
                        history.replace(`/dashboard`)
                      }
                      else {
                        const currentPath = `${location.pathname}${location.search}`
                        history.replace(`/action`)
                        setTimeout(() => {
                          history.replace(currentPath)
                        }, 0)
                      }
                      if (onClose) {
                        onClose()
                      }
                  }}>
                    {'Continue'}
                  </Button>
                </div>
              ) : null
            }
            <div className="float-right">
              <LinkContainer to={action.targetApp ? '/plan' : '/action'}>
                <Button
                  variant="primary"
                  onClick={() => {
                    if (action.targetApp) {
                      changeApplication(currentApp, action.targetApp)
                      setRedirectedAction(action)
                    }
                    else if (action.targetUrl) {
                      window.open(action.targetUrl, '_blank')
                      return
                    }
                    if (onClose) {
                      onClose()
                    }
                }}>
                  {action.ctaText}
                </Button>
              </LinkContainer>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    )
  }
}

export default withRouter(ActionModal)