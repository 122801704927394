import React, { useState, useEffect } from 'react'
import MyLabel from './MyLabel'
import { FiEye, FiEyeOff } from 'react-icons/fi'

export default function MyInput({
  register,
  name,
  errors,
  rules = {},
  label,
  labelSuffix,
  type = 'text',
  placeholder,
  prefix,
  fieldClassName = '',
  disabled = false,
  icon = false,
  onChange,
  tooltip = false,
}) {

  const [showPrivateText, setShowPrivateText] = useState(false)
  const [showTooltip, setShowTooltip] = useState(false)
  const isCheckbox = type === 'checkbox'

  return (
    <div className={type ==='hidden' ? 'tw-absolute tw-hidden' : 'tw-w-full'}>
      <MyLabel 
        text={label} 
        textSuffix={labelSuffix} 
        style={isCheckbox ? {display: 'inline-block'} : null}
        wrapperStyle={isCheckbox ? {display: 'flex', alignItems: 'center'} : null}
      >
        <>
          <div 
            className="tw-flex tw-items-center" 
            onMouseEnter={() => setShowTooltip(true)} onMouseLeave={() => setShowTooltip(false)}
            style={isCheckbox ? {display: 'inline-block'} : null}
          >
            {prefix && <div>{prefix}</div>}
            <input
              className={`field ${disabled ? '!tw-bg-gray-100 !tw-text-gray-500' : ''} ${fieldClassName}`}
              {...register(name, rules)}
              placeholder={placeholder ? placeholder : undefined}
              disabled={disabled}
              type={icon && type === 'password' && showPrivateText ? 'text' : type}
              style={isCheckbox ? {boxShadow: 'none', marginLeft: 5} : null}
              onChange={onChange}
            />
            {icon && <span className='tw-absolute tw-right-12 tw-cursor-pointer' onClick={() => setShowPrivateText(!showPrivateText)}>{showPrivateText ? <FiEyeOff /> : <FiEye />}</span>}
          </div>
          {(showTooltip && tooltip) && (
            <div className="tw-text-xs tw-text-gray-600 tw-font-medium tw-mt-1 tw-absolute tw-top-6 tw-bg-gray-200 tw-rounded tw-p-2">
              {tooltip}
            </div>
          )}
          {errors?.[name] && (
            <div className="tw-text-xs tw-text-red-600 tw-font-medium tw-mt-1">{errors[name]?.message}</div>
          )}
        </>
      </MyLabel>
    </div>
  )
}
