import React, { useEffect } from 'react'
import InsurancePreferences from './InsurancePreferences'
import GeneralPreferences from './GeneralPreferences'
import {
  fetchPreferences,
  selectPreferences,
  selectPreferencesStatus,
  updatePreferences,
} from '../Settings/settingsSlice'
import {
  selectIsStripeBilling
} from '../Plan/planSlice'
import { toast } from 'react-toastify'
import { updateUserPreferencesRoot } from '../../actions/rootActions'
import { useFieldArray, useForm } from 'react-hook-form'
import { connect, useDispatch, useSelector } from 'react-redux'
import BottomMenu from './BottomMenu'
import { DEFAULT_AUTO_INSURANCE_RULES } from '../Shipping/ShippingConfigModal/constants'
import { withRouter } from 'react-router-dom'

function Preferences({ history, setStep }) {
  const dispatch = useDispatch()

  const isStripeBilling = useSelector(selectIsStripeBilling)

  /**
   *
   * Fetch preferences
   *
   */
  const preferences = useSelector(selectPreferences)
  useEffect(() => {
    dispatch(fetchPreferences())
  }, [])

  /**
   *
   * Set general preferences default values (async)
   *
   */
  useEffect(() => {
    if (!preferences) return

    setValue('defaultCurrency', preferences.defaultCurrency)
    setValue('defaultTimezone', preferences.defaultTimezone)
    setValue('autoFilterDefaultCurrency', preferences?.currencySettings?.auto_filter_default_currency || null)
  }, [preferences])

  /**
   *
   * Set insurance preferences default values (async)
   *
   */
  useEffect(() => {
    if (!preferences?.shippingSettings) return

    if (preferences.shippingSettings.autoInsure) {
      setValue('autoInsure', preferences.shippingSettings.autoInsure)
    }

    if (preferences.shippingSettings.autoInsuranceRules) {
      setValue('autoInsuranceRules', preferences.shippingSettings.autoInsuranceRules)
    }
  }, [preferences])

  /**
   *
   * Handle form setup
   *
   */
  const {
    register,
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {
      defaultCurrency: 'USD',
      defaultTimezone: null,
      autoFilterDefaultCurrency: false,
      autoInsure: false,
      autoInsuranceRules: DEFAULT_AUTO_INSURANCE_RULES,
    },
  })
  const { fields: autoInsuranceRules } = useFieldArray({ name: 'autoInsuranceRules', control })

  /**
   *
   * Handle form submit
   *
   */
  const onSubmit = async (values) => {
    try {
      const { defaultCurrency, defaultTimezone, autoFilterDefaultCurrency, autoInsure, autoInsuranceRules } = values

      const preferencesPayload = {
        ...preferences,
        defaultCurrency,
        defaultTimezone,
        currencySettings: {
          auto_filter_default_currency: autoFilterDefaultCurrency,
        },
        shippingSettings: {
          ...preferences.shippingSettings,
          autoInsure,
          autoInsuranceRules,
        },
      }

      await dispatch(updatePreferences(preferencesPayload))

      // Manually update the root user state
      dispatch(updateUserPreferencesRoot(preferencesPayload))

      toast.success('Preferences successfully saved!')

      setStep(3)

      if (!isStripeBilling) {
        history.push('/dashboard')
      }
    } catch (err) {
      console.log('err in onSubmit', err)
      toast.error('Something went wrong while updating your preferences - please try again later or contact support')
    }
  }

  const onSkip = () => {
    setStep(3)

    history.push('/dashboard')
  }

  return (
    <div className="tw-min-h-screen">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div>
          <GeneralPreferences formObj={{ register, errors }} />
          <InsurancePreferences preferences={preferences} formObj={{ register, errors, autoInsuranceRules }} />
        </div>
        {
          isStripeBilling ? (
            <BottomMenu onPrev={() => setStep(1)} onSkip={() => setStep(3)} onNext={() => setStep(3)} />
          ) : (
            <BottomMenu onPrev={() => setStep(1)} onSkip={onSkip} submitOnNext={true} />
          )
        }
      </form>
    </div>
  )
}

export default withRouter(Preferences)
