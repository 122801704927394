import { preferencesUrl } from 'urls'
import { getAxiosDefaultConfig } from 'utils'
import axios from 'axios'

export function updateSearchResults(data) {
  return {
    type: 'UPDATE_SEARCH_RESULTS_DASHBOARD',
    payload: data,
  }
}

export function updateFetchStatus(fetching) {
  return {
    type: 'UPDATE_FETCH_STATUS_DASHBOARD',
    payload: fetching,
  }
}

export function updateDataPending(dataPending) {
  return {
    type: 'UPDATE_DATA_PENDING_DASHBOARD',
    payload: dataPending,
  }
}

export function resetDataPending() {
  return {
    type: 'RESET_DATA_PENDING_DASHBOARD',
  }
}

export function updateFilter(filter, hard) {
  return {
    type: 'UPDATE_FILTER_DASHBOARD',
    payload: filter,
    hard: hard,
  }
}

export function updateFilterValue(key, value) {
  return {
    type: 'UPDATE_FILTER_VALUE_DASHBOARD',
    payload: {
      [key]: value,
    },
  }
}

export function replaceFilterValue(key, value, newValue) {
  return {
    type: 'REPLACE_FILTER_VALUE_DASHBOARD',
    payload: {
      [key]: value,
      newValue,
    },
  }
}

export function updateFilterLogicOperator(operator) {
  return {
    type: 'UPDATE_FILTER_LOGIC_OPERATOR_DASHBOARD',
    payload: operator,
  }
}

export function resetFilter(applyAdditionalSettings = false) {
  return (dispatch, getState) => {
    const {
      default_currency: defaultCurrency,
      currencysettings: currencySettings,
    } = getState().root.user
    const payload = {}
    if (applyAdditionalSettings) {
      if (currencySettings?.auto_filter_default_currency) {
        payload.currency = [defaultCurrency]
      }
    }
    dispatch({
      type: 'RESET_FILTER_DASHBOARD',
      payload,
    })
  }
}

export function resetShippingFilter(filter) {
  return (dispatch, getState) => {
    const payload = { fulfillment_status: [filter] }
    dispatch({
      type: 'RESET_FILTER_DASHBOARD',
      payload,
    })
  }
}

export function toggleFilter() {
  return {
    type: 'TOGGLE_FILTER_DASHBOARD',
  }
}

export function setExpandedCategoryTree(expanded) {
  return {
    type: 'UPDATE_FILTER_EXPAND_CATEGORY_TREE_DASHBOARD',
    payload: expanded,
  }
}

export function setDataPage(dataType, page) {
  return {
    type: 'SET_DATA_PAGE_DASHBOARD',
    payload: { dataType, page },
  }
}

export function setDataPerPage(dataType, perPage) {
  return {
    type: 'SET_DATA_PER_PAGE_DASHBOARD',
    payload: { dataType, perPage },
  }
}

export function setDataSortOrder(dataType, sortOrder) {
  return {
    type: 'SET_DATA_SORT_ORDER_DASHBOARD',
    payload: { dataType, sortOrder },
  }
}

export function setView(viewIndex) {
  return {
    type: 'SET_VIEW_DASHBOARD',
    payload: viewIndex,
  }
}

// For URL use
export function setReport(report) {
  return {
    type: 'SET_REPORT_DASHBOARD',
    payload: report,
  }
}

// soft flag: if it's set by clicks on filter buttons (not auto apply currency settings in such cases)
export function setForceSearch(flag, soft) {
  return {
    type: 'SET_FORCE_SEARCH_DASHBOARD',
    payload: { flag, soft },
  }
}

export function setMapSortOrder(sortOrder) {
  return {
    type: 'SET_MAP_SORT_ORDER_DASHBOARD',
    payload: sortOrder,
  }
}

export function setMapDrillDown(country) {
  return {
    type: 'SET_MAP_DRILL_DOWN_DASHBOARD',
    payload: country,
  }
}

// For config modal use
export function setReportKey(key) {
  return {
    type: 'SET_REPORT_KEY_DASHBOARD',
    payload: key,
  }
}

export function setTrendsGroupBy(groupBy) {
  return {
    type: 'SET_TRENDS_GROUP_BY_DASHBOARD',
    payload: groupBy,
  }
}

export function setTrendKeys(trendKeys) {
  return {
    type: 'SET_TREND_KEYS_DASHBOARD',
    payload: trendKeys,
  }
}

export function setConfigModalOpen(flag) {
  return {
    type: 'SET_CONFIG_MODAL_OPEN_DASHBOARD',
    payload: flag,
  }
}

// Full search
export function setFullSearchStatus(status) {
  return {
    type: 'SET_FULL_SEARCH_STATUS_DASHBOARD',
    payload: status,
  }
}

export const saveConfig = (config, forceSearch = true) => {
  return async (dispatch, getState) => {
    const { email } = getState().root.user
    try {
      dispatch({ type: 'SET_SAVING_CONFIG_DASHBOARD', payload: true })
      await axios.post(`${preferencesUrl}/dashboard_config`, { email, config }, getAxiosDefaultConfig())
      dispatch({ type: 'SET_SAVING_CONFIG_DASHBOARD', payload: false })
      dispatch(applyConfigSortOrders(config.columns))
      dispatch(setConfigModalOpen(false))
      dispatch({ type: 'UPDATE_USER_ROOT', payload: { dashboardconfig: config }  })
      if (forceSearch) {
        dispatch(resetDataPending())
        dispatch(setForceSearch(true))
      }
    }
    catch (error) {
      console.error(error)
    }
  }
}

export const applyConfigSortOrders = columnConfigs => {
  return dispatch => {
    for (var reportKey in (columnConfigs || {})) {
      const nextSortOrder = Object.keys(columnConfigs[reportKey]).reduce((acc, colKey) => {
        const sortOrder = columnConfigs[reportKey][colKey].sort
        return acc || (sortOrder && { sortBy: colKey, sortAsc: sortOrder === 'asc' })
      }, null)
      dispatch(setDataSortOrder(reportKey, nextSortOrder))
    }
  }
}

export function setProgressBar(payload) {
  return {
    type: 'SET_PROGRESS_BAR_DASHBOARD',
    payload: payload,
  }
}
