const viewList = ['options', 'add-ons', 'billing', 'invoices', 'paymentHistory']
const defaultDepositAmount = 25
const viewIndex = location.pathname === '/plan' ? viewList.indexOf((location.search.match(/[?&]view=([\w\-]+)(&|$)/) || [null, 'options'])[1]) : 0
const initialState = {
  viewList: viewList,
  viewIndex: viewIndex < 0 ? 0 : viewIndex,
  stripeLoaded: false,
  paymentModalOn: false,
  paymentModalType: 'creditCard',
  loadingView: '',
  selectedCreditCard: null,
  selectedBillingAddress: null,
  selectedPlanOption: null,
  nextModalBody: '',
  creditCards: [],
  subscription: null,
  billingChannel: null,
  monthlySales: 0,
  billingMessage: { text: '', type: '' },
  billingError: null,
  redirectedAction: null,
  subscriptionActive: true,
  planExpired: false,
  newPaidCustomer: null,
  upgradeDismissed: false,
  customerBalance: null,
  customerBalanceTransactions: [],
  customerBalanceTransactionsCount: 0,
  customerBalanceLoad: false,
  customerTotalDebits: 0,
  customerTotalCredits: 0,
  autoDepositAmount: defaultDepositAmount,
  balanceFetching: false,
  addOns: [],
  addOnPrices: {},
  selectedAddOn: null,
  selectedType: null,
  addOnUpgradeDismissed: {},
}

export default function reducer(state = initialState, action) {
  switch(action.type) {
    case "SET_VIEW_PLAN": {
      return {
        ...state,
        viewIndex: action.payload,
      }
    }
    case "SET_STRIPE_LOADED_PLAN": {
      return {
        ...state,
        stripeLoaded: action.payload
      }
    }
    case "TOGGLE_PAYMENT_MODAL_PLAN": {
      return {
        ...state,
        paymentModalOn: action.payload.on,
        paymentModalType: action.payload.type
      }
    }
    case "SET_UPGRADE_DISMISSED_PLAN": {
      return {
        ...state,
        upgradeDismissed: action.payload,
      }
    }
    case "SET_ADD_ON_UPGRADE_DISMISSED_PLAN": {
      return {
        ...state,
        addOnUpgradeDismissed: {
          ...state.addOnUpgradeDismissed,
          ...action.payload,
        },
      }
    }
    case "SET_LOADING_VIEW_PLAN": {
      return {
        ...state,
        loadingView: action.payload
      }
    }
    case "SELECT_CREDIT_CARD_PLAN": {
      return {
        ...state,
        selectedCreditCard: action.payload
      }
    }
    case "SELECT_BILLING_ADDRESS_PLAN": {
      return {
        ...state,
        selectedBillingAddress: action.payload
      }
    }
    case "SELECT_PLAN_OPTION_PLAN": {
      return {
        ...state,
        selectedPlanOption: action.payload,
        selectedType: 'plan',
      }
    }
    case "SELECT_ADD_ON_PLAN": {
      return {
        ...state,
        selectedAddOn: action.payload,
        selectedType: 'add-on',
      }
    }
    case "SET_NEXT_MODAL_BODY_PLAN": {
      return {
        ...state,
        nextModalBody: action.payload
      }
    }
    case "SET_CREDIT_CARDS_PLAN": {
      return {
        ...state,
        creditCards: action.payload
      }
    }
    case "SET_SUBSCRIPTION_PLAN": {
      return {
        ...state,
        subscription: action.payload
      }
    }
    case "SET_ADD_ONS_PLAN": {
      return {
        ...state,
        addOns: action.payload
      }
    }
    case "SET_ADD_ON_PRICES_PLAN": {
      return {
        ...state,
        addOnPrices: action.payload
      }
    }
    case "SET_BILLING_CHANNEL_PLAN": {
      return {
        ...state,
        billingChannel: action.payload
      }
    }
    case "SET_CUSTOMER_BALANCE_PLAN": {
      return {
        ...state,
        customerBalance: action.payload
      }
    }
    case "SET_CUSTOMER_BALANCE_TRANSACTION_PLAN": {
      return {
        ...state,
        customerBalanceTransactions: action.payload
      }
    }
    case "SET_CUSTOMER_BALANCE_TRANSACTION_COUNT_PLAN": {
      return {
        ...state,
        customerBalanceTransactionsCount: action.payload
      }
    }
    case "SET_MONTHLY_SALES_PLAN": {
      return {
        ...state,
        monthlySales: action.payload
      }
    }
    case "SET_BILLING_MESSAGE_PLAN": {
      return {
        ...state,
        billingMessage: action.payload
      }
    }
    case "SET_BILLING_ERROR_PLAN": {
      return {
        ...state,
        billingError: action.payload
      }
    }
    case "SET_REDIRECT_ACTION_PLAN": {
      return {
        ...state,
        redirectedAction: action.payload
      }
    }
    case "SET_SUBSCRIPTION_ACTIVE_PLAN": {
      return {
        ...state,
        subscriptionActive: action.payload
      }
    }
    case "SET_PLAN_EXPIRED_PLAN": {
      return {
        ...state,
        planExpired: action.payload
      }
    }
    case "SET_PLAN_NEW_PAID_CUSTOMER": {
      return {
        ...state,
        newPaidCustomer: action.payload
      }
    }
    case "GET_STRIPE_INVOICES": {
      return {
        ...state,
        stripeInvoices: action.payload
      }
    }
    case "SET_CUSTOMER_BALANCE_LOAD": {
      return {
        ...state,
        customerBalanceLoad: action.payload
      }
    }
    case "SET_CUSTOMER_BALANCE_CREDITS": {
      return {
        ...state,
        customerTotalCredits: action.payload
      }
    }
    case "SET_CUSTOMER_BALANCE_DEBITS": {
      return {
        ...state,
        customerTotalDebits: action.payload
      }
    }
    case "SET_AUTO_DEPOSIT_AMOUNT_SETTING_PLAN": {
      return {
        ...state,
        autoDepositAmount: action.payload
      }
    }
    case "SET_BALANCE_FETCHING_PLAN": {
      return {
        ...state,
        balanceFetching: action.payload
      }
    }
  }
  return state
}