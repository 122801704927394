import React from 'react'

export default function BackfillButton({ onClick }) {
  return (
    <div
      className="tw-w-fit tw-cursor-pointer tw-rounded-md tw-border tw-border-slate-300 hover:tw-border-blue-600 hover:tw-bg-blue-600 hover:tw-text-white tw-transition-colors tw-duration-150 tw-bg-white tw-shadow-sm tw-flex tw-items-center tw-justify-center tw-space-x-2.5 tw-px-2.5 tw-py-1"
      onClick={onClick}
    >
      <div>Backfill</div>
    </div>
  )
}
