module.exports = {
	"USD": [
		"en-US"
	],
	"CAD": [
		"en-CA",
		"fr-CA"
	],
	"AUD": [
		"en-AU"
	],
	"NZD": [
		"en-NZ"
	],
	"GBP": [
		"en-GB"
	],
	"EUR": [
		"de-DE",
		"fr-FR",
		"en-IE",
		"nl-NL"
	],
	"DKK": [
		"da-DK"
	],
	"CHF": [
		"de-CH",
		"fr-CH",
		"it-CH"
	],
	"JPY": [
		"ja-JP"
	],
	"HKD": [
		"zh-HK"
	],
	"SGD": [
		"zh-SG"
	],
	"INR": [
		"en-IN",
		"bn-IN",
		"hi-IN"
	]
};