import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import _ from 'lodash'
import { apiStoreUrl } from 'urls'
import { getAxiosDefaultConfig } from '../../modules/utils'

export const initialState = {
  account: {},
}

export const fetchApiStoreAccount = createAsyncThunk('apistore/fetchApiStoreAccount', async () => {
  let res = await axios.get(apiStoreUrl, getAxiosDefaultConfig())
  return res?.data
})

export const createApiStoreAccount = createAsyncThunk('apistore/createApiStoreAccount', async (payload) => {
  let res = await axios.post(apiStoreUrl, payload, getAxiosDefaultConfig())
  return res?.data?.data
})

export const updateApiStoreAccount = createAsyncThunk('apistore/updateApiStoreAccount', async (payload) => {
  const { key_id } = payload
  let res = await axios.post(`${apiStoreUrl}`, payload, getAxiosDefaultConfig())
  return res?.data
})

export const apistoreSlice = createSlice({
  name: 'apistore',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      /**
       *
       * Api Store account
       *
       */
      .addCase(fetchApiStoreAccount.pending, (state, action) => {
        state.account.status = 'loading'
      })
      .addCase(fetchApiStoreAccount.fulfilled, (state, action) => {
        state.account.status = 'fulfilled'
        state.account.data = action.payload
      })
      .addCase(fetchApiStoreAccount.rejected, (state, action) => {
        state.account.status = 'failed'
        state.account.data = action.error.message
      })
      .addCase(createApiStoreAccount.fulfilled, (state, action) => {
        state.account.data = action.payload
      })
      .addCase(updateApiStoreAccount.fulfilled, (state, action) => {
        state.account.data = action.payload
      })
  },
})

export const {} = apistoreSlice.actions

/**
 *
 * Selectors
 *
 */
export const selectApiStoreAccountStatus = (state) => state.apistore.account.status || 'idle'
export const selectApiStoreAccountError = (state) => state.apistore.account.error
export const selectApiStoreAccount = (state) => state.apistore.account.data

export default apistoreSlice.reducer