import React, { useEffect } from 'react'
import useDrivePicker from 'react-google-drive-picker'
import { googleClientId, googlePickerDeveloperToken } from 'token'
import { Button } from 'react-bootstrap'
import axios from 'axios'
import { getAxiosDefaultConfig } from 'utils'
import { getGoogleTokenUrl, getGoogleDriveTokenUrl } from 'urls'

function FilePicker({ setFolderInfo, folderName, viewId, setFileInfo, fileName, selectedEmail }) {
  const [openPicker, authResponse] = useDrivePicker()
  // const customViewsArray = [new google.picker.DocsView()]; // custom view
  const handleOpenPicker = async () => {
    let res = await axios.get(`${getGoogleDriveTokenUrl}?email=${selectedEmail}`, getAxiosDefaultConfig())

    const token = res.data.access_token
    openPicker({
      clientId: googleClientId,
      developerKey: googlePickerDeveloperToken,
      viewId: viewId,
      token: token,
      customScopes: ["email","profile","https://www.googleapis.com/auth/drive.file","https://www.googleapis.com/auth/spreadsheets"],
      showUploadView: true,
      showUploadFolders: true,
      setSelectFolderEnabled: true,
      supportDrives: true,
      multiselect: false,
      // customViews: customViewsArray, // custom view
      callbackFunction: (data) => {
        if (data.action === 'cancel') {
          console.log('User clicked cancel/close button')
        }
        if (viewId === 'FOLDERS') {
          setFolderInfo(data?.docs[0]?.name, data.docs[0]?.id)
        } else {
          setFileInfo(data?.docs[0]?.name, data.docs[0]?.id)
        }
      },
    })
  }

  let renderView = null

  if (viewId === 'FOLDERS') {
    renderView = folderName ? (
      <>
        <div>Selected Folder: {folderName}</div>
        <div
          onClick={() => handleOpenPicker()}
          style={{ textDecoration: 'underline', fontSize: 'smaller', color: '#0d6efd' }}
        >
          Choose another folder...
        </div>
      </>
    ) : (
      <Button onClick={() => handleOpenPicker()}>Choose Google Folder</Button>
    )
  } else {
    renderView = fileName ? (
      <>
        <div>Selected File: {fileName}</div>
        <div
          onClick={() => handleOpenPicker()}
          style={{ textDecoration: 'underline', fontSize: 'smaller', color: '#0d6efd' }}
        >
          Choose another file...
        </div>
      </>
    ) : (
      <Button onClick={() => handleOpenPicker()}>Choose Google File</Button>
    )
  }

  return (
    <div>
      {renderView}
    </div>
  )
}
export default FilePicker
