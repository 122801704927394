import { accountLinking, getEbaySellTokenUrl } from 'urls'
import { setCookie } from 'cookie'
import { topRedirect, topRedirectToShopifyAppStore, topRedirectToBigcommerceAppStore, topRedirectToEtsy, topRedirectToHubspot, topRedirectToUPS, topRedirectToEbay, topRedirectToWix, topRedirectToWalmart } from 'utils'
import store from '../app/store'
import { setLinkModalOpenFor, setModalOpenStatus } from '../actions/rootActions'

export const channelTitles = {
  Google: 'Google Analytics'
}

export const getAccountStatus = (account) => {
  let tokenExpired = false
  switch(account.channel) {
    // case 'eBay':
    //   tokenExpired = (!account.sell_access_token_expiry_time || new Date() > new Date(account.sell_access_token_expiry_time))
    //   break
    case 'Shopify':
      tokenExpired = !account.shopify_access_token_exists
      break
    case 'Facebook Ads':
      tokenExpired = account.facebook_relink
      break
  }
  return {
    tokenExpired,
  }
}

/**
 * Temporary util to map the array of accounts into array of direct seller accounts (only eBay and Shopify)
 * @param {Array} accounts array of accounts returned from endpoints logins/verify or inkfrog/auth
 */
export const extractAllSellerAccounts = (accounts) => {
  if (!accounts.length) {
    return []
  }
  const inkfrogAccount = accounts.filter(account => account.platform === 'inkFrog')[0]
  if (inkfrogAccount) {
    return inkfrogAccount.remote_seller_accounts.map(remote_seller_account => {
      return Object.assign({}, ...['account_email', 'agree_to', 'baremetrics_token', 'email_campaign_config', 'intercom_token', 'is_owner', 'merchant_id', 'slack_config'].map(field => ({
        [field]: inkfrogAccount[field]
      })), {
        isInkfrog: true,
      }, remote_seller_account.platform === 'eBay' ? {
        channel: 'eBay',
        account_name: remote_seller_account.username,
        ebay_session_expiry_date: remote_seller_account.ebay_session_expiry_date,
      } : null, remote_seller_account.platform === 'Shopify' ? {
        channel: 'Shopify',
        account_name: `${remote_seller_account.username}.myshopify.com`,
        shopify_access_token_exists: remote_seller_account.shopify_access_token_exists,
      }: null)
    })
  }
  else return accounts
}

export const linkAccount = (account, refreshToken, waitingTime = 1000*60*20) => {
  const merchant_id = account.merchant_id || ''
  const remote_account_username = account.isInkfrog ? account.account_name : ''
  const user_id = account.user_id
  const { url, referer, newWindow } = accountLinking(account.channel.toLowerCase(), merchant_id, user_id)

  setCookie('linking_account', 'true', waitingTime, null, false)
  if (refreshToken) {
    if (remote_account_username) {
      setCookie('refreshing_token_for', `${merchant_id}|${remote_account_username}`, waitingTime)
    }
    else {
      setCookie('refreshing_token_for', merchant_id, waitingTime)
    }
  }

  topRedirect({ url, referer, newWindow })
}

export const linkFromChannel = (channel) => {
  const { billingChannel } = store.getState().plan
  if (channel.toLowerCase() === 'shopify') {
    if (billingChannel === 'shopify') {
      setCookie('linking_account', 'true', 1000*60*20, null, false)
      topRedirectToShopifyAppStore()
    }
    else {
      store.dispatch(setLinkModalOpenFor('Shopify'))
      // store.dispatch(setModalOpenStatus(true))
    }
  }
  else if (channel.toLowerCase() === 'bigcommerce') {
    setCookie('linking_account', 'true', 1000*60*20, null, false)
    topRedirectToBigcommerceAppStore()
  }
  else if (channel.toLowerCase() === 'woocommerce') {
    store.dispatch(setLinkModalOpenFor('WooCommerce'))
    // store.dispatch(setModalOpenStatus(true))
  } else if (channel.toLowerCase() === 'etsy') {
    topRedirectToEtsy()
  } else if (channel.toLowerCase() === 'hubspot') {
    topRedirectToHubspot()
  } else if (channel.toLowerCase() === 'ups') {
    topRedirectToUPS()
  } else if (channel.toLowerCase() === 'ebay') {
    topRedirectToEbay()
  } else if (channel.toLowerCase() === 'wix') {
    topRedirectToWix()
  } else if (channel.toLowerCase() === 'walmart') {
    setCookie('linking_account', 'true', 1000*60*20, null, false)
    topRedirectToWalmart()
  }
  else {
    linkAccount({ channel }, false)
  }
}

export const getEbaySellToken = (account, topUrl) => {
  const { applicationId } = store.getState().root
  const waitingTime = 1000*60*20
  setCookie('fetching_ebay_sell_token_for', account.account_name, waitingTime)
  topRedirect({ url: getEbaySellTokenUrl(applicationId), waitingTime, referer: topUrl })
}

export const getEbayUsernames = (accounts) => {
  return accounts.filter(account => account.channel === 'eBay').map(account => account.account_name)
}

export const getAccountEmailDict = (accounts) => {
  return Object.assign({}, ...accounts.map(account => ({
    [account.merchant_id]: account.channel.toLowerCase().includes('paypal') ? {
      channel: 'paypal',
      email: account.account_email || account.email,
    } : {
      channel: account.channel.toLowerCase(),
    }
  })))
}