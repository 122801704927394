const prefixContent = {
  total: 'Total',
  avg: 'Average',
  max: 'The maximum',
  min: 'The minimum',
}

const mainContent = {
  sale: 'subtotal amount (all line items\' price - discount, excluding shipping and tax) of',
  product_sale: 'sales amount (individual item price x quantity - discount ) off',
  default_sales: 'default sales amount (default item price x quantity sold) of',
  order: 'number of',
  taxed_order: 'number of',
  discounted_order: 'number of',
  product_order: 'number of orders that contain',
  items_sold: 'items sold in',
  items_per_order: 'quantity of sold items per order in',
  price: 'individual item price in',
  product_items_sold: 'sold items of',
  tax: 'tax amount (including shipping tax) of',
  shipping: 'shipping cost amount (excluding shipping tax) of',
  store_fee: 'store fee amount of',
  cost: 'product cost (individual item cost x quantity) amount of',
  discount: 'discount amount of',
  profit: 'profit amount (subtotal - total product cost - total tax - store fee - gateway fee + (total shipping - total shipping label cost)) of',
  gross_sale: 'gross sale (total sales + shipping cost) amount of',
  refund: 'refund amount of',
  product: 'number of distinct results in',
  product_taxe: 'amount of line item tax of',
  product_discount: 'amount of line item discount of',
  product_cost: 'amount of line item cost (individual item cost x quantity) of',
  product_profit: 'amount of line item profit (individual item price x quantity - discount - total product tax - total product cost) of',
  item_tax: 'amount of line item tax of',
  item_cost: 'amount of line item cost (individual item cost x quantity) of',
  item_discount: 'amount of line item discount of',
  item_sale: 'amount of line item sales (individual item price x quantity - discount) of',
  revenue: 'revenue (total tax + total shipping + total sales of',
  gross_margin: 'percentage of gross profit (net sales - cost) out of net sales (all line items\' price - discount - refund) of',
  shipment_cost: 'amount of shipping label cost of',
  shipment_insurance_cost: 'amount of shipping insurance cost of',
  shipment_insurance_value: 'declared value of the shipment to insure of',
  shipment_profit: 'shipping profit (shipping cost - shipping label cost) of',
  customer: 'unique customers in the date range including guest checkouts of',
  tax_rate: 'tax rate (total tax / total sales) of',
  gross_tax_rate: 'gross tax rate (total tax / total gross sales) of',
  fulfillment_time: 'fulfillment time (order completed - order created) of',
  net_sales: 'sales - total sales of matched refunded orders',
  cost_of_refund: 'product cost of refunded items in',
  cost_of_goods_sold: 'product cost - refunded product cost of',
  items_refunded: 'number of items refunded in',
  sale_discount: 'sale discount (base price - sale price + discounts) of',
  shipping_tax: 'shipping tax amount of',
  store_credit_amount: 'amount of store credit redeemed of',
  store_credit_refund: 'amount of store credit refunded of',
  refund_tax: 'amount of sales tax refunded of',
  shipping_refund: 'amount of shipping refunded of',
  gateway_fee: 'amount of gateway fees of',
  points_claimed: 'amount of points claimed of',
  points_refunded: 'amount of points refunded of',
  points_earned: 'amount of points earned of',
  points_claimed_and_refunded: 'amount of points claimed and refunded of',
  points_net_claimed: 'amount of net points claimed of',
  gift_card_amount: 'amount of gift card redeemed of',
}

const suffixContent = {
  order: 'matched orders',
  product: 'matched sold products',
  marketing: 'matched UTM orders',
}

const customSuffix = {
  taxed_order: 'that have taxes paid',
  discounted_order: 'that have discounts',
}

export const getMetricTooltipContent = (type, metricName) => {
  const [prefix, ...tokens] = metricName.replace('utm_avg_', 'avg_').replace('utm_', 'total_').replace('_product_', '_').split('_')
  const name = ['gross_margin', 'tax_rate', 'gross_tax_rate', 'net_sales', 'default_sales', 'gateway_fee', 'points_earned', 'points_claimed', 'points_refunded', 'gift_card_amount'].includes(metricName) ? metricName : tokens.join('_').replace(/s$/, '')

  return [prefixContent[prefix] || 'Total', mainContent[`${type}_${name}`] || mainContent[name], suffixContent[type], customSuffix[name]].filter(t => t).join(' ')
}