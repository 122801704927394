import React, { Children, useState } from 'react'
import { AiFillCaretRight, AiFillCaretDown } from 'react-icons/ai'
import { FiChevronDown, FiChevronUp } from 'react-icons/fi'

export default function Accordian({
  size = 'default',
  iconOpened = <FiChevronDown strokeWidth={1.2} />,
  iconClosed = <FiChevronUp strokeWidth={1.2} />,
  children,
  className = '',
  ...rest
}) {
  const sizeMap = {
    default: {
      icon: 'tw-text-2xl tw-text-slate-700',
      text: 'tw-text-base tw-p-4',
    },
  }
  const sizeIconClassName = sizeMap[size].icon
  const sizeTextClassName = sizeMap[size].text
  const [activeIndex, setActiveIndex] = useState(null)
  return (
    <div className="tw-rounded-md tw-border tw-border-slate-200 tw-overflow-x-auto">
      {Children.map(children, (child, i) => {
        return (
          <div key={i}>
            <div
              className={`tw-cursor-pointer tw-flex tw-items-start tw-space-x-1 ${
                activeIndex === i
                  ? 'tw-pb-3 tw-border-b tw-border-slate-200 tw-rounded-md tw-ring-4 tw-ring-blue-200 tw-ring-offset-0'
                  : ''
              } ${sizeTextClassName} ${className}`}
              onClick={() => {
                if (activeIndex === i) {
                  setActiveIndex(null)
                } else {
                  setActiveIndex(i)
                }
              }}
              {...rest}
            >
              <div className="tw-flex-auto">{child.props.header}</div>
              <div className={`tw-shrink-0 ${sizeIconClassName}`}>{activeIndex === i ? iconOpened : iconClosed}</div>
            </div>
            {activeIndex === i && <div className={sizeTextClassName}>{child.props.children}</div>}
          </div>
        )
      })}
    </div>
  )
}

export const AccordianItem = ({ header, children }) => <div header={header}>{children}</div>
