import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import { Button, Dropdown, InputGroup, FormControl, Row, Col, FormCheck } from 'react-bootstrap'
import Alert from 'react-bootstrap/Alert'
import { useDispatch, useSelector } from 'react-redux'
import { 
  closeConnectionModal, 
  closeSettingsModal, 
  openSettingsModal, 
  openConnectionModal,
  selectConnectionModalVisible,
  selectSettingsModalVisible,
} from '../../integrationsSlice'
import {
  fetchPayhelmPixelAccounts,
  createPayhelmPixelAccount,
  deletePayhelmPixelAccount,
  selectPayhelmPixelAccounts,
  selectPayhelmPixelDataStatus,
  selectPayhelmPixelError,
  selectEditedPayhelmPixelAccount,
  setEditedPayhelmPixelAccount,
  setPendingConfig,
  setError,
  selectPendingConfig,
  updatePayhelmPixelAccounts,
  setWarning,
  selectPayhelmPixelWarning,
  setResult,
  selectPayhelmPixelResult,
} from '../../../../features/payhelmPixel/payhelmPixelSlice'
import { transactionsConfig, customersConfig } from 'modules/formatting'
import Spinner from 'sharedComponents/Spinner'
import MyLabel from '../../../../features/forms/MyLabel'
import { BiPlug } from 'react-icons/bi'

const subdomainOptions = ['payhelm', 'pixel', 'gtm', 'track', 'data', 'I want to name my own']
const presetSubdomainOptions = subdomainOptions.slice(0, 5)
const extractTopDomain = (domain) => {
  const tokens = domain.split('.')
  if (tokens.length == 2) {
    return tokens.join('.')
  }
  else {
    return tokens.slice(1).join('.')
  }
}
const updatePendingConfig = (pendingConfig, id, data) => {
  return pendingConfig.map(config => {
    return config.id === id ? {
      ...config,
      ...data,
    } : config
  })
}

export default function PayHelmPixelModalContent({ 
  newConnection = false,
  accounts: allChannelAccounts, 
  retrieveAccounts 
}) {
  const dispatch = useDispatch()
  const dataStatus = useSelector(selectPayhelmPixelDataStatus)
  const error = useSelector(selectPayhelmPixelError)
  const warning = useSelector(selectPayhelmPixelWarning)
  const result = useSelector(selectPayhelmPixelResult)
  const accounts = useSelector(selectPayhelmPixelAccounts)
  const pendingConfig = useSelector(selectPendingConfig)
  const editedPayhelmPixelAccount = useSelector(selectEditedPayhelmPixelAccount)
  const connectionModalVisible = useSelector(selectConnectionModalVisible)
  const settingsModalVisible = useSelector(selectSettingsModalVisible)

  const [selectedMetaDataType, selectMetaDataType] = useState('Order')
  const [selectedDomain, selectDomain] = useState(newConnection ? null : (editedPayhelmPixelAccount?.domain || ''))
  const [selectedSubdomain, selectSubdomain] = useState(
    newConnection ? 'payhelm' : (
      presetSubdomainOptions.includes(editedPayhelmPixelAccount?.subdomain) ? 
      (editedPayhelmPixelAccount?.subdomain || '') :
      'I want to name my own'
    )
  )
  const [customSubdomain, setCustomSubdomain] = useState(
    newConnection ? 'payhelm' : (editedPayhelmPixelAccount?.subdomain || '')
  )
  const [useCustomSubdomain, setUseCustomSubdomain] = useState(
    newConnection ? false : !presetSubdomainOptions.includes((editedPayhelmPixelAccount?.subdomain || ''))
  )
  const [newConfig, setNewConfig] = useState(null)
  const [metadataFilter, setMetadataFilter] = useState('')

  const closeModal = () => {
    if (newConnection) {
      return dispatch(closeConnectionModal())
    }
    else {
      return dispatch(closeSettingsModal())
    }
  }

  useEffect(() => {
    if (error && (connectionModalVisible || settingsModalVisible)) {
      dispatch(setError(null))
    }
  }, [connectionModalVisible, settingsModalVisible, error])

  useEffect(() => {
    if (dataStatus === 'idle') {
      dispatch(fetchPayhelmPixelAccounts())
    }
    else if (dataStatus === 'fulfilled' && !newConnection && accounts?.length) {
      const nextEditedPayhelmPixelAccount = accounts[0]
      selectDomain(nextEditedPayhelmPixelAccount.domain)
      selectSubdomain(
        presetSubdomainOptions.includes(nextEditedPayhelmPixelAccount.subdomain) ? 
        nextEditedPayhelmPixelAccount.subdomain :
        'I want to name my own'
      )
      setCustomSubdomain(nextEditedPayhelmPixelAccount.subdomain)
      setUseCustomSubdomain(!presetSubdomainOptions.includes(nextEditedPayhelmPixelAccount.subdomain))
    }
  }, [dataStatus])

  const onSubmitNewConnection = async () => {
    const payload = { domain: selectedDomain, ...newConfig }
    if (useCustomSubdomain) {
      payload.subdomain = customSubdomain
    }
    else {
      payload.subdomain = selectedSubdomain
    }
    await dispatch(createPayhelmPixelAccount({
      payload, 
      retrieveAccounts,
      callback: (warning) => {
        selectDomain(null)
        selectSubdomain('payhelm')
        setNewConfig(null)
        dispatch(fetchPayhelmPixelAccounts())
        dispatch(setWarning(warning))
        setTimeout(() => {
          dispatch(setResult(null))
          dispatch(setWarning(null))
          dispatch(setError(null))
        }, 3000)
      }
    }))
  }

  const onSubmitSettings = async () => {
    await dispatch(updatePayhelmPixelAccounts({ 
      payload: pendingConfig,
      retrieveAccounts,
      callback: (warning) => {
        dispatch(fetchPayhelmPixelAccounts())
        dispatch(setWarning(warning))
        setTimeout(() => {
          dispatch(setResult(null))
          dispatch(setWarning(null))
          dispatch(setError(null))
        }, 3000)
      }
    }))
  }

  if (dataStatus === 'idle' || !accounts) {
    return (
      <div className="tw-p-6">
        <Spinner />
      </div>
    )
  }

  const connectedDomains = accounts.map(account => account.domain)
  const applicableDomains = allChannelAccounts.filter(account => [
    'Bigcommerce', 'Shopify', 'Woocommerce', 'Wix', 'API Store'
  ].includes(account.channel)).map(account => {
    return account.domain || account.merchant_id
  }).filter(accountDomain => {
    return !connectedDomains.includes(accountDomain)
  })

  const pendingConfigOfEditedAccount = newConnection ? newConfig : (pendingConfig || []).find(c => c.id === editedPayhelmPixelAccount.id)
  const subdomainValue = useCustomSubdomain ? customSubdomain : selectedSubdomain
  const metaDataNames = selectedMetaDataType === 'Order' ? transactionsConfig.titles : customersConfig.titles

  const subdomainMenu = (
    <Dropdown style={{ maxWidth: 210}}>
      <Dropdown.Toggle>{selectedSubdomain}</Dropdown.Toggle>
      <Dropdown.Menu>
        {
          subdomainOptions.map(subdomain => (
            <Dropdown.Item
              key={subdomain}
              onClick={() => {
                if (subdomain !== selectedSubdomain) {
                  selectSubdomain(subdomain)
                  let nextSubdomain
                  if (subdomain === 'I want to name my own') {
                    setUseCustomSubdomain(true)
                    nextSubdomain = customSubdomain
                  }
                  else {
                    setUseCustomSubdomain(false)
                    nextSubdomain = subdomain
                  }
                  if (!newConnection) {
                    dispatch(setPendingConfig(updatePendingConfig(pendingConfig, editedPayhelmPixelAccount.id, {
                      subdomain: nextSubdomain,
                    })))
                  }
                }
              }}
              active={subdomain === selectedDomain}
            >
              { subdomain }
            </Dropdown.Item>
          ))
        }
      </Dropdown.Menu>
    </Dropdown>
  )
  const subdomainInput = (
    <FormControl
      name="subdomain"
      label=""
      type="text"
      className='right'
      placeholder='Enter subdomain'
      onChange={(e) => {
        const escaped = e.target.value.replace(/[^0-9A-Za-z\-\.]+/g, '')
        setCustomSubdomain(escaped)
        if (!newConnection) {
          dispatch(setPendingConfig(updatePendingConfig(pendingConfig, editedPayhelmPixelAccount.id, {
            subdomain: escaped,
          })))
        }
      }}
      value={subdomainValue}
      disabled={!useCustomSubdomain}
    />
  )
  
  const metaDataTypeMenu = (
    <>
      <Col xs={12}>
        <h5>Metadata Settings</h5>
      </Col>
      <Col xs={6} className='mb2'>
        <Dropdown>
          <Dropdown.Toggle>{selectedMetaDataType}</Dropdown.Toggle>
          <Dropdown.Menu>
            {
              ['Order', 'Customer'].map(metaDataType => (
                <Dropdown.Item
                  key={metaDataType}
                  onClick={() => selectMetaDataType(metaDataType)}
                  active={metaDataType === selectedMetaDataType}
                >
                  { metaDataType }
                </Dropdown.Item>
              ))
            }
          </Dropdown.Menu>
        </Dropdown>
      </Col>
      <Col xs={6} className='mb2 right'>
        <FormControl
          name="metadata-filter"
          label=""
          type="text"
          className='right'
          placeholder='Search fields'
          onChange={(e) => {
            setMetadataFilter(e.target.value)
          }}
          value={metadataFilter}
        />
      </Col>
    </>
  )
  const metaDataCheckboxes = (
    <Row style={{ maxHeight: 200, overflow: 'auto' }} className='mb3'>
      <Col xs={12}><MyLabel text={'Available Pixels'}/></Col>
      {
        Object.keys(metaDataNames).filter(
          metaDataKey => metaDataNames[metaDataKey].toLowerCase().includes(metadataFilter.toLowerCase())
        ).map(metaDataKey => {
          const checked = _.get(pendingConfigOfEditedAccount, ['metadata', selectedMetaDataType], []).includes(metaDataKey)
          return (
            <Col xs={6} lg={4} key={metaDataKey}>
              <FormCheck
                type='checkbox'
                inline
              >
                <FormCheck.Input
                  type={'checkbox'}
                  checked={checked}
                  value={'metaDataKey'}
                  onChange={() => {
                    const currentMetadataFields = _.get(pendingConfigOfEditedAccount, ['metadata', selectedMetaDataType], [])
                    const nextMetadataFields = checked ? 
                      currentMetadataFields.filter(f => f !== metaDataKey) :
                      [...currentMetadataFields, metaDataKey]
                    const configUpdate = {
                      metadata: {
                        ...(_.get(pendingConfigOfEditedAccount, 'metadata', null)),
                        [selectedMetaDataType]: nextMetadataFields,
                      }
                    }
                    const nextConfig = {
                      ...pendingConfigOfEditedAccount,
                      ...configUpdate
                    }
                    if (newConnection) {
                      setNewConfig(nextConfig)
                    }
                    else {
                      dispatch(setPendingConfig(updatePendingConfig(pendingConfig, editedPayhelmPixelAccount.id, configUpdate)))
                    }
                  }}
                >
                </FormCheck.Input>
                <FormCheck.Label>{metaDataNames[metaDataKey]}</FormCheck.Label>
              </FormCheck>
            </Col>
          )
        })
      }
    </Row>
  )

  if (newConnection) {
    return (
      <div className='tw-p-6'>
        <h2 className='tw-text-xl tw-mb-6'>Add PayHelm Pixel</h2>
        {result && <Alert variant={'success'}>{result}</Alert>}
        {error && <Alert variant={'danger'}>{error}</Alert>}
        {warning && <Alert variant={'warning'}>{warning}</Alert>}
        <form>
          { dataStatus === 'loading' ? <Spinner mask /> : null }
          {
            applicableDomains.length ? (
              <>
              <Row className='mb2'>
                <Col xs={12}>
                  <h5>Domain Settings</h5>
                </Col>
                <Col xs={12} lg={4} className='mb2'>
                  { subdomainMenu }
                </Col>
                <Col xs={12} lg={8}>
                  <InputGroup>
                    { subdomainInput }
                    <Dropdown align={'end'}>
                      <Dropdown.Toggle>
                        {selectedDomain ? `.${extractTopDomain(selectedDomain)}` : 'Select domain'}
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        {
                          applicableDomains.map(domain => (
                            <Dropdown.Item
                              key={domain}
                              onClick={() => selectDomain(domain)}
                              active={domain === selectedDomain}
                            >
                              { domain }
                            </Dropdown.Item>
                          ))
                        }
                      </Dropdown.Menu>
                    </Dropdown>
                  </InputGroup>
                </Col>
              </Row>
              <Row>
                { metaDataTypeMenu }
              </Row>
              { metaDataCheckboxes }
              </>
            ) : (
              <p>You do not have any available store domains to connect.</p>
            )
          }
          {
            accounts?.length ? (
              <div>
                <h5>Connected pixel domains</h5>
                {
                  accounts.map(account => (
                    <div key={account.id} className='flex-box mb1 v-center'>
                      <b className='flex-1'>{account.id}</b>
                      <Button
                        onClick={() => {
                          dispatch(closeConnectionModal())
                          dispatch(openSettingsModal())
                          setEditedPayhelmPixelAccount(account)
                        }}
                      >
                        <span className='fa fa-pencil'/>
                      </Button>
                      &nbsp;
                      <Button
                        onClick={async () => {
                          await dispatch(deletePayhelmPixelAccount({id: account.id, retrieveAccounts}))
                          setTimeout(() => {
                            dispatch(setResult(null))
                            dispatch(setWarning(null))
                            dispatch(setError(null))
                          }, 3000)
                        }}
                      >
                        <span className='fa fa-trash'/>
                      </Button>
                    </div>
                  ))
                }
              </div>
            ) : null
          }
          <hr />
          <div className="tw-flex tw-items-center tw-justify-end tw-space-x-2">
            <Button variant="secondary" onClick={closeModal}>
              Close
            </Button>
            <Button onClick={() => onSubmitNewConnection()} disabled={!subdomainValue || !selectedDomain}>
              Save PayHelm Pixel
            </Button>
          </div>
        </form>
      </div>
    )
  }
  else {
    return (
      <div>
        {result && <Alert variant={'success'}>{result}</Alert>}
        {error && <Alert variant={'danger'}>{error}</Alert>}
        {warning && <Alert variant={'warning'}>{warning}</Alert>}
        <form>
          { dataStatus === 'loading' ? <Spinner mask /> : null }
          <Row className='mb2'>
            <Col xs={12}>
              <h5>Domain Settings</h5>
            </Col>
            <Col xs={12} className='mb2'>
              <span>Pixel domain</span>
              <div>
                <Dropdown style={{ maxWidth: 210, display: 'inline' }}>
                  <Dropdown.Toggle>{editedPayhelmPixelAccount?.id || ''}</Dropdown.Toggle>
                  <Dropdown.Menu>
                    {
                      accounts.map(account => (
                        <Dropdown.Item
                          key={account.id}
                          onClick={() => {
                            setEditedPayhelmPixelAccount(account)
                          }}
                          active={editedPayhelmPixelAccount?.id === account.id}
                        >
                          { account.id }
                        </Dropdown.Item>
                      ))
                    }
                  </Dropdown.Menu>
                </Dropdown>
                &nbsp;&nbsp;
                <div
                  // variant='light'
                  className="float-right tw-cursor-pointer tw-rounded-md tw-border tw-border-slate-300 hover:tw-border-blue-600 hover:tw-bg-blue-600 hover:tw-text-white tw-transition-colors tw-duration-150 tw-shadow-sm tw-flex tw-items-center tw-space-x-2.5 tw-px-2.5 tw-py-1"
                  onClick={() => {
                    dispatch(closeSettingsModal())
                    dispatch(openConnectionModal())
                  }}
                >
                  <div className='inline-block mr1'><BiPlug /></div>
                  New Connection
                </div>
              </div>
            </Col>
            <Col xs={12} lg={4} className='mb2'>
              { subdomainMenu }
            </Col>
            <Col xs={12} lg={8}>
              <InputGroup>
                { subdomainInput }
                <Button>{`.${extractTopDomain(selectedDomain)}`}</Button>
              </InputGroup>
            </Col>
          </Row>
          <Row>
            { metaDataTypeMenu }
          </Row>
          { metaDataCheckboxes }
          <hr />
          <div className="right tw-items-center tw-justify-end tw-space-x-2">
            <Button 
              className='float-left' 
              variant='dark'
              onClick={async () => {
              await dispatch(deletePayhelmPixelAccount({id: editedPayhelmPixelAccount.id, retrieveAccounts}))
              dispatch(closeSettingsModal())
              dispatch(openConnectionModal())
              setTimeout(() => {
                dispatch(setResult(null))
                dispatch(setWarning(null))
                dispatch(setError(null))
              }, 3000)
            }}>
              Delete this pixel
            </Button>
            <Button variant="secondary" onClick={closeModal}>
              Close
            </Button>
            <Button onClick={() => onSubmitSettings()}>
              Save PayHelm Pixel Settings
            </Button>
          </div>
        </form>
      </div>
    )
  }
}
