import React from 'react'
import CustomHighcharts from 'sharedComponents/CustomHighcharts'

export default function Chart({ facets, chartType, facetType, startDate, endDate, }) {
  return (
    <div>
      {Object.keys(facets).map((facet) => {
        const facetKey = facet
        if (facetKey !== facetType) return
        return (
          <div style={{display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center',}}>
            <CustomHighcharts
              key={`custom-highchart-${facetKey}`}
              facet={facets[facetKey]}
              prevFacet={null}
              prevRange={null}
              currentRange={{ startDate, endDate, }}
              facetName={facetKey}
              legend={false}
              height={300}
              cols={{xs:12, sm:12, md:6, lg:6}}
              filterVisible={false}
              currentFilter={[]}
              chartTypes={[]}
              empty={false}
              filterable={facetKey !== 'sold_hour'}
              setChartTypeCallback={() => {}}
              fetching={false}
              chartType={chartType}
            />
          </div>
        )
      })}
    </div>
  )
}
