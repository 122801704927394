import React from 'react'
import { BiBarChartSquare, BiPlug } from 'react-icons/bi'
import { FiArrowRight } from 'react-icons/fi'
import { withRouter } from 'react-router-dom'

function Ad({ history }) {
  return (
    <div
      className="tw-w-full tw-h-40 tw-rounded-lg tw-shadow-md tw-bg-cover tw-flex tw-items-center tw-justify-center tw-bg-gradient-to-r tw-from-sky-500 tw-to-blue-800 tw-text-white tw-p-8 tw-cursor-pointer tw-mt-10 lg:tw-mt-0 hover:tw-ring hover:tw-ring-blue-600"
      onClick={() => history.push('/integrations')}
    >
      <div>
        <div className="tw-flex tw-items-center tw-justify-center tw-mb-3 tw-space-x-3 tw-text-xl">
          <div className="tw-w-12 tw-h-12 tw-flex tw-items-center tw-justify-center tw-rounded-xl tw-backdrop-blur-md tw-bg-white/60 tw-text-blue-900">
            <BiPlug />
          </div>
          <FiArrowRight className="tw-text-white/75" />
          <div className="tw-w-12 tw-h-12 tw-flex tw-items-center tw-justify-center tw-rounded-xl tw-backdrop-blur-md tw-bg-white/60 tw-text-blue-900">
            <BiBarChartSquare />
          </div>
        </div>
        <div className=" tw-text-center tw-text-lg tw-font-bold">Add more integrations to see more data</div>
      </div>
    </div>
  )
}

export default withRouter(Ad)
