import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import _ from 'lodash'
import {
  createCreditCardUrl,
} from 'urls'
import { getAxiosDefaultConfig } from '../../modules/utils'
import { sendGtmPixel } from 'tagManager'

export const initialState = {
  newCreditCard: {
    status: 'idle',
    data: null,
    error: null,
  }
}

export const addCreditCard = createAsyncThunk('planSettings/addCreditCard', async (token) => {
  try {
    const { data: res } = await axios.post(
      createCreditCardUrl,
      { token },
      getAxiosDefaultConfig()
    )
    sendGtmPixel(res, 'cc_added')
    return res
  }
  catch(error) {
    console.error(error)
    const errorMessage = _.get(error, ['response', 'data', 'error'], 'Internal Error')
    return errorMessage
  }
})

export const planSlice = createSlice({
  name: 'planSettings', // temporary name until we can remove the old settings state altogether
  initialState,
  reducers: {
  },
  extraReducers(builder) {
    builder
      /**
       *
       * add credit card
       *
       */
      .addCase(addCreditCard.pending, (state, action) => {
        state.newCreditCard.status = 'loading'
      })
      .addCase(addCreditCard.fulfilled, (state, action) => {
        console.log(action)
        state.newCreditCard.status = 'succeeded'
        state.newCreditCard.data = action.payload
        state.newCreditCard.error = null
      })
      .addCase(addCreditCard.rejected, (state, action) => {
        state.newCreditCard.status = 'failed'
        state.newCreditCard.error = action.error.message
      })
  },
})

export const {} = planSlice.actions

export const selectNewCreditCard = (state) => state.planSettings.newCreditCard.data
export const selectNewCreditCardStatus = (state) => state.planSettings.newCreditCard.status
export const selectNewCreditCardError = (state) => state.planSettings.newCreditCard.error

// Select from the legacy redux state
export const selectCurrentCreditCardsSubscription = (state) => ({
  creditCards: state.plan.creditCards,
  subscription: state.plan.subscription,
})
export const selectIsStripeBilling = (state) => state.plan.billingChannel === 'stripe'

export default planSlice.reducer