import hosts from 'hosts.json'

export const setCookie = (cname, cvalue, extime = 0, cdomain, sameSite = true) => {
    let d = new Date()
    d.setTime(d.getTime() + extime);
    let expires = "expires="+ d.toUTCString();
    let domain = "domain=" + (cdomain || hosts.cookieDomain[location.hostname]);
    document.cookie = cname + "=" + cvalue + ";" + domain + ";" + expires + ";path=/" + (sameSite ? ";SameSite=None;Secure;Partitioned" : "");
}

export const getCookie = (cname) => {
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(';');
    let name;

    if (Array.isArray(cname)) {
        let cvalue = cname.map(() => '');
        name = cname.map(cn => cn + '=');
        for(var i = 0; i <ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            name.forEach((n, index) => {
                if (c.indexOf(n) === 0) {
                    cvalue[index] = c.substring(n.length, c.length);
                }
            })
        }
        return cvalue
    }
    else {
        name = cname + "=";
        for(var i = 0; i <ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        return '';
    }
}

export const clearTempCookies = () => {
    ([
        'refreshing_token_for', 'new_linked_account', 'linking_account',
        'confirming_charge', 'charge_fulfilled',
        'fetching_ebay_sell_token_for', 'fetching_ebay_sell_token_fulfilled',
    ]).forEach(cname => {
        setCookie(cname, '')
    })
}