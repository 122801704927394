import { localStoragePayhelm, getQueryJson, validateJSONString, } from 'modules/utils'
import { keywordTypes, } from '../modules/constants'

let keywordFilters = {}
const savedState = localStoragePayhelm.keywordFilters && validateJSONString(localStoragePayhelm.keywordFilters) 
  ? JSON.parse(localStoragePayhelm.keywordFilters) : undefined
let checksum = true
if (savedState) {
  for (const keywordType of Object.keys(savedState)) {
    if (typeof(savedState[keywordType]?.value !== 'string') || typeof(savedState[rangeType]?.value !== 'string')) checksum = false
    if (!keywordTypes.includes(keywordType)) checksum = false
  }
} else checksum = false
if (checksum) keywordFilters = savedState
else keywordTypes.map(keywordType => keywordFilters[keywordType] = { active: false, value: '', containing: true, })

const queryJson = getQueryJson()
try {
  for (const type of keywordTypes) {
    if (queryJson[type] !== undefined) {
      const [containing, value] = queryJson[type].split('-')
      if (typeof(value) === 'string' || !isNaN(value)) {
        keywordFilters[type] = { active: true, value, containing: containing === 'not' ? false : true, }
      }
    }
  }
} catch (error) {
  console.log(error)
}

const initialState = {
  keywordTypes: keywordFilters,
  updated: false,
}

export default function reducer(state = initialState, action) {
  let nextState = state
  switch(action.type) {
    case 'ACTIVATE_KEYWORD_TYPE':
      nextState = {
        ...state,
        keywordTypes: {
          ...state.keywordTypes,
          [action.payload]: {
            ...state.keywordTypes[action.payload],
            active: true,
          }
        },
        updated: true,
      }
      break
    case 'DEACTIVATE_KEYWORD_TYPE':
      nextState = {
        ...state,
        keywordTypes: {
          ...state.keywordTypes,
          [action.payload]: {
            ...state.keywordTypes[action.payload],
            active: false,
          }
        },
        updated: true,
      }
      break
    case 'DEACTIVATE_ALL_KEYWORD_TYPES': {
      let nextKeywordTypes = {}
      for (const keywordType of Object.keys(state.keywordTypes)) {
        nextKeywordTypes[keywordType] = {
          ...state.keywordTypes[keywordType],
          active: false,
        }
      }
      nextState = {
        ...state,
        keywordTypes: nextKeywordTypes,
        updated: true,
      }
      break
    }
    case 'CHANGE_KEYWORD_CONTAINING':
      nextState = {
        ...state,
        keywordTypes: {
          ...state.keywordTypes,
          [action.payload.keywordType]: {
            ...state.keywordTypes[action.payload.keywordType],
            containing: action.payload.containing,
          }
        },
        updated: true,
      }
      break
    case 'SET_KEYWORD_TYPE':
      nextState = {
        ...state,
        keywordTypes: {
          ...state.keywordTypes,
          [action.payload.keywordType]: {
            ...state.keywordTypes[action.payload.keywordType],
            value: action.payload.value !== undefined ? action.payload.value : state.keywordTypes[action.payload.keywordType].value,
          },
        },
        updated: true,
      }
      break
    case 'SET_UPDATED_KEYWORD_FILTERS':
      nextState = {
        ...state,
        updated: action.payload,
      }
      break
    case 'SET_KEYWORD_FILTERS_CONFIG':
      return {
        ...state,
        ...action.payload,
      }
  }
  localStoragePayhelm.keywordFilters = JSON.stringify(nextState.keywordTypes || '')
  return nextState
}
