import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import _ from 'lodash'
import { openAIUrl } from 'urls'
import { getAxiosDefaultConfig } from '../../modules/utils'

export const initialState = {
  account: {},
  chat: {},
  chatHistory: {},
  relatedQuestions: {},
  relatedQuestionsStatus: {},
  relatedQuestionsError: {},
  insightResponse: {},
  insightStatus: {},
  insightError: {},
  chatBoxIsExpanded: false,
  messageId: null,
}

export const fetchOpenAIAccount = createAsyncThunk('openai/fetchOpenAIAccount', async (payload) => {
  const { key_id } = payload
  let res = await axios.get(`${openAIUrl}/account/${key_id}`, getAxiosDefaultConfig())
  return res?.data
})

export const createOpenAIAccount = createAsyncThunk('openai/createOpenAIAccount', async (payload) => {
  let res = await axios.post(`${openAIUrl}/account`, payload, getAxiosDefaultConfig())
  return res?.data
})

export const updateOpenAIAccount = createAsyncThunk('openai/updateOpenAIAccount', async (payload) => {
  const { key_id } = payload
  let res = await axios.put(`${openAIUrl}/account/${key_id}`, payload, getAxiosDefaultConfig())
  return res?.data
})

export const deleteOpenAIAccount = createAsyncThunk('openai/deleteOpenAIAccount', async (payload) => {
  const { key_id } = payload
  let res = await axios.delete(`${openAIUrl}/account/${key_id}`, getAxiosDefaultConfig())
  return res?.data
})

export const callOpenAIChat = createAsyncThunk('openai/callOpenAIChat', async (payload, thunkAPI) => {
  try {
    let res = await axios.post(`${openAIUrl}/chat`, payload, getAxiosDefaultConfig())
    return res?.data
  } catch (err) {
    return thunkAPI.rejectWithValue(err?.response?.data || err)
  }
})

export const rateOpenAIChat = createAsyncThunk('openai/rateOpenAIChat', async (payload, thunkAPI) => {
  try {
    let res = await axios.post(`${openAIUrl}/chat/rate`, payload, getAxiosDefaultConfig())
    return res?.data
  } catch (err) {
    return thunkAPI.rejectWithValue(err?.response?.data || err)
  }
})

export const fetchOpenAIChatHistory = createAsyncThunk('openai/fetchOpenAIChatHistory', async (payload) => {
  const { app_email } = payload
  let res = await axios.get(`${openAIUrl}/chat/history/${app_email}`, getAxiosDefaultConfig())
  return res?.data
})

const sleep = (ms) => new Promise(resolve => setTimeout(resolve, ms))
export const fetchRelatedQuestions = createAsyncThunk(
  'openai/fetchRelatedQuestions',
  async (payload, { rejectWithValue }) => {
    try {
      const { sourcePage, isMimic } = payload
      const res = await axios.post(
        `${openAIUrl}/chat/insights-questions`, 
        {
          sourcePage,
          isMimic,
        }, 
        getAxiosDefaultConfig()
      )
      return res?.data
    } catch (err) {
      return rejectWithValue(err?.response?.data || err)
    }
  }
)

export const askForInsight = createAsyncThunk(
  'openai/askForInsight',
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      const { question, data, dataTypes, sourcePage, key_id, isMimic } = payload
      dispatch(setMessageId(null))
      const res = await axios.post(
        `${openAIUrl}/chat/insights-from-data`,
        {
          question,
          data,
          dataTypes,
          sourcePage,
          key_id,
          isMimic,
        },
        {
          responseType: 'text',
          onDownloadProgress: (progressEvent) => {
            const responseText = progressEvent.currentTarget.response
            const messages = responseText.split('\n\n').filter(msg => msg.trim())
            let fullMessage = ''
            for (const message of messages) {
              if (message.startsWith('data: ')) {
                const data = message.substring(6)
                if (data === '[DONE]') {
                  continue
                }
                if (data === '[UNRELATED]') {
                  fullMessage = 'Sorry, I cannot find related insights from this report.'
                  break
                }
                const parsedData = JSON.parse(data)
                if (parsedData?.messageId) {
                  dispatch(setMessageId(parsedData?.messageId))
                }
                else {
                  fullMessage += (parsedData?.content || '').replace(/\[DONE\]$/, '')
                }
              }
            }
            dispatch(updateInsightChunk({ sourcePage, data: fullMessage }))
          },
          ...getAxiosDefaultConfig()
        }
      )

      await sleep(500)
    } catch (err) {
      console.error(err)
      return rejectWithValue(err?.response?.data || err)
    }
  }
)

export const updateInsightChunk = createAsyncThunk(
  'openai/updateInsightChunk',
  (payload) => {
    return payload?.data || ''
  }
)

export const openaiSlice = createSlice({
  name: 'openai',
  initialState,
  reducers: {
    toggleChatBox: (state) => {
      state.chatBoxIsExpanded = !state.chatBoxIsExpanded
    },
    setChatBoxExpanded: (state, action) => {
      state.chatBoxIsExpanded = action.payload
    },
    setMessageId: (state, action) => {
      state.messageId = action.payload
    },
  },
  extraReducers(builder) {
    builder
      /**
       *
       * OpenAI account
       *
       */
      .addCase(fetchOpenAIAccount.pending, (state, action) => {
        state.account.status = 'loading'
      })
      .addCase(fetchOpenAIAccount.fulfilled, (state, action) => {
        state.account.status = 'fulfilled'
        state.account.data = action.payload
      })
      .addCase(fetchOpenAIAccount.rejected, (state, action) => {
        state.account.status = 'failed'
        state.account.data = action.error.message
      })
      .addCase(createOpenAIAccount.fulfilled, (state, action) => {
        state.account.data = action.payload
      })
      .addCase(updateOpenAIAccount.fulfilled, (state, action) => {
        state.account.data = action.payload
      })
      .addCase(deleteOpenAIAccount.fulfilled, (state, action) => {
        state.account.data = action.payload
      })

      /**
       *
       * OpenAI Chat API
       *
       */
      .addCase(callOpenAIChat.fulfilled, (state, action) => {
        state.chat.data = action.payload
      })
      .addCase(callOpenAIChat.rejected, (state, action) => {
        state.chat.status = 'failed'
        state.chat.data = action.payload
      })

      /**
       *
       * OpenAI Chat History API
       *
       */
      .addCase(fetchOpenAIChatHistory.fulfilled, (state, action) => {
        state.account.status = 'fulfilled'
        state.chatHistory.data = action.payload
      })

      .addCase(fetchRelatedQuestions.pending, (state, action) => {
        const { sourcePage } = action.meta.arg
        state.relatedQuestionsStatus[sourcePage] = 'loading'
        state.relatedQuestionsError[sourcePage] = null
      })
      .addCase(fetchRelatedQuestions.fulfilled, (state, action) => {
        const { sourcePage } = action.meta.arg
        state.relatedQuestionsStatus[sourcePage] = 'succeeded'
        state.relatedQuestions[sourcePage] = action.payload
        state.relatedQuestionsError[sourcePage] = null
      })
      .addCase(fetchRelatedQuestions.rejected, (state, action) => {
        const { sourcePage } = action.meta.arg
        state.relatedQuestionsStatus[sourcePage] = 'failed'
        state.relatedQuestionsError[sourcePage] = action.payload
      })

      .addCase(updateInsightChunk.fulfilled, (state, action) => {
        const { sourcePage } = action.meta.arg
        state.insightResponse[sourcePage] = action.payload
      })

      .addCase(askForInsight.pending, (state, action) => {
        const { sourcePage } = action.meta.arg
        state.insightStatus[sourcePage] = 'loading'
        state.insightError[sourcePage] = null
      })
      .addCase(askForInsight.fulfilled, (state, action) => {
        const { sourcePage } = action.meta.arg
        state.insightStatus[sourcePage] = 'succeeded'
        state.insightResponse[sourcePage] = ''
        state.insightError[sourcePage] = null
      })
      .addCase(askForInsight.rejected, (state, action) => {
        const { sourcePage } = action.meta.arg
        state.insightStatus[sourcePage] = 'failed'
        state.insightError[sourcePage] = action.payload
      })
  },
})

export const { toggleChatBox, setChatBoxExpanded, setMessageId } = openaiSlice.actions

/**
 *
 * Selectors
 *
 */
export const selectOpenAIAccountStatus = (state) => state.openai.account.status || 'idle'
export const selectOpenAIAccountError = (state) => state.openai.account.error
export const selectOpenAIAccount = (state) => state.openai.account.data

export const selectDashboardConfig = (state) => state.root.user.dashboardconfig
export const selectRangeFilters = (state) => state.rangeFilters
export const selectKeywordFilters = (state) => state.keywordFilters
export const selectDashboardFilters = (state) => state.dashboard.filters

export const selectOpenAIChatHistoryStatus = (state) => state.openai.chatHistory.status || 'idle'
export const selectOpenAIChatHistory = (state) => state.openai.chatHistory.data

export const selectRelatedQuestionsStatus = (state, sourcePage) => 
  state.openai.relatedQuestionsStatus[sourcePage] || 'idle'
export const selectRelatedQuestionsError = (state, sourcePage) => 
  state.openai.relatedQuestionsError[sourcePage]
export const selectRelatedQuestions = (state, sourcePage) => 
  state.openai.relatedQuestions[sourcePage] || []

export const selectInsightStatus = (state, sourcePage) => 
  state.openai.insightStatus[sourcePage] || 'idle'
export const selectInsightError = (state, sourcePage) => 
  state.openai.insightError[sourcePage]
export const selectInsightResponse = (state, sourcePage) => 
  state.openai.insightResponse[sourcePage]
export const selectInsightResponseMessageId = (state) =>
  state.openai.messageId

export default openaiSlice.reducer
