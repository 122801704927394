import React, { useEffect, useState } from 'react'
import { Dropdown, Button } from 'react-bootstrap'
import { connect } from 'react-redux'
import DropdownButton from 'react-bootstrap/DropdownButton'
import ReactTooltip from 'react-tooltip'
import _ from 'lodash'
import useDrivePicker from 'react-google-drive-picker'
import FilePicker from 'sharedComponents/SearchBox/FilePicker'
import { getGoogleSheetsUrl } from 'urls'
import axios from 'axios'
import { getAxiosDefaultConfig } from 'utils'

const mapStateToProps = (store) => ({
  accounts: store.root.accounts,
})

function SheetsDropdown({ handleData, setLoading, loading, clearModal, accounts, setGoogleUpload, googleUpload, setError, setMiscError }) {
  const [sheets, setSheets] = useState([])
  const [selectedSheet, setSelectedSheet] = useState({})
  const [googleDriveAccount, setGoogleDriveAccount] = useState(false)

  const setFileInfo = (fileName, fileId) => {
    setSelectedSheet({
      id: fileId,
      name: fileName,
    })

    if (fileName) {
      setGoogleUpload(true)
    }
  }

  useEffect(() => {
    const googleDriveAccount = accounts.find((account) => account.channel === 'Google Drive')
    if (googleDriveAccount) {
      setGoogleDriveAccount(true)
    }
  }, [accounts])

  const handleFileSelect = async () => {
    clearModal()
    setLoading(true)
    try {
      const response = await axios.get(`${getGoogleSheetsUrl}?sheetsId=${selectedSheet.id}`, getAxiosDefaultConfig())
      const sheetData = response.data
      handleData(sheetData)
      setLoading(false)
    } catch (error) {
      setError(true)
      if (error.response.data === 'Unable to parse range: Sheet1!A1:Z') {
        setMiscError('Please make sure your sheet has a tab named Sheet1')
      } else {
        setMiscError('Something went wrong processing your google sheets file. Please contact support.')
      }
      setLoading(false)
    }
  }

  return googleDriveAccount ? (
    <div className='tw-flex tw-justify-center'>
      <FilePicker setFileInfo={setFileInfo} fileName={selectedSheet.name} viewId='SHEETS' />
      {googleUpload ? <div className='tw-pl-1'>
        <Button onClick={() => handleFileSelect()}>Upload Sheets</Button>
      </div> : null}
    </div>
  ) : (
    <div className='tw-flex tw-justify-center'>
      <DropdownButton disabled variant='secondary' title="Import From Google Sheets" data-tip data-for="connect-sheets-tooltip" className='tw-inline-block'></DropdownButton>
      <ReactTooltip id="connect-sheets-tooltip">Connect your google drive account to use this feature</ReactTooltip>
    </div>
  )
}

export default connect(mapStateToProps, {})(SheetsDropdown)
