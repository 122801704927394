import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import { Button } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import Alert from 'react-bootstrap/Alert'
import { useDispatch, useSelector } from 'react-redux'
import {
  fetchApiStoreAccount,
  selectApiStoreAccount,
  selectApiStoreAccountStatus,
  updateApiStoreConfigs,
  createApiStoreAccount
} from '../../../../features/apistore/apistoreslice.js'
import MyInput from '../../../../features/forms/MyInput'
import { closeConnectionModal } from '../../integrationsSlice'
import APIKeyComponent from '../../../Settings/views/APIKey'

export default function APIStoreConnectionModalContent({ retrieveAccounts }) {
  const dispatch = useDispatch()
  const [updated, setUpdated] = useState(false)
  const [error, setError] = useState(false)
  const closeModal = () => {
    setUpdated(false)
    return dispatch(closeConnectionModal())
  }

  /**
   *
   * Handle ShipStation config
   *
   */
  const apiStoreConfigStatus = useSelector(selectApiStoreAccountStatus)
  const apiStoreConfig = useSelector(selectApiStoreAccount)
  useEffect(() => {
    if (apiStoreConfigStatus === 'idle') {
      dispatch(fetchApiStoreAccount())
    }
  }, [apiStoreConfigStatus])

  /**
   *
   * Handle form
   *
   */
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm()

  // Set default form values
  useEffect(() => {

    if (_.isEmpty(apiStoreConfig?.data)) return

    setValue('id', apiStoreConfig.data['id'])
    setValue('store_url', apiStoreConfig.data['store_url'])
    setValue('store_name', apiStoreConfig.data['store_name'])
    setValue('order_url', apiStoreConfig.data['order_url'])
    setValue('product_url', apiStoreConfig.data['product_url'])
  }, [apiStoreConfig])

  const onSubmit = async (values) => {
    // const payload = _.values(values)
    const result = await dispatch(createApiStoreAccount(values))
    if (result.meta.requestStatus === 'fulfilled') {
      await retrieveAccounts()
      setUpdated(true)
      setTimeout(() => dispatch(closeConnectionModal()), '1000')
    } else if (result.meta.requestStatus === 'rejected') {
      setError(true)
    }
    dispatch(fetchApiStoreAccount())
  }

  const urlRegex = /^(http|https):\/\/[^ "]+$/

  return (
    <div className="tw-p-6">
      <h2 className="tw-text-xl tw-mb-6">Connect Your API Store Account</h2>
      {/* {error && <Alert variant={'danger'}>Invalid policy id and email combination</Alert>} */}
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="tw-grid tw-grid-cols-1 sm:tw-grid-cols-2 lg:tw-grid-cols-4 tw-gap-4 tw-mb-6">
          <div className="tw-col-span-2">
            <MyInput
              register={register}
              name={`store_name`}
              label="Store Name"
              errors={errors}
              disabled={false}
            />
          </div>
          <div className="tw-col-span-2">
            <MyInput
              register={register}
              rules={{ required: 'Store URL is required', pattern: { value: urlRegex, message: 'Please enter a valid URL' }}}
              name={`store_url`}
              label="Store URL"
              errors={errors}
              disabled={false}
            />
          </div>
          <div className="tw-col-span-2">
            <MyInput
              register={register}
              name={`order_url`}
              rules={{ required: 'Order URL is required', pattern: { value: urlRegex, message: 'Please enter a valid URL' }}}
              label="Order URL"
              errors={errors}
              disabled={false}
              placeholder="e.g. https://{your_store_url}/orders/{order_id}"
              tooltip="This is the URL to your store's order page. This is used to link orders in ShipStream to your store. e.g. https://www.WEBSITE_URL.com/admin/order/edit/{{ORDER_ID}}"
            />
          </div>
          <div className="tw-col-span-2">
            <MyInput
              register={register}
              name={`product_url`}
              rules={{ required: 'Product URL is required', pattern: { value: urlRegex, message: 'Please enter a valid URL' }}}
              label="Product URL"
              errors={errors}
              disabled={false}
              placeholder="e.g. https://{your_store_url}/products/{product_id}"
              tooltip="This is the URL to your store's product page. This is used to link products in ShipStream to your store. e.g. https://www.WEBSITE_URL.com/admin/product/edit/{{PRODUCT_ID}}"
            />
          </div>
        </div>
        <APIKeyComponent />
        <div className="tw-flex tw-items-center tw-justify-end tw-space-x-2">
          {updated && <span className="fa fa-check" style={{color: '#5cb85c'}}/>}
          <Button type="submit" disabled={isSubmitting}>
            Save API Store Connection
          </Button>
          <Button variant="secondary" onClick={closeModal}>
            Cancel
          </Button>
        </div>
      </form>
    </div>
  )
}
