const initialState = {
  lastLoginDate: (new Date()).toString(),
  doneAction: null,
}

export default function reducer(state = initialState, action) {
  switch(action.type) {
    case "SET_LAST_LOGIN_DATE_INTERCOM": {
      return {
        ...state,
        lastLoginDate: action.payload,
      }
    }
    case "SET_DONE_ACTION_INTERCOM": {
      return {
        ...state,
        doneAction: action.payload,
      }
    }
  }
  return state
}