
import axios from "axios";
import { mimicUserUrl, unMimicUserUrl, mimicStatusUrl } from 'urls'
import { setCookie } from 'cookie'

export function unMimicUser(token) {
  return function(dispatch) {
    dispatch({type: "FETCH_UNMIMIC"});
    return axios.get(unMimicUserUrl + token, {withCredentials: true})
      .then((response) => {
        dispatch({type: "FETCH_UNMIMIC_FULFILLED", payload: response.data})
      })
      .catch((err) => {
        dispatch({type: "FETCH_UNMIMIC_REJECTED", payload: err})
    })
  }
}

export function getMimicStatus(token) {
  return function(dispatch) {
    dispatch({type: "FETCH_MIMIC_STATUS"});
    return axios.get(mimicStatusUrl + token, {withCredentials: true})
      .then((response) => {
        dispatch({type: "FETCH_MIMIC_STATUS_FULFILLED", payload: response.data})
      })
      .catch((err) => {
        dispatch({type: "FETCH_MIMIC_STATUS_REJECTED", payload: err})
    })
  }
}

export function mimicUser(user) {
  return function(dispatch) {
    dispatch({type: "FETCH_REDIRECT"});
    return axios.get(mimicUserUrl + user, {withCredentials: true})
      .then((response) => {
        setCookie('intercom-session-c8oesru0', '')
        dispatch({type: "FETCH_REDIRECT_FULFILLED", payload: response.data})
      })
      .catch((err) => {
        dispatch({type: "FETCH_REDIRECT_REJECTED", payload: err})
    })
  }
}