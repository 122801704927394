const initialState = {
  ebayVersion: null,
  ebay: []
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
  case 'SET_EBAY_CATEGORIES': {
    return {
      ...state,
      ebay: [
        ...action.payload,
      ]
    }
  }
  case 'UPDATE_EBAY_VERSION_CATEGORIES': {
    return {
      ...state,
      ebayVersion: action.payload
    }
  }
  }
  return state
}


/**
 * Check if `category` is an ancestor of `selected`
 * @param {Object} category Category to check if ancestor of selected
 * @param {Object} selected Selected category
 * @param {Array} categories All categories
 * @returns {Boolean} is `category` an ancestor of `selected`?
 */
const isAncestor = (category, selected, categories) => {
  if (selected.level === 1) return false // eslint-disable-line no-magic-numbers
  if (selected.parentId === category.id) return true
  const parentCategory = categories.find(i => i.id === selected.parentId)
  return isAncestor(category, parentCategory, categories)
}


/**
 * Selects the categories to display from the current category state tree
 * @param {Object} state Categories state tree
 * @param {String} selected Category ID currently selected
 * @returns {Array} Visible ebay categories
 */
export const getVisibleEbayCategories = (state, selected) => {
  const selectedCategory = state.ebay.find(i => i.id === selected)
  return state.ebay.filter(category => {
    if (category.id === '0') return true // show all categories option
    // eslint-disable-next-line no-magic-numbers
    if ((!selectedCategory || selectedCategory.level === 1) && category.level === 1) return true // show all root level categories
    if (selectedCategory) {
      if (selectedCategory.id === category.id) return true // show selected category
      if (selectedCategory.parentId === category.parentId) return true // show all peers of selected
      if (selectedCategory.id === category.parentId) return true // show immediate children of selected
      if (isAncestor(category, selectedCategory, state.ebay)) return true // show all ancestors of selected
    }
    return false
  })
}
