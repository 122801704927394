import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Modal, Form, Button } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import Papa from 'papaparse'
import _ from 'lodash'
import { CSVLink } from 'react-csv'
import SheetsDropdown from './SheetsDropdown'
import ErrorMessage from './ErrorMessage'
// import FileUpload from './FileUpload'
import SuccessMessage from './SuccessMessage'
import CsvProgress from './CsvProgress'
import { sanitizeData, checkFileSize, checkInvalidProperties, sampleCsvData, sampleCsvHeaders, checkInvalidSheetsProperties } from './utils'
import axios from 'axios'
import { getAxiosDefaultConfig } from 'utils'
import { uploadOrdersUrl } from 'urls'
import { postSlackMessage } from '../../applications/Integrations/integrationsSlice'
import { SLACK_DATA_STATUS_CHANNEL_ID } from '../../modules/constants'
import { selectRootUser } from '../../rootSlice'

function UploadModal({ open, closeModal }) {
  const [error, setError] = useState(false)
  const [dataErrorInfo, setDataErrorInfo] = useState([])
  const [miscError, setMiscError] = useState(null)
  const [loading, setLoading] = useState(false)
  const [fileName, setFileName] = useState(null)
  const [fileSize, setFileSize] = useState(0)
  const [successful, setSuccessful] = useState(false)
  const [successMessage, setSuccessMessage] = useState('')
  const [progress, setProgress] = useState(0)
  const [googleUpload, setGoogleUpload] = useState(false)
  const user = useSelector(selectRootUser)
  const dispatch = useDispatch()

  /**
   *
   * Handle form
   *
   */
  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm()

  const handleFileInputChange = (event) => {
    setSuccessful(false)
    setValue('csvFile', event.target.files[0])
  }

  const handleFormSubmit = (data) => {
    clearModal()
    validateData(data.csvFile[0])
    setFileName(data.csvFile[0].name)
  }

  const validateData = (data) => {
    Papa.parse(data, {
      complete: (result) => {
        const { data } = result
        handleData(data)
      },
      header: true,
      skipEmptyLines: true,
    })
  }

  const handleSheetsUpload = async (data) => {
    const sanitizedData = sanitizeData(data)
    // send to the backend
    const invalidProperties = checkInvalidSheetsProperties(data)

    if (invalidProperties.length > 0) {
      setLoading(false)
      setError(true)
      setDataErrorInfo(_.uniq(invalidProperties))
      return
    }

    try {
      const response = await axios.post(
        uploadOrdersUrl,
        {
          csvData: sanitizedData,
        },
        {
          onUploadProgress: progressEvent => {
            const progress = Math.round((progressEvent.loaded / progressEvent.total) * 100)
            setProgress(progress)
          },
          ...getAxiosDefaultConfig()
        }
      )
      const { data: message, status } = response
      if (status === 200) {
        setSuccessful(true)
        setSuccessMessage(`Successfully uploaded ${data.length} orders.`)
      }
    } catch (error) {
      setError(true)
      setMiscError(error.response.data || 'Something went wrong processing your csv file.')
    }
  }

  const handleData = async (data) => {
    setLoading(true)
    const invalidProperties = checkInvalidProperties(data)
    const fileSize = checkFileSize(data)

    dispatch(
      postSlackMessage({
        channel: SLACK_DATA_STATUS_CHANNEL_ID,
        text: "*Message* by " +user.email+ ":\n```User attempted to upload a csv file " + fileSize / 1000 + "kb```",
      })
    )

    setFileSize(fileSize)
    if (invalidProperties.length > 0) {
      setLoading(false)
      setError(true)
      setDataErrorInfo(_.uniq(invalidProperties))
      return
    }

    if (fileSize > 1000000) {
      setLoading(false)
      setError(true)
      setMiscError(`File Size is too large ${fileSize / 1000}kb, please reduce the file to less than 1000kb`)
      return
    }

    const sanitizedData = sanitizeData(data)
    // send to the backend
    try {
      const response = await axios.post(
        uploadOrdersUrl,
        {
          csvData: sanitizedData,
        },
        {
          onUploadProgress: progressEvent => {
            const progress = Math.round((progressEvent.loaded / progressEvent.total) * 100)
            setProgress(progress)
          },
          ...getAxiosDefaultConfig()
        }
      )
      const { data: message, status } = response
      if (status === 200) {
        setSuccessful(true)
        setSuccessMessage(message)
      }
    } catch (error) {
      setError(true)
      setMiscError('Something went wrong processing your csv file.')
    }

    setLoading(false)
  }

  const clearModal = () => {
    setError(false)
    setDataErrorInfo([])
    setMiscError(null)
    setSuccessful(false)
    setFileName(null)
    setGoogleUpload(false)
  }

  return (
    <Modal
      show={open}
      centered
      onHide={() => {
        closeModal()
        clearModal()
        setGoogleUpload(false)
      }}
      onExited={() => clearModal()}
    >
      <Form onSubmit={handleSubmit(handleFormSubmit)}>
        <Modal.Header>
          Upload Orders CSV Data
          <CSVLink data={sampleCsvData} headers={sampleCsvHeaders} filename={'CSV_Bulk_Orders_template.csv'}>
            <Button variant="success" size='sm'>Download CSV Template</Button>
          </CSVLink>
        </Modal.Header>
        <Modal.Body>
          {error && <ErrorMessage dataErrorInfo={dataErrorInfo} miscError={miscError} />}
          <SuccessMessage successful={successful} successMessage={successMessage} />
          <Form.Group controlId="csvFile">
            <Form.Control
              type="file"
              accept=".csv"
              size="sm"
              onChange={handleFileInputChange}
              disabled={loading}
              onClick={() => clearModal()}
              isInvalid={errors.csvFile}
              {...register('csvFile', {
                required: true,
                validate: { fileType: (value) => value[0].type === 'text/csv' },
              })}
            />
            <Form.Control.Feedback type="invalid">{errors.csvFile && 'Please select a csv file'}</Form.Control.Feedback>
          </Form.Group>
          {!errors.csvFile && fileName && dataErrorInfo.length === 0 && !miscError ? (
            <CsvProgress loading={loading} fileName={fileName} fileSize={fileSize} progress={progress} />
          ) : null}
          <div className="tw-inline-flex tw-items-center tw-justify-center tw-w-full">
            <hr className="tw-w-64 tw-h-px tw-my-8 tw-bg-gray-500 tw-border-0 tw-dark:tw-bg-gray-700" />
            <span className="tw-absolute tw-px-3 tw-font-medium tw-text-gray-900 tw--translate-x-1/2 tw-bg-white tw-left-1/2 tw-dark:tw-text-white tw-dark:tw-bg-gray-900">
              or
            </span>
          </div>
          <SheetsDropdown handleData={handleSheetsUpload} setLoading={setLoading} loading={loading} clearModal={clearModal} setGoogleUpload={setGoogleUpload} googleUpload={googleUpload} setError={setError} setMiscError={setMiscError} />
        </Modal.Body>
        <Modal.Footer>
          {loading && <span className="fa fa-spinner fa-pulse" />}
          {!googleUpload ? <Button type="submit" disabled={loading}>
            Upload
          </Button> : null}
          <Button onClick={() => closeModal()} disabled={loading}>
            Close
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}

export default UploadModal
