
export default function reducer(state={
    userList: [],
    fetching: false,
    fetched: false,
    error: null,
  }, action) {

    switch (action.type) {
      case "FETCH_USER_LIST": {
        return {...state, fetching: true}
      }
      case "FETCH_USER_LIST_REJECTED": {
        return {...state, fetching: false, error: action.payload}
      }
      case "FETCH_USER_LIST_FULFILLED": {
        return {
          ...state,
          fetching: false,
          fetched: true,
          userList: action.payload,
        }
      }
    }

    return state
}