import numeral from 'numeral'
import moment from 'moment'
import React from 'react'
import { logoUrls } from 'urls'
import { countryCodeDict } from 'countries'
import states from 'us-state-converter'

export const number_formatter = val => numeral(val || 0).format('0,0')
export const number_decimal_formatter = val => numeral(val || 0).format('0,0.0')
export const amount_formatter = val => numeral(val || 0).format('$0,0.00')
export const percentage_formatter = val => numeral(val || 0).format('0.00%')
export const year_month_day = val => moment(val).format('YYYY-MM-DD')
export const date_formatter = (val) => val ? moment(val).format('YYYY-MM-DD h:mm:ssA') : 'N/A'
export const month_formatter = val => moment(val).format('MMM YYYY')
export const currency_value_formatter = (val) => numeral(val || 0).format('0,0.00')
export const rank_formatter = (val) => (val || 101) > 100 ? 'More than 100' : val.toFixed(0)
// export const duration_formatter = (val) => val ? moment.utc(val * 1000).format('d [days, ] HH:mm:ss') : 'N/A'
export const duration_formatter = (val) => val ? moment.duration(val * 1000).humanize() : 'N/A'
export const download_formatter = (val) => <span><i class="fa fa-cloud-download mr1"/> {val}</span>

export const dynamic_province_formatter = (type) => {
  switch (type) {
    case 'name':
      return (val) => val ? states.fullName(val) : 'N/A'
    case 'abbreviation':
      return (val) => val ? states.abbr(val) : 'N/A'
    case 'default':
      return (val) => val || 'N/A'
  }
}

export const dynamic_date_formatter = (type) => {
  switch (type) {
    case 'YYYY-MM-DD':
      return (val) => val ? moment(val).format('YYYY-MM-DD') : 'N/A'
    case 'MM/DD/YYYY':
      return (val) => val ? moment(val).format('MM/DD/YYYY') : 'N/A'
    case 'DD-MM-YYYY':
      return (val) => val ? moment(val).format('DD-MM-YYYY') : 'N/A'
    case 'YYYY-MM-DD HH:mm:ss':
      return (val) => val ? moment(val).format('YYYY-MM-DD HH:mm:ss') : 'N/A'
    case 'HH:mm:ss':
      return (val) => val ? moment(val).format('HH:mm:ss') : 'N/A'
    case 'HH:mm':
      return (val) => val ? moment(val).format('HH:mm') : 'N/A'
    case 'MM-YYYY':
      return (val) => val ? moment(val).format('MM-YYYY') : 'N/A'
    case 'YYYY-MM':
      return (val) => val ? moment(val).format('YYYY-MM') : 'N/A'
    default:
      return (val) => val ? moment(val).format('YYYY-MM-DD h:mm:ssA') : 'N/A'
  }
}

// For Highcharts
export const numberFormat = {
  format: '0,0',
  axis_format:  '0,0',
  axis_format_big: '0a'
}
export const numberDecimalFormat = {
  format: '0,0.0',
  axis_format:  '0,0',
  axis_format_big: '0,0a'
}
export const currencyValueFormat = {
  format: '0,0.00',
  axis_format:  '0,0',
  axis_format_big: '0a'
}
export const currencyFormat = {
  format: '$0,0.00',
  axis_format:  '$0,0',
  axis_format_big: '$0a'
}
export const rankFormat = {
  format: '0,0.0',
  axis_format:  '0,0',
  axis_format_big: '0,0a'
}
export const percentageFormat = {
  format: '0.00%',
  axis_format: '0.0%',
  axis_format_big: '0%',
}
export const durationFormat = {
  formatter: (val) => val ? moment.duration(val * 1000).humanize() : 'N/A'
}

export const currency_formatter = (val, currency) => {
  if ((val < 0 && val > -0.004999) || (val > 0 && val < 0.004999)) val = 0 // catch floating point errors.
  return (currency && currency !== 'USD') ? (currency + ' ' + numeral(val || 0).format('0,0.00')) : numeral(val || 0).format('$0,0.00')
}

export const csv_currency_formatter = (val) => {
  if ((val < 0 && val > -0.004999) || (val > 0 && val < 0.004999)) val = 0 // catch floating point errors.
  return numeral(val || 0).format('0.00')
}

export const micro_currency_formatter = (val, currency) => {
  if ((val < 0 && val > -0.004999) || (val > 0 && val < 0.004999)) val = 0 // catch floating point errors.
  return (currency && currency !== 'USD') ? (currency + ' ' + numeral(val / 1000000 || 0).format('0,0.00')) : numeral(val / 1000000 || 0).format('$0,0.00')
}

// All available keys: ['images', 'title', 'seller_username', 'items_sold', 'avg_price', 'total_sales', 'avg_shipping_price', 'avg_rank', 'top_rank']
export const ebayItemFormatters = {
  titles: {
    images: 'Listing',
    title: 'Title',
    seller_username: 'Seller',
    items_sold: 'Items Sold',
    avg_price: 'Avg. Price',
    total_sales: 'Total Sales',
    avg_shipping_price: 'Avg. Ship Price',
    avg_rank: 'Avg. Rank',
    top_rank: 'Top Rank',
  },
  formatters: {
    items_sold: number_formatter,
    avg_price: currency_formatter,
    total_sales: currency_formatter,
    avg_shipping_price: currency_formatter,
  },
  linkTo: {
    images: 'item_link',
    title: 'item_link',
    seller_username: 'seller_link',
  },
  tooltipTo: {
    images: 'title',
  },
  subContentFormatters: {
    seller_username: (el) => `Item ${el.item_id}`,
    items_sold: (el) => `${number_formatter(el.total_items_sold)} all time`
  }
}

// For multi-currency cases
export const getCurrencyStatFormatter = (currencies) => {
  if (currencies.length > 1) {
    return {
      formatter: currency_value_formatter,
      axisFormatter: val => numeral(val).format(currencyValueFormat.axis_format),
      axisFormatterBig: val => numeral(val).format(currencyValueFormat.axis_format_big),
    }
  }
  else {
    const currencyPrefix = (currencies[0] && (currencies[0] !== 'USD')) ? `${currencies[0]} ` : '$'
    return {
      formatter: val => currency_formatter(val, currencies[0]),
      axisFormatter: val => currencyPrefix + numeral(val).format(currencyValueFormat.axis_format),
      axisFormatterBig: val => currencyPrefix + numeral(val).format(currencyValueFormat.axis_format_big),
    }
  }
}

/**
 * Formatter for elements in component ListTable
 * @param {*} val The raw value of a certain field (total_sales, total_items_sold etc.) to fill in the current cell
 * @param {*} currency Speicied currency (left null in this function)
 * @param {Object} el The element to fill in the current row, which can carry `currencies` properties
 */
export const elementCurrencyFormatter = (val, currency, el) => {
  const { currencies = []} = el
  if (currencies.length > 1) {
    return currency_value_formatter(val)
  }
  else {
    return currency_formatter(val, currencies[0] || currency)
  }
}

const channelTitles = {
  'paypal': 'PayPal',
  'shopify': 'Shopify',
  'bigcommerce': 'BigCommerce',
  'google': 'Google',
}

export const merchantIdFormatters = {
  merchant_id: (val, currency, el) => {
    return (
      <div className="flex-box v-center">
        <img className="filter-entry-icon mr1" src={logoUrls[el.channel]}/>
        {el?.merchant_email || val}
      </div>
    )
  },
  merchant_id_csv: (val, currency, el) => `${el?.merchant_email || val}${el?.channel ? ' (' + channelTitles[el.channel] + ')' : ''}`
}

export const arrayFormatters = ({withLink} = {}) => ({
  array: (val = [], currency, el) => {
    if (val?.length) {
      let entries = []
      let suffix = Math.random().toString().substr(2,8)
      for (var index in val) {
        if (index == 3) {
          entries.push(<input type="radio" name={`${el.uniqueKey}-${suffix}`} id={`${el.uniqueKey}-${suffix}-show`} className="hide hide-after" key="show-btn"/>)
          entries.push(<label className="float-right cursor-pointer" htmlFor={`${el.uniqueKey}-${suffix}-show`} key="show-label"><a>...</a></label>)
        }
        entries.push(
          <div key={index} className="wrap-pre">
            {
              withLink ? (
                <a target="_blank" href={val[index].link}>{val[index].title}</a>
              ) : val[index]
            }
          </div>
        )
      }
      if (val.length > 3) {
        entries.push(<input type="radio" name={`${el.uniqueKey}-${suffix}`} id={`${el.uniqueKey}-${suffix}-hide`} className="hide" key="hide-btn"/>)
        entries.push(<label className="float-right cursor-pointer" htmlFor={`${el.uniqueKey}-${suffix}-hide`} key="hide-label"><a>...</a></label>)
      }
      return entries
    }
    return 'N/A'
  },
  array_csv: (val = [], currency, el) => {
    if (val?.length) {
      return (withLink ? val.map(v => v.title) : val).join('\n')
    }
    return 'N/A'
  }
})

export const nameValueArrayFormatters = {
  array: (val = [], currency, el) => {
    if (val?.length) {
      let entries = []
      for (var index in val) {
        if (index == 3) {
          entries.push(<input type="radio" name={el.uniqueKey} id={`${el.uniqueKey}-show`} className="hide hide-after" key="show-btn"/>)
          entries.push(<label className="float-right cursor-pointer" htmlFor={`${el.uniqueKey}-show`} key="show-label"><a>...</a></label>)
        }
        const [name, ...rest] = val[index].split(':')
        entries.push(rest.length ? <div key={index} className="wrap-pre"><b>{name}</b>:&nbsp;{rest.join(':')}</div> : <div key={index} className="wrap-pre"><b>{name}</b></div>)
      }
      if (val.length > 3) {
        entries.push(<input type="radio" name={el.uniqueKey} id={`${el.uniqueKey}-hide`} className="hide" key="hide-btn"/>)
        entries.push(<label className="float-right cursor-pointer" htmlFor={`${el.uniqueKey}-hide`} key="hide-label"><a>...</a></label>)
      }
      return entries
    }
    return 'N/A'
  },
  array_csv: (val = [], currency, el) => val ? val.map(entry => entry.replace(/,/g, ' ')).join('\n') : 'N/A'
}

export const customFieldPreFormatter = (configs, orders) => {
  if (!configs || !configs.length || !orders || !orders.length) return orders
  for (let config of configs) {
    for (let order of orders) {
      if (order[config.target] && order[config.target].length) {
        order[config.target] = order[config.target].filter(field => {
          return field[config.operation](new RegExp(config.parameter))
        })
        if (config.separator) {
          order[config.target] = order[config.target].map(el => el.split(config.separator)[config.desiredPosition])
        }
        for (let lineItem of order.line_items) {
          if (lineItem[config.target]) {
            lineItem[config.target] = lineItem[config.target].filter(field => {
              return field[config.operation](new RegExp(config.parameter))
            })
            lineItem[config.target] = lineItem[config.target].map(el => el.split(config.separator)[config.desiredPosition])
          }
        }
      }
    }
  }
  return orders
}

export const arrayElementFormatter = (formatter) => {
  return (array = []) => {
    return array.length ? array.map(formatter) : 'N/A'
  }
}

export const arrayElementFormatterCsv = (formatter) => {
  return (array = []) => {
    return array.length ? array.map(formatter).join('\n') : 'N/A'
  }
}

export const customerContactFormatters = {
  customer_email: email => email ? <a href={`mailto:${email}`}>{email}</a> : 'N/A',
  customer_phone: phone => phone ? <a href={`tel:${phone}`}>{phone}</a> : 'N/A',
  customer_email_csv: email => email,
  customer_phone_csv: phone => phone,
}

// Note: value needs to be a moment object
export const axisDateFormatterGroupBy = groupBy => {
  switch(groupBy) {
    case 'Hour':
      return value => value?.format && `${value.format('MMM D hA')}`
    case 'Week':
      return value => value?.start?.format && value?.end?.format &&
        `${value.start.format('MMM D')} - ${value.end.format('MMM D')}`
    case 'Month':
      return value => value?.start?.format && `${value.start.format('MMM')}`
    case 'Quarter':
      return value => value?.start?.get &&
        `${value.start.get('year')} Q${(Math.floor(value.start.get('month') / 3) + 1)}`
    default:
      return value => value?.format && `${value.format('MMM D')}`
  }
}
// Note: value needs to be a moment object
export const dateFormatterGroupBy = groupBy => {
  switch(groupBy) {
    case 'Hour':
      return value => value?.format && `${value?.format('MMM D YYYY hA')}`
    case 'Week':
      return value => value?.start?.format && value?.end?.format &&
        `${value.start.format('MMM D YYYY')} - ${value.end.format('MMM D YYYY')}`
    case 'Month':
      return value => value?.start?.format && `${value.start.format('MMM YYYY')}`
    case 'Quarter':
      return value => value?.start?.get &&
        `${value.start?.get('year')} Q${(Math.floor(value.start.get('month') / 3) + 1)}`
    default:
      return value => value?.format && `${value.format('MMM D YYYY')}`
  }
}

export const stripCurrencyFormattersForExport = (data) => {
  const ret = _.transform(data, (acc, val, key) => ['currency_formatter', 'elementCurrencyFormatter', 'formatter'].includes(val?.name) ? acc[key] = csv_currency_formatter : acc[key] = val, {})
  return ret
}

// Field: either shipping_country or billing_country
export const countryFormatter = field => {
  return (val, currency, el) => {
    return val || countryCodeDict[el[`${field}_code`]] || el[`${field}_code`]
  }
}