import React from 'react'
import { Navbar, Nav, NavItem, NavDropdown, Dropdown, Container } from 'react-bootstrap'
import { redirectHomepage } from 'loginControl'
import { connect } from 'react-redux'
import { changeApplication, signOut } from 'actions/rootActions'
import { unMimicUser, getMimicStatus } from 'actions/mimicActions'
import NavLinkContainer from 'sharedComponents/NavLinkContainer'
import { getCookie } from 'cookie'
import Logo from '../../assets/images/payhelm-logo-blue.png'
import './loader.css'

@connect(
  (store) => ({
    currentApp: store.root.applicationId,
    accounts: store.root.accounts,
    topUrl: store.root.topUrl,
    admin: store.root.admin,
    mimicStatus: store.mimic.mimic.status,
    subscriptionActive: store.plan.subscriptionActive,
    upgradeDismissed: store.plan.upgradeDismissed,
    sessionSignature: store.root.sessionSignature,
    customerBalance: store.plan.customerBalance,
    progressBar: store.dashboard.progressBar,
    fetching: store.dashboard.fetching,
    inventoryLoading: store.inventory.inventoryLoading,
  }),
  { changeApplication, unMimicUser, getMimicStatus, signOut }
)
export default class NavbarHeader extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      progressBar: null,
    }
  }

  /**
   * Sign out and redirect to www homepage
   */
  signOut = () => {
    this.props.signOut().then(() => {
      redirectHomepage('You have signed out', 'info')
    })
  }

  unMimic() {
    this.props.unMimicUser(JSON.parse(getCookie('mimic_token').replace('j:', '')).token).then(() => {
      window.intercomSettings = undefined
      window.location.href = `${window.location.protocol}//${window.location.host}/statistics`
    })
  }

  componentDidMount() {
    let mimic_token = 'none'
    if (getCookie('mimic_token') != '') {
      mimic_token = JSON.parse(getCookie('mimic_token').replace('j:', '')).token
    }
    this.props.getMimicStatus(mimic_token)
  }

  /**
   * On select callback for nav items
   * @param {String} nextApp name of the clicked app
   */
  onSelect = (nextApp) => {
    this.props.changeApplication(this.props.currentApp, nextApp)
  }

  render() {
    const {
      admin,
      subscriptionActive,
      upgradeDismissed,
      hasAccounts,
      sessionSignature,
      customerBalance,
      progressBar,
      accessPermissions,
      fetching,
      inventoryLoading,
      isLite,
    } = this.props
    const shopifyAccess = this.props.topUrl && this.props.topUrl.includes('.myshopify.')
    const hasPaypalLogin = !!this.props.accounts.find((account) => account.channel === 'Paypal')
    const status = this.props.mimicStatus

    let showBalance = customerBalance ? <span className="pr1">${customerBalance.toFixed(2)}</span> : null
    const currentDate = new Date().toISOString().split('T')[0]
    return (
      <>
        <Navbar bsPrefix="navbar" fixed="top" bg="light" expand="lg">
          <Navbar.Brand>
            <a href="/home">
              <img src={Logo} style={{ padding: 10, height: 49 }} />
            </a>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse style={{ height: 52 }}>
            {(subscriptionActive || isLite || upgradeDismissed || status) ? (
              <Nav activeKey={this.props.currentApp} className="full-height">
                {hasAccounts ? (
                  <Nav.Item as="li" key="dashboard" className="full-height">
                    <Nav.Link
                      href="/dashboard"
                      eventKey="dashboard"
                      className="full-height flex-box justify-center align-center"
                    >
                      <NavLinkContainer to="/dashboard" onClick={() => this.onSelect('dashboard')} type="nav-item">
                        Dashboard
                      </NavLinkContainer>
                    </Nav.Link>
                  </Nav.Item>
                ) : null}
                {hasAccounts ? (
                  <Nav.Item key="traffic" as="li" className="full-height">
                    <Nav.Link
                      href="/traffic"
                      eventKey="traffic"
                      className="full-height flex-box justify-center align-center"
                    >
                      <NavLinkContainer to="/traffic" onClick={() => this.onSelect('traffic')} type="nav-item">
                        Traffic
                      </NavLinkContainer>
                    </Nav.Link>
                  </Nav.Item>
                ) : null}
                {hasAccounts ? (
                  <Nav.Item as="li" key="reports" className="full-height">
                    <Nav.Link
                      href="/reports"
                      eventKey="reports"
                      className="full-height flex-box justify-center align-center"
                    >
                      <NavLinkContainer to="/reports" onClick={() => this.onSelect('reports')} type="nav-item">
                        Reports
                      </NavLinkContainer>
                    </Nav.Link>
                  </Nav.Item>
                ) : null}
                {hasAccounts ? (
                  <Nav.Item key="integrations" as="li" className="full-height">
                    <Nav.Link
                      href="/integrations"
                      eventKey="integrations"
                      className="full-height flex-box justify-center align-center"
                    >
                      <NavLinkContainer
                        to="/integrations"
                        onClick={() => this.onSelect('integrations')}
                        type="nav-item"
                      >
                        Integrations
                      </NavLinkContainer>
                    </Nav.Link>
                  </Nav.Item>
                ) : null}
                {hasAccounts ? (
                  <Nav.Item as="li" key="custom" className="full-height">
                    <Nav.Link
                      href="/custom"
                      eventKey="custom"
                      className="full-height flex-box justify-center align-center"
                    >
                      <NavLinkContainer to="/custom" onClick={() => this.onSelect('custom')} type="nav-item">
                        Custom Reports
                      </NavLinkContainer>
                    </Nav.Link>
                  </Nav.Item>
                ) : null}
                {hasAccounts ? (
                  <Nav.Item key="AI" as="li" className="full-height">
                    <Nav.Link href="/ai" eventKey="AI" className="full-height flex-box justify-center align-center">
                      <NavLinkContainer to="/ai" onClick={() => this.onSelect('AI')} type="nav-item">
                        Ask AI
                        <div style={{
                          position: 'absolute', zIndex: 1, color: 'white', backgroundColor: 'red', fontSize: 12, fontWeight: 700,
                          borderRadius: 24, padding: 4, marginTop: -8, marginLeft: 36,
                        }}>Try!</div>
                      </NavLinkContainer>
                    </Nav.Link>
                  </Nav.Item>
                ) : null}
                {hasAccounts ? (
                  <Nav.Item as="li" key="emailcampaigns" className="full-height">
                    <Nav.Link
                      href="/emailcampaigns"
                      eventKey="emailcampaigns"
                      className="full-height flex-box justify-center align-center"
                    >
                      <NavLinkContainer
                        to="/emailcampaigns"
                        onClick={() => this.onSelect('emailcampaigns')}
                        type="nav-item"
                      >
                        Email Campaigns
                      </NavLinkContainer>
                    </Nav.Link>
                  </Nav.Item>
                ) : null}
                {hasAccounts ? (
                  <Nav.Item as="li" key="shipping" className="full-height">
                    <Nav.Link
                      href={`/shipping?dateType=processed_at&q=%7B"filters"%3A%7B"fulfillment_status"%3A%5B"Shipped"%2C"fulfilled"%2C"pending"%5D%7D%7D&processed_at=${currentDate}to${currentDate}`}
                      eventKey="shipping"
                      className="full-height flex-box justify-center align-center"
                    >
                      <NavLinkContainer
                        to={`/shipping?dateType=processed_at&q=%7B"filters"%3A%7B"fulfillment_status"%3A%5B"Shipped"%2C"fulfilled"%2C"pending"%5D%7D%7D&processed_at=${currentDate}to${currentDate}`}
                        onClick={() => this.onSelect('shipping')}
                        type="nav-item"
                      >
                        Shipping
                      </NavLinkContainer>
                    </Nav.Link>
                  </Nav.Item>
                ) : null}
                {hasAccounts ? (
                  <Nav.Item key="inventory" as="li" className="full-height">
                    <Nav.Link
                      href="/inventory"
                      eventKey="inventory"
                      className="full-height flex-box justify-center align-center"
                    >
                      <NavLinkContainer to="/inventory" onClick={() => this.onSelect('inventory')} type="nav-item">
                        Inventory
                      </NavLinkContainer>
                    </Nav.Link>
                  </Nav.Item>
                ) : null}
                {/* {hasPaypalLogin ? (
                  <Nav.Item as="li" key="subscribers" className="full-height">
                    <Nav.Link
                      href="/subscribers"
                      eventKey="subscribers"
                      className="full-height flex-box justify-center align-center"
                    >
                      <NavLinkContainer to="/subscribers" onClick={() => this.onSelect('subscribers')} type="nav-item">
                        Subscribers
                      </NavLinkContainer>
                    </Nav.Link>
                  </Nav.Item>
                ) : null} */}
                {/* {admin ? (
                  <Nav.Item as="li" key="staff" className="full-height">
                    <Nav.Link
                      href="/staff"
                      eventKey="staff"
                      className="full-height flex-box justify-center align-center"
                    >
                      <NavLinkContainer to="/staff" onClick={() => this.onSelect('staff')} type="nav-item">
                        Staff
                      </NavLinkContainer>
                    </Nav.Link>
                  </Nav.Item>
                ) : null}
                {admin ? (
                  <Nav.Item as="li" key="statistics" className="full-height">
                    <Nav.Link
                      href="/statistics"
                      eventKey="statistics"
                      className="full-height flex-box justify-center align-center"
                    >
                      <NavLinkContainer to="/statistics" onClick={() => this.onSelect('statistics')} type="nav-item">
                        Statistics
                      </NavLinkContainer>
                    </Nav.Link>
                  </Nav.Item>
                ) : null}
                {admin ? (
                  <Nav.Item key="backfills" className="full-height">
                    <Nav.Link
                      href="/backfills"
                      eventKey="backfills"
                      className="full-height flex-box justify-center align-center"
                    >
                      <NavLinkContainer to="/backfills" onClick={() => this.onSelect('backfills')} type="nav-item">
                        Backfills
                      </NavLinkContainer>
                    </Nav.Link>
                  </Nav.Item>
                ) : null} */}
              </Nav>
            ) : null}
            <Nav className="ms-auto mr2">
              <NavDropdown
                key={3}
                title={
                  <>
                    {showBalance}
                    <span className="fa fa-user-circle" />
                  </>
                }
                id="basic-nav-dropdown"
                align="end"
              >
                <Dropdown.Item eventKey="plan" active={this.props.currentApp === 'plan'} href="/plan">
                  <NavLinkContainer to="/plan" onClick={() => this.onSelect('plan')} type="nav-dropdown">
                    <div
                      className="flex-box"
                      style={this.props.currentApp === 'plan' ? { backgroundColor: '#0D6EFD' } : null}
                    >
                      <span className="flex-1">My Plan</span>
                      <span>
                        <span className="fa fa-columns mr01" />
                      </span>
                    </div>
                  </NavLinkContainer>
                </Dropdown.Item>
                <Dropdown.Item eventKey="settings" active={this.props.currentApp === 'settings'} href="/settings">
                  <NavLinkContainer to="/settings" onClick={() => this.onSelect('settings')} type="nav-dropdown">
                    <div
                      className="flex-box"
                      style={this.props.currentApp === 'settings' ? { backgroundColor: '#0D6EFD' } : null}
                    >
                      <span className="flex-1">Settings</span>
                      <span>
                        <span className="fa fa-cog mr01" />
                      </span>
                    </div>
                  </NavLinkContainer>
                </Dropdown.Item>
                <Dropdown.Item eventKey="faq" active={this.props.currentApp === 'faq'} href="/faq">
                  <NavLinkContainer to="/faq" onClick={() => this.onSelect('faq')} type="nav-dropdown">
                    <div
                      className="flex-box"
                      style={this.props.currentApp === 'faq' ? { backgroundColor: '#0D6EFD' } : null}
                    >
                      <span className="flex-1">Support</span>
                      <span>
                        <span className="fa fa-question-circle mr01" />
                      </span>
                    </div>
                  </NavLinkContainer>
                </Dropdown.Item>
                {admin ? (
                  <>
                    <Dropdown.Item eventKey="staff" active={this.props.currentApp === 'staff'} href="/staff">
                      <NavLinkContainer to="/staff" onClick={() => this.onSelect('staff')} type="nav-dropdown">
                        <div
                          className="flex-box"
                          style={this.props.currentApp === 'staff' ? { backgroundColor: '#0D6EFD' } : null}
                        >
                          <span className="flex-1">Staff</span>
                          <span>
                            <span className="fa fa-users mr01" />
                          </span>
                        </div>
                      </NavLinkContainer>
                    </Dropdown.Item>
                    <Dropdown.Item eventKey="statistics" active={this.props.currentApp === 'statistics'} href="/statistics">
                      <NavLinkContainer to="/statistics" onClick={() => this.onSelect('statistics')} type="nav-dropdown">
                        <div
                          className="flex-box"
                          style={this.props.currentApp === 'statistics' ? { backgroundColor: '#0D6EFD' } : null}
                        >
                          <span className="flex-1">Statistics</span>
                          <span>
                            <span className="fa fa-bar-chart mr01" />
                          </span>
                        </div>
                      </NavLinkContainer>
                    </Dropdown.Item>
                    <Dropdown.Item eventKey="backfills" active={this.props.currentApp === 'backfills'} href="/backfills">
                      <NavLinkContainer to="/backfills" onClick={() => this.onSelect('backfills')} type="nav-dropdown">
                        <div
                          className="flex-box"
                          style={this.props.currentApp === 'backfills' ? { backgroundColor: '#0D6EFD' } : null}
                        >
                          <span className="flex-1">Backfills</span>
                          <span>
                            <span className="fa fa-refresh mr01" />
                          </span>
                        </div>
                      </NavLinkContainer>
                    </Dropdown.Item>
                  </>
                ) : null}
                {status ? (
                  <Dropdown.Item eventKey={3.4} onClick={() => this.unMimic()}>
                    <div className="flex-box">
                      <span className="flex-1">Unmimic</span>
                      <span>
                        <span className="fa fa-ban mr01" />
                      </span>
                    </div>
                  </Dropdown.Item>
                ) : null}
                {shopifyAccess || sessionSignature ? null : <Dropdown.Divider />}
                {shopifyAccess || sessionSignature ? null : (
                  <Dropdown.Item eventKey={3.3} onClick={this.signOut}>
                    <div className="flex-box">
                      <span className="flex-1">Sign out</span>
                      <span>
                        <span className="fa fa-sign-out mr01" />
                      </span>
                    </div>
                  </Dropdown.Item>
                )}
              </NavDropdown>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
        {progressBar && (
          <div className="meter blue">
            <span style={{ width: '100%' }} />
          </div>
        )}
        {fetching.order && !progressBar && (
          <div className="meter blue">
            <span style={{ width: '100%' }} />
          </div>
        )}
        {inventoryLoading && (
          <div className="meter blue">
            <span style={{ width: '100%' }} />
          </div>
        )}
      </>
    )
  }
}
