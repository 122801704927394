import React, { useCallback, useEffect, useState } from 'react'
import axios from 'axios'
import _ from 'lodash'
import { Button } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { getAxiosDefaultConfig } from 'utils'
import { checkSlackConnectionUrl } from 'urls'
import { useDispatch, useSelector } from 'react-redux'
import {
  fetchSlackConfigs,
  selectSlackConfig,
  selectSlackConfigStatus,
  updateSlackConfigs,
} from '../../../Settings/settingsSlice'
import MyCheckbox from '../../../../features/forms/MyCheckbox'

export default function SlackSettingsModalContent() {
  const dispatch = useDispatch()

  /**
   *
   * Handle Slack config
   *
   */
  const slackConfigStatus = useSelector(selectSlackConfigStatus)
  const slackConfig = useSelector(selectSlackConfig)
  useEffect(() => {
    if (slackConfigStatus === 'idle') {
      dispatch(fetchSlackConfigs())
    }
  }, [slackConfigStatus])

  const configGroupTitlesMap = {
    paypal: 'Paypal',
    shopify: 'Shopify',
    bigcommerce: 'Big Commerce',
    subscriptions: 'Paypal Subscriptions',
  }

  /**
   *
   * Handle slack connection status
   *
   */
  const [slackConnected, setSlackConnected] = useState(false)
  useEffect(() => {
    const fn = async () => {
      try {
        const slackRes = await axios.get(checkSlackConnectionUrl, getAxiosDefaultConfig())
        const slackConnectionStatus = slackRes?.data?.status
        if (slackConnectionStatus) {
          setSlackConnected(slackConnectionStatus)
        }
      } catch (err) {
        console.log('Error while fetching Slack connection status', err)
      }
    }
    fn()
  }, [])

  /**
   *
   * Handle form
   *
   */
  const {
    register,
    handleSubmit,
    setValue,
    formState: { isSubmitting },
  } = useForm()

  const getFieldsByGroup = useCallback((config) => {
    return _.chain(config)
      .toPairs()
      .map(([configItemKey, configItemValue]) => {
        const fields = _.chain(configItemValue)
          .toPairs()
          .map(([k, v]) => {
            return { name: `${configItemKey}.${k}`, value: v.value, title: v.title }
          })
          .value()
        return { groupName: configItemKey, fields }
      })
      .value()
  }, [])

  const formValuesToDbPayload = useCallback((config, formValues) => {
    return _.chain(config)
      .toPairs()
      .map(([groupName, fields]) => {
        const newFields = _.chain(fields)
          .toPairs()
          .map(([fieldName, obj]) => [fieldName, { ...obj, value: _.get(formValues, [groupName, fieldName]) }])
          .fromPairs()
          .value()
        return [groupName, newFields]
      })
      .fromPairs()
      .value()
  }, [])

  // Set default form values
  useEffect(() => {
    if (_.isEmpty(slackConfig)) return

    getFieldsByGroup(slackConfig).forEach(({ fields }) => fields.forEach(({ name, value }) => setValue(name, value)))
  }, [slackConfig])

  // Handle submit
  const onSubmit = (values) => {
    const payload = formValuesToDbPayload(slackConfig, values)
    dispatch(updateSlackConfigs(payload))
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {slackConfig && slackConfig.length > 0 && (
        <div>
          {getFieldsByGroup(slackConfig).map(({ groupName, fields }) => {
            return (
              <div
                key={groupName}
                className="tw-rounded-lg tw-bg-white/40 tw-border tw-border-slate-300 tw-p-6 tw-shadow-sm tw-mt-6 tw-mb-6"
              >
                <div className="tw-font-base tw-font-semibold tw-mb-4">{configGroupTitlesMap[groupName]}</div>
                <div className="tw-grid tw-grid-cols-1 sm:tw-grid-cols-2 md:tw-grid-cols-3 lg:tw-grid-cols-4 xl:tw-grid-cols-6 tw-gap-x-4 tw-gap-y-3">
                  {fields.map(({ name, title }) => (
                    <div key={name} className="tw-cols-span-1 tw-text-slate-600">
                      <MyCheckbox register={register} name={name} label={title} />
                    </div>
                  ))}
                </div>
              </div>
            )
          })}
        </div>
      )}

      <Button type="submit" disabled={isSubmitting}>
        Save Slack Settings
      </Button>
    </form>
  )
}
