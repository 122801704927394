import axios from 'axios'
import {
  getBackfill,
  getShopifyBackfill,
  getPaypalBackfill,
  getMerchantPaypalBackfill,
  getBigcommerceBackfill,
  getWoocommerceBackfill,
  getBackfillStatus,
  getBackfillRunningStatus,
} from 'urls'
import { setCookie } from 'cookie'
import _ from 'lodash'
import { localStoragePayhelm } from 'modules/utils'
import { getTriggerBackfillDetails } from '../features/backfill/helpers'

let backfillInterval, runningBackfillInterval

export const fetchBackfill = (user, channel, options) => {
  return async (dispatch, getState) => {

    // let backfillUrl = getBackfill
    // let _user = user
    // if (channel === 'Shopify') {
    //   backfillUrl = getShopifyBackfill
    //   if (options && options.order) {
    //     backfillUrl += '_for_order'
    //   }
    //   else if (options) {
    //     backfillUrl += options.type ? `_${options.type}_date` : `_days`
    //   }
    //   _user = user.match(/.myshopify.com$/) ? user : user + '.myshopify.com'
    // }
    // else if (channel === 'Paypal') {
    //   backfillUrl = getPaypalBackfill
    // }
    // else if (channel === 'Paypal Merchant') {
    //   backfillUrl = getMerchantPaypalBackfill
    // }
    // else if (channel === 'Bigcommerce') {
    //   backfillUrl = getBigcommerceBackfill
    //   if (options && options.order) {
    //     backfillUrl += '_for_order'
    //   }
    //   else if (options) {
    //     backfillUrl += options.type ? `_${options.type}_date` : `_days`
    //   }
    // }
    // else if (channel === 'Woocommerce') {
    //   backfillUrl = getWoocommerceBackfill
    //   if (options) {
    //     backfillUrl += options.type ? `_${options.type}_date` : `_days`
    //   }
    // }
    // else {
    //   backfillUrl = getBackfill
    // }

    const { user: _user, url: backfillUrl, startDate, endDate, } = getTriggerBackfillDetails(user, channel, options)
    const timezone = _.get(getState(), ['root', 'user', 'default_timezone'], 'utc')
    dispatch({ type: 'FETCH_BACKFILL' })

    try {
      const { data: response } = await axios.post(backfillUrl, { user_id: _user, timezone, custom_start_date: startDate, custom_end_date: endDate, ...options })
      const nextBackfillToken = [localStoragePayhelm.backfillToken, response.id].filter((i) => i).join(',')
      localStoragePayhelm.backfillToken = nextBackfillToken
      let backfills = getState().backfill.backfill
      if (!backfills.map((b) => b.id).includes(response.id)) {
        backfills.push(response)
      }
      dispatch({ type: 'FETCH_FULFILLED_BACKFILL', payload: backfills })
      if (!backfillInterval) {
        backfillInterval = setTimeout(() => fetchBackfillStatus(nextBackfillToken)(dispatch), 3000)
      }
    } catch (err) {
      console.log('fetchBackfill err', err)
      clearTimeout(backfillInterval)
      delete localStoragePayhelm.backfillToken
      dispatch({ type: 'FETCH_REJECTED_BACKFILL', payload: err })
    }
  }
}

export const fetchBackfillStatus = (token, merchantIds) => {
  return async (dispatch) => {
    dispatch({ type: 'FETCH_BACKFILL' })
    try {
      const { data: response } = await axios.post(getBackfillStatus, { token, merchantIds })
      const backfillsInProgress = response.filter((r) => r.status === 'running')
      const idsInProgress = backfillsInProgress.map((r) => r.id).join(',')
      if (response.length) {
        if (token) {
          // Follow-up fetch
          dispatch({ type: 'FETCH_FULFILLED_BACKFILL', payload: response })
        } else if (backfillsInProgress.length) {
          // Initial fetch
          dispatch({ type: 'FETCH_FULFILLED_BACKFILL', payload: backfillsInProgress })
        }
      }
      if (idsInProgress.length) {
        backfillInterval = setTimeout(() => fetchBackfillStatus(idsInProgress)(dispatch), 3000)
        localStoragePayhelm.backfillToken = idsInProgress
      } else {
        clearTimeout(backfillInterval)
        backfillInterval = null
        delete localStoragePayhelm.backfillToken
        setCookie('backfill_token', '')
        setTimeout(() => {
          dispatch({ type: 'RESET_BACKFILL' })
        }, 3000)
      }
    } catch (err) {
      clearTimeout(backfillInterval)
      backfillInterval = null
      dispatch({ type: 'FETCH_REJECTED_BACKFILL', payload: err })
    }
  }
}

export const fetchRunningBackfills = () => {
  return async (dispatch, getState) => {
    dispatch({ type: 'FETCH_BACKFILL' })
    try {
      const { runningBackfill, finishedBackfill, failedBackfill, adminPage } = getState().backfill
      const backfillIds = runningBackfill.map((r) => r.id)
      const { data: response } = await axios.post(getBackfillRunningStatus, { backfillIds })
      const backfillsInProgress = response
        .filter((r) => r.status === 'running')
        .map((b) => ({ ...b, created_at: new Date(b.created_at) }))
      const backfillsFinished = response
        .filter((r) => r.status === 'done')
        .map((b) => ({ ...b, created_at: new Date(b.created_at) }))
      const backfillsFailed = response
        .filter((r) => r.status === 'error')
        .map((b) => ({ ...b, created_at: new Date(b.created_at) }))
      dispatch({
        type: 'SET_FINISHED_BACKFILL',
        payload: [
          ...backfillsFinished.map((b) => ({
            ...b,
            finished_at: new Date(),
          })),
          ...finishedBackfill,
        ],
      })
      dispatch({
        type: 'SET_FAILED_BACKFILL',
        payload: [
          ...backfillsFailed.map((b) => ({
            ...b,
            failed_at: new Date(),
          })),
          ...failedBackfill,
        ],
      })
      dispatch({ type: 'FETCH_FULFILLED_RUNNING_BACKFILL', payload: backfillsInProgress })
      if (adminPage) {
        runningBackfillInterval = setTimeout(() => fetchRunningBackfills()(dispatch, getState), 5000)
      } else {
        clearTimeout(runningBackfillInterval)
        runningBackfillInterval = null
      }
    } catch (err) {
      console.error(err)
      clearTimeout(runningBackfillInterval)
      runningBackfillInterval = null
      dispatch({ type: 'FETCH_REJECTED_RUNNING_BACKFILL', payload: err })
    }
  }
}

export const switchBackfill = (index) => {
  return {
    type: 'SWITCH_BACKFILL',
    payload: index,
  }
}

export const toggleAdminPage = (flag) => {
  return {
    type: 'TOGGLE_ADMIN_PAGE_BACKFILL',
    payload: flag,
  }
}
