import { localStoragePayhelm } from 'modules/utils'

let startTime = '00:00'
let endTime = '23:59'

const initialState = {
  startTime: startTime,
  endTime: endTime,
}

export default function reducer(state = initialState, action) {
  switch(action.type) {
    case 'CHANGE_START_TIME': {
      return {
        ...state,
        startTime: action.payload,
      }
    }
    case 'CHANGE_END_TIME': {
      return {
        ...state,
        endTime: action.payload,
      }
    }
  }
  return state
}