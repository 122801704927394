import { getQueryJson, localStoragePayhelm, getDefaultFilterType, validateJSONString, } from 'modules/utils'
import moment from 'momentExt'
import { dateTypes, } from 'modules/constants'

let dateFilters = {}
const savedState = localStoragePayhelm.dateFilters && validateJSONString(localStoragePayhelm.dateFilters)
  ? JSON.parse(localStoragePayhelm.dateFilters) : undefined
let checksum = true, checkBool = false
if (savedState) {
  for (const dateType of Object.keys(savedState)) {
    if (!savedState[dateType]?.dateRange?.start || !savedState[dateType]?.dateRange?.end) checksum = false
    if (!dateTypes.includes(dateType)) checksum = false
    if (savedState[dateType]?.active === true) checkBool = true
  }
} else checksum = false
if (checksum && checkBool) dateFilters = savedState
else {
  dateTypes.map(dateType => dateFilters[dateType] = {
    active: dateType === dateTypes[0] ? true : false,
    dateRange: { start: moment().format('YYYY-MM-DD'), end: moment().format('YYYY-MM-DD'), },
    compareDateRange: { start: moment().format('YYYY-MM-DD'), end: moment().format('YYYY-MM-DD'), },
  })
  localStoragePayhelm.dateFilters = JSON.stringify(dateFilters)
}

const queryJson = getQueryJson()
try {
  for (const type of dateTypes) {
    let active = false
    let dateRange = undefined
    let compareDateRange = undefined
    if (queryJson[type] !== undefined) {
      const [start, end] = queryJson[type].split('to')
      if (moment(start).isValid() && moment(end).isValid()) {
        if (moment(start) <= moment(end)) {
          active = true
          dateRange = { start: moment(start).format('YYYY-MM-DD'), end: moment(end).format('YYYY-MM-DD'), }
        } else {
          active = true
          dateRange = { start: moment(end).format('YYYY-MM-DD'), end: moment(start).format('YYYY-MM-DD'), }
        }
      }
    }
    if (queryJson[`compare-${type}`] !== undefined) {
      const [compareStart, compareEnd] = queryJson[`compare-${type}`].split('to')
      if (moment(compareStart).isValid() && moment(compareEnd).isValid()) {
        if (moment(compareStart) <= moment(compareEnd)) {
          active = true
          compareDateRange = { start: moment(compareStart).format('YYYY-MM-DD'), end: moment(compareEnd).format('YYYY-MM-DD'), }
        } else {
          active = true
          compareDateRange = { start: moment(compareEnd).format('YYYY-MM-DD'), end: moment(compareStart).format('YYYY-MM-DD'), }
        }
      }
    }
    if (active && dateRange && compareDateRange) dateFilters[type] = { active, dateRange, compareDateRange, }
    else if (active && dateRange) dateFilters[type] = { active, dateRange, }
  }
} catch (error) {
  console.log(error)
}

const initialState = {
  dateTypes: dateFilters,
  prevDateType: null,
  prevSearch: null,
  updated: false,
}

export default function reducer(state = initialState, action) {
  let nextState = state

  switch(action.type) {
    case 'ACTIVATE_DATE_TYPE': {
      nextState = {
        ...nextState,
        dateTypes: {
          ...nextState.dateTypes,
          [action.payload]: {
            ...nextState.dateTypes[action.payload],
            active: true,
          },
        },
        prevDateType: action.payload,
      }
      break
    }
    case 'DEACTIVATE_DATE_TYPE': {
      nextState = {
        ...nextState,
        dateTypes: {
          ...nextState.dateTypes,
          [action.payload]: {
            ...nextState.dateTypes[action.payload],
            active: false,
            dateRange: {
              start: null,
              end: null,
            },
          },
        },
      }
      nextState = {
        ...nextState,
        prevDateType: getDefaultFilterType(nextState.dateTypes).defaultFilterType,
      }
      break
    }
    case 'CHANGE_DATE_TYPE': {
      nextState = {
        ...state,
        dateTypes: {
          ...state.dateTypes,
          [action.payload.prevDateType]: {
            ...state.dateTypes[action.payload.nextDateType],
          },
          [action.payload.nextDateType]: {
            ...state.dateTypes[action.payload.prevDateType],
          },
        }
      }
      break
    }
    case 'DEACTIVE_ALL_DATE_TYPES': {
      let preserveActive
      let nextDateTypes = {}
      for (const dateType of Object.keys(nextState.dateTypes)) {
        if (nextState.dateTypes[dateType].active && !preserveActive) { preserveActive = dateType }
        nextDateTypes[dateType] = {
          ...nextState.dateTypes[dateType],
          active: preserveActive === dateType ? true : false,
          dateRange:
            preserveActive === dateType
              ? nextState.dateTypes[dateType].dateRange
              : {
                start: null,
                end: null,
              },
        }
      }
      nextState = {
        ...nextState,
        dateTypes: nextDateTypes,
        prevDateType: getDefaultFilterType(nextDateTypes).defaultFilterType,
      }
      break
    }
    case 'SET_DATE_RANGE': {
      nextState = {
        ...nextState,
        dateTypes: {
          ...nextState.dateTypes,
          [action.payload.dateType]: {
            ...nextState.dateTypes[action.payload.dateType],
            dateRange: action.payload.dateRange,
          },
        },
        prevDateType: action.payload.dateType,
      }
      break
    }
    case 'SET_COMPARE_DATE_RANGE': {
      nextState = {
        ...nextState,
        dateTypes: {
          ...nextState.dateTypes,
          [action.payload.dateType]: {
            ...nextState.dateTypes[action.payload.dateType],
            compareDateRange: action.payload.compareDateRange,
          },
        },
        prevDateType: action.payload.dateType,
      }
      break
    }
    case 'SET_PREVIOUS_SEARCH': {
      nextState = {
        ...nextState,
        prevSearch: nextState.dateTypes,
      }
      break
    }
    case 'SET_UPDATED': {
      nextState = {
        ...nextState,
        updated: true,
      }
      break
    }
    case 'UNSET_UPDATED': {
      nextState = {
        ...nextState,
        updated: false,
      }
      break
    }
    default:
      break
  }
  try {
    localStoragePayhelm.dateFilters = JSON.stringify(nextState.dateTypes || '')
  } catch (e) {
    console.log(e)
  }
  return nextState
}
