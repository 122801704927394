const viewList = ['accounts', 'data_connectors', 'shared_accounts', 'preferences']
const viewIndex = location.pathname === '/settings' ? viewList.indexOf((location.search.match(/[?&]view=(\w+)(&|$)/) || [null, 'accounts'])[1]) : 0
const initialState = {
  viewList: viewList,
  viewIndex: viewIndex < 0 ? 0 : viewIndex,
  sellApiTokenStatus: {},
  sellApiTokenFetched: false,
  preferences: {
    defaultTimezone: null,
    shopifySettings: {},
    bigcommerceSettings: {},
    amazonSettings: {},
    walmartSettings: {},
    contacts: {},
    socialAccounts: {},
    currencySettings: {},
    shippingSettings: {},
    inventorySettings: {},
  },
  preferencesStatus: 'loading',
  preferencesModified: false,
  dataConnectorsStatus: 'loading',
  dataConnectorsError: null,
  shipstationConfigs: null,
  shippingeasyConfigs: null,
}

export default function reducer(state = initialState, action) {
  switch(action.type) {
    case "SET_VIEW_SETTINGS": {
      return {
        ...state,
        viewIndex: action.payload,
      }
    }
    case "SET_SELL_API_TOKEN_STATUS_SETTINGS": {
      return {
        ...state,
        sellApiTokenStatus: action.payload,
      }
    }
    case "FETCH_SELL_API_TOKEN_FULFILLED_SETTINGS": {
      return {
        ...state,
        sellApiTokenFetched: action.payload,
      }
    }
    case "SET_PREFERENCES_SETTINGS": {
      return {
        ...state,
        preferences: Object.assign({}, ...Object.keys(action.payload).map(key => {
          return action.payload[key] ? { [key]: action.payload[key] } : null
        })),
      }
    }
    case "SET_PREFERENCES_STATUS_SETTINGS": {
      return {
        ...state,
        preferencesStatus: action.payload,
      }
    }
    case "SET_PREFERENCES_MODIFIED_SETTINGS": {
      return {
        ...state,
        preferencesModified: action.payload,
      }
    }
    case 'SET_DATA_CONNECTORS_STATUS_SETTINGS': {
      return {
        ...state,
        dataConnectorsStatus: action.payload,
      }
    }
    case 'SET_DATA_CONNECTORS_ERROR_SETTINGS': {
      return {
        ...state,
        dataConnectorsError: action.payload,
      }
    }
    case 'SET_SHIPSTATION_CONFIGS_SETTINGS': {
      return {
        ...state,
        shipstationConfigs: action.payload,
      }
    }
    case 'SET_SHIPPINGEASY_CONFIGS_SETTINGS': {
      return {
        ...state,
        shippingeasyConfigs: action.payload,
      }
    }
  }
  return state
}