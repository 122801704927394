module.exports = {
	"visitors": [
		{
			"identifier": "guest-Xl4A8hnfFbWF6Gklk8Ivnd5MONQmhZiT",
			"order_id": null,
			"domain": "www.almanac.com",
			"channel": "bigcommerce",
			"session": null,
			"pageviews": 1,
			"user_data": null,
			"promo": null,
			"sales": "0.00",
			"storefront_data": null,
			"first_visit": {
				"ep": "https://www.almanac.com/gardening/planting-calendar/QC/Coaticook",
				"fd": "2023-05-02 13:06:19",
				"rf": "(none)",
				"cmp": "(none)",
				"cnt": "(none)",
				"mdm": "(none)",
				"src": "(direct)",
				"trm": "(none)",
				"typ": "typein"
			},
			"latest_visit": {
				"ep": "https://www.almanac.com/gardening/planting-calendar/QC/Coaticook",
				"fd": "2023-05-02 13:06:19",
				"rf": "(none)",
				"cmp": "(none)",
				"cnt": "(none)",
				"mdm": "(none)",
				"src": "(direct)",
				"trm": "(none)",
				"typ": "typein"
			},
			"first_date": "2023-05-02T17:06:20.359Z",
			"last_date": "2023-05-02T17:06:20.357Z",
			"checkout_stage": "on_site",
			"checkout_stage_history": null,
			"current_page": "/gardening/planting-calendar/QC/Coaticook",
			"page_history": null,
			"type": "Visitor",
			"utm_campaign": "(none)",
			"utm_source": "(direct)",
			"utm_medium": "(none)",
			"utm_content": "(none)",
			"utm_term": "(none)",
			"lineItems": []
		},
		{
			"identifier": "guest-aYwRL7abYhrBrusW6SpXBOyMzUGtaUIZ",
			"order_id": null,
			"domain": "www.almanac.com",
			"channel": "bigcommerce",
			"session": null,
			"pageviews": 1,
			"user_data": null,
			"promo": null,
			"sales": "0.00",
			"storefront_data": null,
			"first_visit": {
				"ep": "https://www.almanac.com/plant/radishes",
				"fd": "2023-05-02 12:06:20",
				"rf": "https://duckduckgo.com/",
				"cmp": "(none)",
				"cnt": "/",
				"mdm": "referral",
				"src": "duckduckgo.com",
				"trm": "(none)",
				"typ": "referral"
			},
			"latest_visit": {
				"ep": "https://www.almanac.com/plant/radishes",
				"fd": "2023-05-02 12:06:20",
				"rf": "https://duckduckgo.com/",
				"cmp": "(none)",
				"cnt": "/",
				"mdm": "referral",
				"src": "duckduckgo.com",
				"trm": "(none)",
				"typ": "referral"
			},
			"first_date": "2023-05-02T17:06:20.254Z",
			"last_date": "2023-05-02T17:06:20.217Z",
			"checkout_stage": "on_site",
			"checkout_stage_history": null,
			"current_page": "/plant/radishes",
			"page_history": null,
			"type": "Visitor",
			"utm_campaign": "(none)",
			"utm_source": "duckduckgo.com",
			"utm_medium": "referral",
			"utm_content": "/",
			"utm_term": "(none)",
			"lineItems": []
		},
		{
			"identifier": "guest-rOUL4xxKGrRuuNQBN7nMBZLm707vdPCF",
			"order_id": null,
			"domain": "www.arcticus.co.uk",
			"channel": "shopify",
			"session": null,
			"pageviews": 1,
			"user_data": null,
			"promo": null,
			"sales": "0.00",
			"storefront_data": null,
			"first_visit": {
				"ep": "https://www.arcticus.co.uk/collections/sweatcoin-drinkware",
				"fd": "2023-05-02 19:06:18",
				"rf": "(none)",
				"cmp": "(none)",
				"cnt": "(none)",
				"mdm": "(none)",
				"src": "(direct)",
				"trm": "(none)",
				"typ": "typein"
			},
			"latest_visit": {
				"ep": "https://www.arcticus.co.uk/collections/sweatcoin-drinkware",
				"fd": "2023-05-02 19:06:18",
				"rf": "(none)",
				"cmp": "(none)",
				"cnt": "(none)",
				"mdm": "(none)",
				"src": "(direct)",
				"trm": "(none)",
				"typ": "typein"
			},
			"first_date": "2023-05-02T17:06:20.248Z",
			"last_date": "2023-05-02T17:06:20.155Z",
			"checkout_stage": "on_site",
			"checkout_stage_history": null,
			"current_page": "/collections/sweatcoin-drinkware",
			"page_history": null,
			"type": "Visitor",
			"utm_campaign": "(none)",
			"utm_source": "(direct)",
			"utm_medium": "(none)",
			"utm_content": "(none)",
			"utm_term": "(none)",
			"lineItems": []
		},
		{
			"identifier": "guest-U2vCtmNxkN2yMKp2kFO6YZoJWK4al7Vs",
			"order_id": null,
			"domain": "cedarhouse.co",
			"channel": "bigcommerce",
			"session": null,
			"pageviews": 0,
			"user_data": null,
			"promo": null,
			"sales": "0.00",
			"storefront_data": null,
			"first_visit": {
				"cmp": "f29a4bc08f-Mother's+Day+2023+-+Month+of+Mary+-+May+1",
				"cnt": "(none)",
				"mdm": "email",
				"src": "Cedar+House",
				"trm": "0_f3ebe91937-f29a4bc08f-85957134",
				"typ": "utm"
			},
			"latest_visit": {
				"cmp": "f29a4bc08f-Mother's+Day+2023+-+Month+of+Mary+-+May+1",
				"cnt": "(none)",
				"mdm": "email",
				"src": "Cedar+House",
				"trm": "0_f3ebe91937-f29a4bc08f-85957134",
				"typ": "utm"
			},
			"first_date": "2023-05-02T17:06:20.115Z",
			"last_date": "2023-05-02T17:06:20.108Z",
			"checkout_stage": "on_site",
			"checkout_stage_history": null,
			"current_page": "/linnocence-painting-by-william-adolphe-bouguereau-1881-framed-canvas-12-x-22/",
			"page_history": null,
			"type": "Visitor",
			"utm_campaign": "f29a4bc08f-Mother's+Day+2023+-+Month+of+Mary+-+May+1",
			"utm_source": "Cedar+House",
			"utm_medium": "email",
			"utm_content": "(none)",
			"utm_term": "0_f3ebe91937-f29a4bc08f-85957134",
			"lineItems": []
		},
		{
			"identifier": "guest-aRy9jAo5IkrdSxdjuOlgvwo439Ju2sCU",
			"order_id": null,
			"domain": "www.almanac.com",
			"channel": "bigcommerce",
			"session": null,
			"pageviews": 1,
			"user_data": null,
			"promo": null,
			"sales": "0.00",
			"storefront_data": null,
			"first_visit": {
				"ep": "https://www.almanac.com/plant/kale",
				"fd": "2023-05-02 13:06:18",
				"rf": "https://www.google.com/",
				"cmp": "(none)",
				"cnt": "(none)",
				"mdm": "organic",
				"src": "google",
				"trm": "(none)",
				"typ": "organic"
			},
			"latest_visit": {
				"ep": "https://www.almanac.com/plant/kale",
				"fd": "2023-05-02 13:06:18",
				"rf": "https://www.google.com/",
				"cmp": "(none)",
				"cnt": "(none)",
				"mdm": "organic",
				"src": "google",
				"trm": "(none)",
				"typ": "organic"
			},
			"first_date": "2023-05-02T17:06:20.092Z",
			"last_date": "2023-05-02T17:06:20.075Z",
			"checkout_stage": "on_site",
			"checkout_stage_history": null,
			"current_page": "/plant/kale",
			"page_history": null,
			"type": "Visitor",
			"utm_campaign": "(none)",
			"utm_source": "google",
			"utm_medium": "organic",
			"utm_content": "(none)",
			"utm_term": "(none)",
			"lineItems": []
		},
		{
			"identifier": "guest-h5KS8LthvnljqGSwfvK51vlUUxrAHuU4",
			"order_id": null,
			"domain": "store.almanac.com",
			"channel": "bigcommerce",
			"session": null,
			"pageviews": 3,
			"user_data": null,
			"promo": null,
			"sales": "0.00",
			"storefront_data": null,
			"first_visit": {
				"ep": "https://www.almanac.com/spring-weather-forecast",
				"fd": "2023-05-02 13:02:47",
				"rf": "https://www.google.com/",
				"cmp": "(none)",
				"cnt": "(none)",
				"mdm": "organic",
				"src": "google",
				"trm": "(none)",
				"typ": "organic"
			},
			"latest_visit": {
				"ep": "https://www.almanac.com/spring-weather-forecast",
				"fd": "2023-05-02 13:05:24",
				"rf": "https://www.google.com/",
				"cmp": "(none)",
				"cnt": "(none)",
				"mdm": "organic",
				"src": "google",
				"trm": "(none)",
				"typ": "organic"
			},
			"first_date": "2023-05-02T17:06:20.034Z",
			"last_date": "2023-05-02T17:06:20.022Z",
			"checkout_stage": "on_site",
			"checkout_stage_history": null,
			"current_page": "/online-edition-2023-farmers-almanac/",
			"page_history": null,
			"type": "Visitor",
			"utm_campaign": "(none)",
			"utm_source": "google",
			"utm_medium": "organic",
			"utm_content": "(none)",
			"utm_term": "(none)",
			"lineItems": []
		},
		{
			"identifier": "guest-Q5FZPlFEsiwVZbcntllHcaCB11oytt0w",
			"order_id": null,
			"domain": "www.almanac.com",
			"channel": "bigcommerce",
			"session": null,
			"pageviews": 1,
			"user_data": null,
			"promo": null,
			"sales": "0.00",
			"storefront_data": null,
			"first_visit": {
				"ep": "https://www.almanac.com/content/mercury-retrograde-dates",
				"fd": "2023-05-02 12:06:19",
				"rf": "https://www.google.com/",
				"cmp": "(none)",
				"cnt": "(none)",
				"mdm": "organic",
				"src": "google",
				"trm": "(none)",
				"typ": "organic"
			},
			"latest_visit": {
				"ep": "https://www.almanac.com/content/mercury-retrograde-dates",
				"fd": "2023-05-02 12:06:19",
				"rf": "https://www.google.com/",
				"cmp": "(none)",
				"cnt": "(none)",
				"mdm": "organic",
				"src": "google",
				"trm": "(none)",
				"typ": "organic"
			},
			"first_date": "2023-05-02T17:06:19.987Z",
			"last_date": "2023-05-02T17:06:19.985Z",
			"checkout_stage": "on_site",
			"checkout_stage_history": null,
			"current_page": "/content/mercury-retrograde-dates",
			"page_history": null,
			"type": "Visitor",
			"utm_campaign": "(none)",
			"utm_source": "google",
			"utm_medium": "organic",
			"utm_content": "(none)",
			"utm_term": "(none)",
			"lineItems": []
		},
		{
			"identifier": "guest-PAgA1Xh9PVVNgJDUWcK8YGOJk4qe8U9s",
			"order_id": null,
			"domain": "flyleafpublishing.com",
			"channel": "bigcommerce",
			"session": null,
			"pageviews": 1,
			"user_data": null,
			"promo": null,
			"sales": "0.00",
			"storefront_data": null,
			"first_visit": {
				"ep": "https://flyleafpublishing.com/quick-quote",
				"fd": "2023-05-02 12:06:19",
				"rf": "(none)",
				"cmp": "(none)",
				"cnt": "(none)",
				"mdm": "(none)",
				"src": "(direct)",
				"trm": "(none)",
				"typ": "typein"
			},
			"latest_visit": {
				"ep": "https://flyleafpublishing.com/quick-quote",
				"fd": "2023-05-02 12:06:19",
				"rf": "(none)",
				"cmp": "(none)",
				"cnt": "(none)",
				"mdm": "(none)",
				"src": "(direct)",
				"trm": "(none)",
				"typ": "typein"
			},
			"first_date": "2023-05-02T17:06:19.951Z",
			"last_date": "2023-05-02T17:06:19.949Z",
			"checkout_stage": "on_site",
			"checkout_stage_history": null,
			"current_page": "/quick-quote",
			"page_history": null,
			"type": "Visitor",
			"utm_campaign": "(none)",
			"utm_source": "(direct)",
			"utm_medium": "(none)",
			"utm_content": "(none)",
			"utm_term": "(none)",
			"lineItems": []
		},
		{
			"identifier": "guest-8Mq1CfJp01LBouuu8vzsrm9ohqnJ3cgy",
			"order_id": null,
			"domain": "www.almanac.com",
			"channel": "bigcommerce",
			"session": null,
			"pageviews": 1,
			"user_data": null,
			"promo": null,
			"sales": "0.00",
			"storefront_data": null,
			"first_visit": {
				"ep": "https://www.almanac.com/pest/moles",
				"fd": "2023-05-02 19:06:15",
				"rf": "https://www.google.com/",
				"cmp": "(none)",
				"cnt": "(none)",
				"mdm": "organic",
				"src": "google",
				"trm": "(none)",
				"typ": "organic"
			},
			"latest_visit": {
				"ep": "https://www.almanac.com/pest/moles",
				"fd": "2023-05-02 19:06:15",
				"rf": "https://www.google.com/",
				"cmp": "(none)",
				"cnt": "(none)",
				"mdm": "organic",
				"src": "google",
				"trm": "(none)",
				"typ": "organic"
			},
			"first_date": "2023-05-02T17:06:19.947Z",
			"last_date": "2023-05-02T17:06:19.945Z",
			"checkout_stage": "on_site",
			"checkout_stage_history": null,
			"current_page": "/pest/moles",
			"page_history": null,
			"type": "Visitor",
			"utm_campaign": "(none)",
			"utm_source": "google",
			"utm_medium": "organic",
			"utm_content": "(none)",
			"utm_term": "(none)",
			"lineItems": []
		},
		{
			"identifier": "guest-Ei9kUixqnIO6QPPmydh033xlBfMspPLu",
			"order_id": null,
			"domain": "www.almanac.com",
			"channel": "bigcommerce",
			"session": null,
			"pageviews": 1,
			"user_data": null,
			"promo": null,
			"sales": "0.00",
			"storefront_data": null,
			"first_visit": {
				"ep": "https://www.almanac.com/content/month-may-holidays-fun-facts-folklore",
				"fd": "2023-05-02 12:06:18",
				"rf": "https://www.google.com/",
				"cmp": "(none)",
				"cnt": "(none)",
				"mdm": "organic",
				"src": "google",
				"trm": "(none)",
				"typ": "organic"
			},
			"latest_visit": {
				"ep": "https://www.almanac.com/content/month-may-holidays-fun-facts-folklore",
				"fd": "2023-05-02 12:06:18",
				"rf": "https://www.google.com/",
				"cmp": "(none)",
				"cnt": "(none)",
				"mdm": "organic",
				"src": "google",
				"trm": "(none)",
				"typ": "organic"
			},
			"first_date": "2023-05-02T17:06:19.874Z",
			"last_date": "2023-05-02T17:06:19.872Z",
			"checkout_stage": "on_site",
			"checkout_stage_history": null,
			"current_page": "/content/month-may-holidays-fun-facts-folklore",
			"page_history": null,
			"type": "Visitor",
			"utm_campaign": "(none)",
			"utm_source": "google",
			"utm_medium": "organic",
			"utm_content": "(none)",
			"utm_term": "(none)",
			"lineItems": []
		},
		{
			"identifier": "guest-GkHNv38uUQ04j5KyeDKfp7Lrb0fWm9Wd",
			"order_id": null,
			"domain": "www.almanac.com",
			"channel": "bigcommerce",
			"session": null,
			"pageviews": 1,
			"user_data": null,
			"promo": null,
			"sales": "0.00",
			"storefront_data": null,
			"first_visit": {
				"ep": "https://www.almanac.com/content/party-planner-cooking-crowd",
				"fd": "2023-05-02 12:06:19",
				"rf": "https://www.google.com/",
				"cmp": "(none)",
				"cnt": "(none)",
				"mdm": "organic",
				"src": "google",
				"trm": "(none)",
				"typ": "organic"
			},
			"latest_visit": {
				"ep": "https://www.almanac.com/content/party-planner-cooking-crowd",
				"fd": "2023-05-02 12:06:19",
				"rf": "https://www.google.com/",
				"cmp": "(none)",
				"cnt": "(none)",
				"mdm": "organic",
				"src": "google",
				"trm": "(none)",
				"typ": "organic"
			},
			"first_date": "2023-05-02T17:06:19.569Z",
			"last_date": "2023-05-02T17:06:19.568Z",
			"checkout_stage": "on_site",
			"checkout_stage_history": null,
			"current_page": "/content/party-planner-cooking-crowd",
			"page_history": null,
			"type": "Visitor",
			"utm_campaign": "(none)",
			"utm_source": "google",
			"utm_medium": "organic",
			"utm_content": "(none)",
			"utm_term": "(none)",
			"lineItems": []
		},
		{
			"identifier": "guest-xW3HOlBzwUKFiymmT4KBvC1KwuBGD316",
			"order_id": null,
			"domain": "www.pnta.com",
			"channel": "bigcommerce",
			"session": null,
			"pageviews": 1,
			"user_data": null,
			"promo": null,
			"sales": "0.00",
			"storefront_data": null,
			"first_visit": {
				"ep": "https://www.pnta.com/",
				"fd": "2023-05-02 10:06:17",
				"rf": "(none)",
				"cmp": "(none)",
				"cnt": "(none)",
				"mdm": "(none)",
				"src": "(direct)",
				"trm": "(none)",
				"typ": "typein"
			},
			"latest_visit": {
				"ep": "https://www.pnta.com/",
				"fd": "2023-05-02 10:06:17",
				"rf": "(none)",
				"cmp": "(none)",
				"cnt": "(none)",
				"mdm": "(none)",
				"src": "(direct)",
				"trm": "(none)",
				"typ": "typein"
			},
			"first_date": "2023-05-02T17:06:19.302Z",
			"last_date": "2023-05-02T17:06:19.300Z",
			"checkout_stage": "on_site",
			"checkout_stage_history": null,
			"current_page": "/",
			"page_history": null,
			"type": "Visitor",
			"utm_campaign": "(none)",
			"utm_source": "(direct)",
			"utm_medium": "(none)",
			"utm_content": "(none)",
			"utm_term": "(none)",
			"lineItems": []
		},
		{
			"identifier": "guest-IM4cfw02noRWqRzxCalpAAFbflvebkik",
			"order_id": null,
			"domain": "www.almanac.com",
			"channel": "bigcommerce",
			"session": null,
			"pageviews": 1,
			"user_data": null,
			"promo": null,
			"sales": "0.00",
			"storefront_data": null,
			"first_visit": {
				"ep": "https://www.almanac.com/content/meteor-shower-calendar",
				"fd": "2023-05-02 10:06:17",
				"rf": "https://www.google.com/",
				"cmp": "(none)",
				"cnt": "(none)",
				"mdm": "organic",
				"src": "google",
				"trm": "(none)",
				"typ": "organic"
			},
			"latest_visit": {
				"ep": "https://www.almanac.com/content/meteor-shower-calendar",
				"fd": "2023-05-02 10:06:17",
				"rf": "https://www.google.com/",
				"cmp": "(none)",
				"cnt": "(none)",
				"mdm": "organic",
				"src": "google",
				"trm": "(none)",
				"typ": "organic"
			},
			"first_date": "2023-05-02T17:06:19.227Z",
			"last_date": "2023-05-02T17:06:19.223Z",
			"checkout_stage": "on_site",
			"checkout_stage_history": null,
			"current_page": "/content/meteor-shower-calendar",
			"page_history": null,
			"type": "Visitor",
			"utm_campaign": "(none)",
			"utm_source": "google",
			"utm_medium": "organic",
			"utm_content": "(none)",
			"utm_term": "(none)",
			"lineItems": []
		},
		{
			"identifier": "guest-44p8yx9jdmdZn0WnrbqbXz1QrwYX1zQ2",
			"order_id": null,
			"domain": "www.almanac.com",
			"channel": "bigcommerce",
			"session": null,
			"pageviews": 1,
			"user_data": null,
			"promo": null,
			"sales": "0.00",
			"storefront_data": null,
			"first_visit": {
				"ep": "https://www.almanac.com/content/common-garden-weeds",
				"fd": "2023-05-02 13:06:16",
				"rf": "https://www.google.com/",
				"cmp": "(none)",
				"cnt": "(none)",
				"mdm": "organic",
				"src": "google",
				"trm": "(none)",
				"typ": "organic"
			},
			"latest_visit": {
				"ep": "https://www.almanac.com/content/common-garden-weeds",
				"fd": "2023-05-02 13:06:16",
				"rf": "https://www.google.com/",
				"cmp": "(none)",
				"cnt": "(none)",
				"mdm": "organic",
				"src": "google",
				"trm": "(none)",
				"typ": "organic"
			},
			"first_date": "2023-05-02T17:06:19.193Z",
			"last_date": "2023-05-02T17:06:19.191Z",
			"checkout_stage": "on_site",
			"checkout_stage_history": null,
			"current_page": "/content/common-garden-weeds",
			"page_history": null,
			"type": "Visitor",
			"utm_campaign": "(none)",
			"utm_source": "google",
			"utm_medium": "organic",
			"utm_content": "(none)",
			"utm_term": "(none)",
			"lineItems": []
		},
		{
			"identifier": "guest-jyoXnSjsRa0KOqzoGhPp9lEcWUQNzY2p",
			"order_id": null,
			"domain": "www.arcticus.co.uk",
			"channel": "shopify",
			"session": null,
			"pageviews": 1,
			"user_data": null,
			"promo": null,
			"sales": "0.00",
			"storefront_data": null,
			"first_visit": {
				"ep": "https://www.arcticus.co.uk/collections/sweatcoin-drinkware",
				"fd": "2023-05-02 09:06:17",
				"rf": "(none)",
				"cmp": "(none)",
				"cnt": "(none)",
				"mdm": "(none)",
				"src": "(direct)",
				"trm": "(none)",
				"typ": "typein"
			},
			"latest_visit": {
				"ep": "https://www.arcticus.co.uk/collections/sweatcoin-drinkware",
				"fd": "2023-05-02 09:06:17",
				"rf": "(none)",
				"cmp": "(none)",
				"cnt": "(none)",
				"mdm": "(none)",
				"src": "(direct)",
				"trm": "(none)",
				"typ": "typein"
			},
			"first_date": "2023-05-02T17:06:18.963Z",
			"last_date": "2023-05-02T17:06:18.957Z",
			"checkout_stage": "on_site",
			"checkout_stage_history": null,
			"current_page": "/collections/sweatcoin-drinkware",
			"page_history": null,
			"type": "Visitor",
			"utm_campaign": "(none)",
			"utm_source": "(direct)",
			"utm_medium": "(none)",
			"utm_content": "(none)",
			"utm_term": "(none)",
			"lineItems": []
		},
		{
			"identifier": "guest-N8SMqatjZjrXdko8WwRtCBfdNk663eKC",
			"order_id": null,
			"domain": "www.almanac.com",
			"channel": "bigcommerce",
			"session": null,
			"pageviews": 1,
			"user_data": null,
			"promo": null,
			"sales": "0.00",
			"storefront_data": null,
			"first_visit": {
				"ep": "https://www.almanac.com/weather/longrange/wv",
				"fd": "2023-05-02 17:06:18",
				"rf": "(none)",
				"cmp": "(none)",
				"cnt": "(none)",
				"mdm": "(none)",
				"src": "(direct)",
				"trm": "(none)",
				"typ": "typein"
			},
			"latest_visit": {
				"ep": "https://www.almanac.com/weather/longrange/wv",
				"fd": "2023-05-02 17:06:18",
				"rf": "(none)",
				"cmp": "(none)",
				"cnt": "(none)",
				"mdm": "(none)",
				"src": "(direct)",
				"trm": "(none)",
				"typ": "typein"
			},
			"first_date": "2023-05-02T17:06:18.858Z",
			"last_date": "2023-05-02T17:06:18.856Z",
			"checkout_stage": "on_site",
			"checkout_stage_history": null,
			"current_page": "/weather/longrange/wv",
			"page_history": null,
			"type": "Visitor",
			"utm_campaign": "(none)",
			"utm_source": "(direct)",
			"utm_medium": "(none)",
			"utm_content": "(none)",
			"utm_term": "(none)",
			"lineItems": []
		},
		{
			"identifier": "guest-loKpTw6YO3oE6ncjw4YHp7jZL8UlPDgj",
			"order_id": null,
			"domain": "wearebala.com",
			"channel": "shopify",
			"session": null,
			"pageviews": 1,
			"user_data": null,
			"promo": null,
			"sales": "0.00",
			"storefront_data": null,
			"first_visit": {
				"ep": "https://wearebala.com/",
				"fd": "2023-05-02 13:06:17",
				"rf": "https://www.google.com/",
				"cmp": "(none)",
				"cnt": "(none)",
				"mdm": "organic",
				"src": "google",
				"trm": "(none)",
				"typ": "organic"
			},
			"latest_visit": {
				"ep": "https://wearebala.com/",
				"fd": "2023-05-02 13:06:17",
				"rf": "https://www.google.com/",
				"cmp": "(none)",
				"cnt": "(none)",
				"mdm": "organic",
				"src": "google",
				"trm": "(none)",
				"typ": "organic"
			},
			"first_date": "2023-05-02T17:06:18.813Z",
			"last_date": "2023-05-02T17:06:18.811Z",
			"checkout_stage": "on_site",
			"checkout_stage_history": null,
			"current_page": "/",
			"page_history": null,
			"type": "Visitor",
			"utm_campaign": "(none)",
			"utm_source": "google",
			"utm_medium": "organic",
			"utm_content": "(none)",
			"utm_term": "(none)",
			"lineItems": []
		},
		{
			"identifier": "guest-lU9DQgoDx7BnB2ieDVgLUm04lv38pi3o",
			"order_id": null,
			"domain": "www.watchit.ca",
			"channel": "shopify",
			"session": null,
			"pageviews": 1,
			"user_data": null,
			"promo": null,
			"sales": "0.00",
			"storefront_data": null,
			"first_visit": {
				"ep": "https://www.watchit.ca/search?q=bm698300h",
				"fd": "2023-05-02 17:06:17",
				"rf": "(none)",
				"cmp": "(none)",
				"cnt": "(none)",
				"mdm": "(none)",
				"src": "(direct)",
				"trm": "(none)",
				"typ": "typein"
			},
			"latest_visit": {
				"ep": "https://www.watchit.ca/search?q=bm698300h",
				"fd": "2023-05-02 17:06:17",
				"rf": "(none)",
				"cmp": "(none)",
				"cnt": "(none)",
				"mdm": "(none)",
				"src": "(direct)",
				"trm": "(none)",
				"typ": "typein"
			},
			"first_date": "2023-05-02T17:06:18.777Z",
			"last_date": "2023-05-02T17:06:18.775Z",
			"checkout_stage": "on_site",
			"checkout_stage_history": null,
			"current_page": "/search",
			"page_history": null,
			"type": "Visitor",
			"utm_campaign": "(none)",
			"utm_source": "(direct)",
			"utm_medium": "(none)",
			"utm_content": "(none)",
			"utm_term": "(none)",
			"lineItems": []
		},
		{
			"identifier": "guest-ElSW9mQs1yz1hidC0iW904ZKOrWqEE9z",
			"order_id": null,
			"domain": "www.almanac.com",
			"channel": "bigcommerce",
			"session": null,
			"pageviews": 1,
			"user_data": null,
			"promo": null,
			"sales": "0.00",
			"storefront_data": null,
			"first_visit": {
				"ep": "https://www.almanac.com/plant/yarrow",
				"fd": "2023-05-02 13:06:17",
				"rf": "https://www.google.com/",
				"cmp": "(none)",
				"cnt": "(none)",
				"mdm": "organic",
				"src": "google",
				"trm": "(none)",
				"typ": "organic"
			},
			"latest_visit": {
				"ep": "https://www.almanac.com/plant/yarrow",
				"fd": "2023-05-02 13:06:17",
				"rf": "https://www.google.com/",
				"cmp": "(none)",
				"cnt": "(none)",
				"mdm": "organic",
				"src": "google",
				"trm": "(none)",
				"typ": "organic"
			},
			"first_date": "2023-05-02T17:06:18.653Z",
			"last_date": "2023-05-02T17:06:18.651Z",
			"checkout_stage": "on_site",
			"checkout_stage_history": null,
			"current_page": "/plant/yarrow",
			"page_history": null,
			"type": "Visitor",
			"utm_campaign": "(none)",
			"utm_source": "google",
			"utm_medium": "organic",
			"utm_content": "(none)",
			"utm_term": "(none)",
			"lineItems": []
		},
		{
			"identifier": "guest-4e55s2ACHOkeTfW0wnsPzmXojSre8FCR",
			"order_id": null,
			"domain": "www.almanac.com",
			"channel": "bigcommerce",
			"session": null,
			"pageviews": 1,
			"user_data": null,
			"promo": null,
			"sales": "0.00",
			"storefront_data": null,
			"first_visit": {
				"ep": "https://www.almanac.com/astronomy/moon/calendar",
				"fd": "2023-05-02 12:06:17",
				"rf": "https://search.brave.com/",
				"cmp": "(none)",
				"cnt": "/",
				"mdm": "referral",
				"src": "search.brave.com",
				"trm": "(none)",
				"typ": "referral"
			},
			"latest_visit": {
				"ep": "https://www.almanac.com/astronomy/moon/calendar",
				"fd": "2023-05-02 12:06:17",
				"rf": "https://search.brave.com/",
				"cmp": "(none)",
				"cnt": "/",
				"mdm": "referral",
				"src": "search.brave.com",
				"trm": "(none)",
				"typ": "referral"
			},
			"first_date": "2023-05-02T17:06:18.573Z",
			"last_date": "2023-05-02T17:06:18.571Z",
			"checkout_stage": "on_site",
			"checkout_stage_history": null,
			"current_page": "/astronomy/moon/calendar",
			"page_history": null,
			"type": "Visitor",
			"utm_campaign": "(none)",
			"utm_source": "search.brave.com",
			"utm_medium": "referral",
			"utm_content": "/",
			"utm_term": "(none)",
			"lineItems": []
		}
	],
	"carts": [
		{
			"identifier": "bigcommerce-9f7e8099-904e-4f4d-91c6-9a0ba3662313",
			"order_id": null,
			"domain": "www.lindafinegold.com",
			"channel": "bigcommerce",
			"session": null,
			"pageviews": 3,
			"user_data": null,
			"promo": null,
			"sales": "109.50",
			"storefront_data": {
				"lineItems": [
					"IT AINT GONNA SPANK ITSELF • Black Sweatpants",
					"THROAT GOAT • Teal Tie Dye Hoodie",
					"SPRING CLEANING BOX",
					"ALL MOVIE MONSTERS ARE HUMAN • Black Hoodie",
					"EMOTIONAL BAGGAGE OG • Olive Printed Strap Duffle Bag",
					"WALK OF SHAME • Black Printed Strap Duffle Bag",
					"7 ITEM MYSTERY BOX • COMES w LOVE KILLS HOODIE",
					"7 ITEM MYSTERY BOX • COMES w LIVE FAST EAT ASS HOODIE",
					"PAY MY MAY RENT • Mystery Box",
					"BLACKOUT DUFFLE BAG FULL OF SHIT • All Black Mystery Box",
					"NEW $10 DUFFLE BAG FULL OF SHIT • Comes w GLOW IN THE DARK Tee",
					"RANDOM FREE STICKERS • Collect All 100 of Them",
					"OUR FINAL DAYS ONLINE • Premium Duffle Full of Shit",
					"ASSHOLES LIVE HERE • Black Doormat x 2"
				]
			},
			"first_visit": {
				"ep": "https://www.lindafinegold.com/account/login",
				"fd": "2023-05-02 10:03:33",
				"rf": "https://bc.lindafinegold.com/",
				"cmp": "(none)",
				"cnt": "(none)",
				"mdm": "(none)",
				"src": "(direct)",
				"trm": "(none)",
				"typ": "typein"
			},
			"latest_visit": {
				"ep": "https://www.lindafinegold.com/account/login",
				"fd": "2023-05-02 10:03:33",
				"rf": "https://bc.lindafinegold.com/",
				"cmp": "(none)",
				"cnt": "(none)",
				"mdm": "(none)",
				"src": "(direct)",
				"trm": "(none)",
				"typ": "typein"
			},
			"first_date": "2023-05-02T16:55:03.680Z",
			"last_date": "2023-05-02T17:06:19.310Z",
			"checkout_stage": "cart_added",
			"checkout_stage_history": null,
			"current_page": "/account/login",
			"page_history": null,
			"type": "Active Cart",
			"utm_campaign": "(none)",
			"utm_source": "(direct)",
			"utm_medium": "(none)",
			"utm_content": "(none)",
			"utm_term": "(none)",
			"lineItems": [
				{
					"name": "IT AINT GONNA SPANK ITSELF • Black Sweatpants"
				},
				{
					"name": "THROAT GOAT • Teal Tie Dye Hoodie"
				},
				{
					"name": "SPRING CLEANING BOX"
				},
				{
					"name": "ALL MOVIE MONSTERS ARE HUMAN • Black Hoodie"
				},
				{
					"name": "EMOTIONAL BAGGAGE OG • Olive Printed Strap Duffle Bag"
				},
				{
					"name": "WALK OF SHAME • Black Printed Strap Duffle Bag"
				},
				{
					"name": "7 ITEM MYSTERY BOX • COMES w LOVE KILLS HOODIE"
				},
				{
					"name": "7 ITEM MYSTERY BOX • COMES w LIVE FAST EAT ASS HOODIE"
				},
				{
					"name": "PAY MY MAY RENT • Mystery Box"
				},
				{
					"name": "BLACKOUT DUFFLE BAG FULL OF SHIT • All Black Mystery Box"
				},
				{
					"name": "NEW $10 DUFFLE BAG FULL OF SHIT • Comes w GLOW IN THE DARK Tee"
				},
				{
					"name": "RANDOM FREE STICKERS • Collect All 100 of Them"
				},
				{
					"name": "OUR FINAL DAYS ONLINE • Premium Duffle Full of Shit"
				},
				{
					"name": "ASSHOLES LIVE HERE • Black Doormat x 2"
				}
			]
		},
		{
			"identifier": "bigcommerce-7d164134-60d4-4ed8-9602-b121d5e07740",
			"order_id": null,
			"domain": "www.multipure.com",
			"channel": "bigcommerce",
			"session": null,
			"pageviews": 22,
			"user_data": null,
			"promo": null,
			"sales": "90.00",
			"storefront_data": {
				"lineItems": [
					"Aquadome Filter (CB6AD)"
				]
			},
			"first_visit": {
				"ep": "https://www.multipure.com/",
				"fd": "2023-03-27 12:13:30",
				"rf": "(none)",
				"cmp": "(none)",
				"cnt": "(none)",
				"mdm": "(none)",
				"src": "(direct)",
				"trm": "(none)",
				"typ": "typein"
			},
			"latest_visit": {
				"ep": "https://www.multipure.com/account.php?action=order_status",
				"fd": "2023-04-28 08:38:55",
				"rf": "https://store-2t5fkq8mli.mybigcommerce.com/",
				"cmp": "(none)",
				"cnt": "/",
				"mdm": "referral",
				"src": "store-2t5fkq8mli.mybigcommerce.com",
				"trm": "(none)",
				"typ": "referral"
			},
			"first_date": "2023-05-02T17:02:26.878Z",
			"last_date": "2023-05-02T17:06:19.294Z",
			"checkout_stage": "cart_added",
			"checkout_stage_history": null,
			"current_page": "/account.php",
			"page_history": null,
			"type": "Active Cart",
			"utm_campaign": "(none)",
			"utm_source": "(direct)",
			"utm_medium": "(none)",
			"utm_content": "(none)",
			"utm_term": "(none)",
			"lineItems": [
				{
					"name": "Aquadome Filter (CB6AD)"
				}
			]
		},
		{
			"identifier": "bigcommerce-cad228d8-c5a2-4a32-ab2e-a08e7845d9be",
			"order_id": null,
			"domain": "bulletproofdesigns.com",
			"channel": "bigcommerce",
			"session": null,
			"pageviews": 1,
			"user_data": null,
			"promo": null,
			"sales": "139.00",
			"storefront_data": {
				"lineItems": [
					"KTM/HUSQVARNA/GAS GAS REAR DISC GUARD 2004-2023 ALL BIKES 125-501"
				]
			},
			"first_visit": {
				"ep": "https://bulletproofdesigns.com/",
				"fd": "2023-04-25 21:58:46",
				"rf": "(none)",
				"cmp": "(none)",
				"cnt": "(none)",
				"mdm": "(none)",
				"src": "(direct)",
				"trm": "(none)",
				"typ": "typein"
			},
			"latest_visit": {
				"ep": "https://bulletproofdesigns.com/",
				"fd": "2023-05-02 11:06:17",
				"rf": "https://www.google.com/",
				"cmp": "(none)",
				"cnt": "(none)",
				"mdm": "organic",
				"src": "google",
				"trm": "(none)",
				"typ": "organic"
			},
			"first_date": "2023-04-26T03:58:47.824Z",
			"last_date": "2023-05-02T17:06:19.228Z",
			"checkout_stage": "cart_added",
			"checkout_stage_history": null,
			"current_page": "/",
			"page_history": null,
			"type": "Active Cart",
			"utm_campaign": "(none)",
			"utm_source": "(direct)",
			"utm_medium": "(none)",
			"utm_content": "(none)",
			"utm_term": "(none)",
			"lineItems": [
				{
					"name": "KTM/HUSQVARNA/GAS GAS REAR DISC GUARD 2004-2023 ALL BIKES 125-501"
				}
			]
		},
		{
			"identifier": "bigcommerce-457351ee-861a-4e23-bd6b-3ee29d8a2732",
			"order_id": null,
			"domain": "www.lindafinegold.com",
			"channel": "bigcommerce",
			"session": null,
			"pageviews": 1,
			"user_data": null,
			"promo": null,
			"sales": "20.00",
			"storefront_data": {
				"lineItems": [
					"ALF FLAG • Black Tee"
				]
			},
			"first_visit": {
				"ep": "https://www.lindafinegold.com/",
				"fd": "2023-05-02 10:03:14",
				"rf": "https://www.google.com/",
				"cmp": "(none)",
				"cnt": "(none)",
				"mdm": "organic",
				"src": "google",
				"trm": "(none)",
				"typ": "organic"
			},
			"latest_visit": {
				"ep": "https://www.lindafinegold.com/",
				"fd": "2023-05-02 10:03:14",
				"rf": "https://www.google.com/",
				"cmp": "(none)",
				"cnt": "(none)",
				"mdm": "organic",
				"src": "google",
				"trm": "(none)",
				"typ": "organic"
			},
			"first_date": "2023-05-02T17:03:15.677Z",
			"last_date": "2023-05-02T17:06:19.191Z",
			"checkout_stage": "cart_added",
			"checkout_stage_history": null,
			"current_page": "/",
			"page_history": null,
			"type": "Active Cart",
			"utm_campaign": "(none)",
			"utm_source": "google",
			"utm_medium": "organic",
			"utm_content": "(none)",
			"utm_term": "(none)",
			"lineItems": [
				{
					"name": "ALF FLAG • Black Tee"
				}
			]
		},
		{
			"identifier": "bigcommerce-f59d763d-1cc6-4acd-80de-6e40999e701b",
			"order_id": null,
			"domain": "koruworks.com",
			"channel": "bigcommerce",
			"session": null,
			"pageviews": 9,
			"user_data": null,
			"promo": null,
			"sales": "1950.00",
			"storefront_data": {
				"lineItems": [
					"IN-STOCK M-Sports S13 Silvia Type B Full Kit"
				]
			},
			"first_visit": {
				"ep": "https://koruworks.com/j-replace/",
				"fd": "2023-04-30 14:09:44",
				"rf": "https://www.google.com/",
				"cmp": "(none)",
				"cnt": "(none)",
				"mdm": "organic",
				"src": "google",
				"trm": "(none)",
				"typ": "organic"
			},
			"latest_visit": {
				"ep": "https://koruworks.com/make-model/nissan/180sx-s13-silvia-240sx/exterior/m-sports-s13-silvia-type-b-full-kit/",
				"fd": "2023-05-02 09:51:00",
				"rf": "https://www.google.com/",
				"cmp": "(none)",
				"cnt": "(none)",
				"mdm": "organic",
				"src": "google",
				"trm": "(none)",
				"typ": "organic"
			},
			"first_date": "2023-02-20T01:12:14.223Z",
			"last_date": "2023-05-02T17:06:17.942Z",
			"checkout_stage": "checking_out",
			"checkout_stage_history": null,
			"current_page": "/checkout",
			"page_history": null,
			"type": "Active Cart",
			"utm_campaign": "(none)",
			"utm_source": "google",
			"utm_medium": "organic",
			"utm_content": "(none)",
			"utm_term": "(none)",
			"lineItems": [
				{
					"name": "IN-STOCK M-Sports S13 Silvia Type B Full Kit"
				}
			]
		},
		{
			"identifier": "bigcommerce-fd04f6f4-7c97-4f79-a007-8032f28bc4e8",
			"order_id": null,
			"domain": "compressionhealth.com",
			"channel": "bigcommerce",
			"session": null,
			"pageviews": 13,
			"user_data": null,
			"promo": null,
			"sales": "89.56",
			"storefront_data": {
				"lineItems": [
					"Mediven Harmony Seamless Gauntlet 30-40mmHg x 2"
				]
			},
			"first_visit": {
				"ep": "https://compressionhealth.com/juzo-seamless-gauntlet-20-30mmhg/?sku=2301AC L&gclid=Cj0KCQjw6cKiBhD5ARIsAKXUdyaeDhDXjpwWyCaFqH6lKCXfl38R0667STDKpNyY4dN9U7m2_bsm8-oaAr9iEALw_wcB",
				"fd": "2023-05-02 08:58:08",
				"rf": "https://www.google.com/",
				"cmp": "google_cpc",
				"cnt": "(none)",
				"mdm": "cpc",
				"src": "google",
				"trm": "(none)",
				"typ": "utm"
			},
			"latest_visit": {
				"ep": "https://compressionhealth.com/juzo-seamless-gauntlet-20-30mmhg/?sku=2301AC L&gclid=Cj0KCQjw6cKiBhD5ARIsAKXUdyaeDhDXjpwWyCaFqH6lKCXfl38R0667STDKpNyY4dN9U7m2_bsm8-oaAr9iEALw_wcB",
				"fd": "2023-05-02 08:58:08",
				"rf": "https://www.google.com/",
				"cmp": "google_cpc",
				"cnt": "(none)",
				"mdm": "cpc",
				"src": "google",
				"trm": "(none)",
				"typ": "utm"
			},
			"first_date": "2023-05-02T12:58:11.280Z",
			"last_date": "2023-05-02T17:06:17.259Z",
			"checkout_stage": "cart_added",
			"checkout_stage_history": null,
			"current_page": "/cart.php",
			"page_history": null,
			"type": "Active Cart",
			"utm_campaign": "google_cpc",
			"utm_source": "google",
			"utm_medium": "cpc",
			"utm_content": "(none)",
			"utm_term": "(none)",
			"lineItems": [
				{
					"name": "Mediven Harmony Seamless Gauntlet 30-40mmHg x 2"
				}
			]
		},
		{
			"identifier": "bigcommerce-f00451aa-43ad-41d2-8e54-0e9b0b885a68",
			"order_id": null,
			"domain": "fiveflavorsherbs.com",
			"channel": "bigcommerce",
			"session": null,
			"pageviews": 6,
			"user_data": null,
			"promo": null,
			"sales": "93.00",
			"storefront_data": {
				"lineItems": [
					"Ashwagandha 8oz",
					"Sweet Dreams Tincture"
				]
			},
			"first_visit": {
				"ep": "https://fiveflavorsherbs.com/",
				"fd": "2023-04-20 13:25:15",
				"rf": "https://www.google.com/",
				"cmp": "(none)",
				"cnt": "(none)",
				"mdm": "organic",
				"src": "google",
				"trm": "(none)",
				"typ": "organic"
			},
			"latest_visit": {
				"ep": "https://fiveflavorsherbs.com/",
				"fd": "2023-05-02 10:04:10",
				"rf": "https://www.google.com/",
				"cmp": "(none)",
				"cnt": "(none)",
				"mdm": "organic",
				"src": "google",
				"trm": "(none)",
				"typ": "organic"
			},
			"first_date": "2023-05-02T17:04:10.311Z",
			"last_date": "2023-05-02T17:06:14.722Z",
			"checkout_stage": "checking_out",
			"checkout_stage_history": null,
			"current_page": "/checkout",
			"page_history": null,
			"type": "Active Cart",
			"utm_campaign": "(none)",
			"utm_source": "google",
			"utm_medium": "organic",
			"utm_content": "(none)",
			"utm_term": "(none)",
			"lineItems": [
				{
					"name": "Ashwagandha 8oz"
				},
				{
					"name": "Sweet Dreams Tincture"
				}
			]
		},
		{
			"identifier": "bigcommerce-49bcf42a-5eca-4894-adbd-5c01471d9dc0",
			"order_id": null,
			"domain": "starcitygames.com",
			"channel": "bigcommerce",
			"session": null,
			"pageviews": 5,
			"user_data": null,
			"promo": null,
			"sales": "0.19",
			"storefront_data": {
				"lineItems": [
					"Enthusiastic Mechanaut [SGL-MTG-NEO-218-ENN]"
				]
			},
			"first_visit": {
				"ep": "https://starcitygames.com/",
				"fd": "2023-05-02 13:01:32",
				"rf": "https://www.bing.com/",
				"cmp": "(none)",
				"cnt": "/",
				"mdm": "referral",
				"src": "bing.com",
				"trm": "(none)",
				"typ": "referral"
			},
			"latest_visit": {
				"ep": "https://starcitygames.com/",
				"fd": "2023-05-02 13:01:32",
				"rf": "https://www.bing.com/",
				"cmp": "(none)",
				"cnt": "/",
				"mdm": "referral",
				"src": "bing.com",
				"trm": "(none)",
				"typ": "referral"
			},
			"first_date": "2023-05-02T17:01:31.016Z",
			"last_date": "2023-05-02T17:06:14.183Z",
			"checkout_stage": "cart_added",
			"checkout_stage_history": null,
			"current_page": "/search/",
			"page_history": null,
			"type": "Active Cart",
			"utm_campaign": "(none)",
			"utm_source": "bing.com",
			"utm_medium": "referral",
			"utm_content": "/",
			"utm_term": "(none)",
			"lineItems": [
				{
					"name": "Enthusiastic Mechanaut [SGL-MTG-NEO-218-ENN]"
				}
			]
		},
		{
			"identifier": "shopify-0675f0f2b3a549e26bd238f69a1f7cdb",
			"order_id": null,
			"domain": "www.arcticus.co.uk",
			"channel": "shopify",
			"session": null,
			"pageviews": 8,
			"user_data": null,
			"promo": null,
			"sales": "3390.00",
			"storefront_data": {
				"lineItems": [
					"Arctic Bottle"
				]
			},
			"first_visit": {
				"ep": "https://www.arcticus.co.uk/collections/sweatcoin-drinkware",
				"fd": "2023-05-02 20:02:54",
				"rf": "(none)",
				"cmp": "(none)",
				"cnt": "(none)",
				"mdm": "(none)",
				"src": "(direct)",
				"trm": "(none)",
				"typ": "typein"
			},
			"latest_visit": {
				"ep": "https://www.arcticus.co.uk/collections/sweatcoin-drinkware",
				"fd": "2023-05-02 20:02:54",
				"rf": "(none)",
				"cmp": "(none)",
				"cnt": "(none)",
				"mdm": "(none)",
				"src": "(direct)",
				"trm": "(none)",
				"typ": "typein"
			},
			"first_date": "2023-05-02T17:02:55.562Z",
			"last_date": "2023-05-02T17:06:14.118Z",
			"checkout_stage": "cart_added",
			"checkout_stage_history": null,
			"current_page": "/cart",
			"page_history": null,
			"type": "Active Cart",
			"utm_campaign": "(none)",
			"utm_source": "(direct)",
			"utm_medium": "(none)",
			"utm_content": "(none)",
			"utm_term": "(none)",
			"lineItems": [
				{
					"name": "Arctic Bottle"
				}
			]
		},
		{
			"identifier": "bigcommerce-9552ef37-a04e-466a-b379-16687b27aa75",
			"order_id": null,
			"domain": "shopcharlestonwrap.com",
			"channel": "bigcommerce",
			"session": null,
			"pageviews": 5,
			"user_data": null,
			"promo": null,
			"sales": "59.00",
			"storefront_data": {
				"lineItems": [
					"Red Birch Round Serving Board - Engraved Sentiment"
				]
			},
			"first_visit": {
				"ep": "https://shopcharlestonwrap.com/?student1ID=212HFQVH73TYB&student1FNM=Organization&student1LNM=Credit Only&progid=&inviteID=&channelID=gateway&school1NM=CW DEMO STORE&school1STC=&school1STS=&school1STSE=2021_12_30",
				"fd": "2023-05-02 11:18:25",
				"rf": "https://www.charlestonwrap.com/",
				"cmp": "(none)",
				"cnt": "/",
				"mdm": "referral",
				"src": "charlestonwrap.com",
				"trm": "(none)",
				"typ": "referral"
			},
			"latest_visit": {
				"ep": "https://shopcharlestonwrap.com/?student1ID=212HFQVH73TYB&student1FNM=Organization&student1LNM=Credit Only&progid=&inviteID=&channelID=gateway&school1NM=CW DEMO STORE&school1STC=&school1STS=&school1STSE=2021_12_30",
				"fd": "2023-05-02 11:18:25",
				"rf": "https://www.charlestonwrap.com/",
				"cmp": "(none)",
				"cnt": "/",
				"mdm": "referral",
				"src": "charlestonwrap.com",
				"trm": "(none)",
				"typ": "referral"
			},
			"first_date": "2023-05-02T16:18:26.279Z",
			"last_date": "2023-05-02T17:06:13.659Z",
			"checkout_stage": "cart_added",
			"checkout_stage_history": null,
			"current_page": "/cart.php",
			"page_history": null,
			"type": "Active Cart",
			"utm_campaign": "(none)",
			"utm_source": "charlestonwrap.com",
			"utm_medium": "referral",
			"utm_content": "/",
			"utm_term": "(none)",
			"lineItems": [
				{
					"name": "Red Birch Round Serving Board - Engraved Sentiment"
				}
			]
		},
		{
			"identifier": "bigcommerce-2aa0e405-6993-4fd0-a2b7-d7136aaa1690",
			"order_id": null,
			"domain": "flateamshop.com",
			"channel": "bigcommerce",
			"session": null,
			"pageviews": 2,
			"user_data": null,
			"promo": null,
			"sales": "35.00",
			"storefront_data": {
				"lineItems": [
					"Florida Panthers Women's Confetti Icon Clean Up Cap"
				]
			},
			"first_visit": {
				"ep": "https://flateamshop.com/florida-panthers-womens-confetti-icon-clean-up-cap/",
				"fd": "2023-05-02 13:05:32",
				"rf": "https://www.google.ca/",
				"cmp": "(none)",
				"cnt": "(none)",
				"mdm": "organic",
				"src": "google",
				"trm": "(none)",
				"typ": "organic"
			},
			"latest_visit": {
				"ep": "https://flateamshop.com/florida-panthers-womens-confetti-icon-clean-up-cap/",
				"fd": "2023-05-02 13:05:32",
				"rf": "https://www.google.ca/",
				"cmp": "(none)",
				"cnt": "(none)",
				"mdm": "organic",
				"src": "google",
				"trm": "(none)",
				"typ": "organic"
			},
			"first_date": "2023-05-02T17:05:33.139Z",
			"last_date": "2023-05-02T17:06:13.384Z",
			"checkout_stage": "cart_added",
			"checkout_stage_history": null,
			"current_page": "/cart.php",
			"page_history": null,
			"type": "Active Cart",
			"utm_campaign": "(none)",
			"utm_source": "google",
			"utm_medium": "organic",
			"utm_content": "(none)",
			"utm_term": "(none)",
			"lineItems": [
				{
					"name": "Florida Panthers Women's Confetti Icon Clean Up Cap"
				}
			]
		},
		{
			"identifier": "bigcommerce-b40cbd9a-3507-4846-8464-ac58f2223f07",
			"order_id": null,
			"domain": "groomerschoice.com",
			"channel": "bigcommerce",
			"session": null,
			"pageviews": 19,
			"user_data": null,
			"promo": null,
			"sales": "253.92",
			"storefront_data": {
				"lineItems": [
					"Bubble Bros. Oatmeal Dog Shampoo, 1 Gallon x 4",
					"Bubble Bros. Oatmeal Dog Conditioner, 1 Gallon x 2",
					"The Coat Handler Undercoat Control DeShedding Conditioner Coconut Milk Fragrance, 1 Gallon",
					"Petology Oatmeal Honey Soothing Daily Finishing Spray, 1 Gallon"
				]
			},
			"first_visit": {
				"ep": "https://groomerschoice.com/",
				"fd": "2023-05-02 08:44:26",
				"rf": "https://www.google.com/",
				"cmp": "(none)",
				"cnt": "(none)",
				"mdm": "organic",
				"src": "google",
				"trm": "(none)",
				"typ": "organic"
			},
			"latest_visit": {
				"ep": "https://groomerschoice.com/",
				"fd": "2023-05-02 08:44:26",
				"rf": "https://www.google.com/",
				"cmp": "(none)",
				"cnt": "(none)",
				"mdm": "organic",
				"src": "google",
				"trm": "(none)",
				"typ": "organic"
			},
			"first_date": "2023-05-02T13:44:27.102Z",
			"last_date": "2023-05-02T17:06:13.254Z",
			"checkout_stage": "checking_out",
			"checkout_stage_history": null,
			"current_page": "/checkout",
			"page_history": null,
			"type": "Active Cart",
			"utm_campaign": "(none)",
			"utm_source": "google",
			"utm_medium": "organic",
			"utm_content": "(none)",
			"utm_term": "(none)",
			"lineItems": [
				{
					"name": "Bubble Bros. Oatmeal Dog Shampoo, 1 Gallon x 4"
				},
				{
					"name": "Bubble Bros. Oatmeal Dog Conditioner, 1 Gallon x 2"
				},
				{
					"name": "The Coat Handler Undercoat Control DeShedding Conditioner Coconut Milk Fragrance, 1 Gallon"
				},
				{
					"name": "Petology Oatmeal Honey Soothing Daily Finishing Spray, 1 Gallon"
				}
			]
		},
		{
			"identifier": "bigcommerce-9cff318a-34b3-4d97-be3d-0424dcd7304e",
			"order_id": null,
			"domain": "www.lindafinegold.com",
			"channel": "bigcommerce",
			"session": null,
			"pageviews": 1,
			"user_data": null,
			"promo": null,
			"sales": "40.00",
			"storefront_data": {
				"lineItems": [
					"ASSHOLES LIVE FOREVER • Black Zip Up Hoodie"
				]
			},
			"first_visit": {
				"ep": "https://www.lindafinegold.com/products/assholes-live-forever-black-zip-up-hoodie",
				"fd": "2023-05-02 12:04:39",
				"rf": "(none)",
				"cmp": "(none)",
				"cnt": "(none)",
				"mdm": "(none)",
				"src": "(direct)",
				"trm": "(none)",
				"typ": "typein"
			},
			"latest_visit": {
				"ep": "https://www.lindafinegold.com/products/assholes-live-forever-black-zip-up-hoodie",
				"fd": "2023-05-02 12:04:39",
				"rf": "(none)",
				"cmp": "(none)",
				"cnt": "(none)",
				"mdm": "(none)",
				"src": "(direct)",
				"trm": "(none)",
				"typ": "typein"
			},
			"first_date": "2023-05-02T16:04:39.659Z",
			"last_date": "2023-05-02T17:06:11.576Z",
			"checkout_stage": "cart_added",
			"checkout_stage_history": null,
			"current_page": "/products/assholes-live-forever-black-zip-up-hoodie",
			"page_history": null,
			"type": "Active Cart",
			"utm_campaign": "(none)",
			"utm_source": "(direct)",
			"utm_medium": "(none)",
			"utm_content": "(none)",
			"utm_term": "(none)",
			"lineItems": [
				{
					"name": "ASSHOLES LIVE FOREVER • Black Zip Up Hoodie"
				}
			]
		},
		{
			"identifier": "bigcommerce-0bdaf7e7-6971-4380-a26b-34582b8061f6",
			"order_id": null,
			"domain": "biloxishrimpco.com",
			"channel": "bigcommerce",
			"session": null,
			"pageviews": 19,
			"user_data": null,
			"promo": null,
			"sales": "261.21",
			"storefront_data": {
				"lineItems": [
					"Jumbo 21/30 Peeled & Deveined Tail-On Wild-Caught Gulf Shrimp ($22.00/lb) x 3",
					"Jumbo 21/30 Count Headless Wild-Caught Gulf Shrimp ($18.00/LB) x 4",
					"Route Package Protection"
				]
			},
			"first_visit": {
				"ep": "https://biloxishrimpco.com/shop-for-shrimp-products/build-your-own-bundle/?utm_source=Facebook&utm_medium=Ad&utm_campaign=Facebook-Conversions&utm_content=build-your-own-bundle-all-shrimp-sizes",
				"fd": "2023-05-02 12:51:35",
				"rf": "https://l.facebook.com/",
				"cmp": "Facebook-Conversions",
				"cnt": "build-your-own-bundle-all-shrimp-sizes",
				"mdm": "Ad",
				"src": "Facebook",
				"trm": "(none)",
				"typ": "utm"
			},
			"latest_visit": {
				"ep": "https://biloxishrimpco.com/shop-for-shrimp-products/build-your-own-bundle/?utm_source=Facebook&utm_medium=Ad&utm_campaign=Facebook-Conversions&utm_content=build-your-own-bundle-all-shrimp-sizes",
				"fd": "2023-05-02 13:02:20",
				"rf": "https://l.facebook.com/",
				"cmp": "Facebook-Conversions",
				"cnt": "build-your-own-bundle-all-shrimp-sizes",
				"mdm": "Ad",
				"src": "Facebook",
				"trm": "(none)",
				"typ": "utm"
			},
			"first_date": "2023-05-02T16:51:36.671Z",
			"last_date": "2023-05-02T17:06:07.316Z",
			"checkout_stage": "cart_added",
			"checkout_stage_history": null,
			"current_page": "/cart.php",
			"page_history": null,
			"type": "Active Cart",
			"utm_campaign": "Facebook-Conversions",
			"utm_source": "Facebook",
			"utm_medium": "Ad",
			"utm_content": "build-your-own-bundle-all-shrimp-sizes",
			"utm_term": "(none)",
			"lineItems": [
				{
					"name": "Jumbo 21/30 Peeled & Deveined Tail-On Wild-Caught Gulf Shrimp ($22.00/lb) x 3"
				},
				{
					"name": "Jumbo 21/30 Count Headless Wild-Caught Gulf Shrimp ($18.00/LB) x 4"
				},
				{
					"name": "Route Package Protection"
				}
			]
		},
		{
			"identifier": "shopify-8c95421bd449570f5cb12fdbc50455b6",
			"order_id": null,
			"domain": "namebranfam.com",
			"channel": "shopify",
			"session": null,
			"pageviews": 9,
			"user_data": null,
			"promo": null,
			"sales": "0.00",
			"storefront_data": {
				"lineItems": [
					"Diamond Single Tooth Grillz"
				]
			},
			"first_visit": {
				"ep": "https://namebranfam.com/",
				"fd": "2023-05-02 13:04:32",
				"rf": "(none)",
				"cmp": "(none)",
				"cnt": "(none)",
				"mdm": "(none)",
				"src": "(direct)",
				"trm": "(none)",
				"typ": "typein"
			},
			"latest_visit": {
				"ep": "https://namebranfam.com/",
				"fd": "2023-05-02 13:04:32",
				"rf": "(none)",
				"cmp": "(none)",
				"cnt": "(none)",
				"mdm": "(none)",
				"src": "(direct)",
				"trm": "(none)",
				"typ": "typein"
			},
			"first_date": "2023-05-02T17:04:33.618Z",
			"last_date": "2023-05-02T17:06:06.335Z",
			"checkout_stage": "cart_added",
			"checkout_stage_history": null,
			"current_page": "/cart",
			"page_history": null,
			"type": "Active Cart",
			"utm_campaign": "(none)",
			"utm_source": "(direct)",
			"utm_medium": "(none)",
			"utm_content": "(none)",
			"utm_term": "(none)",
			"lineItems": [
				{
					"name": "Diamond Single Tooth Grillz"
				}
			]
		},
		{
			"identifier": "bigcommerce-2454d987-2749-4fb5-a562-461cf39e9fc5",
			"order_id": null,
			"domain": "monstrumtactical.com",
			"channel": "bigcommerce",
			"session": null,
			"pageviews": 7,
			"user_data": null,
			"promo": null,
			"sales": "75.00",
			"storefront_data": {
				"lineItems": [
					"Alpha 1-4x24 FFP Rifle Scope"
				]
			},
			"first_visit": {
				"ep": "https://monstrumtactical.com/rifle-scopes/",
				"fd": "2023-04-19 22:26:39",
				"rf": "https://www.google.com/",
				"cmp": "(none)",
				"cnt": "(none)",
				"mdm": "organic",
				"src": "google",
				"trm": "(none)",
				"typ": "organic"
			},
			"latest_visit": {
				"ep": "https://monstrumtactical.com/rifle-scopes/?page=2",
				"fd": "2023-05-02 12:56:59",
				"rf": "https://www.google.com/",
				"cmp": "(none)",
				"cnt": "(none)",
				"mdm": "organic",
				"src": "google",
				"trm": "(none)",
				"typ": "organic"
			},
			"first_date": "2023-05-02T16:55:29.902Z",
			"last_date": "2023-05-02T17:06:05.975Z",
			"checkout_stage": "cart_added",
			"checkout_stage_history": null,
			"current_page": "/monstrum-tactical-heavy-duty-30mm-cantilever-adjustable-dual-ring-scope-mount/",
			"page_history": null,
			"type": "Active Cart",
			"utm_campaign": "(none)",
			"utm_source": "google",
			"utm_medium": "organic",
			"utm_content": "(none)",
			"utm_term": "(none)",
			"lineItems": [
				{
					"name": "Alpha 1-4x24 FFP Rifle Scope"
				}
			]
		},
		{
			"identifier": "bigcommerce-08337bf9-d019-4633-a6e3-e6478d1d1c35",
			"order_id": null,
			"domain": "bc.lindafinegold.com",
			"channel": "bigcommerce",
			"session": null,
			"pageviews": 4,
			"user_data": null,
			"promo": null,
			"sales": "12.00",
			"storefront_data": {
				"lineItems": [
					"NOT HANDICAPPED • Air Freshener",
					"RANDOM FREE STICKERS • Collect All 100 of Them",
					"ASSHOLES LIFT FOREVER • Black Tee",
					"THIS AIR FRESHENER DIDNT HANG ITSELF • Air Freshener",
					"NEW $10 DUFFLE BAG FULL OF SHIT • Comes w GLOW IN THE DARK Tee",
					"NEW $10 DUFFLE BAG FULL OF SHIT • Comes w GLOW IN THE DARK Tee"
				]
			},
			"first_visit": {
				"ep": "https://www.lindafinegold.com/",
				"fd": "2023-03-22 17:27:46",
				"rf": "(none)",
				"cmp": "(none)",
				"cnt": "(none)",
				"mdm": "(none)",
				"src": "(direct)",
				"trm": "(none)",
				"typ": "typein"
			},
			"latest_visit": {
				"ep": "https://www.lindafinegold.com/",
				"fd": "2023-03-22 17:27:46",
				"rf": "(none)",
				"cmp": "(none)",
				"cnt": "(none)",
				"mdm": "(none)",
				"src": "(direct)",
				"trm": "(none)",
				"typ": "typein"
			},
			"first_date": "2023-03-28T19:02:47.764Z",
			"last_date": "2023-05-02T17:06:05.092Z",
			"checkout_stage": "checking_out",
			"checkout_stage_history": null,
			"current_page": "/checkout",
			"page_history": null,
			"type": "Active Cart",
			"utm_campaign": "(none)",
			"utm_source": "(direct)",
			"utm_medium": "(none)",
			"utm_content": "(none)",
			"utm_term": "(none)",
			"lineItems": [
				{
					"name": "NOT HANDICAPPED • Air Freshener"
				},
				{
					"name": "RANDOM FREE STICKERS • Collect All 100 of Them"
				},
				{
					"name": "ASSHOLES LIFT FOREVER • Black Tee"
				},
				{
					"name": "THIS AIR FRESHENER DIDNT HANG ITSELF • Air Freshener"
				},
				{
					"name": "NEW $10 DUFFLE BAG FULL OF SHIT • Comes w GLOW IN THE DARK Tee"
				},
				{
					"name": "NEW $10 DUFFLE BAG FULL OF SHIT • Comes w GLOW IN THE DARK Tee"
				}
			]
		},
		{
			"identifier": "bigcommerce-962974aa-8437-4ef8-9fac-92bac7f6b719",
			"order_id": null,
			"domain": "www.perfectsupplements.com",
			"channel": "bigcommerce",
			"session": null,
			"pageviews": 2,
			"user_data": null,
			"promo": null,
			"sales": "42.95",
			"storefront_data": {
				"lineItems": [
					"Perfect Hydrolyzed Collagen Unflavored - 100% Collagen Sourced From Brazilian Pasture Raised (Grass Fed) Cows"
				]
			},
			"first_visit": {
				"ep": "https://www.perfectsupplements.com/?fbclid=PAAab4riKLPL2fk9LVZQw1sc-NyrMSKLJ0MMvYy3OPWFFRv9bToHHrID31xio",
				"fd": "2023-05-02 13:05:46",
				"rf": "https://l.instagram.com/",
				"cmp": "(none)",
				"cnt": "/",
				"mdm": "referral",
				"src": "l.instagram.com",
				"trm": "(none)",
				"typ": "referral"
			},
			"latest_visit": {
				"ep": "https://www.perfectsupplements.com/?fbclid=PAAab4riKLPL2fk9LVZQw1sc-NyrMSKLJ0MMvYy3OPWFFRv9bToHHrID31xio",
				"fd": "2023-05-02 13:05:46",
				"rf": "https://l.instagram.com/",
				"cmp": "(none)",
				"cnt": "/",
				"mdm": "referral",
				"src": "l.instagram.com",
				"trm": "(none)",
				"typ": "referral"
			},
			"first_date": "2023-05-02T17:05:47.414Z",
			"last_date": "2023-05-02T17:06:05.042Z",
			"checkout_stage": "cart_added",
			"checkout_stage_history": null,
			"current_page": "/cart.php",
			"page_history": null,
			"type": "Active Cart",
			"utm_campaign": "(none)",
			"utm_source": "l.instagram.com",
			"utm_medium": "referral",
			"utm_content": "/",
			"utm_term": "(none)",
			"lineItems": [
				{
					"name": "Perfect Hydrolyzed Collagen Unflavored - 100% Collagen Sourced From Brazilian Pasture Raised (Grass Fed) Cows"
				}
			]
		},
		{
			"identifier": "bigcommerce-bd3abb42-9ec4-4a47-85e9-4a74e6285ebc",
			"order_id": null,
			"domain": "www.prioritytire.com",
			"channel": "bigcommerce",
			"session": null,
			"pageviews": 1,
			"user_data": null,
			"promo": null,
			"sales": "337.36",
			"storefront_data": {
				"lineItems": [
					"Fullway HS266 305/30R26 109V XL AS A/S All Season Tire x 2",
					"State Tire Fee (PTCOEXTAX)"
				]
			},
			"first_visit": {
				"ep": "https://www.prioritytire.com/cart.php",
				"fd": "2023-05-02 11:04:42",
				"rf": "https://www.prioritytire.com/cart.php",
				"cmp": "(none)",
				"cnt": "(none)",
				"mdm": "(none)",
				"src": "(direct)",
				"trm": "(none)",
				"typ": "typein"
			},
			"latest_visit": {
				"ep": "https://www.prioritytire.com/cart.php",
				"fd": "2023-05-02 11:04:42",
				"rf": "https://www.prioritytire.com/cart.php",
				"cmp": "(none)",
				"cnt": "(none)",
				"mdm": "(none)",
				"src": "(direct)",
				"trm": "(none)",
				"typ": "typein"
			},
			"first_date": "2023-04-30T21:49:43.125Z",
			"last_date": "2023-05-02T17:06:04.496Z",
			"checkout_stage": "checking_out",
			"checkout_stage_history": null,
			"current_page": "/checkout",
			"page_history": null,
			"type": "Active Cart",
			"utm_campaign": "(none)",
			"utm_source": "(direct)",
			"utm_medium": "(none)",
			"utm_content": "(none)",
			"utm_term": "(none)",
			"lineItems": [
				{
					"name": "Fullway HS266 305/30R26 109V XL AS A/S All Season Tire x 2"
				},
				{
					"name": "State Tire Fee (PTCOEXTAX)"
				}
			]
		},
		{
			"identifier": "bigcommerce-6210f64f-e58f-40fc-a949-7dd594799dc7",
			"order_id": null,
			"domain": "www.prioritytire.com",
			"channel": "bigcommerce",
			"session": null,
			"pageviews": 1,
			"user_data": null,
			"promo": null,
			"sales": "126.24",
			"storefront_data": {
				"lineItems": [
					"Provider Radial ST ST 205/75R15 107M C (6 Ply) Trailer Tire 5 x 4.5 Modular White Trailer Tire and Wheel Assembly",
					"State Tire Fee (PTNJEXTAX)"
				]
			},
			"first_visit": {
				"ep": "https://www.prioritytire.com/",
				"fd": "2023-05-02 12:30:32",
				"rf": "https://www.google.com/",
				"cmp": "(none)",
				"cnt": "(none)",
				"mdm": "organic",
				"src": "google",
				"trm": "(none)",
				"typ": "organic"
			},
			"latest_visit": {
				"ep": "https://www.prioritytire.com/",
				"fd": "2023-05-02 12:30:32",
				"rf": "https://www.google.com/",
				"cmp": "(none)",
				"cnt": "(none)",
				"mdm": "organic",
				"src": "google",
				"trm": "(none)",
				"typ": "organic"
			},
			"first_date": "2023-05-02T16:30:34.303Z",
			"last_date": "2023-05-02T17:06:04.473Z",
			"checkout_stage": "checking_out",
			"checkout_stage_history": null,
			"current_page": "/checkout",
			"page_history": null,
			"type": "Active Cart",
			"utm_campaign": "(none)",
			"utm_source": "google",
			"utm_medium": "organic",
			"utm_content": "(none)",
			"utm_term": "(none)",
			"lineItems": [
				{
					"name": "Provider Radial ST ST 205/75R15 107M C (6 Ply) Trailer Tire 5 x 4.5 Modular White Trailer Tire and Wheel Assembly"
				},
				{
					"name": "State Tire Fee (PTNJEXTAX)"
				}
			]
		}
	],
	"checkouts": [
		{
			"identifier": "bigcommerce-f59d763d-1cc6-4acd-80de-6e40999e701b",
			"order_id": null,
			"domain": "koruworks.com",
			"channel": "bigcommerce",
			"session": null,
			"pageviews": 9,
			"user_data": null,
			"promo": null,
			"sales": "1950.00",
			"storefront_data": {
				"lineItems": [
					"IN-STOCK M-Sports S13 Silvia Type B Full Kit"
				]
			},
			"first_visit": {
				"ep": "https://koruworks.com/j-replace/",
				"fd": "2023-04-30 14:09:44",
				"rf": "https://www.google.com/",
				"cmp": "(none)",
				"cnt": "(none)",
				"mdm": "organic",
				"src": "google",
				"trm": "(none)",
				"typ": "organic"
			},
			"latest_visit": {
				"ep": "https://koruworks.com/make-model/nissan/180sx-s13-silvia-240sx/exterior/m-sports-s13-silvia-type-b-full-kit/",
				"fd": "2023-05-02 09:51:00",
				"rf": "https://www.google.com/",
				"cmp": "(none)",
				"cnt": "(none)",
				"mdm": "organic",
				"src": "google",
				"trm": "(none)",
				"typ": "organic"
			},
			"first_date": "2023-02-20T01:12:14.223Z",
			"last_date": "2023-05-02T17:06:17.942Z",
			"checkout_stage": "checking_out",
			"checkout_stage_history": null,
			"current_page": "/checkout",
			"page_history": null,
			"type": "Active Cart",
			"utm_campaign": "(none)",
			"utm_source": "google",
			"utm_medium": "organic",
			"utm_content": "(none)",
			"utm_term": "(none)",
			"lineItems": [
				{
					"name": "IN-STOCK M-Sports S13 Silvia Type B Full Kit"
				}
			]
		},
		{
			"identifier": "bigcommerce-f00451aa-43ad-41d2-8e54-0e9b0b885a68",
			"order_id": null,
			"domain": "fiveflavorsherbs.com",
			"channel": "bigcommerce",
			"session": null,
			"pageviews": 6,
			"user_data": null,
			"promo": null,
			"sales": "93.00",
			"storefront_data": {
				"lineItems": [
					"Ashwagandha 8oz",
					"Sweet Dreams Tincture"
				]
			},
			"first_visit": {
				"ep": "https://fiveflavorsherbs.com/",
				"fd": "2023-04-20 13:25:15",
				"rf": "https://www.google.com/",
				"cmp": "(none)",
				"cnt": "(none)",
				"mdm": "organic",
				"src": "google",
				"trm": "(none)",
				"typ": "organic"
			},
			"latest_visit": {
				"ep": "https://fiveflavorsherbs.com/",
				"fd": "2023-05-02 10:04:10",
				"rf": "https://www.google.com/",
				"cmp": "(none)",
				"cnt": "(none)",
				"mdm": "organic",
				"src": "google",
				"trm": "(none)",
				"typ": "organic"
			},
			"first_date": "2023-05-02T17:04:10.311Z",
			"last_date": "2023-05-02T17:06:14.722Z",
			"checkout_stage": "checking_out",
			"checkout_stage_history": null,
			"current_page": "/checkout",
			"page_history": null,
			"type": "Active Cart",
			"utm_campaign": "(none)",
			"utm_source": "google",
			"utm_medium": "organic",
			"utm_content": "(none)",
			"utm_term": "(none)",
			"lineItems": [
				{
					"name": "Ashwagandha 8oz"
				},
				{
					"name": "Sweet Dreams Tincture"
				}
			]
		},
		{
			"identifier": "bigcommerce-b40cbd9a-3507-4846-8464-ac58f2223f07",
			"order_id": null,
			"domain": "groomerschoice.com",
			"channel": "bigcommerce",
			"session": null,
			"pageviews": 19,
			"user_data": null,
			"promo": null,
			"sales": "253.92",
			"storefront_data": {
				"lineItems": [
					"Bubble Bros. Oatmeal Dog Shampoo, 1 Gallon x 4",
					"Bubble Bros. Oatmeal Dog Conditioner, 1 Gallon x 2",
					"The Coat Handler Undercoat Control DeShedding Conditioner Coconut Milk Fragrance, 1 Gallon",
					"Petology Oatmeal Honey Soothing Daily Finishing Spray, 1 Gallon"
				]
			},
			"first_visit": {
				"ep": "https://groomerschoice.com/",
				"fd": "2023-05-02 08:44:26",
				"rf": "https://www.google.com/",
				"cmp": "(none)",
				"cnt": "(none)",
				"mdm": "organic",
				"src": "google",
				"trm": "(none)",
				"typ": "organic"
			},
			"latest_visit": {
				"ep": "https://groomerschoice.com/",
				"fd": "2023-05-02 08:44:26",
				"rf": "https://www.google.com/",
				"cmp": "(none)",
				"cnt": "(none)",
				"mdm": "organic",
				"src": "google",
				"trm": "(none)",
				"typ": "organic"
			},
			"first_date": "2023-05-02T13:44:27.102Z",
			"last_date": "2023-05-02T17:06:13.254Z",
			"checkout_stage": "checking_out",
			"checkout_stage_history": null,
			"current_page": "/checkout",
			"page_history": null,
			"type": "Active Cart",
			"utm_campaign": "(none)",
			"utm_source": "google",
			"utm_medium": "organic",
			"utm_content": "(none)",
			"utm_term": "(none)",
			"lineItems": [
				{
					"name": "Bubble Bros. Oatmeal Dog Shampoo, 1 Gallon x 4"
				},
				{
					"name": "Bubble Bros. Oatmeal Dog Conditioner, 1 Gallon x 2"
				},
				{
					"name": "The Coat Handler Undercoat Control DeShedding Conditioner Coconut Milk Fragrance, 1 Gallon"
				},
				{
					"name": "Petology Oatmeal Honey Soothing Daily Finishing Spray, 1 Gallon"
				}
			]
		},
		{
			"identifier": "bigcommerce-08337bf9-d019-4633-a6e3-e6478d1d1c35",
			"order_id": null,
			"domain": "bc.lindafinegold.com",
			"channel": "bigcommerce",
			"session": null,
			"pageviews": 4,
			"user_data": null,
			"promo": null,
			"sales": "12.00",
			"storefront_data": {
				"lineItems": [
					"NOT HANDICAPPED • Air Freshener",
					"RANDOM FREE STICKERS • Collect All 100 of Them",
					"ASSHOLES LIFT FOREVER • Black Tee",
					"THIS AIR FRESHENER DIDNT HANG ITSELF • Air Freshener",
					"NEW $10 DUFFLE BAG FULL OF SHIT • Comes w GLOW IN THE DARK Tee",
					"NEW $10 DUFFLE BAG FULL OF SHIT • Comes w GLOW IN THE DARK Tee"
				]
			},
			"first_visit": {
				"ep": "https://www.lindafinegold.com/",
				"fd": "2023-03-22 17:27:46",
				"rf": "(none)",
				"cmp": "(none)",
				"cnt": "(none)",
				"mdm": "(none)",
				"src": "(direct)",
				"trm": "(none)",
				"typ": "typein"
			},
			"latest_visit": {
				"ep": "https://www.lindafinegold.com/",
				"fd": "2023-03-22 17:27:46",
				"rf": "(none)",
				"cmp": "(none)",
				"cnt": "(none)",
				"mdm": "(none)",
				"src": "(direct)",
				"trm": "(none)",
				"typ": "typein"
			},
			"first_date": "2023-03-28T19:02:47.764Z",
			"last_date": "2023-05-02T17:06:05.092Z",
			"checkout_stage": "checking_out",
			"checkout_stage_history": null,
			"current_page": "/checkout",
			"page_history": null,
			"type": "Active Cart",
			"utm_campaign": "(none)",
			"utm_source": "(direct)",
			"utm_medium": "(none)",
			"utm_content": "(none)",
			"utm_term": "(none)",
			"lineItems": [
				{
					"name": "NOT HANDICAPPED • Air Freshener"
				},
				{
					"name": "RANDOM FREE STICKERS • Collect All 100 of Them"
				},
				{
					"name": "ASSHOLES LIFT FOREVER • Black Tee"
				},
				{
					"name": "THIS AIR FRESHENER DIDNT HANG ITSELF • Air Freshener"
				},
				{
					"name": "NEW $10 DUFFLE BAG FULL OF SHIT • Comes w GLOW IN THE DARK Tee"
				},
				{
					"name": "NEW $10 DUFFLE BAG FULL OF SHIT • Comes w GLOW IN THE DARK Tee"
				}
			]
		},
		{
			"identifier": "bigcommerce-bd3abb42-9ec4-4a47-85e9-4a74e6285ebc",
			"order_id": null,
			"domain": "www.prioritytire.com",
			"channel": "bigcommerce",
			"session": null,
			"pageviews": 1,
			"user_data": null,
			"promo": null,
			"sales": "337.36",
			"storefront_data": {
				"lineItems": [
					"Fullway HS266 305/30R26 109V XL AS A/S All Season Tire x 2",
					"State Tire Fee (PTCOEXTAX)"
				]
			},
			"first_visit": {
				"ep": "https://www.prioritytire.com/cart.php",
				"fd": "2023-05-02 11:04:42",
				"rf": "https://www.prioritytire.com/cart.php",
				"cmp": "(none)",
				"cnt": "(none)",
				"mdm": "(none)",
				"src": "(direct)",
				"trm": "(none)",
				"typ": "typein"
			},
			"latest_visit": {
				"ep": "https://www.prioritytire.com/cart.php",
				"fd": "2023-05-02 11:04:42",
				"rf": "https://www.prioritytire.com/cart.php",
				"cmp": "(none)",
				"cnt": "(none)",
				"mdm": "(none)",
				"src": "(direct)",
				"trm": "(none)",
				"typ": "typein"
			},
			"first_date": "2023-04-30T21:49:43.125Z",
			"last_date": "2023-05-02T17:06:04.496Z",
			"checkout_stage": "checking_out",
			"checkout_stage_history": null,
			"current_page": "/checkout",
			"page_history": null,
			"type": "Active Cart",
			"utm_campaign": "(none)",
			"utm_source": "(direct)",
			"utm_medium": "(none)",
			"utm_content": "(none)",
			"utm_term": "(none)",
			"lineItems": [
				{
					"name": "Fullway HS266 305/30R26 109V XL AS A/S All Season Tire x 2"
				},
				{
					"name": "State Tire Fee (PTCOEXTAX)"
				}
			]
		},
		{
			"identifier": "bigcommerce-6210f64f-e58f-40fc-a949-7dd594799dc7",
			"order_id": null,
			"domain": "www.prioritytire.com",
			"channel": "bigcommerce",
			"session": null,
			"pageviews": 1,
			"user_data": null,
			"promo": null,
			"sales": "126.24",
			"storefront_data": {
				"lineItems": [
					"Provider Radial ST ST 205/75R15 107M C (6 Ply) Trailer Tire 5 x 4.5 Modular White Trailer Tire and Wheel Assembly",
					"State Tire Fee (PTNJEXTAX)"
				]
			},
			"first_visit": {
				"ep": "https://www.prioritytire.com/",
				"fd": "2023-05-02 12:30:32",
				"rf": "https://www.google.com/",
				"cmp": "(none)",
				"cnt": "(none)",
				"mdm": "organic",
				"src": "google",
				"trm": "(none)",
				"typ": "organic"
			},
			"latest_visit": {
				"ep": "https://www.prioritytire.com/",
				"fd": "2023-05-02 12:30:32",
				"rf": "https://www.google.com/",
				"cmp": "(none)",
				"cnt": "(none)",
				"mdm": "organic",
				"src": "google",
				"trm": "(none)",
				"typ": "organic"
			},
			"first_date": "2023-05-02T16:30:34.303Z",
			"last_date": "2023-05-02T17:06:04.473Z",
			"checkout_stage": "checking_out",
			"checkout_stage_history": null,
			"current_page": "/checkout",
			"page_history": null,
			"type": "Active Cart",
			"utm_campaign": "(none)",
			"utm_source": "google",
			"utm_medium": "organic",
			"utm_content": "(none)",
			"utm_term": "(none)",
			"lineItems": [
				{
					"name": "Provider Radial ST ST 205/75R15 107M C (6 Ply) Trailer Tire 5 x 4.5 Modular White Trailer Tire and Wheel Assembly"
				},
				{
					"name": "State Tire Fee (PTNJEXTAX)"
				}
			]
		},
		{
			"identifier": "bigcommerce-db3be4cb-d88e-406b-993f-0cd78ba11292",
			"order_id": null,
			"domain": "groomerschoice.com",
			"channel": "bigcommerce",
			"session": null,
			"pageviews": 15,
			"user_data": null,
			"promo": null,
			"sales": "27.99",
			"storefront_data": {
				"lineItems": [
					"BatherBox Go To Dog Shampoo, 1 Gallon"
				]
			},
			"first_visit": {
				"ep": "https://groomerschoice.com/batherbox-go-to-shampoo/",
				"fd": "2023-05-01 21:14:13",
				"rf": "https://www.google.com/",
				"cmp": "(none)",
				"cnt": "(none)",
				"mdm": "organic",
				"src": "google",
				"trm": "(none)",
				"typ": "organic"
			},
			"latest_visit": {
				"ep": "https://groomerschoice.com/batherbox-go-to-shampoo/",
				"fd": "2023-05-02 11:59:07",
				"rf": "https://www.google.com/",
				"cmp": "(none)",
				"cnt": "(none)",
				"mdm": "organic",
				"src": "google",
				"trm": "(none)",
				"typ": "organic"
			},
			"first_date": "2023-05-02T02:14:13.983Z",
			"last_date": "2023-05-02T17:06:04.318Z",
			"checkout_stage": "checking_out",
			"checkout_stage_history": null,
			"current_page": "/checkout",
			"page_history": null,
			"type": "Active Cart",
			"utm_campaign": "(none)",
			"utm_source": "google",
			"utm_medium": "organic",
			"utm_content": "(none)",
			"utm_term": "(none)",
			"lineItems": [
				{
					"name": "BatherBox Go To Dog Shampoo, 1 Gallon"
				}
			]
		},
		{
			"identifier": "bigcommerce-ba80684f-78cc-4235-aa6b-81c700ad5ed1",
			"order_id": null,
			"domain": "diamonddynastyvirginhair.com",
			"channel": "bigcommerce",
			"session": null,
			"pageviews": 72,
			"user_data": null,
			"promo": null,
			"sales": "952.00",
			"storefront_data": {
				"lineItems": [
					"Single Bundle Deals/Add on Bundles (Color 1B) x 4",
					"Single Bundle Deals/Add on Bundles (Color 1B) x 4"
				]
			},
			"first_visit": {
				"ep": "https://diamonddynastyvirginhair.com/",
				"fd": "2023-04-27 13:33:46",
				"rf": "https://www.google.com/",
				"cmp": "(none)",
				"cnt": "(none)",
				"mdm": "organic",
				"src": "google",
				"trm": "(none)",
				"typ": "organic"
			},
			"latest_visit": {
				"ep": "https://diamonddynastyvirginhair.com/",
				"fd": "2023-04-27 13:33:46",
				"rf": "https://www.google.com/",
				"cmp": "(none)",
				"cnt": "(none)",
				"mdm": "organic",
				"src": "google",
				"trm": "(none)",
				"typ": "organic"
			},
			"first_date": "2023-05-01T13:11:40.613Z",
			"last_date": "2023-05-02T17:06:03.235Z",
			"checkout_stage": "checking_out",
			"checkout_stage_history": null,
			"current_page": "/checkout",
			"page_history": null,
			"type": "Active Cart",
			"utm_campaign": "(none)",
			"utm_source": "google",
			"utm_medium": "organic",
			"utm_content": "(none)",
			"utm_term": "(none)",
			"lineItems": [
				{
					"name": "Single Bundle Deals/Add on Bundles (Color 1B) x 4"
				},
				{
					"name": "Single Bundle Deals/Add on Bundles (Color 1B) x 4"
				}
			]
		},
		{
			"identifier": "bigcommerce-9df91159-211f-441e-8796-64280b3b51ba",
			"order_id": null,
			"domain": "zogics.com",
			"channel": "bigcommerce",
			"session": null,
			"pageviews": 0,
			"user_data": null,
			"promo": null,
			"sales": "127.95",
			"storefront_data": {
				"lineItems": [
					"Antibacterial Disinfecting Wipes, Z800-4 (4 rolls/case)"
				]
			},
			"first_visit": {
				"cmp": "Finch-Zogics.com-Search-Brand",
				"cnt": "120044704742",
				"mdm": "cpc",
				"src": "google",
				"trm": "zogics",
				"typ": "utm"
			},
			"latest_visit": {
				"cmp": "Finch-Zogics.com-Search-Brand",
				"cnt": "120044704742",
				"mdm": "cpc",
				"src": "google",
				"trm": "zogics",
				"typ": "utm"
			},
			"first_date": "2023-05-02T15:56:08.746Z",
			"last_date": "2023-05-02T17:06:03.016Z",
			"checkout_stage": "checking_out",
			"checkout_stage_history": null,
			"current_page": "/checkout",
			"page_history": null,
			"type": "Active Cart",
			"utm_campaign": "Finch-Zogics.com-Search-Brand",
			"utm_source": "google",
			"utm_medium": "cpc",
			"utm_content": "120044704742",
			"utm_term": "zogics",
			"lineItems": [
				{
					"name": "Antibacterial Disinfecting Wipes, Z800-4 (4 rolls/case)"
				}
			]
		},
		{
			"identifier": "bigcommerce-4a246891-422b-4f40-8034-a48e3b0f1198",
			"order_id": null,
			"domain": "bc.lindafinegold.com",
			"channel": "bigcommerce",
			"session": null,
			"pageviews": 5,
			"user_data": null,
			"promo": null,
			"sales": "10.80",
			"storefront_data": {
				"lineItems": [
					"ALFVANA $5 Tee • Tee Shirt",
					"OG ALF TANKTOP MYSTERY BOX • Comes w OG Tank and 6 Items",
					"ALFTALLICA $5 Tee • Tee Shirt",
					"ALFGUNS $5 Tee • Tee Shirt",
					"SPRING CLEANING BOX"
				]
			},
			"first_visit": {
				"ep": "https://www.lindafinegold.com/account/login",
				"fd": "2023-01-10 20:30:39",
				"rf": "https://www.google.com/",
				"cmp": "(none)",
				"cnt": "(none)",
				"mdm": "organic",
				"src": "google",
				"trm": "(none)",
				"typ": "organic"
			},
			"latest_visit": {
				"ep": "https://www.lindafinegold.com/account",
				"fd": "2023-05-02 12:53:11",
				"rf": "https://www.google.com/",
				"cmp": "(none)",
				"cnt": "(none)",
				"mdm": "organic",
				"src": "google",
				"trm": "(none)",
				"typ": "organic"
			},
			"first_date": "2023-05-02T16:54:13.550Z",
			"last_date": "2023-05-02T17:05:58.509Z",
			"checkout_stage": "checking_out",
			"checkout_stage_history": null,
			"current_page": "/checkout",
			"page_history": null,
			"type": "Active Cart",
			"utm_campaign": "(none)",
			"utm_source": "google",
			"utm_medium": "organic",
			"utm_content": "(none)",
			"utm_term": "(none)",
			"lineItems": [
				{
					"name": "ALFVANA $5 Tee • Tee Shirt"
				},
				{
					"name": "OG ALF TANKTOP MYSTERY BOX • Comes w OG Tank and 6 Items"
				},
				{
					"name": "ALFTALLICA $5 Tee • Tee Shirt"
				},
				{
					"name": "ALFGUNS $5 Tee • Tee Shirt"
				},
				{
					"name": "SPRING CLEANING BOX"
				}
			]
		},
		{
			"identifier": "bigcommerce-39f1cb55-246f-45a3-87c1-982dbcd7abe5",
			"order_id": null,
			"domain": "dixiebellepaint.com",
			"channel": "bigcommerce",
			"session": null,
			"pageviews": 3,
			"user_data": null,
			"promo": null,
			"sales": "76.70",
			"storefront_data": {
				"lineItems": [
					"Voodoo Gel Stain (Water-Based) x 2",
					"Foam N Dandy",
					"White Lightning Cleaner",
					"Foam N Dandy",
					"Clear Coat Satin",
					"Mystery Gift"
				]
			},
			"first_visit": {
				"cmp": "google_cpc",
				"cnt": "(none)",
				"mdm": "cpc",
				"src": "google",
				"trm": "(none)",
				"typ": "utm"
			},
			"latest_visit": {
				"cmp": "google_cpc",
				"cnt": "(none)",
				"mdm": "cpc",
				"src": "google",
				"trm": "(none)",
				"typ": "utm"
			},
			"first_date": "2023-04-25T04:19:15.508Z",
			"last_date": "2023-05-02T17:05:57.128Z",
			"checkout_stage": "checking_out",
			"checkout_stage_history": null,
			"current_page": "/checkout",
			"page_history": null,
			"type": "Active Cart",
			"utm_campaign": "google_cpc",
			"utm_source": "google",
			"utm_medium": "cpc",
			"utm_content": "(none)",
			"utm_term": "(none)",
			"lineItems": [
				{
					"name": "Voodoo Gel Stain (Water-Based) x 2"
				},
				{
					"name": "Foam N Dandy"
				},
				{
					"name": "White Lightning Cleaner"
				},
				{
					"name": "Foam N Dandy"
				},
				{
					"name": "Clear Coat Satin"
				},
				{
					"name": "Mystery Gift"
				}
			]
		},
		{
			"identifier": "bigcommerce-b2d98ebb-a4b6-42c0-a78b-0145bfd86fc0",
			"order_id": null,
			"domain": "bc.lindafinegold.com",
			"channel": "bigcommerce",
			"session": null,
			"pageviews": 9,
			"user_data": null,
			"promo": null,
			"sales": "36.00",
			"storefront_data": {
				"lineItems": [
					"BLACKOUT DUFFLE BAG FULL OF SHIT • All Black Mystery Box",
					"TAKE A TRIP  • Unisex Black Multicolor Mushroom Printed Hoodie",
					"ALF STARTER PACK • 10 Item Bundle",
					"RANDOM FREE STICKERS • Collect All 100 of Them",
					"OG ALF TANKTOP MYSTERY BOX • Comes w OG Tank and 6 Items"
				]
			},
			"first_visit": {
				"ep": "https://www.lindafinegold.com/help",
				"fd": "2023-04-29 17:04:57",
				"rf": "(none)",
				"cmp": "(none)",
				"cnt": "(none)",
				"mdm": "(none)",
				"src": "(direct)",
				"trm": "(none)",
				"typ": "typein"
			},
			"latest_visit": {
				"ep": "https://www.lindafinegold.com/help",
				"fd": "2023-04-29 17:04:57",
				"rf": "(none)",
				"cmp": "(none)",
				"cnt": "(none)",
				"mdm": "(none)",
				"src": "(direct)",
				"trm": "(none)",
				"typ": "typein"
			},
			"first_date": "2023-04-30T00:04:58.976Z",
			"last_date": "2023-05-02T17:05:55.803Z",
			"checkout_stage": "checking_out",
			"checkout_stage_history": null,
			"current_page": "/checkout",
			"page_history": null,
			"type": "Active Cart",
			"utm_campaign": "(none)",
			"utm_source": "(direct)",
			"utm_medium": "(none)",
			"utm_content": "(none)",
			"utm_term": "(none)",
			"lineItems": [
				{
					"name": "BLACKOUT DUFFLE BAG FULL OF SHIT • All Black Mystery Box"
				},
				{
					"name": "TAKE A TRIP  • Unisex Black Multicolor Mushroom Printed Hoodie"
				},
				{
					"name": "ALF STARTER PACK • 10 Item Bundle"
				},
				{
					"name": "RANDOM FREE STICKERS • Collect All 100 of Them"
				},
				{
					"name": "OG ALF TANKTOP MYSTERY BOX • Comes w OG Tank and 6 Items"
				}
			]
		},
		{
			"identifier": "bigcommerce-493d33fb-cbef-4531-af83-fbb9d5baf8c1",
			"order_id": null,
			"domain": "www.prioritytire.com",
			"channel": "bigcommerce",
			"session": null,
			"pageviews": 1,
			"user_data": null,
			"promo": null,
			"sales": "407.96",
			"storefront_data": {
				"lineItems": [
					"Nexen Classe Premiere CP672 215/55R17 94V AS A/S All Season Tire x 4"
				]
			},
			"first_visit": {
				"ep": "https://www.prioritytire.com/nexen-classe-premiere-cp672-185-65r15-88h-as-a-s-all-season-tire/",
				"fd": "2023-05-02 14:29:36",
				"rf": "(none)",
				"cmp": "(none)",
				"cnt": "(none)",
				"mdm": "(none)",
				"src": "(direct)",
				"trm": "(none)",
				"typ": "typein"
			},
			"latest_visit": {
				"ep": "https://www.prioritytire.com/nexen-classe-premiere-cp672-245-40r18-97h-xl-as-a-s-all-season-tire/",
				"fd": "2023-05-02 20:04:05",
				"rf": "https://www.google.com/",
				"cmp": "(none)",
				"cnt": "(none)",
				"mdm": "organic",
				"src": "google",
				"trm": "(none)",
				"typ": "organic"
			},
			"first_date": "2023-05-02T11:29:38.264Z",
			"last_date": "2023-05-02T17:05:55.467Z",
			"checkout_stage": "checking_out",
			"checkout_stage_history": null,
			"current_page": "/checkout",
			"page_history": null,
			"type": "Active Cart",
			"utm_campaign": "(none)",
			"utm_source": "(direct)",
			"utm_medium": "(none)",
			"utm_content": "(none)",
			"utm_term": "(none)",
			"lineItems": [
				{
					"name": "Nexen Classe Premiere CP672 215/55R17 94V AS A/S All Season Tire x 4"
				}
			]
		},
		{
			"identifier": "bigcommerce-8b81ed8b-d38f-465a-a1de-dfc8e5a212f5",
			"order_id": null,
			"domain": "gregnormancollection.com",
			"channel": "bigcommerce",
			"session": null,
			"pageviews": 2,
			"user_data": null,
			"promo": null,
			"sales": "118.98",
			"storefront_data": {
				"lineItems": [
					"Classic Pique Shark Polo",
					"Classic Pique Shark Polo",
					"Classic Pique Shark Polo"
				]
			},
			"first_visit": {
				"cmp": "Digital-Matter_Greg-Norman_Prospecting_Evergreen_2022Conversions_Ongoing",
				"cnt": "Pique3For$119_Prospecting_For-a-limited_1080X1080.jpg",
				"mdm": "paid_social",
				"src": "facebook",
				"trm": "(none)",
				"typ": "utm"
			},
			"latest_visit": {
				"cmp": "Digital-Matter_Greg-Norman_Prospecting_Evergreen_2022Conversions_Ongoing",
				"cnt": "Pique3For$119_Prospecting_For-a-limited_1080X1080.jpg",
				"mdm": "paid_social",
				"src": "facebook",
				"trm": "(none)",
				"typ": "utm"
			},
			"first_date": "2023-05-02T17:03:52.162Z",
			"last_date": "2023-05-02T17:05:54.730Z",
			"checkout_stage": "checking_out",
			"checkout_stage_history": null,
			"current_page": "/checkout",
			"page_history": null,
			"type": "Active Cart",
			"utm_campaign": "Digital-Matter_Greg-Norman_Prospecting_Evergreen_2022Conversions_Ongoing",
			"utm_source": "facebook",
			"utm_medium": "paid_social",
			"utm_content": "Pique3For$119_Prospecting_For-a-limited_1080X1080.jpg",
			"utm_term": "(none)",
			"lineItems": [
				{
					"name": "Classic Pique Shark Polo"
				},
				{
					"name": "Classic Pique Shark Polo"
				},
				{
					"name": "Classic Pique Shark Polo"
				}
			]
		},
		{
			"identifier": "bigcommerce-e978da9e-544f-47bf-860b-a22063633f0d",
			"order_id": null,
			"domain": "miwallcorp.com",
			"channel": "bigcommerce",
			"session": null,
			"pageviews": 3,
			"user_data": null,
			"promo": null,
			"sales": "379.50",
			"storefront_data": {
				"lineItems": [
					"Federal Premium Ammunition - 45 ACP - 230 Grain HST Jacketed Hollow Point - 50 Rounds - Nickel Plated Brass Case x 10"
				]
			},
			"first_visit": {
				"ep": "https://miwallcorp.com/",
				"fd": "2023-05-02 10:01:23",
				"rf": "(none)",
				"cmp": "(none)",
				"cnt": "(none)",
				"mdm": "(none)",
				"src": "(direct)",
				"trm": "(none)",
				"typ": "typein"
			},
			"latest_visit": {
				"ep": "https://miwallcorp.com/",
				"fd": "2023-05-02 10:01:23",
				"rf": "(none)",
				"cmp": "(none)",
				"cnt": "(none)",
				"mdm": "(none)",
				"src": "(direct)",
				"trm": "(none)",
				"typ": "typein"
			},
			"first_date": "2023-05-02T17:01:24.335Z",
			"last_date": "2023-05-02T17:05:47.871Z",
			"checkout_stage": "checking_out",
			"checkout_stage_history": null,
			"current_page": "/checkout",
			"page_history": null,
			"type": "Active Cart",
			"utm_campaign": "(none)",
			"utm_source": "(direct)",
			"utm_medium": "(none)",
			"utm_content": "(none)",
			"utm_term": "(none)",
			"lineItems": [
				{
					"name": "Federal Premium Ammunition - 45 ACP - 230 Grain HST Jacketed Hollow Point - 50 Rounds - Nickel Plated Brass Case x 10"
				}
			]
		},
		{
			"identifier": "bigcommerce-6b46d82c-59a0-47dd-9aba-12c04d0f193a",
			"order_id": null,
			"domain": "groomerschoice.com",
			"channel": "bigcommerce",
			"session": null,
			"pageviews": 6,
			"user_data": null,
			"promo": null,
			"sales": "227.88",
			"storefront_data": {
				"lineItems": [
					"The Coat Handler Hypoallergenic Dog Shampoo, Gallon x 4",
					"Pet Expressions Plain Colored Ribbon, Turquoise x 2",
					"Pet Expressions Plain Colored Ribbon, Hot Lime",
					"Pet Expressions Plain Colored Ribbon, Hot Raspberry x 2",
					"Pet Expressions Plain Colored Ribbon, Red x 2",
					"Pet Expressions Plain Colored Ribbon, Royal Blue",
					"The Coat Handler Hypoallergenic Dog Shampoo, Gallon"
				]
			},
			"first_visit": {
				"ep": "https://groomerschoice.com/",
				"fd": "2023-05-02 08:22:56",
				"rf": "(none)",
				"cmp": "(none)",
				"cnt": "(none)",
				"mdm": "(none)",
				"src": "(direct)",
				"trm": "(none)",
				"typ": "typein"
			},
			"latest_visit": {
				"ep": "https://groomerschoice.com/",
				"fd": "2023-05-02 08:22:56",
				"rf": "(none)",
				"cmp": "(none)",
				"cnt": "(none)",
				"mdm": "(none)",
				"src": "(direct)",
				"trm": "(none)",
				"typ": "typein"
			},
			"first_date": "2023-05-02T14:26:07.119Z",
			"last_date": "2023-05-02T17:05:45.200Z",
			"checkout_stage": "checking_out",
			"checkout_stage_history": null,
			"current_page": "/checkout",
			"page_history": null,
			"type": "Active Cart",
			"utm_campaign": "(none)",
			"utm_source": "(direct)",
			"utm_medium": "(none)",
			"utm_content": "(none)",
			"utm_term": "(none)",
			"lineItems": [
				{
					"name": "The Coat Handler Hypoallergenic Dog Shampoo, Gallon x 4"
				},
				{
					"name": "Pet Expressions Plain Colored Ribbon, Turquoise x 2"
				},
				{
					"name": "Pet Expressions Plain Colored Ribbon, Hot Lime"
				},
				{
					"name": "Pet Expressions Plain Colored Ribbon, Hot Raspberry x 2"
				},
				{
					"name": "Pet Expressions Plain Colored Ribbon, Red x 2"
				},
				{
					"name": "Pet Expressions Plain Colored Ribbon, Royal Blue"
				},
				{
					"name": "The Coat Handler Hypoallergenic Dog Shampoo, Gallon"
				}
			]
		},
		{
			"identifier": "bigcommerce-8c4417f2-5e12-41a5-900f-dabb375b89ee",
			"order_id": null,
			"domain": "www.perfectsupplements.com",
			"channel": "bigcommerce",
			"session": null,
			"pageviews": 17,
			"user_data": null,
			"promo": null,
			"sales": "120.08",
			"storefront_data": {
				"lineItems": [
					"Perfect Bone Broth - Organic Free-Range Chicken Bone Broth - 330g Powder",
					"Perfect Hydrolyzed Collagen Unflavored - 100% Collagen Sourced From Brazilian Pasture Raised (Grass Fed) Cows",
					"Perfect Acerola - Vitamin C Superfood - 120 Capsules",
					"Perfect Mushroom Immunity Powder - 144g"
				]
			},
			"first_visit": {
				"ep": "https://www.perfectsupplements.com/",
				"fd": "2023-05-02 12:59:49",
				"rf": "(none)",
				"cmp": "(none)",
				"cnt": "(none)",
				"mdm": "(none)",
				"src": "(direct)",
				"trm": "(none)",
				"typ": "typein"
			},
			"latest_visit": {
				"ep": "https://www.perfectsupplements.com/",
				"fd": "2023-05-02 12:59:49",
				"rf": "(none)",
				"cmp": "(none)",
				"cnt": "(none)",
				"mdm": "(none)",
				"src": "(direct)",
				"trm": "(none)",
				"typ": "typein"
			},
			"first_date": "2023-03-23T02:53:41.038Z",
			"last_date": "2023-05-02T17:05:38.944Z",
			"checkout_stage": "checking_out",
			"checkout_stage_history": null,
			"current_page": "/checkout",
			"page_history": null,
			"type": "Active Cart",
			"utm_campaign": "(none)",
			"utm_source": "(direct)",
			"utm_medium": "(none)",
			"utm_content": "(none)",
			"utm_term": "(none)",
			"lineItems": [
				{
					"name": "Perfect Bone Broth - Organic Free-Range Chicken Bone Broth - 330g Powder"
				},
				{
					"name": "Perfect Hydrolyzed Collagen Unflavored - 100% Collagen Sourced From Brazilian Pasture Raised (Grass Fed) Cows"
				},
				{
					"name": "Perfect Acerola - Vitamin C Superfood - 120 Capsules"
				},
				{
					"name": "Perfect Mushroom Immunity Powder - 144g"
				}
			]
		},
		{
			"identifier": "bigcommerce-2227fd1c-ea03-4394-8222-665ca8b9a69e",
			"order_id": null,
			"domain": "g2stobeq.ca",
			"channel": "bigcommerce",
			"session": null,
			"pageviews": 4,
			"user_data": null,
			"promo": null,
			"sales": "95.00",
			"storefront_data": {
				"lineItems": [
					"PG198  -  12-SLOT ULTRA-FLEXIBLE MAGNET WRENCH HOLDER, 12.25\" X 2.4\" X .94\" - RED x 2"
				]
			},
			"first_visit": {
				"cmp": "(none)",
				"cnt": "(none)",
				"mdm": "(none)",
				"src": "(direct)",
				"trm": "(none)",
				"typ": "typein"
			},
			"latest_visit": {
				"cmp": "(none)",
				"cnt": "(none)",
				"mdm": "(none)",
				"src": "(direct)",
				"trm": "(none)",
				"typ": "typein"
			},
			"first_date": "2023-02-04T16:42:03.290Z",
			"last_date": "2023-05-02T17:05:38.770Z",
			"checkout_stage": "checking_out",
			"checkout_stage_history": null,
			"current_page": "/checkout",
			"page_history": null,
			"type": "Active Cart",
			"utm_campaign": "(none)",
			"utm_source": "(direct)",
			"utm_medium": "(none)",
			"utm_content": "(none)",
			"utm_term": "(none)",
			"lineItems": [
				{
					"name": "PG198  -  12-SLOT ULTRA-FLEXIBLE MAGNET WRENCH HOLDER, 12.25\" X 2.4\" X .94\" - RED x 2"
				}
			]
		},
		{
			"identifier": "bigcommerce-f76d26cf-4369-4c9d-9704-675e18d8c986",
			"order_id": null,
			"domain": "compressionhealth.com",
			"channel": "bigcommerce",
			"session": null,
			"pageviews": 12,
			"user_data": null,
			"promo": null,
			"sales": "316.72",
			"storefront_data": {
				"lineItems": [
					"Medi Plus Thigh High W/ Silicone Beaded Band 30-40mmHg",
					"Medi Plus Knee High 30-40mmHg",
					"Medi Plus Thigh High, Open Toe 40-50mmHg",
					"Medi Forte Knee High, Open Toe 40-50mmHg"
				]
			},
			"first_visit": {
				"ep": "https://compressionhealth.com/blog/compression-socks-for-edema/?utm_source=remarkety&utm_medium=email&utm_campaign=Our One-Of-a-Kind Compression Socks Learning Center&utm_content=&_rmId=z6B7YypyQgFE1kgbRVBrFl92yV93WJCkBAZ",
				"fd": "2023-05-01 22:30:16",
				"rf": "(none)",
				"cmp": "Our One-Of-a-Kind Compression Socks Learning Center",
				"cnt": "(none)",
				"mdm": "email",
				"src": "remarkety",
				"trm": "(none)",
				"typ": "utm"
			},
			"latest_visit": {
				"ep": "https://compressionhealth.com/cart.php?utm_source=remarkety&utm_medium=email&utm_campaign=Abandoned cart_1 hour&utm_content=&_rmId=z6B7YypyQgFE1kgbRVBrFl92yV93WJCkBAZ",
				"fd": "2023-05-02 10:56:24",
				"rf": "(none)",
				"cmp": "Abandoned cart_1 hour",
				"cnt": "(none)",
				"mdm": "email",
				"src": "remarkety",
				"trm": "(none)",
				"typ": "utm"
			},
			"first_date": "2023-05-02T15:40:29.219Z",
			"last_date": "2023-05-02T17:05:34.692Z",
			"checkout_stage": "checking_out",
			"checkout_stage_history": null,
			"current_page": "/checkout",
			"page_history": null,
			"type": "Active Cart",
			"utm_campaign": "Our One-Of-a-Kind Compression Socks Learning Center",
			"utm_source": "remarkety",
			"utm_medium": "email",
			"utm_content": "(none)",
			"utm_term": "(none)",
			"lineItems": [
				{
					"name": "Medi Plus Thigh High W/ Silicone Beaded Band 30-40mmHg"
				},
				{
					"name": "Medi Plus Knee High 30-40mmHg"
				},
				{
					"name": "Medi Plus Thigh High, Open Toe 40-50mmHg"
				},
				{
					"name": "Medi Forte Knee High, Open Toe 40-50mmHg"
				}
			]
		},
		{
			"identifier": "bigcommerce-04ffcf45-656a-4259-ae40-2eee2ba944d9",
			"order_id": null,
			"domain": "gregnormancollection.com",
			"channel": "bigcommerce",
			"session": null,
			"pageviews": 26,
			"user_data": null,
			"promo": null,
			"sales": "99.00",
			"storefront_data": {
				"lineItems": [
					"Baseball Collar Micro Pique Shark Polo",
					"Baseball Collar Micro Pique Shark Polo",
					"Baseball Collar Micro Pique Shark Polo"
				]
			},
			"first_visit": {
				"ep": "https://gregnormancollection.com/?utm_source=Klaviyo&utm_medium=campaign&utm_campaign=Timeless SP23 Polos&_kx=X6eqV_sm28JOmTAw24Xh0fQAHfTlvrtR5qXk29q_Tw0=.KMNnF8",
				"fd": "2023-05-02 09:52:52",
				"rf": "(none)",
				"cmp": "Timeless SP23 Polos",
				"cnt": "(none)",
				"mdm": "campaign",
				"src": "Klaviyo",
				"trm": "(none)",
				"typ": "utm"
			},
			"latest_visit": {
				"ep": "https://gregnormancollection.com/?utm_source=Klaviyo&utm_medium=campaign&utm_campaign=Timeless SP23 Polos&_kx=X6eqV_sm28JOmTAw24Xh0fQAHfTlvrtR5qXk29q_Tw0=.KMNnF8",
				"fd": "2023-05-02 09:52:52",
				"rf": "(none)",
				"cmp": "Timeless SP23 Polos",
				"cnt": "(none)",
				"mdm": "campaign",
				"src": "Klaviyo",
				"trm": "(none)",
				"typ": "utm"
			},
			"first_date": "2023-05-02T17:01:23.591Z",
			"last_date": "2023-05-02T17:05:26.955Z",
			"checkout_stage": "checking_out",
			"checkout_stage_history": null,
			"current_page": "/checkout",
			"page_history": null,
			"type": "Active Cart",
			"utm_campaign": "Timeless SP23 Polos",
			"utm_source": "Klaviyo",
			"utm_medium": "campaign",
			"utm_content": "(none)",
			"utm_term": "(none)",
			"lineItems": [
				{
					"name": "Baseball Collar Micro Pique Shark Polo"
				},
				{
					"name": "Baseball Collar Micro Pique Shark Polo"
				},
				{
					"name": "Baseball Collar Micro Pique Shark Polo"
				}
			]
		}
	],
	"orders": [
		{
			"identifier": "bigcommerce-b9977e10-ab49-4fde-ac80-af7e7cf978d2",
			"order_id": "954270",
			"domain": "www.factorydirectjewelry.com",
			"channel": "bigcommerce",
			"session": null,
			"pageviews": 0,
			"user_data": null,
			"promo": null,
			"sales": "369.97",
			"storefront_data": {
				"lineItems": [
					"White Gold Ichthus (Fish) Vertical Pendant Necklace",
					"White Gold Ichthus (Fish) Earrings"
				]
			},
			"first_visit": {
				"ep": "https://www.factorydirectjewelry.com/",
				"fd": "2023-03-27 09:02:55",
				"rf": "https://www.google.com/",
				"cmp": "(none)",
				"cnt": "(none)",
				"mdm": "organic",
				"src": "google",
				"trm": "(none)",
				"typ": "organic"
			},
			"latest_visit": {
				"ep": "https://www.factorydirectjewelry.com/wedding-ring-sets/gold-cz-3-piece-wedding-ring-set/?showHidden=true",
				"fd": "2023-05-01 12:27:46",
				"rf": "https://store-f813d.mybigcommerce.com/",
				"cmp": "(none)",
				"cnt": "/",
				"mdm": "referral",
				"src": "store-f813d.mybigcommerce.com",
				"trm": "(none)",
				"typ": "referral"
			},
			"first_date": "2023-05-01T20:33:32.015Z",
			"last_date": "2023-05-02T17:05:10.000Z",
			"checkout_stage": "order_complete",
			"checkout_stage_history": null,
			"current_page": "/checkout",
			"page_history": null,
			"type": "Order",
			"utm_campaign": "(none)",
			"utm_source": "google",
			"utm_medium": "organic",
			"utm_content": "(none)",
			"utm_term": "(none)",
			"lineItems": [
				{
					"name": "White Gold Ichthus (Fish) Vertical Pendant Necklace"
				},
				{
					"name": "White Gold Ichthus (Fish) Earrings"
				}
			]
		},
		{
			"identifier": "bigcommerce-46ae10a8-1671-4834-832b-4f55c713792f",
			"order_id": "89577",
			"domain": "eknives.com",
			"channel": "bigcommerce",
			"session": null,
			"pageviews": 9,
			"user_data": null,
			"promo": null,
			"sales": "1675.00",
			"storefront_data": {
				"lineItems": [
					"Strider Knives/Blackside Customs Collab SMF G10 Oderintdum Metuant/Titanium DigiCam, Yo-Yo Digicam, Custom Case"
				]
			},
			"first_visit": {
				"ep": "https://eknives.com/",
				"fd": "2023-03-02 06:56:28",
				"rf": "(none)",
				"cmp": "(none)",
				"cnt": "(none)",
				"mdm": "(none)",
				"src": "(direct)",
				"trm": "(none)",
				"typ": "typein"
			},
			"latest_visit": {
				"ep": "https://eknives.com/microtech-socom-bravo-manual-folding-knife-dlc-titanium-carbon-fiber-4-tanto-dlc-261-1dlctcftis/?utm_campaign=New Arrivals_4.26.2023 (01GYZJJVM3DA593YYJX2WEK0VE)&utm_medium=email&utm_source=EK List&_kx=mJV5S602kg9E48cf9N4l62C6PeZkoRaCYndzTvoPxPs=.Q56kxu",
				"fd": "2023-04-26 13:33:29",
				"rf": "(none)",
				"null": ""
			},
			"first_date": "2023-05-02T01:40:11.945Z",
			"last_date": "2023-05-02T17:02:55.410Z",
			"checkout_stage": "order_complete",
			"checkout_stage_history": null,
			"current_page": "/new/",
			"page_history": null,
			"type": "Order",
			"utm_campaign": "(none)",
			"utm_source": "(direct)",
			"utm_medium": "(none)",
			"utm_content": "(none)",
			"utm_term": "(none)",
			"lineItems": [
				{
					"name": "Strider Knives/Blackside Customs Collab SMF G10 Oderintdum Metuant/Titanium DigiCam, Yo-Yo Digicam, Custom Case"
				}
			]
		},
		{
			"identifier": "bigcommerce-24225760-499f-4af6-a111-dba22f798727",
			"order_id": "82837",
			"domain": "ausnaturalcare.com.au",
			"channel": "bigcommerce",
			"session": null,
			"pageviews": 26,
			"user_data": null,
			"promo": null,
			"sales": "92.75",
			"storefront_data": {
				"lineItems": [
					"Marine Collagen Chews 180 tabs",
					"Healthy Greens 120g",
					"Healthy Reds 120g",
					"EasiYo Yogurt Pouches 3 Pack",
					"GIFT - Healthy Reds & Greens Pack",
					"Healthy Reds 120g",
					"Healthy Greens 120g",
					"Marine Collagen Chews 180 tabs"
				]
			},
			"first_visit": {
				"ep": "https://ausnaturalcare.com.au/vitamins/natural-beauty-skin?_kx=BXq-P2QUorcK-8Ryr-6N_xlAYb-kDdcbIm1m557OPn9LTKOx3WuWa3mF4px_csKc.Yhkn3j",
				"fd": "2023-03-08 13:56:22",
				"rf": "(none)",
				"cmp": "(none)",
				"cnt": "(none)",
				"mdm": "(none)",
				"src": "(direct)",
				"trm": "(none)",
				"typ": "typein"
			},
			"latest_visit": {
				"ep": "https://ausnaturalcare.com.au/vitamins/natural-beauty-skin?_kx=BXq-P2QUorcK-8Ryr-6N_xlAYb-kDdcbIm1m557OPn9LTKOx3WuWa3mF4px_csKc.Yhkn3j",
				"fd": "2023-03-08 13:56:22",
				"rf": "(none)",
				"cmp": "(none)",
				"cnt": "(none)",
				"mdm": "(none)",
				"src": "(direct)",
				"trm": "(none)",
				"typ": "typein"
			},
			"first_date": "2023-03-08T03:56:23.770Z",
			"last_date": "2023-03-08T04:34:50.000Z",
			"checkout_stage": "order_complete",
			"checkout_stage_history": null,
			"current_page": "/checkout",
			"page_history": null,
			"type": "Order",
			"utm_campaign": "(none)",
			"utm_source": "(direct)",
			"utm_medium": "(none)",
			"utm_content": "(none)",
			"utm_term": "(none)",
			"lineItems": [
				{
					"name": "Marine Collagen Chews 180 tabs"
				},
				{
					"name": "Healthy Greens 120g"
				},
				{
					"name": "Healthy Reds 120g"
				},
				{
					"name": "EasiYo Yogurt Pouches 3 Pack"
				},
				{
					"name": "GIFT - Healthy Reds & Greens Pack"
				},
				{
					"name": "Healthy Reds 120g"
				},
				{
					"name": "Healthy Greens 120g"
				},
				{
					"name": "Marine Collagen Chews 180 tabs"
				}
			]
		},
		{
			"identifier": "bigcommerce-4c5ec22a-34b5-4832-92ac-f55afef468d2",
			"order_id": "82837",
			"domain": "farmfocused.com",
			"channel": "bigcommerce",
			"session": null,
			"pageviews": 2,
			"user_data": null,
			"promo": null,
			"sales": "189.00",
			"storefront_data": {
				"lineItems": [
					"Calf Optimizer Scour Gel x 7"
				]
			},
			"first_visit": {
				"ep": "https://farmfocused.com/calf-optimizer-scour-gel/",
				"fd": "2023-02-07 12:46:09",
				"rf": "(none)",
				"cmp": "(none)",
				"cnt": "(none)",
				"mdm": "(none)",
				"src": "(direct)",
				"trm": "(none)",
				"typ": "typein"
			},
			"latest_visit": {
				"ep": "https://farmfocused.com/calf-optimizer-scour-gel/",
				"fd": "2023-02-07 12:46:09",
				"rf": "(none)",
				"cmp": "(none)",
				"cnt": "(none)",
				"mdm": "(none)",
				"src": "(direct)",
				"trm": "(none)",
				"typ": "typein"
			},
			"first_date": "2023-02-07T18:46:10.055Z",
			"last_date": "2023-02-13T19:42:37.000Z",
			"checkout_stage": "order_complete",
			"checkout_stage_history": null,
			"current_page": "/checkout",
			"page_history": null,
			"type": "Order",
			"utm_campaign": "(none)",
			"utm_source": "(direct)",
			"utm_medium": "(none)",
			"utm_content": "(none)",
			"utm_term": "(none)",
			"lineItems": [
				{
					"name": "Calf Optimizer Scour Gel x 7"
				}
			]
		},
		{
			"identifier": "bigcommerce-fb8c72e8-86e2-4dbf-a935-f341506e3ab9",
			"order_id": "82837",
			"domain": "eknives.com",
			"channel": "bigcommerce",
			"session": null,
			"pageviews": 4,
			"user_data": null,
			"promo": null,
			"sales": "1075.00",
			"storefront_data": {
				"lineItems": [
					"Microtech Twin Flames Hera 2 Knives Set 3\" Black Double Edge 702-1SETTFS"
				]
			},
			"first_visit": {
				"ep": "https://eknives.com/?utm_medium=store-directory&utm_source=sezzle",
				"fd": "2023-02-13 18:20:22",
				"rf": "(none)",
				"cmp": "(none)",
				"cnt": "(none)",
				"mdm": "store-directory",
				"src": "sezzle",
				"trm": "(none)",
				"typ": "utm"
			},
			"latest_visit": {
				"ep": "https://eknives.com/?utm_medium=store-directory&utm_source=sezzle",
				"fd": "2023-02-13 18:20:22",
				"rf": "(none)",
				"cmp": "(none)",
				"cnt": "(none)",
				"mdm": "store-directory",
				"src": "sezzle",
				"trm": "(none)",
				"typ": "utm"
			},
			"first_date": "2023-01-26T01:51:11.096Z",
			"last_date": "2023-02-13T23:22:02.000Z",
			"checkout_stage": "order_complete",
			"checkout_stage_history": null,
			"current_page": "/checkout",
			"page_history": null,
			"type": "Order",
			"utm_campaign": "(none)",
			"utm_source": "sezzle",
			"utm_medium": "store-directory",
			"utm_content": "(none)",
			"utm_term": "(none)",
			"lineItems": [
				{
					"name": "Microtech Twin Flames Hera 2 Knives Set 3\" Black Double Edge 702-1SETTFS"
				}
			]
		},
		{
			"identifier": "bigcommerce-d75c2121-edb3-45fd-8c86-03fb8fd83935",
			"order_id": "82837",
			"domain": "ironman4x4america.com",
			"channel": "bigcommerce",
			"session": null,
			"pageviews": 5,
			"user_data": null,
			"promo": null,
			"sales": "129.00",
			"storefront_data": {
				"lineItems": [
					"Awning Quick Release Mounting Brackets"
				]
			},
			"first_visit": {
				"ep": "https://ironman4x4america.com/6-5-instant-awning-with-led-lighting-dimmer/?sku=IAWNING2MN&gclid=EAIaIQobChMIhOHskYzX_gIVqAKtBh21YwIVEAQYBiABEgILvPD_BwE",
				"fd": "2023-05-02 09:52:11",
				"rf": "(none)",
				"cmp": "google_cpc",
				"cnt": "(none)",
				"mdm": "cpc",
				"src": "google",
				"trm": "(none)",
				"typ": "utm"
			},
			"latest_visit": {
				"ep": "https://ironman4x4america.com/6-5-instant-awning-with-led-lighting-dimmer/?sku=IAWNING2MN&gclid=EAIaIQobChMIhOHskYzX_gIVqAKtBh21YwIVEAQYBiABEgILvPD_BwE",
				"fd": "2023-05-02 09:57:52",
				"rf": "(none)",
				"cmp": "google_cpc",
				"cnt": "(none)",
				"mdm": "cpc",
				"src": "google",
				"trm": "(none)",
				"typ": "utm"
			},
			"first_date": "2023-05-02T16:52:14.254Z",
			"last_date": "2023-05-02T17:05:47.000Z",
			"checkout_stage": "order_complete",
			"checkout_stage_history": null,
			"current_page": "/6-5-instant-awning-with-led-lighting-dimmer/",
			"page_history": null,
			"type": "Order",
			"utm_campaign": "google_cpc",
			"utm_source": "google",
			"utm_medium": "cpc",
			"utm_content": "(none)",
			"utm_term": "(none)",
			"lineItems": [
				{
					"name": "Awning Quick Release Mounting Brackets"
				}
			]
		},
		{
			"identifier": "bigcommerce-e50f1f40-e2e1-48ee-ac81-c1649c5371b6",
			"order_id": "82836",
			"domain": "ausnaturalcare.com.au",
			"channel": "bigcommerce",
			"session": null,
			"pageviews": 2,
			"user_data": null,
			"promo": null,
			"sales": "19.95",
			"storefront_data": {
				"lineItems": [
					"Marine Collagen Plus Minerals Original Flavour 30 sachets"
				]
			},
			"first_visit": {
				"ep": "https://ausnaturalcare.com.au/?fbclid=IwAR21WTuggUMkVX6n365t-m-NaDqRthErEN3KDAk4ONKpWHWlyyOB9tzriXg_aem_AaOiWho1241YstqHGrc2HnwFx2T-v6xH2asgUJYB9EGmY2geUVbloc0tEZ2wPvA_4q3VVAGR_6_ffhkilUOPUPMyIdTAU34FSJN0cryKE5is8XDPRGFBngL15NtTHm0T056NdRDB11ZTYxQaVcddBLuA",
				"fd": "2023-03-06 20:25:02",
				"rf": "http://m.facebook.com/",
				"cmp": "(none)",
				"cnt": "/",
				"mdm": "referral",
				"src": "m.facebook.com",
				"trm": "(none)",
				"typ": "referral"
			},
			"latest_visit": {
				"ep": "https://ausnaturalcare.com.au/?fbclid=IwAR1ff-S8e8l9rjhbzIK_GGHptlnXI_D8UwBECg1m9-KFMeQ19MdmoVnn0ds_aem_AdxJ9eYP-4b_fl2TTmyLvuyLcjdv5bIvGbddSqMMqQcM8ZfJEa5pxcJWGV3jdE1zJERXvyBYBjhA_gAa29_AfWeplA7ztbteWrLfFM4mkjtQiAazOs-V7WB6K19cajuTrUNg3yEDe0pgbXYzo2kwJXJ2",
				"fd": "2023-03-08 13:02:42",
				"rf": "http://m.facebook.com/",
				"cmp": "(none)",
				"cnt": "/",
				"mdm": "referral",
				"src": "m.facebook.com",
				"trm": "(none)",
				"typ": "referral"
			},
			"first_date": "2023-03-06T10:25:06.588Z",
			"last_date": "2023-03-08T04:17:43.000Z",
			"checkout_stage": "order_complete",
			"checkout_stage_history": null,
			"current_page": "/checkout",
			"page_history": null,
			"type": "Order",
			"utm_campaign": "(none)",
			"utm_source": "m.facebook.com",
			"utm_medium": "referral",
			"utm_content": "/",
			"utm_term": "(none)",
			"lineItems": [
				{
					"name": "Marine Collagen Plus Minerals Original Flavour 30 sachets"
				}
			]
		},
		{
			"identifier": "bigcommerce-ff705b13-43f3-465e-8c5e-db7d06da95cd",
			"order_id": "82836",
			"domain": "eknives.com",
			"channel": "bigcommerce",
			"session": null,
			"pageviews": 5,
			"user_data": null,
			"promo": null,
			"sales": "1075.00",
			"storefront_data": {
				"lineItems": [
					"Microtech Twin Flames Hera 2 Knives Set 3\" Black Double Edge 702-1SETTFS"
				]
			},
			"first_visit": {
				"ep": "https://eknives.com/?utm_campaign=New Arrivals_2.10.2023 (01GRYWJDRFK0PX0RPB5R88M4QN)&utm_medium=email&utm_source=KL Highly Engaged (90 days)&_kx=qWFk_b2BxZFxQga0pGxOJfoiPhieOU2v9Mah1fI7vXw=.Q56kxu",
				"fd": "2023-02-11 16:44:36",
				"rf": "(none)",
				"cmp": "New Arrivals_2.5.2023 (01GRHQ8MD731YF5F60S2ZRHZV4)",
				"cnt": "(none)",
				"mdm": "email",
				"src": "KL Highly Engaged (90 days)",
				"trm": "(none)",
				"typ": "utm"
			},
			"latest_visit": {
				"ep": "https://eknives.com/?utm_campaign=New Arrivals_2.10.2023 (01GRYWJDRFK0PX0RPB5R88M4QN)&utm_medium=email&utm_source=KL Highly Engaged (90 days)&_kx=qWFk_b2BxZFxQga0pGxOJfoiPhieOU2v9Mah1fI7vXw=.Q56kxu",
				"fd": "2023-02-11 16:44:36",
				"rf": "(none)",
				"cmp": "New Arrivals_2.13.2023 (01GS69FWYVE57NJ0XNV2DVWJTP)",
				"cnt": "(none)",
				"mdm": "email",
				"src": "KL Highly Engaged (90 days)",
				"trm": "(none)",
				"typ": "utm"
			},
			"first_date": "2023-02-06T00:45:09.432Z",
			"last_date": "2023-02-13T23:18:06.000Z",
			"checkout_stage": "order_complete",
			"checkout_stage_history": null,
			"current_page": "/checkout",
			"page_history": null,
			"type": "Order",
			"utm_campaign": "New Arrivals_2.5.2023 (01GRHQ8MD731YF5F60S2ZRHZV4)",
			"utm_source": "KL Highly Engaged (90 days)",
			"utm_medium": "email",
			"utm_content": "(none)",
			"utm_term": "(none)",
			"lineItems": [
				{
					"name": "Microtech Twin Flames Hera 2 Knives Set 3\" Black Double Edge 702-1SETTFS"
				}
			]
		},
		{
			"identifier": "bigcommerce-77fc7fc2-7d26-4b5a-991d-d0c086cdcddf",
			"order_id": "82836",
			"domain": "ironman4x4america.com",
			"channel": "bigcommerce",
			"session": null,
			"pageviews": 2,
			"user_data": null,
			"promo": null,
			"sales": "299.00",
			"storefront_data": {
				"lineItems": [
					"Multifunction RGB Switch Panel with Bluetooth Controls"
				]
			},
			"first_visit": {
				"ep": "https://ironman4x4america.com/multifunction-rgb-switch-panel-with-bluetooth-controls/?sku=QP008526&gclid=CjwKCAjwxr2iBhBJEiwAdXECw1En0SafA8JcBVajOlZ0TY2aVZldzqnJvA7g0OfXPY5NUAKUIF0fDhoC8CMQAvD_BwE",
				"fd": "2023-05-01 18:13:26",
				"rf": "https://www.google.com/",
				"cmp": "google_cpc",
				"cnt": "(none)",
				"mdm": "cpc",
				"src": "google",
				"trm": "(none)",
				"typ": "utm"
			},
			"latest_visit": {
				"ep": "https://ironman4x4america.com/multifunction-rgb-switch-panel-with-bluetooth-controls/?sku=QP008526&gclid=CjwKCAjwxr2iBhBJEiwAdXECw1En0SafA8JcBVajOlZ0TY2aVZldzqnJvA7g0OfXPY5NUAKUIF0fDhoC8CMQAvD_BwE",
				"fd": "2023-05-02 12:59:24",
				"rf": "https://www.google.com/",
				"cmp": "google_cpc",
				"cnt": "(none)",
				"mdm": "cpc",
				"src": "google",
				"trm": "(none)",
				"typ": "utm"
			},
			"first_date": "2023-04-25T23:21:35.699Z",
			"last_date": "2023-05-02T17:04:36.000Z",
			"checkout_stage": "order_complete",
			"checkout_stage_history": null,
			"current_page": "/cart.php",
			"page_history": null,
			"type": "Order",
			"utm_campaign": "google_cpc",
			"utm_source": "google",
			"utm_medium": "cpc",
			"utm_content": "(none)",
			"utm_term": "(none)",
			"lineItems": [
				{
					"name": "Multifunction RGB Switch Panel with Bluetooth Controls"
				}
			]
		},
		{
			"identifier": "bigcommerce-c60821e0-8666-4aa4-8504-d371fbb78714",
			"order_id": "79735",
			"domain": "ausnaturalcare.com.au",
			"channel": "bigcommerce",
			"session": null,
			"pageviews": 21,
			"user_data": null,
			"promo": null,
			"sales": "189.65",
			"storefront_data": {
				"lineItems": [
					"Brain and Memory Boost 60 tabs x 2",
					"Healthy Eyes Plus 60 caps x 5"
				]
			},
			"first_visit": {
				"ep": "https://ausnaturalcare.com.au/shop-by-brand?_kx=LQSRtD6vAyr-NKfzLEZFF4yv5T_dimFQi4FavD7hcLQ=.Yhkn3j",
				"fd": "2022-12-27 10:09:49",
				"rf": "(none)",
				"cmp": "(none)",
				"cnt": "(none)",
				"mdm": "(none)",
				"src": "(direct)",
				"trm": "(none)",
				"typ": "typein"
			},
			"latest_visit": {
				"ep": "https://ausnaturalcare.com.au/shop-by-brand?_kx=LQSRtD6vAyr-NKfzLEZFF4yv5T_dimFQi4FavD7hcLQ=.Yhkn3j",
				"fd": "2022-12-27 10:09:49",
				"rf": "(none)",
				"cmp": "(none)",
				"cnt": "(none)",
				"mdm": "(none)",
				"src": "(direct)",
				"trm": "(none)",
				"typ": "typein"
			},
			"first_date": "2022-12-26T23:09:51.820Z",
			"last_date": "2022-12-26T23:29:16.000Z",
			"checkout_stage": "order_complete",
			"checkout_stage_history": null,
			"current_page": "/checkout",
			"page_history": null,
			"type": "Order",
			"utm_campaign": "(none)",
			"utm_source": "(direct)",
			"utm_medium": "(none)",
			"utm_content": "(none)",
			"utm_term": "(none)",
			"lineItems": [
				{
					"name": "Brain and Memory Boost 60 tabs x 2"
				},
				{
					"name": "Healthy Eyes Plus 60 caps x 5"
				}
			]
		},
		{
			"identifier": "bigcommerce-fe84b075-412b-4aaf-825c-52ad39908a39",
			"order_id": "79735",
			"domain": "eknives.com",
			"channel": "bigcommerce",
			"session": null,
			"pageviews": 16,
			"user_data": null,
			"promo": null,
			"sales": "451.00",
			"storefront_data": {
				"lineItems": [
					"Microtech Troodon OTF Automatic D/E 3\" Bronze 138-13"
				]
			},
			"first_visit": {
				"ep": "https://eknives.com/microtech/",
				"fd": "2023-01-07 10:48:32",
				"rf": "https://www.google.com/",
				"cmp": "(none)",
				"cnt": "(none)",
				"mdm": "organic",
				"src": "google",
				"trm": "(none)",
				"typ": "organic"
			},
			"latest_visit": {
				"ep": "https://eknives.com/microtech-ultratech-otf-automatic-knife-black-3-4-bronze-drop-point-121-13/",
				"fd": "2023-01-07 11:21:13",
				"rf": "https://www.google.com/",
				"cmp": "(none)",
				"cnt": "(none)",
				"mdm": "organic",
				"src": "google",
				"trm": "(none)",
				"typ": "organic"
			},
			"first_date": "2023-01-07T16:48:34.001Z",
			"last_date": "2023-01-07T17:24:37.000Z",
			"checkout_stage": "order_complete",
			"checkout_stage_history": null,
			"current_page": "/checkout",
			"page_history": null,
			"type": "Order",
			"utm_campaign": "(none)",
			"utm_source": "google",
			"utm_medium": "organic",
			"utm_content": "(none)",
			"utm_term": "(none)",
			"lineItems": [
				{
					"name": "Microtech Troodon OTF Automatic D/E 3\" Bronze 138-13"
				}
			]
		},
		{
			"identifier": "bigcommerce-e31431b4-1a9e-4532-a5c1-76657efacbdd",
			"order_id": "79735",
			"domain": "timneytriggers.com",
			"channel": "bigcommerce",
			"session": null,
			"pageviews": 8,
			"user_data": null,
			"promo": null,
			"sales": "279.99",
			"storefront_data": {
				"lineItems": [
					"Replacement Trigger for the Ruger 10/22®"
				]
			},
			"first_visit": {
				"ep": "https://timneytriggers.com/replacement-trigger-for-the-ruger-10-22/",
				"fd": "2023-04-01 20:27:23",
				"rf": "https://www.google.com/",
				"cmp": "(none)",
				"cnt": "(none)",
				"mdm": "organic",
				"src": "google",
				"trm": "(none)",
				"typ": "organic"
			},
			"latest_visit": {
				"ep": "https://timneytriggers.com/replacement-trigger-for-the-ruger-10-22/",
				"fd": "2023-04-02 19:18:19",
				"rf": "https://www.google.com/",
				"cmp": "(none)",
				"cnt": "(none)",
				"mdm": "organic",
				"src": "google",
				"trm": "(none)",
				"typ": "organic"
			},
			"first_date": "2023-04-02T00:27:24.385Z",
			"last_date": "2023-04-02T23:27:45.000Z",
			"checkout_stage": "order_complete",
			"checkout_stage_history": null,
			"current_page": "/cart.php",
			"page_history": null,
			"type": "Order",
			"utm_campaign": "(none)",
			"utm_source": "google",
			"utm_medium": "organic",
			"utm_content": "(none)",
			"utm_term": "(none)",
			"lineItems": [
				{
					"name": "Replacement Trigger for the Ruger 10/22®"
				}
			]
		},
		{
			"identifier": "bigcommerce-32c4843a-f4a3-44ef-b6d0-3150c7400f8b",
			"order_id": "79735",
			"domain": "ironman4x4america.com",
			"channel": "bigcommerce",
			"session": null,
			"pageviews": 1,
			"user_data": null,
			"promo": null,
			"sales": "119.00",
			"storefront_data": {
				"lineItems": [
					"50L Roof Rack Water Tank with Barbed Outlet - 13 Gal"
				]
			},
			"first_visit": {
				"ep": "https://ironman4x4america.com/50l-roof-rack-water-tank-with-barbed-outlet-13-gal/?sku=IWT004N&wbraid=Ck4KCQjwiOCgBhCqARI9AFSynSTG_t09Jp8EsHpdnR7KxAAXFNSTJIa6gCe3z1MyRYzMzj10sIkdvGIEwAenyoVZYlPrm57gx4kcMxoCUe0",
				"fd": "2023-03-21 14:46:24",
				"rf": "(none)",
				"cmp": "(none)",
				"cnt": "(none)",
				"mdm": "(none)",
				"src": "(direct)",
				"trm": "(none)",
				"typ": "typein"
			},
			"latest_visit": {
				"ep": "https://ironman4x4america.com/50l-roof-rack-water-tank-with-barbed-outlet-13-gal/?sku=IWT004N&wbraid=Ck4KCQjwiOCgBhCqARI9AFSynSTG_t09Jp8EsHpdnR7KxAAXFNSTJIa6gCe3z1MyRYzMzj10sIkdvGIEwAenyoVZYlPrm57gx4kcMxoCUe0",
				"fd": "2023-03-21 14:46:24",
				"rf": "(none)",
				"cmp": "(none)",
				"cnt": "(none)",
				"mdm": "(none)",
				"src": "(direct)",
				"trm": "(none)",
				"typ": "typein"
			},
			"first_date": "2023-03-21T21:46:25.791Z",
			"last_date": "2023-03-22T00:20:18.000Z",
			"checkout_stage": "order_complete",
			"checkout_stage_history": null,
			"current_page": "/cart.php",
			"page_history": null,
			"type": "Order",
			"utm_campaign": "(none)",
			"utm_source": "(direct)",
			"utm_medium": "(none)",
			"utm_content": "(none)",
			"utm_term": "(none)",
			"lineItems": [
				{
					"name": "50L Roof Rack Water Tank with Barbed Outlet - 13 Gal"
				}
			]
		},
		{
			"identifier": "bigcommerce-81654a03-dfc9-4d4d-9d02-594a3881c1d0",
			"order_id": "79735",
			"domain": "aimpoint.us",
			"channel": "bigcommerce",
			"session": null,
			"pageviews": 11,
			"user_data": null,
			"promo": null,
			"sales": "1013.00",
			"storefront_data": {
				"lineItems": [
					"Micro® T-2™ Red Dot Reflex Sight - AR15 Ready"
				]
			},
			"first_visit": {
				"ep": "https://aimpoint.us/",
				"fd": "2023-05-02 07:05:13",
				"rf": "https://www.google.com/",
				"cmp": "(none)",
				"cnt": "(none)",
				"mdm": "organic",
				"src": "google",
				"trm": "(none)",
				"typ": "organic"
			},
			"latest_visit": {
				"ep": "https://aimpoint.us/",
				"fd": "2023-05-02 07:05:24",
				"rf": "https://www.google.com/",
				"cmp": "(none)",
				"cnt": "(none)",
				"mdm": "organic",
				"src": "google",
				"trm": "(none)",
				"typ": "organic"
			},
			"first_date": "2023-05-02T11:07:26.152Z",
			"last_date": "2023-05-02T17:02:05.000Z",
			"checkout_stage": "order_complete",
			"checkout_stage_history": null,
			"current_page": "/checkout",
			"page_history": null,
			"type": "Order",
			"utm_campaign": "(none)",
			"utm_source": "google",
			"utm_medium": "organic",
			"utm_content": "(none)",
			"utm_term": "(none)",
			"lineItems": [
				{
					"name": "Micro® T-2™ Red Dot Reflex Sight - AR15 Ready"
				}
			]
		},
		{
			"identifier": "bigcommerce-80d4e4dc-4850-49d5-9752-84ad10962b2b",
			"order_id": "690258",
			"domain": "starcitygames.com",
			"channel": "bigcommerce",
			"session": null,
			"pageviews": 23,
			"user_data": null,
			"promo": null,
			"sales": "61.64",
			"storefront_data": {
				"lineItems": [
					"Zabaz, the Glimmerwasp [SGL-MTG-MH22-474-ENF]",
					"Triad of Fates [SGL-MTG-THS-206-ENF]",
					"Neyam Shai Murad [SGL-MTG-40K2-135-ENF]",
					"Deathleaper, Terror Weapon [SGL-MTG-40K2-115-ENF]",
					"Lantern of Insight [SGL-MTG-PWSB-5DN_135-ENF]",
					"Synthesis Pod [SGL-MTG-ONC2-033-ENF]",
					"Encroaching Mycosynth [SGL-MTG-ONE2-380-ENF]",
					"Whirler Rogue [SGL-MTG-ORI-83-ENF]",
					"Bootleggers' Stash [SGL-MTG-SNC2-288-ENF]",
					"Ingenious Artillerist [SGL-MTG-CLB-182-ENF]",
					"Wild-Magic Sorcerer [SGL-MTG-AFC2-305-ENN]",
					"Sarinth Steelseeker [SGL-MTG-BRO-189-ENF]",
					"Kappa Cannoneer [SGL-MTG-NEC2-050-ENN]",
					"Hinder [SGL-MTG-2XM-054-ENF]",
					"Irenicus's Vile Duplication [SGL-MTG-CLB-078-ENF]",
					"Slagwoods Bridge [SGL-MTG-MH2-256-ENF]",
					"Sakashima the Impostor [SGL-MTG-PRM-SECRET_SLD_1232-ENF]"
				]
			},
			"first_visit": {
				"ep": "https://starcitygames.com/",
				"fd": "2023-05-01 07:55:29",
				"rf": "(none)",
				"cmp": "(none)",
				"cnt": "(none)",
				"mdm": "(none)",
				"src": "(direct)",
				"trm": "(none)",
				"typ": "typein"
			},
			"latest_visit": {
				"ep": "https://starcitygames.com/",
				"fd": "2023-05-01 07:55:29",
				"rf": "(none)",
				"cmp": "(none)",
				"cnt": "(none)",
				"mdm": "(none)",
				"src": "(direct)",
				"trm": "(none)",
				"typ": "typein"
			},
			"first_date": "2023-05-01T11:55:29.957Z",
			"last_date": "2023-05-02T17:05:58.000Z",
			"checkout_stage": "order_complete",
			"checkout_stage_history": null,
			"current_page": "/checkout",
			"page_history": null,
			"type": "Order",
			"utm_campaign": "(none)",
			"utm_source": "(direct)",
			"utm_medium": "(none)",
			"utm_content": "(none)",
			"utm_term": "(none)",
			"lineItems": [
				{
					"name": "Zabaz, the Glimmerwasp [SGL-MTG-MH22-474-ENF]"
				},
				{
					"name": "Triad of Fates [SGL-MTG-THS-206-ENF]"
				},
				{
					"name": "Neyam Shai Murad [SGL-MTG-40K2-135-ENF]"
				},
				{
					"name": "Deathleaper, Terror Weapon [SGL-MTG-40K2-115-ENF]"
				},
				{
					"name": "Lantern of Insight [SGL-MTG-PWSB-5DN_135-ENF]"
				},
				{
					"name": "Synthesis Pod [SGL-MTG-ONC2-033-ENF]"
				},
				{
					"name": "Encroaching Mycosynth [SGL-MTG-ONE2-380-ENF]"
				},
				{
					"name": "Whirler Rogue [SGL-MTG-ORI-83-ENF]"
				},
				{
					"name": "Bootleggers' Stash [SGL-MTG-SNC2-288-ENF]"
				},
				{
					"name": "Ingenious Artillerist [SGL-MTG-CLB-182-ENF]"
				},
				{
					"name": "Wild-Magic Sorcerer [SGL-MTG-AFC2-305-ENN]"
				},
				{
					"name": "Sarinth Steelseeker [SGL-MTG-BRO-189-ENF]"
				},
				{
					"name": "Kappa Cannoneer [SGL-MTG-NEC2-050-ENN]"
				},
				{
					"name": "Hinder [SGL-MTG-2XM-054-ENF]"
				},
				{
					"name": "Irenicus's Vile Duplication [SGL-MTG-CLB-078-ENF]"
				},
				{
					"name": "Slagwoods Bridge [SGL-MTG-MH2-256-ENF]"
				},
				{
					"name": "Sakashima the Impostor [SGL-MTG-PRM-SECRET_SLD_1232-ENF]"
				}
			]
		},
		{
			"identifier": "bigcommerce-f921b18d-a62a-42d8-9aca-6486811469dc",
			"order_id": "690255",
			"domain": "starcitygames.com",
			"channel": "bigcommerce",
			"session": null,
			"pageviews": 24,
			"user_data": null,
			"promo": null,
			"sales": "81.72",
			"storefront_data": {
				"lineItems": [
					"Alesha, Who Smiles at Death [SGL-MTG-PRM-SECRET_SLD_1007-ENN] x 2",
					"Clackbridge Troll [SGL-MTG-PRM-PP_ELD_084-ENF] x 2",
					"Colossal Whale [SGL-MTG-PRM-LNCH_M14_048-ENF] x 2",
					"Charix, the Raging Isle [SGL-MTG-PRM-BUN_ZNR_386-ENF]",
					"Crawling Barrens [SGL-MTG-PRM-PP_ZNR_262-ENN] x 2",
					"Demilich [SGL-MTG-PRM-PP_AFR_053-ENN] x 2",
					"Demon of Catastrophes [SGL-MTG-PRM-SCHP_M19_091-ENF] x 2",
					"Drownyard Temple [SGL-MTG-PRM-PRE_SOI_271-ENF]",
					"Guttersnipe [SGL-MTG-PRM-OPNH_M19_145-ENF] x 2",
					"Latch Seeker [SGL-MTG-PRM-GMDY_AVR_063-ENN] x 2",
					"Shelldock Isle [SGL-MTG-PRM-SECRET_SLD_230-ENF] x 4",
					"Squelching Leeches [SGL-MTG-PRM-GMDY_JOU_084-ENN] x 2",
					"Sunken Hollow [SGL-MTG-PRM-ALT_BFZ_249-ENN] x 2",
					"Trophy Mage [SGL-MTG-PRM-GMDY_AER_048-ENN] x 2",
					"Trinket Mage [SGL-MTG-SOM-48-ENN] x 4",
					"Rotting Rats [SGL-MTG-CON-51-ENN] x 4",
					"Krenko, Tin Street Kingpin [SGL-MTG-MOC-287-ENN]",
					"Toggo, Goblin Weaponsmith [SGL-MTG-CMR-204-ENN]",
					"Dauntless Dourbark [SGL-MTG-LRW-203-ENN]",
					"Pashalik Mons [SGL-MTG-DMR-133-ENF]",
					"Sulfur Falls [SGL-MTG-DOM-247-ENN] x 2",
					"Fractured Sanity [SGL-MTG-MH22-336-ENN] x 2",
					"Krydle of Baldur's Gate [SGL-MTG-AFR-226-ENN] x 2",
					"Dragonskull Summit [SGL-MTG-M13-222-ENN] x 2",
					"Nephalia Drownyard [SGL-MTG-ISD-245-ENN] x 2",
					"Castle Vantress [SGL-MTG-ELD-242-ENN] x 3",
					"Drowned Catacomb [SGL-MTG-XLN-253-ENN] x 2",
					"Karplusan Forest [SGL-MTG-DMU2-379-ENN] x 3",
					"Shivan Reef [SGL-MTG-DMU2-380-ENN] x 2",
					"Castle Embereth [SGL-MTG-ONC-150-ENN] x 2",
					"Idyllic Grange [SGL-MTG-ELD-246-ENN] x 2",
					"Mystic Sanctuary [SGL-MTG-ELD-247-ENN]",
					"Gingerbread Cabin [SGL-MTG-C21-290-ENN] x 2",
					"Dwarven Mine [SGL-MTG-ELD-243-ENN] x 2"
				]
			},
			"first_visit": {
				"ep": "https://starcitygames.com/",
				"fd": "2022-12-26 14:04:11",
				"rf": "https://www.google.com/",
				"cmp": "(none)",
				"cnt": "(none)",
				"mdm": "organic",
				"src": "google",
				"trm": "(none)",
				"typ": "organic"
			},
			"latest_visit": {
				"ep": "https://starcitygames.com/",
				"fd": "2022-12-26 14:04:11",
				"rf": "https://www.google.com/",
				"cmp": "(none)",
				"cnt": "(none)",
				"mdm": "organic",
				"src": "google",
				"trm": "(none)",
				"typ": "organic"
			},
			"first_date": "2023-04-19T03:44:31.358Z",
			"last_date": "2023-05-02T17:02:05.000Z",
			"checkout_stage": "order_complete",
			"checkout_stage_history": null,
			"current_page": "/checkout",
			"page_history": null,
			"type": "Order",
			"utm_campaign": "(none)",
			"utm_source": "google",
			"utm_medium": "organic",
			"utm_content": "(none)",
			"utm_term": "(none)",
			"lineItems": [
				{
					"name": "Alesha, Who Smiles at Death [SGL-MTG-PRM-SECRET_SLD_1007-ENN] x 2"
				},
				{
					"name": "Clackbridge Troll [SGL-MTG-PRM-PP_ELD_084-ENF] x 2"
				},
				{
					"name": "Colossal Whale [SGL-MTG-PRM-LNCH_M14_048-ENF] x 2"
				},
				{
					"name": "Charix, the Raging Isle [SGL-MTG-PRM-BUN_ZNR_386-ENF]"
				},
				{
					"name": "Crawling Barrens [SGL-MTG-PRM-PP_ZNR_262-ENN] x 2"
				},
				{
					"name": "Demilich [SGL-MTG-PRM-PP_AFR_053-ENN] x 2"
				},
				{
					"name": "Demon of Catastrophes [SGL-MTG-PRM-SCHP_M19_091-ENF] x 2"
				},
				{
					"name": "Drownyard Temple [SGL-MTG-PRM-PRE_SOI_271-ENF]"
				},
				{
					"name": "Guttersnipe [SGL-MTG-PRM-OPNH_M19_145-ENF] x 2"
				},
				{
					"name": "Latch Seeker [SGL-MTG-PRM-GMDY_AVR_063-ENN] x 2"
				},
				{
					"name": "Shelldock Isle [SGL-MTG-PRM-SECRET_SLD_230-ENF] x 4"
				},
				{
					"name": "Squelching Leeches [SGL-MTG-PRM-GMDY_JOU_084-ENN] x 2"
				},
				{
					"name": "Sunken Hollow [SGL-MTG-PRM-ALT_BFZ_249-ENN] x 2"
				},
				{
					"name": "Trophy Mage [SGL-MTG-PRM-GMDY_AER_048-ENN] x 2"
				},
				{
					"name": "Trinket Mage [SGL-MTG-SOM-48-ENN] x 4"
				},
				{
					"name": "Rotting Rats [SGL-MTG-CON-51-ENN] x 4"
				},
				{
					"name": "Krenko, Tin Street Kingpin [SGL-MTG-MOC-287-ENN]"
				},
				{
					"name": "Toggo, Goblin Weaponsmith [SGL-MTG-CMR-204-ENN]"
				},
				{
					"name": "Dauntless Dourbark [SGL-MTG-LRW-203-ENN]"
				},
				{
					"name": "Pashalik Mons [SGL-MTG-DMR-133-ENF]"
				},
				{
					"name": "Sulfur Falls [SGL-MTG-DOM-247-ENN] x 2"
				},
				{
					"name": "Fractured Sanity [SGL-MTG-MH22-336-ENN] x 2"
				},
				{
					"name": "Krydle of Baldur's Gate [SGL-MTG-AFR-226-ENN] x 2"
				},
				{
					"name": "Dragonskull Summit [SGL-MTG-M13-222-ENN] x 2"
				},
				{
					"name": "Nephalia Drownyard [SGL-MTG-ISD-245-ENN] x 2"
				},
				{
					"name": "Castle Vantress [SGL-MTG-ELD-242-ENN] x 3"
				},
				{
					"name": "Drowned Catacomb [SGL-MTG-XLN-253-ENN] x 2"
				},
				{
					"name": "Karplusan Forest [SGL-MTG-DMU2-379-ENN] x 3"
				},
				{
					"name": "Shivan Reef [SGL-MTG-DMU2-380-ENN] x 2"
				},
				{
					"name": "Castle Embereth [SGL-MTG-ONC-150-ENN] x 2"
				},
				{
					"name": "Idyllic Grange [SGL-MTG-ELD-246-ENN] x 2"
				},
				{
					"name": "Mystic Sanctuary [SGL-MTG-ELD-247-ENN]"
				},
				{
					"name": "Gingerbread Cabin [SGL-MTG-C21-290-ENN] x 2"
				},
				{
					"name": "Dwarven Mine [SGL-MTG-ELD-243-ENN] x 2"
				}
			]
		},
		{
			"identifier": "bigcommerce-a4c3d1c9-81e9-4b05-898c-5de8da89daa4",
			"order_id": "650919",
			"domain": "starcitygames.com",
			"channel": "bigcommerce",
			"session": null,
			"pageviews": 20,
			"user_data": null,
			"promo": null,
			"sales": "18.46",
			"storefront_data": {
				"lineItems": [
					"Harsh Mentor [SGL-MTG-AKH-135-ENN]",
					"Red Elemental Blast [SGL-MTG-2ED-170-ENN]",
					"Banefire [SGL-MTG-DDN-31-ENN]",
					"Citadel of Pain [SGL-MTG-PCY-86-ENN]",
					"Dual Casting [SGL-MTG-AVR-133-ENN]",
					"Inexorable Tide [SGL-MTG-MM2-49-ENN]"
				]
			},
			"first_visit": {
				"cmp": "(none)",
				"cnt": "(none)",
				"mdm": "(none)",
				"src": "(direct)",
				"trm": "(none)",
				"typ": "typein"
			},
			"latest_visit": {
				"cmp": "(none)",
				"cnt": "(none)",
				"mdm": "(none)",
				"src": "(direct)",
				"trm": "(none)",
				"typ": "typein"
			},
			"first_date": "2023-02-11T20:39:18.958Z",
			"last_date": "2023-02-12T03:26:53.000Z",
			"checkout_stage": "order_complete",
			"checkout_stage_history": null,
			"current_page": "/checkout",
			"page_history": null,
			"type": "Order",
			"utm_campaign": "(none)",
			"utm_source": "(direct)",
			"utm_medium": "(none)",
			"utm_content": "(none)",
			"utm_term": "(none)",
			"lineItems": [
				{
					"name": "Harsh Mentor [SGL-MTG-AKH-135-ENN]"
				},
				{
					"name": "Red Elemental Blast [SGL-MTG-2ED-170-ENN]"
				},
				{
					"name": "Banefire [SGL-MTG-DDN-31-ENN]"
				},
				{
					"name": "Citadel of Pain [SGL-MTG-PCY-86-ENN]"
				},
				{
					"name": "Dual Casting [SGL-MTG-AVR-133-ENN]"
				},
				{
					"name": "Inexorable Tide [SGL-MTG-MM2-49-ENN]"
				}
			]
		},
		{
			"identifier": "bigcommerce-b9103e21-08f0-4237-8972-9fe82dbd1d9a",
			"order_id": "650919",
			"domain": "www.prioritytire.com",
			"channel": "bigcommerce",
			"session": null,
			"pageviews": 1,
			"user_data": null,
			"promo": null,
			"sales": "113.78",
			"storefront_data": {
				"lineItems": [
					"Atlas Force UHP 265/35R19 98Y XL AS A/S All Season Tire",
					"State Tire Fee (PTCAEXTAX)"
				]
			},
			"first_visit": {
				"ep": "https://www.prioritytire.com/current-deals/?gclid=Cj0KCQjw6cKiBhD5ARIsAKXUdyZHxhTgvNQCdNWMD8ZLhdagj0nqYqa6X20kSAmyH2RwTamvVko185saAibrEALw_wcB",
				"fd": "2023-05-02 09:49:52",
				"rf": "https://www.google.com/",
				"cmp": "google_cpc",
				"cnt": "(none)",
				"mdm": "cpc",
				"src": "google",
				"trm": "(none)",
				"typ": "utm"
			},
			"latest_visit": {
				"ep": "https://www.prioritytire.com/current-deals/?gclid=Cj0KCQjw6cKiBhD5ARIsAKXUdyZHxhTgvNQCdNWMD8ZLhdagj0nqYqa6X20kSAmyH2RwTamvVko185saAibrEALw_wcB",
				"fd": "2023-05-02 09:49:52",
				"rf": "https://www.google.com/",
				"cmp": "google_cpc",
				"cnt": "(none)",
				"mdm": "cpc",
				"src": "google",
				"trm": "(none)",
				"typ": "utm"
			},
			"first_date": "2023-05-02T16:49:56.503Z",
			"last_date": "2023-05-02T17:04:08.000Z",
			"checkout_stage": "order_complete",
			"checkout_stage_history": null,
			"current_page": "/checkout",
			"page_history": null,
			"type": "Order",
			"utm_campaign": "google_cpc",
			"utm_source": "google",
			"utm_medium": "cpc",
			"utm_content": "(none)",
			"utm_term": "(none)",
			"lineItems": [
				{
					"name": "Atlas Force UHP 265/35R19 98Y XL AS A/S All Season Tire"
				},
				{
					"name": "State Tire Fee (PTCAEXTAX)"
				}
			]
		},
		{
			"identifier": "bigcommerce-873987a6-edd3-4b0d-a8f2-5c6b890fa85d",
			"order_id": "650918",
			"domain": "www.prioritytire.com",
			"channel": "bigcommerce",
			"session": null,
			"pageviews": 0,
			"user_data": null,
			"promo": null,
			"sales": "371.72",
			"storefront_data": {
				"lineItems": [
					"Forceum Hexa-R 245/40R18 ZR 97Y XL AS A/S All Season Tire x 4"
				]
			},
			"first_visit": {
				"ep": "https://www.prioritytire.com/forceum-hexa-r-245-40r18-zr-97y-xl-as-a-s-all-season-tire?gclid=CjwKCAjwxr2iBhBJEiwAdXECwzo8vDTNTuoSx8AxlOYk_T9SaXA-o6tCYGIK2meqERD_EHI2M0LkchoC8aAQAvD_BwE",
				"fd": "2023-05-01 13:11:49",
				"rf": "https://www.google.com/",
				"cmp": "google_cpc",
				"cnt": "(none)",
				"mdm": "cpc",
				"src": "google",
				"trm": "(none)",
				"typ": "utm"
			},
			"latest_visit": {
				"ep": "https://www.prioritytire.com/forceum-hexa-r-245-40r18-zr-97y-xl-as-a-s-all-season-tire?gclid=CjwKCAjwxr2iBhBJEiwAdXECwzo8vDTNTuoSx8AxlOYk_T9SaXA-o6tCYGIK2meqERD_EHI2M0LkchoC8aAQAvD_BwE",
				"fd": "2023-05-01 13:11:49",
				"rf": "https://www.google.com/",
				"cmp": "google_cpc",
				"cnt": "(none)",
				"mdm": "cpc",
				"src": "google",
				"trm": "(none)",
				"typ": "utm"
			},
			"first_date": "2023-05-02T16:58:28.441Z",
			"last_date": "2023-05-02T17:02:33.000Z",
			"checkout_stage": "order_complete",
			"checkout_stage_history": null,
			"current_page": "/checkout",
			"page_history": null,
			"type": "Order",
			"utm_campaign": "google_cpc",
			"utm_source": "google",
			"utm_medium": "cpc",
			"utm_content": "(none)",
			"utm_term": "(none)",
			"lineItems": [
				{
					"name": "Forceum Hexa-R 245/40R18 ZR 97Y XL AS A/S All Season Tire x 4"
				}
			]
		},
		{
			"identifier": "bigcommerce-c31ef600-4a26-4c13-8099-83e8456c3949",
			"order_id": "650918",
			"domain": "starcitygames.com",
			"channel": "bigcommerce",
			"session": null,
			"pageviews": 6,
			"user_data": null,
			"promo": null,
			"sales": "77.93",
			"storefront_data": {
				"lineItems": [
					"Banisher Priest [SGL-MTG-M14-007-JAF]",
					"Dissolve [SGL-MTG-THS-047-KOF]",
					"Destructive Revelry [SGL-MTG-THS-192-JAF]",
					"Destructive Revelry [SGL-MTG-THS-192-KOF]",
					"Geralf's Messenger [SGL-MTG-DKA-63-ENF] x 3"
				]
			},
			"first_visit": {
				"ep": "https://starcitygames.com/",
				"fd": "2023-02-08 20:38:27",
				"rf": "https://starcitygames.com/redirect/?q=https://starcitygames.com",
				"cmp": "(none)",
				"cnt": "(none)",
				"mdm": "(none)",
				"src": "(direct)",
				"trm": "(none)",
				"typ": "typein"
			},
			"latest_visit": {
				"ep": "https://starcitygames.com/",
				"fd": "2023-02-11 20:46:29",
				"rf": "https://www.google.com/",
				"cmp": "(none)",
				"cnt": "(none)",
				"mdm": "organic",
				"src": "google",
				"trm": "(none)",
				"typ": "organic"
			},
			"first_date": "2023-02-09T04:00:05.934Z",
			"last_date": "2023-02-12T03:26:09.000Z",
			"checkout_stage": "order_complete",
			"checkout_stage_history": null,
			"current_page": "/checkout",
			"page_history": null,
			"type": "Order",
			"utm_campaign": "(none)",
			"utm_source": "(direct)",
			"utm_medium": "(none)",
			"utm_content": "(none)",
			"utm_term": "(none)",
			"lineItems": [
				{
					"name": "Banisher Priest [SGL-MTG-M14-007-JAF]"
				},
				{
					"name": "Dissolve [SGL-MTG-THS-047-KOF]"
				},
				{
					"name": "Destructive Revelry [SGL-MTG-THS-192-JAF]"
				},
				{
					"name": "Destructive Revelry [SGL-MTG-THS-192-KOF]"
				},
				{
					"name": "Geralf's Messenger [SGL-MTG-DKA-63-ENF] x 3"
				}
			]
		},
		{
			"identifier": "bigcommerce-09000143-4b9c-406c-930a-34b9dffe2a70",
			"order_id": "6105091",
			"domain": "www.multipure.com",
			"channel": "bigcommerce",
			"session": null,
			"pageviews": 14,
			"user_data": null,
			"promo": null,
			"sales": "490.00",
			"storefront_data": {
				"lineItems": [
					"Aquaversa"
				]
			},
			"first_visit": {
				"ep": "https://www.multipure.com/aquaversa/",
				"fd": "2023-05-02 08:47:35",
				"rf": "https://duckduckgo.com/",
				"cmp": "(none)",
				"cnt": "/",
				"mdm": "referral",
				"src": "duckduckgo.com",
				"trm": "(none)",
				"typ": "referral"
			},
			"latest_visit": {
				"ep": "https://www.multipure.com/aquaversa/",
				"fd": "2023-05-02 08:47:35",
				"rf": "https://duckduckgo.com/",
				"cmp": "(none)",
				"cnt": "/",
				"mdm": "referral",
				"src": "duckduckgo.com",
				"trm": "(none)",
				"typ": "referral"
			},
			"first_date": "2023-02-26T01:32:45.424Z",
			"last_date": "2023-05-02T17:04:16.000Z",
			"checkout_stage": "order_complete",
			"checkout_stage_history": null,
			"current_page": "/checkout",
			"page_history": null,
			"type": "Order",
			"utm_campaign": "(none)",
			"utm_source": "duckduckgo.com",
			"utm_medium": "referral",
			"utm_content": "/",
			"utm_term": "(none)",
			"lineItems": [
				{
					"name": "Aquaversa"
				}
			]
		},
		{
			"identifier": "bigcommerce-2732ff32-c120-46b4-9906-4ce95827bd44",
			"order_id": "6105090",
			"domain": "www.multipure.com",
			"channel": "bigcommerce",
			"session": null,
			"pageviews": 15,
			"user_data": null,
			"promo": null,
			"sales": "90.00",
			"storefront_data": {
				"lineItems": [
					"Aquaversa Filter (CB6)"
				]
			},
			"first_visit": {
				"ep": "https://www.multipure.com/",
				"fd": "2023-03-27 12:13:30",
				"rf": "(none)",
				"cmp": "(none)",
				"cnt": "(none)",
				"mdm": "(none)",
				"src": "(direct)",
				"trm": "(none)",
				"typ": "typein"
			},
			"latest_visit": {
				"ep": "https://www.multipure.com/account.php?action=order_status",
				"fd": "2023-04-28 08:38:55",
				"rf": "https://store-2t5fkq8mli.mybigcommerce.com/",
				"cmp": "(none)",
				"cnt": "/",
				"mdm": "referral",
				"src": "store-2t5fkq8mli.mybigcommerce.com",
				"trm": "(none)",
				"typ": "referral"
			},
			"first_date": "2023-05-02T16:58:41.394Z",
			"last_date": "2023-05-02T17:02:21.000Z",
			"checkout_stage": "order_complete",
			"checkout_stage_history": null,
			"current_page": "/checkout",
			"page_history": null,
			"type": "Order",
			"utm_campaign": "(none)",
			"utm_source": "(direct)",
			"utm_medium": "(none)",
			"utm_content": "(none)",
			"utm_term": "(none)",
			"lineItems": [
				{
					"name": "Aquaversa Filter (CB6)"
				}
			]
		},
		{
			"identifier": "shopify-47b5e93bc17d23393e3b4a0d22592cbf",
			"order_id": "5581183385941",
			"domain": "ayu.ie",
			"channel": "shopify",
			"session": null,
			"pageviews": 0,
			"user_data": null,
			"promo": null,
			"sales": "4000.00",
			"storefront_data": {
				"lineItems": [
					"Illuminating Face Cream x 2"
				]
			},
			"first_visit": {
				"ep": "https://ayu.ie/products/new-face-mist?_kx=dmHU6IQ8FV7uLnEdyFogrmvvm73S7PR0zarCmob4W1o=.YzKVu5",
				"fd": "2023-03-16 13:59:01",
				"rf": "(none)",
				"cmp": "(none)",
				"cnt": "(none)",
				"mdm": "(none)",
				"src": "(direct)",
				"trm": "(none)",
				"typ": "typein"
			},
			"latest_visit": {
				"ep": "https://ayu.ie/products/new-face-mist?_kx=dmHU6IQ8FV7uLnEdyFogrmvvm73S7PR0zarCmob4W1o=.YzKVu5",
				"fd": "2023-03-16 13:59:01",
				"rf": "(none)",
				"cmp": "(none)",
				"cnt": "(none)",
				"mdm": "(none)",
				"src": "(direct)",
				"trm": "(none)",
				"typ": "typein"
			},
			"first_date": "2023-04-22T19:25:37.903Z",
			"last_date": "2023-05-02T17:04:22.000Z",
			"checkout_stage": "order_complete",
			"checkout_stage_history": null,
			"current_page": "/collections/cosmetics",
			"page_history": null,
			"type": "Order",
			"utm_campaign": "(none)",
			"utm_source": "(direct)",
			"utm_medium": "(none)",
			"utm_content": "(none)",
			"utm_term": "(none)",
			"lineItems": [
				{
					"name": "Illuminating Face Cream x 2"
				}
			]
		},
		{
			"identifier": "shopify-cb06899243b1a9f46160d20ae69be290",
			"order_id": "5556698153297",
			"domain": "myalma.es",
			"channel": "shopify",
			"session": null,
			"pageviews": 3,
			"user_data": null,
			"promo": null,
			"sales": "998.00",
			"storefront_data": {
				"lineItems": [
					"Tampones con aplicador x 2"
				]
			},
			"first_visit": {
				"ep": "https://myalma.es/",
				"fd": "2023-05-02 17:01:00",
				"rf": "https://www.google.es/",
				"cmp": "(none)",
				"cnt": "(none)",
				"mdm": "organic",
				"src": "google",
				"trm": "(none)",
				"typ": "organic"
			},
			"latest_visit": {
				"ep": "https://myalma.es/",
				"fd": "2023-05-02 17:01:00",
				"rf": "https://www.google.es/",
				"cmp": "(none)",
				"cnt": "(none)",
				"mdm": "organic",
				"src": "google",
				"trm": "(none)",
				"typ": "organic"
			},
			"first_date": "2023-05-02T15:01:02.202Z",
			"last_date": "2023-05-02T17:04:53.000Z",
			"checkout_stage": "order_complete",
			"checkout_stage_history": null,
			"current_page": "/collections/all",
			"page_history": null,
			"type": "Order",
			"utm_campaign": "(none)",
			"utm_source": "google",
			"utm_medium": "organic",
			"utm_content": "(none)",
			"utm_term": "(none)",
			"lineItems": [
				{
					"name": "Tampones con aplicador x 2"
				}
			]
		},
		{
			"identifier": "bigcommerce-6f7aa572-098e-41ba-9ea4-d7853f6ac98f",
			"order_id": "543242",
			"domain": "www.perfectsupplements.com",
			"channel": "bigcommerce",
			"session": null,
			"pageviews": 3,
			"user_data": null,
			"promo": null,
			"sales": "59.88",
			"storefront_data": {
				"lineItems": [
					"Perfect Desiccated Liver - Nutrient Dense Source of Naturally Occurring High Quality Protein & Iron - 120 Capsule x 3"
				]
			},
			"first_visit": {
				"ep": "https://www.perfectsupplements.com/",
				"fd": "2023-05-01 13:43:46",
				"rf": "(none)",
				"cmp": "(none)",
				"cnt": "(none)",
				"mdm": "(none)",
				"src": "(direct)",
				"trm": "(none)",
				"typ": "typein"
			},
			"latest_visit": {
				"ep": "https://www.perfectsupplements.com/",
				"fd": "2023-05-01 13:43:46",
				"rf": "(none)",
				"cmp": "(none)",
				"cnt": "(none)",
				"mdm": "(none)",
				"src": "(direct)",
				"trm": "(none)",
				"typ": "typein"
			},
			"first_date": "2023-05-01T20:43:47.479Z",
			"last_date": "2023-05-02T17:04:35.000Z",
			"checkout_stage": "order_complete",
			"checkout_stage_history": null,
			"current_page": "/checkout",
			"page_history": null,
			"type": "Order",
			"utm_campaign": "(none)",
			"utm_source": "(direct)",
			"utm_medium": "(none)",
			"utm_content": "(none)",
			"utm_term": "(none)",
			"lineItems": [
				{
					"name": "Perfect Desiccated Liver - Nutrient Dense Source of Naturally Occurring High Quality Protein & Iron - 120 Capsule x 3"
				}
			]
		},
		{
			"identifier": "bigcommerce-484eafca-bf1a-4aa7-b265-d87539ac32a6",
			"order_id": "543242",
			"domain": "groomerschoice.com",
			"channel": "bigcommerce",
			"session": null,
			"pageviews": 16,
			"user_data": null,
			"promo": null,
			"sales": "110.08",
			"storefront_data": {
				"lineItems": [
					"EZ Care Grooming Jacket, Navy Blue x 2",
					"Artero Natalie Smock, Navy and Fuchsia"
				]
			},
			"first_visit": {
				"ep": "https://groomerschoice.com/",
				"fd": "2022-09-25 12:09:14",
				"rf": "(none)",
				"cmp": "(none)",
				"cnt": "(none)",
				"mdm": "(none)",
				"src": "(direct)",
				"trm": "(none)",
				"typ": "typein"
			},
			"latest_visit": {
				"ep": "https://groomerschoice.com/",
				"fd": "2022-12-06 10:40:06",
				"rf": "https://www.google.com/",
				"cmp": "(none)",
				"cnt": "(none)",
				"mdm": "organic",
				"src": "google",
				"trm": "(none)",
				"typ": "organic"
			},
			"first_date": "2023-01-04T16:03:10.992Z",
			"last_date": "2023-01-04T16:23:11.000Z",
			"checkout_stage": "order_complete",
			"checkout_stage_history": null,
			"current_page": "/checkout",
			"page_history": null,
			"type": "Order",
			"utm_campaign": "(none)",
			"utm_source": "(direct)",
			"utm_medium": "(none)",
			"utm_content": "(none)",
			"utm_term": "(none)",
			"lineItems": [
				{
					"name": "EZ Care Grooming Jacket, Navy Blue x 2"
				},
				{
					"name": "Artero Natalie Smock, Navy and Fuchsia"
				}
			]
		},
		{
			"identifier": "bigcommerce-b84efaf8-98bb-4d24-8a44-fb0b5c9f7465",
			"order_id": "543241",
			"domain": "groomerschoice.com",
			"channel": "bigcommerce",
			"session": null,
			"pageviews": 3,
			"user_data": null,
			"promo": null,
			"sales": "119.96",
			"storefront_data": {
				"lineItems": [
					"Fetch Dog Bandana, 22 inch, 12 Pack",
					"Retro Love Dog Bandana, 22 inch, 12 Pack",
					"Bones and Paws Dog Bandana, 22 inch, 12 Pack",
					"Heartfelt Dog Bandana, 22 inch, 12 Pack"
				]
			},
			"first_visit": {
				"ep": "https://groomerschoice.com/",
				"fd": "2023-01-03 09:20:36",
				"rf": "(none)",
				"cmp": "(none)",
				"cnt": "(none)",
				"mdm": "(none)",
				"src": "(direct)",
				"trm": "(none)",
				"typ": "typein"
			},
			"latest_visit": {
				"ep": "https://groomerschoice.com/",
				"fd": "2023-01-03 09:20:36",
				"rf": "(none)",
				"cmp": "(none)",
				"cnt": "(none)",
				"mdm": "(none)",
				"src": "(direct)",
				"trm": "(none)",
				"typ": "typein"
			},
			"first_date": "2023-01-04T15:07:38.274Z",
			"last_date": "2023-01-04T16:21:53.000Z",
			"checkout_stage": "order_complete",
			"checkout_stage_history": null,
			"current_page": "/checkout",
			"page_history": null,
			"type": "Order",
			"utm_campaign": "(none)",
			"utm_source": "(direct)",
			"utm_medium": "(none)",
			"utm_content": "(none)",
			"utm_term": "(none)",
			"lineItems": [
				{
					"name": "Fetch Dog Bandana, 22 inch, 12 Pack"
				},
				{
					"name": "Retro Love Dog Bandana, 22 inch, 12 Pack"
				},
				{
					"name": "Bones and Paws Dog Bandana, 22 inch, 12 Pack"
				},
				{
					"name": "Heartfelt Dog Bandana, 22 inch, 12 Pack"
				}
			]
		},
		{
			"identifier": "bigcommerce-b801571c-56c5-4967-b0c3-f1be5a5a5ad1",
			"order_id": "543241",
			"domain": "www.perfectsupplements.com",
			"channel": "bigcommerce",
			"session": null,
			"pageviews": 7,
			"user_data": null,
			"promo": null,
			"sales": "66.51",
			"storefront_data": {
				"lineItems": [
					"Perfect Bovine Gelatin - Collagen Protein Sourced Exclusively From Brazilian Pasture Raised (Grass Fed) Cows x 2"
				]
			},
			"first_visit": {
				"ep": "https://www.perfectsupplements.com/",
				"fd": "2023-05-02 11:59:35",
				"rf": "https://www.google.com/",
				"cmp": "(none)",
				"cnt": "(none)",
				"mdm": "organic",
				"src": "google",
				"trm": "(none)",
				"typ": "organic"
			},
			"latest_visit": {
				"ep": "https://www.perfectsupplements.com/",
				"fd": "2023-05-02 11:59:35",
				"rf": "https://www.google.com/",
				"cmp": "(none)",
				"cnt": "(none)",
				"mdm": "organic",
				"src": "google",
				"trm": "(none)",
				"typ": "organic"
			},
			"first_date": "2023-05-02T16:59:35.869Z",
			"last_date": "2023-05-02T17:03:05.000Z",
			"checkout_stage": "order_complete",
			"checkout_stage_history": null,
			"current_page": "/checkout",
			"page_history": null,
			"type": "Order",
			"utm_campaign": "(none)",
			"utm_source": "google",
			"utm_medium": "organic",
			"utm_content": "(none)",
			"utm_term": "(none)",
			"lineItems": [
				{
					"name": "Perfect Bovine Gelatin - Collagen Protein Sourced Exclusively From Brazilian Pasture Raised (Grass Fed) Cows x 2"
				}
			]
		},
		{
			"identifier": "bigcommerce-0499a2fc-a96d-48fd-bd0e-f183941b128a",
			"order_id": "536419",
			"domain": "store.almanac.com",
			"channel": "bigcommerce",
			"session": null,
			"pageviews": 5,
			"user_data": null,
			"promo": null,
			"sales": "8.47",
			"storefront_data": {
				"lineItems": [
					"The 2023 Old Farmer's Almanac"
				]
			},
			"first_visit": {
				"ep": "https://www.almanac.com/",
				"fd": "2023-05-02 12:53:30",
				"rf": "(none)",
				"cmp": "(none)",
				"cnt": "(none)",
				"mdm": "(none)",
				"src": "(direct)",
				"trm": "(none)",
				"typ": "typein"
			},
			"latest_visit": {
				"ep": "https://www.almanac.com/",
				"fd": "2023-05-02 12:53:30",
				"rf": "(none)",
				"cmp": "(none)",
				"cnt": "(none)",
				"mdm": "(none)",
				"src": "(direct)",
				"trm": "(none)",
				"typ": "typein"
			},
			"first_date": "2023-05-02T16:59:05.465Z",
			"last_date": "2023-05-02T17:05:30.000Z",
			"checkout_stage": "order_complete",
			"checkout_stage_history": null,
			"current_page": "/checkout",
			"page_history": null,
			"type": "Order",
			"utm_campaign": "(none)",
			"utm_source": "(direct)",
			"utm_medium": "(none)",
			"utm_content": "(none)",
			"utm_term": "(none)",
			"lineItems": [
				{
					"name": "The 2023 Old Farmer's Almanac"
				}
			]
		},
		{
			"identifier": "bigcommerce-8af98cb7-a7f5-4881-a7dd-09349777114c",
			"order_id": "536419",
			"domain": "www.perfectsupplements.com",
			"channel": "bigcommerce",
			"session": null,
			"pageviews": 6,
			"user_data": null,
			"promo": null,
			"sales": "127.84",
			"storefront_data": {
				"lineItems": [
					"Perfect Hydrolyzed Collagen Chocolate - Sourced From Brazilian Pasture Raised (Grass Fed) Cows x 4"
				]
			},
			"first_visit": {
				"cmp": "judgeme-review-request",
				"cnt": "(none)",
				"mdm": "email",
				"src": "judgeme",
				"trm": "(none)",
				"typ": "utm"
			},
			"latest_visit": {
				"cmp": "judgeme-review-request",
				"cnt": "(none)",
				"mdm": "email",
				"src": "judgeme",
				"trm": "(none)",
				"typ": "utm"
			},
			"first_date": "2023-02-01T12:36:02.095Z",
			"last_date": "2023-04-18T14:53:48.000Z",
			"checkout_stage": "order_complete",
			"checkout_stage_history": null,
			"current_page": "/checkout",
			"page_history": null,
			"type": "Order",
			"utm_campaign": "judgeme-review-request",
			"utm_source": "judgeme",
			"utm_medium": "email",
			"utm_content": "(none)",
			"utm_term": "(none)",
			"lineItems": [
				{
					"name": "Perfect Hydrolyzed Collagen Chocolate - Sourced From Brazilian Pasture Raised (Grass Fed) Cows x 4"
				}
			]
		},
		{
			"identifier": "shopify-f502f49a08ea21fd8fb231b61ff2e098",
			"order_id": "5261050085608",
			"domain": "www.arcticus.co.uk",
			"channel": "shopify",
			"session": null,
			"pageviews": 2,
			"user_data": null,
			"promo": null,
			"sales": "3800.00",
			"storefront_data": {
				"lineItems": [
					"Arctic Bottle"
				]
			},
			"first_visit": {
				"ep": "https://www.arcticus.co.uk/collections/sweatcoin-drinkware",
				"fd": "2023-05-01 23:48:33",
				"rf": "(none)",
				"cmp": "(none)",
				"cnt": "(none)",
				"mdm": "(none)",
				"src": "(direct)",
				"trm": "(none)",
				"typ": "typein"
			},
			"latest_visit": {
				"ep": "https://www.arcticus.co.uk/collections/sweatcoin-drinkware",
				"fd": "2023-05-01 23:48:33",
				"rf": "(none)",
				"cmp": "(none)",
				"cnt": "(none)",
				"mdm": "(none)",
				"src": "(direct)",
				"trm": "(none)",
				"typ": "typein"
			},
			"first_date": "2023-05-02T03:48:33.606Z",
			"last_date": "2023-05-02T17:05:52.000Z",
			"checkout_stage": "order_complete",
			"checkout_stage_history": null,
			"current_page": "/cart",
			"page_history": null,
			"type": "Order",
			"utm_campaign": "(none)",
			"utm_source": "(direct)",
			"utm_medium": "(none)",
			"utm_content": "(none)",
			"utm_term": "(none)",
			"lineItems": [
				{
					"name": "Arctic Bottle"
				}
			]
		},
		{
			"identifier": "bigcommerce-05e57ae8-a4c9-44ba-891b-46ab17f1efa4",
			"order_id": "394693",
			"domain": "covenanthealthproducts.com",
			"channel": "bigcommerce",
			"session": null,
			"pageviews": 1,
			"user_data": null,
			"promo": null,
			"sales": "127.48",
			"storefront_data": {
				"lineItems": [
					"PS 150 Phosphatidylserine 60c x 2"
				]
			},
			"first_visit": {
				"ep": "https://covenanthealthproducts.com/?utm_source=Newsletter&utm_medium=email&utm_campaign=December Coupon Code: 12-5-2022 (01GKJ18J041S61H6FTZF5C61T3)&_kx=vKanmyi-mylyYmo_GL0JT0tXuw9HRkFDOcBNOsJlWCA=.XvzmEy",
				"fd": "2023-01-01 16:13:56",
				"rf": "(none)",
				"cmp": "December Coupon Code: 12-5-2022 (01GKJ18J041S61H6FTZF5C61T3)",
				"cnt": "(none)",
				"mdm": "email",
				"src": "Newsletter",
				"trm": "(none)",
				"typ": "utm"
			},
			"latest_visit": {
				"ep": "https://covenanthealthproducts.com/?utm_source=Potential Purchasers&utm_medium=email&utm_campaign=March Coupon Code: 3.31.23B (01GWW6X78XPVQMXWKDW91BV906)&_kx=vKanmyi-mylyYmo_GL0JT0tXuw9HRkFDOcBNOsJlWCA=.XvzmEy",
				"fd": "2023-04-01 10:45:28",
				"rf": "(none)",
				"cmp": "March Coupon Code: 3.31.23B (01GWW6X78XPVQMXWKDW91BV906)",
				"cnt": "(none)",
				"mdm": "email",
				"src": "Potential Purchasers",
				"trm": "(none)",
				"typ": "utm"
			},
			"first_date": "2023-05-01T22:19:03.039Z",
			"last_date": "2023-05-02T17:04:21.000Z",
			"checkout_stage": "order_complete",
			"checkout_stage_history": null,
			"current_page": "/checkout",
			"page_history": null,
			"type": "Order",
			"utm_campaign": "December Coupon Code: 12-5-2022 (01GKJ18J041S61H6FTZF5C61T3)",
			"utm_source": "Newsletter",
			"utm_medium": "email",
			"utm_content": "(none)",
			"utm_term": "(none)",
			"lineItems": [
				{
					"name": "PS 150 Phosphatidylserine 60c x 2"
				}
			]
		},
		{
			"identifier": "bigcommerce-fa62e44f-c08a-40fc-b137-42b721edcc55",
			"order_id": "36453",
			"domain": "compressionhealth.com",
			"channel": "bigcommerce",
			"session": null,
			"pageviews": 5,
			"user_data": null,
			"promo": null,
			"sales": "40.00",
			"storefront_data": {
				"lineItems": [
					"Medi Protect.Achi Achilles Support"
				]
			},
			"first_visit": {
				"ep": "https://compressionhealth.com/medi-protect-achi-achilles-support/?sku=K610803&gclid=EAIaIQobChMI0OjenYnX_gIVs4NbCh2GrwX3EAQYByABEgK2FfD_BwE",
				"fd": "2023-05-02 11:44:30",
				"rf": "(none)",
				"cmp": "google_cpc",
				"cnt": "(none)",
				"mdm": "cpc",
				"src": "google",
				"trm": "(none)",
				"typ": "utm"
			},
			"latest_visit": {
				"ep": "https://compressionhealth.com/medi-protect-achi-achilles-support/?sku=K610803&gclid=EAIaIQobChMI0OjenYnX_gIVs4NbCh2GrwX3EAQYByABEgK2FfD_BwE#size-chart",
				"fd": "2023-05-02 11:58:10",
				"rf": "(none)",
				"cmp": "google_cpc",
				"cnt": "(none)",
				"mdm": "cpc",
				"src": "google",
				"trm": "(none)",
				"typ": "utm"
			},
			"first_date": "2023-05-02T16:44:30.859Z",
			"last_date": "2023-05-02T17:02:57.000Z",
			"checkout_stage": "order_complete",
			"checkout_stage_history": null,
			"current_page": "/checkout",
			"page_history": null,
			"type": "Order",
			"utm_campaign": "google_cpc",
			"utm_source": "google",
			"utm_medium": "cpc",
			"utm_content": "(none)",
			"utm_term": "(none)",
			"lineItems": [
				{
					"name": "Medi Protect.Achi Achilles Support"
				}
			]
		},
		{
			"identifier": "bigcommerce-14691948-c9fe-4531-bd4a-98ad78097618",
			"order_id": "33593",
			"domain": "vinylshake.com",
			"channel": "bigcommerce",
			"session": null,
			"pageviews": 12,
			"user_data": null,
			"promo": null,
			"sales": "580.00",
			"storefront_data": {
				"lineItems": [
					"Fullback Foam Panel x 4"
				]
			},
			"first_visit": {
				"ep": "https://vinylshake.com/cart.php",
				"fd": "2023-01-01 13:04:01",
				"rf": "(none)",
				"cmp": "(none)",
				"cnt": "(none)",
				"mdm": "(none)",
				"src": "(direct)",
				"trm": "(none)",
				"typ": "typein"
			},
			"latest_visit": {
				"ep": "https://vinylshake.com/cart.php",
				"fd": "2023-01-01 13:04:01",
				"rf": "(none)",
				"cmp": "(none)",
				"cnt": "(none)",
				"mdm": "(none)",
				"src": "(direct)",
				"trm": "(none)",
				"typ": "typein"
			},
			"first_date": "2023-02-03T22:17:50.049Z",
			"last_date": "2023-03-03T14:25:56.000Z",
			"checkout_stage": "order_complete",
			"checkout_stage_history": null,
			"current_page": "/checkout",
			"page_history": null,
			"type": "Order",
			"utm_campaign": "(none)",
			"utm_source": "(direct)",
			"utm_medium": "(none)",
			"utm_content": "(none)",
			"utm_term": "(none)",
			"lineItems": [
				{
					"name": "Fullback Foam Panel x 4"
				}
			]
		},
		{
			"identifier": "bigcommerce-1b4b38d7-4bc4-4517-890e-26e979289933",
			"order_id": "33593",
			"domain": "bulletproofdesigns.com",
			"channel": "bigcommerce",
			"session": null,
			"pageviews": 10,
			"user_data": null,
			"promo": null,
			"sales": "254.00",
			"storefront_data": {
				"lineItems": [
					"KTM/HUSQVARNA/GASGAS RADIATOR GUARDS 125-501 ALL MODELS 17-23"
				]
			},
			"first_visit": {
				"ep": "https://bulletproofdesigns.com/ktm-radiator-guards-2017-2023-all-models-125-500-sx-sxf-xc-xcf-exc-xcw-2016-sxf-xcf-sx125-150/",
				"fd": "2023-03-07 17:32:20",
				"rf": "(none)",
				"cmp": "(none)",
				"cnt": "(none)",
				"mdm": "(none)",
				"src": "(direct)",
				"trm": "(none)",
				"typ": "typein"
			},
			"latest_visit": {
				"ep": "https://bulletproofdesigns.com/ktm-radiator-guards-2017-2023-all-models-125-500-sx-sxf-xc-xcf-exc-xcw-2016-sxf-xcf-sx125-150/",
				"fd": "2023-03-07 17:32:20",
				"rf": "(none)",
				"cmp": "(none)",
				"cnt": "(none)",
				"mdm": "(none)",
				"src": "(direct)",
				"trm": "(none)",
				"typ": "typein"
			},
			"first_date": "2023-03-07T22:32:20.875Z",
			"last_date": "2023-03-09T19:11:10.000Z",
			"checkout_stage": "order_complete",
			"checkout_stage_history": null,
			"current_page": "/checkout",
			"page_history": null,
			"type": "Order",
			"utm_campaign": "(none)",
			"utm_source": "(direct)",
			"utm_medium": "(none)",
			"utm_content": "(none)",
			"utm_term": "(none)",
			"lineItems": [
				{
					"name": "KTM/HUSQVARNA/GASGAS RADIATOR GUARDS 125-501 ALL MODELS 17-23"
				}
			]
		},
		{
			"identifier": "bigcommerce-43a0dc6b-801b-43b7-9c52-856691367f0b",
			"order_id": "33593",
			"domain": "store-fr.greenworkstools.eu",
			"channel": "bigcommerce",
			"session": null,
			"pageviews": 1,
			"user_data": null,
			"promo": null,
			"sales": "89.99",
			"storefront_data": {
				"lineItems": [
					"40V Coupe-bordures - sans batterie et chargeur"
				]
			},
			"first_visit": {
				"ep": "https://store-fr.greenworkstools.eu/checkout",
				"fd": "2023-05-02 18:53:58",
				"rf": "https://greenworkstools.eu/",
				"cmp": "(none)",
				"cnt": "/",
				"mdm": "referral",
				"src": "greenworkstools.eu",
				"trm": "(none)",
				"typ": "referral"
			},
			"latest_visit": {
				"ep": "https://store-fr.greenworkstools.eu/checkout",
				"fd": "2023-05-02 18:53:58",
				"rf": "https://greenworkstools.eu/",
				"cmp": "(none)",
				"cnt": "/",
				"mdm": "referral",
				"src": "greenworkstools.eu",
				"trm": "(none)",
				"typ": "referral"
			},
			"first_date": "2023-05-02T16:54:00.354Z",
			"last_date": "2023-05-02T17:02:02.000Z",
			"checkout_stage": "order_complete",
			"checkout_stage_history": null,
			"current_page": "/checkout",
			"page_history": null,
			"type": "Order",
			"utm_campaign": "(none)",
			"utm_source": "greenworkstools.eu",
			"utm_medium": "referral",
			"utm_content": "/",
			"utm_term": "(none)",
			"lineItems": [
				{
					"name": "40V Coupe-bordures - sans batterie et chargeur"
				}
			]
		}
	],
	"sessions": [
		{
			"identifier": "guest-Xl4A8hnfFbWF6Gklk8Ivnd5MONQmhZiT",
			"order_id": null,
			"domain": "www.almanac.com",
			"channel": "bigcommerce",
			"session": null,
			"pageviews": 1,
			"user_data": null,
			"promo": null,
			"sales": "0.00",
			"storefront_data": null,
			"first_visit": {
				"ep": "https://www.almanac.com/gardening/planting-calendar/QC/Coaticook",
				"fd": "2023-05-02 13:06:19",
				"rf": "(none)",
				"cmp": "(none)",
				"cnt": "(none)",
				"mdm": "(none)",
				"src": "(direct)",
				"trm": "(none)",
				"typ": "typein"
			},
			"latest_visit": {
				"ep": "https://www.almanac.com/gardening/planting-calendar/QC/Coaticook",
				"fd": "2023-05-02 13:06:19",
				"rf": "(none)",
				"cmp": "(none)",
				"cnt": "(none)",
				"mdm": "(none)",
				"src": "(direct)",
				"trm": "(none)",
				"typ": "typein"
			},
			"first_date": "2023-05-02T17:06:20.359Z",
			"last_date": "2023-05-02T17:06:20.357Z",
			"checkout_stage": "on_site",
			"checkout_stage_history": null,
			"current_page": "/gardening/planting-calendar/QC/Coaticook",
			"page_history": null,
			"type": "Visitor",
			"utm_campaign": "(none)",
			"utm_source": "(direct)",
			"utm_medium": "(none)",
			"utm_content": "(none)",
			"utm_term": "(none)",
			"lineItems": []
		},
		{
			"identifier": "guest-aYwRL7abYhrBrusW6SpXBOyMzUGtaUIZ",
			"order_id": null,
			"domain": "www.almanac.com",
			"channel": "bigcommerce",
			"session": null,
			"pageviews": 1,
			"user_data": null,
			"promo": null,
			"sales": "0.00",
			"storefront_data": null,
			"first_visit": {
				"ep": "https://www.almanac.com/plant/radishes",
				"fd": "2023-05-02 12:06:20",
				"rf": "https://duckduckgo.com/",
				"cmp": "(none)",
				"cnt": "/",
				"mdm": "referral",
				"src": "duckduckgo.com",
				"trm": "(none)",
				"typ": "referral"
			},
			"latest_visit": {
				"ep": "https://www.almanac.com/plant/radishes",
				"fd": "2023-05-02 12:06:20",
				"rf": "https://duckduckgo.com/",
				"cmp": "(none)",
				"cnt": "/",
				"mdm": "referral",
				"src": "duckduckgo.com",
				"trm": "(none)",
				"typ": "referral"
			},
			"first_date": "2023-05-02T17:06:20.254Z",
			"last_date": "2023-05-02T17:06:20.217Z",
			"checkout_stage": "on_site",
			"checkout_stage_history": null,
			"current_page": "/plant/radishes",
			"page_history": null,
			"type": "Visitor",
			"utm_campaign": "(none)",
			"utm_source": "duckduckgo.com",
			"utm_medium": "referral",
			"utm_content": "/",
			"utm_term": "(none)",
			"lineItems": []
		},
		{
			"identifier": "guest-rOUL4xxKGrRuuNQBN7nMBZLm707vdPCF",
			"order_id": null,
			"domain": "www.arcticus.co.uk",
			"channel": "shopify",
			"session": null,
			"pageviews": 1,
			"user_data": null,
			"promo": null,
			"sales": "0.00",
			"storefront_data": null,
			"first_visit": {
				"ep": "https://www.arcticus.co.uk/collections/sweatcoin-drinkware",
				"fd": "2023-05-02 19:06:18",
				"rf": "(none)",
				"cmp": "(none)",
				"cnt": "(none)",
				"mdm": "(none)",
				"src": "(direct)",
				"trm": "(none)",
				"typ": "typein"
			},
			"latest_visit": {
				"ep": "https://www.arcticus.co.uk/collections/sweatcoin-drinkware",
				"fd": "2023-05-02 19:06:18",
				"rf": "(none)",
				"cmp": "(none)",
				"cnt": "(none)",
				"mdm": "(none)",
				"src": "(direct)",
				"trm": "(none)",
				"typ": "typein"
			},
			"first_date": "2023-05-02T17:06:20.248Z",
			"last_date": "2023-05-02T17:06:20.155Z",
			"checkout_stage": "on_site",
			"checkout_stage_history": null,
			"current_page": "/collections/sweatcoin-drinkware",
			"page_history": null,
			"type": "Visitor",
			"utm_campaign": "(none)",
			"utm_source": "(direct)",
			"utm_medium": "(none)",
			"utm_content": "(none)",
			"utm_term": "(none)",
			"lineItems": []
		},
		{
			"identifier": "guest-U2vCtmNxkN2yMKp2kFO6YZoJWK4al7Vs",
			"order_id": null,
			"domain": "cedarhouse.co",
			"channel": "bigcommerce",
			"session": null,
			"pageviews": 0,
			"user_data": null,
			"promo": null,
			"sales": "0.00",
			"storefront_data": null,
			"first_visit": {
				"cmp": "f29a4bc08f-Mother's+Day+2023+-+Month+of+Mary+-+May+1",
				"cnt": "(none)",
				"mdm": "email",
				"src": "Cedar+House",
				"trm": "0_f3ebe91937-f29a4bc08f-85957134",
				"typ": "utm"
			},
			"latest_visit": {
				"cmp": "f29a4bc08f-Mother's+Day+2023+-+Month+of+Mary+-+May+1",
				"cnt": "(none)",
				"mdm": "email",
				"src": "Cedar+House",
				"trm": "0_f3ebe91937-f29a4bc08f-85957134",
				"typ": "utm"
			},
			"first_date": "2023-05-02T17:06:20.115Z",
			"last_date": "2023-05-02T17:06:20.108Z",
			"checkout_stage": "on_site",
			"checkout_stage_history": null,
			"current_page": "/linnocence-painting-by-william-adolphe-bouguereau-1881-framed-canvas-12-x-22/",
			"page_history": null,
			"type": "Visitor",
			"utm_campaign": "f29a4bc08f-Mother's+Day+2023+-+Month+of+Mary+-+May+1",
			"utm_source": "Cedar+House",
			"utm_medium": "email",
			"utm_content": "(none)",
			"utm_term": "0_f3ebe91937-f29a4bc08f-85957134",
			"lineItems": []
		},
		{
			"identifier": "guest-aRy9jAo5IkrdSxdjuOlgvwo439Ju2sCU",
			"order_id": null,
			"domain": "www.almanac.com",
			"channel": "bigcommerce",
			"session": null,
			"pageviews": 1,
			"user_data": null,
			"promo": null,
			"sales": "0.00",
			"storefront_data": null,
			"first_visit": {
				"ep": "https://www.almanac.com/plant/kale",
				"fd": "2023-05-02 13:06:18",
				"rf": "https://www.google.com/",
				"cmp": "(none)",
				"cnt": "(none)",
				"mdm": "organic",
				"src": "google",
				"trm": "(none)",
				"typ": "organic"
			},
			"latest_visit": {
				"ep": "https://www.almanac.com/plant/kale",
				"fd": "2023-05-02 13:06:18",
				"rf": "https://www.google.com/",
				"cmp": "(none)",
				"cnt": "(none)",
				"mdm": "organic",
				"src": "google",
				"trm": "(none)",
				"typ": "organic"
			},
			"first_date": "2023-05-02T17:06:20.092Z",
			"last_date": "2023-05-02T17:06:20.075Z",
			"checkout_stage": "on_site",
			"checkout_stage_history": null,
			"current_page": "/plant/kale",
			"page_history": null,
			"type": "Visitor",
			"utm_campaign": "(none)",
			"utm_source": "google",
			"utm_medium": "organic",
			"utm_content": "(none)",
			"utm_term": "(none)",
			"lineItems": []
		},
		{
			"identifier": "guest-h5KS8LthvnljqGSwfvK51vlUUxrAHuU4",
			"order_id": null,
			"domain": "store.almanac.com",
			"channel": "bigcommerce",
			"session": null,
			"pageviews": 3,
			"user_data": null,
			"promo": null,
			"sales": "0.00",
			"storefront_data": null,
			"first_visit": {
				"ep": "https://www.almanac.com/spring-weather-forecast",
				"fd": "2023-05-02 13:02:47",
				"rf": "https://www.google.com/",
				"cmp": "(none)",
				"cnt": "(none)",
				"mdm": "organic",
				"src": "google",
				"trm": "(none)",
				"typ": "organic"
			},
			"latest_visit": {
				"ep": "https://www.almanac.com/spring-weather-forecast",
				"fd": "2023-05-02 13:05:24",
				"rf": "https://www.google.com/",
				"cmp": "(none)",
				"cnt": "(none)",
				"mdm": "organic",
				"src": "google",
				"trm": "(none)",
				"typ": "organic"
			},
			"first_date": "2023-05-02T17:06:20.034Z",
			"last_date": "2023-05-02T17:06:20.022Z",
			"checkout_stage": "on_site",
			"checkout_stage_history": null,
			"current_page": "/online-edition-2023-farmers-almanac/",
			"page_history": null,
			"type": "Visitor",
			"utm_campaign": "(none)",
			"utm_source": "google",
			"utm_medium": "organic",
			"utm_content": "(none)",
			"utm_term": "(none)",
			"lineItems": []
		},
		{
			"identifier": "guest-Q5FZPlFEsiwVZbcntllHcaCB11oytt0w",
			"order_id": null,
			"domain": "www.almanac.com",
			"channel": "bigcommerce",
			"session": null,
			"pageviews": 1,
			"user_data": null,
			"promo": null,
			"sales": "0.00",
			"storefront_data": null,
			"first_visit": {
				"ep": "https://www.almanac.com/content/mercury-retrograde-dates",
				"fd": "2023-05-02 12:06:19",
				"rf": "https://www.google.com/",
				"cmp": "(none)",
				"cnt": "(none)",
				"mdm": "organic",
				"src": "google",
				"trm": "(none)",
				"typ": "organic"
			},
			"latest_visit": {
				"ep": "https://www.almanac.com/content/mercury-retrograde-dates",
				"fd": "2023-05-02 12:06:19",
				"rf": "https://www.google.com/",
				"cmp": "(none)",
				"cnt": "(none)",
				"mdm": "organic",
				"src": "google",
				"trm": "(none)",
				"typ": "organic"
			},
			"first_date": "2023-05-02T17:06:19.987Z",
			"last_date": "2023-05-02T17:06:19.985Z",
			"checkout_stage": "on_site",
			"checkout_stage_history": null,
			"current_page": "/content/mercury-retrograde-dates",
			"page_history": null,
			"type": "Visitor",
			"utm_campaign": "(none)",
			"utm_source": "google",
			"utm_medium": "organic",
			"utm_content": "(none)",
			"utm_term": "(none)",
			"lineItems": []
		},
		{
			"identifier": "guest-PAgA1Xh9PVVNgJDUWcK8YGOJk4qe8U9s",
			"order_id": null,
			"domain": "flyleafpublishing.com",
			"channel": "bigcommerce",
			"session": null,
			"pageviews": 1,
			"user_data": null,
			"promo": null,
			"sales": "0.00",
			"storefront_data": null,
			"first_visit": {
				"ep": "https://flyleafpublishing.com/quick-quote",
				"fd": "2023-05-02 12:06:19",
				"rf": "(none)",
				"cmp": "(none)",
				"cnt": "(none)",
				"mdm": "(none)",
				"src": "(direct)",
				"trm": "(none)",
				"typ": "typein"
			},
			"latest_visit": {
				"ep": "https://flyleafpublishing.com/quick-quote",
				"fd": "2023-05-02 12:06:19",
				"rf": "(none)",
				"cmp": "(none)",
				"cnt": "(none)",
				"mdm": "(none)",
				"src": "(direct)",
				"trm": "(none)",
				"typ": "typein"
			},
			"first_date": "2023-05-02T17:06:19.951Z",
			"last_date": "2023-05-02T17:06:19.949Z",
			"checkout_stage": "on_site",
			"checkout_stage_history": null,
			"current_page": "/quick-quote",
			"page_history": null,
			"type": "Visitor",
			"utm_campaign": "(none)",
			"utm_source": "(direct)",
			"utm_medium": "(none)",
			"utm_content": "(none)",
			"utm_term": "(none)",
			"lineItems": []
		},
		{
			"identifier": "guest-8Mq1CfJp01LBouuu8vzsrm9ohqnJ3cgy",
			"order_id": null,
			"domain": "www.almanac.com",
			"channel": "bigcommerce",
			"session": null,
			"pageviews": 1,
			"user_data": null,
			"promo": null,
			"sales": "0.00",
			"storefront_data": null,
			"first_visit": {
				"ep": "https://www.almanac.com/pest/moles",
				"fd": "2023-05-02 19:06:15",
				"rf": "https://www.google.com/",
				"cmp": "(none)",
				"cnt": "(none)",
				"mdm": "organic",
				"src": "google",
				"trm": "(none)",
				"typ": "organic"
			},
			"latest_visit": {
				"ep": "https://www.almanac.com/pest/moles",
				"fd": "2023-05-02 19:06:15",
				"rf": "https://www.google.com/",
				"cmp": "(none)",
				"cnt": "(none)",
				"mdm": "organic",
				"src": "google",
				"trm": "(none)",
				"typ": "organic"
			},
			"first_date": "2023-05-02T17:06:19.947Z",
			"last_date": "2023-05-02T17:06:19.945Z",
			"checkout_stage": "on_site",
			"checkout_stage_history": null,
			"current_page": "/pest/moles",
			"page_history": null,
			"type": "Visitor",
			"utm_campaign": "(none)",
			"utm_source": "google",
			"utm_medium": "organic",
			"utm_content": "(none)",
			"utm_term": "(none)",
			"lineItems": []
		},
		{
			"identifier": "guest-Ei9kUixqnIO6QPPmydh033xlBfMspPLu",
			"order_id": null,
			"domain": "www.almanac.com",
			"channel": "bigcommerce",
			"session": null,
			"pageviews": 1,
			"user_data": null,
			"promo": null,
			"sales": "0.00",
			"storefront_data": null,
			"first_visit": {
				"ep": "https://www.almanac.com/content/month-may-holidays-fun-facts-folklore",
				"fd": "2023-05-02 12:06:18",
				"rf": "https://www.google.com/",
				"cmp": "(none)",
				"cnt": "(none)",
				"mdm": "organic",
				"src": "google",
				"trm": "(none)",
				"typ": "organic"
			},
			"latest_visit": {
				"ep": "https://www.almanac.com/content/month-may-holidays-fun-facts-folklore",
				"fd": "2023-05-02 12:06:18",
				"rf": "https://www.google.com/",
				"cmp": "(none)",
				"cnt": "(none)",
				"mdm": "organic",
				"src": "google",
				"trm": "(none)",
				"typ": "organic"
			},
			"first_date": "2023-05-02T17:06:19.874Z",
			"last_date": "2023-05-02T17:06:19.872Z",
			"checkout_stage": "on_site",
			"checkout_stage_history": null,
			"current_page": "/content/month-may-holidays-fun-facts-folklore",
			"page_history": null,
			"type": "Visitor",
			"utm_campaign": "(none)",
			"utm_source": "google",
			"utm_medium": "organic",
			"utm_content": "(none)",
			"utm_term": "(none)",
			"lineItems": []
		},
		{
			"identifier": "guest-GkHNv38uUQ04j5KyeDKfp7Lrb0fWm9Wd",
			"order_id": null,
			"domain": "www.almanac.com",
			"channel": "bigcommerce",
			"session": null,
			"pageviews": 1,
			"user_data": null,
			"promo": null,
			"sales": "0.00",
			"storefront_data": null,
			"first_visit": {
				"ep": "https://www.almanac.com/content/party-planner-cooking-crowd",
				"fd": "2023-05-02 12:06:19",
				"rf": "https://www.google.com/",
				"cmp": "(none)",
				"cnt": "(none)",
				"mdm": "organic",
				"src": "google",
				"trm": "(none)",
				"typ": "organic"
			},
			"latest_visit": {
				"ep": "https://www.almanac.com/content/party-planner-cooking-crowd",
				"fd": "2023-05-02 12:06:19",
				"rf": "https://www.google.com/",
				"cmp": "(none)",
				"cnt": "(none)",
				"mdm": "organic",
				"src": "google",
				"trm": "(none)",
				"typ": "organic"
			},
			"first_date": "2023-05-02T17:06:19.569Z",
			"last_date": "2023-05-02T17:06:19.568Z",
			"checkout_stage": "on_site",
			"checkout_stage_history": null,
			"current_page": "/content/party-planner-cooking-crowd",
			"page_history": null,
			"type": "Visitor",
			"utm_campaign": "(none)",
			"utm_source": "google",
			"utm_medium": "organic",
			"utm_content": "(none)",
			"utm_term": "(none)",
			"lineItems": []
		},
		{
			"identifier": "bigcommerce-9f7e8099-904e-4f4d-91c6-9a0ba3662313",
			"order_id": null,
			"domain": "www.lindafinegold.com",
			"channel": "bigcommerce",
			"session": null,
			"pageviews": 3,
			"user_data": null,
			"promo": null,
			"sales": "109.50",
			"storefront_data": {
				"lineItems": [
					"IT AINT GONNA SPANK ITSELF • Black Sweatpants",
					"THROAT GOAT • Teal Tie Dye Hoodie",
					"SPRING CLEANING BOX",
					"ALL MOVIE MONSTERS ARE HUMAN • Black Hoodie",
					"EMOTIONAL BAGGAGE OG • Olive Printed Strap Duffle Bag",
					"WALK OF SHAME • Black Printed Strap Duffle Bag",
					"7 ITEM MYSTERY BOX • COMES w LOVE KILLS HOODIE",
					"7 ITEM MYSTERY BOX • COMES w LIVE FAST EAT ASS HOODIE",
					"PAY MY MAY RENT • Mystery Box",
					"BLACKOUT DUFFLE BAG FULL OF SHIT • All Black Mystery Box",
					"NEW $10 DUFFLE BAG FULL OF SHIT • Comes w GLOW IN THE DARK Tee",
					"RANDOM FREE STICKERS • Collect All 100 of Them",
					"OUR FINAL DAYS ONLINE • Premium Duffle Full of Shit",
					"ASSHOLES LIVE HERE • Black Doormat x 2"
				]
			},
			"first_visit": {
				"ep": "https://www.lindafinegold.com/account/login",
				"fd": "2023-05-02 10:03:33",
				"rf": "https://bc.lindafinegold.com/",
				"cmp": "(none)",
				"cnt": "(none)",
				"mdm": "(none)",
				"src": "(direct)",
				"trm": "(none)",
				"typ": "typein"
			},
			"latest_visit": {
				"ep": "https://www.lindafinegold.com/account/login",
				"fd": "2023-05-02 10:03:33",
				"rf": "https://bc.lindafinegold.com/",
				"cmp": "(none)",
				"cnt": "(none)",
				"mdm": "(none)",
				"src": "(direct)",
				"trm": "(none)",
				"typ": "typein"
			},
			"first_date": "2023-05-02T16:55:03.680Z",
			"last_date": "2023-05-02T17:06:19.310Z",
			"checkout_stage": "cart_added",
			"checkout_stage_history": null,
			"current_page": "/account/login",
			"page_history": null,
			"type": "Active Cart",
			"utm_campaign": "(none)",
			"utm_source": "(direct)",
			"utm_medium": "(none)",
			"utm_content": "(none)",
			"utm_term": "(none)",
			"lineItems": [
				{
					"name": "IT AINT GONNA SPANK ITSELF • Black Sweatpants"
				},
				{
					"name": "THROAT GOAT • Teal Tie Dye Hoodie"
				},
				{
					"name": "SPRING CLEANING BOX"
				},
				{
					"name": "ALL MOVIE MONSTERS ARE HUMAN • Black Hoodie"
				},
				{
					"name": "EMOTIONAL BAGGAGE OG • Olive Printed Strap Duffle Bag"
				},
				{
					"name": "WALK OF SHAME • Black Printed Strap Duffle Bag"
				},
				{
					"name": "7 ITEM MYSTERY BOX • COMES w LOVE KILLS HOODIE"
				},
				{
					"name": "7 ITEM MYSTERY BOX • COMES w LIVE FAST EAT ASS HOODIE"
				},
				{
					"name": "PAY MY MAY RENT • Mystery Box"
				},
				{
					"name": "BLACKOUT DUFFLE BAG FULL OF SHIT • All Black Mystery Box"
				},
				{
					"name": "NEW $10 DUFFLE BAG FULL OF SHIT • Comes w GLOW IN THE DARK Tee"
				},
				{
					"name": "RANDOM FREE STICKERS • Collect All 100 of Them"
				},
				{
					"name": "OUR FINAL DAYS ONLINE • Premium Duffle Full of Shit"
				},
				{
					"name": "ASSHOLES LIVE HERE • Black Doormat x 2"
				}
			]
		},
		{
			"identifier": "guest-xW3HOlBzwUKFiymmT4KBvC1KwuBGD316",
			"order_id": null,
			"domain": "www.pnta.com",
			"channel": "bigcommerce",
			"session": null,
			"pageviews": 1,
			"user_data": null,
			"promo": null,
			"sales": "0.00",
			"storefront_data": null,
			"first_visit": {
				"ep": "https://www.pnta.com/",
				"fd": "2023-05-02 10:06:17",
				"rf": "(none)",
				"cmp": "(none)",
				"cnt": "(none)",
				"mdm": "(none)",
				"src": "(direct)",
				"trm": "(none)",
				"typ": "typein"
			},
			"latest_visit": {
				"ep": "https://www.pnta.com/",
				"fd": "2023-05-02 10:06:17",
				"rf": "(none)",
				"cmp": "(none)",
				"cnt": "(none)",
				"mdm": "(none)",
				"src": "(direct)",
				"trm": "(none)",
				"typ": "typein"
			},
			"first_date": "2023-05-02T17:06:19.302Z",
			"last_date": "2023-05-02T17:06:19.300Z",
			"checkout_stage": "on_site",
			"checkout_stage_history": null,
			"current_page": "/",
			"page_history": null,
			"type": "Visitor",
			"utm_campaign": "(none)",
			"utm_source": "(direct)",
			"utm_medium": "(none)",
			"utm_content": "(none)",
			"utm_term": "(none)",
			"lineItems": []
		},
		{
			"identifier": "bigcommerce-7d164134-60d4-4ed8-9602-b121d5e07740",
			"order_id": null,
			"domain": "www.multipure.com",
			"channel": "bigcommerce",
			"session": null,
			"pageviews": 22,
			"user_data": null,
			"promo": null,
			"sales": "90.00",
			"storefront_data": {
				"lineItems": [
					"Aquadome Filter (CB6AD)"
				]
			},
			"first_visit": {
				"ep": "https://www.multipure.com/",
				"fd": "2023-03-27 12:13:30",
				"rf": "(none)",
				"cmp": "(none)",
				"cnt": "(none)",
				"mdm": "(none)",
				"src": "(direct)",
				"trm": "(none)",
				"typ": "typein"
			},
			"latest_visit": {
				"ep": "https://www.multipure.com/account.php?action=order_status",
				"fd": "2023-04-28 08:38:55",
				"rf": "https://store-2t5fkq8mli.mybigcommerce.com/",
				"cmp": "(none)",
				"cnt": "/",
				"mdm": "referral",
				"src": "store-2t5fkq8mli.mybigcommerce.com",
				"trm": "(none)",
				"typ": "referral"
			},
			"first_date": "2023-05-02T17:02:26.878Z",
			"last_date": "2023-05-02T17:06:19.294Z",
			"checkout_stage": "cart_added",
			"checkout_stage_history": null,
			"current_page": "/account.php",
			"page_history": null,
			"type": "Active Cart",
			"utm_campaign": "(none)",
			"utm_source": "(direct)",
			"utm_medium": "(none)",
			"utm_content": "(none)",
			"utm_term": "(none)",
			"lineItems": [
				{
					"name": "Aquadome Filter (CB6AD)"
				}
			]
		},
		{
			"identifier": "bigcommerce-cad228d8-c5a2-4a32-ab2e-a08e7845d9be",
			"order_id": null,
			"domain": "bulletproofdesigns.com",
			"channel": "bigcommerce",
			"session": null,
			"pageviews": 1,
			"user_data": null,
			"promo": null,
			"sales": "139.00",
			"storefront_data": {
				"lineItems": [
					"KTM/HUSQVARNA/GAS GAS REAR DISC GUARD 2004-2023 ALL BIKES 125-501"
				]
			},
			"first_visit": {
				"ep": "https://bulletproofdesigns.com/",
				"fd": "2023-04-25 21:58:46",
				"rf": "(none)",
				"cmp": "(none)",
				"cnt": "(none)",
				"mdm": "(none)",
				"src": "(direct)",
				"trm": "(none)",
				"typ": "typein"
			},
			"latest_visit": {
				"ep": "https://bulletproofdesigns.com/",
				"fd": "2023-05-02 11:06:17",
				"rf": "https://www.google.com/",
				"cmp": "(none)",
				"cnt": "(none)",
				"mdm": "organic",
				"src": "google",
				"trm": "(none)",
				"typ": "organic"
			},
			"first_date": "2023-04-26T03:58:47.824Z",
			"last_date": "2023-05-02T17:06:19.228Z",
			"checkout_stage": "cart_added",
			"checkout_stage_history": null,
			"current_page": "/",
			"page_history": null,
			"type": "Active Cart",
			"utm_campaign": "(none)",
			"utm_source": "(direct)",
			"utm_medium": "(none)",
			"utm_content": "(none)",
			"utm_term": "(none)",
			"lineItems": [
				{
					"name": "KTM/HUSQVARNA/GAS GAS REAR DISC GUARD 2004-2023 ALL BIKES 125-501"
				}
			]
		},
		{
			"identifier": "guest-IM4cfw02noRWqRzxCalpAAFbflvebkik",
			"order_id": null,
			"domain": "www.almanac.com",
			"channel": "bigcommerce",
			"session": null,
			"pageviews": 1,
			"user_data": null,
			"promo": null,
			"sales": "0.00",
			"storefront_data": null,
			"first_visit": {
				"ep": "https://www.almanac.com/content/meteor-shower-calendar",
				"fd": "2023-05-02 10:06:17",
				"rf": "https://www.google.com/",
				"cmp": "(none)",
				"cnt": "(none)",
				"mdm": "organic",
				"src": "google",
				"trm": "(none)",
				"typ": "organic"
			},
			"latest_visit": {
				"ep": "https://www.almanac.com/content/meteor-shower-calendar",
				"fd": "2023-05-02 10:06:17",
				"rf": "https://www.google.com/",
				"cmp": "(none)",
				"cnt": "(none)",
				"mdm": "organic",
				"src": "google",
				"trm": "(none)",
				"typ": "organic"
			},
			"first_date": "2023-05-02T17:06:19.227Z",
			"last_date": "2023-05-02T17:06:19.223Z",
			"checkout_stage": "on_site",
			"checkout_stage_history": null,
			"current_page": "/content/meteor-shower-calendar",
			"page_history": null,
			"type": "Visitor",
			"utm_campaign": "(none)",
			"utm_source": "google",
			"utm_medium": "organic",
			"utm_content": "(none)",
			"utm_term": "(none)",
			"lineItems": []
		},
		{
			"identifier": "bigcommerce-457351ee-861a-4e23-bd6b-3ee29d8a2732",
			"order_id": null,
			"domain": "www.lindafinegold.com",
			"channel": "bigcommerce",
			"session": null,
			"pageviews": 1,
			"user_data": null,
			"promo": null,
			"sales": "20.00",
			"storefront_data": {
				"lineItems": [
					"ALF FLAG • Black Tee"
				]
			},
			"first_visit": {
				"ep": "https://www.lindafinegold.com/",
				"fd": "2023-05-02 10:03:14",
				"rf": "https://www.google.com/",
				"cmp": "(none)",
				"cnt": "(none)",
				"mdm": "organic",
				"src": "google",
				"trm": "(none)",
				"typ": "organic"
			},
			"latest_visit": {
				"ep": "https://www.lindafinegold.com/",
				"fd": "2023-05-02 10:03:14",
				"rf": "https://www.google.com/",
				"cmp": "(none)",
				"cnt": "(none)",
				"mdm": "organic",
				"src": "google",
				"trm": "(none)",
				"typ": "organic"
			},
			"first_date": "2023-05-02T17:03:15.677Z",
			"last_date": "2023-05-02T17:06:19.191Z",
			"checkout_stage": "cart_added",
			"checkout_stage_history": null,
			"current_page": "/",
			"page_history": null,
			"type": "Active Cart",
			"utm_campaign": "(none)",
			"utm_source": "google",
			"utm_medium": "organic",
			"utm_content": "(none)",
			"utm_term": "(none)",
			"lineItems": [
				{
					"name": "ALF FLAG • Black Tee"
				}
			]
		},
		{
			"identifier": "guest-44p8yx9jdmdZn0WnrbqbXz1QrwYX1zQ2",
			"order_id": null,
			"domain": "www.almanac.com",
			"channel": "bigcommerce",
			"session": null,
			"pageviews": 1,
			"user_data": null,
			"promo": null,
			"sales": "0.00",
			"storefront_data": null,
			"first_visit": {
				"ep": "https://www.almanac.com/content/common-garden-weeds",
				"fd": "2023-05-02 13:06:16",
				"rf": "https://www.google.com/",
				"cmp": "(none)",
				"cnt": "(none)",
				"mdm": "organic",
				"src": "google",
				"trm": "(none)",
				"typ": "organic"
			},
			"latest_visit": {
				"ep": "https://www.almanac.com/content/common-garden-weeds",
				"fd": "2023-05-02 13:06:16",
				"rf": "https://www.google.com/",
				"cmp": "(none)",
				"cnt": "(none)",
				"mdm": "organic",
				"src": "google",
				"trm": "(none)",
				"typ": "organic"
			},
			"first_date": "2023-05-02T17:06:19.193Z",
			"last_date": "2023-05-02T17:06:19.191Z",
			"checkout_stage": "on_site",
			"checkout_stage_history": null,
			"current_page": "/content/common-garden-weeds",
			"page_history": null,
			"type": "Visitor",
			"utm_campaign": "(none)",
			"utm_source": "google",
			"utm_medium": "organic",
			"utm_content": "(none)",
			"utm_term": "(none)",
			"lineItems": []
		},
		{
			"identifier": "guest-jyoXnSjsRa0KOqzoGhPp9lEcWUQNzY2p",
			"order_id": null,
			"domain": "www.arcticus.co.uk",
			"channel": "shopify",
			"session": null,
			"pageviews": 1,
			"user_data": null,
			"promo": null,
			"sales": "0.00",
			"storefront_data": null,
			"first_visit": {
				"ep": "https://www.arcticus.co.uk/collections/sweatcoin-drinkware",
				"fd": "2023-05-02 09:06:17",
				"rf": "(none)",
				"cmp": "(none)",
				"cnt": "(none)",
				"mdm": "(none)",
				"src": "(direct)",
				"trm": "(none)",
				"typ": "typein"
			},
			"latest_visit": {
				"ep": "https://www.arcticus.co.uk/collections/sweatcoin-drinkware",
				"fd": "2023-05-02 09:06:17",
				"rf": "(none)",
				"cmp": "(none)",
				"cnt": "(none)",
				"mdm": "(none)",
				"src": "(direct)",
				"trm": "(none)",
				"typ": "typein"
			},
			"first_date": "2023-05-02T17:06:18.963Z",
			"last_date": "2023-05-02T17:06:18.957Z",
			"checkout_stage": "on_site",
			"checkout_stage_history": null,
			"current_page": "/collections/sweatcoin-drinkware",
			"page_history": null,
			"type": "Visitor",
			"utm_campaign": "(none)",
			"utm_source": "(direct)",
			"utm_medium": "(none)",
			"utm_content": "(none)",
			"utm_term": "(none)",
			"lineItems": []
		},
		{
			"identifier": "guest-N8SMqatjZjrXdko8WwRtCBfdNk663eKC",
			"order_id": null,
			"domain": "www.almanac.com",
			"channel": "bigcommerce",
			"session": null,
			"pageviews": 1,
			"user_data": null,
			"promo": null,
			"sales": "0.00",
			"storefront_data": null,
			"first_visit": {
				"ep": "https://www.almanac.com/weather/longrange/wv",
				"fd": "2023-05-02 17:06:18",
				"rf": "(none)",
				"cmp": "(none)",
				"cnt": "(none)",
				"mdm": "(none)",
				"src": "(direct)",
				"trm": "(none)",
				"typ": "typein"
			},
			"latest_visit": {
				"ep": "https://www.almanac.com/weather/longrange/wv",
				"fd": "2023-05-02 17:06:18",
				"rf": "(none)",
				"cmp": "(none)",
				"cnt": "(none)",
				"mdm": "(none)",
				"src": "(direct)",
				"trm": "(none)",
				"typ": "typein"
			},
			"first_date": "2023-05-02T17:06:18.858Z",
			"last_date": "2023-05-02T17:06:18.856Z",
			"checkout_stage": "on_site",
			"checkout_stage_history": null,
			"current_page": "/weather/longrange/wv",
			"page_history": null,
			"type": "Visitor",
			"utm_campaign": "(none)",
			"utm_source": "(direct)",
			"utm_medium": "(none)",
			"utm_content": "(none)",
			"utm_term": "(none)",
			"lineItems": []
		}
	],
	"stats": {
		"total_sessions_today": 329662,
		"total_visitors_today": 315399,
		"total_carts_today": 9986,
		"total_checkouts_today": 2043,
		"total_orders_today": 4277,
		"total_sales_today": "8632234.58",
		"total_potential_sales_today": "43095758.34",
		"total_visitors": 2837,
		"total_carts": 173,
		"total_checkouts": 60,
		"total_orders": 45
	},
	"facets": [],
	"trends": [
		{
			"value": "6 min ago",
			"min_ago": 5,
			"total_sessions": 2,
			"total_visitors": 2,
			"total_carts": 0,
			"total_checkouts": 0,
			"total_orders": 0,
			"total_sales": 0,
			"total_potential_sales": 0
		},
		{
			"value": "5 min ago",
			"total_sessions": 593,
			"total_carts": 32,
			"total_orders": 11,
			"total_sales": 1976.46,
			"total_potential_sales": 39724.61,
			"min_ago": 4,
			"total_visitors": null,
			"total_checkouts": null
		},
		{
			"value": "4 min ago",
			"total_sessions": 611,
			"total_carts": 32,
			"total_orders": 10,
			"total_sales": 2766.7,
			"total_potential_sales": 558623.2,
			"min_ago": 3,
			"total_visitors": null,
			"total_checkouts": null
		},
		{
			"value": "3 min ago",
			"total_sessions": 559,
			"total_carts": 26,
			"total_orders": 4,
			"total_sales": 1266.67,
			"total_potential_sales": 74471.36,
			"min_ago": 2,
			"total_visitors": null,
			"total_checkouts": null
		},
		{
			"value": "2 min ago",
			"total_sessions": 638,
			"total_carts": 36,
			"total_orders": 12,
			"total_sales": 6590.16,
			"total_potential_sales": 50425.1,
			"min_ago": 1,
			"total_visitors": null,
			"total_checkouts": null
		},
		{
			"value": "Now",
			"total_sessions": 652,
			"total_carts": 47,
			"total_orders": 8,
			"total_sales": 4875.51,
			"total_potential_sales": 32724.85,
			"min_ago": 0,
			"total_visitors": null,
			"total_checkouts": null
		}
	],
	"pages": {
		"sessions": null,
		"visitors": 142,
		"carts": 9,
		"checkouts": 3,
		"orders": 3
	}
};