import React from 'react'
import { Button } from 'react-bootstrap'
import { saveAsCSV, saveAsGoogleDrive, getAxiosDefaultConfig } from 'utils'
import { connect } from "react-redux"
import { setFullSearchStatus, } from 'actions/dashboardActions'
import withDrivePicker from 'sharedComponents/OpenDrivePicker'
import axios from 'axios'
import { googleDriveReportUrl, getGoogleDriveTokenUrl, sendEmailReportUrl } from 'urls'
import { googleClientId, googlePickerDeveloperToken } from 'token'

@connect(() => ({}), { setFullSearchStatus, })
@withDrivePicker
export default class ExportButton extends React.Component {
  exportData = async (returnData = false, runPreAction = true) => {
    const {
      csvFormatterSets = [],
      fileName,
      preAction,
      dataPreFormatterSets = [],
      exportKeySets = [],
      exportTitleSets = [],
      dataSetNames = [],
      setFullSearchStatus,
      type = 'csv',
      googleDriveAccount = {},
      report_id,
    } = this.props
    try {
      if (type !== 'google_drive') {
        if (runPreAction && preAction) {
          await preAction()
        }
        const exportingDataSets = [], exportingFormatters = []
        for (var dataIndex in this.props.dataSets) {
          const dataPreFormatter = dataPreFormatterSets[dataIndex], csvFormatter = csvFormatterSets[dataIndex]
          const data = dataPreFormatter ? dataPreFormatter(this.props.dataSets[dataIndex]) : this.props.dataSets[dataIndex]
          if (csvFormatter) {
            const { formattedData, formatters } = csvFormatter(data)
            exportingDataSets.push(formattedData)
            exportingFormatters.push(formatters)
          }
          else {
            exportingDataSets.push(data)
            exportingFormatters.push((this.props.formatterSets || [])[dataIndex])
          }
        }
        await setTimeout(async () => await setFullSearchStatus({ state: null, }), 1500)
        if (type !== 'email') {
          const csvData = await saveAsCSV(exportingDataSets, fileName, exportingFormatters, exportKeySets, exportTitleSets, dataSetNames, returnData)
          if (returnData) {
            return csvData
          }
        } else {
          const formData = await saveAsGoogleDrive(exportingDataSets, fileName, exportingFormatters, exportKeySets, exportTitleSets, dataSetNames)
          const { headers } = getAxiosDefaultConfig()
          await axios.post(`${sendEmailReportUrl}`, formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
              ...headers
            },
            withCredentials: true
          })
        }
      } else {
        const { openPicker } = this.props
        openPicker({
          clientId: googleClientId,
          developerKey: googlePickerDeveloperToken,
          viewId: 'FOLDERS',
          customScopes: ["email","profile","https://www.googleapis.com/auth/drive.file","https://www.googleapis.com/auth/spreadsheets"],
          showUploadView: true,
          showUploadFolders: true,
          setSelectFolderEnabled: true,
          supportDrives: true,
          multiselect: false,
          callbackFunction: async (data) => {
            if (data.action === 'cancel') {
              console.log('User clicked cancel/close button')
            } else {
              if (data.action === 'picked') {
                if (preAction) {
                  await preAction()
                }
                const exportingDataSets = [], exportingFormatters = []
                for (var dataIndex in this.props.dataSets) {
                  const dataPreFormatter = dataPreFormatterSets[dataIndex], csvFormatter = csvFormatterSets[dataIndex]
                  const data = dataPreFormatter ? dataPreFormatter(this.props.dataSets[dataIndex]) : this.props.dataSets[dataIndex]
                  if (csvFormatter) {
                    const { formattedData, formatters } = csvFormatter(data)
                    exportingDataSets.push(formattedData)
                    exportingFormatters.push(formatters)
                  }
                  else {
                    exportingDataSets.push(data)
                    exportingFormatters.push((this.props.formatterSets || [])[dataIndex])
                  }
                }
                await setTimeout(async () => await setFullSearchStatus({ state: null, }), 1500)
                const formData = await saveAsGoogleDrive(exportingDataSets, fileName, exportingFormatters, exportKeySets, exportTitleSets, dataSetNames)

                formData.append('google_drive_folder_id', data.docs[0].id);
                formData.append('google_id', googleDriveAccount.merchant_id);

                const { headers } = getAxiosDefaultConfig()

                await axios.post(`${googleDriveReportUrl}/report`, formData, {
                  headers: {
                    'Content-Type': 'multipart/form-data',
                    ...headers
                  },
                  withCredentials: true
                })
              }
            }
          },
        })
      }
    }
    catch (error) {
      console.log(error)
    }
  }

  componentDidMount() {
    if (this.props.getExportDataRef) {
      this.props.getExportDataRef(this.exportData)
    }
  } 

  render() {
    const { children, id = '', className = '', style = {}, disabled } = this.props
    const defaultOnclick = () => {
      this.exportData()
    }
    const onClick = this.props.onClick || defaultOnclick
    return (
      <div className="export-btn-box" style={style}>
        {
          children ? (
            <div onClick={onClick} id={id} className={className}>{children}</div>
          ) : (
            <Button variant="primary" onClick={onClick} id={id} className={className} disabled={disabled}>
              <span className="fa fa-cloud-download"/>
              <span>Export to CSV</span>
            </Button>
          )
        }
      </div>
    )
  }
}
