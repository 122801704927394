import React from 'react'
import './index.css'

const FAQBigcommerce = () => (
  <main className="faq">
    <div className="content-wrapper">
      <div className="container">
          <div className="content">
            <h1 className="center"><p>Frequently Asked Questions & Support</p></h1>
            <h2 className="center">Frequently Asked Questions & Support<br/><br/></h2>
            <h4><strong>How many BigCommerce channels can I link to Payhelm?</strong></h4>
            <p>You can link unlimited BigCommerce channels into your Payhelm reporting & email campaigns. Your subscription plan is related to the total amount of sales you've made which is what costs more or less to serve your account.</p>
            <br/>
            <h4><strong>How can I contact support?</strong></h4>
            <p>You can email our support team at <a href="mailto:success@payhelm.com">success@payhelm.com</a> and we'll respond within 24 to 48 business hours.</p>
            <br/>
            <h4><strong>I linked my BigCommerce store and I do not see any data, why?</strong></h4>
            <p>BigCommerce data will appear in the reports through the day when new orders appear.</p>
            <br/>
            <h4><strong>How long does it take to load my BigCommerce history?</strong></h4>
            <p>When you signup we initiate a history backfill to load the history of your BigCommerce account.  The progress bar will indicate how much has completed and it can often take anywhere from a few minutes to an hour depending on the size of your sales volume.</p>
            <br/>
            <h4><strong>How much history does Payhelm support for my data?</strong></h4>
            <p>We support as much history as you have in your account.  Every day more history accumulates and we store that history forever.  In addition we backfill as much history as each channel allows. In BigCommerce will go back as far as the accounts creation.</p>
            <br/>
            <h4><strong>How do I filter my data to drill into other aspects of it?</strong></h4>
            <p>There is a filter icon next to the search box on the report tab.  You can click that filter to see other options available and to drill deeper into your data to uncover other insights.</p>
          </div>
      </div>
    </div>
</main>
)

export default FAQBigcommerce