import axios from 'axios'
import { searchUrl } from 'urls'
import {
  utmDataTypes,
  utmSolrTerms,
  fullSearchPerPage,
  fullSearchTrunkSize,
  fullSearchSubTrunkSize,
} from 'modules/constants'
import { shippingUrl } from "urls";
import { getAxiosDefaultConfig } from "utils";
import store from '../../../app/store'
import _ from 'lodash'

export const DEFAULT_ORDER_SEARCH_FACETS = [
  'totals', 'financial_status', 'processing_method', 'payment_method', 'gateway', 'credit_card_type', 'source_name', 'customer', 'customer_group_name',
  'fulfillment_status', 'billing_country_code', 'billing_county_name', 'billing_city', 'fulfillment_full_address', 'shipping_providers', 'shipping_country_code', 'shipping_province', 'shipping_county_name', 'shipping_city', 'currency', 'merchant_id', 'channel',
  'utm_source', 'utm_medium', 'utm_campaign', 'utm_content', 'utm_term', 'utm_id', 'marketing', 'brand_ids', 'sku_ids', 'product_sku_ids', 'bin_picking_numbers', 'vendor_ids', 'coupon_codes', 'sales_channel_name', 'sales_channel_type', 'sales_channel_platform',
  'options', 'custom_fields', 'customer_form_fields', 'shipping_form_fields', 'product_types', 'category_titles', 'catalog_titles_v1', 'order_tags', 'app_id', 'tax_exempt_category', 'product_metafields', 'order_metafields', 'taxed', 'discounted', 'noted', 'guest', 'points_status', 'gift_cards', 'customer_custom_attributes',
]

export const searchServer = () => axios.create({
  baseURL: searchUrl,
  headers: {
    authorization: 'bearer fe376420f3b0e2264fa1b73794bbe643',
    ['session-signature']: store.getState().root.sessionSignature,
    path: location.pathname,
    search: location.search,
  },
  withCredentials: true,
})

// Split into trunks if full search
export const runSearch = async (route, dataTypes, params, config, maxLimit, setFullSearchStatus) => {
  let numTrunk = 1, isFullsearch = params.rows === fullSearchPerPage
  if (isFullsearch) {
    params.rows = fullSearchTrunkSize
    numTrunk = Math.ceil(maxLimit / params.rows)
  }
  let output = {}
  for (var trunkIndex = 1; trunkIndex <= numTrunk; trunkIndex ++) {
    try {
      const trunkResponses = (await Promise.all(isFullsearch ? [0,1,2].map(subIndex => (
        searchServer().post(route, {
          params: {
            ...params,
            rows: fullSearchSubTrunkSize,
            start: params.start + fullSearchSubTrunkSize * subIndex
          },
          config
        })
      )) : [
        searchServer().post(route, { params, config })
      ])).map(r => r.data)
      if (isFullsearch && !store.getState().dashboard.fullSearchStatus.state) {
        // Has been cancelled by user
        throw('cancelled')
      }
      output = {
        ...output,
        ...(trunkResponses.reduce((racc, trunkResponse) => ({ ...racc, ...trunkResponse}), {})),
        ...dataTypes.reduce((acc, cur) => ({
          [cur]: [
            ...(output[cur] || []),
            ...(trunkResponses.reduce((racc, trunkResponse) => [...racc, ...(trunkResponse[cur] || [])], [])),
          ]
        }), {}),
      }

      params.start += params.rows
      setFullSearchStatus({
        fetched: params.start,
      })
    }
    catch(error) {
      if (error === 'cancelled') {
        throw('cancelled')
      }
      else if (isFullsearch) {
        console.error(`Failed to get full search trunk ${trunkIndex}/${numTrunk}`, error)
        setFullSearchStatus({
          state: 'failed',
          message: 'Please click the Close button to view/download the current fetched records',
        })
        return output
      }
    }
  }

  // Append shipping label purchased and / or shipping insurance for orders

  const pathname = window.location.pathname

  if (dataTypes[0] === 'orders' && pathname === '/shipping') {
    const { orders } = output
    const orderNumbers = _.map(orders, 'order_number')
    const [result, labelResult] = await Promise.all([checkInsuredOrders(orderNumbers), checkLabeledOrders(orderNumbers)])
    const { labelResult: labelRes } = labelResult
    const { result: insuranceRes } = result
    const insuranceOrderNumberResult = insuranceRes.map(order => typeof order.order_number === 'string' ? order.order_number.replace(/-/g, '') : order.order_number)
    const labelOrderNumberResult = _.map(labelRes, 'order_number')

    output.orders = orders?.map(order => {
      const { order_number } = order

      if (insuranceOrderNumberResult.includes(order_number.toString().replace().replace(/-/g, '')) || labelOrderNumberResult.includes(order_number.toString().replace(/-/g, ''))) {
        const insuredInformation = _.find(insuranceRes, (order) => {
          return Number(order.order_number) == order_number || order.order_number.toString().replace(/-/g, '') == order_number.toString().replace().replace(/-/g, '')
        })
        const labeledInformation = _.find(labelRes, (order) => {
          return Number(order.order_number) == order_number || order.order_number == order_number
        })

        return { insured_shipment_information: insuredInformation, labeled_shipment_information: labeledInformation, ...order }
      } else {
        return order
      }
    })
  }

  return output
}

export const createTrendSearch = (
  filters,
  filterLogicOperator,
  { effectiveFilterKeys, effectiveOptionFilterKeys, effectiveCustomFieldFilterKeys, effectiveCustomerFormFieldFilterKeys, effectiveShippingFormFieldFilterKeys, effectiveCustomerCustomAttributesFilterKeys },
  searchTerms,
  searchExcludeTerms,
  { startDate, endDate }, // date range param
  dateType,
  { trendKeys, trendsGroupBy },
  accountFilter,
  config,
  timestamp,
  setFullSearchStatus,
  dateFilters,
  rangeFilters,
  keywordFilters,
  timePicker
) => {
  const params = {
    q: constructSearchTerms(searchTerms, searchExcludeTerms, filterLogicOperator),
    fq: constructFilterQuery(
      filters,
      effectiveFilterKeys,
      effectiveOptionFilterKeys,
      effectiveCustomFieldFilterKeys,
      effectiveCustomerFormFieldFilterKeys,
      effectiveShippingFormFieldFilterKeys,
      effectiveCustomerCustomAttributesFilterKeys,
      filterLogicOperator
    ),
    start_date: startDate,
    end_date: endDate,
    date_type: dateType,
    logic_operator: filterLogicOperator,
    merchant_id: (accountFilter && accountFilter.value) ? accountFilter.value : '',
    timestamp,
    date_filters: dateFilters,
    range_filters: rangeFilters,
    keyword_filters: keywordFilters,
    timePicker,
  }
  if (trendsGroupBy) {
    params.group_by = trendsGroupBy
  }
  if (trendKeys) {
    params.trend_keys = trendKeys
  }
  return runSearch('/trends', ['trends'], params, config, 0, setFullSearchStatus)
}

export const createOrderSearch = (
  filters,
  filterLogicOperator,
  { effectiveFilterKeys, effectiveOptionFilterKeys, effectiveCustomFieldFilterKeys, effectiveCustomerFormFieldFilterKeys, effectiveShippingFormFieldFilterKeys, effectiveCustomerCustomAttributesFilterKeys },
  searchTerms,
  searchExcludeTerms,
  { startDate, endDate }, // date range param
  dateType,
  { page, perPage, newPage, newPerPage, newSearchOnlyFor, newSortOrder = {} },
  accountFilter,
  isPageAction,
  config,
  maxLimit,
  timestamp,
  setFullSearchStatus,
  dateFilters,
  rangeFilters,
  keywordFilters,
  timePicker
) => {
  // Not to get some facets when it's a pagination action
  const facets = newSearchOnlyFor && !isPageAction ? [] : DEFAULT_ORDER_SEARCH_FACETS

  const params = {
    q: constructSearchTerms(searchTerms, searchExcludeTerms, filterLogicOperator),
    fq: constructFilterQuery(
      filters,
      effectiveFilterKeys,
      effectiveOptionFilterKeys,
      effectiveCustomFieldFilterKeys,
      effectiveCustomerFormFieldFilterKeys,
      effectiveShippingFormFieldFilterKeys,
      effectiveCustomerCustomAttributesFilterKeys,
      filterLogicOperator
    ),
    start_date: startDate,
    end_date: endDate,
    date_type: dateType,
    logic_operator: filterLogicOperator,
    facets: facets,
    sort: constructSortQuery(newSortOrder),
    rows: newPerPage || perPage,
    start: ((newPage || page) - 1) * (newPerPage || perPage),
    merchant_id: (accountFilter && accountFilter.value) ? accountFilter.value : '',
    page_action: isPageAction || '',
    timestamp,
    date_filters: dateFilters,
    range_filters: rangeFilters,
    keyword_filters: keywordFilters,
    timePicker,
  }
  if (params.rows === fullSearchPerPage) {
    setFullSearchStatus({
      recordName: 'order',
      state: 'in-progess',
      total: maxLimit,
      fetched: 0,
     })
  }
  return runSearch('/orders', ['orders'], params, config, maxLimit, setFullSearchStatus)
}

// For data types: ['product', 'customer', 'brand', 'sku', 'vendor']
export const createSecondarySearch = (
  dataType,
  filters,
  filterLogicOperator,
  { effectiveFilterKeys, effectiveOptionFilterKeys, effectiveCustomFieldFilterKeys, effectiveCustomerFormFieldFilterKeys, effectiveShippingFormFieldFilterKeys, effectiveCustomerCustomAttributesFilterKeys },
  searchTerms,
  searchExcludeTerms,
  { startDate, endDate },
  dateType,
  { page, perPage, newPage, newPerPage, newSortOrder = {} },
  accountFilter,
  isPageAction,
  config,
  maxLimit,
  timestamp,
  setFullSearchStatus,
  dateFilters,
  rangeFilters,
  keywordFilters,
  timePicker,
) => {
  const params = {
    q: constructSearchTerms(searchTerms, searchExcludeTerms, filterLogicOperator),
    fq: constructFilterQuery(
      filters,
      effectiveFilterKeys,
      effectiveOptionFilterKeys,
      effectiveCustomFieldFilterKeys,
      effectiveCustomerFormFieldFilterKeys,
      effectiveShippingFormFieldFilterKeys,
      effectiveCustomerCustomAttributesFilterKeys,
      filterLogicOperator
    ),
    start_date: startDate,
    end_date: endDate,
    date_type: dateType,
    logic_operator: filterLogicOperator,
    facets: [`${dataType}s`, dataType === 'customer' ? 'customer_totals' : 'product_totals'],
    rows: newPerPage || perPage,
    start: ((newPage || page) - 1) * (newPerPage || perPage),
    sort: constructSortQuery(newSortOrder),
    merchant_id: (accountFilter && accountFilter.value) ? accountFilter.value : '',
    page_action: isPageAction || '',
    timestamp,
    date_filters: dateFilters,
    range_filters: rangeFilters,
    keyword_filters: keywordFilters,
    timePicker,
  }
  if (params.rows === fullSearchPerPage) {
    setFullSearchStatus({
      recordName: dataType,
      state: 'in-progess',
      total: maxLimit,
      fetched: 0,
     })
  }
  return runSearch(`/${dataType}s`, [`${dataType}s`], params, config, maxLimit, setFullSearchStatus)
}

export const createMarketingSearch = (
  dataType,
  filters,
  filterLogicOperator,
  { effectiveFilterKeys, effectiveOptionFilterKeys, effectiveCustomFieldFilterKeys, effectiveCustomerFormFieldFilterKeys, effectiveShippingFormFieldFilterKeys, effectiveCustomerCustomAttributesFilterKeys },
  searchTerms,
  searchExcludeTerms,
  { startDate, endDate },
  dateType,
  { page = {}, perPage = {}, newPage = {}, newPerPage = {}, newSortOrder = {} },
  accountFilter,
  isPageAction,
  config,
  maxLimit,
  timestamp,
  setFullSearchStatus,
  dateFilters,
  rangeFilters,
  keywordFilters,
  timePicker,
) => {
  const effectiveTypes = dataType ? [dataType] : utmDataTypes
  const params = {
    term: utmSolrTerms[dataType] || '',
    q: constructSearchTerms(searchTerms, searchExcludeTerms, filterLogicOperator),
    fq: constructFilterQuery(
      filters,
      effectiveFilterKeys,
      effectiveOptionFilterKeys,
      effectiveCustomFieldFilterKeys,
      effectiveCustomerFormFieldFilterKeys,
      effectiveShippingFormFieldFilterKeys,
      effectiveCustomerCustomAttributesFilterKeys,
      filterLogicOperator
    ),
    start_date: startDate,
    end_date: endDate,
    date_type: dateType,
    logic_operator: filterLogicOperator,
    facets: ['marketing'],
    rows: effectiveTypes.map(k => `${utmSolrTerms[k]}:${newPerPage[k] || perPage[k]}`).join(','),
    start: effectiveTypes.map(k => `${utmSolrTerms[k]}:${((newPage[k] || page[k]) - 1) * (newPerPage[k] || perPage[k])}`).join(','),
    sort: effectiveTypes.map(k => `${utmSolrTerms[k]}:${constructSortQuery(newSortOrder[k])}`).join(','),
    merchant_id: (accountFilter && accountFilter.value) ? accountFilter.value : '',
    page_action: isPageAction || '',
    timestamp,
    date_filters: dateFilters,
    range_filters: rangeFilters,
    keyword_filters: keywordFilters,
    timePicker,
  }
  if (params.rows === fullSearchPerPage) {
    setFullSearchStatus({
      recordName: 'marketing',
      state: 'in-progess',
      total: maxLimit,
      fetched: 0,
     })
  }
  return runSearch('/marketing', effectiveTypes, params, config, maxLimit, setFullSearchStatus)
}

export const createGoogleAdsSearch = async () => {
  try {
    const { data } = await axios.get('http://localhost:3000/google/ads', {
      ...getAxiosDefaultConfig()
    })
    return data
  } catch (error) {
    return []
  }
}

const constructSearchTerms = (searchTerms, searchExcludeTerms, filterLogicOperator) => {
  const allTerms = [searchTerms, searchExcludeTerms ? `\\!${searchExcludeTerms}` : ''].filter(v => v)
  return allTerms.length > 1 ? allTerms.map(t => `(${t})`).join(` \\${filterLogicOperator}\\ `) : allTerms.join()
}

const constructSecondaryFilterSearchTerm = (filterKey, val) => {
  const trimmedFilterKey = filterKey.replace(/^-/, '')
  return ((val === '[* TO *]') ? `(/${trimmedFilterKey}:.*/)` : `"${trimmedFilterKey}:${val}"`).replace(/'/g, "\\'")
}

const constructFilterQuery = (
  filters,
  effectiveFilterKeys,
  effectiveOptionFilterKeys,
  effectiveCustomFieldFilterKeys,
  effectiveCustomerFormFieldFilterKeys,
  effectiveShippingFormFieldFilterKeys,
  effectiveCustomerCustomAttributesFilterKeys,
  filterLogicOperator = 'AND',
) => {
  let filterTerms = []
  if (effectiveFilterKeys.length) {
    filterTerms = effectiveFilterKeys.map(filterKey => (
      (`${filterKey}:` + (
        filters[filterKey].length > 1 ? (
          '(' + filters[filterKey].map(val => {
              return (val.startsWith('*') && val.endsWith('*'))
                ? val.replace(/\n/g, '')
                : '"' + val.replace(/'/g, "\\'").replace(/\n/g, '') + '"'
          }).join(' OR ') + ')'
        ) : (filters[filterKey][0].startsWith('*') && filters[filterKey][0].endsWith('*'))
          ? filters[filterKey][0].replace(/\n/g, '')
          : `"${filters[filterKey][0].replace(/'/g, "\\'").replace(/\n/g, '')}"`
      )).replace(/"\[\* TO \*\]"/g, '[* TO *]')
    ))
  }
  if (effectiveOptionFilterKeys.length) {
    filterTerms = [
      ...filterTerms,
      ...effectiveOptionFilterKeys.map(filterKey => (
        `${filterKey.startsWith('-') ? '-' : ''}options:` + (
          filters.options[filterKey].length > 1 ? (
            '(' + filters.options[filterKey].map(val => constructSecondaryFilterSearchTerm(filterKey, val)).join(' OR ') + ')'
          ) : constructSecondaryFilterSearchTerm(filterKey, filters.options[filterKey][0])
        )
      )),
    ]
  }
  if (effectiveCustomFieldFilterKeys.length) {
    filterTerms = [
      ...filterTerms,
      ...effectiveCustomFieldFilterKeys.map(filterKey => (
        `${filterKey.startsWith('-') ? '-' : ''}custom_fields:` + (
          filters.custom_fields[filterKey].length > 1 ? (
            '(' + filters.custom_fields[filterKey].map(val => constructSecondaryFilterSearchTerm(filterKey, val)).join(' OR ') + ')'
          ) : constructSecondaryFilterSearchTerm(filterKey, filters.custom_fields[filterKey][0])
        )
      )),
    ]
  }
  if (effectiveCustomerFormFieldFilterKeys.length) {
    filterTerms = [
      ...filterTerms,
      ...effectiveCustomerFormFieldFilterKeys.map(filterKey => (
        `${filterKey.startsWith('-') ? '-' : ''}customer_form_fields:` + (
          filters.customer_form_fields[filterKey].length > 1 ? (
            '(' + filters.customer_form_fields[filterKey].map(val => constructSecondaryFilterSearchTerm(filterKey, val)).join(' OR ') + ')'
          ) : constructSecondaryFilterSearchTerm(filterKey, filters.customer_form_fields[filterKey][0])
        )
      )),
    ]
  }
  if (effectiveShippingFormFieldFilterKeys.length) {
    filterTerms = [
      ...filterTerms,
      ...effectiveShippingFormFieldFilterKeys.map(filterKey => (
        `${filterKey.startsWith('-') ? '-' : ''}shipping_form_fields:` + (
          filters.shipping_form_fields[filterKey].length > 1 ? (
            '(' + filters.shipping_form_fields[filterKey].map(val => constructSecondaryFilterSearchTerm(filterKey, val)).join(' OR ') + ')'
          ) : constructSecondaryFilterSearchTerm(filterKey, filters.shipping_form_fields[filterKey][0])
        )
      )),
    ]
  }
  if (effectiveCustomerCustomAttributesFilterKeys.length) {
    filterTerms = [
      ...filterTerms,
      ...effectiveCustomerCustomAttributesFilterKeys.map(filterKey => (
        `${filterKey.startsWith('-') ? '-' : ''}customer_custom_attributes:` + (
          filters.customer_custom_attributes[filterKey].length > 1 ? (
            '(' + filters.customer_custom_attributes[filterKey].map(val => constructSecondaryFilterSearchTerm(filterKey, val)).join(' OR ') + ')'
          ) : constructSecondaryFilterSearchTerm(filterKey, filters.customer_custom_attributes[filterKey][0])
        )
      )),
    ]
  }
  return filterTerms.join(` \\${filterLogicOperator}\\ `)
}

const constructSortQuery = newSortOrder => {
  if (newSortOrder && newSortOrder.hasOwnProperty('sortBy') && newSortOrder.hasOwnProperty('sortAsc')) {
    return `${newSortOrder.sortBy} ${newSortOrder.sortAsc ? 'asc' : 'desc'}`
  }
  else return ''
}

const checkInsuredOrders = async (orderNumbers) => {
  const { data } = await axios.post(
    `${shippingUrl}/retrieve_shipping_insured_orders`,
    {
      orderNumbers: orderNumbers,
    },
    getAxiosDefaultConfig()
  );

  return data;
};

const checkLabeledOrders = async (orderNumbers) => {
  const { data } = await axios.post(
    `${shippingUrl}/retrieve_shipping_labeled_orders`,
    {
      orderNumbers: orderNumbers,
    },
    getAxiosDefaultConfig()
  );

  return data;
};