import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import _ from 'lodash'
import { feedsUrl } from 'urls'
import { getAxiosDefaultConfig } from '../../modules/utils'

export const initialState = {
  feedonomics: { account: {} },
  datafeedwatch: { account: {} },
  google_merchant_center: { account: {} },
}

export const fetchFeedsAccount = createAsyncThunk('feeds/fetchFeedsAccount', async (channel) => {
  try {
    let res = await axios.get(`${feedsUrl}?channel=${channel}`, getAxiosDefaultConfig())
    return { channel, data: res?.data }
  }
  catch(error) {
    console.error(error)
    return { channel, error }
  }
})

export const createFeedsAccount = createAsyncThunk('feeds/createFeedsAccount', async ({ channel, data }) => {
  try {
    let res = await axios.post(`${feedsUrl}?channel=${channel}`, data, getAxiosDefaultConfig())
    return { channel, data: res?.data }
  }
  catch(error) {
    console.error(error)
    return { channel, error }
  }
})

export const updateFeedsAccount = createAsyncThunk('feeds/updateFeedsAccount', async ({ channel, data }) => {
  try {
    let res = await axios.post(`${feedsUrl}?channel=${channel}`, data, getAxiosDefaultConfig())
    return { channel, data: res?.data }
  }
  catch(error) {
    console.error(error)
    return { channel, error }
  }
})

export const feedSlice = createSlice({
  name: 'feeds',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      /**
       *
       * Feeds extra reducers
       *
       */
      .addCase(fetchFeedsAccount.pending, (state, {payload}) => {
        state.status = 'loading'
      })
      .addCase(fetchFeedsAccount.fulfilled, (state, {payload}) => {
        const { channel, data } = payload
        state.status = 'fulfilled'
        state[channel].account.data = data
      })
      .addCase(fetchFeedsAccount.rejected, (state, {payload}) => {
        const { channel, data } = payload
        state.status = 'failed'
        state[channel].account.error = data?.error || 'Error while fetching feed configuration'
      })
      .addCase(createFeedsAccount.fulfilled, (state, {payload}) => {
        const { channel, data } = payload
        state[channel].account.data = data
      })
      .addCase(createFeedsAccount.rejected, (state, {payload}) => {
        const { channel, error } = payload
        state.status = 'failed'
        state[channel].account.error = error || 'Error while updating feed configuration'
      })
      .addCase(updateFeedsAccount.fulfilled, (state, {payload}) => {
        const { channel, data } = payload
        state[channel].account.data = data
      })
      .addCase(updateFeedsAccount.rejected, (state, {payload}) => {
        const { channel, error } = payload
        state.status = 'failed'
        state[channel].account.error = error || 'Error while updating feed configuration'
      })
  },
})

export const {} = feedSlice.actions

/**
 *
 * Selectors
 *
 */
export const selectFeedsStatus = (state) => state.feeds.status || 'idle'
export const selectFeedsError = (state) => state.feeds[state.feeds.selectedChannel].account?.error || null
export const selectFeedsConfig = (state) => state.feeds[state.feeds.selectedChannel].account?.data || null

export default feedSlice.reducer