
export default function reducer(state={
    userLinkedAccounts: {
      type: "accounts",
      data: {
        details: {
          email: null,
          is_admin: false,
          is_inkfrog: false
        },
        linked_accounts: []
      }
    },
    fetching: false,
    fetched: false,
  }, action) {

    switch (action.type) {
      case "FETCH_USER_LINKED_ACCOUNTS": {
        return {...state, fetching: true}
      }
      case "FETCH_USER_LINKED_ACCOUNTS_REJECTED": {
        return {...state, fetching: false, error: action.payload}
      }
      case "FETCH_USER_LINKED_ACCOUNTS_FULFILLED": {
        return {
          ...state,
          fetching: false,
          fetched: true,
          userLinkedAccounts: action.payload,
        }
      }
    }

    return state
}