import React from 'react'

class ErrorBoundary extends React.Component {
  state = {
    hasError: false
  }

  componentDidCatch(error, info) {
    this.setState({ hasError: true });
  }

  render() {
    const errorMessage = this.props.message || 'Something went wrong.'
    if (this.state.hasError) {
      if(this.props.suppress) return null
      return <div className="empty-message">{errorMessage}</div>
    }
    return <React.Fragment>{this.props.children || null}</React.Fragment>;
  }
}

export default ErrorBoundary