import { filterConfigs } from 'modules/reportConfigs'
import { proCustReportKeys, reportKeys, initialSortOrders } from 'modules/reportConfigs'
import { utmDataTypes, reduxKeySuffix } from 'modules/constants'
import { localStoragePayhelm, getInitialTrendKeys, getQueryJson, } from 'modules/utils'
import queryString from 'query-string'

const viewList = ['overview', 'orders', 'products', 'customer', 'marketing', 'map']
const { view, report } = queryString.parse(location.search)
const viewIndex = location.pathname === '/dashboard' ? viewList.indexOf(view || 'overview') : 0

const queryJson = getQueryJson()
export const initialState = {
  viewList,
  viewIndex: viewIndex < 0 ? 0 : viewIndex,
  report,
  orders: [],
  shippedOrders: [],
  awaitingFulfillmentOrders: [],
  marketing: { stats: {}, currencies: [] },
  currencies: ['USD'],
  stats: {},
  facets: {},
  trendKeys: getInitialTrendKeys(),
  trends: [],
  trendsHour: [],
  trendsGroupBy: localStoragePayhelm.dashboard_overview_group_by || 'Day',
  prevStats: [],
  prevFacets: [],
  prevTrends: [],
  prevTrendsHour: [],
  prevRanges: [],
  prevRangeIndex: 0,
  fetching: Object.assign({}, ...proCustReportKeys.concat(['order', 'trend', 'marketing', 'product']).map(key => ({ [key]: false }))),
  dataPending: Object.assign({}, ...proCustReportKeys.concat(['order', 'trend', 'marketing']).map(key => ({ [key]: true }))),
  filters: Object.keys(filterConfigs).reduce((acc, val) => {
    return {
      ...acc,
      [val]: [null],
      [`-${val}`]: [null],
    }
  }, {
    keywords: [null],
    ['-keywords']: [null],
    options: {},
    custom_fields: {},
    customer_form_fields: {},
    shipping_form_fields: {},
    customer_custom_attributes: {},
  }),
  filterLogicOperator: queryJson.join || localStoragePayhelm.filterLogicOperator || 'AND',
  filterVisible: localStoragePayhelm?.filterVisible ? JSON.parse(localStoragePayhelm.filterVisible) : true,
  categoryTree: {
    expanded: []
  },
  // Order tab configs
  ordersPage: 1,
  ordersPerPage: 20,
  orderSortOrder: initialSortOrders.orderSortOrder,
  // Other data tabs' configs
  productStats: {},
  variantStats: {},
  brandStats: {},
  skuStats: {},
  customerStats: {},
  ...([...proCustReportKeys, ...utmDataTypes]).reduce((acc, k) => ({
    ...acc,
    [`${k}s`]: [],
    [`${k}${reduxKeySuffix.page}`]: 1,
    [`${k}${reduxKeySuffix.perPage}`]: 20,
    [`${k}${reduxKeySuffix.sortOrder}`]: initialSortOrders[`${k}${reduxKeySuffix.sortOrder}`] || { sortBy: 'total_sales', sortAsc: false },
  }), {}),
  // Misc
  forceSearch: { flag: false, soft: false },
  mapSortOrder: initialSortOrders.mapSortOrder,
  mapDrillDown: null,
  configModalOpen: false,
  savingConfig: false,
  reportKey: reportKeys[0],
  fullSearchStatus: {
    recordName: 'order',
    state: null, // 'in-progress' or 'failed' or null
    total: null, // total count of records
    fetched: null, // fetched count of records
    message: null,
    context: null, // null as report fetching by default, others are like "CSV exporting"
  },
  progressBar: null,
}

export default function reducer(state = initialState, action) {
  switch(action.type) {
    case "SET_VIEW_DASHBOARD": {
      return {
        ...state,
        viewIndex: action.payload,
      }
    }
    case 'UPDATE_SEARCH_RESULTS_DASHBOARD': {
      return {
        ...state,
        orders: action.payload.orders || state.orders,
        shippedOrders: action.payload.shippedOrders || state.shippedOrders,
        awaitingFulfillmentOrders: action.payload.awaitingFulfillmentOrders || state.awaitingFulfillmentOrders,
        productStats: action.payload.productStats || state.productStats,
        variantStats: action.payload.variantStats || state.variantStats,
        brandStats: action.payload.brandStats || state.brandStats,
        skuStats: action.payload.skuStats || state.skuStats,
        customerStats: action.payload.customerStats || state.customerStats,
        marketing: action.payload.marketing || state.marketing,
        stats: action.payload.stats || state.stats,
        facets: action.payload.facets || state.facets,
        trends: action.payload.trends || state.trends,
        trendsHour: action.payload.trendsHour || state.trendsHour,
        prevStats: action.payload.prevStats || state.prevStats,
        prevFacets: action.payload.prevFacets || state.prevFacets,
        prevTrends: action.payload.prevTrends || state.prevTrends,
        prevTrendsHour: action.payload.prevTrendsHour || state.prevTrendsHour,
        prevRanges: action.payload.prevRanges || state.prevRanges,
        currencies: action.payload.currencies || state.currencies,
        ...[...proCustReportKeys, ...utmDataTypes].reduce((acc, k) => ({
          ...acc,
          [`${k}s`]: action.payload[`${k}s`] || state[`${k}s`]
        }), {})
      }
    }
    case 'UPDATE_FETCH_STATUS_DASHBOARD': {
      return {
        ...state,
        fetching: action.payload === null ? initialState.fetching : {
          ...state.fetching,
          ...action.payload,
        },
      }
    }
    case 'UPDATE_DATA_PENDING_DASHBOARD': {
      return {
        ...state,
        dataPending: {
          ...state.dataPending,
          ...action.payload,
        },
      }
    }
    case 'RESET_DATA_PENDING_DASHBOARD': {
      return {
        ...state,
        dataPending: initialState.dataPending,
      }
    }
    case 'UPDATE_FILTER_DASHBOARD': {
      return {
        ...state,
        filters: {
          ...(action.hard ? initialState.filters : state.filters),
          ...action.payload,
          options: {
            ...state.filters.options,
            ...action.payload.options,
          },
          custom_fields: {
            ...state.filters.custom_fields,
            ...action.payload.custom_fields,
          },
          customer_form_fields: {
            ...state.filters.customer_form_fields,
            ...action.payload.customer_form_fields,
          },
          shipping_form_fields: {
            ...state.filters.shipping_form_fields,
            ...action.payload.shipping_form_fields,
          },
          customer_custom_attributes: {
            ...state.filters.customer_custom_attributes,
            ...action.payload.customer_custom_attributes,
          },
        },
      }
    }
    case 'UPDATE_FILTER_VALUE_DASHBOARD': {
      const [ filterKey ] = Object.keys(action.payload)
      const filterValue = action.payload[filterKey]
      const itemIndex = state.filters[filterKey].indexOf(filterValue)
      let nextState = state
      if (itemIndex !== -1) {
        nextState.filters[filterKey].splice(itemIndex, 1)
        if (nextState.filters[filterKey].length === 0) { nextState.filters[filterKey] = [null] }
      } else {
        if (nextState.filters[filterKey].includes(null)) { nextState.filters[filterKey] = [filterValue] }
        else { nextState.filters[filterKey].push(filterValue) }
      }
      return {
        ...state,
        filters: {
          ...state.filters,
          [filterKey]: nextState.filters[filterKey],
        },
      }
    }
    case 'REPLACE_FILTER_VALUE_DASHBOARD': {
      const [ filterKey ] = Object.keys(action.payload)
      const filterValue = action.payload[filterKey]
      const newFilterValue = action.payload.newValue
      const itemIndex = state.filters[filterKey].indexOf(filterValue)
      let nextState = state
      if (itemIndex !== -1) {
        nextState.filters[filterKey].splice(itemIndex, 1, newFilterValue)
      } else {
        nextState.filters[filterKey].push(newFilterValue)
      }
      return {
        ...state,
        filters: {
          ...state.filters,
          [filterKey]: nextState.filters[filterKey],
        },
      }
    }
    case 'UPDATE_FILTER_EXPAND_CATEGORY_TREE_DASHBOARD': {
      return {
        ...state,
        categoryTree: {
          expanded: [...action.payload]
        }
      }
    }
    case 'UPDATE_FILTER_LOGIC_OPERATOR_DASHBOARD': {
      localStoragePayhelm.filterLogicOperator = action.payload
      return {
        ...state,
        filterLogicOperator: action.payload,
      }
    }
    case 'RESET_FILTER_DASHBOARD': {
      return {
        ...state,
        filters: {
          ...initialState.filters,
          ...action.payload,
        },
      }
    }
    case 'TOGGLE_FILTER_DASHBOARD': {
      localStoragePayhelm.filterVisible = !state.filterVisible
      return {
        ...state,
        filterVisible: !state.filterVisible
      }
    }
    case 'SET_DATA_PAGE_DASHBOARD': {
      return {
        ...state,
        [`${action.payload.dataType}sPage`]: action.payload.page,
      }
    }
    case 'SET_DATA_PER_PAGE_DASHBOARD': {
      return {
        ...state,
        [`${action.payload.dataType}sPerPage`]: action.payload.perPage,
      }
    }
    case 'SET_DATA_SORT_ORDER_DASHBOARD': {
      return {
        ...state,
        [`${action.payload.dataType}SortOrder`]: action.payload.sortOrder,
      }
    }
    case 'SET_FORCE_SEARCH_DASHBOARD': {
      return {
        ...state,
        forceSearch: action.payload,
      }
    }
    case 'SET_MAP_SORT_ORDER_DASHBOARD': {
      return {
        ...state,
        mapSortOrder: action.payload,
      }
    }
    case 'SET_MAP_DRILL_DOWN_DASHBOARD': {
      return {
        ...state,
        mapDrillDown: action.payload,
      }
    }
    case 'SET_REPORT_KEY_DASHBOARD': {
      return {
        ...state,
        reportKey: action.payload,
      }
    }
    case 'SET_REPORT_DASHBOARD': {
      return {
        ...state,
        report: action.payload,
      }
    }
    case 'SET_TRENDS_GROUP_BY_DASHBOARD': {
      return {
        ...state,
        trendsGroupBy: action.payload,
      }
    }
    case 'SET_TREND_KEYS_DASHBOARD': {
      return {
        ...state,
        trendKeys: action.payload
      }
    }
    case 'SET_CONFIG_MODAL_OPEN_DASHBOARD': {
      return {
        ...state,
        configModalOpen: action.payload,
      }
    }
    case 'SET_SAVING_CONFIG_DASHBOARD': {
      return {
        ...state,
        savingConfig: action.payload,
      }
    }
    case 'SET_FULL_SEARCH_STATUS_DASHBOARD': {
      return {
        ...state,
        fullSearchStatus: {
          ...state.fullSearchStatus,
          ...action.payload,
        }
      }
    }
    case 'SET_PROGRESS_BAR_DASHBOARD': {
      return {
        ...state,
        progressBar: action.payload,
      }
    }
  }
  return state
}
