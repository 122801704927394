import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { baseUrl } from 'urls'

export const googleAdsApi = createApi({
  reducerPath: 'googleAdsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${baseUrl}/`,
    prepareHeaders: (headers, { getState }) => {
      headers.set('session-signature', getState().root.sessionSignature)
      headers.set('Access-Control-Allow-Origin', '*')
      return headers
    },
    credentials: 'include'
  }),
  tagTypes: ['Get'],
  endpoints: (builder) => ({
    getCampaigns: builder.query({
      query: ({startDate, endDate}) => ({ url: `google/ads?start_date=${startDate}&end_date=${endDate}` }),
    }),
    getUtm: builder.query({
      query: () => ({
        url: 'google/utmAds'
      })
    })
  }),
});

export const { useGetCampaignsQuery, useGetUtmQuery } = googleAdsApi;