import React from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'

export default function TrafficChart({ xAxis, yAxis, series, metric }) {
  const options = {
    title: {
      text: '',
    },
    xAxis,
    yAxis,
    tooltip: {
      valueSuffix: '',
      formatter: function () {
        return `<span style="font-size: 10px; color: #64748b;">${this.x}</span><br />${this.series.name}: <b>${
          metric.includes('sales') ? 'US$' + this.y.toLocaleString() : this.y.toLocaleString()
        }</b>`
      },
    },
    legend: {
      layout: 'horizontal',
      align: 'center',
      verticalAlign: 'top',
      borderWidth: 0,
      showInLegend: false,
      itemWidth: 200,
    },
    series,
  }

  return <HighchartsReact highcharts={Highcharts} options={options} />
}
