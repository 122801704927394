import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import { Button } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import Alert from 'react-bootstrap/Alert'
import { useDispatch, useSelector } from 'react-redux'
import { closeConnectionModal } from '../../integrationsSlice'
import { linkFromChannel } from '../../../../modules/account'

export default function GoogleConnectionModal({ }) {
  const dispatch = useDispatch()
  const closeModal = () => {
    return dispatch(closeConnectionModal())
  }

  const connect = () => {
    linkFromChannel('google_eco')
  }

  return (
    <div className="tw-p-6">
      <h2 className="tw-text-xl tw-mb-6">Agree To Google's Term</h2>
      {/* {error && <Alert variant={'danger'}>Invalid policy id and email combination</Alert>} */}
      <p>
        PayHelm's use and transfer of information received from Google APIs to any other app will adhere to <a href="https://developers.google.com/terms/api-services-user-data-policy#additional_requirements_for_specific_api_scopes">Google API Services User Data Policy</a>, including the Limited Use requirements.
      </p>
      <div className="tw-flex tw-items-center tw-justify-end tw-space-x-2">
          <Button variant="secondary" onClick={closeModal}>
            Cancel
          </Button>
          <Button onClick={connect}>
            Connect
          </Button>
        </div>
    </div>
  )
}
