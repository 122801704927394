import React from 'react'
import { Row } from 'react-bootstrap'
import { DEFAULT_HOME_URL, DEFAULT_COPYRIGHT } from 'modules/constants'

const Footer = ({ reportTimeCost }) => (
  <Row>
    <div className="copyright">
      <p>
        <a href={DEFAULT_HOME_URL} target="_blank">{DEFAULT_COPYRIGHT}</a>
        <span className='xs-visible'>&nbsp;|&nbsp;</span>
        <br className='xs-invisible'/>
        <a href="https://www.payhelm.com/terms-of-service/" target="_blank">Terms of Use</a>
        &nbsp;|&nbsp;
        <a href="https://www.payhelm.com/privacy-policy/" target="_blank">Privacy Policy</a>
      </p>
      {
        reportTimeCost !== null ? (
          <div className="font-size-smaller" style={{ color: 'grey' }}>
            &nbsp;{'Report generated in ' + (reportTimeCost / 1000).toFixed(1) + ' second(s)'}
          </div>
        ) : null
      }
    </div>
  </Row>
)

export default Footer
