import React from 'react'
import { Modal } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { selectRootAccounts, selectRootUser } from '../../../rootSlice'
import { selectCurrentChannel } from '../integrationsSlice'
import { useSettingsModalContent } from './hooks'

export default function SettingsModal({ visible, closeModal, retrieveAccounts }) {
  /**
   *
   * Handle accounts
   *
   */
  const user = useSelector(selectRootUser)
  const rootAccounts = useSelector(selectRootAccounts)
  const accounts = rootAccounts.map((_account) => ({ ..._account, isOwner: user.email === _account.owner }))
  const currentChannel = useSelector(selectCurrentChannel)
  const currentChannelName = currentChannel?.channel
  const account = accounts.find((account) => account.channel === currentChannelName)

  const { content } = useSettingsModalContent({ account, accounts, retrieveAccounts, channel: currentChannel })

  return (
    <Modal show={visible} onHide={closeModal} size="lg">
      <Modal.Body>
        <div className="tw-p-6">
          <h2 className="tw-text-xl tw-mb-6">{currentChannelName} Settings</h2>
          {content}
        </div>
      </Modal.Body>
    </Modal>
  )
}
