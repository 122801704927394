import { getCookie, setCookie } from 'cookie'
import hosts from 'hosts.json'

const cookieNames = ['name', 'paypal_user_id', 'paypal_hash_token', 'ebay_session_id', 'unique_user_id', 'app_token']

export const getLoginInfo = () => {
  return Object.assign(
    {},
    ...cookieNames.map((name) => {
      return { [name]: getCookie(name) }
    })
  )
}

export const redirectHomepage = (message, messageType, page) => {
  if (process.env.NODE_ENV !== 'development') {
    const { hostname } = location
    cookieNames.forEach((name) => {
      setCookie(name, '')
    })
    if (message && messageType) {
      setCookie('loginMessage', message, 1000 * 60)
      setCookie('loginMessageType', messageType, 1000 * 60)
    }
    window.location = hosts.apiHost[hostname] + (page || '/login')
  }
}

export const redirectLogin = ({ redirect = true, message = null, messageType = null, loginError = null } = {}) => {
  const redirectPath = window.location.pathname + window.location.search
  let queryParams = []
  if (redirect && redirectPath !== '/') {
    queryParams.push(`redirect=${encodeURIComponent(redirectPath)}`)
  }
  if (loginError) {
    queryParams.push(
      `pageError=${encodeURIComponent(
        'An error occurred during sign in. Please contact support if this issue persists.'
      )}`
    )
  }
  const page = '/login' + (queryParams.length ? '?' + queryParams.join('&') : '')
  redirectHomepage(message, messageType, page)
}
