import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import moment from 'moment-timezone'
import {
  trafficCartsReportUrl,
  trafficUniqueFieldValuesUrl,
  trafficMetricsUrl,
  googleAdsReportingUrl,
  googleAdsMetricsUrl,
  googleAdsMetricsSummaryUrl,
  fbAdsMetricsSummaryUrl,
  klaviyoAdsMetricsSummaryUrl,
} from '@/modules/urls'
import { getAxiosDefaultConfig } from '@/modules/utils'
import { DEFAULT_TIME_PERIOD } from './constants'
import { set } from 'lodash'
import { setPage } from '@/actions/trafficActions'
import { buildGaqlQuery } from './utils'

export const initialState = {
  trafficReport: {},
  currentTab: 'overview',
  tabPagination: {},
  tabTotalPages: {},
  metric: {},
  googleAdsReporting: {},
  googleAdsMetricsSummary: {},
  googleAdsMetrics: {},
  googleAdsAuditData: {},
  fbAdsMetricsSummary: {},
  fbAdsAuditData: {},
  klaviyoAdsMetricsSummary: {},
  klaviyoAdsAuditData: {},
  uniqueFieldValues: {},
  selectedRows: {},
  metricTableColumns: [],
  metricTableRawData: [],
  metricTableRows: [],
}

const timePeriodToMinutes = (timePeriod) => {
  if (timePeriod === 'today') return 'today'
  if (timePeriod === 'yesterday') return 'yesterday'

  const timePeriodPair = timePeriod.split('-')
  return moment.duration(timePeriodPair[0], timePeriodPair[1]).asMinutes()
}

export const fetchUniqueFieldValues = createAsyncThunk('trafficReport/fetchUniqueFieldValues', async (payload) => {
  const { fieldName } = payload
  const reqUrl = `${trafficUniqueFieldValuesUrl}/${fieldName}/?timePeriod=${encodeURIComponent(
    payload?.timePeriod
  )}&timezone=${encodeURIComponent(payload?.timezone)}`
  const res = await axios.get(reqUrl, getAxiosDefaultConfig())
  return res?.data
})

export const fetchTrafficReport = createAsyncThunk(
  'trafficReport/fetchTrafficReport',
  async (payload, { getState, dispatch }) => {
    const { timePeriod = DEFAULT_TIME_PERIOD, tabName, pagination = { pageIndex: 1, pageSize: 20 }, filters } = payload

    const timestamp = new Date().getTime()
    const timeQuery = timePeriodToMinutes(timePeriod)

    const page = pagination.pageIndex
    const limit = pagination.pageSize
    const {
      user: { default_timezone: timezone },
    } = getState().root

    let requestUrl = `${trafficCartsReportUrl}?timestamp=${timestamp}&page=${page}&limit=${limit}&timezone=${timezone}`

    if (tabName) {
      requestUrl += `&tab=${tabName}`
    }

    const todayTimestamp = moment.tz(timezone).hour(0).minute(0).second(0).millisecond(0).toDate().getTime()
    if (timeQuery === 'today') {
      requestUrl += `&start_timestamp=${todayTimestamp}&today_timestamp=${todayTimestamp}&by_day=true`
    } else if (timeQuery === 'yesterday') {
      const startTimestamp = (moment.tz(timezone).hour(0).minute(0).second(0).millisecond(0).subtract(1, 'day').toDate()).getTime()
      const endTimestamp = (moment.tz(timezone).hour(23).minute(59).second(59).millisecond(999).subtract(1, 'day').toDate()).getTime()
      requestUrl += `&start_timestamp=${startTimestamp}&end_timestamp=${endTimestamp}&today_timestamp=${todayTimestamp}&by_day=true`

    } else {
      requestUrl += `&today_timestamp=${todayTimestamp}&min_ago=${timeQuery}`
    }

    const res = await axios.post(requestUrl, { filters }, getAxiosDefaultConfig())

    // Also update the tabPagination state
    if (tabName) {
      dispatch(setTabPagination({ [tabName]: pagination }))
    }

    return res?.data
  }
)

export const fetchTrafficMetric = createAsyncThunk(
  'trafficReport/fetchTrafficMetric',
  async (payload, { getState }) => {
    const { metric, timePeriod = DEFAULT_TIME_PERIOD, startTimestamp, endTimestamp, filters, groupBy } = payload

    const {
      user: { default_timezone: timezone },
    } = getState().root

    const reqUrl = `${trafficMetricsUrl}/${metric}`
    const reqPayload = {
      timePeriod,
      startTimestamp,
      endTimestamp,
      filters,
      groupBy,
      timezone,
    }

    const res = await axios.post(reqUrl, reqPayload, getAxiosDefaultConfig())

    return res?.data

  }
)

export const fetchGoogleAdsReporting = createAsyncThunk(
  'trafficReport/fetchGoogleAdsReporting',
  async (payload, { getState }) => {
    const {
      user: { default_timezone: timezone },
    } = getState().root

    const { select, from, where, limit, timePeriod = DEFAULT_TIME_PERIOD } = payload

    const query = buildGaqlQuery({ select, from, where, limit, timePeriod })

    const reqUrl = `${googleAdsReportingUrl}`
    const res = await axios.post(reqUrl, { query }, getAxiosDefaultConfig())
    return res?.data
  }
)

export const fetchGoogleAdsMetricsSummary = createAsyncThunk(
  'trafficReport/fetchGoogleAdsMetricsSummary',
  async (payload, { getState, dispatch }) => {
    const {
      user: { default_timezone: timezone },
    } = getState().root

    const { timePeriod, filters, limit = 20 } = payload

    const reqUrl = `${googleAdsMetricsSummaryUrl}`
    const res = await axios.post(
      reqUrl,
      {
        timePeriod,
        timezone,
        filters,
        limit,
      },
      getAxiosDefaultConfig()
    )
    // console.log('fetchGoogleAdsMetricsSummary res?.data', res?.data)

    // Set the audit data
    const auditData = res?.data?.auditData
    if (auditData) {
      dispatch(setGoogleAdsAuditData(auditData))
    }

    return res?.data?.mergedData
  }
)

export const fetchGoogleAdsMetrics = createAsyncThunk(
  'trafficReport/fetchGoogleAdsMetrics',
  async (payload, { getState }) => {
    const {
      user: { default_timezone: timezone },
    } = getState().root

    const { timePeriod, filters, groupBy, limit = 20 } = payload

    const reqUrl = `${googleAdsMetricsUrl}`
    const res = await axios.post(
      reqUrl,
      {
        timePeriod,
        timezone,
        filters,
        groupBy,
        limit,
      },
      getAxiosDefaultConfig()
    )
    return res?.data
  }
)

export const fetchFbAdsMetricsSummary = createAsyncThunk(
  'trafficReport/fetchFbAdsMetricsSummary',
  async (payload, { getState, dispatch }) => {
    const {
      user: { default_timezone: timezone },
    } = getState().root

    const { timePeriod, filters, limit = 20 } = payload

    const reqUrl = `${fbAdsMetricsSummaryUrl}`
    const res = await axios.post(
      reqUrl,
      {
        timePeriod,
        timezone,
        filters,
        limit,
      },
      getAxiosDefaultConfig()
    )
    // console.log('fetchFbAdsMetricsSummary res?.data', res?.data)

    // Set the audit data
    const auditData = res?.data?.auditData
    if (auditData) {
      dispatch(setFbAdsAuditData(auditData))
    }

    return res?.data?.mergedData
  }
)

export const fetchKlaviyoAdsMetricsSummary = createAsyncThunk(
  'trafficReport/fetchKlaviyoAdsMetricsSummary',
  async (payload, { getState, dispatch }) => {
    const {
      user: { default_timezone: timezone },
    } = getState().root

    const { timePeriod, filters, limit = 20 } = payload

    const reqUrl = `${klaviyoAdsMetricsSummaryUrl}`
    const res = await axios.post(
      reqUrl,
      {
        timePeriod,
        timezone,
        filters,
        limit,
      },
      getAxiosDefaultConfig()
    )
    // console.log('fetchFbAdsMetricsSummary res?.data', res?.data)

    // Set the audit data
    const auditData = res?.data?.auditData
    if (auditData) {
      dispatch(setKlaviyoAdsAuditData(auditData))
    }

    return res?.data?.mergedData
  }
)

export const trafficReportSlice = createSlice({
  name: 'trafficReport',
  initialState,
  reducers: {
    setCurrentTab: (state, action) => {
      state.currentTab = action.payload
    },
    setTabPagination: (state, action) => {
      state.tabPagination = { ...state.tabPagination, ...action.payload }
    },
    setTabTotalPages: (state, action) => {
      state.tabTotalPages = action.payload
    },
    setSelectedRows: (state, action) => {
      // For the metric data table row selection
      state.selectedRows = action.payload
    },
    resetFetchTrafficReportStatus: (state) => {
      state.trafficReport.status = 'idle'
    },
    resetFetchTrafficMetricsStatus: (state) => {
      state.metric.status = 'idle'
    },
    setMetricTableColumns: (state, action) => {
      state.metricTableColumns = action.payload
    },
    setMetricTableRawData: (state, action) => {
      state.metricTableRawData = action.payload
    },
    setMetricTableRows: (state, action) => {
      state.metricTableRows = action.payload
    },
    setGoogleAdsAuditData: (state, action) => {
      state.googleAdsAuditData = action.payload
    },
    resetFetchGoogleAdsMetricsSummaryStatus: (state) => {
      state.googleAdsMetricsSummary.status = 'idle'
    },
    setFbAdsAuditData: (state, action) => {
      state.fbAdsAuditData = action.payload
    },
    resetFetchFbAdsMetricsSummaryStatus: (state) => {
      state.fbAdsMetricsSummary.status = 'idle'
    },
    setKlaviyoAdsAuditData: (state, action) => {
      state.klaviyoAdsAuditData = action.payload
    },
    resetFetchKlaviyoAdsMetricsSummaryStatus: (state) => {
      state.klaviyoAdsMetricsSummary.status = 'idle'
    },
  },
  extraReducers(builder) {
    builder
      /**
       *
       * unique field values api
       *
       */
      .addCase(fetchUniqueFieldValues.pending, (state, action) => {
        const { fieldName, timePeriod } = action.meta.arg
        const stateKey = `${fieldName}--${timePeriod}`
        state.uniqueFieldValues[stateKey] = state.uniqueFieldValues[stateKey] || {}
        state.uniqueFieldValues[stateKey].status = 'loading'
      })
      .addCase(fetchUniqueFieldValues.fulfilled, (state, action) => {
        const { fieldName, timePeriod } = action.meta.arg
        const stateKey = `${fieldName}--${timePeriod}`
        state.uniqueFieldValues[stateKey] = state.uniqueFieldValues[stateKey] || {}
        state.uniqueFieldValues[stateKey].data = action.payload
        state.uniqueFieldValues[stateKey].status = 'succeeded'
      })
      .addCase(fetchUniqueFieldValues.rejected, (state, action) => {
        const { fieldName, timePeriod } = action.meta.arg
        const stateKey = `${fieldName}--${timePeriod}`
        state.uniqueFieldValues[stateKey] = state.uniqueFieldValues[stateKey] || {}
        state.uniqueFieldValues[stateKey].status = 'failed'
        state.uniqueFieldValues[stateKey].error = action.error.message
      })

      /**
       *
       * traffic reports api
       *
       */
      .addCase(fetchTrafficReport.pending, (state, action) => {
        state.trafficReport.status = 'loading'
      })
      .addCase(fetchTrafficReport.fulfilled, (state, action) => {
        const { tabName } = action.meta.arg

        if (tabName) {
          state.trafficReport.data = {
            ...state.trafficReport.data,
            [tabName]: action.payload[tabName],
          }
        } else {
          state.trafficReport.data = action.payload
        }
        state.trafficReport.status = 'succeeded'
      })
      .addCase(fetchTrafficReport.rejected, (state, action) => {
        state.trafficReport.status = 'failed'
        state.trafficReport.error = action.error.message
      })

      /**
       *
       * metric api
       *
       */
      .addCase(fetchTrafficMetric.pending, (state, action) => {
        state.metric.status = 'loading'
      })
      .addCase(fetchTrafficMetric.fulfilled, (state, action) => {
        state.metric.data = action.payload
        state.metric.status = 'succeeded'
      })
      .addCase(fetchTrafficMetric.rejected, (state, action) => {
        state.metric.status = 'failed'
        state.metric.error = action.error.message
      })

      /**
       *
       * google ads api
       *
       */
      .addCase(fetchGoogleAdsReporting.pending, (state, action) => {
        state.googleAdsReporting.status = 'loading'
      })
      .addCase(fetchGoogleAdsReporting.fulfilled, (state, action) => {
        state.googleAdsReporting.data = action.payload
        state.googleAdsReporting.status = 'succeeded'
      })
      .addCase(fetchGoogleAdsReporting.rejected, (state, action) => {
        state.googleAdsReporting.status = 'failed'
        state.googleAdsReporting.error = action.error.message
      })

      /**
       *
       * Google ads metrics summary api
       *
       */
      .addCase(fetchGoogleAdsMetricsSummary.pending, (state, action) => {
        state.googleAdsMetricsSummary.status = 'loading'
      })
      .addCase(fetchGoogleAdsMetricsSummary.fulfilled, (state, action) => {
        state.googleAdsMetricsSummary.data = action.payload
        state.googleAdsMetricsSummary.status = 'succeeded'
      })
      .addCase(fetchGoogleAdsMetricsSummary.rejected, (state, action) => {
        state.googleAdsMetricsSummary.status = 'failed'
        state.googleAdsMetricsSummary.error = action.error.message
      })

      /**
       *
       * Google ads metrics api
      *
      */
     .addCase(fetchGoogleAdsMetrics.pending, (state, action) => {
       state.googleAdsMetrics.status = 'loading'
      })
      .addCase(fetchGoogleAdsMetrics.fulfilled, (state, action) => {
        state.googleAdsMetrics.data = action.payload
        state.googleAdsMetrics.status = 'succeeded'
      })
      .addCase(fetchGoogleAdsMetrics.rejected, (state, action) => {
        state.googleAdsMetrics.status = 'failed'
        state.googleAdsMetrics.error = action.error.message
      })

      /**
       *
       * FB ads metrics summary api
       *
       */
      .addCase(fetchFbAdsMetricsSummary.pending, (state, action) => {
        state.fbAdsMetricsSummary.status = 'loading'
      })
      .addCase(fetchFbAdsMetricsSummary.fulfilled, (state, action) => {
        state.fbAdsMetricsSummary.data = action.payload
        state.fbAdsMetricsSummary.status = 'succeeded'
      })
      .addCase(fetchFbAdsMetricsSummary.rejected, (state, action) => {
        state.fbAdsMetricsSummary.status = 'failed'
        state.fbAdsMetricsSummary.error = action.error.message
      })

      /**
       *
       * Klaviyo ads metrics summary api
       *
       * */
      .addCase(fetchKlaviyoAdsMetricsSummary.pending, (state, action) => {
        state.klaviyoAdsMetricsSummary.status = 'loading'})
      .addCase(fetchKlaviyoAdsMetricsSummary.fulfilled, (state, action) => {
        state.klaviyoAdsMetricsSummary.data = action.payload
        state.klaviyoAdsMetricsSummary.status = 'succeeded'
      })
      .addCase(fetchKlaviyoAdsMetricsSummary.rejected, (state, action) => {
        state.klaviyoAdsMetricsSummary.status = 'failed'
        state.klaviyoAdsMetricsSummary.error = action.error.message
      })
  },
})

export const {
  setCurrentTab,
  setTabPagination,
  setTabTotalPages,
  setSelectedRows,
  resetFetchTrafficReportStatus,
  resetFetchTrafficMetricsStatus,
  setMetricTableColumns,
  setMetricTableRawData,
  setMetricTableRows,
  setGoogleAdsAuditData,
  resetFetchGoogleAdsMetricsSummaryStatus,
  setFbAdsAuditData,
  resetFetchFbAdsMetricsSummaryStatus,
  setKlaviyoAdsAuditData,
  resetFetchKlaviyoAdsMetricsSummaryStatus,
} = trafficReportSlice.actions

/**
 *
 * Selectors
 *
 */
export const selectTrafficUniqueFieldValuesStatus = createSelector(
  [(state) => state.trafficReport.uniqueFieldValues, (state, { fieldName, timePeriod }) => ({ fieldName, timePeriod })],
  (uniqueFieldValues, { fieldName, timePeriod }) => {
    const stateKey = `${fieldName}--${timePeriod}`
    return uniqueFieldValues[stateKey]?.status ? uniqueFieldValues[stateKey].status : 'idle'
  }
)
export const selectTrafficUniqueFieldValues = createSelector(
  [(state) => state.trafficReport.uniqueFieldValues, (state, { fieldName, timePeriod }) => ({ fieldName, timePeriod })],
  (uniqueFieldValues, { fieldName, timePeriod }) => {
    const stateKey = `${fieldName}--${timePeriod}`
    return uniqueFieldValues[stateKey] ? uniqueFieldValues[stateKey].data : null
  }
)

export const selectMetricTableColumns = (state) => state.trafficReport.metricTableColumns
export const selectMetricTableRawData = (state) => state.trafficReport.metricTableRawData
export const selectMetricTableRows = (state) => state.trafficReport.metricTableRows

export const selectTrafficReport = (state) => state.trafficReport.trafficReport.data
export const selectTrafficReportStatus = (state) => state.trafficReport.trafficReport.status || 'idle'
export const selectTrafficReportError = (state) => state.trafficReport.trafficReport.error

export const selectTrafficMetric = (state) => state.trafficReport.metric.data
export const selectTrafficMetricStatus = (state) => state.trafficReport.metric.status || 'idle'
export const selectTrafficMetricError = (state) => state.trafficReport.metric.error

export const selectGoogleAdsReporting = (state) => state.trafficReport.googleAdsReporting.data
export const selectGoogleAdsReportingStatus = (state) => state.trafficReport.googleAdsReporting.status || 'idle'
export const selectGoogleAdsReportingError = (state) => state.trafficReport.googleAdsReporting.error

export const selectGoogleAdsMetricsSummary = (state) => state.trafficReport.googleAdsMetricsSummary.data
export const selectGoogleAdsMetricsSummaryStatus = (state) =>
  state.trafficReport.googleAdsMetricsSummary.status || 'idle'
export const selectGoogleAdsMetricsSummaryError = (state) => state.trafficReport.googleAdsMetricsSummary.error
export const selectGoogleAdsAuditData = (state) => state.trafficReport.googleAdsAuditData

export const selectGoogleAdsMetrics = (state) => state.trafficReport.googleAdsMetrics.data
export const selectGoogleAdsMetricsStatus = (state) => state.trafficReport.googleAdsMetrics.status || 'idle'
export const selectGoogleAdsMetricsError = (state) => state.trafficReport.googleAdsMetrics.error

export const selectFbAdsMetricsSummary = (state) => state.trafficReport.fbAdsMetricsSummary.data
export const selectFbAdsMetricsSummaryStatus = (state) =>
  state.trafficReport.fbAdsMetricsSummary.status || 'idle'
export const selectFbAdsMetricsSummaryError = (state) => state.trafficReport.fbAdsMetricsSummary.error
export const selectFbAdsAuditData = (state) => state.trafficReport.fbAdsAuditData

export const selectKlaviyoAdsMetricsSummary = (state) => state.trafficReport.klaviyoAdsMetricsSummary.data
export const selectKlaviyoAdsMetricsSummaryStatus = (state) =>
  state.trafficReport.klaviyoAdsMetricsSummary.status || 'idle'
export const selectKlaviyoAdsMetricsSummaryError = (state) => state.trafficReport.klaviyoAdsMetricsSummary.error
export const selectKlaviyoAdsAuditData = (state) => state.trafficReport.klaviyoAdsAuditData

export const selectCurrentTab = (state) => state.trafficReport.currentTab
export const selectTabPagination = createSelector(
  [(state) => state.trafficReport.tabPagination, (state, tabName) => tabName],
  (tabPagination, tabName) => tabPagination[tabName] || null
)
export const selectTabTotalPages = (state) => state.trafficReport.tabTotalPages
export const selectSelectedRows = (state) => state.trafficReport.selectedRows

export default trafficReportSlice.reducer
