import React from 'react'
import moment from 'momentExt'
import queryString from 'query-string'
import { setCookie, getCookie } from 'cookie'
import { accountLinkingUrls } from 'urls'
import numeral from 'numeral'
import store from '../app/store'
import { formatters } from 'modules/formatting'
import { dateTypes } from 'modules/constants'

export const saveAsCSV = (
  dataSets = [],
  filename,
  formatterSets = [],
  exportKeySets = [],
  exportTitleSets = [],
  dataSetNames = [],
  returnData = false
) => {
  let results = [],
    headers

  for (var dataIndex in dataSets) {
    let data = dataSets[dataIndex],
      formatters = formatterSets[dataIndex] || {},
      dataSetName = dataSetNames[dataIndex]
    let fields = (exportKeySets[dataIndex] || Object.keys(data[0])).filter((v) => v),
      fieldValue = ''
    let titles = exportTitleSets[dataIndex] || {}
    results[dataIndex] = returnData ? [] : ''
    if (!data.length) {
      continue
    }
    // Process data.
    data.forEach((el) => {
      if (returnData) {
        results[dataIndex].push([])
      }
      fields.forEach((field, index) => {
        fieldValue = ((formatters[field] ? formatters[field](el[field], el.currency, el) : el[field]) || 'N/A')
          .toString()
          .replace(/"/g, '""')

        if (returnData) {
          results[dataIndex][results[dataIndex].length - 1].push('"' + fieldValue + '"')
        }
        else {
          results[dataIndex] += '"' + fieldValue + '"'
          if (index < fields.length - 1) {
            results[dataIndex] += ','
          }
        }
      })
      if (!returnData) {
        results[dataIndex] += '\n'
      }
    })

    // Set headers.
    headers = fields.map((field) => titles[field] || field)
    if (!returnData) {
      headers = headers.join(',')
    }

    // Ready data for reading.
    if (returnData) {
      results[dataIndex] = results[dataIndex] ? {
        name: dataSetName,
        headers,
        data: results[dataIndex]
      } : null
    }
    else {
      results[dataIndex] = (dataSetName ? `${dataSetName}\n` : '') + headers + '\n' + results[dataIndex]
    }
  }

  let result = results.filter((v) => v)
  if (returnData) {
    return result
  }
  result = result.join('\n\n')
  let a = document.createElement('a')
  document.body.appendChild(a)
  a.style = 'display: none'

  let blob = new Blob([result], { type: 'text/csv;charset=UTF-8' }),
    url = window.URL.createObjectURL(blob)

  a.href = url
  a.download = filename + '_' + (Date.now() ? Date.now().toString() : '') + '.csv'
  a.click()
  window.URL.revokeObjectURL(url)
  a.remove()
}

export const saveAsGoogleDrive = async (
  dataSets = [],
  filename,
  formatterSets = [],
  exportKeySets = [],
  exportTitleSets = [],
  dataSetNames = []
) => {
  let results = [], headers

  for (var dataIndex in dataSets) {
    let data = dataSets[dataIndex],
      formatters = formatterSets[dataIndex] || {},
      dataSetName = dataSetNames[dataIndex]
    let fields = (exportKeySets[dataIndex] || Object.keys(data[0])).filter((v) => v),
      fieldValue = ''
    let titles = exportTitleSets[dataIndex] || {}
    results[dataIndex] = ''
    if (!data.length) {
      continue
    }
    // Process data.
    data.forEach((el) => {
      fields.forEach((field, index) => {
        fieldValue = ((formatters[field] ? formatters[field](el[field], el.currency, el) : el[field]) || 'N/A')
          .toString()
          .replace(/"/g, '""')

        results[dataIndex] += '"' + fieldValue + '"'

        if (index < fields.length - 1) {
          results[dataIndex] += ','
        }
      })
      results[dataIndex] += '\n'
    })

    // Set headers.
    headers = fields.map((field) => titles[field] || field).join(',')

    // Ready data for reading.
    results[dataIndex] = (dataSetName ? `${dataSetName}\n` : '') + headers + '\n' + results[dataIndex]
  }

  let result = results.filter((v) => v).join('\n\n')

  let blob = new Blob([result], { type: 'text/csv;charset=UTF-8' })
  const formData = new FormData();
  const fileName = filename + '_' + (Date.now() ? Date.now().toString() : '') + '.csv'
  formData.append('file', blob, fileName)

  return formData
}


export const updateUrlWithView = (viewIndex, viewList, appName) => {
  const search = queryString.stringify({
    ...queryString.parse(location.search),
    view: viewList[viewIndex],
  })
  history.replaceState('', '', `/${appName}?${search}`)
}

export const updateUrlQuery = (newQueryJson, replaceState) => {
  const currentQueryJson = queryString.parse(location.search)
  let queryJson = {
    ...currentQueryJson,
    ...newQueryJson,
  }
  if (newQueryJson.dateFilters) {
    delete queryJson.dateFilters
    for (const type of Object.keys(newQueryJson.dateFilters.dateTypes)) {
      if (newQueryJson.dateFilters.dateTypes[type].active) {
        queryJson[
          type
        ] = `${newQueryJson.dateFilters.dateTypes[type].dateRange.start}to${newQueryJson.dateFilters.dateTypes[type].dateRange.end}`
        if (newQueryJson.dateFilters.dateTypes[type].compareDateRange) {
          queryJson[
            `compare-${type}`
          ] = `${newQueryJson.dateFilters.dateTypes[type].compareDateRange.start}to${newQueryJson.dateFilters.dateTypes[type].compareDateRange.end}`
        }
      } else {
        delete queryJson[type]
      }
    }
  }
  if (newQueryJson.rangeFilters) {
    delete queryJson.rangeFilters
    for (const type of Object.keys(newQueryJson.rangeFilters.rangeTypes)) {
      if (newQueryJson.rangeFilters.rangeTypes[type].active) {
        queryJson[
          type
        ] = `${newQueryJson.rangeFilters.rangeTypes[type].min}to${newQueryJson.rangeFilters.rangeTypes[type].max}`
      } else {
        delete queryJson[type]
      }
    }
  }
  if (newQueryJson.keywordFilters) {
    delete queryJson.keywordFilters
    for (const type of Object.keys(newQueryJson.keywordFilters.keywordTypes)) {
      if (newQueryJson.keywordFilters.keywordTypes[type].active) {
        queryJson[type] = `${newQueryJson.keywordFilters.keywordTypes[type].containing ? 'is' : 'not'}-${
          newQueryJson.keywordFilters.keywordTypes[type].value
        }`
      } else {
        delete queryJson[type]
      }
    }
  }
  Object.keys(queryJson).forEach((field) => {
    if (!queryJson[field]) {
      delete queryJson[field]
    }
  })
  if (queryJson.startDate && queryJson.endDate) {
    const { startDate, endDate, periodCompare, ...restQueries } = queryJson
    queryJson = {
      ...restQueries,
      startDate,
      endDate,
    }
    if (periodCompare) {
      queryJson = {
        ...queryJson,
        periodCompare,
      }
    }
  }
  if (queryJson.timePicker?.startTime && queryJson.timePicker?.endTime) {
    const { startTime, endTime } = queryJson.timePicker
    const { ...restQueries } = queryJson
    queryJson = {
      ...restQueries,
      startTime,
      endTime,
    }
  }
  const search = queryString.stringify(queryJson, { sort: false })
  const allQueryKeys = [...new Set([...Object.keys(currentQueryJson), ...Object.keys(queryJson)])]
  if (!location.search && !search) return
  if (
    allQueryKeys.length === Object.keys(queryJson).length && // identical query keys
    allQueryKeys.reduce((acc, cur) => acc && currentQueryJson[cur] === queryJson[cur], true) // identical query values
  ) {
    return
  }
  if (location.search === '?' + search) return
  history[replaceState ? 'replaceState' : 'pushState']('', '', `${location.pathname}${search ? '?' + search : ''}`)
}

export const topRedirect = ({
  url,
  waitingTime = 1000 * 60 * 20, // optional
  referer = store.getState().root.topUrl || location.href, // optional
  newWindow = false,
  successMessage = '',
}) => {
  setCookie('auth_referer', referer, waitingTime)
  if (window !== window.top) {
    if (newWindow) {
      setCookie('auth_referer', location.href, waitingTime)
      setCookie('waiting_for_close', 'true', 1000 * 60 * 5)
      if (successMessage) {
        setCookie('pending_success_message', successMessage, 1000 * 60 * 5)
      }
      window.open(url)
      const checkClose = setInterval(() => {
        if (!getCookie('waiting_for_close')) {
          location.href = location.href
        }
      }, 1000)
      setTimeout(() => {
        setCookie('waiting_for_close', '')
        clearInterval(checkClose)
      }, 1000 * 60 * 5)
    }
    else {
      window.location.href = url
    }
  }
  else window.top.location.href = url
}

export const topRedirectToShopifyAppStore = () => {
  topRedirect({ url: accountLinkingUrls.shopifyAppStore })
}

export const topRedirectToBigcommerceAppStore = () => {
  topRedirect({ url: accountLinkingUrls.bigcommerceAppStore })
}

export const topRedirectToEtsy = () => {
  topRedirect({ url: accountLinkingUrls.etsy })
}

export const topRedirectToHubspot = () => {
  topRedirect({ url: accountLinkingUrls.hubspot })
}

export const topRedirectToUPS = () => {
  topRedirect({ url: accountLinkingUrls.ups })
}

export const topRedirectToEbay = () => {
  let newWindow = window !== window.top
  topRedirect({ url: accountLinkingUrls.ebay, newWindow: newWindow })
}

export const topRedirectToWix = () => {
  topRedirect({ url: accountLinkingUrls.wixAppStore })
}

export const topRedirectToWalmart = () => {
  let newWindow = window !== window.top
  topRedirect({ url: accountLinkingUrls.walmart, newWindow: newWindow })
}

export const convertToDateRange = (datePickerStore) => {
  if (!datePickerStore.startDate || !datePickerStore.endDate) {
    return null
  }
  return moment.range(datePickerStore.startDate, datePickerStore.endDate)
}

export const getDateRangesToCompare = ({ startDate, endDate, periodCompare, periodNum = 1 }) => {
  if (!periodCompare || periodCompare === 'none') {
    return []
  } else {
    let dateRanges = [],
      start = moment(startDate),
      end = moment(endDate),
      unit = 'days',
      number = 1
    if (periodCompare === 'previous_year') {
      for (var i = 0; i < periodNum; i++) {
        dateRanges.push({
          startDate: moment(startDate).subtract(1, 'year').format('YYYY-MM-DD'),
          endDate: moment(endDate).subtract(1, 'year').format('YYYY-MM-DD'),
        })
      }
    } else {
      if (startDate === endDate) {
        number = 1
      } else if (start.isSame(moment(endDate).subtract(1, 'year').add(1, 'day'))) {
        unit = 'year'
      } else if (start.isSame(moment(endDate).subtract(1, 'month').add(1, 'day'))) {
        unit = 'month'
      } else {
        number = moment.duration(end.diff(start)).asDays() + 1
      }
      for (var i = 0; i < periodNum; i++) {
        end = moment(start).subtract(1, 'day')
        start = moment(end).subtract(number, unit).add(1, 'day')
        dateRanges.push({
          startDate: start.format('YYYY-MM-DD'),
          endDate: end.format('YYYY-MM-DD'),
        })
      }
    }
    return dateRanges
  }
}

export const getQuickRanges = () => {
  const dayToday = moment().day()
  return {
    Today: moment.range(moment(), moment()),
    Yesterday: moment.range(moment().subtract(1, 'day'), moment().subtract(1, 'day')),
    divider1: {
      divider: true,
      className: 'divider-col-2',
    },
    'Last Week': moment.range(moment().subtract(7 + dayToday, 'days'), moment().subtract(1 + dayToday, 'days')),
    divider2: {
      divider: true,
      className: 'divider-col-3',
    },
    'This Week': moment.range(moment().subtract(dayToday, 'days'), moment()),
    divider3: {
      divider: true,
      className: 'divider-col-2',
    },
    'Last 7 Days': moment.range(moment().subtract(6, 'days'), moment()),
    'Last 14 Days': moment.range(moment().subtract(13, 'days'), moment()),
    divider4: {
      divider: true,
      className: 'divider-col-2',
    },
    divider41: {
      divider: true,
      className: 'divider-col-3',
    },
    'Last Month': moment.range(moment().subtract(1, 'month').date(1), moment().date(1).subtract(1, 'day')),
    'This Month': moment.range(moment().date(1), moment()),
    divider5: {
      divider: true,
      className: 'divider-col-2',
    },
    'Last 30 Days': moment.range(moment().subtract(29, 'days'), moment()),
    divider6: {
      divider: true,
      className: 'divider-col-3',
    },
    'Last 90 Days': moment.range(moment().subtract(89, 'days'), moment()),
    divider7: {
      divider: true,
      className: 'divider-col-2',
    },
    'This Quarter': moment.range(moment().quarter(moment().quarter()).startOf('quarter'), moment()),
    'Last Quarter': moment.range(
      moment()
        .quarter(moment().quarter() - 1)
        .startOf('quarter'),
      moment()
        .quarter(moment().quarter() - 1)
        .endOf('quarter')
    ),
    divider8: {
      divider: true,
      className: 'divider-col-2',
    },
    divider81: {
      divider: true,
      className: 'divider-col-3',
    },
    'Last Year': moment.range(
      moment().subtract(1, 'year').month(0).date(1),
      moment().month(0).date(1).subtract(1, 'day')
    ),
    'Last 365 Days': moment.range(moment().subtract(364, 'day'), moment()),
    divider9: {
      divider: true,
      className: 'divider-col-2',
    },
    'This Year': moment.range(moment().month(0).date(1), moment()),
    divider10: {
      divider: true,
      className: 'divider-col-3',
    },
    'Last 730 Days': moment.range(moment().subtract(729, 'day'), moment()),
  }
}

export const reportDateRange = [
  'Today',
  'Yesterday',
  'Last Week',
  'This Week',
  'Last 7 Days',
  'Last 14 Days',
  'Last Month',
  'This Month',
  'Last 30 Days',
  'Last 90 Days',
  'This Quarter',
  'Last Quarter',
  'Last Year',
  'Last 365 Days',
  'This Year',
]

export const switchToUrlView = (nextView, viewList, viewIndex, setView) => {
  if (nextView) {
    const nextViewIndex = viewList.indexOf(nextView)
    if (nextViewIndex >= 0 && nextViewIndex !== viewIndex) {
      setView(nextViewIndex)
    }
  }
}

export const getQueryJson = () => {
  const query = queryString.parse(location.search)
  const min_price = parseFloat(query.min_price) || null
  const max_price = parseFloat(query.max_price) || null
  return { ...query, min_price, max_price }
}

// Drap & drop utils
const dragOverEntry = (e, index, component) => {
  e.preventDefault()
  component.setState({ draggedOverEntryIndex: index })
}

const dragEntry = (index, component) => {
  component.setState({ draggedEntryIndex: index })
}

const dropEntry = (index, component, entriesToSort, callback) => {
  const { draggedEntryIndex } = component.state,
    droppedEntryIndex = index
  if (draggedEntryIndex === droppedEntryIndex) return
  const nextEntries =
    draggedEntryIndex < droppedEntryIndex
      ? [
          // drop after
          ...entriesToSort.slice(0, draggedEntryIndex),
          ...entriesToSort.slice(draggedEntryIndex + 1, droppedEntryIndex + 1),
          entriesToSort[draggedEntryIndex],
          ...entriesToSort.slice(droppedEntryIndex + 1),
        ]
      : [
          // drop before
          ...entriesToSort.slice(0, droppedEntryIndex),
          entriesToSort[draggedEntryIndex],
          ...entriesToSort.slice(droppedEntryIndex, draggedEntryIndex),
          ...entriesToSort.slice(draggedEntryIndex + 1),
        ]
  component.setState({ draggedEntryIndex: null, draggedOverEntryIndex: null })
  callback(nextEntries)
}

export const dragAndDrop = {
  dragOverEntry,
  dragEntry,
  dropEntry,
}

export const getAxiosDefaultConfig = () => ({
  withCredentials: true,
  headers: {
    ['session-signature']: store.getState().root.sessionSignature,
    path: location.pathname,
    search: location.search,
    ...(
      (document.referrer && (window !== window.top)) ? {
        'referrer-hostname': (new URL(document.referrer)).hostname
      } : null
    )
  },
})

export const getInitialTrendKeys = () => {
  const trendKeys = [
    localStoragePayhelm.dashboard_overview_primary_metric_key,
    localStoragePayhelm.dashboard_overview_secondary_metric_key,
  ]
  const trendTitles = [
    localStoragePayhelm.dashboard_overview_primary_metric,
    localStoragePayhelm.dashboard_overview_secondary_metric,
  ]

  if (trendKeys[0]) return trendKeys
  if (!trendTitles[0] && !trendTitles[1]) return ['items_sold', 'null']
  if (!trendTitles[0]) trendTitles[0] = 'Items Sold'
  return trendTitles.map((title) => {
    const trendKey = _.findKey(formatters.trends, (trend) => trend.title === title)
    return trendKey ? formatters.trends[trendKey].csvKey : 'null'
  })
}

/**
 * Get effective general & option filter keys from the redux filters
 * @param {Object} filters filters object in redux store
 */
export const getEffectiveFilterKeys = (filters) => {
  const {
    options: optionFilters,
    custom_fields: customFieldFilters,
    customer_form_fields: customerFormFieldFilters,
    shipping_form_fields: shippingFormFieldFilters,
    customer_custom_attributes: customerCustomAttributesFilters,
    ...generalFilters
  } = filters

  return {
    effectiveFilterKeys: Object.keys(generalFilters).filter((val) => !generalFilters[val].includes(null)),
    effectiveOptionFilterKeys: Object.keys(optionFilters).filter((val) => !optionFilters[val].includes(null)),
    effectiveCustomFieldFilterKeys: Object.keys(customFieldFilters).filter(
      (val) => !customFieldFilters[val].includes(null)
    ),
    effectiveCustomerFormFieldFilterKeys: Object.keys(customerFormFieldFilters).filter(
      (val) => !customerFormFieldFilters[val].includes(null)
    ),
    effectiveShippingFormFieldFilterKeys: Object.keys(shippingFormFieldFilters).filter(
      (val) => !shippingFormFieldFilters[val].includes(null)
    ),
    effectiveCustomerCustomAttributesFilterKeys: Object.keys(customerCustomAttributesFilters).filter(
      (val) => !customerCustomAttributesFilters[val].includes(null)
    ),
  }
}

/**
 * Insert or update query in URL
 * @param {String} searchTerms search keywords
 * @param {Object} filters raw filters
 * @param {Array} effectiveFilterKeys keys of filters that don't include "All *s"
 */
export const getNewQueryJson = (
  searchTerms,
  searchExcludeTerms,
  dateType,
  filters,
  {
    effectiveFilterKeys,
    effectiveOptionFilterKeys,
    effectiveCustomFieldFilterKeys,
    effectiveCustomerFormFieldFilterKeys,
    effectiveShippingFormFieldFilterKeys,
    effectiveCustomerCustomAttributesFilterKeys,
  },
  filterLogicOperator = 'AND'
) => {
  const newQueryJson = {
    dateType,
    join: filterLogicOperator,
    q:
      !searchTerms &&
      !searchExcludeTerms &&
      !effectiveFilterKeys.length &&
      !effectiveOptionFilterKeys.length &&
      !effectiveCustomFieldFilterKeys.length &&
      !effectiveCustomerFormFieldFilterKeys.length &&
      !effectiveShippingFormFieldFilterKeys.length &&
      !effectiveCustomerCustomAttributesFilterKeys.length
        ? null
        : JSON.stringify({
            ...(searchTerms
              ? {
                  title: searchTerms,
                }
              : {}),
            ...(searchExcludeTerms
              ? {
                  title_ex: searchExcludeTerms,
                }
              : {}),
            ...(effectiveFilterKeys.length
              ? {
                  filters: Object.assign(
                    {},
                    ...effectiveFilterKeys.map((key) => {
                      return { [key]: filters[key] }
                    })
                  ),
                }
              : {}),
            ...(effectiveOptionFilterKeys.length
              ? {
                  option_filters: Object.assign(
                    {},
                    ...effectiveOptionFilterKeys.map((key) => {
                      return { [key]: filters.options[key] }
                    })
                  ),
                }
              : {}),
            ...(effectiveCustomFieldFilterKeys.length
              ? {
                  custom_field_filters: Object.assign(
                    {},
                    ...effectiveCustomFieldFilterKeys.map((key) => {
                      return { [key]: filters.custom_fields[key] }
                    })
                  ),
                }
              : {}),
            ...(effectiveCustomerFormFieldFilterKeys.length
              ? {
                  customer_form_field_filters: Object.assign(
                    {},
                    ...effectiveCustomerFormFieldFilterKeys.map((key) => {
                      return { [key]: filters.customer_form_fields[key] }
                    })
                  ),
                }
              : {}),
            ...(effectiveShippingFormFieldFilterKeys.length
              ? {
                  shipping_form_field_filters: Object.assign(
                    {},
                    ...effectiveShippingFormFieldFilterKeys.map((key) => {
                      return { [key]: filters.shipping_form_fields[key] }
                    })
                  ),
                }
              : {}),
            ...(effectiveCustomerCustomAttributesFilterKeys.length
              ? {
                  customer_attributes_filters: Object.assign(
                    {},
                    ...effectiveCustomerCustomAttributesFilterKeys.map((key) => {
                      return { [key]: filters.customer_custom_attributes[key] }
                    })
                  ),
                }
              : {}),
          }),
  }
  return newQueryJson
}

export const getDefaultFilterType = (filters) => {
  let defaultFilterType,
    activeFilters = 0,
    ultimateFilter
  for (const type of Object.keys(filters)) {
    if (filters[type]?.active) {
      activeFilters = activeFilters + 1
      ultimateFilter = type
      if (!defaultFilterType) defaultFilterType = type
    }
  }
  if (!defaultFilterType) defaultFilterType = Object.keys(filters)[0]
  return { defaultFilterType, activeFilters, ultimateFilter }
}

const getLocalStorage = () => {
  try {
    const storage = window.localStorage
    return storage
  } catch (error) {
    console.error('Browser window local storage is not accessible')
    return {}
  }
}

export const localStoragePayhelm = getLocalStorage()

const getSessionStorage = () => {
  try {
    const storage = window.sessionStorage
    return storage
  } catch (error) {
    console.error('Browser window session storage is not accessible')
    return {}
  }
}

export const sessionStoragePayhelm = getSessionStorage()

export const queryObjToStr = (queryObj) => {
  return _.chain(queryObj)
    .toPairs()
    .reject(([k, v]) => _.isNil(v))
    .map(([k, v]) => `${k}=${v}`)
    .join('&')
    .value()
}

export const searchFilterValueLegend = {
  billing_country_code: { title: 'Country Code or Name' },
  billing_province: { title: 'Province' },
  billing_county_name: { title: 'County' },
  brand_ids: { title: 'Brand ID or Name' },
  category_titles: { title: 'Category' },
  currency: { title: 'Currency Code' },
  // customer_id: { title: 'Customer ID', },
  coupon_codes: { title: 'Coupon Code' },
  product_skus: { title: 'Product SKU' },
  skus: { title: 'SKU' },
  utm_campaign: { title: 'UTM Campaign' },
  utm_content: { title: 'UTM Content' },
  utm_medium: { title: 'UTM Medium' },
  utm_source: { title: 'UTM Source' },
  utm_term: { title: 'UTM Term' },
  utm_id: { title: 'UTM ID' },
}

export const searchableFilterValues = Object.keys(searchFilterValueLegend)

export const processSearchFilterInput = (filters, filterKey, input) => {
  if (['billing_country_code', 'brand_ids'].includes(filterKey)) {
    let dictionary = {}
    filters.forEach((filter) => {
      dictionary[filter.title.toLowerCase()] = filter.val
    })
    return dictionary[input.toLowerCase()] || input
  } else return input
}

export const searchFilterInputHandler = (input, filterKey) => {
  switch (filterKey) {
    case 'billing_country_code':
      return input.toUpperCase()
    case 'billing_province' || 'billing_county_name':
      return input
        .toLowerCase()
        .split(' ')
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ')
    case 'currency':
      return input.toUpperCase()
    // case 'customer_id':
    //   return input.toLowerCase()
    default:
      return input
  }
}

export const filterTitleFormatter = (filter, filterKey) => {
  let title = filter.title || filter.val
  let id
  const count = filter.count ? `(${filter.count})` : ''
  if (['billing_country_code', 'brand_ids'].includes(filterKey)) id = `${filter.val}`
  // if (filterKey === 'customer_id') {
  //   const filterStorePrefix = Object.keys(accountEmailDict).map(account => `${accountEmailDict[account].channel}_${account}_`)
  // } // TODO - figure out customer_id filter with multiple possible accounts

  return (
    <span>
      {id}
      {id && ' - '}
      {title} {count}
    </span>
  )
}

export const getOwnerId = (user, teamAccounts) => {
  if (!user || !teamAccounts) return null
  if (teamAccounts.length === 0) return user.id

  // i.e. First person ever invited must be invited by the owner user
  const sortedTeamAccounts = _.sortBy(teamAccounts, ['created_time'])
  return sortedTeamAccounts[0].owner_id
}

// [{ id: '', ... }] -> { ids: [], entities: { [id]: entity, ... } }
// Takes optional selectId fn to specify which key should be the id (defaults to id)
export const normalizeEntities = (data, { selectId = ({ id }) => id } = {}) => {
  if (!data) return null

  if (_.isEmpty(data)) {
    return { ids: [], entities: {} }
  }

  return {
    ids: _.map(data, selectId),
    entities: _.reduce(data, (prev, curr) => ({ ...prev, [selectId(curr)]: curr }), {}),
  }
}

export const validateJSONString = (string) => {
  try {
    JSON.parse(string)
    return true
  } catch (e) {
    return false
  }
}

// MUST input an array of promise factories NOT promises - i.e. () => Promise
export const resolvePromiseFactoriesSeq = async (promiseFactories) => {
  const results = []
  for (const promiseFactory of promiseFactories) {
    try {
      const result = await promiseFactory()
      results.push(result)
    } catch (err) {
      console.log('Error in resolvePromisesSeq', err)
      throw new Error(err)
    }
  }

  return results
}

export const convertTo12HourFormat = (militaryTime) => {
  // Parse the military time string
  const [hours, minutes] = militaryTime.split(':')
  let hour = parseInt(hours, 10)

  // Determine AM/PM and adjust hour
  const period = hour >= 12 ? 'PM' : 'AM'
  hour = hour % 12 || 12 // Convert hour 0 to 12

  // Format the time in 12-hour format with AM/PM
  const time12Hour = `${hour}:${minutes} ${period}`;
  return time12Hour;
}

export const acceptedFormatColumns = ['processed_at', 'created_at', 'updated_at', 'fulfilled_at', 'shipping_province', 'last_refund_at', 'payout_dates']
