import React from 'react'
import _ from 'lodash'

export default function Author({ author }) {
  const { picture, email } = author || {}

  return (
    <div
      className="tw-shrink-0 tw-flex tw-items-center tw-justify-center tw-rounded-full tw-w-[24px] tw-h-[24px] tw-text-white tw-text-xs tw-overflow-hidden"
      style={{ backgroundColor: '#000' }}
    >
      {picture ? <img src={picture} /> : <div className="tw-uppercase">{email.substring(0, 2)}</div>}
    </div>
  )
}
