import React from 'react'
import { getAccountStatus, linkAccount } from 'account'
import { Button } from 'react-bootstrap'

export default function AccountStatus({ account }) {
  const { tokenExpired } = getAccountStatus(account)

  if (!tokenExpired) return <div className="tw-text-green-600">Good</div>

  return (
    <div>
      <div
        className="tw-cursor-pointer tw-underline tw-underline-offset-4 tw-text-blue-600"
        onClick={() => linkAccount(account, true)}
      >
        Relink needed
      </div>
    </div>
  )
}
