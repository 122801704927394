import React from 'react'
import { FiTrendingUp } from 'react-icons/fi'
import { withRouter } from 'react-router-dom'

function ReportCard({ data, history }) {
  const { name, title, description, link } = data

  return (
    <div
      onClick={() => history.push(link)}
      className="tw-w-full tw-h-full tw-bg-slate-100 hover:tw-ring hover:tw-ring-blue-600 tw-rounded-lg tw-p-3 tw-text-sm tw-cursor-pointer"
    >
      <div className="tw-shrink-0 tw-w-8 tw-h-8 tw-rounded-lg tw-flex tw-items-center tw-justify-center tw-bg-white tw-text-blue-600 tw-text-xl tw-mr-2 tw-mb-2">
        <FiTrendingUp className="tw-text-xl" />
      </div>
      <div className="tw-w-full tw-flex tw-items-center">
        <div>
          <div className="tw-text-slate-800 tw-font-bold tw-mb-0.5">{title}</div>
          <div className="tw-text-slate-500">{description}</div>
        </div>
      </div>
    </div>
  )
}

export default withRouter(ReportCard)
