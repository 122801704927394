import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import { IntercomAPI } from 'react-intercom'
import { Button, Spinner } from 'react-bootstrap'
import { useFieldArray, useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import MyInput from '../../../../features/forms/MyInput'
import {
  createSecret,
  deleteSecret,
  fetchAllSecrets,
  updateSecret,
} from '../../../../features/aws/awsSecretsManagerSlice'
import { FiEye, FiEyeOff, FiX } from 'react-icons/fi'
import { checkShouldRemoveOldKey, getSecretId } from './helpers'
import {
  closeConnectionModal,
  createPublicKey,
  deletePublicKey,
  fetchAllPublicKeys,
  postSlackMessage,
  updatePublicKey,
} from '../../integrationsSlice'
import { toast } from 'react-toastify'
import { selectRootUser } from '../../../../rootSlice'
import { SLACK_DATA_STATUS_CHANNEL_ID } from '../../../../modules/constants'
import { validateSnakeCase } from '../../../../features/forms/validators'
import { resolvePromiseFactoriesSeq } from '../../../../modules/utils'

export default function GenericConnectionModalContent({ ownerId, channel, retrieveAccounts, accountLabel = undefined, privateKeyLabel = undefined, publicKeyLabel = undefined, }) {
  const dispatch = useDispatch()
  const closeModal = () => dispatch(closeConnectionModal())
  const user = useSelector(selectRootUser)
  const { id: channelId, channel: channelName, ppKeys } = channel || {}
  const secretId = getSecretId(ownerId, channelId)
  const keyId = secretId

  /**
   *
   * Toggle form type
   *
   */
  const [formTypes, setFormTypes] = useState(['password', 'password', 'password'])

  /**
   *
   * Handle form
   *
   */
  const {
    register,
    control,
    setValue,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm()

  const { fields, append, prepend, remove, swap, move, insert } = useFieldArray({
    control,
    name: 'keys',
  })

  /**
   *
   * Set default form values
   *
   */
  useEffect(() => {
    if (!ppKeys || _.isEmpty(ppKeys)) return

    // Delete the old key if it's been replaced by a new one
    const { shouldRemoveOldKey, oldKey, newKeys } = checkShouldRemoveOldKey(ppKeys)
    const filteredPpKeys = shouldRemoveOldKey ? newKeys : ppKeys

    if (shouldRemoveOldKey) {
      const oldKeyIdToRemove = oldKey?.keyId
      dispatch(deleteSecret({ secretId: oldKeyIdToRemove }))
      dispatch(deletePublicKey({ keyId: oldKeyIdToRemove }))
    }

    filteredPpKeys.forEach((keyObject, i) => {
      const { accountId, publicKey, privateKey } = keyObject
      append({ accountId, publicKey, privateKey })
    })
  }, [ppKeys])

  /**
   *
   * Handle form submit
   *
   */
  const onSubmit = async (values) => {
    const { keys } = values

    const currentAccountIds = ppKeys
      .map(({ keyId }) => {
        const accountIdExists = keyId.split('--').length === 3
        return accountIdExists ? _.chain(keyId).split('--').last().value() : null
      })
      .filter((item) => item !== null)

    // Keys to be deleted
    const deletePromiseFactories = _.chain(currentAccountIds)
      .filter((accountId) => {
        return !keys.map(({ accountId }) => accountId).includes(accountId)
      })
      .map((accountId) => {
        const fullKeyId = `${ownerId}--${channelId}--${accountId}`
        return [
          () => dispatch(deleteSecret({ secretId: fullKeyId })).unwrap(),
          () => dispatch(deletePublicKey({ keyId: fullKeyId })).unwrap(),
        ]
      })
      .flatten()
      .value()

    // Keys to be created or updated
    const createOrUpdatePromiseFactories = _.chain(keys)
      .map((_key) => {
        const { accountId, publicKey, privateKey } = _key

        const fullKeyId = `${ownerId}--${channelId}--${accountId}`

        if (currentAccountIds.includes(accountId)) {
          // If account already exists, update
          return [
            () =>
              dispatch(
                updateSecret({
                  secretId: fullKeyId,
                  secretValue: privateKey,
                })
              ).unwrap(),
            () =>
              dispatch(
                updatePublicKey({
                  keyId: fullKeyId,
                  keyValue: publicKey,
                })
              ).unwrap(),
          ]
        } else {
          // Otherwise, create
          const tags = [
            { Key: 'ownerId', Value: ownerId },
            { Key: 'channelId', Value: channelId },
            { Key: 'accountId', Value: accountId },
          ]

          return [
            () =>
              dispatch(
                createSecret({
                  secretId: fullKeyId,
                  secretValue: privateKey,
                  description: `Private key for channel ${channelId} and account ${accountId}`,
                  tags,
                })
              ).unwrap(),
            () =>
              dispatch(
                createPublicKey({
                  keyId: fullKeyId,
                  keyValue: publicKey,
                  tags,
                })
              ).unwrap(),
          ]
        }
      })
      .flatten()
      .value()

    try {
      // Running private key promise first since it's more important - if it fails, we don't want to run the public key promise
      await resolvePromiseFactoriesSeq(createOrUpdatePromiseFactories)
    } catch (err) {
      const errMessage = `Error while creating or updating integration keys: ${err}`
      console.error(errMessage, err)
      toast.error(errMessage)
      return
    }

    // Delete only if create/update is successful
    try {
      // Running private key promise first since it's more important - if it fails, we don't want to run the public key promise
      await resolvePromiseFactoriesSeq(deletePromiseFactories)
    } catch (err) {
      const errMessage = `Error while deleting integration keys`
      console.error(errMessage, err)
      toast.error(errMessage)
      return
    }

    // When done, retrieve accounts and re-fetch keys
    await retrieveAccounts()

    // Send message to a slack channel for notification
    dispatch(
      postSlackMessage({
        channel: SLACK_DATA_STATUS_CHANNEL_ID,
        text: `${user.email} saved public/private keys for ${channelName} integration`,
      })
    )

    // Track it via intercom
    IntercomAPI('trackEvent', 'clicked-integration-connect-button', {
      email: user.email,
      channel: channelName,
    })

    const successMessage = `Successfully created or updated ${channelName} keys`
    toast.success(successMessage)
    closeModal()
  }

  return (
    <div className="tw-p-6">
      <h2 className="tw-text-xl tw-mb-2">Connect Your {channelName} Account</h2>
      <div className="tw-text-slate-500 tw-mb-4">
        Please enter your public/private keys below to connect your account. You can add multiple accounts.
      </div>

      {!ppKeys ? (
        <Spinner animation="border" variant="primary" />
      ) : (
        <form className="tw-w-full" onSubmit={handleSubmit(onSubmit)}>
          {fields.map((field, i) => {
            const formType = formTypes[i]

            return (
              <div key={field.id} className="tw-rounded-xl tw-bg-slate-50 tw-p-3 tw-mb-4">
                <div className="tw-flex tw-justify-end">
                  <div
                    className="tw-shrink-0 tw-cursor-pointer tw-text-slate-400 hover:tw-text-slate-700 tw-text-lg"
                    onClick={() => remove(i)}
                  >
                    <FiX />
                  </div>
                </div>

                <div className="tw-mb-3">
                  <MyInput
                    register={register}
                    name={`keys.${i}.accountId`}
                    label={accountLabel || "Account ID"}
                    type="text"
                    rules={{
                      required: `${accountLabel || 'Account Id'} is required`,
                      validate: { validateSnakeCase },
                    }}
                    errors={errors}
                  />
                </div>

                <div className="tw-mb-3">
                  <MyInput
                    register={register}
                    name={`keys.${i}.publicKey`}
                    label={publicKeyLabel || "Public key"}
                    type="text"
                    rules={{ required: `${publicKeyLabel || 'Public key'} is required` }}
                    errors={errors}
                  />
                </div>

                <div className="tw-flex tw-items-center tw-space-x-2 tw-mb-3">
                  <MyInput
                    register={register}
                    name={`keys.${i}.privateKey`}
                    label={privateKeyLabel || "Private key"}
                    type={formTypes[i]}
                    rules={{ required: `${privateKeyLabel || 'Private key'} is required'` }}
                    errors={errors}
                  />

                  {formTypes[i] === 'password' ? (
                    <div
                      className="tw-shrink-0 tw-mt-6 tw-cursor-pointer tw-text-slate-600 hover:tw-text-slate-900 tw-text-lg"
                      onClick={() => {
                        const newFormType = formTypes.map((item, j) => (i === j ? 'text' : item))
                        setFormTypes(newFormType)
                      }}
                    >
                      <FiEye />
                    </div>
                  ) : (
                    <div
                      className="tw-shrink-0 tw-mt-6 tw-cursor-pointer tw-text-slate-600 hover:tw-text-slate-900 tw-text-lg"
                      onClick={() => {
                        const newFormType = formTypes.map((item, j) => (i === j ? 'password' : item))
                        setFormTypes(newFormType)
                      }}
                    >
                      <FiEyeOff />
                    </div>
                  )}
                </div>
              </div>
            )
          })}

          <div className="-tw-mt-2 tw-mb-4">
            <a
              className="cursor-pointer tw-text-blue-500 hover:tw-text-blue-700"
              onClick={() => append({ accountId: '', publicKey: '', privateKey: '' })}
            >
              + Add account
            </a>
          </div>

          <div className="tw-flex tw-items-center tw-justify-end tw-space-x-2">
            <Button variant="secondary" onClick={closeModal}>
              Cancel
            </Button>
            <Button type="submit" disabled={isSubmitting}>
              Save {channelName} Connection
            </Button>
          </div>
          <div className="tw-flex tw-justify-end tw-mt-2">
            {errors?.keys &&
              errors.keys.map((_key) => {
                const { accountId, publicKey, privateKey } = _key || {}
                const errorMessage = accountId?.message || publicKey?.message || privateKey?.message
                return errorMessage ? <div className="tw-text-red-500 tw-text-sm">{errorMessage}</div> : null
              })}
          </div>
        </form>
      )}
    </div>
  )
}
