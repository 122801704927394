import React from 'react'

function ErrorMessage({ dataErrorInfo, miscError }) {
  return (
    <div>
      {dataErrorInfo?.length ? (
        <div>
          <div className="tw-text-red-800 tw-mb-1">
            This spreadsheet is not in the correct format, please see our sample file above:
          </div>
          {dataErrorInfo.map((missingHeader) => (
            <div className="tw-text-red-800 tw-mb-1" key={missingHeader}>
              {`• Missing ${missingHeader} header in the csv`}
            </div>
          ))}
        </div>
      ) : null}
      {miscError ? <div className="tw-text-red-800 tw-mb-1">{`${miscError}`}</div> : null}
    </div>
  )
}

export default ErrorMessage
