import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/features/shadcn/ui/table'
import React from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'

export default function MetricsTab({ trafficReport, setExportData }) {
  const topMetrics = [
    {
      title: 'Total Sales',
      value: trafficReport?.stats.total_sales.toLocaleString(undefined, {
        style: 'currency',
        currency: 'USD',
      }),
    },
    {
      title: 'Total Sessions',
      value: trafficReport?.stats.total_sessions.toLocaleString(),
    },
    {
      title: 'Total Orders',
      value: trafficReport?.stats.total_orders.toLocaleString(),
    },
    {
      title: 'Total Visitors',
      value: trafficReport?.stats.total_visitors.toLocaleString(),
    },
    {
      title: 'Total Active Carts',
      value: trafficReport?.stats.total_carts.toLocaleString(),
    },
    {
      title: 'Total Checkouts',
      value: trafficReport?.stats.total_checkouts.toLocaleString(),
    },
  ]

  const customerBehaviors = [
    {
      title: 'Visitors',
      total_sessions: trafficReport?.stats.total_visitors.toLocaleString(),
    },
    {
      title: 'Active Carts',
      total_sessions: trafficReport?.stats.total_carts.toLocaleString(),
    },
    {
      title: 'Checkouts',
      total_sessions: trafficReport?.stats.total_checkouts.toLocaleString(),
    },
    {
      title: 'Purchased',
      total_sessions: trafficReport?.stats.total_orders.toLocaleString(),
    },
  ]

  const sessions = trafficReport?.trends

  const ordersByCountry = trafficReport?.countries.orders

  const sessionsByCountry = trafficReport?.countries.sessions

  useEffect(() => {
    if (!trafficReport) return

    const _exportData = [
      {
        sectionName: 'top_metrics',
        data: [
          topMetrics.reduce((acc, metric) => {
            return { ...acc, [metric.title]: metric.value }
          }, {}),
        ],
        header: topMetrics.map((metric) => {
          return metric.title
        }),
      },
      {
        sectionName: 'customer_behaviors',
        data: customerBehaviors.map((row) => ({
          'Customer Group': row.title,
          'Total Sessions': row.total_sessions,
        })),
        header: ['Customer Group', 'Total Sessions'],
      },
      {
        sectionName: 'sessions_trend',
        data: sessions.map((row) => ({ Date: row.value, 'Total Sessions': row.total_sessions })),
        header: ['Date', 'Total Sessions'],
      },
      {
        sectionName: 'orders_by_country',
        data: ordersByCountry.map((row) => ({ Country: row.country_code, Orders: row.count })),
        header: ['Country', 'Orders'],
      },
      {
        sectionName: 'sessions_by_country',
        data: sessionsByCountry.map((row) => ({ Country: row.country_code, Sessions: row.count })),
        header: ['Country', 'Sessions'],
      },
    ]
    setExportData(_exportData)
  }, [trafficReport])

  return (
    <div className="tw-space-y-10">
      <div>
        <div className="tw-grid tw-grid-cols-1 lg:tw-grid-cols-12 tw-gap-4">
          {topMetrics.map((metric, i) => {
            return (
              <div key={i} className="tw-col-span-1 lg:tw-col-span-4">
                <MetricBox metric={metric} />
              </div>
            )
          })}
        </div>
      </div>

      <div className="tw-border-t tw-border-slate-200 -tw-mx-4" />

      <div>
        <div className="tw-mt-4 tw-mb-4 tw-font-bold">Summary</div>
        <div className="tw-grid tw-grid-cols-1 lg:tw-grid-cols-12 tw-gap-4 tw-mt-8">
          <div className="tw-col-span-1 lg:tw-col-span-6">
            <div className="tw-h-[320px] tw-overflow-auto tw-px-3 tw-py-2 tw-rounded-md tw-border tw-border-slate-200 tw-mb-4">
              <div className="tw-mb-2 tw-font-bold">Customer Behaviors</div>
              <CustomerBehaviorsTable data={customerBehaviors} />
            </div>
          </div>
          <div className="tw-col-span-1 lg:tw-col-span-6">
            <div className="tw-h-[320px] tw-overflow-auto tw-px-3 tw-py-2 tw-rounded-md tw-border tw-border-slate-200 tw-mb-4">
              <div className="tw-mb-2 tw-font-bold">Sessions Trend</div>
              <SessionsChart data={sessions} />
            </div>
          </div>
          <div className="tw-col-span-1 lg:tw-col-span-6">
            <div className="tw-h-[320px] tw-overflow-auto tw-px-3 tw-py-2 tw-rounded-md tw-border tw-border-slate-200 tw-mb-4">
              <div className="tw-mb-2 tw-font-bold">Orders by Country</div>
              <OrdersByCountryTable data={ordersByCountry} />
            </div>
          </div>
          <div className="tw-col-span-1 lg:tw-col-span-6">
            <div className="tw-h-[320px] tw-overflow-auto tw-px-3 tw-py-2 tw-rounded-md tw-border tw-border-slate-200 tw-mb-4">
              <div className="tw-mb-2 tw-font-bold">Sessions by Country</div>
              <SessionsByCountryChart data={sessionsByCountry} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const MetricBox = ({ metric }) => {
  const { title, value } = metric
  return (
    <div className="tw-rounded-md tw-border tw-border-slate-200 tw-px-3 tw-py-2">
      <div className="tw-mb-4">{title}</div>
      <div className="tw-text-2xl tw-text-blue-700">{value}</div>
    </div>
  )
}

const CustomerBehaviorsTable = ({ data }) => {
  return (
    <Table className="!tw-text-base tw-border-b">
      <TableHeader>
        <TableRow>
          <TableHead className="tw-w-1/2 tw-text-slate-500 !tw-font-bold"></TableHead>
          <TableHead className="tw-w-1/2 tw-text-slate-500 !tw-font-bold tw-text-right">Total Sessions</TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {data.map((record) => (
          <TableRow key={record.title}>
            <TableCell>{record.title}</TableCell>
            <TableCell className="tw-text-right">{record.total_sessions}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}

const SessionsChart = ({ data }) => {
  const options = {
    chart: { type: 'column', height: 264 },
    title: { text: '' },
    xAxis: { categories: data.map((d) => d.value) },
    yAxis: {
      title: { text: '' },
      plotLines: [
        {
          value: 0,
          width: 1,
          color: '#808080',
        },
      ],
    },
    tooltip: {
      valueSuffix: '',
      // formatter: function () {
      //   return `<span style="font-size: 10px; color: #64748b;">${this.x}</span><br />${
      //     this.series.name
      //   }: <b>${this.y.toLocaleString()}</b>`
      // },
    },
    legend: { enabled: false },
    series: {
      name: 'Sessions',
      data: data.map((d) => d.total_sessions),
    },
  }

  return <HighchartsReact highcharts={Highcharts} options={options} className="tw-h-full" />
}

const OrdersByCountryTable = ({ data }) => {
  return (
    <Table className="!tw-text-base tw-border-b">
      <TableHeader>
        <TableRow>
          <TableHead className="tw-w-1/2 tw-text-slate-500 !tw-font-bold"></TableHead>
          <TableHead className="tw-w-1/2 tw-text-slate-500 !tw-font-bold tw-text-right">Orders</TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {data.map((record) => (
          <TableRow key={record.country_code}>
            <TableCell>{record.country_code}</TableCell>
            <TableCell className="tw-text-right">{record.count.toLocaleString()}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}

const SessionsByCountryChart = ({ data }) => {
  const options = {
    chart: { type: 'pie', height: 264 },
    title: { text: '' },
    xAxis: { categories: data.map((d) => d.value) },
    yAxis: {
      title: { text: '' },
      plotLines: [
        {
          value: 0,
          width: 1,
          color: '#808080',
        },
      ],
    },
    tooltip: {
      valueSuffix: '',
      // formatter: function () {
      //   return `<span style="font-size: 10px; color: #64748b;">${this.x}</span><br />${
      //     this.series.name
      //   }: <b>${this.y.toLocaleString()}</b>`
      // },
    },
    series: {
      name: 'Sessions',
      data: data.map((d) => {
        return {
          name: d.country_code,
          y: d.count,
        }
      }),
    },
  }

  return <HighchartsReact highcharts={Highcharts} options={options} className="tw-h-full" />
}
