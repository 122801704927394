import {
  getBackfill,
  getShopifyBackfill,
  getPaypalBackfill,
  getMerchantPaypalBackfill,
  getBigcommerceBackfill,
  getWoocommerceBackfill,
  getEtsyBackfill,
  getAmazonBackfill,
  getWixBackfill,
  getEbayBackfill,
  getWalmartBackfill,
} from 'urls'

const buildUrlFromOptions = (url, options) => {
  if (options?.order) return `${url}_for_order`
  if (options?.type) return `${url}_${options?.type}_date`
  if (options?.total) return `${url}_days`
  if (options?.product) return `${url}_product_inventory`
  return url
}

export const getTriggerBackfillDetails = (user, channel, options) => {
  const today = new Date()
  let startDate, endDate = null

  if (channel === 'Shopify') {
    const shopifyUser = user.includes('.myshopify.com') ? user : user + '.myshopify.com'
    const shopifyUrl = buildUrlFromOptions(getShopifyBackfill, options)
    return { user: shopifyUser, url: shopifyUrl }
  }

  if (channel === 'Paypal') {
    return { user, url: getPaypalBackfill }
  }

  if (channel === 'Paypal Merchant') {
    return { user, url: getMerchantPaypalBackfill }
  }

  if (channel === 'Bigcommerce') {
    const bigcommerceUrl = buildUrlFromOptions(getBigcommerceBackfill, options)
    return { user, url: bigcommerceUrl }
  }

  if (channel === 'Woocommerce') {
    const woocommerceUrl = buildUrlFromOptions(getWoocommerceBackfill, options)
    return { user, url: woocommerceUrl }
  }

  if (channel === 'Etsy') {
    const etsyUrl = buildUrlFromOptions(getEtsyBackfill, options)
    return { user, url: etsyUrl}
  }

  if (channel === 'Amazon') {
    const amazonUrl = buildUrlFromOptions(getAmazonBackfill, options)
    return { user, url: amazonUrl}
  }

  if (channel === 'Wix') {
    const wixUrl = buildUrlFromOptions(getWixBackfill, options)
    return { user, url: wixUrl}
  }

  if (channel === 'eBay') {
    const ebayUrl = buildUrlFromOptions(getEbayBackfill, options)
    return { user, url: ebayUrl}
  }

  if (channel === 'Walmart') {
    const walmartUrl = getWalmartBackfill
    switch(true) {
      case options?.total:
        startDate = moment().format('YYYY-MM-DD')
        endDate = moment().clone().subtract(options.total, 'days').format('YYYY-MM-DD')
        break
      case options?.type === 'back_to':
        startDate = moment(options.date).format('YYYY-MM-DD')
        endDate = moment().format('YYYY-MM-DD')
        break
      case options?.type === 'until':
        startDate = '2019-01-01'
        endDate = moment(options.date).format('YYYY-MM-DD')
        break
      case options?.type === 'on':
        startDate = moment(options.date).format('YYYY-MM-DD')
        endDate = moment(options.date).format('YYYY-MM-DD')
        break
      default:
        startDate = '2019-01-01'
        endDate = moment().format('YYYY-MM-DD')
        break
    }
    return { user, url: walmartUrl, startDate, endDate, }
  }

  return { user, url: getBackfill, startDate, endDate, }
}
