import axios from 'axios'
import { setCookie } from 'cookie'
import {
  createCreditCardUrl,
  deleteCreditCardUrl,
  changePlanUrl,
  getStripeInvoicesUrl,
  createCustomerChargeUrl,
  getCustomerInfoUrl,
  updateAutoDepositSettingUrl,
  getCustomerTransactionBalanceUrl,
  updateAddOnUrl,
} from 'urls'
import { topRedirect } from 'utils'
import { sendGtmPixel } from 'tagManager'
import { fireRefersionTracking } from 'tracking'
import { checkRequiredAction } from './actionUtils'
import _ from 'lodash'
import { getAxiosDefaultConfig } from 'utils'

export function setView(viewIndex) {
  return {
    type: 'SET_VIEW_PLAN',
    payload: viewIndex,
  }
}

export function setStripeLoaded(flag) {
  return {
    type: 'SET_STRIPE_LOADED_PLAN',
    payload: flag,
  }
}

export function toggleManagementModal(option) {
  return {
    type: 'TOGGLE_PAYMENT_MODAL_PLAN',
    payload: option,
  }
}

export function setUpgradeDismissed(flag) {
  return {
    type: 'SET_UPGRADE_DISMISSED_PLAN',
    payload: flag,
  }
}

export function setAddOnUpgradeDismissed(addOnDismissed) {
  return {
    type: 'SET_ADD_ON_UPGRADE_DISMISSED_PLAN',
    payload: addOnDismissed,
  }
}

export function setLoadingView(view) {
  return {
    type: 'SET_LOADING_VIEW_PLAN',
    payload: view,
  }
}

export function selectCreditCard(card) {
  return {
    type: 'SELECT_CREDIT_CARD_PLAN',
    payload: card,
  }
}

export function selectBillingAddress(address) {
  return {
    type: 'SELECT_BILLING_ADDRESS_PLAN',
    payload: address,
  }
}

export function selectPlanOption(option) {
  return {
    type: 'SELECT_PLAN_OPTION_PLAN',
    payload: option,
  }
}

export function selectAddOn(addOn) {
  return {
    type: 'SELECT_ADD_ON_PLAN',
    payload: addOn,
  }
}

export function setNextModalBody(name) {
  return {
    type: 'SET_NEXT_MODAL_BODY_PLAN',
    payload: name,
  }
}

export function setCreditCards(creditCards) {
  return {
    type: 'SET_CREDIT_CARDS_PLAN',
    payload: creditCards,
  }
}

export function setSubscription(subscription) {
  return {
    type: 'SET_SUBSCRIPTION_PLAN',
    payload: subscription,
  }
}

export function setBillingChannel(billingChannel) {
  return {
    type: 'SET_BILLING_CHANNEL_PLAN',
    payload: billingChannel,
  }
}

export function setRedirectedAction(action) {
  return {
    type: 'SET_REDIRECT_ACTION_PLAN',
    payload: action,
  }
}

export function setBillingMessage(message) {
  return {
    type: 'SET_BILLING_MESSAGE_PLAN',
    payload: message,
  }
}

export function setCustomerBalanceLoad(action) {
  return {
    type: 'SET_CUSTOMER_BALANCE_LOAD',
    payload: action
  }
}

export function setBillingError(error) {
  return {
    type: 'SET_BILLING_ERROR_PLAN',
    payload: typeof error === 'string' ? error : 'There was an error during processing. Please try again.',
  }
}

export function applyNewCreditCard(currentCards, newCreditCard, subscription) {
  return (dispatch) => {
    dispatch({ type: 'SET_CREDIT_CARDS_PLAN', payload: [...currentCards, newCreditCard] })
    dispatch({ type: 'SELECT_CREDIT_CARD_PLAN', payload: newCreditCard})
    dispatch({ type: 'SET_SUBSCRIPTION_PLAN', payload: {
      ...subscription,
      plan_status: {
        ...subscription.plan_status,
        creditCardRequired: false,
      }
    }})
  }
}

export function updateCreditCard(currentCards, token, action, subscription, callback) {
  return async (dispatch, getState) => {
    try {
      const nextCardsDeleted = currentCards.filter(card => card.id !== token)
      const nextDefaultCard = nextCardsDeleted.length ? nextCardsDeleted[0] : null
      const nextDefaultToken = nextDefaultCard ? nextDefaultCard.id : null
      const addingCard = action === 'add'
      const { data: resData } = await axios.post(
        addingCard ? createCreditCardUrl : deleteCreditCardUrl,
        { token, nextDefaultToken },
        getAxiosDefaultConfig()
      )
      const nextCards = addingCard ? [resData, ...currentCards] : nextCardsDeleted
      const nextSelectedCard = addingCard ? resData : nextDefaultCard
      dispatch({ type: 'SET_LOADING_VIEW_PLAN', payload: '' })
      dispatch({ type: 'SET_CREDIT_CARDS_PLAN', payload: nextCards })
      dispatch({ type: 'SELECT_CREDIT_CARD_PLAN', payload: nextSelectedCard})
      dispatch({ type: 'SET_BILLING_ERROR_PLAN', payload: null })
      dispatch({ type: 'SET_SUBSCRIPTION_PLAN', payload: {
        ...subscription,
        plan_status: {
          ...subscription.plan_status,
          creditCardRequired: nextCards.length === 0,
        }
      }})
      if (addingCard) {
        sendGtmPixel(resData, 'cc_added')
      }
      if (callback) callback()
      if (resData.new_paid_customer) {
        dispatch({ type: 'SET_PLAN_NEW_PAID_CUSTOMER', payload: resData.new_paid_customer })
      }
    }
    catch(error) {
      console.error(error)
      const errorMessage = _.get(error, ['response', 'data', 'error'], 'Internal Error')
      dispatch({ type: 'SET_LOADING_VIEW_PLAN', payload: '' })
      dispatch({ type: 'SET_BILLING_ERROR_PLAN', payload: errorMessage })
    }
  }
}

const successMsg = 'Your plan has been updated successfully. Thank you.'
const failureMsg = 'The charge was not successful. Please try again.'
const timeoutMsg = 'The session was timed out. Please try again.'

export function updatePlan(
  subscription, product, interval, intervalCount, creditCards, selectedCreditCard = {}, callback, topUrl, newPaidCustomerFromCcEnter,
  toCancel, { cancelReason, cancelReasonSpecified }
) {
  const oldPlan = subscription.plans[0]
  return async (dispatch, getState) => {
    try {
      const { user: { email } } = getState().root
      const enterprisePrice = _.get(getState().plan, ['subscription', 'plan_status', 'enterprisePrice'])
      const { data: { addOns: nextAddOns, ...resData} } = await axios.post(
        changePlanUrl,
        {
          product, interval, intervalCount, creditCards, subscriptionId: subscription.id, token: (selectedCreditCard || {}).id,
          toCancel, cancelReason, cancelReasonSpecified, enterprisePrice,
        },
        getAxiosDefaultConfig()
      )
      const planAction = {type: 'new_plan', oldPlan, product, interval, intervalCount, date: (new Date()).toString()}
      if (resData.confirmation_url) {
        const waitingTime = 1000*60*20

        setCookie('confirming_charge', 'true', waitingTime)
        topRedirect({ url: resData.confirmation_url, waitingTime, newWindow: true, successMessage: 'Your plan option is updated' })
      }
      else {
        const { subscriptionActive, requiredAction } = checkRequiredAction({ subscription: resData }, getState().root.admin)
        dispatch({ type: 'SET_DONE_ACTION_INTERCOM', payload: planAction })
        dispatch({ type: 'SET_DONE_ACTION_INTERCOM', payload: requiredAction })
        dispatch({ type: 'SET_SUBSCRIPTION_PLAN', payload: resData })
        if (nextAddOns) {
          dispatch({ type: 'SET_ADD_ONS_PLAN', payload: nextAddOns })
        }
        dispatch({ type: 'SET_BILLING_MESSAGE_PLAN', payload: { text: successMsg, type: 'success' } })
        dispatch({ type: 'SET_BILLING_ERROR_PLAN', payload: null })
        dispatch({ type: 'SET_SUBSCRIPTION_ACTIVE_PLAN', payload: subscriptionActive })
        dispatch({ type: 'SET_PLAN_EXPIRED_PLAN', payload: false })
        if (callback) {
          callback()
        }

        const newPaidCustomerFromPlanOrder = resData.new_paid_customer
        if ((newPaidCustomerFromCcEnter || newPaidCustomerFromPlanOrder) && resData.plans && resData.plans.length) {
          const planOption = resData.plans[0]
          fireRefersionTracking(planOption, newPaidCustomerFromCcEnter || newPaidCustomerFromPlanOrder, subscription.id)
        }
      }
      if (toCancel) {
        sendGtmPixel({ email, oldPlan, cancelReason, cancelReasonSpecified }, 'plan_cancelled')
      }
      else {
        sendGtmPixel({ email, oldPlan, newPlan: product }, 'plan_changed')
      }
    }
    catch(error) {
      console.log(error)
      const errorMessage = _.get(error, ['response', 'data', 'error', 'raw', 'message'], 'Internal Error')
      dispatch({ type: 'SET_LOADING_VIEW_PLAN', payload: '' })
      dispatch({ type: 'SET_BILLING_ERROR_PLAN', payload: errorMessage })
    }
  }
}

export function updateAddOn(
  mainSubscriptionId,
  subscription,
  product,
  interval,
  intervalCount,
  creditCards,
  selectedCreditCard = {},
  callback,
  topUrl,
  newPaidCustomerFromCcEnter,
  toCancel,
  { cancelReason, cancelReasonSpecified }
) {
  const oldPlan = subscription?.plans[0] || null
  const subscriptionId = subscription?.id || null
  return async (dispatch, getState) => {
    try {
      const { user: { email } } = getState().root
      const { addOns } = getState().plan
      // const enterprisePrice = _.get(getState().plan, ['subscription', 'plan_status', 'enterprisePrice'])
      const { data: { subscription, ...resData } } = await axios.post(
        updateAddOnUrl,
        {
          product, interval, intervalCount, creditCards, subscriptionId, token: (selectedCreditCard || {}).id,
          toCancel, cancelReason, cancelReasonSpecified, mainSubscriptionId
        },
        getAxiosDefaultConfig()
      )
      const planAction = {type: 'new_plan', oldPlan, product, interval, intervalCount, date: (new Date()).toString()}
      if (resData.confirmation_url) {
        const waitingTime = 1000*60*20

        setCookie('confirming_charge', 'true', waitingTime)
        topRedirect({ url: resData.confirmation_url, waitingTime, newWindow: true, successMessage: 'Your plan option is updated' })
      }
      else {
        const { subscriptionActive, requiredAction } = checkRequiredAction({ subscription: resData }, getState().root.admin)
        const nextAddOns = toCancel ? addOns.filter(a => a.plans[0].product !== product) : addOns.reduce((acc, a) => {
          return [
            ...acc,
            ...(a.plans[0].product === product ? [] : [a]),
          ]
        }, [resData])
        console.log(nextAddOns)
        dispatch({ type: 'SET_DONE_ACTION_INTERCOM', payload: planAction })
        // dispatch({ type: 'SET_DONE_ACTION_INTERCOM', payload: requiredAction })
        dispatch({ type: 'SET_ADD_ONS_PLAN', payload: nextAddOns })
        dispatch({ type: 'SET_BILLING_MESSAGE_PLAN', payload: { text: successMsg, type: 'success' } })
        dispatch({ type: 'SET_BILLING_ERROR_PLAN', payload: null })
        if (subscription) {
          dispatch({ type: 'SET_SUBSCRIPTION_PLAN', payload: subscription })
        }
        // dispatch({ type: 'SET_SUBSCRIPTION_ACTIVE_PLAN', payload: subscriptionActive })
        // dispatch({ type: 'SET_PLAN_EXPIRED_PLAN', payload: false })
        if (callback) {
          callback()
        }

        const newPaidCustomerFromPlanOrder = resData.new_paid_customer
        if ((newPaidCustomerFromCcEnter || newPaidCustomerFromPlanOrder) && resData.plans && resData.plans.length) {
          const planOption = resData.plans[0]
          fireRefersionTracking(planOption, newPaidCustomerFromCcEnter || newPaidCustomerFromPlanOrder, subscription.id)
        }
      }
      if (toCancel) {
        sendGtmPixel({ email, oldPlan, cancelReason, cancelReasonSpecified }, 'plan_cancelled')
      }
      else {
        sendGtmPixel({ email, oldPlan, newPlan: product }, 'plan_changed')
      }
    }
    catch(error) {
      console.log(error)
      const errorMessage = _.get(error, ['response', 'data', 'error', 'raw', 'message'], 'Internal Error')
      dispatch({ type: 'SET_LOADING_VIEW_PLAN', payload: '' })
      dispatch({ type: 'SET_BILLING_ERROR_PLAN', payload: errorMessage })
    }
  }
}

export function setSubscriptionActive(flag) {
  return {
    type: 'SET_SUBSCRIPTION_ACTIVE_PLAN',
    payload: flag,
  }
}

export function setPlanExpired(flag) {
  return {
    type: 'SET_PLAN_EXPIRED_PLAN',
    payload: flag,
  }
}

export function getStripeInvoices() {
  return async (dispatch, getState) => {
    try {
      const { data: resData } = await axios.get(
        getStripeInvoicesUrl,
        getAxiosDefaultConfig()
      )

      const invoices = resData.filter(invoice => invoice.amount_due || invoice.statement_descriptor === 'PayHelm' || invoice.statement_descriptor === 'PayHelm Business').map(invoice => {

        if (invoice.object === 'invoice') {
          return {
            ...invoice,
            amount_due: invoice.amount_due / 100,
            amount_paid: invoice.amount_paid / 100,
            currency: invoice.currency.toUpperCase(),
            nickname: _.get(invoice, 'lines.data[0].plan.nickname', null),
            finalized_at: invoice.finalized_at * 1000,
            date: invoice.date * 1000,
          }
        } else {
          return {
            ...invoice,
            id: _.get(invoice, 'charges.data[0].id', null),
            amount_due: invoice.amount / 100,
            amount_paid: invoice.amount_received / 100,
            currency: invoice.currency.toUpperCase(),
            nickname: _.get(invoice, 'charges.data[0].refunded', false) ? 'Refunded' : 'Payment',
            finalized_at: invoice.created * 1000,
            date: invoice.created * 1000,
          }
        }
      }).sort((a,b) => b.created > a.created ? 1 : -1)

      dispatch({ type: 'GET_STRIPE_INVOICES', payload: invoices })
    }
    catch(error) {
      console.log(error)
      dispatch({ type: 'GET_STRIPE_INVOICES', payload: '' })
    }
  }
}

export function createCharge(amount, selectedCreditCard = {}) {
  return async (dispatch, getState) => {
    const billingChannel = getState().plan.billingChannel
    if (billingChannel !== 'stripe') {
      return
    }
    dispatch({ type: 'SET_CUSTOMER_BALANCE_LOAD', payload: true })
    try {
      const { data: resData } = await axios.post(
        createCustomerChargeUrl,
        { amount: amount, token: (selectedCreditCard || {}).id },
        getAxiosDefaultConfig()
      )
      const { data: customerRes } = await axios.get(getCustomerInfoUrl, getAxiosDefaultConfig())
      const { customerBalance } = customerRes
      dispatch({ type: 'SET_CUSTOMER_BALANCE_PLAN', payload: parseFloat(customerBalance / 100) })
      dispatch({ type: 'SET_CUSTOMER_BALANCE_LOAD', payload: false })
    }
    catch(error) {
      console.log(error)
      let message = 'Please Select a Deposit Amount'
      dispatch({ type: 'SET_SHIPPING_TOAST_NOTIFICATION', payload: true })
      dispatch({ type: 'SET_SHIPPING_TOAST_MESSAGE', payload: message })
      dispatch({ type: 'SET_CUSTOMER_BALANCE_LOAD', payload: false })
    }
  }
}

export function setBalanceTransactionsList(firstIndex, lastIndex) {
  return async (dispatch, getState) => {
    const billingChannel = getState().plan.billingChannel
    if (billingChannel !== 'stripe') {
      return
    }
    try {
      dispatch({ type: 'SET_BALANCE_FETCHING_PLAN', payload: true })
      const { data: { data: customerBalanceRes, totalCount, debits, credits } } = await axios.post(getCustomerTransactionBalanceUrl, {
        firstIndex, lastIndex
      }, getAxiosDefaultConfig())
      dispatch({ type: 'SET_CUSTOMER_BALANCE_TRANSACTION_COUNT_PLAN', payload: totalCount })
      dispatch({ type: 'SET_CUSTOMER_BALANCE_TRANSACTION_PLAN', payload: customerBalanceRes })
      dispatch({ type: 'SET_CUSTOMER_BALANCE_CREDITS', payload: parseFloat(credits / 100) })
      dispatch({ type: 'SET_CUSTOMER_BALANCE_DEBITS', payload: parseFloat(debits / 100) })
      dispatch({ type: 'SET_BALANCE_FETCHING_PLAN', payload: false })
    }
    catch(error) {
      console.log(error)
      dispatch({ type: 'SET_CUSTOMER_BALANCE_TRANSACTION_PLAN', payload: [] })
      dispatch({ type: 'SET_BALANCE_FETCHING_PLAN', payload: false })
    }
  }
}

export function updateBalanceTransactionsList() {
  return async (dispatch, getState) => {
    try {
      const { data: customerRes } = await axios.get(getCustomerInfoUrl, getAxiosDefaultConfig())
      const { customerBalance } = customerRes
      dispatch({ type: 'SET_CUSTOMER_BALANCE_PLAN', payload: parseFloat(customerBalance / 100) })
      return customerBalance / 100
    }
    catch(error) {
      console.log(error)
    }
  }
}

export function updateAutoDepositSetting(autoDepositAmount) {
  return async (dispatch, getState) => {
    try {
      await axios.post(updateAutoDepositSettingUrl, {
        autoDepositAmount
      }, getAxiosDefaultConfig())
      const { data: customerRes } = await axios.get(getCustomerInfoUrl, getAxiosDefaultConfig())
      const { autoDepositAmountSetting } = customerRes
      dispatch({ type: 'SET_AUTO_DEPOSIT_AMOUNT_SETTING_PLAN', payload: autoDepositAmountSetting })
      let message = 'Auto-Deposit settings updated'
      dispatch({ type: 'SET_SHIPPING_TOAST_NOTIFICATION', payload: true })
      dispatch({ type: 'SET_SHIPPING_TOAST_MESSAGE', payload: message })
    } catch (error) {
      console.log(error)
      let message = 'Something went wrong updating auto-deposit setting'
      dispatch({ type: 'SET_SHIPPING_TOAST_NOTIFICATION', payload: true })
      dispatch({ type: 'SET_SHIPPING_TOAST_MESSAGE', payload: message })
    }
  }
}

