import React, { useState, useEffect, useRef } from 'react'
import _ from 'lodash'
import { cn } from '@/features/utils'
import { Button } from '@/features/shadcn/ui/button'
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@/features/shadcn/ui/dropdown-menu'
import { FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/features/shadcn/ui/form'
import { InputWithIcon } from '../shadcn/ui/input-with-icon'
import { Search } from 'lucide-react'

export default function Multiselect({
  form,
  name,
  label,
  labelClassName = '',
  options,
  placeholder = 'Select an option...',
  searchPlaceholder = 'Search...',
}) {
  const [open, setOpen] = useState(false)

  const fieldValueWatch = form.watch(name)
  const [displayedValue, setDisplayedValue] = useState(placeholder)
  useEffect(() => {
    if (!fieldValueWatch || !options) {
      setDisplayedValue(placeholder)
      return
    }
    const selectedLabels = fieldValueWatch.map((fv) => options.find(({ value }) => value === fv)?.label)
    const newDisplayedValue =
      fieldValueWatch.length >= 3
        ? `${selectedLabels[0]} or ${fieldValueWatch.length - 1} more`
        : selectedLabels.join(' or ')
    setDisplayedValue(newDisplayedValue)
  }, [fieldValueWatch, options])

  // Set default options
  useEffect(() => {
    if (!options) return
    setFilteredOptions(options)
  }, [options])

  // Reset options on close
  useEffect(() => {
    if (!open) {
      setFilteredOptions(options)
    }
  }, [open])

  const [filteredOptions, setFilteredOptions] = useState([])
  const filterOptions = (e) => {
    const searchValue = e.target.value
    if (!searchValue || searchValue === '') {
      setFilteredOptions(options)
      return
    }
    const newFilteredOptions = options.filter((option) =>
      option.label.toLowerCase().includes(searchValue.toLowerCase())
    )
    setFilteredOptions(newFilteredOptions)
  }

  return (
    <FormField
      control={form.control}
      name={name}
      render={({ field }) => (
        <FormItem className="tw-w-full tw-flex tw-flex-col">
          <FormLabel className={labelClassName}>{label}</FormLabel>
          <DropdownMenu open={open} onOpenChange={setOpen}>
            <FormControl>
              <DropdownMenuTrigger asChild>
                <Button
                  variant="outline"
                  className={cn(
                    'tw-w-full tw-justify-between tw-border-slate-200 tw-shadow-sm !tw-px-3 tw-h-9 tw-grow-0 overflow-hidden'
                  )}
                >
                  {displayedValue}
                </Button>
              </DropdownMenuTrigger>
            </FormControl>
            <DropdownMenuContent className="tw-w-[--radix-dropdown-menu-trigger-width] tw-h">
              <div
                onKeyDown={(e) => {
                  if (e.key !== 'ArrowDown' && e.key !== 'ArrowUp') {
                    e.stopPropagation()
                  }
                }}
              >
                <InputWithIcon
                  autoFocus={true}
                  placeholder={searchPlaceholder}
                  onChange={filterOptions}
                  startIcon={<Search color="#94a3b8" />}
                />
              </div>
              <DropdownMenuSeparator />

              <div className="tw-max-h-[200px] tw-overflow-y-auto">
                {filteredOptions?.map((option, i) => {
                  const { label, value } = option
                  const checkedValues = field.value || []
                  const isChecked = checkedValues.includes(value) || false

                  return (
                    <DropdownMenuCheckboxItem
                      key={i}
                      checked={isChecked}
                      onSelect={(e) => e.preventDefault()}
                      onCheckedChange={() => {
                        const currentValue = field.value || []
                        const newValue = currentValue.includes(value)
                          ? _.without(currentValue, value)
                          : [...currentValue, value]
                        form.setValue(name, newValue)
                      }}
                    >
                      {label}
                    </DropdownMenuCheckboxItem>
                  )
                })}
              </div>
            </DropdownMenuContent>
          </DropdownMenu>
          <FormMessage />
        </FormItem>
      )}
    />
  )
}
