import { getCookie, setCookie } from './cookie'

const sendSignUp = accounts => {
  console.log('sendSignUp: cookie is_sign_up', getCookie('is_sign_up'))
  if (getCookie('is_sign_up')) {
    setCookie('is_sign_up', '')
    return {
      event: 'signup_success',
      email: accounts[0]?.email || 'N/A',
      merchant_id: accounts[0]?.merchant_id || 'N/A',
      channel: accounts[0]?.channel || 'N/A',
    }
  }
}

const sendOnboardSuccess = accounts => {
  return {
    event: 'onboard_success',
    email: accounts[0]?.email || 'N/A',
    merchant_id: accounts[0]?.merchant_id || 'N/A',
    channel: accounts[0]?.channel || 'N/A',
  }
}

export const sendCcAdded = card => {
  const {
    customer_id, email, brand, exp_month, exp_year, last4
  } = card
  return {
    event: 'cc_added',
    customer_id,
    email,
    brand,
    expiry: `${exp_month}/${exp_year}`,
    last4,
  }
}

export const sendOnboardStep = ({ email, step }) => {
  return {
    event: 'onboard_step_finished',
    email,
    step,
  }
}

const planChange = ({email, oldPlan, newPlan}) => {
  return {
    event: 'plan_changed',
    email,
    old_plan: oldPlan || null,
    new_plan: newPlan,
  }
}

const planCancelled = ({email, oldPlan, cancelReason, cancelReasonSpecified}) => {
  return {
    event: 'plan_cancelled',
    email,
    old_plan: oldPlan || null,
    cancel_reason: cancelReason, 
    cancel_reason_specified: cancelReasonSpecified || '',
  }
}

const pageView = ({ email, page }) => {
  return {
    event: 'pageview',
    email,
    page,
  }
}

const loginFailure = ({ email, message }) => {
  const userId = getCookie('unique_user_id')
  return {
    event: 'login_failure',
    email,
    user_id: userId,
    message,
  }
}

const loginIframe = ({ email, message }) => {
  const userId = getCookie('unique_user_id')
  return {
    event: 'login_iframe',
    email,
    user_id: userId,
    message,
  }
}

const dataFormatterDict = {
  signup_success: sendSignUp,
  cc_added: sendCcAdded,
  onboard_step_finished: sendOnboardStep,
  plan_changed: planChange,
  plan_cancelled: planCancelled,
  pageview: pageView,
  login_failure: loginFailure,
  login_iframe: loginIframe,
  onboard_success: sendOnboardSuccess,
}

export const sendGtmPixel = (data, eventType) => {
  if (!dataFormatterDict[eventType]) {
    console.error('Invalid event type', eventType)
    return
  }
  const eventData = dataFormatterDict[eventType](data)
  if (window.google_tag_manager) {
    dataLayer.push(eventData)
  }
  else {
    const script = document.getElementById('gtm-script')
    if (script) {
      script.onload = () => dataLayer.push(eventData)
    }
  }
}