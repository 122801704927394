import React from 'react'
import { connect } from 'react-redux'
import { fetchBackfillStatus } from '../../actions/backFillActions'
import { localStoragePayhelm } from '../../modules/utils'

/**
 *
 * WHY?
 *    - Because we need to run fetchBackfillStatus, but can't inside a functional component (due to legacy redux implementation).
 *      So we trigger it as a separate class component.
 *
 */
@connect(
  (store) => {
    const accounts = store.root.accounts
    return { accounts }
  },
  { fetchBackfillStatus }
)
export default class InvisibleBackfill extends React.Component {
  constructor(props) {
    super(props)
  }

  componentDidMount() {
    const { fetchBackfillStatus, accounts } = this.props
    delete localStoragePayhelm.backfillToken
    fetchBackfillStatus(
      '',
      accounts.map((_account) => _account.merchant_id)
    )
  }

  render() {
    return <div className="tw-hidden" />
  }
}
