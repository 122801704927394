export const initialState = {
  error: null,
  fetching: false,
  accounts: null,
}

export default function(state = initialState, action) {
  switch(action.type) {
    case 'SET_SHARED_ACCOUNTS_ERROR': {
      return {
        ...state,
        error: action.payload
      }
    }
    case 'SET_SHARED_ACCOUNTS_FETCH_STATUS': {
      return {
        ...state,
        fetching: action.payload
      }
    }
    case 'SET_SHARED_ACCOUNTS': {
      return {
        ...state,
        accounts: action.payload
      }
    }
  }
  return state
}