import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { z } from 'zod'
import queryString from 'query-string'
import DataTable from './DataTable'
import { Form } from '@/features/shadcn/ui/form'
import { zodResolver } from '@hookform/resolvers/zod'
import Combobox from '@/features/forms/Combobox'
import { Button } from '@/features/shadcn/ui/button'
import { selectRootDomains, selectRootUser } from '@/rootSlice'
import {
  fetchGoogleAdsReporting,
  fetchTrafficMetric,
  selectGoogleAdsReporting,
  selectGoogleAdsReportingStatus,
  selectMetricTableColumns,
  selectMetricTableRawData,
  selectMetricTableRows,
  selectSelectedRows,
  selectTrafficMetric,
  selectTrafficMetricStatus,
  setMetricTableColumns,
  setMetricTableRawData,
  setMetricTableRows,
} from '../trafficReportSlice'
import _ from 'lodash'
import { getMetricTableColumns, getMetricTableRawData, getMetricTableRows } from '../utils'
import { DEFAULT_TIME_PERIOD } from '../constants'
import TrafficChart from './TrafficChart'
import { EmptyState, LoadingState, propertyOptions } from '../Traffic'

/**
 *
 * Constants
 *
 */
const MAX_CHART_LINES = 200

/**
 *
 * Metrics related vars
 *
 */
const metricOptions = [
  { label: 'Page View', value: 'pageview' },
  { label: 'Order', value: 'order' },
  { label: 'Cart', value: 'cart' },
  { label: 'Checkout', value: 'checkout' },
  { label: 'Sales', value: 'sales_usd' },
]

export const metricToTotalKey = {
  pageview: 'total_pageviews',
  cart: 'total_carts',
  order: 'total_orders',
  checkout: 'total_checkouts',
  sales_usd: 'total_sales_usd',
}

export default function OverviewTab({ location, history, prevTimePeriod, timePeriodWatch, filtersWatch }) {
  const timezone = useSelector(selectRootUser)?.default_timezone

  /**
   *
   * Fetch the metric data
   *
   */
  const dispatch = useDispatch()
  const qs = queryString.parse(location.search)

  const tableColumns = useSelector(selectMetricTableColumns)
  const setTableColumns = (columns) => dispatch(setMetricTableColumns(columns))
  const tableRawData = useSelector(selectMetricTableRawData)
  const setTableRawData = (rawData) => dispatch(setMetricTableRawData(rawData))
  const tableRows = useSelector(selectMetricTableRows)
  const setTableRows = (rows) => dispatch(setMetricTableRows(rows))
  const tableRowCount = tableRows.length

  const metricData = useSelector(selectTrafficMetric)
  const metricStatus = useSelector(selectTrafficMetricStatus)
  useEffect(() => {
    const fn = async () => {
      const timePeriodChanged = !_.isEqual(timePeriodWatch, prevTimePeriod)
      if (!timePeriodWatch || _.isEmpty(timePeriodWatch) || !timePeriodChanged) return

      if (metricStatus === 'idle') {
        const _timePeriod = qs.timePeriod || DEFAULT_TIME_PERIOD
        const _metric = qs.metric || 'pageview'
        const _filters = qs.filters ? JSON.parse(qs.filters) : '' // [{ field: 'cartStatus', op: 'eq', value: 'abandoned' }]
        const _groupBy = qs.groupBy || ''

        const _metricData = await dispatch(
          fetchTrafficMetric({
            metric: _metric,
            timePeriod: _timePeriod,
            timezone,
            filters: _filters,
            groupBy: _groupBy,
          })
        ).unwrap()
        // console.log('_metricData on load', _metricData)

        // Turn the data into columns and data and set it in state
        const _tableColumns = getMetricTableColumns({
          metricData: _metricData,
          timePeriod: _timePeriod,
          timezone,
          groupBy: _groupBy,
        })
        setTableColumns(_tableColumns)
        // console.log('_tableColumns', _tableColumns)

        const _tableRawData = getMetricTableRawData({
          metricData: _metricData,
          metric: _metric,
          timePeriod: _timePeriod,
          timezone,
          groupBy: _groupBy,
          tableColumns: _tableColumns,
        })
        setTableRawData(_tableRawData)

        const _tableRows = getMetricTableRows(_tableRawData)
        // console.log('_tableRows', _tableRows)
        setTableRows(_tableRows)
      }
    }
    fn()
  }, [metricStatus, timePeriodWatch])

  /**
   *
   * Chart data
   *
   */
  const rowSelection = useSelector(selectSelectedRows)
  const [chartLines, setChartLines] = useState([])
  useEffect(() => {
    if (!tableRawData || _.isEmpty(tableRawData)) return

    // If the data has more than 200 rows, return
    if (tableRawData.length > MAX_CHART_LINES) return

    const _chartLines = tableRawData.map((row) => {
      const legend = row.groupBy || metricOptions.find((_metric) => _metric.value === metricWatch)?.label // when there's no segment, show the metric name
      const data = tableColumns
        .filter((d) => !_.isNil(d?.meta?.date)) // filter out segment column
        .map((col) => {
          const { accessorKey } = col
          const y = row[accessorKey] || 0
          return y
        })

      return {
        marker: {
          fillColor: 'transparent',
          lineColor: 'transparent',
        },
        name: legend,
        data,
        color: row.color,
        clip: false,
      }
    })

    // Filter out charts based on segment selection in the table
    const selectedLineIndexes = _.chain(rowSelection)
      .toPairs()
      .filter(([k, v]) => v === true)
      .map(([k, v]) => parseInt(k))
      .value()
    const filteredChartLines = _chartLines.filter((line, i) => selectedLineIndexes.includes(i))
    setChartLines(filteredChartLines)
  }, [tableRawData, rowSelection])

  /**
   *
   * Metric form
   *
   */
  const MetricFormSchema = z.object({
    metric: z.string({
      required_error: 'Please select a metric.',
    }),
    groupBy: z.string().optional(),
  })

  const metricForm = useForm({
    resolver: zodResolver(MetricFormSchema),
  })

  const metricWatch = metricForm.watch('metric')

  /**
   *
   * Set default values on load based on the url state
   *
   */
  useEffect(() => {
    metricForm.reset({
      metric: qs.metric || 'pageview',
      groupBy: qs.groupBy || '',
    })
  }, [])

  /**
   *
   * Handle submit
   *
   */
  const onSubmit = async (values) => {
    // console.log('values', values)
    const _timePeriod = timePeriodWatch
    const _metric = values.metric
    const _groupBy = values.groupBy || ''
    const _filters = filtersWatch.map((f) => ({ ...f, op: 'eq' }))

    // Set the state in the url
    const qsStr = queryString.stringify({
      ...qs,
      metric: _metric,
      groupBy: _groupBy,
    })
    history.push({ pathname: history.pathname, search: `?${qsStr}` })

    // Fetch data
    const _metricData = await dispatch(
      fetchTrafficMetric({
        metric: _metric,
        timePeriod: _timePeriod,
        timezone,
        ...(_filters ? { filters: _filters } : {}),
        ...(_groupBy ? { groupBy: _groupBy } : {}),
      })
    ).unwrap()

    // Turn the data into columns and data and set it in state
    const _tableColumns = getMetricTableColumns({
      metricData: _metricData,
      timePeriod: _timePeriod,
      timezone,
      groupBy: _groupBy,
    })
    setTableColumns(_tableColumns)

    const _tableRawData = getMetricTableRawData({
      metricData: _metricData,
      metric: _metric,
      timePeriod: _timePeriod,
      timezone,
      groupBy: _groupBy,
      tableColumns: _tableColumns,
    })
    setTableRawData(_tableRawData)

    const _tableRows = getMetricTableRows(_tableRawData)
    setTableRows(_tableRows)
  }

  const defaultSorting = qs.groupBy ? [{ id: 'groupTotal', desc: true }] : []

  return (
    <div className="tw-mt-4">
      <Form {...metricForm}>
        <form
          onSubmit={metricForm.handleSubmit(onSubmit)}
          className="tw-w-full md:tw-w-[540px] tw-flex tw-items-center tw-space-x-4 tw-mb-6"
        >
          <div className="tw-grow tw-grid tw-gap-4 tw-grid-cols-1 md:tw-grid-cols-12">
            <div className="tw-col-span-1 md:tw-col-span-5">
              <div className="tw-w-full">
                <Combobox
                  form={metricForm}
                  name="metric"
                  label="Metric"
                  labelClassName="!tw-font-bold"
                  placeholder="Select a metric"
                  options={metricOptions}
                />
              </div>
            </div>
            <div className="tw-grow tw-col-span-1 md:tw-col-span-5">
              <div className="tw-w-full">
                <Combobox
                  form={metricForm}
                  name="groupBy"
                  label="Segment by"
                  labelClassName="!tw-font-bold"
                  placeholder="Select segment"
                  options={propertyOptions}
                />
              </div>
            </div>
            <div className="tw-col-span-1 md:tw-col-span-2">
              <Button type="submit" className="md:tw-mt-[20px] !tw-h-9">
                Retrieve Data
              </Button>
            </div>
          </div>
        </form>
      </Form>

      {(!metricData || _.isEmpty(metricData)) && metricStatus !== 'loading' && (
        <div className="tw-col-span-1 md:tw-col-span-9 2xl:tw-col-span-10">
          <EmptyState />
        </div>
      )}

      {metricStatus === 'loading' && (
        <div className="tw-col-span-1 md:tw-col-span-9 2xl:tw-col-span-10">
          <LoadingState />
        </div>
      )}

      {metricStatus !== 'loading' && metricData && !_.isEmpty(metricData) && (
        <div className="tw-col-span-1 md:tw-col-span-9 2xl:tw-col-span-10 tw-space-y-10">
          {tableRowCount <= MAX_CHART_LINES && (
            <TrafficChart
              xAxis={{
                categories: _.chain(tableColumns)
                  .filter((d) => !_.isNil(d?.meta?.date)) // filter out segment column
                  .map(({ header }) => header)
                  .value(),
              }}
              yAxis={{
                title: {
                  text: '',
                },
                plotLines: [
                  {
                    value: 0,
                    width: 1,
                    color: '#808080',
                  },
                ],
              }}
              series={chartLines}
              metric={qs.metric || 'pageview'}
            />
          )}

          <div className="tw-border-t tw-border-slate-200 -tw-mx-4" />

          <div>
            {metricWatch?.includes('sales') && (
              <div className="tw-text-sm tw-text-gray-500 tw-mb-2 tw-italic">(All in US$)</div>
            )}
            <DataTable columns={tableColumns} data={tableRows} defaultSorting={defaultSorting} showPagination={true} />
          </div>
        </div>
      )}
    </div>
  )
}
