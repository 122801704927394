export const DEFAULT_AUTO_INSURANCE_RULES = [
  {
    id: 1,
    carrier: 'USPS',
    domestic: {
      enabled: false,
      amount: 0,
    },
    international: {
      enabled: false,
      amount: 0,
    },
  },
  {
    id: 108,
    carrier: 'Sure Post',
    domestic: {
      enabled: false,
      amount: 0,
    },
    international: {
      enabled: false,
      amount: 0,
    },
  },
  {
    id: 41,
    carrier: 'Mail Innovations',
    domestic: {
      enabled: false,
      amount: 0,
    },
    international: {
      enabled: false,
      amount: 0,
    },
  },
  {
    id: 2,
    carrier: 'FedEx',
    domestic: {
      enabled: false,
      amount: 0,
    },
    international: {
      enabled: false,
      amount: 0,
    },
  },
  {
    id: 66,
    carrier: 'Smart Post',
    domestic: {
      enabled: false,
      amount: 0,
    },
    international: {
      enabled: false,
      amount: 0,
    },
  },
  {
    id: 3,
    carrier: 'UPS',
    domestic: {
      enabled: false,
      amount: 0,
    },
    international: {
      enabled: false,
      amount: 0,
    },
  },
  {
    id: 10,
    carrier: 'Common Carrier',
    domestic: {
      enabled: false,
      amount: 0,
    },
    international: {
      enabled: false,
      amount: 0,
    },
  }
]