export default {
  competitors: {
    title: 'Competitors',
    bodyTitle: 'Find & analyze your eBay competitors',
    description:
      'See who is beating you in eBay sales & know how to compete on eBay. Compare your rank with top performers and price your products competitively.',
    readMoreLink: 'https://www.payhelm.com/competitor-research/'
  },
  hotproducts: {
    title: 'Hot Products',
    bodyTitle: 'Analyze the hottest products on eBay',
    description:
      'Browse million dollar opportunities and know how to price competitively to win on eBay. See who sells the products the most and how their business is winning.',
    readMoreLink: 'https://www.payhelm.com/hot-products/'
  },
  emailcampaigns: {
    title: 'Email Campaigns',
    bodyTitle: 'Intelligent email marketing & sales reporting',
    description: 'Send branded emails perfectly timed to grow your sales.',
    readMoreLink: 'https://www.payhelm.com/email-marketing/'
  }
}
