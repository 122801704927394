import { faChartBar, faChartColumn, faTable, faChartLine, faShoppingCart, faBox, faUsers, faBullhorn } from "@fortawesome/free-solid-svg-icons";


export const DEFAULT_HOME_URL = 'https://www.payhelm.com'
export const DEFAULT_COPYRIGHT = `Copyright © ${new Date().getFullYear()} payhelm.com`
export const DEFAULT_APP_HOSTNAME = 'app.payhelm.com'
export const DEFAULT_APP_PROTOCOL = 'https:'
export const DEFAULT_APP_NAME = 'PayHelm'

export const SLACK_CLIENT_ID = '180375554356.364292871202' // prod
// export const SLACK_CLIENT_ID = '180375554356.467000349764' // staging
export const SLACK_REDIRECT_URI = 'https://client.payhelm.com/auth/slack/accept' // prod
// export const SLACK_REDIRECT_URI = 'https://client.staging.payhelm.com/auth/slack/accept' // staging
export const SLACK_SCOPES = 'channels:read,chat:write,im:history,im:read,im:write,incoming-webhook,team:read,channels:join,channels:history'
export const SLACK_DATA_STATUS_CHANNEL_ID = 'C5LV4KKAM'

export const utmDataTypes = ['utmSource', 'utmMedium', 'utmCampaign', 'utmContent', 'utmTerm', 'utmId']
export const utmSolrTerms = {
  utmSource: 'utm_source',
  utmMedium: 'utm_medium',
  utmCampaign: 'utm_campaign',
  utmContent: 'utm_content',
  utmTerm: 'utm_term',
  utmId: 'utm_id',
}
export const reduxKeySuffix = {
  page: 'sPage',
  perPage: 'sPerPage',
  sortOrder: 'SortOrder'
}

export const dateTypeTitles = {
  processed_at: 'Placed',
  updated_at: 'Updated',
  fulfilled_at: 'Fulfilled',
  shipped_at: 'Shipped',
  last_refund_at: 'Refunded',
}
export const dateTypes = Object.keys(dateTypeTitles)

export const rangeTypeTitles = {
  total_price: 'Total Price Range',
  total_shipping_presentment_amount: 'Total Shipping Range',
  total_tax: 'Total Taxes Range',
  total_costs: 'Total Costs Range',
  total_refunds: 'Total Refunds Range',
  quantity: 'Quantity Items Range',
}
export const rangeTypes = Object.keys(rangeTypeTitles)
export const rangeTypeTooltips = {
  quantity: 'Analyze Total Items Sold under Orders reports. Analyze Avg. Items per Order under Products, Customers and Marketing reports.',
}

export const keywordTypeTitles = {
  order_number: 'Order ID',
  customer_email: 'Customer Email Address',
  ip_address: 'IP Address',
  external_order_id: 'External Order ID',
}
export const keywordTypePlaceholders = {
  order_number: '1001, 1002, 1003...',
  customer_email: 'john@domain.com, jane@domain.com...',
  external_order_id: '1001, 1002, 1003...',
  ip_address: '192.168.1.1, 10.0.0.1, 172.16.0.1...',
}
export const keywordTypes = Object.keys(keywordTypeTitles)

export const reportTypeTitles = {
  orders: 'Orders',
  lineItems: 'Order Line Items',
  charts: 'Breakdown',
  trends: 'Timeline',
  customers: 'Customers',
  products: 'Products',
  variants: 'Product Variants',
  brands: 'Product Brands',
  skus: 'SKUs',
  productTrends: 'Product Timeline',
  customOrderBases: 'Pivot Grouping Report'
}

export const sourceTypes = {
  overview: 'Overview',
  orders: 'Orders',
  products: 'Products',
  customers: 'Customers',
  marketing: 'Marketing',
}

export const sourceTypesV2 = [
  { label: "Overview", value: "overview", icon: faChartLine, description: 'High-level summary of key metrics and performance indicators.' },
  { label: "Orders", value: "orders", icon: faShoppingCart, description: 'Detailed table of individual orders.' },
  { label: "Products", value: "products", icon: faBox, description: 'List of all products with detailed data.' },
  { label: "Customers", value: "customers", icon: faUsers, description: 'Profiles and purchase history for customers.' },
  { label: "Marketing", value: "marketing", icon: faBullhorn, description: 'Analyze traffic and performance by marketing source.' },
];


export const sourceTypeReportTypes = {
  overview: [
    { label: "Breakdown", value: "charts", icon: faChartColumn, description: 'Visualize key metrics with charts and graphs.' },
    { label: "Timeline", value: "trends", icon: faChartBar, description: 'Track performance trends over time.' },
    { label: "Pivot & Group", value: "customOrderBases", icon: faTable, description: 'Group and analyze orders with pivot-style reports.' },
  ],
  orders: [
    { label: "Orders", value: "orders", icon: faTable, description: 'Detailed table of individual orders.' },
    { label: "Order Line Items", value: "lineItems", icon: faTable, description: 'View specific products or services in orders.' },
  ],
  products: [
    { label: "Products", value: "products", icon: faTable, description: 'List all products with detailed data.' },
    { label: "Product Variants", value: "variants", icon: faTable, description: 'View variant-specific details like size and color.' },
    { label: "Product Brands", value: "brands", icon: faTable, description: 'Focus on brand-specific performance metrics.' },
    { label: "SKUs", value: "skus", icon: faTable, description: 'Monitor inventory and sales at the SKU level.' },
    { label: "Product Timeline", value: "productTrends", icon: faChartBar, description: 'Track product sales and performance trends.' },
  ],
  customers: [
    { label: "Customers", value: "customers", icon: faTable, description: 'View profiles and purchase history for customers.' },
  ],
  marketing: [
    { label: "UTM Source", value: "utmSource", icon: faTable, description: 'Analyze traffic by UTM source.' },
    { label: "UTM Medium", value: "utmMedium", icon: faTable, description: 'Evaluate performance by UTM medium.' },
    { label: "UTM Campaign", value: "utmCampaign", icon: faTable, description: 'Track impact by UTM campaign.' },
    { label: "UTM Content", value: "utmContent", icon: faTable, description: 'Analyze performance by UTM content.' },
    { label: "UTM Term", value: "utmTerm", icon: faTable, description: 'Evaluate keyword-level targeting by UTM term.' },
    { label: "UTM ID", value: "utmId", icon: faTable, description: 'Track performance by specific UTM IDs.' },
  ],
};

export const getSourceTypeBySearchType = (searchType) => {
  for (const [sourceType, reportTypes] of Object.entries(sourceTypeReportTypes)) {
    if (reportTypes.some(reportType => reportType.value === searchType)) {
      return sourceType;
    }
  }
  return null; // or return a default value if needed
};


export const sourceTypeReportDescriptions = {
  charts: 'Visualize your data through charts and graphs, offering a high-level summary of key metrics and performance indicators.',
  trends: 'Analyze trends over time with timeline reports to track patterns and performance changes across selected periods.',
  orders: 'View a detailed table of individual orders, with the ability to select and customize displayed fields such as order ID, date, customer, and total amount.',
  lineItems: 'Drill down into order details, displaying specific products or services purchased within each order, including quantities, prices, and item-specific information.',
  customOrderBases: 'Generate customizable pivot-style reports to group, aggregate, and analyze orders based on criteria such as date, customer, or product categories.',
  products: 'Display a detailed list of all products in your catalog, with options to include product-specific data like titles, prices, and categories.',
  variants: 'Dive into detailed reports of product variants, such as size, color, or other differentiators, along with inventory and sales performance.',
  brands: 'Focus on performance metrics and details for specific brands within your catalog.',
  skus: 'Access a SKU-level report to monitor stock-keeping units with granular data such as inventory levels, pricing, and sales.',
  productTrends: 'Examine sales and performance trends of products over time, identifying best-sellers and seasonal fluctuations.',
  customers: 'View customer-specific data, including profiles, purchase history, and segmentation for better understanding and targeting.',
  utmSource: 'Analyze traffic and performance based on UTM source values, such as specific platforms or referrers.',
  utmMedium: 'Evaluate performance by UTM medium, like email, CPC, or social media channels.',
  utmCampaign: 'Break down data by UTM campaigns to track the impact of marketing initiatives and promotions.',
  utmContent: 'Gain insights into performance based on UTM content tags, useful for testing creative and messaging variations.',
  utmTerm: 'Analyze UTM terms to evaluate the effectiveness of keyword-level targeting in paid campaigns.',
  utmId: 'Track performance by specific UTM IDs, allowing for unique campaign or advertisement identification.',
};

export const fullSearchPerPage = 2147483647
export const fullSearchTrunkSize = 300
export const fullSearchSubTrunkSize = 100
