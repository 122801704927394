import React from 'react'
import { useDispatch } from 'react-redux'
import { openBackfillModal, setCurrentChannelAccount } from '../integrationsSlice'
import AccountStatus from './AccountStatus'
import BackfillButton from './BackfillButton'

export default function ChannelAccountTable({ accounts, isAdmin, backfillEnabled }) {
  const dispatch = useDispatch()
  // const filteredAccounts = accounts.filter((account) => (isAdmin ? true : account.channel !== 'eBay'))
  const headings = ['ID', 'Email', 'Status', 'Backfill']
  const headingCellPaddingClassName = 'tw-px-2 tw-py-1'
  const cellPaddingClassName = 'tw-px-2 tw-py-1'

  const onBackfillClick = (_account) => {
    dispatch(setCurrentChannelAccount(_account))
    dispatch(openBackfillModal())
  }

  return (
    <div className="tw-rounded-lg tw-border tw-border-slate-200 tw-w-full tw-text-sm tw-shadow-sm tw-overflow-x-auto">
      <table className="tw-table-auto tw-border-collapse tw-w-full">
        <thead className="tw-border-b tw-font-medium tw-text-slate-500 tw-bg-slate-50 tw-text-left">
          <tr>
            {headings.map((heading) => (
              <th key={heading} className={headingCellPaddingClassName}>
                {heading}
              </th>
            ))}
          </tr>
        </thead>

        {accounts.map((account, i) => {
          const record = [
            account.account_name || account.merchant_id,
            account.account_email,
            <AccountStatus account={account} />,
            backfillEnabled ? <BackfillButton onClick={() => onBackfillClick(account)} /> : <div>N/A</div>,
          ]

          return (
            <tbody key={account.merchant_id}>
              <tr className={i !== 0 ? `tw-border-t tw-border-slate-200` : ''}>
                {record.map((value, j) => (
                  <td key={j} className={cellPaddingClassName}>
                    <span className="tw-whitespace-nowrap">{value}</span>
                  </td>
                ))}
              </tr>
            </tbody>
          )
        })}
      </table>
    </div>
  )
}
