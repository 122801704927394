import * as React from 'react'
import * as TabsPrimitive from '@radix-ui/react-tabs'
import { cva } from 'class-variance-authority'
import { cn } from '@/features/utils'

const Tabs = TabsPrimitive.Root

const tabsListVariants = cva('tw-inline-flex ', {
  variants: {
    variant: {
      default: 'tw-items-center tw-justify-center tw-h-10 tw-rounded-md tw-bg-muted tw-p-1 tw-text-muted-foreground',
      outline: 'tw-border-b tw-border-slate-300',
      folder: 'tw-w-full',
      // Add more variants here
    },
  },
  defaultVariants: {
    variant: 'default',
  },
})
const tabsTriggerVariants = cva('tw-inline-flex tw-items-center tw-justify-center', {
  variants: {
    variant: {
      default:
        'tw-whitespace-nowrap tw-rounded-sm tw-px-3 tw-py-1.5 tw-font-medium tw-ring-offset-background tw-transition-all focus-visible:tw-outline-none focus-visible:tw-ring-2 focus-visible:tw-ring-ring focus-visible:tw-ring-offset-2 disabled:tw-pointer-events-none disabled:tw-opacity-50 data-[state=active]:tw-bg-background data-[state=active]:tw-text-foreground data-[state=active]:tw-shadow-sm',
      outline:
        '-tw-mb-[2px] tw-px-3 tw-py-1.5 tw-font-medium tw-ring-offset-background tw-transition-all tw-border-b-2 tw-border-transparent data-[state=active]:tw-border-blue-600 data-[state=active]:tw-text-foreground tw-text-muted-foreground',
      // Add more variants here
      folder:
        'tw-px-4 tw-py-2.5 tw-rounded-tl-md tw-rounded-tr-md data-[state=active]:tw-border-t data-[state=active]:tw-border-l data-[state=active]:tw-border-r data-[state=active]:tw-border-slate-200 data-[state=active]:tw-bg-white',
    },
  },
  defaultVariants: {
    variant: 'default',
  },
})

const TabsList = React.forwardRef(({ className, variant, ...props }, ref) => (
  <TabsPrimitive.List ref={ref} className={cn(tabsListVariants({ variant, className }))} {...props} />
))
TabsList.displayName = TabsPrimitive.List.displayName

const TabsTrigger = React.forwardRef(({ className, variant, ...props }, ref) => (
  <TabsPrimitive.Trigger ref={ref} className={cn(tabsTriggerVariants({ variant, className }))} {...props} />
))
TabsTrigger.displayName = TabsPrimitive.Trigger.displayName

const TabsContent = React.forwardRef(({ className, ...props }, ref) => (
  <TabsPrimitive.Content
    ref={ref}
    className={cn(
      'tw-ring-offset-background focus-visible:tw-outline-none focus-visible:tw-ring-2 focus-visible:tw-ring-ring focus-visible:tw-ring-offset-2',
      className
    )}
    {...props}
  />
))
TabsContent.displayName = TabsPrimitive.Content.displayName

export { Tabs, TabsList, TabsTrigger, TabsContent }
