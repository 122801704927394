import { localStoragePayhelm, getInitialTrendKeys, getQueryJson, } from 'modules/utils'

const initialState = {
  reportList: null,
  reportData: {},
  reportPagination: {},
  dataStatus: 'loading',
  filters: {
    keyword: '',
    author: '',
    type: '',
  },
  trendKeys: getInitialTrendKeys(),
  configModalOpen: false,
  tempReportConfig: null,
  fullSearchStatus: {
    recordName: 'order',
    state: null, // 'in-progress' or 'failed' or null
    total: null, // total count of records
    fetched: null, // fetched count of records
    message: null,
    context: null, // null as report fetching by default, others are like "CSV exporting"
  },
  customFieldsError: {
    error: false,
    message: '',
  }
}

export default function reducer(state = initialState, action) {
  switch(action.type) {
    case 'SET_REPORT_LIST_REPORTS':
      return {
        ...state,
        reportList: action.payload
      }
    case 'SET_DATA_REPORTS':
      return {
        ...state,
        reportData: {
          ...(Object.keys(action.payload).reduce((acc, reportId) => ({
            ...acc,
            [reportId]: {
              ...state.reportData[reportId],
              ...action.payload[reportId],
            }
          }), {})),
        }
      }
    case 'SET_PAGINATION_REPORTS': {
      return {
        ...state,
        reportPagination: {
          ...state.reportPagination,
          ...action.payload,
        }
      }
    }
    case 'SET_FILTERS_REPORTS':
      return {
        ...state,
        filters: {
          ...state.filters,
          ...action.payload,
        }
      }
    case 'SET_DATA_STATUS_REPORTS':
      return {
        ...state,
        dataStatus: action.payload
      }
    case 'SET_TEMP_REPORT_CONFIG_REPORTS':
      return {
        ...state,
        tempReportConfig: action.payload
      }
    case 'SET_CONFIG_MODAL_OPEN_REPORTS': {
      return {
        ...state,
        configModalOpen: action.payload,
      }
    }
    case 'SET_FULL_SEARCH_STATUS_REPORTS': {
      return {
        ...state,
        fullSearchStatus: action.payload,
      }
    }
    case 'SET_CUSTOM_FIELDS_ERROR_REPORTS': {
      return {
        ...state,
        customFieldsError: action.payload,
      }
    }
    case 'SET_PREVIEW_DATA_STATUS_REPORTS': {
      return {
        ...state,
        previewDataStatus: action.payload,
      }
    }
    case 'SET_PREVIEW_REPORT_DATA': {
      return {
        ...state,
        reportPreviewData: {
          ...state.reportPreviewData,
          ...action.payload,
        }
      }
    }
    case 'SET_REPORT_PREVIEW_PAGINATION': {
      return {
        ...state,
        reportPreviewPagination: {
          ...state.reportPreviewPagination,
          ...action.payload,
        }
      }
    }
  }
  return state
}