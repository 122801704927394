import React from 'react'
import { bool, element, oneOfType, func } from 'prop-types'
import { Route } from 'react-router-dom'


const ConditionalRoute = ({ condition = false, fallback = null, ...props }) => {
  return condition
    ? <Route {...props} />
    : <Route {...props} component={fallback} />
}

ConditionalRoute.propTypes = {
  condition: bool.isRequired,
  fallback: oneOfType([element, func])
}

export default ConditionalRoute
