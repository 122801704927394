import React from 'react'
import ChartBoxes from 'sharedComponents/ChartBoxes'
import moment from 'moment'
import { currencyValueFormat, currencyFormat } from 'formatters'

export default function Trends({ trends, currency }) {
  if (!trends.metrics) {
    return null
  }
  const dataKey = {
    array: (trends.key.array || []).map(date => moment(date)),
  }
  return trends.metrics[0].array.filter(metrics => {
    return !!metrics && Object.keys(metrics).length > 0
  }).map((metrics, index) => {
    return (
      <div key={index} className='mt1' style={{width: '100%', justifyContent: 'center', alignItems: 'center',}}>
        <ChartBoxes
          currency={currency && currency !== 'USD' ? currency : null}
          isCurrency={metrics.isCurrency}
          updateDataForExport={() => { return }}
          chartType="column"
          source="ai_overview"
          height={300}
          metricSelector={false}
          groupByMenu={false}
          canExport={false}
          defaultPrimary={metrics.title}
          defaultSecondary='None'
          metrics={[{ 
            array: [{
              ...metrics,
              ...(metrics.isCurrency ? (currency === 'USD' ? currencyFormat : currencyValueFormat) : null)
            }]
          }]}
          metricNames={trends.metricNames}
          dataKey={dataKey}
          type={trends.type}
          linkedMetrics={[[]]}
          legend={true}
          cols={{xs: 12, sm: 12, md: 12, lg: 12}}
        />
      </div>
    )
  })
}