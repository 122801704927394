import React from 'react'
import { FiSettings } from 'react-icons/fi'

export default function SettingsButton({ onClick }) {
  return (
    <div
      className="tw-cursor-pointer tw-rounded-md tw-border tw-border-slate-300 hover:tw-border-blue-600 hover:tw-bg-blue-600 hover:tw-text-white tw-transition-colors tw-duration-150 tw-shadow-sm tw-flex tw-items-center tw-space-x-2.5 tw-px-2.5 tw-py-2"
      onClick={onClick}
    >
      <div>
        <FiSettings />
      </div>
    </div>
  )
}
