import { countryCodeDict } from 'countries'
import moment from 'momentExt'
import {
  numberFormat,
  numberDecimalFormat,
  number_formatter,
  number_decimal_formatter,
  date_formatter,
  currency_formatter,
  percentage_formatter,
  duration_formatter,
  percentageFormat,
  getCurrencyStatFormatter,
  elementCurrencyFormatter,
  merchantIdFormatters,
  nameValueArrayFormatters,
  customerContactFormatters,
  arrayFormatters,
  durationFormat,
  countryFormatter,
  micro_currency_formatter,
  arrayElementFormatter,
  arrayElementFormatterCsv,
  dynamic_date_formatter,
  dynamic_province_formatter,
  year_month_day
} from 'formatters'
import get from 'lodash/get'
import React from 'react'
import _ from 'lodash'
import { getMetricTooltipContent } from 'modules/tooltipContents'

const arrayFormattersWithLink = arrayFormatters({ withLink: true })
const arrayFormattersNoLink = arrayFormatters()

export const overviewStatsNames = [
  'total_sales', 'net_sales', 'total_orders', 'total_revenue', 'total_items_sold', 'total_tax', 'total_store_fees', 'total_shippings',
  'total_costs', 'total_cost_of_goods_sold', 'total_discounts', 'total_sale_discount', 'total_profit', 'total_gross_sales', 'total_refunds',
  'total_items_refunded', 'total_cost_of_refunds', 'gross_margin', 'total_customers',
  'avg_price', 'avg_shipping', 'avg_tax', 'avg_order', 'avg_items_per_order',
  'max_sales', 'min_sales', 'max_tax', 'min_tax', 'max_shipping', 'min_shipping', 'max_discount', 'min_discount',
  'total_shipment_costs', 'total_shipment_profit', 'avg_shipment_profit', 'total_shipment_insurance_costs', 'total_shipment_insurance_value', 'tax_rate', 'gross_tax_rate',
  'avg_fulfillment_time', 'min_fulfillment_time', 'max_fulfillment_time', 'total_taxed_orders', 'total_discounted_orders', 'total_shipping_tax', 'total_store_credit_amount',
  'total_store_credit_refund', 'total_refund_tax', 'total_shipping_refund', 'gateway_fee', 'points_earned', 'points_claimed', 'points_refunded', 'total_points_net_claimed', 'total_points_claimed_and_refunded', 'gift_card_amount',
]
const productStatsNames = [
  'default_sales',
  'total_sales', 'total_orders', 'total_items_sold', 'total_products', 'total_product_taxes', 'total_product_discounts',
  'total_sale_discount', 'total_product_costs', 'total_product_profit', 'total_refunds', 'total_items_refunded', 'total_cost_of_refunds',
  'total_cost_of_goods_sold', 'gross_margin',
  'avg_price', 'avg_items_per_order', 'avg_item_tax', 'avg_item_cost', 'avg_item_discount',
  'max_item_sales', 'min_item_sales', 'max_items_sold', 'min_items_sold', 'max_item_tax', 'min_item_tax', 'max_item_cost', 'min_item_cost',
  'max_item_discount', 'min_item_discount',
]
const marketingStatsNames = [
  'utm_orders', 'utm_sales', 'utm_items_sold', 'utm_avg_price', 'utm_avg_order', 'utm_avg_items_per_order', 'utm_taxes', 'utm_shippings',
  'utm_discounts' ,'utm_profit', 'utm_refunds', 'utm_gross_margin', 'utm_customers',
  'avg_shipping', 'avg_tax',
  'max_sales', 'min_sales', 'max_tax', 'min_tax', 'max_shipping', 'min_shipping', 'max_discount', 'min_discount',
]
export const statsNames = {
  overview: overviewStatsNames,
  product: productStatsNames,
  marketing: marketingStatsNames,
}
export const facetNames = [
  'financial_status',
  'processing_method',
  'payment_method',
  'source_name',
  'app_id',
  'fulfillment_status',
  'billing_country_code',
  'billing_province',
  'billing_county_name',
  'billing_city',
  'shipping_providers',
  'shipping_methods',
  'shipping_country_code',
  'shipping_province',
  'shipping_county_name',
  'shipping_city',
  'fulfillment_full_address',
  'coupon_codes',
  'sold_hour',
  'merchant_id',
  'brand_ids',
  'marketplace_product_ids',
  'skus',
  'product_skus',
  'bin_picking_numbers',
  'category_titles',
  'catalog_titles_v1',
  'channel',
  'currency',
  'customer_group_name',
  'gateway',
  'credit_card_type',
  'order_tags',
  'tax_exempt_category',
  'sales_channel_name',
  'sales_channel_type',
  'sales_channel_platform',
  'product_metafields',
  'order_metafields',
  'taxed',
  'discounted',
  'noted',
  'gateway_fee',
  'guest',
  'points_status',
]

export const formatters = {
  stats: {
    total_sales: {
      title: 'Total Sales',
      isCurrency: true,
      ySuffix: 'sales',
    },
    net_sales: {
      title: 'Net Sales',
      isCurrency: true,
      ySuffix: 'sales',
    },
    default_sales: {
      title: 'Default Sales',
      isCurrency: true,
      ySuffix: 'sales'
    },
    total_revenue: {
      title: 'Total Revenue',
      isCurrency: true,
      ySuffix: 'revenue',
    },
    avg_price: {
      title: 'Avg. Item Price',
      isCurrency: true,
      ySuffix: 'average item price'
    },
    total_tax: {
      title: 'Total Taxes',
      isCurrency: true,
      ySuffix: 'taxes'
    },
    total_shippings: {
      title: 'Total Shipping',
      isCurrency: true,
      ySuffix: 'shipping',
    },
    total_items_sold: {
      title: 'Total Items Sold',
      ...numberFormat,
      ySuffix: 'items sold',
    },
    total_orders: {
      title: 'Total Orders',
      ...numberFormat,
      ySuffix: 'orders',
    },
    total_products: {
      title: 'Total Results',
      ...numberFormat,
      ySuffix: 'products',
    },
    total_product_taxes: {
      title: 'Total Product Taxes',
      isCurrency: true,
      ySuffix: 'product taxes',
    },
    total_product_discounts: {
      title: 'Total Product Discounts',
      isCurrency: true,
      ySuffix: 'product discounts'
    },
    total_product_costs: {
      title: 'Total Product Costs',
      isCurrency: true,
      ySuffix: 'product cost'
    },
    total_product_profit: {
      title: 'Total Product Profit',
      isCurrency: true,
      ySuffix: 'product profit',
    },
    total_store_fees: {
      title: 'Total Store Fees',
      isCurrency: true,
      ySuffix: 'store fees',
    },
    total_costs: {
      title: 'Total Costs',
      isCurrency: true,
      ySuffix: 'total cost',
    },
    total_cost_of_goods_sold: {
      title: 'Total Cost of Goods Sold',
      isCurrency: true,
      ySuffix: 'total cost of goods sold',
    },
    total_discounts: {
      title: 'Total Discounts',
      isCurrency: true,
      ySuffix: 'discounts',
    },
    total_sale_discount: {
      title: 'Total Sale Discount',
      isCurrency: true,
      ySuffix: 'sale discounts'
    },
    total_profit: {
      title: 'Total Profit',
      isCurrency: true,
      ySuffix: 'profit',
    },
    total_refunds: {
      title: 'Total Refunds',
      isCurrency: true,
      ySuffix: 'refunds',
    },
    total_items_refunded: {
      title: 'Total Items Refunded',
      ySuffix: 'refunds',
    },
    total_cost_of_refunds: {
      title: 'Total Cost of Refunds',
      isCurrency: true,
      ySuffix: 'total cost of refunds',
    },
    total_gross_sales: {
      title: 'Total Gross Sales',
      isCurrency: true,
      ySuffix: 'gross sales',
    },
    gross_margin: {
      title: 'Gross Margin',
      ySuffix: 'gross margin',
      ...percentageFormat,
    },
    total_shipping_tax: {
      title: 'Total Shipping Tax',
      isCurrency: true,
      ySuffix: 'sales',
    },
    total_customers: {
      title: 'Total Customers',
      ySuffix: 'customers',
      ...numberFormat,
    },
    total_taxed_orders: {
      title: 'Total Taxed Orders',
      ySuffix: 'taxed orders',
      ...numberFormat,
    },
    total_discounted_orders: {
      title: 'Total Discounted Orders',
      ySuffix: 'discounted orders',
      ...numberFormat,
    },
    avg_price: {
      title: 'Avg. Item Value',
      isCurrency: true,
      ySuffix: 'average item value',
    },
    avg_shipping: {
      title: 'Avg. Order Shipping',
      isCurrency: true,
      ySuffix: 'average shipping',
    },
    avg_tax: {
      title: 'Avg. Order Tax',
      isCurrency: true,
      ySuffix: 'average order tax',
    },
    avg_order: {
      title: 'Avg. Order Value',
      isCurrency: true,
      ySuffix: 'average order value',
    },
    avg_items_per_order: {
      title: 'Avg. Items per Order',
      ...numberDecimalFormat,
      ySuffix: 'average items per order',
    },
    avg_item_tax: {
      title: 'Avg. Item Tax',
      isCurrency: true,
      ySuffix: 'average item tax',
    },
    avg_item_cost: {
      title: 'Avg. Item Cost',
      isCurrency: true,
      ySuffix: 'average item cost',
    },
    avg_item_discount: {
      title: 'Avg. Item Discount',
      isCurrency: true,
      ySuffix: 'average item discount',
    },
    max_sales: {
      title: 'Max. Order Sales',
      isCurrency: true,
      ySuffix: 'maximum order sales',
    },
    min_sales: {
      title: 'Min. Order Sales',
      isCurrency: true,
      ySuffix: 'minimum order sales',
    },
    max_tax: {
      title: 'Max. Order Tax',
      isCurrency: true,
      ySuffix: 'maximum order tax',
    },
    min_tax: {
      title: 'Min. Order Tax',
      isCurrency: true,
      ySuffix: 'minimum order tax',
    },
    max_shipping: {
      title: 'Max. Order Shipping',
      isCurrency: true,
      ySuffix: 'maximum order shipping',
    },
    min_shipping: {
      title: 'Min. Order Shipping',
      isCurrency: true,
      ySuffix: 'minimum order shipping',
    },
    max_discount: {
      title: 'Max. Order Discount',
      isCurrency: true,
      ySuffix: 'maximum order discount',
    },
    min_discount: {
      title: 'Min. Order Discount',
      isCurrency: true,
      ySuffix: 'minimum order discount',
    },
    max_item_sales: {
      title: 'Max. Item Sales',
      isCurrency: true,
      ySuffix: 'maximum item sales',
    },
    min_item_sales: {
      title: 'Min. Item Sales',
      isCurrency: true,
      ySuffix: 'minimum item sales',
    },
    max_items_sold: {
      title: 'Max. Items Sold',
      ...numberFormat,
      ySuffix: 'maximum item sold',
    },
    min_items_sold: {
      title: 'Min. Items Sold',
      ...numberFormat,
      ySuffix: 'minimum item sold',
    },
    max_item_tax: {
      title: 'Max. Item Tax',
      isCurrency: true,
      ySuffix: 'maximum item tax',
    },
    min_item_tax: {
      title: 'Min. Item Tax',
      isCurrency: true,
      ySuffix: 'minimum item tax',
    },
    max_item_cost: {
      title: 'Max. Item Cost',
      isCurrency: true,
      ySuffix: 'maximum item cost',
    },
    min_item_cost: {
      title: 'Min. Item Cost',
      isCurrency: true,
      ySuffix: 'minimum item cost',
    },
    max_item_discount: {
      title: 'Max. Item Discount',
      isCurrency: true,
      ySuffix: 'maximum item discount',
    },
    min_item_discount: {
      title: 'Min. Item Discount',
      isCurrency: true,
      ySuffix: 'minimum item discount',
    },
    utm_sales: {
      title: 'Total Sales',
      isCurrency: true,
    },
    utm_avg_price: {
      title: 'Avg. Item Price',
      isCurrency: true,
    },
    utm_avg_order: {
      title: 'Avg. Order Price',
      isCurrency: true,
    },
    utm_avg_items_per_order: {
      title: 'Avg. Items per Order',
      ...numberDecimalFormat,
    },
    utm_items_sold: {
      title: 'Total Items Sold',
      ...numberFormat,
    },
    utm_orders: {
      title: 'Total Orders',
      ...numberFormat,
    },
    utm_discounts: {
      title: 'Total Discounts',
      isCurrency: true,
    },
    utm_shippings: {
      title: 'Total Shipping',
      isCurrency: true,
    },
    utm_store_fees: {
      title: 'Total Store Fees',
      isCurrency: true,
    },
    utm_taxes: {
      title: 'Total Taxes',
      isCurrency: true,
    },
    utm_costs: {
      title: 'Total Costs',
      isCurrency: true,
    },
    utm_profit: {
      title: 'Total Profit',
      isCurrency: true,
    },
    utm_refunds: {
      title: 'Total Refunds',
      isCurrency: true,
    },
    utm_gross_margin: {
      title: 'Total Gross Margin',
      ...percentageFormat,
    },
    utm_customers: {
      title: 'Total Customers',
      ...numberFormat,
    },
    total_shipment_costs: {
      title: 'Total Shipping Label Cost',
      isCurrency: true,
    },
    total_shipment_insurance_costs: {
      title: 'Total Shipping Insurance Cost',
      isCurrency: true,
    },
    total_shipment_insurance_value: {
      title: 'Total Shipping Insurance Value',
      isCurrency: true,
    },
    total_shipment_profit: {
      title: 'Total Shipping Profit',
      isCurrency: true,
    },
    avg_shipment_profit: {
      title: 'Average Shipping Profit',
      isCurrency: true,
    },
    tax_rate: {
      title: 'Tax Rate',
      ...percentageFormat
    },
    gross_tax_rate: {
      title: 'Gross Tax Rate',
      ...percentageFormat
    },
    avg_fulfillment_time: {
      title: 'Average Fulfillment Time',
      ...durationFormat,
      formatter: duration_formatter
    },
    min_fulfillment_time: {
      title: 'Minimum Fulfillment Time',
      ...durationFormat
    },
    max_fulfillment_time: {
      title: 'Maximum Fulfillment Time',
      ...durationFormat
    },
    total_store_credit_amount: {
      title: 'Total Store Credit',
      isCurrency: true
    },
    total_store_credit_refund: {
      title: 'Total Store Credit Refund',
      isCurrency: true
    },
    total_refund_tax: {
      title: 'Total Sales Tax Refund',
      isCurrency: true,
    },
    total_shipping_refund: {
      title: 'Total Shipping Refund',
      isCurrency: true,
    },
    gateway_fee: {
      title: 'Total Gateway Fees',
      isCurrency: true,
    },
    points_earned: {
      title: 'Total Points Earned',
      ...numberFormat,
    },
    points_claimed: {
      title: 'Total Points Claimed',
      ...numberFormat,
    },
    points_refunded: {
      title: 'Total Points Refunded',
      ...numberFormat,
    },
    total_points_claimed_and_refunded: {
      title: 'Total Points Claimed and Refunded',
      ...numberFormat,
    },
    total_points_net_claimed: {
      title: 'Total Points Net Claimed',
      ...numberFormat,
    },
    gift_card_amount: {
      title: 'Total Gift Card Sales',
      isCurrency: true
    }
  },
  facets: {
    financial_status: {
      title: 'Payment Status',
      default_chart: 'column'
    },
    processing_method: {
      title: 'Processing Method',
      default_chart: 'pie'
    },
    payment_method: {
      title: 'Payment Method',
      default_chart: 'pie',
    },
    source_name: {
      title: 'Source',
      default_chart: 'pie'
    },
    app_id: {
      title: 'Sales Channel ID',
      default_chart: 'pie'
    },
    fulfillment_status: {
      title: 'Fulfillment Status',
      default_chart: 'column'
    },
    shipping_providers: {
      title: 'Shipping Provider',
      default_chart: 'column',
    },
    shipping_methods: {
      title: 'Shipping Method',
      default_chart: 'column',
    },
    billing_country_code: {
      title: 'Country Bought In',
      default_chart: 'pie',
      isCountryCode: true,
    },
    billing_province: {
      title: 'State/Province Bought In',
      default_chart: 'pie'
    },
    shipping_country_code: {
      title: 'Country/Region Shipped To',
      default_chart: 'pie',
      isCountryCode: true,
    },
    billing_county_name: {
      title: 'County Bought In',
      default_chart: 'pie',
    },
    billing_city: {
      title: 'City Bought In',
      default_chart: 'pie',
    },
    shipping_province: {
      title: 'State/Province Shipped To',
      default_chart: 'pie',
    },
    shipping_county_name: {
      title: 'County Shipped To',
      default_chart: 'pie',
    },
    shipping_city: {
      title: 'City Shipped To',
      default_chart: 'pie',
    },
    fulfillment_full_address: {
      title: 'Fulfillment Address',
      default_chart: 'pie'
    },
    coupon_codes: {
      title: 'Coupon Code',
      default_chart: 'column',
    },
    sold_hour: {
      title: 'Sold Hour',
      default_chart: 'column',
    },
    merchant_id: {
      title: 'Account',
      default_chart: 'pie'
    },
    marketplace_product_ids: {
      title: 'Marketplace Product ID',
      default_chart: 'pie',
    },
    skus: {
      title: 'SKU',
      default_chart: 'pie',
    },
    product_skus: {
      title: 'Product SKU',
      default_chart: 'pie',
    },
    bin_picking_numbers: {
      title: 'Bin Picking Number',
      default_chart: 'pie',
    },
    brand_ids: {
      title: 'Brand',
      default_chart: 'pie',
    },
    category_titles: {
      title: 'Category/Collection',
      default_chart: 'pie',
    },
    catalog_titles_v1: {
      title: 'Catalog',
      default_chart: 'pie',
    },
    channel: {
      title: 'Channel',
      default_chart: 'pie',
    },
    currency: {
      title: 'Currency',
      default_chart: 'pie',
    },
    customer_group_name: {
      title: 'Customer Group',
      default_chart: 'pie',
    },
    gateway: {
      title: 'Gateway',
      default_chart: 'pie',
    },
    credit_card_type: {
      title: 'Credit Card Type',
      default_chart: 'pie',
    },
    order_tags: {
      title: 'Tags',
      default_chart: 'column',
    },
    tax_exempt_category: {
      title: 'Tax Exempt Category',
      default_chart: 'column'
    },
    sales_channel_name: {
      title: 'Sales Channel Name',
      default: 'column',
    },
    sales_channel_type: {
      title: 'Sales Channel Type',
      default: 'column',
    },
    sales_channel_platform: {
      title: 'Sales Channel Platform',
      default: 'column',
    },
    product_metafields: {
      title: 'Product Metafields',
      default: 'pie',
    },
    order_metafields: {
      title: 'Order Metafields',
      default: 'pie',
    },
    taxed: {
      title: 'Taxed vs. Non-Taxed',
      default: 'pie',
    },
    discounted: {
      title: 'Discounted vs. Non-Discounted',
      default: 'pie',
    },
    guest: {
      title: 'Guest vs. Non-Guest',
      default: 'pie',
    },
    noted: {
      title: 'With vs. Without Staff Notes',
      default: 'pie',
    },
    gateway_fee: {
      title: 'Gateway Fee',
      default: 'column',
    },
    points_status: {
      title: 'Points Status',
      default: 'column',
    },
    gift_cards: {
      title: 'Yes Gift Cards vs. No Gift Cards',
      default: 'pie',
    }
  },
  productFacets: {
    options: {
      title: 'Options',
      customized: true,
      default_chart: 'column'
    },
    custom_fields: {
      title: 'Custom Fields',
      customized: true,
      default_chart: 'column'
    },
    category_titles: {
      title: 'Category',
      default_chart: 'column'
    },
    catalog_titles_v1: {
      title: 'Catalog',
      default_chart: 'column'
    },
    product_type: {
      title: 'Product Tags',
      default_chart: 'column'
    },
    brand_name: {
      title: 'Brand',
      default_chart: 'column'
    },
    vendor: {
      title: 'Vendor',
      default_chart: 'column',
    },
    gtin: {
      title: 'GTIN',
      default_chart: 'column'
    },
    epid: {
      title: 'ePID',
      default_chart: 'column'
    },
    marketplace_product_id: {
      title: 'Marketplace Product ID',
      default_chart: 'column',
    },
    sku: {
      title: 'SKU',
      default_chart: 'column'
    },
    product_sku: {
      title: 'Product SKU',
      default_chart: 'column'
    },
    bin_picking_number: {
      title: 'Bin Picking Number',
      default_chart: 'pie'
    },
    upc: {
      title: 'UPC',
      default_chart: 'column'
    },
    isbn: {
      title: 'ISBN',
      default_chart: 'column'
    },
    ean: {
      title: 'EAN',
      default_chart: 'column'
    },
  },
  orderFacets: {
    customer_id: {
      title: 'Customer ID',
      default_chart: 'column'
    },
    customer_email: {
      title: 'Customer Email',
      default_chart: 'column'
    },
    custom_fields: {
      title: 'Custom Fields',
      customized: true,
      default_chart: 'column'
    },
    customer_form_fields: {
      title: 'Customer Form Fields',
      customized: true,
      default_chart: 'column'
    },
    shipping_form_fields: {
      title: 'Shipping Form Fields',
      customized: true,
      default_chart: 'column'
    },
    customer_custom_attributes: {
      title: 'Customer Attributes',
      customized: true,
      default_chart: 'column'
    },
    financial_status: {
      title: 'Payment Status',
      default_chart: 'column'
    },
    processing_method: {
      title: 'Processing Method',
      default_chart: 'pie'
    },
    payment_method: {
      title: 'Payment Method',
      default_chart: 'pie',
    },
    source_name: {
      title: 'Source',
      default_chart: 'pie'
    },
    app_id: {
      title: 'Sales Channel ID',
      default_chart: 'pie'
    },
    fulfillment_status: {
      title: 'Fulfillment Status',
      default_chart: 'column'
    },
    shipping_providers: {
      title: 'Shipping Provider',
      default_chart: 'column',
    },
    shipping_methods: {
      title: 'Shipping Method',
      default_chart: 'column',
    },
    billing_country_code: {
      title: 'Country Bought In',
      default_chart: 'pie',
      isCountryCode: true,
    },
    billing_province: {
      title: 'State/Province Bought In',
      default_chart: 'pie'
    },
    shipping_country_code: {
      title: 'Country/Region Shipped To',
      default_chart: 'pie',
      isCountryCode: true,
    },
    billing_county_name: {
      title: 'County Bought In',
      default_chart: 'pie',
    },
    billing_city: {
      title: 'City Bought In',
      default_chart: 'pie',
    },
    shipping_province: {
      title: 'State/Province Shipped To',
      default_chart: 'pie',
    },
    shipping_county_name: {
      title: 'County Shipped To',
      default_chart: 'pie',
    },
    shipping_city: {
      title: 'City Shipped To',
      default_chart: 'pie',
    },
    fulfillment_full_address: {
      title: 'Fulfillment Address',
      default_chart: 'pie'
    },
    coupon_codes: {
      title: 'Coupon Code',
      default_chart: 'column',
    },
    sold_hour: {
      title: 'Sold Hour',
      default_chart: 'column',
    },
    merchant_id: {
      title: 'Account',
      default_chart: 'pie'
    },
    marketplace_product_ids: {
      title: 'Marketplace Product ID',
      default_chart: 'pie',
    },
    skus: {
      title: 'SKU',
      default_chart: 'pie',
    },
    product_skus: {
      title: 'Product SKU',
      default_chart: 'pie',
    },
    bin_picking_numbers: {
      title: 'Bin Picking Number',
      default_chart: 'pie',
    },
    brand_names: {
      title: 'Brand',
      default_chart: 'pie',
    },
    category_titles: {
      title: 'Category/Collection',
      default_chart: 'pie',
    },
    catalog_titles_v1: {
      title: 'Catalog',
      default_chart: 'pie',
    },
    channel: {
      title: 'Channel',
      default_chart: 'pie',
    },
    currency: {
      title: 'Currency',
      default_chart: 'pie',
    },
    customer_group_name: {
      title: 'Customer Group',
      default_chart: 'pie',
    },
    gateway: {
      title: 'Gateway',
      default_chart: 'pie',
    },
    credit_card_type: {
      title: 'Credit Card Type',
      default_chart: 'pie',
    },
    order_tags: {
      title: 'Tags',
      default_chart: 'column',
    },
    tax_exempt_category: {
      title: 'Tax Exempt Category',
      default_chart: 'column'
    },
    sales_channel_name: {
      title: 'Sales Channel Name',
      default: 'column',
    },
    sales_channel_type: {
      title: 'Sales Channel Type',
      default: 'column',
    },
    sales_channel_platform: {
      title: 'Sales Channel Platform',
      default: 'column',
    },
    product_metafields: {
      title: 'Product Metafields',
      default: 'pie',
    },
    order_metafields: {
      title: 'Order Metafields',
      default: 'pie',
    },
    taxed: {
      title: 'Taxed vs. Non-Taxed',
      default: 'pie',
    },
    discounted: {
      title: 'Discounted vs. Non-Discounted',
      default: 'pie',
    },
    guest: {
      title: 'Guest vs. Non-Guest',
      default: 'pie',
    },
    noted: {
      title: 'With vs. Without Staff Notes',
      default: 'pie',
    },
    gateway_fee: {
      title: 'Gateway Fee',
      default: 'column',
    },
    points_status: {
      title: 'Points Status',
      default: 'column',
    },
    customer_created_at: {
      title: 'Customer Created Date',
      default: 'column',
    },
    utm_campaign: {
      title: 'UTM Campaign',
      default: 'column',
    },
    utm_medium: {
      title: 'UTM Medium',
      default: 'column',
    },
    utm_source: {
      title: 'UTM Source',
      default: 'column',
    },
    utm_term: {
      title: 'UTM Term',
      default: 'column',
    },
    utm_content: {
      title: 'UTM Content',
      default: 'column',
    },
    utm_id: {
      title: 'UTM ID',
      default: 'column',
    }
  },
  trends: {
    total_items_sold: {
      title: 'Items Sold',
      csvKey: 'items_sold',
      mapping: d => get(d, ['items_sold'], 0),
      ...numberFormat
    },
    total_orders: {
      title: 'Orders',
      csvKey: 'orders',
      mapping: d => get(d, ['count'], 0),
      ...numberFormat
    },
    total_sales: {
      title: 'Sales',
      csvKey: 'sales',
      mapping: d => get(d, ['sales'], 0),
      isCurrency: true,
    },
    net_sales: {
      title: 'Net Sales',
      csvKey: 'net_sales',
      mapping: d => get(d, ['net_sales'], 0),
      isCurrency: true,
    },
    total_gross_sales: {
      title: 'Gross Sales',
      csvKey: 'gross_sales',
      mapping: d => get(d, ['gross_sales'], 0),
      isCurrency: true,
    },
    avg_price: {
      title: 'Average Item Price',
      csvKey: 'avg_price_per_item',
      mapping: d => get(d, ['avg_price_per_item'], 0),
      isCurrency: true,
    },
    total_tax: {
      title: 'Taxes',
      csvKey: 'tax',
      mapping: d => get(d, ['tax'], 0),
      isCurrency: true,
    },
    total_shippings: {
      title: 'Shipping',
      csvKey: 'shipping',
      mapping: d => get(d, ['shipping'], 0),
      isCurrency: true,
    },
    total_discounts: {
      title: 'Discounts',
      csvKey: 'discount',
      mapping: d => get(d, ['discount'], 0),
      isCurrency: true,
    },
    total_store_fees: {
      title: 'Store Fees',
      csvKey: 'store_fee',
      mapping: d => get(d, ['store_fee'], 0),
      isCurrency: true,
    },
    total_customers: {
      title: 'Customers',
      csvKey: 'customers',
      mapping: d => get(d, ['customers'], 0),
      ...numberFormat,
    },
    total_taxed_orders: {
      title: 'Total Taxed Orders',
      csvKey: 'taxed_orders',
      mapping: d => get(d, ['taxed_orders'], 0),
      ...numberFormat,
    },
    total_discounted_orders: {
      title: 'Total Discounted Orders',
      csvKey: 'discounted_orders',
      mapping: d => get(d, ['discounted_orders'], 0),
      ...numberFormat,
    },
  }
}

export const facetFormatters = formatters.facets

/**
 * Convert unified feed raw date into the Dashboard-Overview's format
 * @param {Object} stats raw stats from cloudsearch
 * @param {Object} facets raw facets from cloudsearch
 * @param {Object} trends raw trends data { [date]: {[metric]: count}}
 * @param {Array} currencies array of currency code
 */
export const convertToOverviewMetrics = (stats, facets, trends, trendsHour, currencies = ['USD'], metricConfigs, chartConfigs = {}, trendKeys) => {
  let summary = { facets: {} }
  const currencyStatFormatter = getCurrencyStatFormatter(currencies)
  summary.stats = formatStats(stats, overviewStatsNames, currencyStatFormatter, metricConfigs, 'order')

  if (facets) {
    facetNames.filter(facetName => facets[facetName] !== undefined).forEach(facetName => {
      const categoryFormatter = formatters.facets[facetName].isCountryCode ? (
        bucket => countryCodeDict[bucket.val] || 'N/A'
      ) : (
        bucket => bucket.title || bucket.val
      )
      const [firstMetric, ...extraMetrics] = _.get(chartConfigs, [facetName, 'metric'], 'total_orders').split(',')
      const [firstChartType, ...extraChartTypes] = _.get(chartConfigs, [facetName, 'chart_type'], 'column').split(',')
      summary.facets[facetName] = {
        formatters: Object.assign({}, ...overviewStatsNames.map(metric => ({
          [metric]: formatters.stats[metric].isCurrency ? currencyStatFormatter : formatters.stats[metric]
        }))),
        ...formatters.facets[facetName],
        ...chartConfigs[facetName],
        key: facetName,
        categories: (facets[facetName] || []).map(categoryFormatter),
        values: (facets[facetName] || []).map(bucket => bucket.val),
        series: Object.assign({}, ...overviewStatsNames.map(metric => ({
          [metric]: (facets[facetName] || []).map(bucket => bucket[metric]),
        }))),
        metric: firstMetric,
        metrics: overviewStatsNames,
        yAxisTitle: formatters.stats[firstMetric].title,
        ySuffix: formatters.stats[firstMetric].ySuffix,
        chart_type: firstChartType,
        extraMetricConfigs: extraMetrics.reduce((acc, extraMetric, index) => ({
          ...acc,
          [extraMetric]: {
            yAxisTitle: formatters.stats[extraMetric].title,
            ySuffix: formatters.stats[extraMetric].ySuffix,
            chart_type: extraChartTypes[index],
            metric: extraMetric,
          }
        }), {})
      }
    })
  }
  summary.trends = trends && trendKeys ? formatTrends(trends, trendKeys, currencyStatFormatter) : {}
  if (trendsHour) {
    summary.trends.additionalGroupedMetrics = {
      Hour: formatTrends(trendsHour, trendKeys, currencyStatFormatter, 'Hour')
    }
  }
  return summary
}

/**
 * Convert unified feed raw date into the Dashboard-Marketing's format
 * @param {Object} stats raw stats from cloudsearch
 * @param {Array} currencies array of currency code
 */
export const convertToMarketingMetrics = (stats, currencies, metricConfigs) => {
  const currencyStatFormatter = getCurrencyStatFormatter(currencies)
  return {
    stats: formatStats(stats, marketingStatsNames, currencyStatFormatter, metricConfigs, 'marketing')
  }
}

export const convertToProductMetrics = (stats, currencies, metricConfigs) => {
  const currencyStatFormatter = getCurrencyStatFormatter(currencies)
  return {
    stats: formatStats(stats, productStatsNames, currencyStatFormatter, metricConfigs, 'product')
  }
}

/**
 * Convert raw stats data into component ChartBoxes' data format
 * @param {Object} stats
 * @param {Array} statsNames
 * @param {Function} currencyStatFormatter
 */
const formatStats = (stats, statsNames, currencyStatFormatter, metricConfigs, tooltipType) => {
  const result = {}
  if (stats) {
    statsNames.filter(statName => stats[statName] !== undefined).forEach(statName => {
      result[statName] = {
        ...formatters.stats[statName],
        ...(formatters.stats[statName].isCurrency ? currencyStatFormatter : {}),
        metric: statName,
        value: stats[statName],
        visible: _.get(metricConfigs, [statName, 'visible'], false),
        order: _.get(metricConfigs, [statName, 'order'], 0),
        tooltip: getMetricTooltipContent(tooltipType, statName),
      }
    })
  }
  return result
}

/**
 * Convert raw trends data into component ChartBoxes' data format
 * @param {Object} trends raw trends data
 * @example Input trends data. Fields are selected by `formatters.trends[].mapping`:
 *  {
 *    date: [date],
 *    products: {
 *      items_sold: integer,
 *      sales: float
 *    },
 *    orders: {
 *      number: integer,
 *      sales: float
 *    }
 *  }
 */
const formatTrends = (trends, trendKeys, currencyStatFormatter, dateType = 'Date') => {
  const dateArray = trends.map(({ date }) => moment(date))
  const metricNames = Object.keys(formatters.trends).map(metric => formatters.trends[metric])
  const metricKeys = trendKeys.map(trendKey => _.findKey(formatters.trends, trend => trend.csvKey === trendKey))
  const metrics = [
    {
      array: metricKeys.map(metric => {
        return metric ?  {
          ...formatters.trends[metric],
          ...(formatters.trends[metric].isCurrency ? currencyStatFormatter : {}),
          name: formatters.trends[metric].title,
          data: trends.map(formatters.trends[metric].mapping)
        } : {}
      })
    }
  ]
  return {
    key: { array: dateArray, title: dateType },
    metricNames,
    metrics,
    type: 'timeline_series'
  }
}

export const formatProductTrendsForCsv = (productTrends, metricKeys, currencies, [field, fieldValue], formatterSet) => {
  const fieldTitle = productTrendsConfig.titles[field]
  const exportedFieldValue = (fieldValue ? `${fieldTitle} (${fieldValue})` : fieldTitle).replace(/"/g, '""')
  let output = []
  for (var productTrend of productTrends) {
    for (var metricKey of metricKeys) {
      output.push({
        [`"${exportedFieldValue}"`]: productTrend.value,
        ['Metric']: productTrendsConfig.titles[metricKey],
        ...(productTrend.trends.reduce((acc, trend) => ({
          ...acc,
          [trend.val]: formatterSet[metricKey] ? formatterSet[metricKey](trend[metricKey], trend.currency, { currencies }) : trend[metricKey]
        }), {}))
      })
    }
  }
  return output
}

export const convertFacetToListTableConfig = facet => {
  const { values, key, metric, extraMetricConfigs, series, title } = facet
  const metricKeys = [metric, ...Object.keys(extraMetricConfigs)]
  const data = values.map((v, vIndex) => metricKeys.reduce((acc, k) => ({
    ...acc,
    [k]: series[k][vIndex]
  }), { [key]: v }))
  const keys = [key, ...metricKeys]
  const names = metricKeys.reduce((acc, k) => ({
    ...acc,
    [k]: formatters.stats[k].title
  }), { [key]: title })
  const exportedFormatters = metricKeys.reduce((acc, k) => ({
    ...acc,
    ...(facet.formatters[k].formatter ? { [k]: facet.formatters[k].formatter} : null),
  }), {})
  const exportedFormats = metricKeys.reduce((acc, k) => ({
    ...acc,
    ...(facet.formatters[k].format ? { [k]: facet.formatters[k].format} : null),
  }), {})
  return { data, formatters: exportedFormatters, formats: exportedFormats, keys, names }
}

export const transactionsConfig = {
  keys: [
    'order_number', 'customer_id', 'customer_name', 'customer_email', 'customer_phone', 'customer_company', 'customer_group_name', 'sales_channel_name',
    'sales_channel_type', 'sales_channel_platform', 'billing_full_address', 'shipping_name', 'shipping_first_name', 'shipping_last_name',
    'shipping_full_address', 'shipping_city', 'shipping_province', 'shipping_zip', 'shipping_county_name', 'shipping_city', 'tax_exempt_category',
    'total_price', 'subtotal_price', 'quantity', 'total_costs', 'total_cost_of_goods_sold', 'grand_total', 'total_tax', 'tax_rate',
    'gross_tax_rate', 'total_discounts', 'total_sale_discount', 'total_shipping_presentment_amount', 'total_store_fees','total_profit',
    'total_refunds', 'total_items_refunded', 'total_cost_of_refunds', 'last_refund_at', 'gross_margin', 'coupon_codes', 'line_items',
    'marketplace_product_ids', 'skus', 'product_skus', 'bin_picking_numbers', 'order_tags', 'financial_status', 'fulfillment_status', 'fulfilled_at', 'shipment_cost',
    'shipment_insurance_cost', 'shipment_insurance_value', 'shipment_profit', 'shipping_tax', 'shipments', 'source_name', 'processing_method', 'gateway', 'payment_method',
    'fulfillment_time_in_seconds', 'processed_at', 'updated_at', 'merchant_id', 'category_titles', 'catalog_titles_v1', 'brand_names', 'product_ids', 'shipping_providers',
    'shipping_methods', 'billing_street_address', 'shipping_street_address', 'shipping_country', 'billing_country', 'billing_city', 'currency', 'net_sales',
    'custom_fields', 'note', 'customer_message', 'product_metafields', 'order_metafields', 'fulfillment_city', 'fulfillment_name',
    'fulfillment_province_code', 'fulfillment_zip', 'fulfillment_county_name', 'fulfillment_full_address',
    'payout_amounts', 'payout_ids', 'payout_transaction_ids', 'payout_dates', 'payout_fees', 'credit_card_type', 'refund_tax', 'total_product_refund',
    'utm_campaign','utm_content','utm_medium','utm_source','utm_term', 'utm_id', 'tracking_number', 'total_shipping_tax', 'store_credit_amount', 'total_store_credit_refund', 'shipping_refund',
    'gateway_fee', 'options', 'currency_rate', 'base_currency', 'points_earned', 'points_claimed', 'points_refunded', 'points_status', 'gift_card_amount', 'ip_address', 'external_order_id', 'customer_created_at'
  ],
  sortableFields: [
    'order_number', 'total_price', 'quantity', 'total_costs', 'total_tax',
    'total_discounts', 'total_sale_discount', 'total_shipping_presentment_amount', 'total_store_fees', 'total_refunds', 'total_items_refunded', 'total_cost_of_refunds', 'last_refund_at',
    'financial_status', 'fulfillment_status', 'fulfillment_time_in_seconds', 'processed_at', 'updated_at',
    'shipping_name', 'shipping_first_name', 'shipping_last_name', 'shipping_city', 'shipping_province', 'shipping_zip', 'shipping_county_name', 'shipping_city', 'points_earned', 'points_claimed', 'points_refunded', 'gift_card_amount', 'ip_address', 'external_order_id', 'store_credit_amount', 'customer_created_at'
  ],
  titles: {
    order_number: 'Order',
    tracking_number: 'Tracking Number',
    customer_name: 'Name',
    customer_id: 'Customer ID#',
    customer_email: 'Email',
    customer_phone: 'Phone',
    customer_company: 'Company',
    customer_group_name: 'Customer Group',
    customer_created_at: 'Customer Created Date',
    billing_full_address: 'Billing Address',
    billing_street_address: 'Billing Street Address',
    shipping_name: 'Shipping Name',
    shipping_first_name: 'Shipping First Name',
    shipping_last_name: 'Shipping Last Name',
    shipping_full_address: 'Shipping Address',
    shipping_street_address: 'Shipping Street Address',
    shipping_city: 'Shipping City',
    shipping_province: 'Shipping State/Province',
    shipping_zip: 'Shipping Zip/Postal',
    shipping_county_name: 'Shipping County',
    fulfillment_city: 'Fulfillment City',
    fulfillment_name: 'Fulfillment Name',
    fulfillment_province_code: 'Fullfillment State/Province',
    fulfillment_zip: 'Fulfillment Zip/Postal',
    fulfillment_county_name: 'Fulfillment County',
    fulfillment_full_address: 'Fulfillment Full Address',
    tax_exempt_category: 'Tax Exempt Category',
    total_price: 'Total Price',
    subtotal_price: 'Subtotal Price',
    quantity: 'Total Items Sold',
    total_costs: 'Total Costs',
    total_tax: 'Total Taxes',
    total_discounts: 'Total Discounts',
    total_sale_discount: 'Total Sale Discount',
    total_shipping_presentment_amount: 'Total Shipping',
    total_store_fees: 'Store Fee',
    total_profit: 'Total Profit',
    total_shipping_tax: 'Total Shipping Tax',
    total_refunds: 'Total Refunds',
    total_items_refunded: 'Total Items Refunded',
    total_cost_of_refunds: 'Total Cost of Refunds',
    total_cost_of_goods_sold: 'Total Cost of Goods Sold',
    grand_total: 'Grand Total',
    tax_rate: 'Tax Rate',
    gross_tax_rate: 'Gross Tax Rate',
    last_refund_at: 'Refund Date',
    gross_margin: 'Gross Margin',
    coupon_codes: 'Coupon Code(s)',
    line_items: 'Items',
    marketplace_product_ids: 'Marketplace Product ID',
    skus: 'SKUs',
    product_skus: 'Product SKUs',
    bin_picking_numbers: 'Bin Picking Numbers',
    order_tags: 'Tags',
    financial_status: 'Payment Status',
    fulfillment_status: 'Fulfillment Status',
    fulfilled_at: 'Fulfilled At',
    fulfillment_time_in_seconds: 'Fulfillment Time',
    shipment_cost: 'Shipping Label Cost',
    shipment_insurance_cost: 'Shipping Insurance Cost',
    shipment_insurance_value: 'Shipping Insurance Value',
    shipment_profit: 'Shipping Profit',
    shipping_tax: 'Shipping Tax',
    shipments: 'Shipping Info',
    source_name: 'Source',
    processing_method: 'Processing Method',
    gateway: 'Payment Gateway',
    payment_method: 'Payment Method',
    processed_at: 'Placed At',
    updated_at: 'Last Updated',
    merchant_id: 'Store/Merchant ID',
    category_titles: 'Categories',
    catalog_titles_v1: 'Catalogs',
    brand_names: 'Brands',
    product_ids: 'Product ID(s)',
    shipping_providers: 'Shipping Provider(s)',
    shipping_methods: 'Shipping Method(s)',
    sales_channel_name: 'Sales Channel Name',
    sales_channel_type: 'Sales Channel Type',
    sales_channel_platform: 'Sales Channel Platform',
    shipping_country: 'Shipping Country',
    billing_country: 'Billing Country',
    billing_city: 'Billing City',
    currency: 'Currency',
    net_sales: 'Net Sales',
    custom_fields: 'Custom Fields',
    note: 'Staff Note',
    customer_message: 'Customer Message',
    product_metafields: 'Product Metafields',
    order_metafields: 'Order Metafields',
    utm_campaign: 'UTM Campaign',
    utm_content: 'UTM Content',
    utm_medium: 'UTM Medium',
    utm_source: 'UTM Source',
    utm_term: 'UTM Term',
    utm_id: 'UTM ID',
    credit_card_type: 'Credit Card Type',
    refund_tax: 'Sales Tax Refund',
    total_product_refund: 'Total Product Refund',
    payout_amounts: 'Payout Amount(s)',
    payout_ids: 'Payout ID(s)',
    payout_transaction_ids: 'Payout Transaction ID(s)',
    payout_dates: 'Payout Date(s)',
    payout_fees: 'Payout Fee(s)',
    store_credit_amount: 'Total Store Credit',
    total_store_credit_refund: 'Total Store Credit Refund',
    shipping_refund: 'Total Shipping Refund',
    gateway_fee:  'Total Gateway Fees',
    options: 'Variant Option(s)',
    currency_rate: 'Currency Rate',
    base_currency: 'Base Currency',
    points_earned: 'Points Earned',
    points_claimed: 'Points Claimed',
    points_refunded: 'Points Refunded',
    points_status: 'Points Status',
    gift_card_amount: 'Gift Card Amount',
    ip_address: 'IP Address',
    external_order_id: 'External Order ID',
    customer_created_at: 'Customer Created Date'
  },
  formatters: {
    order_number: orderNumber => `#${orderNumber}`,
    tracking_number: trackingNumber => trackingNumber ? `#${trackingNumber}` : 'N/A',
    customer_id: customerId => `#${customerId}`,
    total_price: currency_formatter,
    subtotal_price: currency_formatter,
    quantity: number_formatter,
    total_costs: currency_formatter,
    total_tax: currency_formatter,
    total_discounts: currency_formatter,
    total_sale_discount: currency_formatter,
    total_shipping_presentment_amount: currency_formatter,
    total_store_fees: currency_formatter,
    total_profit: currency_formatter,
    total_refunds: currency_formatter,
    total_items_refunded: number_formatter,
    total_cost_of_refunds: currency_formatter,
    total_cost_of_goods_sold: currency_formatter,
    grand_total: currency_formatter,
    gross_margin: percentage_formatter,
    coupon_codes: arrayFormattersNoLink.array,
    coupon_codes_csv: arrayFormattersNoLink.array_csv,
    line_items: arrayFormattersWithLink.array,
    line_items_csv: arrayFormattersWithLink.array_csv,
    marketplace_product_ids: arrayFormattersNoLink.array,
    marketplace_product_ids_csv: arrayFormattersNoLink.array_csv,
    skus: arrayFormattersNoLink.array,
    skus_csv: arrayFormattersNoLink.array_csv,
    product_skus: arrayFormattersNoLink.array,
    product_skus_csv: arrayFormattersNoLink.array_csv,
    bin_picking_numbers: arrayFormattersNoLink.array,
    bin_picking_numbers_csv: arrayFormattersNoLink.array_csv,
    order_tags: arrayFormattersNoLink.array,
    order_tags_csv: arrayFormattersNoLink.array_csv,
    shipment_cost: currency_formatter,
    shipment_insurance_cost: currency_formatter,
    shipment_insurance_value: currency_formatter,
    shipment_profit: currency_formatter,
    shipping_tax: currency_formatter,
    shipments: arrayFormattersNoLink.array,
    shipments_csv: arrayFormattersNoLink.array_csv,
    processed_at: date_formatter,
    updated_at: date_formatter,
    last_refund_at: date_formatter,
    tax_rate: percentage_formatter,
    gross_tax_rate: percentage_formatter,
    fulfilled_at: date_formatter,
    fulfillment_time_in_seconds: duration_formatter,
    brand_names: arrayFormattersNoLink.array,
    brand_names_csv: arrayFormattersNoLink.array_csv,
    category_titles: arrayFormattersNoLink.array,
    category_titles_csv: arrayFormattersNoLink.array_csv,
    catalog_titles_v1: arrayFormattersNoLink.array,
    catalog_titles_csv: arrayFormattersNoLink.array_csv,
    product_ids: arrayFormattersNoLink.array,
    product_ids_csv: arrayFormattersNoLink.array_csv,
    shipping_methods: arrayFormattersNoLink.array,
    shipping_methods_csv: arrayFormattersNoLink.array_csv,
    shipping_providers: arrayFormattersNoLink.array,
    shipping_providers_csv: arrayFormattersNoLink.array_csv,
    billing_country: countryFormatter('billing_country'),
    shipping_country: countryFormatter('shipping_country'),
    net_sales: currency_formatter,
    custom_fields: nameValueArrayFormatters.array,
    custom_fields_csv: nameValueArrayFormatters.array_csv,
    product_metafields: nameValueArrayFormatters.array,
    product_metafields_csv: nameValueArrayFormatters.array_csv,
    order_metafields: nameValueArrayFormatters.array,
    order_metafields_csv: nameValueArrayFormatters.array_csv,
    credit_card_type: credit_card_type => credit_card_type !== 'N/A' ? _.capitalize(credit_card_type) : credit_card_type,
    payout_amounts: arrayElementFormatter(currency_formatter),
    payout_amounts_csv: arrayElementFormatterCsv(currency_formatter),
    payout_ids: arrayFormattersNoLink.array,
    payout_ids_csv: arrayFormattersNoLink.array_csv,
    payout_transaction_ids: arrayFormattersNoLink.array,
    payout_transaction_ids_csv: arrayFormattersNoLink.array_csv,
    payout_dates: arrayFormattersNoLink.array,
    payout_dates_csv: arrayFormattersNoLink.array_csv,
    payout_fees: arrayElementFormatter(currency_formatter),
    payout_fees_csv: arrayElementFormatterCsv(currency_formatter),
    refund_tax: currency_formatter,
    total_product_refund: currency_formatter,
    shipping_refund: currency_formatter,
    store_credit_amount: currency_formatter,
    total_store_credit_refund: currency_formatter,
    gateway_fee: currency_formatter,
    total_shipping_tax: currency_formatter,
    options: nameValueArrayFormatters.array,
    options_csv: nameValueArrayFormatters.array_csv,
    points_earned: number_formatter,
    points_claimed: number_formatter,
    points_refunded: number_formatter,
    points_status: arrayFormattersNoLink.array,
    gift_card_amount: currency_formatter,
    customer_created_at: date_formatter,
    ...merchantIdFormatters,
    ...customerContactFormatters,
  },
  subContentFormatters: {
    order_number: order => order.order_status_url ? <a href={order.order_status_url} target="_blank" className="font-size-smaller">Track</a> : null,
  },
  linkTo: {
    order_number: 'order_link',
    tracking_number: 'tracking_url',
    customer_id: 'customer_link',
  },
  tdStyles: {
    financial_status: { textTransform: 'capitalize' },
    fulfillment_status: { textTransform: 'capitalize' },
  }
}

export const lineItemsConfig = {
  keys: [
    'title', 'image', 'order_number', 'customer_id', 'customer_name', 'customer_email', 'customer_phone', 'customer_company', 'customer_group_name',
    'sales_channel_name', 'sales_channel_type', 'sales_channel_platform',
    'billing_full_address', 'shipping_name', 'shipping_first_name', 'shipping_last_name',
    'shipping_full_address', 'shipping_city', 'shipping_province', 'shipping_zip', 'shipping_county_name',
    'tax_exempt_category',
    'total_price', 'quantity', 'total_costs', 'total_tax', 'tax_rate', 'individual_price', // 'cost_price' ?
    'total_discounts', 'total_profit', 'total_shipping_tax',
    'total_refunds', 'total_items_refunded', 'cost_of_refunds',
    'marketplace_product_id', 'sku', 'product_sku', 'bin_picking_number', 'order_tags', 'financial_status', 'fulfillment_status', 'fulfilled_at',
    'source_name', 'processing_method', 'gateway', 'payment_method',
    'fulfillment_time_in_seconds', 'processed_at', 'updated_at', 'merchant_id', 'category_titles', 'brand_name', 'catalog_titles_v1',
    'billing_street_address', 'shipping_street_address', 'shipping_country', 'billing_country', 'billing_city', 'currency',
    'custom_fields', 'note', 'customer_message', 'product_metafields', 'order_metafields', 'fulfillment_city', 'fulfillment_name',
    'fulfillment_province_code', 'fulfillment_zip', 'fulfillment_county_name', 'fulfillment_full_address',
    'credit_card_type',
    'utm_campaign','utm_content','utm_medium','utm_source','utm_term', 'utm_id', 'tracking_number',
    'options', 'currency_rate',
    'shipping_form_fields', 'customer_form_fields', 'custom_fields', 'customer_custom_attributes', 'store_credit_amount', 'customer_created_at'
  ],
  imageFields: ['image'],
  sortableFields: [
    'title', 'customer_id',
    'quantity', 'total_tax', 'individual_price', 'total_discounts',
    'total_refunds', 'total_items_refunded', 'cost_of_refunds',
    'fulfillment_time_in_seconds', 'processed_at', 'updated_at', 'fulfilled_at',
    'marketplace_product_id', 'sku', 'product_sku', 'bin_picking_number',
    'brand_name',
    'utm_campaign','utm_content','utm_medium','utm_source','utm_term', 'utm_id', 'tracking_number',
  ],
  titles: {
    ...transactionsConfig.titles,
    // additional titles
    title: 'Title',
    image: 'Image',
    cost_price: 'Cost Price',
    individual_price: 'Individual Price',
    cost_of_refunds: 'Cost of Refunds',
    marketplace_product_id: 'Marketplace Product ID',
    sku: 'SKU',
    product_sku: 'Product SKU',
    bin_picking_number: 'BIN Picking Number',
    brand_name: 'Brand',
    customer_name: 'Customer Name',
    options: 'Options',
    shipping_form_fields: 'Shipping Form Fields',
    customer_form_fields: 'Customer Form Fields',
    customer_custom_attributes: 'Customer Custom Attributes',
    category_titles: 'Categories',
    catalog_titles_v1: 'Catalogs',
    fulfilled_at: 'Date Shipped',
    created_at: 'Order Date',
    fulfillment_status: 'Fulfillment Status',
    store_credit_amount: 'Total Store Credit',
    customer_created_at: 'Customer Created Date',
  },
  formatters: {
    ...transactionsConfig.formatters,
    // additional formatters
    individual_price: currency_formatter,
    options: nameValueArrayFormatters.array,
    shipping_form_fields: nameValueArrayFormatters.array,
    shipping_form_fields_csv: nameValueArrayFormatters.array_csv,
    customer_form_fields: nameValueArrayFormatters.array,
    customer_form_fields_csv: nameValueArrayFormatters.array_csv,
    category_titles: nameValueArrayFormatters.array,
    category_titles_csv: nameValueArrayFormatters.array_csv,
    catalog_titles_v1: nameValueArrayFormatters.array,
    catalog_titles_csv: nameValueArrayFormatters.array_csv,
    customer_custom_attributes: nameValueArrayFormatters.array,
    customer_custom_attributes_csv: nameValueArrayFormatters.array_csv,
    fulfilled_at: year_month_day,
    created_at: year_month_day,
  },
  linkTo: {
    image: 'link',
  },
  tdStyles: {
    image: { textAlign: 'center' },
    merchant_id: { whiteSpace: 'nowrap' },
  }
}

const basicStatsConfig = {
  keys: ['avg_items_per_order', 'avg_price', 'total_items_sold', 'total_orders', 'total_sales', 'total_refunds', 'total_items_refunded'],
  productSortableFields: ['total_items_sold', 'total_sales', 'total_refunds', 'total_items_refunded'],
  titles: {
    avg_items_per_order: 'Avg. Items per Order',
    avg_price: 'Avg. Item Price',
    total_items_sold: 'Total Items Sold',
    total_orders: 'Total Orders',
    total_sales: 'Total Sales',
    total_refunds: 'Total Refunds',
    total_items_refunded: 'Total Items Refunded',
    merchant_id: 'Store/Merchant ID',
  },
  formatters: {
    avg_items_per_order: number_decimal_formatter,
    avg_price: elementCurrencyFormatter,
    total_items_sold: number_formatter,
    total_orders: number_formatter,
    total_sales: elementCurrencyFormatter,
    total_refunds: elementCurrencyFormatter,
    total_items_refunded: number_formatter,
    net_sales: elementCurrencyFormatter,
    ...merchantIdFormatters,
  },
  tdStyles: {
    merchant_id: { whiteSpace: 'nowrap' },
  }
}
const discountStatsConfig = {
  keys: ['total_discounts', 'total_sale_discount', 'total_after_discount', 'total_store_credit_amount'],
  titles: {
    total_discounts: 'Total Discounts',
    total_sale_discount: 'Total Sale Discount',
    total_after_discount: 'Total After Discount',
    total_store_credit_amount: 'Total Store Credit'
  },
  formatters: {
    total_discounts: elementCurrencyFormatter,
    total_sale_discount: elementCurrencyFormatter,
    total_after_discount: elementCurrencyFormatter,
    total_store_credit_amount: elementCurrencyFormatter,
  },
  productSortableFields:  ['total_discounts', 'total_after_discount']
}
const costsStatsConfig = {
  keys: ['total_costs', 'total_after_costs', 'total_cost_of_refunds'],
  titles: {
    total_costs: 'Total Costs',
    total_after_costs: 'Total After Costs',
    total_cost_of_refunds: 'Total Cost of Refunds',
  },
  formatters: {
    total_costs: elementCurrencyFormatter,
    total_after_costs: elementCurrencyFormatter,
    total_cost_of_refunds: elementCurrencyFormatter,
  }
}
const taxStatsConfig = {
  keys: ['total_tax', 'tax_rate', 'gross_tax_rate'],
  titles: {
    total_tax: 'Total Taxes',
    tax_rate: 'Tax Rate',
    gross_tax_rate: 'Gross Tax Rate',
  },
  formatters: {
    total_tax: elementCurrencyFormatter,
    tax_rate: percentage_formatter,
    gross_tax_rate: percentage_formatter
  }
}

const productTaxStatsConfig = {
  keys: ['total_tax', 'tax_rate'],
  titles: {
    total_tax: 'Total Taxes',
    tax_rate: 'Tax Rate',
  },
  formatters: {
    total_tax: elementCurrencyFormatter,
    tax_rate: percentage_formatter
  }
}
const profitStatsConfig = {
  keys: ['total_profit'],
  titles: {
    total_profit: 'Total Profit',
  },
  formatters: {
    total_profit: elementCurrencyFormatter,
  }
}
const percentStatsConfig = {
  keys: ['items_sold_percent', 'sales_percent', 'gross_margin'],
  titles: {
    items_sold_percent: 'Items Sold %',
    sales_percent: 'Total Sales %',
    gross_margin: 'Gross Margin',
  },
  formatters: {
    items_sold_percent: percentage_formatter,
    sales_percent: percentage_formatter,
    gross_margin: percentage_formatter,
  }
}
const minMaxStatsConfig = {
  keys: ['max_sales', 'min_sales', 'max_tax', 'min_tax', 'max_shipping', 'min_shipping', 'max_discount', 'min_discount'],
  titles: {
    max_sales: 'Max. Sales',
    min_sales: 'Min. Sales',
    max_tax: 'Max. Tax',
    min_tax: 'Min. Tax',
    max_shipping: 'Max. Shipping',
    min_shipping: 'Min. Shipping',
    max_discount: 'Max. Discount',
    min_discount: 'Min. Discount',
  },
  formatters: {
    max_sales: elementCurrencyFormatter,
    min_sales: elementCurrencyFormatter,
    max_tax: elementCurrencyFormatter,
    min_tax: elementCurrencyFormatter,
    max_shipping: elementCurrencyFormatter,
    min_shipping: elementCurrencyFormatter,
    max_discount: elementCurrencyFormatter,
    min_discount: elementCurrencyFormatter,
  }
}

const fulfillmentStatsConfig = {
  keys: ['avg_fulfillment_time','min_fulfillment_time','max_fulfillment_time'],
  titles: {
    avg_fulfillment_time: 'Average Fulfillment Time',
    min_fulfillment_time: 'Minimum Fulfillment Time',
    max_fulfillment_time: 'Maximum Fulfillment Time',
  },
  formatters: {
    avg_fulfillment_time: duration_formatter,
    min_fulfillment_time: duration_formatter,
    max_fulfillment_time: duration_formatter,
  }
}


const avgStatsConfig = {
  keys: ['avg_tax', 'avg_order'],
  titles: {
    avg_tax: 'Avg. Tax',
    avg_order: 'Avg. Value',
  },
  formatters: {
    avg_tax: elementCurrencyFormatter,
    avg_order: elementCurrencyFormatter
  }
}

const secondarySalesStatsConfig = {
  keys: ['total_sales_v1'],
  titles: {
    total_sales_v1: 'Total Sales',
  },
  formatters: {
    total_sales_v1: elementCurrencyFormatter,
  }
}


export const productsConfig = {
  keys: [
    'image_url', 'title', 'marketplace_product_id', 'sku', 'product_sku', 'bin_picking_number', 'variant_title', 'options', 'product_type',
    'default_sales',
    ...basicStatsConfig.keys,
    ...discountStatsConfig.keys,
    ...costsStatsConfig.keys,
    ...productTaxStatsConfig.keys,
    ...profitStatsConfig.keys,
    'total_customers',
    ...percentStatsConfig.keys,
    'total_shipping_cost',
    ...fulfillmentStatsConfig.keys,
    'merchant_id',
  ],
  sortableFields: [...basicStatsConfig.productSortableFields, 'default_sales', 'total_orders', ...discountStatsConfig.productSortableFields, ...costsStatsConfig.keys, ...taxStatsConfig.keys],
  imageFields: ['image_url'],
  titles: {
    product_id: 'Product ID',
    title: 'Name',
    marketplace_product_id: 'Marketplace Product ID',
    sku: 'SKU',
    product_sku: 'Product SKU',
    bin_picking_number: 'Bin Picking Number',
    variant_title: 'Variant Name',
    product_type: 'Product Tags',
    options: 'Variant Option(s)',
    default_sales: 'Default Sales',
    ...basicStatsConfig.titles,
    ...discountStatsConfig.titles,
    ...costsStatsConfig.titles,
    ...productTaxStatsConfig.titles,
    ...profitStatsConfig.titles,
    ...percentStatsConfig.titles,
    ...fulfillmentStatsConfig.titles,
    total_sales: 'Total Before Discount',
    total_customers: 'Total Customers',
    total_shipping_cost: 'Total Shipping Cost',
    avg_shipping_cost: 'Average Shipping Cost',
  },
  formatters: {
    product_id: product_id => `#${product_id}`,
    ...basicStatsConfig.formatters,
    ...discountStatsConfig.formatters,
    ...costsStatsConfig.formatters,
    ...productTaxStatsConfig.formatters,
    ...profitStatsConfig.formatters,
    ...percentStatsConfig.formatters,
    ...fulfillmentStatsConfig.formatters,
    total_customers: number_formatter,
    total_shipping_cost: currency_formatter,
    avg_shipping_cost: currency_formatter,
    options: nameValueArrayFormatters.array,
    default_sales: currency_formatter,
  },
  linkTo: {
    image_url: 'product_link',
  },
  tdStyles: {
    image_url: { textAlign: 'center' },
    ...basicStatsConfig.tdStyles,
  }
}

export const variantsConfig = {
  keys: [
    'variant_image', 'product_title', 'id', 'variant_title', 'options', 'sku',
    'default_sales',
    ...basicStatsConfig.keys,
    ...discountStatsConfig.keys,
    ...costsStatsConfig.keys,
    ...taxStatsConfig.keys,
    ...profitStatsConfig.keys,
    'total_customers',
    ...percentStatsConfig.keys,
    'total_shipping_cost',
    'avg_shipping_cost',
    ...fulfillmentStatsConfig.keys,
    'merchant_id',
  ],
  sortableFields: [...basicStatsConfig.productSortableFields, 'default_sales', 'total_orders', ...discountStatsConfig.productSortableFields, ...costsStatsConfig.keys, ...taxStatsConfig.keys],
  titles: {
    product_title: 'Product Name',
    id: 'Variant ID',
    variant_image: 'Variant Image',
    variant_title: 'Variant Name',
    options: 'Variant Option(s)',
    sku: 'SKU',
    default_sales: 'Default Sales',
    ...basicStatsConfig.titles,
    ...discountStatsConfig.titles,
    ...costsStatsConfig.titles,
    ...taxStatsConfig.titles,
    ...profitStatsConfig.titles,
    ...percentStatsConfig.titles,
    ...fulfillmentStatsConfig.titles,
    total_sales: 'Total Before Discount',
    total_customers: 'Total Customers',
    total_shipping_cost: 'Total Shipping Cost',
    avg_shipping_cost: 'Average Shipping Cost',
  },
  imageFields: ['variant_image'],
  formatters: {
    product_id: product_id => `#${product_id}`,
    options: nameValueArrayFormatters.array,
    options_csv: nameValueArrayFormatters.array_csv,
    ...basicStatsConfig.formatters,
    ...discountStatsConfig.formatters,
    ...costsStatsConfig.formatters,
    ...taxStatsConfig.formatters,
    ...profitStatsConfig.formatters,
    ...percentStatsConfig.formatters,
    ...fulfillmentStatsConfig.formatters,
    total_customers: number_formatter,
    total_shipping_cost: currency_formatter,
    avg_shipping_cost: currency_formatter,
    default_sales: currency_formatter,
  },
  linkTo: {
    variant_image: 'product_link',
  },
  tdStyles: {
    ...basicStatsConfig.tdStyles,
  }
}

export const brandsConfig = {
  keys: [
    'brand_id', 'title',
    ...basicStatsConfig.keys,
    ...discountStatsConfig.keys,
    ...costsStatsConfig.keys,
    ...taxStatsConfig.keys,
    ...profitStatsConfig.keys,
    'total_customers',
    ...percentStatsConfig.keys,
    'total_shipping_cost',
    'avg_shipping_cost',
    ...fulfillmentStatsConfig.keys,
    'merchant_id',
  ],
  sortableFields: [...basicStatsConfig.productSortableFields, ...discountStatsConfig.productSortableFields, ...costsStatsConfig.keys, ...taxStatsConfig.keys],
  titles: {
    brand_id: 'Brand ID',
    title: 'Name',
    ...basicStatsConfig.titles,
    ...discountStatsConfig.titles,
    ...costsStatsConfig.titles,
    ...taxStatsConfig.titles,
    ...profitStatsConfig.titles,
    ...percentStatsConfig.titles,
    ...fulfillmentStatsConfig.titles,
    total_sales: 'Total Before Discount',
    total_customers: 'Total Customers',
    total_shipping_cost: 'Total Shipping Cost',
    avg_shipping_cost: 'Average Shipping Cost',
  },
  formatters: {
    brand_id: brand_id => `#${brand_id}`,
    ...basicStatsConfig.formatters,
    ...discountStatsConfig.formatters,
    ...costsStatsConfig.formatters,
    ...taxStatsConfig.formatters,
    ...profitStatsConfig.formatters,
    ...percentStatsConfig.formatters,
    ...fulfillmentStatsConfig.formatters,
    total_customers: number_formatter,
    total_shipping_cost: currency_formatter,
    avg_shipping_cost: currency_formatter,
  },
  linkTo: {
    brand_id: 'brand_link',
  },
  tdStyles: basicStatsConfig.tdStyles,
}

export const skusConfig = {
  keys: [
    'sku',
    'name',
    'variant_title',
    'options',
    'default_sales',
    ...basicStatsConfig.keys,
    ...discountStatsConfig.keys,
    ...costsStatsConfig.keys,
    ...taxStatsConfig.keys,
    ...profitStatsConfig.keys,
    'total_customers',
    ...percentStatsConfig.keys,
    'total_shipping_cost',
    'avg_shipping_cost',
    ...fulfillmentStatsConfig.keys,
  ],
  sortableFields: ['default_sales', ...basicStatsConfig.productSortableFields, ...discountStatsConfig.productSortableFields, ...costsStatsConfig.keys, ...taxStatsConfig.keys],
  titles: {
    sku: 'SKU',
    name: 'Product Name',
    variant_title: 'Variant Name',
    options: 'Variant Options',
    default_sales: 'Default Sales',
    ...basicStatsConfig.titles,
    ...discountStatsConfig.titles,
    ...costsStatsConfig.titles,
    ...taxStatsConfig.titles,
    ...profitStatsConfig.titles,
    ...percentStatsConfig.titles,
    ...fulfillmentStatsConfig.titles,
    total_sales:'Total Before Discount',
    total_customers: 'Total Customers',
    total_shipping_cost: 'Total Shipping Cost',
    avg_shipping_cost: 'Average Shipping Cost',
  },
  formatters: {
    ...basicStatsConfig.formatters,
    ...discountStatsConfig.formatters,
    ...costsStatsConfig.formatters,
    ...taxStatsConfig.formatters,
    ...profitStatsConfig.formatters,
    ...percentStatsConfig.formatters,
    ...fulfillmentStatsConfig.formatters,
    total_customers: number_formatter,
    total_shipping_cost: currency_formatter,
    avg_shipping_cost: currency_formatter,
    options: nameValueArrayFormatters.array,
    default_sales: currency_formatter,
  },
  linkTo: {
    name: 'product_path',
  },
}

export const productTrendsConfig = {
  keys: [
    'default_sales',
    ...basicStatsConfig.keys,
    ...discountStatsConfig.keys,
    ...costsStatsConfig.keys,
    ...productTaxStatsConfig.keys,
    ...profitStatsConfig.keys,
    'total_customers',
    ...percentStatsConfig.keys,
    'total_shipping_cost',
    ...fulfillmentStatsConfig.keys,
  ],
  sortableFields: [...basicStatsConfig.productSortableFields, 'default_sales', 'total_orders', ...discountStatsConfig.productSortableFields, ...costsStatsConfig.keys, ...taxStatsConfig.keys],
  titles: {
    title: 'Name',
    marketplace_product_id: 'Marketplace Product ID',
    sku: 'SKU',
    gtin: 'GTIN',
    epid: 'ePID',
    product_sku: 'Product SKU',
    bin_picking_number: 'Bin Picking Number',
    category_titles: 'Category',
    catalog_titles_v1: 'Catalog',
    brand_name: 'Brand',
    vendor: 'Vendor',
    product_type: 'Product Tags',
    options: 'Options',
    custom_fields: 'Custom Fields',
    default_sales: 'Default Sales',
    ...basicStatsConfig.titles,
    ...discountStatsConfig.titles,
    ...costsStatsConfig.titles,
    ...productTaxStatsConfig.titles,
    ...profitStatsConfig.titles,
    ...percentStatsConfig.titles,
    ...fulfillmentStatsConfig.titles,
    total_sales: 'Total before Discount',
    total_customers: 'Total Customers',
    total_shipping_cost: 'Total Shipping Cost',
    avg_shipping_cost: 'Average Shipping Cost',
  },
  formatters: {
    ...basicStatsConfig.formatters,
    ...discountStatsConfig.formatters,
    ...costsStatsConfig.formatters,
    ...productTaxStatsConfig.formatters,
    ...profitStatsConfig.formatters,
    ...percentStatsConfig.formatters,
    ...fulfillmentStatsConfig.formatters,
    total_customers: number_formatter,
    total_shipping_cost: currency_formatter,
    avg_shipping_cost: currency_formatter,
    default_sales: currency_formatter,
  },
  tdStyles: basicStatsConfig.tdStyles,
}

const orderFacetsTitles = Object.keys(formatters.orderFacets).reduce((acc, key) => ({
  ...acc,
  [key]: formatters.orderFacets[key].title,
}), {})
export const customOrderBasesConfig = {
  keys: [...new Set([
    ...[
      'customer_name', 'customer_email', 'tax_exempt_category', 'shipping_form_fields', 'customer_form_fields', 'custom_fields', 'channel', 'skus',
      'shipping_full_address', 'shipping_street_address', 'shipping_city', 'shipping_province', 'shipping_zip', 'last_order_date', 'first_order_date',
      ...basicStatsConfig.keys, ...fulfillmentStatsConfig.keys, ...profitStatsConfig.keys, ...avgStatsConfig.keys, ...minMaxStatsConfig.keys,
      'total_tax', 'tax_rate', 'gross_tax_rate', 'total_costs', 'gross_margin', 'merchant_id', 'grand_total', 'total_shippings', 'total_cost_of_refunds', 'total_store_fees', 'avg_shipping', 'total_discounted_orders', 'total_store_credit_refund', 'total_shipping_refund', 'total_refund_tax', 'net_sales', 'customer_custom_attributes', 'customer_created_at', 'source_name', 'utm_term', 'utm_medium', 'utm_source', 'utm_campaign', 'utm_content',
    ],
  ])],
  sortableFields: ['total_orders', ...basicStatsConfig.productSortableFields, 'total_profit', 'grand_total', 'total_shippings', 'total_cost_of_refunds', 'total_store_fees', 'total_discounted_orders', 'total_store_credit_refund', 'total_shipping_refund', 'net_sales', ...minMaxStatsConfig.keys, 'customer_created_at'],
  titles: {
    ...orderFacetsTitles,
    tax_exempt_category: 'Tax Exempt Category',
    channel: 'Channel',
    shipping_full_address: 'Shipping Address',
    shipping_street_address: 'Shipping Street Address',
    shipping_city: 'Shipping City',
    shipping_province: 'Shipping State/Province',
    shipping_zip: 'Shipping Zip/Postal',
    last_order_date: 'Last Order Date',
    first_order_date: 'First Order Date',
    ...basicStatsConfig.titles,
    ...fulfillmentStatsConfig.titles,
    ...profitStatsConfig.titles,
    ...avgStatsConfig.titles,
    ...minMaxStatsConfig.titles,
    total_sales: 'Total Sales',
    total_tax: 'Total Taxes',
    tax_rate: 'Tax Rate',
    gross_tax_rate: 'Gross Tax Rate',
    total_costs: 'Total Costs',
    gross_margin: 'Gross Margin',
    grand_total: 'Grand Total',
    total_shippings: 'Total Shipping',
    total_cost_of_refunds: 'Total Cost of Refunds',
    total_store_fees: 'Total Store Fees',
    avg_shipping: 'Avg. Order Shipping',
    total_discounted_orders: 'Total Discounted Orders',
    total_store_credit_refund: 'Total Store Credit Refund',
    total_shipping_refund: 'Total Shipping Refund',
    total_refund_tax: 'Total Refund Tax',
    net_sales: 'Net Sales',
    custom_fields: 'Custom Fields',
    customer_form_fields: 'Customer Form Fields',
    shipping_form_fields: 'Shipping Form Fields',
    customer_custom_attributes: 'Customer Attributes',
    customer_id: 'Customer ID',
    customer_name: 'Customer Name',
    customer_email: 'Customer Email',
    customer_created_at: 'Customer Created Date',
    source_name: 'Source Name',
    utm_term: 'UTM Term',
    utm_medium: 'UTM Medium',
    utm_source: 'UTM Source',
    utm_campaign: 'UTM Campaign',
    utm_content: 'UTM Content',
  },
  formatters: {
    ...basicStatsConfig.formatters,
    ...discountStatsConfig.formatters,
    ...fulfillmentStatsConfig.formatters,
    ...profitStatsConfig.formatters,
    ...avgStatsConfig.formatters,
    ...minMaxStatsConfig.formatters,
    total_tax: currency_formatter,
    tax_rate: percentage_formatter,
    gross_tax_rate: percentage_formatter,
    total_costs: currency_formatter,
    gross_margin: percentage_formatter,
    ...customerContactFormatters,
    last_order_date: date_formatter,
    first_order_date: date_formatter,
    grand_total: currency_formatter,
    total_shippings: currency_formatter,
    total_cost_of_refunds: currency_formatter,
    total_store_fees: currency_formatter,
    avg_shipping: currency_formatter,
    total_discounted_orders: number_formatter,
    total_store_credit_refund: currency_formatter,
    total_shipping_refund: currency_formatter,
    total_refund_tax: currency_formatter,
    net_sales: currency_formatter
  },
  linkTo: {
    customer_id: 'customer_link',
  },
  tdStyles: basicStatsConfig.tdStyles,
}

export const customersConfig = {
  keys: [
    'customer_id', 'name', 'customer_email', 'customer_phone', 'customer_company', 'customer_group_name', 'tax_exempt_category',
    'billing_full_address', 'shipping_full_address', 'billing_street_address', 'shipping_street_address', 'shipping_city', 'shipping_province', 'shipping_zip', 'last_order_date', 'first_order_date',
    ...basicStatsConfig.keys, ...discountStatsConfig.keys, ...fulfillmentStatsConfig.keys, ...profitStatsConfig.keys, ...avgStatsConfig.keys, ...minMaxStatsConfig.keys, ...secondarySalesStatsConfig.keys,
    'total_tax', 'tax_rate', 'gross_tax_rate', 'total_costs', 'gross_margin', 'merchant_id', 'grand_total', 'total_shippings', 'total_cost_of_refunds', 'total_store_fees', 'avg_shipping', 'total_discounted_orders', 'total_store_credit_refund', 'total_shipping_refund', 'total_refund_tax', 'net_sales', 'customer_created_at'
  ],
  sortableFields: ['total_orders', ...basicStatsConfig.productSortableFields, 'total_profit', 'grand_total', 'total_shippings', 'total_cost_of_refunds', 'total_store_fees', 'total_discounted_orders', 'total_store_credit_refund', 'total_shipping_refund', 'net_sales', ...secondarySalesStatsConfig.keys, ...minMaxStatsConfig.keys, 'customer_created_at'],
  titles: {
    customer_id: 'Customer ID',
    name: 'Name',
    customer_email: 'Email',
    customer_phone: 'Phone',
    customer_company: 'Company',
    customer_group_name: 'Customer Group',
    tax_exempt_category: 'Tax Exempt Category',
    billing_full_address: 'Billing Address',
    shipping_full_address: 'Shipping Address',
    billing_street_address: 'Billing Street Address',
    shipping_street_address: 'Shipping Street Address',
    shipping_city: 'Shipping City',
    shipping_province: 'Shipping State/Province',
    shipping_zip: 'Shipping Zip/Postal',
    last_order_date: 'Last Order Date',
    first_order_date: 'First Order Date',
    ...basicStatsConfig.titles,
    ...discountStatsConfig.titles,
    ...fulfillmentStatsConfig.titles,
    ...profitStatsConfig.titles,
    ...avgStatsConfig.titles,
    ...minMaxStatsConfig.titles,
    ...secondarySalesStatsConfig.titles,
    total_sales: 'Total Before Discount',
    total_tax: 'Total Taxes',
    tax_rate: 'Tax Rate',
    gross_tax_rate: 'Gross Tax Rate',
    total_costs: 'Total Costs',
    gross_margin: 'Gross Margin',
    grand_total: 'Grand Total',
    total_shippings: 'Total Shipping',
    total_cost_of_refunds: 'Total Cost of Refunds',
    total_store_fees: 'Total Store Fees',
    avg_shipping: 'Avg. Order Shipping',
    total_discounted_orders: 'Total Discounted Orders',
    total_store_credit_refund: 'Total Store Credit Refund',
    total_shipping_refund: 'Total Shipping Refund',
    total_refund_tax: 'Total Refund Tax',
    net_sales: 'Net Sales',
    customer_created_at: 'Customer Created Date',
  },
  formatters: {
    customer_id: (customer_id, currency, el) => `#${el.account_customer_id}`,
    ...basicStatsConfig.formatters,
    ...discountStatsConfig.formatters,
    ...fulfillmentStatsConfig.formatters,
    ...profitStatsConfig.formatters,
    ...avgStatsConfig.formatters,
    ...minMaxStatsConfig.formatters,
    ...secondarySalesStatsConfig.formatters,
    total_tax: currency_formatter,
    tax_rate: percentage_formatter,
    gross_tax_rate: percentage_formatter,
    total_costs: currency_formatter,
    gross_margin: percentage_formatter,
    ...customerContactFormatters,
    last_order_date: date_formatter,
    first_order_date: date_formatter,
    grand_total: currency_formatter,
    total_shippings: currency_formatter,
    total_cost_of_refunds: currency_formatter,
    total_store_fees: currency_formatter,
    avg_shipping: currency_formatter,
    total_discounted_orders: number_formatter,
    total_store_credit_refund: currency_formatter,
    total_shipping_refund: currency_formatter,
    total_refund_tax: currency_formatter,
    net_sales: currency_formatter,
    customer_created_at: date_formatter,
  },
  linkTo: {
    customer_id: 'customer_link',
  },
  tdStyles: basicStatsConfig.tdStyles,
}

export const utmTitles = {
  utmSource: 'Source',
  utmMedium: 'Medium',
  utmCampaign: 'Campaign',
  utmContent: 'Content',
  utmTerm: 'Term',
  utmId: 'Id'
}
export const marketingReportTitles = {
  utmSource: 'Marketing - UTM Sources',
  utmMedium: 'Marketing - UTM Media',
  utmCampaign: 'Marketing - UTM Campaigns',
  utmContent: 'Marketing - UTM Contents',
  utmTerm: 'Marketing - UTM Terms',
  utmId: 'Marketing - UTM Ids',
}
export const utmFilterKeys = {
  utmSource: 'utm_source',
  utmMedium: 'utm_medium',
  utmCampaign: 'utm_campaign',
  utmContent: 'utm_content',
  utmTerm: 'utm_term',
  utmId: 'utm_id',
}
export const utmsConfig = Object.assign({}, ...['utmSource', 'utmMedium', 'utmCampaign', 'utmContent', 'utmTerm', 'utmId'].map(utmKey => ({
  [utmKey]: {
    keys: [
      utmKey,
      'avg_shipping', 'avg_tax', 'avg_order',
      ...basicStatsConfig.keys,
      ...taxStatsConfig.keys,
      'total_discounts', 'total_costs', 'total_shippings', 'total_store_fees', 'total_profit', 'gross_margin', 'total_customers', 'total_taxed_orders', 'total_discounted_orders',
      ...minMaxStatsConfig.keys,
      ...fulfillmentStatsConfig.keys,
      ...secondarySalesStatsConfig.keys,
      'amount_micros',
      'cost_micros',
      'clicks',
      'impressions','average_cpc', 'conversions', 'roas',
      'emails_received',
      'emails_opened',
      'emails_clicked',
      'total_shipping_tax',
      'gateway_fee',
      'total_cost_of_goods_sold',
      'total_revenue',
      'net_sales',
      'total_cost_of_refunds',
      'total_store_credit_refund',
      'total_store_credit_amount',
      'total_refund_tax',
      'total_shipping_refund'
    ],
    sortableFields: [
      'avg_shipping', 'avg_tax', 'avg_order',
      ...basicStatsConfig.keys,
      ...taxStatsConfig.keys,
      'total_discounts', 'total_costs', 'total_shippings', 'total_store_fees',
      ...minMaxStatsConfig.keys, 'cost_micros', 'amount_micros', 'impressions', 'clicks', 'average_cpc', 'conversions', 'roas',
      'emails_received',
      'emails_opened',
      'emails_clicked',
      'total_shipping_tax',
      'gateway_fee',
      'total_cost_of_goods_sold',
      'total_revenue',
      'net_sales',
      'total_cost_of_refunds',
      'total_store_credit_refund',
      'total_store_credit_amount',
      'total_refund_tax',
      'total_shipping_refund',
      ...secondarySalesStatsConfig.keys,
    ],
    titles: {
      [utmKey]: utmTitles[utmKey],
      avg_shipping: 'Avg. Shipping',
      avg_tax: 'Avg. Tax',
      avg_order: 'Avg. Order Sales',
      ...basicStatsConfig.titles,
      ...taxStatsConfig.titles,
      total_discounts: 'Total Discounts',
      total_costs: 'Total Costs',
      total_shippings: 'Total Shipping',
      total_store_fees: 'Total Store Fees',
      total_profit: 'Total Profit',
      total_refunds: 'Total Refunds',
      gross_margin: 'Gross Margin',
      total_customers: 'Total Customers',
      total_taxed_orders: 'Total Taxed Orders',
      total_discounted_orders: 'Total Discounted Orders',
      ...minMaxStatsConfig.titles,
      ...fulfillmentStatsConfig.titles,
      amount_micros: 'Daily Budget',
      cost_micros: 'Total Ad Spend',
      impressions: 'Total Impressions',
      clicks: 'Total Clicks',
      average_cpc: 'Avg Cost Per Click',
      conversions: 'Conversions',
      roas: 'ROAS',
      emails_received: 'Emails Received',
      emails_opened: 'Emails Opened',
      emails_clicked: 'Emails Clicked',
      total_shipping_tax: 'Total Shipping Tax',
      gateway_fee: 'Total Gateway Fees',
      total_cost_of_goods_sold: 'Total Cost of Goods Sold',
      total_revenue: 'Total Revenue',
      net_sales: 'Net Sales',
      total_cost_of_refunds: 'Total Cost of Refunds',
      total_store_credit_refund: 'Total Store Credit Refund',
      total_store_credit_amount: 'Total Store Credit Amount',
      total_refund_tax: 'Total Sale Tax Refund',
      total_shipping_refund: 'Total Shipping Refund',
      ...secondarySalesStatsConfig.titles,
    },
    formatters: {
      avg_shipping: elementCurrencyFormatter,
      avg_tax: elementCurrencyFormatter,
      avg_order: elementCurrencyFormatter,
      ...basicStatsConfig.formatters,
      ...taxStatsConfig.formatters,
      total_discounts: elementCurrencyFormatter,
      total_costs: elementCurrencyFormatter,
      total_shippings: elementCurrencyFormatter,
      total_store_fees: elementCurrencyFormatter,
      total_profit: elementCurrencyFormatter,
      total_refunds: elementCurrencyFormatter,
      gross_margin: percentage_formatter,
      total_customers: number_formatter,
      total_taxed_orders: number_formatter,
      total_discounted_orders: number_formatter,
      ...minMaxStatsConfig.formatters,
      ...fulfillmentStatsConfig.formatters,
      amount_micros: micro_currency_formatter,
      cost_micros: micro_currency_formatter,
      average_cpc: micro_currency_formatter,
      clicks: number_formatter,
      impressions: number_formatter,
      conversions: number_formatter,
      roas: number_decimal_formatter,
      emails_received: number_formatter,
      emails_opened: number_formatter,
      emails_clicked: number_formatter,
      total_shipping_tax: elementCurrencyFormatter,
      gateway_fee: elementCurrencyFormatter,
      total_cost_of_goods_sold: elementCurrencyFormatter,
      total_revenue: elementCurrencyFormatter,
      net_sales: elementCurrencyFormatter,
      total_cost_of_refunds: elementCurrencyFormatter,
      total_store_credit_refund: elementCurrencyFormatter,
      total_store_credit_amount: elementCurrencyFormatter,
      total_refund_tax: elementCurrencyFormatter,
      total_shipping_refund: elementCurrencyFormatter,
      ...secondarySalesStatsConfig.formatters,
    },
    filterKey: utmFilterKeys[utmKey],
    tooltipKey: {
      conversions: 'conversions_type'
    }
  }
})))

export const orderFormatter = (order, uniqueKeyFunc) => {
  return {
    ...order,
    uniqueKey: uniqueKeyFunc ? uniqueKeyFunc(order) : order.id,
    order_number: order.order_number || order.id_internal,
  }
}

const customerFormatter = (customer, uniqueKeyFunc) => {
  return {
    ...customer,
    uniqueKey: uniqueKeyFunc ? uniqueKeyFunc(customer) : customer.customer_id,
  }
}

const productFormatter = (product, uniqueKeyFunc) => {
  return {
    ...product,
    uniqueKey: uniqueKeyFunc ? uniqueKeyFunc(product) : product.product_id,
  }
}

const variantFormatter = (variant) => {
  return {
    ...variant,
    id: variant.variant_id.split('_').reverse()[0],
    uniqueKey: variant.variant_id,
  }
}

const brandFormatter = (brand) => {
  return {
    ...brand,
    uniqueKey: brand.brand_id,
  }
}

const skuFormatter = (sku) => {
  return {
    ...sku,
    uniqueKey: sku.sku,
  }
}
const vendorFormatter = (vendor) => vendor

export const secondaryDataFormatter = {
  product: productFormatter,
  variant: variantFormatter,
  brand: brandFormatter,
  sku: skuFormatter,
  vendor: vendorFormatter,
  customer: customerFormatter,
}

export const mapDataSchema = {
  keys: [
    'country', 'province', 'county',
    'total_orders', 'total_tax', 'tax_rate', 'gross_tax_rate', 'total_sales', 'total_gross_sales', 'total_revenue', 'total_shippings', 'total_items_sold', 'total_discounts',
    'total_costs', 'total_store_fees', 'total_profit', 'total_refunds', 'total_items_refunded', 'total_cost_of_refunds', 'gross_margin', 'total_customers', 'total_taxed_orders', 'total_discounted_orders', 'total_store_credit_amount', 'total_store_credit_refund', 'total_shipping_refund',
    ...minMaxStatsConfig.keys,
    ...fulfillmentStatsConfig.keys,
    'total_cost_of_goods_sold',
    'total_sale_discount',
    'avg_items_per_order',
    'total_shipment_cost',
    'total_shipment_profit',
    'avg_shipment_profit',
    'total_refund_tax',
    'gateway_fee',
  ],
  sortableFields: [
    'country', 'province', 'county',
    'total_orders', 'total_tax', 'total_sales', 'total_gross_sales', 'total_revenue', 'total_shippings', 'total_items_sold', 'total_discounts', 'total_costs', 'total_store_fees', 'total_profit', 'total_refunds', 'total_items_refunded', 'total_cost_of_refunds',
    ...minMaxStatsConfig.keys,
    ...fulfillmentStatsConfig.keys,
    'total_cost_of_goods_sold',
    'total_sale_discount',
    'avg_items_per_order',
    'total_shipment_cost',
    'total_shipment_profit',
    'avg_shipment_profit',
    'total_refund_tax',
    'gateway_fee',
  ],
  names: {
    country: 'Country/Region',
    province: 'State/Province',
    county: 'County',
    total_orders: 'Total Orders',
    total_tax: 'Total Taxes',
    total_sales: 'Total Sales',
    total_gross_sales: 'Total Gross Sales',
    total_revenue: 'Total Revenue',
    total_shippings: 'Total Shippings',
    total_items_sold: 'Total Items Sold',
    total_discounts: 'Total Discounts',
    total_costs: 'Total Costs',
    total_store_fees: 'Total Store Fees',
    total_profit: 'Total Profit',
    total_shipping_tax: 'Total Shipping Tax',
    total_refunds: 'Total Refunds',
    total_items_refunded: 'Total Items Refunded',
    total_cost_of_refunds: 'Total Cost of Refunds',
    total_cost_of_goods_sold: 'Total Cost of Goods Sold',
    tax_rate: 'Tax Rate',
    gross_tax_rate: 'Gross Tax Rate',
    gross_margin: 'Gross Margin',
    total_customers: 'Total Customers',
    total_taxed_orders: 'Total Taxed Orders',
    total_discounted_orders: 'Total Discounted Orders',
    total_store_credit_amount: 'Total Store Credit',
    total_store_credit_refund: 'Total Store Credit Refund',
    total_shipping_refund: 'Total Shipping Refund',
    gateway_fee: 'Gateway Fee',
    ...minMaxStatsConfig.titles,
    ...fulfillmentStatsConfig.titles,
    total_sale_discount: 'Total Sale Discount',
    avg_items_per_order: 'Avg. Items Per Order',
    total_shipment_cost: 'Total Shipping Label Cost',
    total_shipment_profit: 'Total Shipping Label Profit',
    avg_shipment_profit: 'Avg. Shipping Label Profit',
    total_refund_tax: 'Total Refund Tax',
    gateway_fees: 'Total Gateway Fee',
  },
  formatters: {
    total_orders: number_formatter,
    total_tax: elementCurrencyFormatter,
    total_shipping_tax: elementCurrencyFormatter,
    total_sales: elementCurrencyFormatter,
    total_gross_sales: elementCurrencyFormatter,
    total_revenue: elementCurrencyFormatter,
    total_shippings: elementCurrencyFormatter,
    total_items_sold: number_formatter,
    total_discounts: elementCurrencyFormatter,
    total_costs: elementCurrencyFormatter,
    total_store_fees: elementCurrencyFormatter,
    total_profit: elementCurrencyFormatter,
    total_refunds: elementCurrencyFormatter,
    total_items_refunded: number_formatter,
    total_cost_of_refunds: elementCurrencyFormatter,
    tax_rate: percentage_formatter,
    gross_tax_rate: percentage_formatter,
    gross_margin: percentage_formatter,
    total_customers: number_formatter,
    total_taxed_orders: number_formatter,
    total_discounted_orders: number_formatter,
    total_store_credit_amount: elementCurrencyFormatter,
    total_store_credit_refund: elementCurrencyFormatter,
    total_shipping_refund: elementCurrencyFormatter,
    gateway_fee: elementCurrencyFormatter,
    total_shipping_tax: elementCurrencyFormatter,
    total_cost_of_goods_sold: elementCurrencyFormatter,
    ...minMaxStatsConfig.formatters,
    ...fulfillmentStatsConfig.formatters,
    total_sale_discount: elementCurrencyFormatter,
    avg_items_per_order: number_formatter,
    total_shipment_cost: elementCurrencyFormatter,
    total_shipment_profit: elementCurrencyFormatter,
    avg_shipment_profit: elementCurrencyFormatter,
    total_refund_tax: elementCurrencyFormatter,
    gateway_fee: elementCurrencyFormatter,
  }
}

export const reportColumnConfigs = [
  { reportKey: 'order', reportTitle: 'Orders', colKeys: transactionsConfig.keys, colTitles: transactionsConfig.titles, sortableFields: transactionsConfig.sortableFields },
  { reportKey: 'lineItem', reportTitle: 'Order Line Items', colKeys: lineItemsConfig.keys, colTitles: lineItemsConfig.titles, sortableFields: lineItemsConfig.sortableFields},
  { reportKey: 'product', reportTitle: 'Product - Items', colKeys: productsConfig.keys, colTitles: { ...productsConfig.titles, image_url: 'Image' }, sortableFields: productsConfig.sortableFields},
  { reportKey: 'variant', reportTitle: 'Product - Variants', colKeys: variantsConfig.keys, colTitles: { ...variantsConfig.titles, image_url: 'Image' }, sortableFields: variantsConfig.sortableFields},
  { reportKey: 'brand', reportTitle: 'Product - Brands', colKeys: brandsConfig.keys, colTitles: brandsConfig.titles, sortableFields: brandsConfig.sortableFields},
  { reportKey: 'sku', reportTitle: 'Product - SKUs', colKeys: skusConfig.keys, colTitles: skusConfig.titles, sortableFields: skusConfig.sortableFields},
  { reportKey: 'productTrend', reportTitle: 'Product - Timeline', colKeys: productTrendsConfig.keys, colTitles: productTrendsConfig.titles, sortableFields: productTrendsConfig.sortableFields},
  { reportKey: 'customer', reportTitle: 'Customers', colKeys: customersConfig.keys, colTitles: customersConfig.titles, sortableFields: customersConfig.sortableFields},
  ...Object.keys(utmsConfig).map(utmKey => ({
    reportKey: utmKey, reportTitle: marketingReportTitles[utmKey], colKeys: utmsConfig[utmKey].keys, colTitles: utmsConfig[utmKey].titles, sortableFields: utmsConfig[utmKey].sortableFields
  })),
  { reportKey: 'map', reportTitle: 'World Map', colKeys: mapDataSchema.keys, colTitles: mapDataSchema.names, sortableFields: mapDataSchema.sortableFields },
  { reportKey: 'customOrderBase', reportTitle: 'Pivot Grouping Report', colKeys: customOrderBasesConfig.keys, colTitles: customOrderBasesConfig.titles, sortableFields: customOrderBasesConfig.sortableFields },
]

/**
 * Insert brand titles(names) from brand's call response to order's
 * @param {Array} brandFacetData entries of facets.brand_ids from the order's call response
 * @param {Array} brandData entries of brands from the brand's call response
 */
export const insertBrandTitles = (brandFacetData, brandData) => {
  return brandFacetData.map(bucket => ({
    ...bucket,
    title: (brandData.find(b => (b.merchant_id === bucket.merchant_id) && (b.brand_id == bucket.val)) || {}).title || 'N/A',
  }))
}

export const sortReportColKeys = columnConfigs => {
  return (a, b) => _.get(columnConfigs, [a, 'order'], 0) - _.get(columnConfigs, [b, 'order'], 0)
}

export const lineItemExcludedCsvFields = [
  'link', 'line_items', 'brand_names', 'category_titles', 'product_ids', 'catalog_titles_v1'
]
const lineItemCopyOrderFields = transactionsConfig.keys.filter(key => !lineItemExcludedCsvFields.includes(key))
export const lineItemAdditionalFormatters = {
  product_title: v => v,
  product_id: v => v,
  product_category: arrayFormattersNoLink.array_csv,
  product_catalog: arrayFormattersNoLink.array_csv,
  variant_title: arrayFormattersNoLink.array_csv,
  product_metafields: arrayFormattersNoLink.array_csv,
  order_metafields: arrayFormattersNoLink.array_csv,
  brand_name: v => v,
}
export const lineItemAdditionalTitles = {
  product_title: 'Product Title',
  product_id: 'Product ID',
  product_category: 'Product Category',
  product_catalog: 'Product Catalog',
  variant_title: 'Variant Title',
  brand_name: 'Brand',
}
export const formatLineItemsCsv = orders => {
  const ret = orders.map(order => order.line_items.map(lineItem => ({
    ...lineItemCopyOrderFields.reduce((acc, field) => ({ ...acc, [field]: order[field] }), {}),
    ...lineItem,
    custom_fields: lineItem['custom_fields'] ? lineItem['custom_fields'] : []
  }))).reduce((acc, lineItems) => [...acc, ...lineItems], [])
  return ret
}


/**
 * {
    "created_at": "YYYY-MM-DD HH:mm:ss",
    "fulfilled_at": "YYYY-MM-DD HH:mm:ss",
    "processed_at": "YYYY-MM-DD HH:mm:ss",
    "shipping_province": "name",
}
 */

export const customColumnFormatters = (customFormattersConfig) => {
  const formattedObject = {};
  const acceptedDateKeys = ['created_at', 'fulfilled_at', 'processed_at', 'updated_at', 'last_refund_at', 'payout_dates'];
  const acceptedProvinceKeys = ['shipping_province'];

  Object.keys(customFormattersConfig).forEach((key) => {
    if (acceptedDateKeys.includes(key)) {
      formattedObject[key] = dynamic_date_formatter(customFormattersConfig[key]);
    } else if (acceptedProvinceKeys.includes(key)) {
      formattedObject[key] = dynamic_province_formatter(customFormattersConfig[key]);
    }
  });

  return formattedObject;
};