const initialState = {
  dataStatus: 'loading',
  timeQuery: 5,
  refreshInterval: 3600000,
  stats: {},
  facets: {},
  visitors: [],
  carts: [],
  checkouts: [],
  orders: [],
  sessions: [], // visitors(TBA), carts and orders
  trends: [],
  timestamp: (new Date()).getTime(),
  page: {
    carts: 1,
    sessions: 1,
    orders: 1,
  },
  perPage: 20,
  pages: {
    carts: 1,
    sessions: 1,
    orders: 1,
  },
  countries: {
    orders: [],
    sessions: [],
  }
}

export default function reducer(state = initialState, action) {
  switch(action.type) {
    case 'SET_DATA_STATUS_TRAFFIC': {
      return {
        ...state,
        dataStatus: action.payload,
      }
    }
    case 'SET_TIME_QUERY_TRAFFIC': {
      return {
        ...state,
        timeQuery: action.payload,
      }
    }
    case 'SET_REFRESH_INTERVAL_TRAFFIC': {
      return {
        ...state,
        refreshInterval: action.payload,
      }
    }
    case 'SET_TIMESTAMP_TRAFFIC': {
      return {
        ...state,
        timestamp: action.payload,
      }
    }
    case 'SET_PAGE_TRAFFIC': {
      return {
        ...state,
        page: {
          ...state.page,
          ...action.payload,
        },
      }
    }
    case 'SET_PER_PAGE_TRAFFIC': {
      return {
        ...state,
        perPage: action.payload,
      }
    }
    case 'UPDATE_SEARCH_RESULTS_TRAFFIC': {
      return {
        ...state,
        trends: action.payload.trends || state.trends,
        stats: action.payload.stats || state.stats,
        facets: action.payload.facets || state.facets,
        visitors: action.payload.visitors || state.visitors,
        checkouts: action.payload.checkouts || state.checkouts,
        carts: action.payload.carts || state.carts,
        orders: action.payload.orders || state.orders,
        sessions: action.payload.sessions || state.sessions,
        pages: action.payload.pages || state.pages,
        countries: action.payload.countries || state.countries
      }
    }
  }
  return state
}