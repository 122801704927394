module.exports = {
	"appName": "PayHelm",
	"apiHost": {
		"local.test-payhelm.com": "http://local.test-payhelm.com:3000",
		"local.payhelm.com-back": "https://www.staging.payhelm.com",
		"local.payhelm.com": "http://localhost.payhelm.com:3000",
		"localhost": "http://localhost:3000",
		"app.curl.ai": "https://www.curl.ai",
		"app-shopify.sukow.org": "http://www-shopify.sukow.org",
		"app.staging.payhelm.com": "https://client.staging.payhelm.com",
		"app.payhelm.com": "https://client.payhelm.com",
		"app.retensio.com": "http://www.retensio.com"
	},
	"homeHost": {
		"local.test-payhelm.com": "http://local.test-payhelm.com:3000",
		"local.payhelm.com": "https://www.staging.payhelm.com",
		"localhost": "http://localhost:3000",
		"app.curl.ai": "https://www.curl.ai",
		"app-shopify.sukow.org": "http://www-shopify.sukow.org",
		"app.staging.payhelm.com": "https://www.staging.payhelm.com",
		"app.retensio.com": "http://www.retensio.com",
		"app.payhelm.com": "https://www.payhelm.com"
	},
	"cookieDomain": {
		"local.test-payhelm.com": ".test-payhelm.com",
		"local.payhelm.com": ".payhelm.com",
		"localhost.payhelm.com": ".payhelm.com",
		"localhost": "localhost",
		"app.curl.ai": ".curl.ai",
		"app.staging.payhelm.com": ".payhelm.com",
		"app.retensio.com": ".retensio.com",
		"app-shopify.sukow.org": ".sukow.org",
		"app.payhelm.com": ".payhelm.com"
	},
	"appHost": {
		"whitelist": [
			"local.test-payhelm.com",
			"localhost",
			"app.curl.ai",
			"app.staging.payhelm.com",
			"app.retensio.com",
			"app-shopify.sukow.org",
			"app.payhelm.com",
			"local.payhelm.com",
			"localhost.payhelm.com"
		],
		"default": "app.payhelm.com"
	},
	"apiNotificationHost": {
		"localhost": "http://localhost:3030",
		"app.curl.ai": "https://notifications.curl.ai",
		"app.staging.payhelm.com": "https://notifications.payhelm.com",
		"app.retensio.com": "https://notifications.curl.ai",
		"app-shopify.sukow.org": "http://notifications-shopify.sukow.org",
		"app.payhelm.com": "https://notifications.payhelm.com"
	},
	"inkfrogHost": {
		"localhost": {
			"user": "http://test-local.inkfrog.com:3000/inkfrog/inkfrog_account"
		},
		"app.curl.ai": {
			"user": "https://open.inkfrog.com/api/current_user.json"
		},
		"app.staging.payhelm.com": {
			"user": "https://open.inkfrog.com/api/current_user.json"
		},
		"app.retensio.com": {
			"user": "https://open.inkfrog.com/api/current_user.json"
		},
		"app.payhelm.com": {
			"user": "https://open.inkfrog.com/api/current_user.json"
		}
	}
};