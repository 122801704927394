import React, { useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import queryString from 'query-string'
import { AiOutlineSearch } from 'react-icons/ai'

function Search({ searchValue, setSearchValue, location, history }) {
  // Set the search value based on url query
  useEffect(() => {
    const queryStr = queryString.parse(location?.search)
    if (queryStr?.search) {
      setSearchValue(queryStr.search)
    }
  }, [])

  const handleChange = (e) => {
    setSearchValue(e.target.value)
    history.push({ pathname: '/integrations', search: `?search=${e.target.value}` })
  }

  return (
    <div className="tw-w-full tw-max-w-full md:tw-w-72 tw-border-b tw-border-slate-300 tw-flex tw-items-center">
      <AiOutlineSearch />
      <input
        className="tw-px-2 tw-py-1.5 tw-bg-transparent"
        placeholder="Search integrations"
        onChange={handleChange}
        value={searchValue}
      />
    </div>
  )
}

export default withRouter(Search)
