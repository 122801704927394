import React from 'react'

export default function MyCheckbox({ register, name, label, content, className = '', ...rest }) {
  return (
    <div className={`tw-relative tw-flex tw-items-start ${className}`} {...rest}>
      <div className="tw-flex tw-h-5 tw-items-center">
        <input
          id={name}
          name={name}
          {...register(name)}
          type="checkbox"
          className="tw-h-4 tw-w-4 tw-rounded tw-border-gray-300 tw-text-indigo-600 tw-focus:ring-indigo-400"
        />
      </div>
      <div className="tw-ml-2">
        <label htmlFor={name}>
          {label}
        </label>
        {content && <div>{content}</div>}
      </div>
    </div>
  )
}
