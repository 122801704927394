import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import axios from 'axios'
import { getAxiosDefaultConfig } from 'utils'
import Search from './components/Search'
import IntegrationCard from './components/IntegrationCard'
import { useIntegrations, useCategories } from './hooks'
import BackfillModal from './components/BackfillModal'
import BackfillBanner from 'sharedComponents/BackfillBanner'
import UploadModal from 'sharedComponents/UploadCSVButton/UploadModal'
import { useDispatch, useSelector } from 'react-redux'
import { selectRootAccounts, selectRootUser } from '../../rootSlice'
import {
  closeBackfillModal,
  closeConnectionModal,
  closeCsvModal,
  closeSettingsModal,
  selectBackillModalVisible,
  selectConnectionModalVisible,
  selectCsvModalVisible,
  selectSettingsModalVisible,
} from './integrationsSlice'
import SettingsModal from './components/SettingsModal'
import ConnectionModal from './components/ConnectionModal'
import SideMenu from './components/SideMenu'
import { fetchTeamAccounts, selectTeamAccounts, selectTeamAccountsStatus } from '../Settings/settingsSlice'
import { setAccounts } from 'actions/rootActions'
import { getOwnerId } from '../../modules/utils'
import { verifyLogin } from 'urls'
import { baseUrl } from '../../modules/urls'
import { Button } from 'react-bootstrap'

export default function Integrations() {
  const dispatch = useDispatch()

  /**
   *
   * Handle accounts
   *
   */
  const user = useSelector(selectRootUser)
  const rootAccounts = useSelector(selectRootAccounts)
  const accounts = rootAccounts.map((_account) => ({ ..._account, isOwner: user.email === _account.owner }))
  const retrieveAccounts = async () => {
    console.log('Retrieving accounts...')
    const loginRes = await axios.get(verifyLogin, getAxiosDefaultConfig())
    const { accounts: _accounts } = loginRes.data
    dispatch(setAccounts(_accounts))
  }

  /**
   *
   * Fetch user and team accounts so we can get the ownerId
   *
   */
  const teamAccounts = useSelector(selectTeamAccounts)
  const teamAccountsStatus = useSelector(selectTeamAccountsStatus)
  useEffect(() => {
    if (teamAccountsStatus === 'idle') {
      dispatch(fetchTeamAccounts())
    }
  }, [teamAccountsStatus])

  const ownerId = getOwnerId(user, teamAccounts)

  /**
   *
   * Handle integrations and categories
   *
   */
  const { data: integrations } = useIntegrations({ accounts, ownerId })
  const { data: categories } = useCategories({ integrationIds: integrations.ids })
  const [selectedCategory, setSelectedCategory] = useState(categories.entities['all'])
  const [searchValue, setSearchValue] = useState('')

  const searchFilterPredicate = (integration = {}) => {
    const { title } = integration
    if (!title || _.isEmpty(searchValue)) return true

    const lowercasedName = title.toLowerCase()
    return lowercasedName.includes(searchValue.toLowerCase())
  }

  const selectedCategoryIntegrations =
    selectedCategory.integrationIds && selectedCategory.integrationIds.map((_id) => integrations.entities[_id]).filter(v => v)
  const connectedIntegrations =
    selectedCategoryIntegrations &&
    selectedCategoryIntegrations.filter(({ connected }) => connected).filter(searchFilterPredicate)
  const unconnectedIntegrations =
    selectedCategoryIntegrations &&
    selectedCategoryIntegrations.filter(({ connected }) => !connected).filter(searchFilterPredicate)

  /**
   *
   * Handle modals
   *
   */
  const connectionModalVisible = useSelector(selectConnectionModalVisible)
  const backfillModalVisible = useSelector(selectBackillModalVisible)
  const settingsModalVisible = useSelector(selectSettingsModalVisible)
  const csvModalVisible = useSelector(selectCsvModalVisible)

  return (
    <>
      <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-3 lg:tw-grid-cols-4 xl:tw-grid-cols-5 md:tw-min-h-screen -tw-m-[20px]">
        <div className="tw-col-span-1 tw-bg-white/80">
          <SideMenu
            categories={categories}
            selectedCategory={selectedCategory}
            setSelectedCategory={setSelectedCategory}
          />
        </div>

        <div className="tw-col-span-1 md:tw-col-span-2 lg:tw-col-span-3 xl:tw-col-span-4 tw-p-8">
          <BackfillBanner style={{ marginBottom: '3rem' }} />
          <div className="tw-flex tw-justify-end tw-mb-6">
            <Search searchValue={searchValue} setSearchValue={setSearchValue} />
          </div>

          {connectedIntegrations && connectedIntegrations?.length > 0 && (
            <div className="tw-mb-10">
              <h5 className="tw-mb-4 tw-font-semibold">Your connected integrations</h5>
              <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-1 lg:tw-grid-cols-1 xl:tw-grid-cols-2 tw-gap-6">
                {connectedIntegrations?.map((_integration) => {
                  return <IntegrationCard key={_integration.id} integration={_integration} ownerId={ownerId} />
                })}
              </div>
            </div>
          )}

          {unconnectedIntegrations && unconnectedIntegrations?.length > 0 && (
            <div>
              <h5 className="tw-mb-4 tw-font-semibold">Recommended integrations</h5>
              <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 lg:tw-grid-cols-3 xl:tw-grid-cols-4 tw-gap-6">
                {unconnectedIntegrations?.map((_integration) => {
                  return (
                    <IntegrationCard
                      key={_integration.id}
                      integration={_integration}
                      ownerId={ownerId}
                      csvModalVisible={csvModalVisible}
                    />
                  )
                })}
              </div>
            </div>
          )}
        </div>
      </div>

      <ConnectionModal
        ownerId={ownerId}
        visible={connectionModalVisible}
        closeModal={() => dispatch(closeConnectionModal())}
        accounts={accounts}
        retrieveAccounts={retrieveAccounts}
      />
      <BackfillModal visible={backfillModalVisible} closeModal={() => dispatch(closeBackfillModal())} />
      <SettingsModal
        visible={settingsModalVisible}
        accounts={accounts}
        closeModal={() => dispatch(closeSettingsModal())}
        retrieveAccounts={retrieveAccounts}
      />
      <UploadModal open={csvModalVisible} closeModal={() => dispatch(closeCsvModal())} />
    </>
  )
}
