export const simpleDispatchProps = (dispatch, actions) => {
  return Object.assign({}, ...Object.keys(actions).map(actionName => {
    return {
      [actionName]: (...args) => dispatch(actions[actionName](...args))
    }
  }))
}

export const simpleMapDispatchToProps = (actions) => (dispatch) =>
  simpleDispatchProps(dispatch, actions)

export const checkRequiredAction = ({ subscription, billingChannel }, admin) => {
  const { plan_status: planStatus = {} } = subscription || {}
  const subscriptionActive = (
    subscription && (
      (planStatus.upgradeRequired && subscription.status === 'trialing') ||
      (!planStatus.upgradeRequired && ['trialing', 'active', 'free'].includes(subscription.status))
    )
  ) || admin || billingChannel === 'thirdparty'
  let requiredAction = { type: 'required_action', details: 'none' }
  if (!subscriptionActive) {
    if (planStatus.upgradeRequired) {
      requiredAction.details = `upgrade_${planStatus.nextPlan}`
    }
    else if (planStatus.trialExpired) {
      requiredAction.details = `set_plan_payments`
    }
    else if (planStatus.creditCardRequired) {
      requiredAction.details = `set_payments`
    }
  }
  return { subscriptionActive, requiredAction }
}