import React from 'react'
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom'
import { bool } from 'prop-types'

import EmailCampaigns from 'applications/EmailCampaigns'
import Dashboard from 'applications/Dashboard'
import Track from 'applications/Track'
import Staff from 'applications/Staff'
import Statistics from 'applications/Statistics'

import MarketingBanner from 'sharedComponents/MarketingBanner'
import Footer from 'sharedComponents/Footer'


const InkfrogRouter = ({ admin } ) => (
  <Router>
    <div>
      <div id="sub-analytics-body" className="third-party">
        <MarketingBanner />
        <Switch>
          <Route path="/emailcampaigns" component={EmailCampaigns} />
          <Route path="/dashboard" component={Dashboard} />
          <Route path="/competitors" component={Competitors} />
          <Route path="/hotproducts" component={HotProducts} />
          <Route path="/track" component={Track} />
          <Route path="/staff" component={admin ? Staff : null} />
          <Route path="/statistics" component={admin ? Statistics : null} />
          <Redirect to="/dashboard" />
        </Switch>
      </div>
      <footer id="sub-analytics-footer" className="third-party">
        <Footer />
      </footer>
    </div>
  </Router>
)

InkfrogRouter.propTypes = {
  admin: bool,
}

export default InkfrogRouter
