import React from 'react'
import { string, func } from 'prop-types'
import { Modal, Button } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'
import content from './content'
import { connect } from 'react-redux'
import { simpleMapDispatchToProps } from 'actions/actionUtils'
import { changeApplication } from 'actions/rootActions'
const applicableApps = Object.keys(content)


const PlanModal = ({ applicationId, changeApplication }) => {
  if (!applicableApps.includes(applicationId)) return null
  return (
    <Modal show={true} backdropClassName={`backdrop-${applicationId} plan-modal-backdrop`} className="plan-modal">
      <Modal.Header>
        <Modal.Title>{content[applicationId].title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h1>{content[applicationId].bodyTitle}</h1>
        <p>{content[applicationId].description}</p>
      </Modal.Body>
      <Modal.Footer>
        <div className="left">
          <div className="float-right">
            <LinkContainer to="/plan">
              <Button variant="primary" onClick={() => changeApplication(applicationId, 'plan')}>
                Switch Plan
              </Button>
            </LinkContainer>
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  )
}


PlanModal.propTypes = {
  applicationId: string.isRequired,
  changeApplication: func.isRequired,
}


const mapStateToProps = (store) => ({
  applicationId: store.root.applicationId,
})

const mapDispatchToProps = simpleMapDispatchToProps({
  changeApplication,
})

export default connect(mapStateToProps, mapDispatchToProps)(PlanModal)
